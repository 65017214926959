import angular, { ITimeoutService } from "angular";
import moment from "moment";
import { ColumnDefinition, HestiaDataTable } from "src/ajs/Directives/HestiaDataTableDirective";
import { PaymentState, ReceiptDto } from "src/ajs/Models/Erp/ReceiptDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { SearchBarService } from "src/ajs/Services/SearchBarService";
import { StorageService } from "src/ajs/Services/StorageService";
import { convertDate, formatDateShort } from "src/ajs/Utils/HestiaCommon";
import { MandatorService } from "src/app/core/services/mandator.service";

export class ReceiptEnvironment {

  CurrentAddressId: number;
  IsLoading: boolean;
  DateStart: Date;
  DateEnd: Date;
  Receipts: ReceiptDto[];
  TypeFilters: CreateReceiptTypeFilter[];
  SelectedTypeFilter: string;
  Loaded: boolean;
  ReceiptsTable: HestiaDataTable;
  FilteredEntries: ReceiptDto[];
  FilteredCount: number;
  SelectedReceipt: ReceiptDto;

  PaymentStateFilter: string;


  constructor(
    private readonly parentScope: any,
    private readonly $timeout: ITimeoutService,
    StorageService: StorageService,
    private readonly ApiHandlerService: ApiHandlerService,
    private readonly $mdDialog: angular.material.IDialogService,
    private readonly SearchBarService: SearchBarService,
    private readonly mandatorService: MandatorService,
    addressId?: number,
    allData?: boolean,

  ) {
    //const receiptEnvironment = this; wird nicht mehr benötig?
    this.CurrentAddressId = addressId;

    this.ReceiptsTable = new HestiaDataTable(StorageService.CreateInstance("ReceiptsTable"), $timeout, this);
    this.ReceiptsTable.ItemClicked = this.ItemDetailsSelected.bind(this);
    this.ReceiptsTable.Options.Select = false;
    this.ReceiptsTable.AggregateFunctions.push({ id: 0, func: this.AggregateSum });
    this.ReceiptsTable.UpdateColumns([
      new ColumnDefinition({ PropertyPath: 'ReceiptIdentifier', Heading: 'Belegnummer', Resizeable: true, Sortable: true }),
      new ColumnDefinition({ PropertyPath: 'Date', Heading: 'Datum', Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDateShort }),
      new ColumnDefinition({ PropertyPath: 'Type', Heading: 'Belegart', Resizeable: true, Sortable: true }),
      new ColumnDefinition({ PropertyPath: 'Matchcode', Heading: 'Matchcode', Resizeable: true, Sortable: true }),
      new ColumnDefinition({ PropertyPath: 'Sum', Aggregate: [true], Heading: 'Nettobetrag', Resizeable: true, Sortable: true, Align: 'stretch', Template: "/ClientApp/src/ajs/Views/Erp/ReceiptSumTemplate.htm" }),
      new ColumnDefinition({ PropertyPath: 'Creator', Heading: 'Bearbeiter', Resizeable: true, Sortable: true }),
      new ColumnDefinition({ PropertyPath: 'HasFile', Heading: ' ', ColumnWidth: 70, Resizeable: false, Sortable: false, Template: "/ClientApp/src/ajs/Views/Erp/ReceiptDownloadTemplate.htm" })
    ]);

    this.PaymentStateFilter = "null";

    this.LoadData(allData);
  }

  DownloadReceipt(model: ReceiptDto, event: any) {
    const win = window.open("/Api/Erp/OpenSageDocument?id=" + model.InternalPath + '&mandatorId=' + this.mandatorService.selectedMandatorId, '_blank');
    win.focus();
  }


  SendReceiptAsMail(model: ReceiptDto, event: any) {
    const currentReceipts = [];

    if (model.BillingEMail) {
      currentReceipts.push({
        EMailAddress: model.BillingEMail,
        DisplayName: model.BillingEMail,
        Image: "/ClientApp/src/ajs/Images/c_placeholder.png"
      });
    }
    else if (model.EMail) {
      currentReceipts.push({
        EMailAddress: model.EMail,
        DisplayName: model.EMail,
        Image: "/ClientApp/src/ajs/Images/c_placeholder.png"
      });
    }
    // else if (_scopeconst.SelectedAddress.BillingEMail) {
    //   currentReceipts.push({
    //     EMailAddress: _scopeconst.SelectedAddress.BillingEMail,
    //     DisplayName: _scopeconst.SelectedAddress.BillingEMail,
    //     Image: "/ClientApp/src/ajs/Images/c_placeholder.png"
    //   });
    // }
    // else if (_scopeconst.SelectedAddress.EMail && _scopeconst.SelectedAddress.EMail !== _scopeconst.SelectedAddress.BillingEMail) {
    //   currentReceipts.push({
    //     EMailAddress: _scopeconst.SelectedAddress.EMail,
    //     DisplayName: _scopeconst.SelectedAddress.EMail,
    //     Image: "/ClientApp/src/ajs/Images/c_placeholder.png"
    //   });
    // }

    let typeText = model.Type;
    if (typeText.toLowerCase().includes("rechnung")) {
      typeText = "Rechnung";
    }
    this.$mdDialog.show({
      targetEvent: event,
      controller: 'MailSendDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/MailSend/MailSendDialog.htm',
      locals: {
        //SelectedAddress.BillingEMail
        Parameters: {
          Subject: typeText + " " + model.ReceiptIdentifier + " vom " + formatDateShort(convertDate(model.Date)),
          Receipts: currentReceipts,
          CachedAddressId: this.parentScope.SelectedAddress?.CachedAddress?.Id
        },
        OnSubmit: (result) => {
          this.ApiHandlerService.SendRequest("Erp", "SendRecieptAsMail", { receipt: model, mailMeta: result, mandatorId: this.mandatorService.selectedMandatorId }).then(function (data) { });
        }
      },
      parent: angular.element(document.body)
    });
  }


  OpenReceipt(model: ReceiptDto) {
    return "/Api/Erp/OpenSageDocument?id=" + model.InternalPath + '&mandatorId=' + this.mandatorService.selectedMandatorId;
  }

  AggregateSum(values: number[]) {
    if (values && values.length) {
      const value: number = values.reduce((a, b) => a + b);
      return "Σ " + value.toFixed(2) + " €";
    }
    return null;
  }

  async LoadData(allData: boolean) {
    this.IsLoading = true;
    this.DateStart = allData ? new Date(new Date().setDate(new Date().getDate() - 14)) : null;
    this.DateEnd = null;
    let promise: angular.IPromise<any> = null;
    if (allData) {
      promise = this.ApiHandlerService.SendRequest("Erp", "GetAllReceipts", { axsPortalMandatorId: this.mandatorService.selectedMandatorId });
    }
    else {
      promise = this.ApiHandlerService.SendRequest("Erp", "GetReceipts", { addressId: this.CurrentAddressId, mandatorId: this.mandatorService.selectedMandatorId });
    }
    const data = await promise;

    this.$timeout(() => {
      this.Receipts = data.Receipts;
      this.TypeFilters = [];
      if (allData) {
        this.SelectedTypeFilter = "*rechnung";
      }
      else {
        this.SelectedTypeFilter = "0";
      }
      this.TypeFilters.push(new CreateReceiptTypeFilter(0, "Alle Belege"));
      this.TypeFilters.push(new CreateReceiptTypeFilter("*rechnung", "Alle Rechnungen"));
      this.Receipts.forEach(f => {
        f.DownloadReceipt = this.DownloadReceipt.bind(this);
        f.SendReceiptAsMail = this.SendReceiptAsMail.bind(this);
        f.Date = moment(f.Date);
        f.DateFormatted = formatDateShort(f.Date);
        f.DueDate = moment(f.DueDate);
        f.DueDateFormatted = formatDateShort(f.DueDate);
        f.SumFormatted = f.Sum.toFixed(2) + " €";
        f.SumWithTaxFormatted = f.SumWithTax.toFixed(2) + " €";
        f.TaxFormatted = f.Tax.toFixed(2) + " €";
        const filter = this.TypeFilters.filter(t => t.Value === f.Type);
        if (filter.length === 0) {
          this.TypeFilters.push(new CreateReceiptTypeFilter(f.Type, f.Type));
        }
        if (f.AccumulatedPayment) {
          f.AccumulatedPayment.PaymentDate = moment(f.AccumulatedPayment.PaymentDate);
          f.AccumulatedPayment.PaymentDateFormatted = formatDateShort(f.AccumulatedPayment.PaymentDate);
          f.AccumulatedPayment.ReceiptAmountFormatted = f.AccumulatedPayment.ReceiptAmount.toFixed(2) + " €";
          f.AccumulatedPayment.PaymentAmountFormatted = f.AccumulatedPayment.PaymentAmount.toFixed(2) + " €";
        }
        if (f.Payments) {
          f.Payments.forEach(p => {
            p.PaymentDate = moment(p.PaymentDate);
            p.PaymentDateFormatted = formatDateShort(p.PaymentDate);
            p.ReceiptAmountFormatted = p.ReceiptAmount.toFixed(2) + " €";
            p.PaymentAmountFormatted = p.PaymentAmount.toFixed(2) + " €";
          });
        }
        if (f.Positions) {
          f.Positions.forEach(p => {
            p.UnitPriceFormatted = p.UnitPrice.toFixed(2) + " €";
            p.Total = p.UnitPrice * p.Quantitiy;
            p.TotalFormatted = p.Total.toFixed(2) + " €";
          });
        }
      });
      this.ApplyFilter();
      this.$timeout(() => {
        this.Loaded = true;
        this.ReceiptsTable.Update();
        this.IsLoading = false;
      }, 50);
    });
  }

  ApplyFilter() {
    if (this.Receipts) {
      if (this.SearchBarService.SearchText) {
        const cleanSearch = this.SearchBarService.SearchText.toLowerCase();
        this.FilteredEntries = this.Receipts.filter(f => {
          for (let i = 0; i < f.Positions.length; i++) {
            const position = f.Positions[i];
            if (position.Description1 && position.Description1.toLowerCase().includes(cleanSearch)) {
              return true;
            }
            if (position.Description2 && position.Description2.toLowerCase().includes(cleanSearch)) {
              return true;
            }
            if (position.Parts && position.Parts.length) {
              for (let x = 0; x < position.Parts.length; x++) {
                const part = position.Parts[x];
                if (part.Description1 && part.Description1.toLowerCase().includes(cleanSearch)) {
                  return true;
                }
                if (part.Description2 && part.Description2.toLowerCase().includes(cleanSearch)) {
                  return true;
                }
              }
            }
          }
          if (f.ReceiptIdentifier.toLowerCase().includes(cleanSearch)) {
            return true;
          }
          return false;
        });
      } else {
        this.FilteredEntries = this.Receipts.slice();
      }

      if (this.SelectedTypeFilter && this.SelectedTypeFilter !== "0") {
        if (this.SelectedTypeFilter[0] === '*') {
          const typeFilter = this.SelectedTypeFilter.substring(1);
          this.FilteredEntries = this.FilteredEntries.filter(f => f.Type.toLowerCase().includes(typeFilter.toLowerCase()));
        } else {
          this.FilteredEntries = this.FilteredEntries.filter(f => f.Type === this.SelectedTypeFilter);
        }
      }

      if (this.DateStart) {
        this.FilteredEntries = this.FilteredEntries.filter(f => moment(f.Date) >= moment(this.DateStart));
      }
      if (this.DateEnd) {
        this.FilteredEntries = this.FilteredEntries.filter(f => moment(f.Date) <= moment(this.DateEnd));
      }

      if (this.PaymentStateFilter && this.PaymentStateFilter !== "null") {

        if (this.PaymentStateFilter === "offen") {
          this.FilteredEntries = this.FilteredEntries.filter(x => x.PaymentState === PaymentState.FuturePayment || x.PaymentState === PaymentState.Open);
        }
        else {
          this.FilteredEntries = this.FilteredEntries.filter(x => x.PaymentState === parseInt(this.PaymentStateFilter));
        }

      }

      this.ReceiptsTable.UpdateSource(this.FilteredEntries, this.$timeout);
      this.FilteredCount = 0;
      if (this.FilteredEntries) {
        this.FilteredCount = this.FilteredEntries.length;
      }
    }
  }

  ItemDetailsSelected(model: ReceiptDto) {
    this.$timeout(() => {
      this.SelectedReceipt = model;
    });
  }
}

class CreateReceiptTypeFilter {
  Value: number | string;
  Title: string;

  constructor(value: number | string, title: string) {
    this.Value = value;
    this.Title = title;
  }
}
