import angular, { IScope, ITimeoutService } from "angular";
import JsBarcode from "jsbarcode";
import { DragAndDropPanel } from "src/ajs/Directives/DragAndDropPanel";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { CustomLabelDto } from "src/app/labels/dto/CustomLabelDto";
import { CustomLabelLineDto } from "src/ajs/Models/Label/CustomLabelLineDto";
import { LabelPrintDataDto } from "src/ajs/Models/Label/LabelPrintDataDto";
import { LoadCustomLabelsResultDto } from "src/ajs/Models/Label/LoadCustomLabelsResultDto";
import { LogoDto } from "src/ajs/Models/Label/LogoDto";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { DialogService } from "src/app/shared/services/dialog.service";
import { ArrayUtils } from "src/app/shared/utils/array-utils";
import { MandatorService } from "src/app/core/services/mandator.service";

createLabelWidgetController.$inject = ['$scope', 'mdPanelRef', '$mdToast', '$timeout', 'ApiHandlerService', 'theme', 'DialogServiceNgx', 'MandatorServiceNgx'];

export function createLabelWidgetController(
  $scope: ILabelWidgetControllerScope,
  mdPanelRef: angular.material.IPanelRef,
  $mdToast: angular.material.IToastService,
  $timeout: ITimeoutService,
  ApiHandlerService: ApiHandlerService,
  theme: ThemeDto,
  dialogService: DialogService,
  mandatorServiceNgx: MandatorService
) {

  const LABELPADDING = 15;

  addDefaultScopeFunctions($scope, theme);
  $scope.IsInListView = true;
  $scope.DragAndDropPanel = new DragAndDropPanel("Etikettendruck", mdPanelRef);
  $scope.Filter = {
    Searchtext: null
  };

  mandatorServiceNgx.selectedMandatorIdChanged.subscribe(() => {
    $scope.LoadData();
  });


  $scope.ApplyFilter = () => {
    if ($scope.Labels) {
      if ($scope.Filter.Searchtext) {
        $scope.FilteredLabels = $scope.Labels.filter(f => f.Name.toLowerCase().includes($scope.Filter.Searchtext.toLowerCase()));
      } else {
        $scope.FilteredLabels = $scope.Labels.slice();
      }
    }
  };

  $scope.RepeaterStartChanged = () => {
    $scope.SelectedLabel.CurrentRepeater = $scope.SelectedLabel.RepeaterStart;
    try {
      $scope.SelectedPrinterChanged();
    } catch (exception) { /*ignore*/ }
  };

  $scope.TransformAlignment = (alignment) => {
    switch (alignment) {
      case 2:
        return "center";
      case 3:
        return "right";
      default:
        return "left";
    }
  };
  $scope.TransformAlignmentShort = (alignment) => {
    switch (alignment) {
      case 2:
        return "C";
      case 3:
        return "R";
      default:
        return "L";
    }
  };

  $scope.SelectedPrinterChanged = () => {
    const preview = $("#previewDiv");
    preview.css("padding", LABELPADDING + "px");
    preview.empty();
    const selectedPrinters = $scope.Printing.Printers.filter(f => f.Id === $scope.SelectedLabel.PrinterId);
    if (selectedPrinters.length) {
      const selectedPrinter = selectedPrinters[0];
      const format = selectedPrinter.Format;
      const width = format.Width * 0.0393701 * selectedPrinter.Dpi;
      const heigth = format.Height * 0.0393701 * selectedPrinter.Dpi;
      const labelContainer = document.createElement("div");
      preview.append(labelContainer);
      const labelContainerModel = $(labelContainer);
      labelContainer.className = "label-preview";
      labelContainerModel.width(width + "px");
      labelContainerModel.height(heigth + "px");

      let extraMarginLeft = 0;
      let extraMarginTop = 0;
      let extraMarginRight = 0;

      if ($scope.SelectedLabel.LogoType) {
        let imageMarginLeft = 5;
        const imageMarginTop = 5;
        const imageMarginRight = 5;
        const image = $scope.CurrentLogo.CreateImageElement();
        if ($scope.SelectedLabel.LogoType === 1) {
          extraMarginLeft = $scope.CurrentLogo.Width + imageMarginLeft;
        }
        if ($scope.SelectedLabel.LogoType === 3) {
          extraMarginRight = $scope.CurrentLogo.Width + imageMarginRight;
          imageMarginLeft = width - $scope.CurrentLogo.Width - imageMarginRight;
        }
        if ($scope.SelectedLabel.LogoType === 2) {
          extraMarginTop = $scope.CurrentLogo.Height + imageMarginTop;
          imageMarginLeft = (width - $scope.CurrentLogo.Width) / 2;
        }

        $(image).css('image-rendering', 'pixelated');
        $(image).css('position', 'fixed');
        $(image).css('margin-left', imageMarginLeft + "px");
        $(image).css('margin-top', imageMarginTop + "px");
        $(image).css('margin-right', imageMarginRight + "px");
        labelContainer.append(image);
      }
      let first = true;
      $scope.SelectedLabel.Lines.forEach((line, index) => {
        const l = document.createElement("div");
        l.className = "label-line";
        labelContainerModel.append(l);
        const lineModel = $(l);
        const properties = {
          'line-height': parseInt(line.Height) + 4 + "px",
          'min-height': parseInt(line.Height) + 4 + "px",
          'max-height': parseInt(line.Height) + 4 + "px",
          'font-size': parseInt(line.Height) + "px",
          'text-align': $scope.TransformAlignment(line.Alignment),
          'font-weight': line.IsBold ? 'bold' : 'normal'
        };
        if (first) {
          if (extraMarginTop) {
            properties["margin-top"] = extraMarginTop + "px";
          }
          first = false;
        }
        if (extraMarginLeft) {
          properties["margin-left"] = extraMarginLeft + "px";
        }
        if (extraMarginRight) {
          properties["margin-right"] = extraMarginRight + "px";
        }
        lineModel.css(properties);
        if (line.IsBarcode) {
          const bcsvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
          lineModel.append(bcsvg);
          const bcModel = $(bcsvg);
          bcModel.attr('id', 'bc' + index);
          bcModel.css('margin-top', '2px');
          JsBarcode('#bc' + index, $scope.GetLineText(line), {
            width: line.IsBold ? 2 : 1,
            format: "CODE128B",
            margin: 0,
            height: parseInt(line.Height),
            displayValue: false
          });
          line.BcWidth = bcModel.width();
        } else {
          lineModel.append(document.createTextNode($scope.GetLineText(line)));
        }
      });
    }
  };

  $scope.SaveLabel = async () => {



    // TODO: dialogService showMessage auf drei Buttons erweitern und verwenden.


    const labelModel: CustomLabelDto =
    {
      Id: $scope.SelectedLabel.Id,
      Name: $scope.SelectedLabel.Name,
      PrinterId: $scope.SelectedLabel.PrinterId,
      LogoType: $scope.SelectedLabel.LogoType,
      LogoHeight: $scope.SelectedLabel.LogoHeight,
      RepeaterPrefix: $scope.SelectedLabel.RepeaterPrefix,
      RepeaterSuffix: $scope.SelectedLabel.RepeaterSuffix,
      RepeaterTextPrefix: $scope.SelectedLabel.RepeaterTextPrefix,
      RepeaterStart: $scope.SelectedLabel.RepeaterStart,
      RepeaterEnd: $scope.SelectedLabel.RepeaterEnd,
      Lines: $scope.SelectedLabel.Lines
    };

    let toastMessage: string = "";

    const result = await dialogService.showMessage('Label speichern oder neues erstellen?', null, 'Speichern', 'primary', 'Neues erstellen', 'primary', 'Abbrechen', null);

    if (result === 1) {
      toastMessage = "Label erfolgreich gespeichert";
    }
    if (result === 2) {
      labelModel.Id = 0;
      toastMessage = "Neues Label erfolgreich erstellt";
    }
    else if (result === 3) {
      return;
    }

    ApiHandlerService.SendRequest("Label", "SaveCustomLabel", { model: labelModel }).then((data) => {
      $scope.SelectedLabel.Id = data.Id;
      const toast = $mdToast.simple()
        .textContent(toastMessage)
        .position('bottom right')
        .capsule(true);
      $mdToast.show(toast);
    });

  };

  $scope.SelectLabel = (label) => {
    $scope.SelectedLabel = label;
    $scope.IsInListView = false;
    $scope.DragAndDropPanel.AddFunction("save", $scope.SaveLabel, "save");
    $scope.DragAndDropPanel.AddFunction("arrow_back", $scope.NavigateToList, "back");
    $scope.DragAndDropPanel.RemoveFunction("add");
    $scope.RepeaterStartChanged();
    $scope.RecaclculateLogo();
    $timeout($scope.SelectedPrinterChanged, 150);
  };

  $scope.NavigateToList = () => {
    $scope.LoadData();
    $scope.IsInListView = true;
    $scope.SelectedLabel = null;
    $scope.DragAndDropPanel.AddFunction("add", $scope.CreateNewLabel, "add");
    $scope.DragAndDropPanel.RemoveFunction("back");
    $scope.DragAndDropPanel.RemoveFunction("save");
  };

  $scope.ToggleBarcode = (line) => {
    line.IsBarcode = !line.IsBarcode;
    $scope.SelectedPrinterChanged();
  };

  $scope.ToggleBold = (line) => {
    line.IsBold = !line.IsBold;
    $scope.SelectedPrinterChanged();
  };

  $scope.PrintNextLabel = () => {
    if ($scope.SelectedLabel.CurrentRepeater <= $scope.SelectedLabel.RepeaterEnd) {
      ApiHandlerService.SendRequest("Label", "PrintCustomLabel", { printerId: $scope.SelectedLabel.PrinterId, content: $scope.CreateLabelCode($scope.SelectedLabel.PrintQuantity) })
        .then(

          function (data) {
            $scope.SelectedLabel.CurrentRepeater++;
            $scope.PrintNextLabel();
          },

          function (reason) {
            $timeout(function () {
              const toast = $mdToast.simple()
                .textContent("Fehler beim Drucken")
                .position('bottom right');

              $mdToast.show(toast);
            });
          },

        );

    } else {
      $scope.SelectedLabel.CurrentRepeater = $scope.SelectedLabel.RepeaterStart;
    }
  };

  $scope.PrintLabel = () => {
    if ($scope.HasRepeater()) {
      $scope.SelectedLabel.CurrentRepeater = $scope.SelectedLabel.RepeaterStart;
      $scope.PrintNextLabel();
    } else {
      ApiHandlerService.SendRequest("Label", "PrintCustomLabel", { printerId: $scope.SelectedLabel.PrinterId, content: $scope.CreateLabelCode($scope.SelectedLabel.PrintQuantity) })
        .then(

          function (data) { },

          function (reason) {
            $timeout(function () {
              const toast = $mdToast.simple()
                .textContent("Fehler beim Drucken")
                .position('bottom right');

              $mdToast.show(toast);
            });
          },

        );
    }
  };

  $scope.CreateLabelCode = (quantity) => {
    const selectedPrinters = $scope.Printing.Printers.filter(f => f.Id === $scope.SelectedLabel.PrinterId);
    if (selectedPrinters.length) {
      const selectedPrinter = selectedPrinters[0];
      const format = selectedPrinter.Format;
      const width = Math.round(format.Width * 0.0393701 * selectedPrinter.Dpi);

      let code = 'CT~~CD,~CC^~CT~' + "\r\n";
      code += '^XA~TA000~JSN^LT0^MNW^MTD^PON^PMN^LH0,0^JMA^JUS^LRN^CI0^XZ' + "\r\n";
      code += '~SD' + selectedPrinter.Darkness + "\r\n";
      code += '^PR' + selectedPrinter.PrintSpeed + ',' + selectedPrinter.SlewSpeed + "\r\n";
      code += '^XA' + "\r\n";
      code += '^POI' + "\r\n";
      code += '^CI28' + "\r\n";
      code += '^MMT' + "\r\n";
      code += '^LS0' + "\r\n";

      let extraMarginLeft = 0;
      let extraMarginTop = 0;
      let extraMarginRight = 0;

      if ($scope.SelectedLabel.LogoType) {
        let imageMarginLeft = 5;
        const imageMarginTop = 5;
        const imageMarginRight = 5;

        if ($scope.SelectedLabel.LogoType === 1) {
          extraMarginLeft = $scope.CurrentLogo.Width + imageMarginLeft;
        }
        if ($scope.SelectedLabel.LogoType === 3) {
          extraMarginRight = $scope.CurrentLogo.Width + imageMarginRight;
          imageMarginLeft = width - $scope.CurrentLogo.Width - imageMarginRight;
        }
        if ($scope.SelectedLabel.LogoType === 2) {
          extraMarginTop = $scope.CurrentLogo.Height + imageMarginTop;
          imageMarginLeft = (width - $scope.CurrentLogo.Width) / 2;
        }
        const image = $scope.CurrentLogo;

        code += '^FO' + imageMarginLeft + ',' + imageMarginTop + '^' + image.ZplLine + "\r\n";
      }


      let currenty = 2 + LABELPADDING;
      $scope.SelectedLabel.Lines.forEach(line => {
        let currentLine = "";
        currenty += parseInt(line.Height);
        if (line.IsBarcode) {
          let left = LABELPADDING + 8 + extraMarginLeft;
          if (line.Alignment === 2) {
            left = ((width - extraMarginLeft - line.BcWidth) / 2) + extraMarginLeft;
          } else if (line.Alignment === 3) {
            left = width - line.BcWidth - LABELPADDING - 8;
          }
          currentLine = '^BY' + (line.IsBold ? 2 : 1) + ',2,' + line.Height + '^FT' + left + ',' + currenty + '^BCN,,N,N';
          currentLine += '^FD>:' + $scope.GetLineText(line) + '^FS';
        } else {
          let fontWidth = parseInt(line.Height);
          if (line.IsBold) {
            fontWidth += Math.round(fontWidth / 2);
          }
          const alignment = $scope.TransformAlignmentShort(line.Alignment);
          currentLine = "^FT" + (LABELPADDING + 8 + extraMarginLeft) + ",";
          currentLine += currenty + extraMarginTop;
          currentLine += "^A0N,";
          currentLine += line.Height;
          currentLine += ",";
          currentLine += fontWidth;
          currentLine += "^FB";
          currentLine += width - 2 * LABELPADDING - extraMarginLeft - extraMarginRight;
          currentLine += ",1,0,";
          currentLine += alignment;
          currentLine += "^FD";
          currentLine += $scope.GetLineText(line);
          currentLine += "^FS\r\n";
        }
        code += currentLine;
        currenty += 2;
      });
      code += '^PQ' + quantity + ',0,1,Y^XZ' + "\r\n";
      return code;
    }
    return undefined;
  };

  $scope.ToggleRepeater = (line) => {
    line.IsRepeater = !line.IsRepeater;
    $scope.SelectedPrinterChanged();
  };

  $scope.HasRepeater = () => {
    if ($scope.SelectedLabel) {
      return $scope.SelectedLabel.Lines.filter(f => f.IsRepeater).length;
    }
    return false;
  };

  $scope.ChangeLogoType = (type) => {
    $scope.SelectedLabel.LogoType = type;
    if (!$scope.SelectedLabel.LogoHeight) {
      $scope.SelectedLabel.LogoHeight = $scope.Logo.Height;
    }
    $scope.RecaclculateLogo();
  };

  $scope.RecaclculateLogo = () => {
    if ($scope.LogoTimeout !== null) {
      $timeout.cancel($scope.LogoTimeout);
    }
    $scope.LogoTimeout = $timeout(() => {
      $scope.CurrentLogo = CreateMonochromeBitmap($scope.Logo.Data, $scope.Logo.Width, $scope.Logo.Height);
      if ($scope.SelectedLabel.LogoHeight && $scope.SelectedLabel.LogoHeight !== $scope.Logo.Height) {
        if ($scope.SelectedLabel.LogoHeight > 500) {
          $scope.SelectedLabel.LogoHeight = 500;
        }
        const ratio = $scope.SelectedLabel.LogoHeight / $scope.Logo.Height;
        const newWidth = Math.round($scope.Logo.Width * ratio);
        $scope.CurrentLogo = $scope.CurrentLogo.Scale(newWidth, $scope.SelectedLabel.LogoHeight);
      }
      if ($scope.SelectedLabel.LogoType === 1) {
        $scope.CurrentLogo = $scope.CurrentLogo.RotateLeft();
      }
      if ($scope.SelectedLabel.LogoType === 3) {
        $scope.CurrentLogo = $scope.CurrentLogo.RotateRight();
      }
      $scope.SelectedPrinterChanged();
      $scope.LogoTimeout = null;
    }, 250);
  };

  $scope.ChangeAlignment = (line, alignment) => {
    line.Alignment = alignment;
    $scope.SelectedPrinterChanged();
  };

  $scope.AddLine = () => {
    $scope.SelectedLabel.Lines.push({
      Text: "",
      Height: "20",
      Alignment: 1,
      IsBold: false,
      IsBarcode: false,
      MarginTop: 0,
      MarginLeft: 0,
      MarginRight: 0,
      MarginBottom: 0,
      IsRepeater: false
    } as CustomLabelLineDto);
    $scope.SelectedPrinterChanged();
  };

  $scope.GetLineText = (line) => {
    if (line.IsRepeater) {
      let lineText = "";
      if ($scope.SelectedLabel.RepeaterPrefix)
        lineText += $scope.SelectedLabel.RepeaterPrefix;
      lineText += $scope.SelectedLabel.CurrentRepeater;
      if ($scope.SelectedLabel.RepeaterSuffix)
        lineText += $scope.SelectedLabel.RepeaterSuffix;
      if (!line.IsBarcode && $scope.SelectedLabel.RepeaterTextPrefix)
        lineText = $scope.SelectedLabel.RepeaterTextPrefix + lineText;
      return lineText;
    } else {
      if (!line.Text) {
        return "";
      }
      return line.Text;
    }

  };

  $scope.DeleteLine = (line) => {
    ArrayUtils.remove($scope.SelectedLabel.Lines, line);
  };

  $scope.CreateNewLabel = () => {
    $scope.SelectLabel({
      PrintQuantity: 1,
      Lines: [],
      RepeaterStart: 0,
      RepeaterEnd: 0,
      RepeaterPrefix: "",
      RepeaterSuffix: "",
      RepeaterTextPrefix: ""
    } as CustomLabelDto);
  };

  $scope.LoadData = () => {
    $scope.IsLoading = true;
    ApiHandlerService.SendRequest("Label", "LoadCustomLabels", { mandatorId: mandatorServiceNgx.selectedMandatorId }).then((data: LoadCustomLabelsResultDto) => {
      $scope.Logo = data.Logo;
      $scope.Labels = data.Labels;
      $scope.Printing = data.Printing;
      $scope.Labels.forEach(f => {
        f.PrintQuantity = 1;
      });
      $scope.ApplyFilter();
      $scope.IsLoading = false;
    });
  };

  $scope.DragAndDropPanel.AddFunction("add", $scope.CreateNewLabel, "add");

  $scope.LoadData();
  //
  //$scope.DragAndDropPanel.AddFunction("view_carousel", function () { $scope.Toggle('#FilterContent', '#FilterButtons'); });
  //$scope.DragAndDropPanel.AddFunction("refresh", $scope.Refresh);

}

interface ILabelWidgetControllerScope extends IScope {
  IsInListView: boolean;
  DragAndDropPanel: DragAndDropPanel;
  Filter: { Searchtext: string };
  Labels: CustomLabelDto[];
  FilteredLabels: CustomLabelDto[];
  SelectedLabel: CustomLabelDto;
  Printing: LabelPrintDataDto;
  CurrentLogo: any;
  Logo: LogoDto;
  LogoTimeout: any;
  IsLoading: boolean;

  ApplyFilter: () => void;
  RepeaterStartChanged: () => void;
  SelectedPrinterChanged: () => void;
  TransformAlignment: (alignment: number) => string;
  TransformAlignmentShort: (alignment: number) => string;
  GetLineText: (line: CustomLabelLineDto) => string;
  SaveLabel: () => void;
  SelectLabel: (label: CustomLabelDto) => void;
  RecaclculateLogo: () => void;
  NavigateToList: () => void;
  LoadData: () => void;
  ToggleBarcode: (line: CustomLabelLineDto) => void;
  ToggleBold: (line: CustomLabelLineDto) => void;
  PrintNextLabel: () => void;
  PrintLabel: () => void;
  HasRepeater: () => number | boolean;
  CreateLabelCode: (quantity: number) => string;
  ToggleRepeater: (line: CustomLabelLineDto) => void;
  ChangeLogoType: (type: number) => void;
  ChangeAlignment: (line: CustomLabelLineDto, alignment: number) => void;
  AddLine: () => void;
  DeleteLine: (line: CustomLabelLineDto) => void;
  CreateNewLabel: () => void;

}

function CreateMonochromeBitmap(arr: number[], width, height) {
  const image = {
    Source: arr,
    Depth: 1, // Monochrome
    Height: height,
    Width: width,
    MonochromeOffset: 62,
    Data: null,
    Image: null,
    BytesPerRow: null,
    BmpSource: <number[]>undefined,
    ZplSourceArray: <any[]>undefined,
    ZplData: <string>undefined,
    ZplLine: <string>undefined,
    RowSize: <number>undefined,

    ConvertBiniary: function (size) {
      return String.fromCharCode(size & 0xff, size >> 8 & 0xff, size >> 16 & 0xff, size >> 24 & 0xff);
    },

    ConvertBiniaryWord: function (size) {
      return String.fromCharCode(size & 0xff, size >> 8 & 0xff);
    },

    CreateHeader: function () {
      //BMP Header
      this.Data = 'BM';                                                   // ID field
      this.Data += this.ConvertBiniary(this.MonochromeOffset + this.BmpSource.length);// BMP size
      this.Data += this.ConvertBiniary(0);                                // unused
      this.Data += this.ConvertBiniary(this.MonochromeOffset);            // pixel data offset
      //DIB Header
      this.Data += this.ConvertBiniary(40);                               // DIB header length
      this.Data += this.ConvertBiniary(this.Width);                       // image width
      this.Data += this.ConvertBiniary(this.Height);                      // image height
      this.Data += this.ConvertBiniaryWord(1);                            // colour panes
      this.Data += this.ConvertBiniaryWord(this.Depth);                   // bits per pixel
      this.Data += this.ConvertBiniary(0);                                // compression method
      this.Data += this.ConvertBiniary(this.BmpSource.length);               // size of the raw data
      this.Data += this.ConvertBiniary(2835);                             // horizontal print resolution
      this.Data += this.ConvertBiniary(2835);                             // vertical print resolution
      this.Data += this.ConvertBiniary(2);                                // colors used
      this.Data += this.ConvertBiniary(0);                                // important colors
      //Grayscale tables for bit depths <= 8
      // First Color:
      this.Data += String.fromCharCode(0x00);
      this.Data += String.fromCharCode(0x00);
      this.Data += String.fromCharCode(0x00);
      this.Data += String.fromCharCode(0x00);
      // Second Color
      this.Data += String.fromCharCode(0xFF);
      this.Data += String.fromCharCode(0xFF);
      this.Data += String.fromCharCode(0xFF);
      this.Data += String.fromCharCode(0x0);
    },

    GenerateBitmap: function () {
      const bitmap = CreateEmptyBitmap();
      bitmap.InitFromImage(this);
      return bitmap;
    },

    RotateRight: function () {
      const currentBitmap = this.GenerateBitmap();
      const newBitmap = currentBitmap.RotateRight();
      return newBitmap.GenerateImage();
    },

    Scale: function (width, height) {
      const currentBitmap = this.GenerateBitmap();
      const newBitmap = currentBitmap.Scale(width, height);
      return newBitmap.GenerateImage();
    },

    RotateLeft: function () {
      const currentBitmap = this.GenerateBitmap();
      const newBitmap = currentBitmap.RotateLeft();
      return newBitmap.GenerateImage();
    },

    InitImage: function () {
      this.ZplSourceArray = [];
      for (let row = this.Height - 1; row >= 0; row--) {
        for (let column = 0; column < this.Width / 8; column++) {
          let byte = this.Source[row * this.Width / 8 + column];
          byte ^= 0xFF;
          //byte = reverseByte(byte);
          this.ZplSourceArray.push(byte);
        }
      }
      this.ZplData = toHexString(this.ZplSourceArray, true);
      this.ZplLine = "^GFA," + this.Source.length + "," + this.Source.length + "," + this.Width / 8 + "," + this.ZplData;
    },

    CreateImageElement: function () {
      this.BmpSource = [];
      this.RowSize = Math.ceil(1 * this.Width / 32) * 4;
      const currentRowSize = this.Source.length / this.Height;
      if (this.RowSize !== currentRowSize) {
        for (let y = 0; y < this.Height; y++) {
          let currentX = 0;
          for (currentX; currentX < currentRowSize; currentX++) {
            this.BmpSource.push(this.Source[y * currentRowSize + currentX]);
          }
          for (currentX; currentX < this.RowSize; currentX++) {
            this.BmpSource.push(0);
          }
        }
      } else {
        this.BmpSource = this.Source;
      }
      this.CreateHeader();
      //console.log(this.Data.length);
      // eslint-disable-next-line prefer-spread
      this.Data += String.fromCharCode.apply(String, this.BmpSource);
      const image = document.createElement('img');
      const dataString = btoa(this.Data);
      //console.log(dataString);
      image.src = 'data:image/bmp;base64,' + dataString;
      return image;
    }
  };
  image.InitImage();
  return image;
}

function toHexString(byteArray: number[], upper) {
  const string = Array.from(byteArray, function (byte) {
    return ('0' + (byte & 0xFF).toString(16)).slice(-2);
  }).join('');
  if (upper) {
    return string.toUpperCase();
  }
  return string;
}

function CreateEmptyBitmap() {
  const bitmap = {
    Data: [],
    Width: 0,
    Height: 0,
    Scale: function (width, height) {
      if (width % 8 !== 0) {
        width = width + 8 - width % 8;
      }
      if (height % 8 !== 0) {
        height = height + 8 - height % 8;
      }
      const newBitmap = CreateEmptyBitmap();
      newBitmap.Width = width;
      newBitmap.Height = height;
      const xRatio = this.Width / width;
      const yRatio = this.Height / height;
      for (let y = 0; y < newBitmap.Height; y++) {
        const row = [];
        const nearestY = Math.floor(y * yRatio);
        newBitmap.Data.push(row);
        for (let x = 0; x < newBitmap.Width; x++) {
          const nearestX = Math.floor(x * xRatio);
          row.push(this.Data[nearestY][nearestX]);
        }
      }
      return newBitmap;
    },
    RotateLeft: function () {
      const newBitmap = CreateEmptyBitmap();
      newBitmap.Width = this.Height;
      newBitmap.Height = this.Width;
      for (let y = 0; y < newBitmap.Height; y++) {
        const row = [];
        newBitmap.Data.push(row);
        const currentX = y;
        for (let x = 0; x < newBitmap.Width; x++) {
          row.push(this.Data[newBitmap.Width - 1 - x][currentX]);
        }
      }
      return newBitmap;
    },
    RotateRight: function () {
      const newBitmap = CreateEmptyBitmap();
      newBitmap.Width = this.Height;
      newBitmap.Height = this.Width;
      for (let y = 0; y < newBitmap.Height; y++) {
        const row = [];
        newBitmap.Data.push(row);
        const currentX = this.Width - 1 - y;
        for (let x = 0; x < newBitmap.Width; x++) {
          row.push(this.Data[x][currentX]);
        }
      }
      return newBitmap;
    },
    PrintBitmap: function () {
      let string = "";
      this.Data.forEach(row => {
        row.forEach(column => {
          if (column) {
            string += "1";
          } else {
            string += "0";
          }
        });
        string += "\r\n";
      });
      console.log(string);
    },
    GenerateImage: function () {
      const result = [];
      for (let y = 0; y < this.Height; y++) {
        for (let x = 0; x < this.Width; x += 8) {
          let byte = 0;
          const data1 = this.Data[y][x];
          const data2 = this.Data[y][x + 1];
          const data3 = this.Data[y][x + 2];
          const data4 = this.Data[y][x + 3];
          const data5 = this.Data[y][x + 4];
          const data6 = this.Data[y][x + 5];
          const data7 = this.Data[y][x + 6];
          const data8 = this.Data[y][x + 7];
          if (data1) {
            byte += 1 << 7;
          }
          if (data2) {
            byte += 1 << 6;
          }
          if (data3) {
            byte += 1 << 5;
          }
          if (data4) {
            byte += 1 << 4;
          }
          if (data5) {
            byte += 1 << 3;
          }
          if (data6) {
            byte += 1 << 2;
          }
          if (data7) {
            byte += 1 << 1;
          }
          if (data8) {
            byte += 1;
          }
          result.push(byte);
        }
      }
      //this.PrintBitmap();
      return CreateMonochromeBitmap(result, this.Width, this.Height);
    },
    InitFromImage: function (parent) {
      this.Data = [];
      this.Width = parent.Width;
      this.Height = parent.Height;
      for (let y = 0; y < parent.Height; y++) {
        let row = [];
        this.Data.push(row);
        for (let x = 0; x < parent.Width / 8; x++) {
          const element = parent.Source[y * (parent.Width / 8) + x];
          const val1 = (element & 0b10000000) !== 0;
          const val2 = (element & 0b01000000) !== 0;
          const val3 = (element & 0b00100000) !== 0;
          const val4 = (element & 0b00010000) !== 0;
          const val5 = (element & 0b00001000) !== 0;
          const val6 = (element & 0b00000100) !== 0;
          const val7 = (element & 0b00000010) !== 0;
          const val8 = (element & 0b00000001) !== 0;
          row.push(val1);
          row.push(val2);
          row.push(val3);
          row.push(val4);
          row.push(val5);
          row.push(val6);
          row.push(val7);
          row.push(val8);
        }
        row = null;
      }
    }
  };
  return bitmap;
}
