import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarRef } from "@angular/material/snack-bar";
import { ExtensionDto } from "src/ajs/Models/Phone3cx/ExtensionDto";
import { SignalRService } from "src/app/core/services/signalr/signalr.service";
import { UserService } from "src/app/core/services/user.service";
import { InCallSnackbarComponent } from "../components/in-call-snackbar/in-call-snackbar.component";
import { PhoneSnackbarData } from "../dto/phone-snackbar-data";

@Injectable({
  providedIn: 'root'
})

export class PhoneSnackbarService {
  private currentlyInCall: boolean;
  private inCallSnackbar: MatSnackBarRef<InCallSnackbarComponent> = null;

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly signalRService: SignalRService,
    private readonly userService: UserService,
  ) {
  }

  initialize() {
    this.signalRService.extensionsChanged.subscribe(this.extensionChanged.bind(this));
  }

  private extensionChanged(extensions: ExtensionDto[]) {
    const myExtension = extensions.find(f => f.Extension && f.Extension === this.userService.currentUser.phoneExtension);

    if (!myExtension) {
      this.dismissSnackbar();
      return;
    }

    if (myExtension.IsInCall !== this.currentlyInCall) {
      this.currentlyInCall = myExtension.IsInCall;

      if (!this.currentlyInCall) {
        this.dismissSnackbar();
      }
      else {
        this.dismissSnackbar();
        const snackBarData: PhoneSnackbarData = {
          myExtension: myExtension,
        };

        this.inCallSnackbar = this.snackBar.openFromComponent(InCallSnackbarComponent,
          {
            data: snackBarData,
            horizontalPosition: 'right'
          });
      }
    }
  }

  private dismissSnackbar() {
    if (this.inCallSnackbar) {
      const currentSnackbar = this.inCallSnackbar;
      this.inCallSnackbar = null;

      setTimeout(() => currentSnackbar.dismiss(), 2000);
    }
  }
}
