import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { SageExportContractQueueDialogComponent } from 'src/app/sage-export/components/sage-export-contract-queue-dialog/sage-export-contract-queue-dialog.component';
import { XmlService } from '../../services/xml.service';
import { ReadXmlResultDto } from '../../dto/read-xml-result-dto';
import { ReadXmlResultItemDto } from '../../dto/read-xml-result-item-dto';
import { ContractService } from 'src/app/contracts/services/contract.service';
import { ContractTypeDto } from 'src/app/contracts/dto/contract-type-dto';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-xml-import-dialog',
  templateUrl: './xml-import-dialog.component.html',
  styleUrls: ['./xml-import-dialog.component.scss']
})
export class XmlImportDialogComponent implements OnInit {

  currentFile: File = null;
  readFile: ReadXmlResultDto = null;
  contractTypes: ContractTypeDto[];


  constructor(
    private readonly dialogRef: MatDialogRef<SageExportContractQueueDialogComponent>,
    private readonly xmlService: XmlService,
    private readonly contractService: ContractService
  ) { }

  async ngOnInit() {

    this.contractTypes = await firstValueFrom(this.contractService.getContractTypes());
  }

  closeDialog() {
    this.dialogRef.close(true);
  }


  fileChange(event) {
    this.currentFile = event.target.files[0];

    this.readXmlFile(this.currentFile);
    event.target.value = null;
  }

  fileDrop(files: FileList) {
    this.currentFile = files[0];
    this.readXmlFile(this.currentFile);
  }


  async readXmlFile(file: File) {
    const result = await firstValueFrom(this.xmlService.readXmlFile(file));

    if (result) {
      for (const item of result.items) {
        item.contractTypeControl = new FormControl<number>(null);
        item.contractAccountableControl = new FormControl<boolean>(item.contract?.accountable);
      }

      this.readFile = result;
    }
  }

  async assignContractType(item: ReadXmlResultItemDto) {
    await firstValueFrom(this.xmlService.assignContractType(item.item.description, item.contractTypeControl.value));
    await this.readXmlFile(this.currentFile);
  }

  getXmlContractTypeDescription(item: ReadXmlResultItemDto) {
    const splitDescription = item.item.description.split("(");

    let typeDescription = splitDescription[0].trim();

    if (typeDescription.includes(":")) {
      typeDescription = typeDescription.split(":")[1].trim();
    }

    return typeDescription;
  }

  async updateContractValidUtilDate(item: ReadXmlResultItemDto) {
    await firstValueFrom(this.xmlService.updateContractValidUtilDate(item.contract.contractId, item.item.toDate));
    await this.readXmlFile(this.currentFile);
  }

  async updateContractAccountable(item: ReadXmlResultItemDto) {
    await firstValueFrom(this.xmlService.updateContractAccountableDates(item.contract.contractId, item.contractAccountableControl.value));
    await this.readXmlFile(this.currentFile);
  }

  isSameValidUntilDate(item: ReadXmlResultItemDto) {
    if (item.contract.dueDate.getDate() === item.item.toDate.getDate()) {
      return true;
    }
    else {
      return false;
    }
  }

}
