import angular from "angular";

export function createFileDropzoneDirective() {
  return {
    restrict: 'A',
    link: fileDropzoneLink,
    scope: {
      onFileDrop: '&',
      fileDropDisabled: '='
    }
  };

  function fileDropzoneLink($scope, element: angular.IAugmentedJQuery, attrs: angular.IAttributes) {
    $scope.counter = 0;
    element.css({ position: 'relative' });
    element.bind('dragover', processDragOverOrEnter);
    element.bind('dragenter', dragenter);
    element.bind('dragend', endDragOver);
    element.bind('dragleave', dragleave);
    //element.bind('mouseleave', endDragOver);
    let dragCounter = 0;
    element.bind('drop', dropHandler);

    function dropHandler(angularEvent) {
      if (!$scope.fileDropDisabled) {
        const event = angularEvent.originalEvent || angularEvent;
        if (event.dataTransfer) {
          const isFile = event.dataTransfer.files.length;
          let file = null;
          let files = null;
          if (isFile) {
            file = event.dataTransfer.files[0];
            files = event.dataTransfer.files;
          } else {
            if (event.dataTransfer.items.length) {
              file = event.dataTransfer.items[0].getAsFile();
              const string = event.dataTransfer.items[0].getAsString(function (s) {
                alert(s);
              });
              files = [];
            }
          }
          event.preventDefault();
          if (file) {
            $scope.onFileDrop({ file: file, files: files });
          }
          removeOverlay();
          dragCounter = 0;
        }
      }
    }

    function endDragOver() {
      if (!$scope.fileDropDisabled) {
        removeOverlay();
        dragCounter = 0;
      }
    }

    function processDragOverOrEnter(angularEvent) {
      if (!$scope.fileDropDisabled) {
        const event = angularEvent.originalEvent || angularEvent;
        if (event) {
          event.preventDefault();
        }
        addOverlay();
        event.dataTransfer.dropEffect = "copy";
        return false;
      }
      return undefined;
    }

    function dragenter(angularEvent) {
      if (!$scope.fileDropDisabled) {
        const event = angularEvent.originalEvent || angularEvent;
        if (event) {
          event.preventDefault();
          dragCounter++;
          addOverlay();
        }
      }
    }

    function dragleave(angularEvent) {
      if (!$scope.fileDropDisabled) {
        const event = angularEvent.originalEvent || angularEvent;
        if (event) {
          event.preventDefault();
          dragCounter--;
          if (dragCounter === 0) {
            removeOverlay();
          }
        }
      }
    }

    function addOverlay() {
      if (!$scope.fileDropDisabled) {
        if (!element['hasOverlay']) {
          element.addClass("isDragging");
          element['draggingElement'] = element.append("<div class='dragging'></div>");
          element['hasOverlay'] = true;
        }
      }
    }

    function removeOverlay() {
      if (!$scope.fileDropDisabled) {
        if (element['hasOverlay']) {
          element.removeClass("isDragging");
          element.children(".dragging").remove();
          element['hasOverlay'] = false;
        }
      }
    }
  }
}
