import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { RouteParamsAjs } from 'src/app/ajs-upgraded-providers';
import { UserService as UserServiceAjs } from '../../../../ajs/Services/UserService';
import { AppConfigService } from '../../services/app-config.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  private readonly destroy$ = new Subject<void>();
  isLoadingUserAuth = false;
  isLoadingWindowsAuth = false;
  readonly formGroup: FormGroup<{
    username: FormControl<string>,
    password: FormControl<string>,
    code: FormControl<string>
  }>;
  userAuthError = '';
  windowsAuthError = '';
  private readonly $routeParams: angular.route.IRouteParamsService;
  private redirect: string;

  get isLoading() {
    return this.isLoadingUserAuth || this.isLoadingWindowsAuth;
  }

  constructor(
    public readonly appConfig: AppConfigService,
    private readonly formBuilder: FormBuilder,
    private readonly userService: UserService,
    private readonly userServiceAjs: UserServiceAjs,
    private readonly location: Location,
    $routeParams: RouteParamsAjs
  ) {
    this.$routeParams = $routeParams as angular.route.IRouteParamsService;

    this.formGroup = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
      code: ['']
    });

    this.formGroup.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => this.clearErrors());
  }

  ngOnInit() {
    this.redirect = decodeURIComponent(this.$routeParams['redirect'] ?? '');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async loginUserAuth() {
    this.formGroup.markAllAsTouched(); // Validierung triggern
    if (this.formGroup.invalid) {
      return;
    }

    this.clearErrors();
    this.isLoadingUserAuth = true;
    this.formGroup.disable();

    try {
      await firstValueFrom(this.userService.loginLegacy(
        this.formGroup.value.username,
        this.formGroup.value.password,
        this.formGroup.value.code
      ));
      this.afterLoginSuccess();
    }
    catch (err) {
      console.log(err);
      this.isLoadingUserAuth = false;
      this.formGroup.enable();
      this.userAuthError = "Die Anmeldung ist fehlgeschlagen.";
    }
  }

  async loginWindowsAuth() {
    this.clearErrors();
    this.isLoadingWindowsAuth = true;
    this.formGroup.disable();

    try {
      await firstValueFrom(this.userService.loginWindowsAuth());
      this.afterLoginSuccess();
    }
    catch (err) {
      console.log(err);
      this.isLoadingWindowsAuth = false;
      this.formGroup.enable();
      this.windowsAuthError = "Die Anmeldung ist fehlgeschlagen.";
    }
  }

  private clearErrors() {
    this.userAuthError = '';
    this.windowsAuthError = '';
  }

  private async afterLoginSuccess() {
    await Promise.all([
      this.userServiceAjs.Load(),
      this.userService.reloadUserInfo()
    ]);

    if (this.redirect) {
      this.location.go(this.redirect);
    }
    else {
      this.location.go('/');
    }
  }
}
