<div class="main-container">
  <div class="toolbar">
    <div class="toolbar-header">Abrechnungsbelege</div>

    <div>
      <mat-slide-toggle [formControl]="showToggle" class="toolbar-margin">0-Belege ausblenden</mat-slide-toggle>

      <button mat-stroked-button (click)="columnEditor.showEditor()">
        <mat-icon>view_column</mat-icon> Spalten
      </button>

      <mat-paginator [length]="totalCount" [pageSize]="25" hidePageSize [showFirstLastButtons]="true"></mat-paginator>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="content-container">
    <table
      mat-table
      class="axs-table dense task-table"
      [dataSource]="dataSource"
      matSort
      [matSortActive]="sortService.active"
      [matSortDirection]="sortService.direction"
      (matSortChange)="sortService.change($event)"
      matSortDisableClear>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Belegbezeichnung </th>
        <td mat-cell *matCellDef="let row" [appCellLink]="'/task-receipt/edit/?receiptId=' + row.TaskReceiptId">{{row.Name}} </td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Addresse/Kunde </th>
        <td mat-cell *matCellDef="let row" [appCellLink]="'/task-receipt/edit/?receiptId=' + row.TaskReceiptId ">{{row.Address}} </td>
      </ng-container>

      <ng-container matColumnDef="accountedTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Abgerechnet </th>
        <td mat-cell *matCellDef="let row" [appCellLink]="'/task-receipt/edit/?receiptId=' + row.TaskReceiptId ">
          <span *ngIf="row.AccountedTime">{{row.AccountedTime + " h"}} </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="accountingPeriodEnd">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Stichtag </th>
        <td mat-cell *matCellDef="let row" [appCellLink]="'/task-receipt/edit/?receiptId=' + row.TaskReceiptId "> {{row.AccountingPeriodEnd |date:'dd.MM.yyyy'}}</td>
      </ng-container>

      <ng-container matColumnDef="totalTime">
        <th mat-header-cell *matHeaderCellDef> Gesamtzeit </th>
        <td mat-cell *matCellDef="let row" [appCellLink]="'/task-receipt/edit/?receiptId=' + row.TaskReceiptId "> {{row.TotalTimeDuration| duration}}</td>
      </ng-container>

      <ng-container matColumnDef="timeEntries">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Zeiteinträge </th>
        <td mat-cell *matCellDef="let row" [appCellLink]="'/task-receipt/edit/?receiptId=' + row.TaskReceiptId "> {{row.TimeEntryDuration| duration}}</td>
      </ng-container>

      <ng-container matColumnDef="taskCalls">
        <th mat-header-cell *matHeaderCellDef> Task Anrufe </th>
        <td mat-cell *matCellDef="let row" [appCellLink]="'/task-receipt/edit/?receiptId=' + row.TaskReceiptId "> {{row.TaskCallDuration| duration}} </td>
      </ng-container>

      <ng-container matColumnDef="otherCalls">
        <th mat-header-cell *matHeaderCellDef> Andere Anrufe </th>
        <td mat-cell *matCellDef="let row" [appCellLink]="'/task-receipt/edit/?receiptId=' + row.TaskReceiptId "> {{row.OtherCallDuration| duration}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnEditor.displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: columnEditor.displayedColumns;"></tr>
    </table>

    <app-table-nodata-indicator [noData]="dataSource?.data.length === 0"></app-table-nodata-indicator>

    <app-loading-overlay [isLoading]="isLoading" overlay="light"></app-loading-overlay>
  </div>


</div>