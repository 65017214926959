import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { Subject } from "rxjs";

@Injectable()
export class HestiaMatPaginatorIntl implements MatPaginatorIntl {
  readonly changes = new Subject<void>();

  firstPageLabel = 'Erste Seite';
  itemsPerPageLabel = 'Einträge pro Seite:';
  lastPageLabel = 'Letzte Seite';
  nextPageLabel = 'Nächste Seite';
  previousPageLabel = 'Voherige Seite';

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (!length) {
      return '0 von 0';
    }

    const from = page * pageSize + 1;
    const to = ((page + 1) * pageSize) > length ? length : ((page + 1) * pageSize);

    return `${from}-${to} von ${length}`;
  }
}
