export interface UserDto {
  id: string;
  firstName: string;
  lastName: string;
  displayName: string;
  mobilePhone: string;
  stationaryPhone: string;
  businessPhone: string;
  fax: string;
  companyName: string;
  department: string;
  position: string;
  website: string;
  userName: string;
  eMail: string;
  lastActivity: Date;
  isDisabled: boolean;
  mandatorId: string;
  selectedMandatorId: string | null;
}

export function fixUserDto(dto: UserDto) {
  if (dto) {
    dto.lastActivity = new Date(dto.lastActivity);
  }
}

