import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { SimpleUserDto } from 'src/ajs/Models/User/SimpleUserDto';
import { Api1Service } from 'src/app/core/services/api1.service';
import { fixSystembuilderServiceReportDto, SystembuilderServiceReportDto } from '../dto/systembuilder-service-report-dto';

const controller = "Systembuilder";

@Injectable({
  providedIn: 'root'
})
export class SystembuilderService {

  constructor(
    private readonly api: Api1Service,

  ) { }

  saveSystembuilderServiceReport(dto: SystembuilderServiceReportDto) {
    return this.api.post<SystembuilderServiceReportDto>(controller, "SaveSystemServiceReport", null, dto)
      .pipe(tap(x => fixSystembuilderServiceReportDto(x)));
  }

  getEmployees() {
    return this.api.get<SimpleUserDto[]>(controller, "GetEmployees");
  }

  getSystembuilderServiceReports(identCode: string) {
    return this.api.get<SystembuilderServiceReportDto[]>(controller, "GetSystemServiceReports", { identCode: identCode })
      .pipe(tap(x => x.forEach(fixSystembuilderServiceReportDto)));
  }

  deleteSystemServiceReport(id: number) {
    return this.api.post<void>(controller, "DeleteSystemServiceReport", { id: id });
  }

}
