import { ValidatorFn } from "@angular/forms";
import { Tag } from "./tag";

export function tagUnique(tags: Tag[]): ValidatorFn {
  return (control => {

    // id aus der FormGroup holen, damit der Eintrag nicht mit sich selbst validiert wird.
    const id = <number>control.parent?.value?.id ?? 0;

    if (!control.value) {
      return null;
    }

    if (!tags) {
      return null;
    }

    if (tags.some(x => x.name.toLowerCase() === (<string>control.value).toLowerCase() && x.id !== id)) {
      return { "tagUnique": "Der Name existiert bereits." };
    }

    return null;
  });
}
