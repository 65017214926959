import angular, { IScope } from "angular";
import { ApiHandlerService } from "../Services/ApiHandlerService";
import { LocalizationService, PluginDict } from "../Services/Localization/LocalizationService";


createAddDiskAlertController.$inject = ['$scope', '$mdDialog', 'ApiHandlerService', 'LocalizationService', 'Partition'];

export function createAddDiskAlertController(
  $scope: IAddDiskAlertControllerScope,
  $mdDialog: angular.material.IDialogService,
  ApiHandlerService: ApiHandlerService,
  LocalizationService: LocalizationService,
  Partition: any,
) {
  $scope.Partition = Partition;
  $scope.Localization = LocalizationService.GetPluginDict("Monitoring");
  $scope.Cancel = function () {
    $mdDialog.hide();
  };

  $scope.SaveDiskAlertSettings = function () {
    ApiHandlerService.SendRequest("Monitoring", "SaveDiskAlertSettings", { partitionId: $scope.Partition.Id, warningLevel: $scope.Partition.DiskThresholdWarning, errorLevel: $scope.Partition.DiskThresholdError, mute: $scope.Partition.Mute }).then(function (data) {
      $mdDialog.hide();
    });
  };
}

interface IAddDiskAlertControllerScope extends IScope {
  Partition: any
  Localization: PluginDict

  Cancel: () => void;
  SaveDiskAlertSettings: () => void;

}
