/** Diese fixDate-Funktion kann sowohl mit JSON-Dates aus ASP.net MVC als auch .NET Core umgehen. */
export function fixDate(value: any): Date {
  if (!value) {
    return value;
  }

  if (typeof value === "string") {
    if (value.startsWith("/Date(")) {
      const num: number = parseInt(value.substring(6, value.indexOf(')')));
      return new Date(num);
    }
  }
  return new Date(value);
}
