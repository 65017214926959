import { SimpleUserDto } from "src/ajs/Models/User/SimpleUserDto";
import { fixDate } from "src/ajs/Utils/DateUtils";

export interface SystembuilderServiceReportDto {
  Id: number;
  SystemIdentcode: string;
  EmployeeId: string;
  Employee: SimpleUserDto;
  WorkDate: Date;
  WorkTime: number;
  Description: string;
  Work: string;
}

export function fixSystembuilderServiceReportDto(dto: SystembuilderServiceReportDto) {

  if (dto.WorkDate) {
    dto.WorkDate = fixDate(dto.WorkDate);
  }
}
