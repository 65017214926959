import { Component, Inject, OnInit } from '@angular/core';
import { SageExportContractQueueDialogComponent } from '../sage-export-contract-queue-dialog/sage-export-contract-queue-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SageExportService } from '../../services/sage-export.service';
import { firstValueFrom } from 'rxjs';
import { MatTableDataSourceEx } from 'src/app/shared/utils/mat-table-data-source-ex';
import { SageExportContractGroupErrorDto } from '../../dto/sage-export-contract-group-error-dto';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { MandatorService } from 'src/app/core/services/mandator.service';

@Component({
  selector: 'app-sage-export-contract-error-dialog',
  templateUrl: './sage-export-contract-error-dialog.component.html',
  styleUrls: ['./sage-export-contract-error-dialog.component.scss']
})
export class SageExportContractErrorDialogComponent implements OnInit {

  dataSource = new MatTableDataSourceEx<SageExportContractGroupErrorDto>();

  displayedColumns = ["sageExportContractGroupId", "belegID", "artikelnummer", "fehlertext", "delete"];
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    private readonly dialogRef: MatDialogRef<SageExportContractQueueDialogComponent>,
    private readonly sageExportService: SageExportService,
    private readonly dialogService: DialogService,
    private readonly mandatorService: MandatorService

  ) { }

  async ngOnInit() {
    if (this.data.queueId) {
      this.dataSource.data = await firstValueFrom(this.sageExportService.getImportErrors(this.data.queueId, this.mandatorService.selectedMandatorId));
    }

  }

  closeDialog() {
    this.dialogRef.close();
  }

  async delete(errorDto: SageExportContractGroupErrorDto) {
    if (await this.dialogService.showConfirmation("EPCBeleg wirklich löschen?", null, "Löschen", "Abbrechen")) {

      await firstValueFrom(this.sageExportService.deleteEPCBeleg(errorDto, this.mandatorService.selectedMandatorId));
      this.dialogRef.close(true);
    }
  }

}
