<app-loading-overlay [isLoading]="isLoading" overlay="light"></app-loading-overlay>


<div class="dialog-container">

  <div mat-dialog-title class="dialog-title"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle>

    <div>
      <h2 *ngIf="isReady && !contract.contractId">Neuen Vertrag anlegen</h2>
      <h2 *ngIf="isReady && contract.contractId">Vertrag #{{contract.contractId}} {{getContractType(formGroup.controls.contractTypeId.value) }} anzeigen/bearbeiten </h2>
    </div>

    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>

  </div>

  <mat-dialog-content class="mat-typography">

    <mat-tab-group [(selectedIndex)]="selectedTabIndex">

      <mat-tab label="Stammdaten">
        <form [formGroup]="formGroup">

          <div class="flex-container">

            <mat-form-field class="flex">
              <mat-label>Adresse</mat-label>
              <mat-select formControlName="addressId">
                <mat-option>
                  <ngx-mat-select-search [formControl]="addressSearch"></ngx-mat-select-search>
                </mat-option>

                <mat-option *ngFor="let address of filteredAddresses$| async" [value]="address.erpCachedAddressId">{{address.cachedDisplayName}}</mat-option>
              </mat-select>
            </mat-form-field>

            <div>
              <mat-slide-toggle formControlName="hasFaktura">Faktura</mat-slide-toggle>
            </div>

            <div>
              <mat-slide-toggle formControlName="cancelled">Storniert</mat-slide-toggle>
            </div>

            <div>
              <mat-slide-toggle formControlName="account">Zu verrechnen</mat-slide-toggle>
            </div>

          </div>

          <div class="flex-container">

            <mat-form-field class="flex">
              <mat-label>Vertragsgruppe</mat-label>
              <mat-select formControlName="contractTypeId">
                <mat-option *ngFor="let type of contractTypes" [value]="type.contractTypeId">{{type.description}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="flex">
              <mat-label>Intervall(Monate)</mat-label>
              <input matInput type="number" (wheel)="$event.preventDefault()" formControlName="intervall">
            </mat-form-field>

            <div class="date-container">
              <mat-form-field class="date-box">
                <mat-label>Gültig bis</mat-label>
                <input matInput [matDatepicker]="validDatePicker" formControlName="validDate" (keyup.enter)="parseDate($event,formGroup.controls.validDate)"
                  (focusout)="parseDate($event, formGroup.controls.validDate)">
                <mat-datepicker-toggle matSuffix [for]="validDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #validDatePicker></mat-datepicker>
              </mat-form-field>

              <span class="last-date-text">Davor: {{ getLastValidDate()| date}} </span>

            </div>

          </div>

          <div class="flex-container">
            <mat-form-field class="flex">
              <mat-label>Titel</mat-label>
              <input matInput type="text" formControlName="title" [matAutocomplete]="titleAuto">

              <mat-autocomplete autoActiveFirstOption #titleAuto="matAutocomplete">
                <mat-option *ngFor="let title of filteredTitles$ | async" [value]="title">
                  {{title}}
                </mat-option>
              </mat-autocomplete>

            </mat-form-field>

            <div class="date-container">
              <mat-form-field>
                <mat-label>Verechnet bis</mat-label>
                <input matInput [matDatepicker]="billingDatePicker" formControlName="billingDate" (keyup.enter)="parseDate($event, formGroup.controls.billingDate)"
                  (focusout)="parseDate($event, formGroup.controls.billingDate)">
                <mat-datepicker-toggle matSuffix [for]="billingDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #billingDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div class="flex-container">
            <mat-form-field class="flex">
              <mat-label>Externe Vertragsnummer</mat-label>
              <input matInput type="text" formControlName="externalContractNumber">
            </mat-form-field>
          </div>

          <div class="flex-container">
            <mat-form-field class="flex">
              <mat-label>Beschreibung</mat-label>
              <textarea matInput formControlName="description" cdkTextareaAutosize
                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="10"></textarea>
            </mat-form-field>
          </div>

        </form>
      </mat-tab>

      <mat-tab label="Faktura" *ngIf="formGroup.controls.hasFaktura.value">

        <div class="faktura-header">

          <div>
            <span class="faktura-header-box">{{getAdress(formGroup.controls.addressId.value)}}</span>
            <span class="faktura-header-box">{{ formGroup.controls.title.value }}</span>
            <span class="faktura-header-box"> {{ formGroup.controls.description.value }} </span>
            <span class="faktura-header-box">{{formGroup.controls.externalContractNumber.value}}</span>
          </div>

          <div class="faktura-header-box">
            <mat-slide-toggle [formControl]="showIntervallSum">Intervallsumme</mat-slide-toggle>
          </div>

        </div>

        <mat-divider></mat-divider>

        <div class="table-container">

          <table mat-table [dataSource]="dataSource" class="axs-table dense" id="table" multiTemplateDataRows>

            <ng-container matColumnDef="search">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let row" class="td-remove-border">
                <button mat-icon-button (click)="openArticleSearchDialog(row)" matTooltip="Artikelsuche"><mat-icon>search</mat-icon></button>
              </td>

              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="articleNumber.value">
              <th mat-header-cell *matHeaderCellDef>Artikelnummer</th>
              <td mat-cell *matCellDef="let row" class="td-remove-border">
                <mat-form-field class="faktura-form-field">
                  <input matInput type="text" [formControl]="row.articleNumber" (keyup.enter)="getArticleByArticleNumber(row)" class="numberInput ">
                </mat-form-field>
              </td>

              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="articleDescription1.value">
              <th mat-header-cell *matHeaderCellDef>Bezeichnung 1</th>
              <td mat-cell *matCellDef="let row" class="td-remove-border">
                <mat-form-field class="faktura-form-field">
                  <input type="text" matInput [formControl]="row.articleDescription1">
                </mat-form-field>
              </td>

              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="articleDescription2.value">
              <th mat-header-cell *matHeaderCellDef>Bezeichnung 2</th>
              <td mat-cell *matCellDef="let row" class="td-remove-border">
                <mat-form-field class="faktura-form-field">
                  <input type="text" matInput [formControl]="row.articleDescription2">
                </mat-form-field>

              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="articleExpressionText.value">
              <th mat-header-cell *matHeaderCellDef>Ausprägung</th>
              <td mat-cell *matCellDef="let row" class="td-remove-border">
                <mat-form-field class="faktura-form-field">
                  <input type="text" matInput [formControl]="row.articleExpressionText">
                </mat-form-field>
              </td>

              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="amount.value">
              <th mat-header-cell *matHeaderCellDef>Anzahl</th>
              <td mat-cell *matCellDef="let row" class="td-remove-border">
                <mat-form-field class="faktura-form-field">
                  <input type="text" (wheel)="$event.preventDefault()" class="numberInput" matInput [formControl]="row.amount" (focusout)="updatePrice(row)">
                </mat-form-field>
              </td>

              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="price.value">
              <th mat-header-cell *matHeaderCellDef>Einzelpreis</th>
              <td mat-cell *matCellDef="let row" class="td-remove-border">
                <mat-form-field class="faktura-form-field">
                  <mat-checkbox matTooltip="Vertragsspezifischer Preis" [formControl]="row.isContractPrice" class="price-checkbox" (change)="updatePrice(row)"></mat-checkbox>
                  <input type="text" (wheel)="$event.preventDefault()" class="numberInput" matInput [formControl]="row.price" [readonly]="!row.isContractPrice.value">
                </mat-form-field>
              </td>


              <td mat-footer-cell *matFooterCellDef class="total-sum" [attr.colspan]="2">Gesamtsumme: </td>
            </ng-container>

            <ng-container matColumnDef="discount.value">
              <th mat-header-cell *matHeaderCellDef>Rabatt</th>
              <td mat-cell *matCellDef="let row" class="td-remove-border">
                <mat-form-field class="faktura-form-field">
                  <mat-checkbox matTooltip="Vertragsspezifischer Rabatt" [formControl]="row.isContractDiscount" class="price-checkbox" (change)="updatePrice(row)"></mat-checkbox>
                  <input type="number" (wheel)="$event.preventDefault()" class="numberInput" (wheel)="$event.preventDefault()" matInput [formControl]="row.discount" min="0" max="100"
                    [readonly]="!row.isContractDiscount.value">
                  <span matSuffix>%</span>
                </mat-form-field>
              </td>

              <td mat-footer-cell *matFooterCellDef class="total-sum"><span>{{getTotalSum()}}</span></td>
            </ng-container>

            <ng-container matColumnDef="sum">
              <th mat-header-cell *matHeaderCellDef>Summe</th>
              <td mat-cell *matCellDef="let row" class="td-remove-border">
                <mat-form-field class="faktura-form-field">
                  <input type="text" class="numberInput" matInput [readonly]="true" [value]="getSumFormated(row)">
                </mat-form-field>
              </td>

              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let row" class="td-remove-border">
              </td>

              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Langtext -->
            <ng-container matColumnDef="blank">
              <td mat-cell *matCellDef="let row">
              </td>
            </ng-container>

            <ng-container matColumnDef="sort">
              <td mat-cell *matCellDef="let row">

              </td>
            </ng-container>

            <ng-container matColumnDef="langtext">
              <td mat-cell [attr.colspan]="displayedColumns.length -2" *matCellDef="let row">
                <mat-form-field class="faktura-form-field faktura-form-field-textarea">
                  <mat-label>Langtext</mat-label>
                  <textarea class="faktura-textarea" type="text" matInput [formControl]="row.langtext" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1">
                  </textarea>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="langtextActions">
              <td mat-cell *matCellDef="let row">

                <div class="row-container">
                  <div class="faktura-sort-container">
                    <mat-icon (click)="sortUp(row)">
                      keyboard_arrow_up
                    </mat-icon>

                    <mat-icon (click)="sortDown(row)">
                      keyboard_arrow_down
                    </mat-icon>
                  </div>

                  <button mat-icon-button (click)="removeProduct(row)" matTooltip="Artikel entfernen"><mat-icon color="warn">delete</mat-icon></button>

                </div>
              </td>
            </ng-container>


            <!--Footer-->
            <ng-container matColumnDef="blankFooter">
              <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>

            <ng-container matColumnDef="intervallSumTitle">
              <td mat-footer-cell *matFooterCellDef class="total-sum" [attr.colspan]="2">Intervallsumme (* Monate): </td>
            </ng-container>

            <ng-container matColumnDef="intervallSum">
              <td mat-footer-cell *matFooterCellDef class="total-sum">{{getIntervallSum()}}</td>
            </ng-container>

            <ng-container matColumnDef="addArticle">
              <td mat-footer-cell *matFooterCellDef>
                <button mat-icon-button color="primary" (click)="addProduct()" matTooltip="Artikel hinzufügen">
                  <mat-icon>
                    add
                  </mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="addText">
              <td mat-footer-cell *matFooterCellDef>
                <button mat-icon-button color="primary" matTooltip="Text hinzufügen" (click)="addText()">
                  <mat-icon>
                    playlist_add
                  </mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="filler">
              <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length - 2"> </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns;  sticky:true;"></tr>

            <tr mat-row *matRowDef="let row; columns: displayedColumns; when: showArticleRow"></tr>
            <tr mat-row *matRowDef="let row; columns: ['blank',  'langtext',  'langtextActions']; "></tr>

            <tr mat-footer-row *matFooterRowDef="['addArticle', 'addText', 'filler']; sticky:true;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky:true;"></tr>

            <tr mat-footer-row class="table-row-footer-height"
              *matFooterRowDef="['blankFooter', 'blankFooter', 'blankFooter', 'blankFooter', 'blankFooter', 'blankFooter',   'intervallSumTitle', 'intervallSum', 'blankFooter'];  sticky:true;">
            </tr>

          </table>
        </div>

      </mat-tab>

      <div *ngFor=" let vm of contractDialogTabVms">
        <mat-tab [label]="vm.dto.title">
          <ng-template mat-tab-label>
            <button mat-icon-button (click)="openTabEditDialog(vm.dto)">
              <mat-icon color="warn">edit</mat-icon>
            </button>

            <span>{{vm.dto.title}}</span>
          </ng-template>

          <div appFileDragOver (filesDropped)="onFiledrop(vm.dto, $event)" class="attachments-drop-zone">

            <div class="flex-container tab-header">
              <span>Kommentare</span>

              <div *ngIf="!editNotes">
                <button mat-icon-button (click)="editNotes = true">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
            </div>

            <mat-divider></mat-divider>

            <div class="comment-box">
              <mat-form-field *ngIf="editNotes">
                <mat-label>Neuer Kommentar</mat-label>
                <textarea matInput
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="2"
                  [formControl]="vm.noteControl">
          </textarea>

              </mat-form-field>
            </div>

            <div class="comment-box" *ngFor="let note of vm.dto.notes">
              <div class="note-container" *ngIf="note">
                <mat-form-field>
                  <mat-label>
                    <b>{{note.created| date: "dd.MM.YYYY HH:mm"}} {{note.createdUser?.firstName + " " + note.createdUser?.lastName}}</b>
                    <span *ngIf="note.modified">
                      | bearbeitet <b>{{note.modified| date: "dd.MM.YYYY HH:mm"}} {{note.modifiedUser?.firstName + " " + note.modifiedUser?.lastName}}</b>
                    </span>
                  </mat-label>

                  <textarea matInput
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="2"
                    [readonly]="!editNotes"
                    [(ngModel)]="note.text">
           </textarea>

                </mat-form-field>

                <button mat-icon-button *ngIf="editNotes" (click)="removeNote(vm.dto, note)">
                  <mat-icon color="warn">delete</mat-icon>
                </button>
              </div>
            </div>

            <mat-divider></mat-divider>

            <div class="flex-container tab-header">
              <span>Dateien</span>
            </div>

            <mat-divider></mat-divider>

            <mat-list role="list" class="file-field">
              <mat-list-item *ngFor="let file of vm.dto.files" role="listitem">
                <div *ngIf="!file.file?.deleted" class="flex-container">

                  <div class="flex-15">
                    <span style="font-size: 1.8em" class="fiv-sqo fiv-icon-{{getExtension(file)}}"></span>
                  </div>

                  <div class="flex-40">
                    <div layout="row" *ngIf="file.storedFileId">
                      <a class="no-wrap" style="font-size: 12px;" target="_blank" href="/Api/FileDownload/Download/{{file.storedFileId}}"><b>{{file.file?.fileName}}</b></a>
                    </div>

                    <div class="no-wrap" style="font-size: 12px;" layout="row" *ngIf="!file.storedFileId">
                      <b>{{file.file?.fileName}}</b>
                    </div>

                    <div style="font-size: 10px;" class="no-wrap" layout="row">
                      <em>{{file.note}}</em>
                    </div>
                  </div>

                  <div class="data-text-box flex-30">
                    {{file.file?.created | date: "dd.MM.YYYY HH:mm"}}
                    <em>{{file.file?.createdUser?.firstName + " " + file.file?.createdUser?.lastName}}</em>
                  </div>

                  <div class="flex-15">
                    <button mat-icon-button (click)="openFileNoteDialog(file)"><mat-icon color="primary">note</mat-icon></button>
                    <button mat-icon-button (click)="removeFile(vm.dto, file)"><mat-icon color="warn">delete</mat-icon></button>
                  </div>

                </div>
              </mat-list-item>
            </mat-list>

            <mat-divider></mat-divider>

          </div>

        </mat-tab>
      </div>

    </mat-tab-group>

  </mat-dialog-content>

  <mat-dialog-actions>

    <div>
      <button mat-raised-button *ngFor="let title of suggestedTabTitles" (click)="tabButtonClick(title)" color="warn">{{title}}</button>
    </div>

    <div class="flex-container">
      <div class="new-tab">
        <mat-form-field>
          <mat-label>Neuer Reiter</mat-label>
          <input matInput type="text" [formControl]="newTabText">

        </mat-form-field>
        <button mat-icon-button (click)="tabButtonClick(newTabText.value, true)"><mat-icon>add</mat-icon></button>
      </div>

      <div class="action-buttons">
        <button mat-raised-button color="warn" [disabled]="!contract?.contractId" (click)="deleteContract()">Löschen</button>
        <button mat-raised-button color="primary" [disabled]="!formGroup.valid" (click)="saveContractAndCreateNew()">Speichern & Neu</button>
        <button mat-raised-button color="primary" [disabled]="!formGroup.valid" (click)="saveContract()">Speichern</button>
      </div>
    </div>

  </mat-dialog-actions>

</div>