import * as angular from 'angular';

export class DragAndDropPanel {
  Hue: number;
  Functions: DragAndDropFunction[] = [];

  constructor(
    public Title: string,
    public mdPanelRef: angular.material.IPanelRef,
    hue?: number) {
    this.Hue = hue ? hue : 800;
  }

  AddFunction(icon: string, callback: () => void, id?: string) {
    if (id) {
      if (!this.Functions.filter(f => f.Id === id).length) {
        this.Functions.unshift({ Icon: icon, Callback: callback, Id: id });
      }
    } else {
      this.Functions.push({ Icon: icon, Callback: callback });
    }
  }

  RemoveFunction(id: string) {
    this.Functions = this.Functions.filter(f => !f.Id || f.Id !== id);
  }
}

interface DragAndDropFunction {
  Id?: string;
  Icon: string;
  Callback: () => void;
}
