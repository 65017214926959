import * as angular from 'angular';

export class GlobalValueService {
  static $inject = [];
  private readonly dict: { [name: string]: any } = {};

  constructor() { }

  get(name: string): any {
    return this.dict[name] ?? null;
  }

  set(name: string, value: any) {
    this.dict[name] = value;
  }
}