<cp-searchbar [searchFormControl]="search">
  <cp-searchbar-buttons>

    <button mat-icon-button type="button" (click)="settingsButtonClick()" matTooltip="Einstellungen">
      <mdi-icon icon="cog-outline"></mdi-icon>
    </button>

    <mat-button-toggle-group class="small" [formControl]="showAll">
      <mat-button-toggle [value]="false">Ungelesene</mat-button-toggle>
      <mat-button-toggle [value]="true">Alle</mat-button-toggle>
    </mat-button-toggle-group>
  </cp-searchbar-buttons>
</cp-searchbar>

<div class="content-padding" fxLayout="column" fxFlex>
  <div class="table-bar" fxLayout="row" fxLayoutAlign="start center">
    <h1 fxFlex>Benachrichtigungen</h1>

    <button mat-raised-button color="primary" (click)="markAllAsRead()" [disabled]="!canMarkAllAsRead">Alle als gelesen markieren</button>

    <mat-paginator [length]="totalCount" [pageSize]="25" hidePageSize [showFirstLastButtons]="true"></mat-paginator>
  </div>

  <div fxFlex="1 0 200px" class="scroll">
    <table mat-table class="cp-table dense" [dataSource]="data" matSort matSortDisableClear [matSortActive]="sortService.active" [matSortDirection]="sortService.direction"
      (matSortChange)="sortService.change($event)">

      <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef disableClear></th>
        <td mat-cell *matCellDef="let row" (click)="markAsRead(row)" [appCellLink]="row.url" [cellLinkEnabled]="row.url" class="icon">
          <mdi-icon [icon]="row.icon" [style.color]="row.color ? row.color : '#00000099'"></mdi-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Titel</th>
        <td mat-cell *matCellDef="let row" (click)="markAsRead(row)" [appCellLink]="row.url" [cellLinkEnabled]="row.url">
          <div class="description-container" [innerHtml]="row.title"></div>
        </td>
      </ng-container>

      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Zeitpunkt</th>
        <td mat-cell *matCellDef="let row" (click)="markAsRead(row)" [appCellLink]="row.url" [cellLinkEnabled]="row.url">
          <span [matTooltip]="row.createdUtc | date:'dd.MM.yyyy HH:mm:ss'">{{row.createdUtc | amTimeAgo }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef disableClear></th>
        <td mat-cell *matCellDef="let row" (click)="markAsRead(row)">
          <button mat-stroked-button (click)="markAsRead(row)" *ngIf="!row.readUtc" matTooltip="Als gelesen markieren">
            <mat-icon>done</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.unread]="!row.readUtc"></tr>
    </table>

    <div *ngIf="!isLoading && !totalCount && !search.value" class="no-notifications">
      <mdi-icon icon="bell-outline"></mdi-icon>
      <span>Keine Benachrichtigungen</span>
    </div>

    <app-table-nodata-indicator [isLoading]="isLoading" [noData]="!totalCount && search.value"></app-table-nodata-indicator>
  </div>
</div>
