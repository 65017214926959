import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { catchError, debounceTime, firstValueFrom, forkJoin, merge, of, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';
import { RemoteStorageService } from 'src/app/core/services/web-storage/remote-storage.service';
import { SortService, SORT_DEFAULT_ACTIVE, SORT_DEFAULT_DIRECTION, SORT_STORAGE_KEY } from 'src/app/shared/services/sort/sort.service';
import { GetNotificationsDto } from '../../dto/get-notifications-dto';
import { NotificationDto } from '../../dto/notification-dto';
import { NotificationService } from '../../services/notification.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  providers: [
    SortService,
    { provide: SORT_STORAGE_KEY, useValue: 'notification-list.sort' },
    { provide: SORT_DEFAULT_ACTIVE, useValue: 'time' },
    { provide: SORT_DEFAULT_DIRECTION, useValue: 'desc' }
  ]
})
export class NotificationListComponent implements AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  private readonly destroy$ = new Subject<void>();
  private readonly refresh$ = new Subject<void>();
  private readonly showAllKey = 'notification-list.showAll';
  readonly search = new UntypedFormControl("");
  totalCount: number = 0;
  data: NotificationDto[] = [];
  displayedColumns = ["icon", "title", "time", "action"];
  isLoading: boolean = false;
  readonly showAll = new UntypedFormControl(false);

  get canMarkAllAsRead(): boolean {
    return this.data?.length && this.data.some(x => !x.readUtc);
  }

  constructor(
    public readonly notificationService: NotificationService,
    private readonly userService: UserService,
    private readonly remoteStorage: RemoteStorageService,
    public readonly sortService: SortService,
    private readonly location: Location
  ) { }

  async ngAfterViewInit() {

    this.isLoading = true;

    const query = await firstValueFrom(forkJoin([
      this.remoteStorage.getBoolean(this.showAllKey),
      this.sortService.loaded$,
    ]));

    this.showAll.setValue(query[0]);

    merge(
      this.sort.sortChange,
      this.paginator.page,
      this.search.valueChanges.pipe(debounceTime(300)),
      this.showAll.valueChanges,
      this.refresh$
    ).pipe(
      takeUntil(this.destroy$),
      tap(() => this.isLoading = true),
      startWith({}),
      switchMap(() => this.notificationService.getNotifications(
        this.sort.active,
        this.sort.direction === "desc",
        this.paginator.pageSize,
        this.paginator.pageIndex,
        this.search.value as string,
        this.showAll.value
      ).pipe(
        catchError(err => {
          console.log(err);
          return of({ totalCount: 0, notifications: [] } as GetNotificationsDto);
        })
      ))
    ).subscribe(result => {
      this.isLoading = false;
      this.totalCount = result.totalCount;
      this.data = result.notifications;
    });

    this.showAll.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(showAll => {
      this.remoteStorage.setBoolean(this.showAllKey, showAll);
    });

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.refresh$.complete();
  }


  markAsRead(notification: NotificationDto) {
    if (notification.readUtc == null) {
      notification.readUtc = new Date();
      firstValueFrom(this.notificationService.markAsRead([notification.id]));
    }
  }

  async markAllAsRead() {
    const notificationsIds = [];

    for (const notification of this.data) {
      if (notification.readUtc == null) {
        notification.readUtc = new Date();
        notificationsIds.push(notification.id);
      }
    }
    firstValueFrom(this.notificationService.markAsRead(notificationsIds));
  }

  settingsButtonClick() {
    this.location.go("/notifications/settings");
  }
}
