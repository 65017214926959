import { fixDate } from "src/ajs/Utils/DateUtils";
import { LegacyUserDto } from "src/app/core/dto/legacy-user-dto";
import { ErpCachedAddressDto } from "src/app/erp/dto/erp-cached-address-dto-legacy";
import { fixPhone3CxCallDto, Phone3CxCallDto } from "src/app/phone/dto/phone-3cx-call-dto";
import { fixTaskNoteDto, TaskNoteDto } from "./task-note-dto";
import { TaskPriorityDto } from "./task-priority-dto";
import { TaskQueueDto } from "./task-queue-dto";
import { TaskStateDto } from "./task-state-dto";

export interface TaskEntryDto {
  Id: number;
  EnvironmentId: string;
  Title: string;
  Description: string;
  CreatedUserId: string;
  AssignedUserId: string | null;
  StateId: number;
  PriorityId: number;
  AddressId: number;
  TaskQueueId: number;
  CreatedDate: Date;
  DueDate: Date | null;
  ModifiedDate: Date;
  CreatedUser: LegacyUserDto;
  AssignedUser: LegacyUserDto;
  State: TaskStateDto;
  Priority: TaskPriorityDto;
  Address: ErpCachedAddressDto;
  TaskQueue: TaskQueueDto;
  Notes: TaskNoteDto[];

}

export function fixTaskEntryDto(dto: TaskEntryDto) {
  if (dto.CreatedDate) {
    dto.CreatedDate = fixDate(dto.CreatedDate);
  }
  if (dto.ModifiedDate) {
    dto.ModifiedDate = fixDate(dto.ModifiedDate);
  }
  if (dto.DueDate) {
    dto.DueDate = fixDate(dto.DueDate);
  }

  if (dto.Notes) {
    dto.Notes.forEach(fixTaskNoteDto);
  }

}
