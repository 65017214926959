import angular from "angular";

createErpContactFlyoutController.$inject = ['$scope', 'mdPanelRef'];

export function createErpContactFlyoutController(
  $scope: ICreateErpContactFlyoutController,
  mdPanelRef: angular.material.IPanelRef
) {
  $scope.Close = function () {
    mdPanelRef.close();
  };
}

interface ICreateErpContactFlyoutController extends angular.IScope {

  Close: () => void;
}
