import { IQService, IScope, ITimeoutService, material } from "angular";
import { CategoryDto } from "src/ajs/Models/Document/CategoryDto";
import { NumberRangeDto } from "src/ajs/Models/Document/NumberRangeDto";
import { LabelPrintDataDto } from "src/ajs/Models/Label/LabelPrintDataDto";
import { SimpleStringResultDto } from "src/ajs/Models/SimpleStringResult";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { StorageService, StorageServiceInstance } from "src/ajs/Services/StorageService";
import { cloneObject } from "src/ajs/Utils/HestiaCommon";
import { LabelPrintPresetDto } from "../../Models/Document/LabelPrintPresetDto";
import { MandatorService } from "src/app/core/services/mandator.service";

export class PrintDocumentLabelDialogController {

  static $inject = ['$scope', '$timeout', '$q', '$mdDialog', '$mdToast', 'StorageService', 'ApiHandlerService', 'Categories', 'Presets', 'Printing', 'Ranges', 'MandatorServiceNgx'];
  constructor(
    private readonly $scope: IPrintDocumentLabelDialogControllerScope,
    private readonly $timeout: ITimeoutService,
    private readonly $q: IQService,
    private readonly $mdDialog: material.IDialogService,
    private readonly $mdToast: angular.material.IToastService,
    StorageService: StorageService,
    private readonly ApiHandlerService: ApiHandlerService,
    Categories: CategoryDto[],
    Ranges: NumberRangeDto[],
    Printing: LabelPrintDataDto,
    Presets: LabelPrintPresetDto[],
    private readonly mandatorServiceNgx: MandatorService
  ) {
    $scope.Title = "Dokument-Etiketten drucken";
    $scope.Presets = Presets;
    $scope.Categories = Categories;
    $scope.SelectedPreset = null;
    $scope.Storage = StorageService.CreateInstance("PrintDocumentLabelDialogController");
    $scope.Ranges = Ranges;
    $scope.Printing = Printing;
    $scope.Printing.PrintQuantity = 1;
    $scope.SelectedCategoryView = null;
    $scope.AllowedCategories = [];
    $scope.FilteredPresets = [];

    $scope.NavigateBack = this.NavigateBack.bind(this);
    $scope.SelectedCategory = this.SelectCategory.bind(this);
    $scope.Close = this.Close.bind(this);
    $scope.SelectedPrinterChanged = this.SelectedPrinterChanged.bind(this);
    $scope.ValidateForm = this.ValidateForm.bind(this);
    $scope.SearchAddress = this.SearchAddress.bind(this);
    $scope.SelectPreset = this.SelectPreset.bind(this);
    $scope.PrintLabels = this.PrintLabels.bind(this);
  }

  NavigateBack() {
    this.$scope.SelectedCategoryView = null;
  }

  SelectCategory(category: CategoryDto) {
    this.$scope.SelectedCategoryView = category;
    if (this.$scope.SelectedCategoryView) {
      this.$scope.FilteredPresets = this.$scope.Presets.filter(f => f.DocumentsCategoryId === this.$scope.SelectedCategoryView.Id);
    }
  }

  Close() {
    this.$mdDialog.hide();
  }

  SelectedPrinterChanged() {
    this.$scope.Storage.Set("SelectedPrinterId", this.$scope.Printing.SelectedPrinterId);
  }

  ValidateForm() {
    this.$timeout(() => {
      this.$scope.PrintDocumentLabelDialogForm.$$controls.forEach(c => {
        c.$setDirty();
        c.$setTouched();
        c.$validate();
      });
    });
  }

  SearchAddress(query: string) {
    var result = null;
    var deferred = this.$q.defer();
    var request = this.ApiHandlerService.SendRequest("Erp", "QueryAddress", { query: query, mandtorId: this.mandatorServiceNgx.selectedMandatorId }).then(data => {
      result = [];
      data.Result.forEach(f => {
        result.push(f.CachedAddress);
      });
      deferred.resolve(result);
    });
    return deferred.promise;
  }

  SelectPreset(preset: LabelPrintPresetDto) {
    this.$scope.SelectedPreset = preset;
    this.$scope.Printing.SourceAddress = preset.SourceAddress;
    this.$scope.Printing.TargetAddress = preset.TargetAddress;
    this.$scope.SelectedCategoryId = preset.DocumentsCategoryId;
    var range = this.$scope.Ranges.filter(f => f.Id === this.$scope.SelectedPreset.DocumentsNumberRangeId)[0];
    this.$scope.AllowedCategories = [];
    range.AllowedCategoryIds.forEach(id => {
      this.$scope.AllowedCategories.push(this.$scope.Categories.filter(f => f.Id === id)[0]);
    });
    this.$scope.ValidateForm();
  }

  PrintLabels() {
    if (!this.$scope.IsPrinting) {
      this.$scope.IsPrinting = true;
      var category = cloneObject(this.$scope.Categories.filter(f => f.Id === this.$scope.SelectedCategoryId)[0], ['Entries', 'TableDefinition']);
      this.ApiHandlerService.SendRequest("Document", "PrintLabels",
        {
          quantity: this.$scope.Printing.PrintQuantity,
          printerId: this.$scope.Printing.SelectedPrinterId,
          rangeId: this.$scope.SelectedPreset.DocumentsNumberRangeId,
          source: this.$scope.Printing.SourceAddress,
          target: this.$scope.Printing.TargetAddress,
          category: category,
          mandatorId: this.mandatorServiceNgx.selectedMandatorId
        }).then((data: SimpleStringResultDto) => {
          this.$scope.IsPrinting = false;
          var toast = this.$mdToast.simple()
            .textContent("Eitketten wurden gedruckt")
            .position('bottom right')
            .capsule(true);
          this.$mdToast.show(toast);
        });
    }
  }
}

interface IPrintDocumentLabelDialogControllerScope extends IScope {
  Title: string;
  Presets: LabelPrintPresetDto[];
  Categories: CategoryDto[];
  SelectedPreset: LabelPrintPresetDto;
  Storage: StorageServiceInstance;
  SelectedCategoryId: number;
  Ranges: NumberRangeDto[];
  Printing: LabelPrintDataDto;
  PrintQuantity: number;
  SelectedCategoryView: CategoryDto;
  AllowedCategories: CategoryDto[];
  FilteredPresets: LabelPrintPresetDto[];
  IsPrinting: boolean;
  PrintDocumentLabelDialogForm: any;

  NavigateBack: () => void;
  SelectedCategory: (category: CategoryDto) => void;
  SelectedPrinterChanged: () => void;
  Close: () => void;
  ValidateForm: () => void;
  SearchAddress: (query: string) => void;
  SelectPreset: (preset: LabelPrintPresetDto) => void;
  PrintLabels: () => void;

}
