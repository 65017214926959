import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageDialogData } from './message-dialog-data';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: MessageDialogData,
    private readonly dialogRef: MatDialogRef<MessageDialogComponent>
  ) { }

  action(num: number) {
    this.dialogRef.close(num);
  }



}
