import { AbstractControl, UntypedFormArray, UntypedFormControl } from "@angular/forms";

export class FormArrayUtils {

  static remove(array: UntypedFormArray, control: AbstractControl): boolean {
    if (!array || !control) {
      return false;
    }

    const index = FormArrayUtils.indexOf(array, control);
    if (index >= 0) {
      array.removeAt(index);
      return true;
    }
    return false;
  }

  static indexOf(array: UntypedFormArray, control: AbstractControl): number {
    if (!array) {
      return -1;
    }

    for (let i = 0; i < array.length; i++) {
      if (array.at(i) === control) {
        return i;
      }
    }
    return -1;
  }

  /** Hilfsfunktion zum Patchen eines FormArrays. */
  static patchValue(array: UntypedFormArray, elements: any[], controlFactory?: () => AbstractControl) {
    if (array) {
      array.clear();
      if (elements) {
        for (const element of elements) {
          const ctrl = controlFactory ? controlFactory() : new UntypedFormControl();
          ctrl.patchValue(element);
          array.push(ctrl);
        }
      }
    }
  }
}
