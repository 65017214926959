import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-task-edit-dialog',
  templateUrl: './task-edit-dialog.component.html',
  styleUrls: ['./task-edit-dialog.component.scss']
})
export class TaskEditDialogComponent {

  taskId: number = 0;
  callIds: number[] = [];

  constructor(
    private dialogRef: MatDialogRef<TaskEditDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data.taskId) {
      this.taskId = this.data.taskId;
    }
    if (this.data.callIds) {
      this.callIds = this.data.callIds;
    }
  }

  closeDialog() {
    this.dialogRef.close(true);
  }
}
