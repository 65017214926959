import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { catchError, filter, merge, of, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { CallAnalyticsAddressDto } from '../../dto/call-analytics-address-dto';
import { CallAnalyticsDto } from '../../dto/call-analytics-dto';
import { PhoneService } from '../../services/phone.service';

@Component({
  selector: 'app-call-analytics',
  templateUrl: './call-analytics.component.html',
  styleUrls: ['./call-analytics.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class CallAnalyticsComponent implements OnInit {

  data: CallAnalyticsDto = null;
  readonly date = new UntypedFormControl();
  private readonly destroy$ = new Subject<void>();
  private readonly refresh$ = new Subject<void>();
  isLoading = false;
  expandedAddresses: CallAnalyticsAddressDto[] = [];

  constructor(
    public readonly phoneService: PhoneService,
  ) {
    merge(
      this.date.valueChanges,
      this.refresh$
    ).pipe(
      takeUntil(this.destroy$),
      startWith({}),
      filter(() => this.date.value),
      tap(() => {
        this.data = null;
        this.isLoading = true;
      }),
      switchMap(() => this.phoneService.getCallAnalytics(new Date(this.date.value))),
      catchError(err => {
        console.log(err);
        return of({ Addresses: [], Extensions: [] });
      })
    ).subscribe(result => {
      this.data = result;
      this.isLoading = false;
      this.expandedAddresses = [];
    });
  }

  ngOnInit() {
    const thisMonth = new Date();
    thisMonth.setDate(1);
    this.date.setValue(thisMonth);
  }

  monthSelected(date: Date, datepicker: MatDatepicker<Date>) {
    this.date.setValue(date);
    datepicker.close();
  }

  isExpanded(address: CallAnalyticsAddressDto) {
    return this.expandedAddresses.indexOf(address) >= 0;
  }

  toggleDetail(address: CallAnalyticsAddressDto) {
    const index = this.expandedAddresses.indexOf(address);
    if (index >= 0) {
      this.expandedAddresses.splice(index, 1);
    }
    else {
      this.expandedAddresses.push(address);
    }
  }
}

