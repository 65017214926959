import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { NotificationNavbarComponent } from './components/notification-userbar/notification-userbar.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { SubscriptionButtonComponent } from './components/subscription-button/subscription-button.component';
import { NotificationSettingsComponent } from './components/notification-settings/notification-settings.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    NotificationNavbarComponent,
    NotificationListComponent,
    SubscriptionButtonComponent,
    NotificationSettingsComponent
  ],
  exports: [
    NotificationNavbarComponent,
    SubscriptionButtonComponent
  ]
})
export class NotificationsModule { }
