import angular from "angular";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { GetInstalledSerialsResult } from "src/ajs/Models/Systembuilder/GetInstalledSerialsResultDto";
import { SystemPartDto } from "src/ajs/Models/Systembuilder/SystemPartDto";
import { SystemPartUnistallReasonDto } from "src/ajs/Models/Systembuilder/SystemPartUnistallReasonDto";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";

createMoveSerialDialogController.$inject = ['$scope', '$mdDialog', 'ApiHandlerService', 'FormerCodes', 'CurrentIdentcode', 'Reasons', 'OnClose', 'OnSuccess', 'Serial', 'Part', 'theme'];


export function createMoveSerialDialogController(
  $scope: MoveSerialDialogControllerScope,
  $mdDialog: angular.material.IDialogService,
  ApiHandlerService: ApiHandlerService,
  FormerCodes: string,
  CurrentIdentcode: string,
  Reasons: SystemPartUnistallReasonDto[],
  OnClose: () => void,
  OnSuccess: (reasonId, reason, sourceParts) => void,
  Serial: string,
  Part: SystemPartDto,
  theme: ThemeDto) {

  addDefaultScopeFunctions($scope, theme);
  $scope.OnSuccess = OnSuccess;
  $scope.SerialNumber = Serial;
  $scope.OnClose = OnClose;
  $scope.Reasons = Reasons;
  $scope.FormerCodes = FormerCodes;
  $scope.Part = Part;

  const reason = Reasons.filter(f => f.Reason === "Umbau");

  $scope.SelectedReasonId = null;
  if (reason.length) {
    $scope.SelectedReasonId = reason[0].Id;
  }

  $scope.HasSelectedEntry = function () {
    if ($scope.CurrentlyInstalled && $scope.CurrentlyInstalled.length) {
      return $scope.CurrentlyInstalled.filter(f => f.IsSelected).length;
    }
    return false;
  };

  $scope.CurrentIdentcode = CurrentIdentcode;
  $scope.Reason = "Umbau in System '" + CurrentIdentcode + "'";

  $scope.GetInstalledSerials = function () {
    $scope.IsLoading = true;
    ApiHandlerService.SendRequest("Systembuilder", "GetInstalledSerials", { serialNumber: $scope.SerialNumber }).then(function (data: GetInstalledSerialsResult) {
      $scope.CurrentlyInstalled = data.CurrentlyInstalled;
      if ($scope.CurrentlyInstalled.length === 1) {
        $scope.CurrentlyInstalled[0].IsSelected = true;
      }
      if (!$scope.Part) {
        $scope.ArticleInformation = data.ArticleInformation;
      } else {
        $scope.ArticleInformation = $scope.Part;
      }
      $scope.IsLoading = false;
    });
  };

  $scope.Hide = function () {
    $scope.OnClose();
    $mdDialog.hide();
  };

  $scope.Cancel = function () {
    $scope.OnClose();
    $mdDialog.cancel();
  };

  $scope.Submit = function (noUninstall) {
    if (noUninstall) {
      $scope.OnSuccess($scope.SelectedReasonId, $scope.Reason, []);
    } else {
      $scope.OnSuccess($scope.SelectedReasonId, $scope.Reason, $scope.CurrentlyInstalled.filter(f => f.IsSelected));
    }

    $mdDialog.cancel();
  };

  $scope.GetInstalledSerials();
}

interface MoveSerialDialogControllerScope extends angular.IScope {
  Reasons: SystemPartUnistallReasonDto[];
  OnClose: () => void;
  OnSuccess: (reasonId, reason, sourceParts) => void;
  SerialNumber: string;
  Part: SystemPartDto;
  FormerCodes: string;
  SelectedReasonId: number | null;
  HasSelectedEntry: () => boolean | number;
  CurrentIdentcode: string;
  Reason: string;
  GetInstalledSerials: () => void;
  IsLoading: boolean;
  CurrentlyInstalled: SystemPartDto[];
  ArticleInformation: SystemPartDto;
  Hide: () => void;
  Cancel: () => void;
  Submit: (noUninstall: boolean) => void;
}
