import { fixDate } from "src/ajs/Utils/DateUtils";
import { ErpCachedAddressDto } from "src/app/erp/dto/erp-cached-address-dto-legacy";
import { ContactMailAddressDto } from "./contact-mail-address.dto";
import { ContactPhoneNumberDto } from "./contact-phone-number-dto";

export interface ContactDto {
  Id: number;
  EnvironmentId: string;
  PortalUniqueIdentifier: string;
  DisplayName: string;
  Company: string;
  Position: string;
  HomePage: string;
  Birthday: Date | null;
  AssistantName: string;
  Body: string;
  GivenName: string;
  MiddleName: string;
  LastName: string;
  Suffix: string;
  Initials: string;
  Nickname: string;
  Department: string;
  OfficeLocation: string;
  Profession: string;
  SpouseName: string;
  ErpCachedAddressId: number | null;

  Address: ErpCachedAddressDto;
  MailAddresses: ContactMailAddressDto[];
  PhoneNumbers: ContactPhoneNumberDto[];
}

export function fixContactDto(dto: ContactDto) {
  if (dto.Birthday) {
    dto.Birthday = fixDate(dto.Birthday);
  }

}
