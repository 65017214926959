import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { LoginComponent } from 'src/app/core/components/login/login.component';
import { MandatorSelectorComponent } from 'src/app/core/components/mandator-selector/mandator-selector.component';
import { Api1Service } from 'src/app/core/services/api1.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { MandatorService } from 'src/app/core/services/mandator.service';
import { SignalRService } from 'src/app/core/services/signalr/signalr.service';
import { UserService } from 'src/app/core/services/user.service';
import { TimeStatisticsComponent } from 'src/app/erp/components/time-statistics/time-statistics.component';
import { NotificationListComponent } from 'src/app/notifications/components/notification-list/notification-list.component';
import { NotificationSettingsComponent } from 'src/app/notifications/components/notification-settings/notification-settings.component';
import { NotificationNavbarComponent } from 'src/app/notifications/components/notification-userbar/notification-userbar.component';
import { CallAnalyticsComponent } from 'src/app/phone/components/call-analytics/call-analytics.component';
import { CallDetailsComponent } from 'src/app/phone/components/call-edit/call-details.component';
import { PhoneListComponent } from 'src/app/phone/components/phone-list/phone-list.component';
import { PhoneService } from 'src/app/phone/services/phone.service';
import { ApproveSharingComponent } from 'src/app/public-calendar/components/approve-sharing/approve-sharing.component';
import { MobileMonthViewComponent } from 'src/app/public-calendar/components/mobile-month-view/mobile-month-view.component';
import { PublicCalendarPrivilegesService } from 'src/app/public-calendar/services/public-calendar-privileges.service';
import { PublicCalendarService } from 'src/app/public-calendar/services/public-calendar.service';
import { MdiIconComponent } from 'src/app/shared/components/mdi-icon/mdi-icon.component';
import { TagEditDialogComponent } from 'src/app/shared/components/tag-edit-dialog/tag-edit-dialog.component';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SystembuilderServiceReportComponent } from 'src/app/systembuilder/components/systembuilder-service-report/systembuilder-service-report.component';
import { TaskListComponent } from 'src/app/task/components/task-list/task-list.component';
import { TaskReceiptEditComponent } from 'src/app/task/components/task-receipt-edit/task-receipt-edit.component';
import { TaskReceiptListComponent } from 'src/app/task/components/task-receipt-list/task-receipt-list.component';
import { TaskTimeListComponent } from 'src/app/task/components/task-time-list/task-time-list.component';
import { TaskService } from 'src/app/task/services/task.service';
import app from './HestiaApp.module';
import { ContractDialogComponent } from 'src/app/contracts/contract-dialog/contract-dialog.component';
import { ContractListComponent } from 'src/app/contracts/contract-list/contract-list.component';
import { WidgetService } from 'src/app/widgets/services/widget.service';
import { InfoHubScriptGeneratorDialogComponent } from 'src/app/info-hub/components/info-hub-script-generator-dialog/info-hub-script-generator-dialog.component';
import { InfoHubService } from 'src/app/info-hub/services/info-hub.service';

// ---- In dieser Datei werden alle downgradeComponents (Angular->AngularJS) definiert. ----


// Services: Damit es keine Namenskollisionen gibt, schreiben wir das Suffix "Ngx" dazu (Kürzel für Angular).
app.factory('BASE_URL_API1', downgradeInjectable('BASE_URL_API1'));
app.factory('BASE_URL_API2', downgradeInjectable('BASE_URL_API2'));
app.factory('BASE_URL_SIGNALR', downgradeInjectable('BASE_URL_SIGNALR'));
app.factory('MandatorServiceNgx', downgradeInjectable(MandatorService));
app.factory('UserServiceNgx', downgradeInjectable(UserService));
app.factory('PublicCalendarServiceNgx', downgradeInjectable(PublicCalendarService));
app.factory('PublicCalendarPrivilegesServiceNgx', downgradeInjectable(PublicCalendarPrivilegesService));
app.factory('DialogServiceNgx', downgradeInjectable(DialogService));
app.factory('AppConfigServiceNgx', downgradeInjectable(AppConfigService));
app.factory('SignalRServiceNgx', downgradeInjectable(SignalRService));
app.factory('PhoneServiceNgx', downgradeInjectable(PhoneService));
app.factory('DialogServiceNgx', downgradeInjectable(DialogService));
app.factory('TaskServiceNgx', downgradeInjectable(TaskService));
app.factory('WidgetServiceNgx', downgradeInjectable(WidgetService));
app.factory('Api1ServiceNgx', downgradeInjectable(Api1Service));
app.factory('InfoHubServiceNgx', downgradeInjectable(InfoHubService));


// Core
app.directive('appMandatorSelector', downgradeComponent({ component: MandatorSelectorComponent }) as angular.IDirectiveFactory);
app.directive('appLogin', downgradeComponent({ component: LoginComponent }) as angular.IDirectiveFactory);

// Shared
app.directive('mdiIcon', downgradeComponent({ component: MdiIconComponent }) as angular.IDirectiveFactory);
app.directive('appTagEditDialog', downgradeComponent({ component: TagEditDialogComponent }) as angular.IDirectiveFactory);

// Notifications
app.directive('appNotificationUserbar', downgradeComponent({ component: NotificationNavbarComponent }) as angular.IDirectiveFactory);
app.directive('appNotificationList', downgradeComponent({ component: NotificationListComponent }) as angular.IDirectiveFactory);
app.directive('appNotificationSettings', downgradeComponent({ component: NotificationSettingsComponent }) as angular.IDirectiveFactory);


// PublicCalendars
app.directive('appApproveSharing', downgradeComponent({ component: ApproveSharingComponent }) as angular.IDirectiveFactory);
app.directive('appMobileMonthView', downgradeComponent({ component: MobileMonthViewComponent }) as angular.IDirectiveFactory);

// Erp
app.directive('appTimeStatistics', downgradeComponent({
  component: TimeStatisticsComponent,
  inputs: ['addressId']
}) as angular.IDirectiveFactory);


// Phone
app.directive('appCallAnalytics', downgradeComponent({ component: CallAnalyticsComponent }) as angular.IDirectiveFactory);
app.directive('appPhoneList', downgradeComponent({
  component: PhoneListComponent,
  outputs: ['callClicked'],
  inputs: ['addressId']
}) as angular.IDirectiveFactory);
app.directive('appCallDetails', downgradeComponent({ component: CallDetailsComponent }) as angular.IDirectiveFactory);

// Task
app.directive('appTaskList', downgradeComponent({
  component: TaskListComponent,
  outputs: ['bulkTaskEdit'],
  inputs: ['addressDto', 'refreshRequested']
}) as angular.IDirectiveFactory);

app.directive('appTaskTimeList', downgradeComponent({ component: TaskTimeListComponent }) as angular.IDirectiveFactory);
app.directive('appTaskReceiptEdit', downgradeComponent({ component: TaskReceiptEditComponent }) as angular.IDirectiveFactory);
app.directive('appTaskReceiptList', downgradeComponent({ component: TaskReceiptListComponent }) as angular.IDirectiveFactory);


// Systembuilder
app.directive('appSystembuilderServiceReport', downgradeComponent({ component: SystembuilderServiceReportComponent }) as angular.IDirectiveFactory);

//Contracts
app.directive('appContractDialog', downgradeComponent({ component: ContractDialogComponent }) as angular.IDirectiveFactory);
app.directive('appContractList', downgradeComponent({ component: ContractListComponent }) as angular.IDirectiveFactory);


//Information Hub
app.directive("appInfoHubScriptGeneratorDialog", downgradeComponent({ component: InfoHubScriptGeneratorDialogComponent }) as angular.IDirectiveFactory);