import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { UrlUtils } from "../utils/url-utils";

export function urlNormalizer(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (typeof control.value === 'string') {
      const newValue = UrlUtils.normalize(control.value);
      if (control.value !== newValue) {
        control.setValue(newValue);
      }
    }
    return null;
  };
}
