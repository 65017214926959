export class Role {
  public static readonly CoreUser = "Core.User";
  public static readonly CoreGlobalAdmin = "Core.GlobalAdmin";
  public static readonly CoreAdmin = "Core.Admin";
  public static readonly CalendarRequestAccess = "Calendar.RequestAccess";
  public static readonly ProjectsReader = "Projects.Reader";
  public static readonly ProjectsWriter = "Projects.Writer";
  public static readonly ProjectsLegacyCreator = "Projects.LegacyCreator";
  public static readonly ProjectsSupervisor = "Projects.Supervisor";
  public static readonly AcquisitionsReader = "Acquisitions.Reader";
  public static readonly AcquisitionsWriter = "Acquisitions.Writer";
  public static readonly AcquisitionsSupervisor = "Acquisitions.Supervisor";
  public static readonly DocumentsReader = "Documents.Reader";
  public static readonly DocumentsWriter = "Documents.Writer";
  public static readonly ContractorsReader = "Contractors.Reader";
  public static readonly ContractorsWriter = "Contractors.Writer";
  public static readonly TimelineReader = "Timeline.Reader";
  public static readonly TimelineWriter = "Timeline.Writer";
}

export class Mandator {
  public static readonly System = '4ae734f7-0eb8-e811-8128-0050568b3cbd';
  public static readonly AlleCP = '1a149b55-0fb8-e811-8128-0050568b3cbd';
}
