export function createInputClearDirective() {
  return {
    restrict: 'A',
    compile: function (element, attrs) {
      const color = attrs.inputClear;
      const clearAction = attrs.inputClearAction;
      const style = color ? "color:" + color + ";" : "";
      const action = attrs.ngModel + " = ''";
      element.after(
        '<md-button class="animate-show md-icon-button md-accent"' +
        'ng-show="' + attrs.ngModel + '" ng-click="' + action + ";" + clearAction + '"' +
        'style="position: absolute; top: -5px; right: -8px; margin: 0px 0px;">' +
        '<div style="' + style + '">x</div>' +
        '</md-button>');
    }
  };
}

