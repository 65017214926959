import * as angular from 'angular';
import moment from 'moment';
import { ColumnDefinition, HestiaDataTable } from 'src/ajs/Directives/HestiaDataTableDirective';
import { EMailQueueDto } from 'src/ajs/Models/Tasks/EMailQueueDto';
import { cloneObject, convertDate, formatDate, globalReceiptQuery } from 'src/ajs/Utils/HestiaCommon';
import { Api1Service } from 'src/app/core/services/api1.service';
import { Attachment } from '../../Models/Tasks/Attachment';
import { GetMailQueueResult } from '../../Models/Tasks/GetMailQueueResultDto';
import { Mail } from '../../Models/Tasks/Mail';
import { ApiHandlerService } from '../../Services/ApiHandlerService';
import { ContactsService } from '../../Services/ContactsService';
import { StorageService } from '../../Services/StorageService';

export function MailEnvironment(
  StorageService: StorageService,
  ApiHandlerService: ApiHandlerService,
  api: Api1Service,
  ContactsService: ContactsService,
  $timeout: angular.ITimeoutService,
  $q: angular.IQService,
  $mdPanel: any) {

  const mailEnvironment = {
    CurrentQueue: [],
    TotalLength: 0,
    SelectedQueue: null,
    Queues: <EMailQueueDto[]>[],
    Mails: [],
    SelectedMail: null,
    ShowSlide: false,
    CurrentAddress: undefined,
    MailsTable: undefined,
    IsLoading: undefined,

    ChangeBlockContent: function () {
      this.SelectedMail.BlockContent = !this.SelectedMail.BlockContent;
      this.BlockExternalContent(this.SelectedMail, this.SelectedMail.BlockContent);
      this.SelectedMail.HasBlockedContent = false;
    },

    Download: function (attachment: any) {
      const blob = new Blob([base64ToArrayBuffer(attachment.Content)], { type: "application" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = attachment.FileName;
      link.click();
    },

    ToReceiptsChanged: function () {
      this.SelectedMail.ToRecipients.forEach(f => {
        if (!f.EMailAddress) {
          const model = {
            EMailAddress: f,
            DisplayName: f,
            Image: "/ClientApp/src/ajs/Images/c_placeholder.png"
          };
          this.SelectedMail.ToRecipients.push(model);
        }
      });
      this.SelectedMail.ToRecipients = this.SelectedMail.ToRecipients.filter(f => f.EMailAddress);
    },

    CcReceiptsChanged: function () {
      this.SelectedMail.CcRecipients.forEach(f => {
        if (!f.EMailAddress) {
          const model = {
            EMailAddress: f,
            DisplayName: f,
            Image: "/ClientApp/src/ajs/Images/c_placeholder.png"
          };
          this.SelectedMail.CcRecipients.push(model);
        }
      });
      this.SelectedMail.CcRecipients = this.SelectedMail.CcRecipients.filter(f => f.EMailAddress);
    },

    BlockExternalContent: function (mail: Mail, block: boolean) {
      if (!mail.ReplacedValues) {
        mail.ReplacedValues = {};
      }
      if (block) {
        let match;
        let tempValue = mail.OriginalBody;
        const cidRegex = /src="[^"]*"/g;
        let counter = 0;
        do {
          match = cidRegex.exec(mail.OriginalBody);
          if (match) {
            const matchText = match[0];
            if (!(matchText.startsWith("src=\"cid:") || matchText.startsWith("src='cid:"))) {
              const replacedText = "src=\"s" + counter + "\"";
              mail.ReplacedValues[replacedText] = matchText;
              mail.HasBlockedContent = true;
              tempValue = tempValue.replace(matchText, replacedText);
            }
          }
          counter++;
        } while (match);
        mailEnvironment.CidResolver(mail, tempValue);
      }
      else {
        if (mail.ReplacedValues) {
          for (const key in mail.ReplacedValues) {
            mail.Body = mail.Body.split(key).join(mail.ReplacedValues[key]);
          }
          mail.ReplacedValues = {};
        }
      }
    },

    CidResolver: function (mail: Mail, tempValue: string) {
      if (!mail.ReplacedValues) {
        mail.ReplacedValues = {};
      }
      //var tempValue = mail.Body;
      const cidRegex = /src="cid:.[^"]*"/g;
      let counter = 0;
      let match;
      do {
        match = cidRegex.exec(mail.OriginalBody);
        if (match) {
          const matchText = match[0];
          const attachments = mail.Attachments.filter(f => {
            return matchText.includes(f.ContentId);
          });
          const attachment = attachments[0];
          let replacedText = "src=\"c" + counter + "\"";
          if (attachment) {
            replacedText = "src=\"data:image/gif;base64," + attachment.Content + "\"";
          }
          mail.ReplacedValues[replacedText] = matchText;
          tempValue = tempValue.replace(matchText, replacedText);
        }
        counter++;
      } while (match);
      $timeout(function () {
        mail.Body = tempValue;
      }, 1);
    },

    ReloadMails: function () {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const environment = this;
      ApiHandlerService.SendRequest("Tasks", "LoadMailQueue", { model: this.SelectedQueue }).then(function (data: GetMailQueueResult) {
        $timeout(function () {
          environment.CurrentAddress = data.QueueAddress;
          environment.CurrentQueue = data.Mails;
          environment.CurrentQueue.forEach(f => {
            if (f.Sender) {
              f.Sender.Image = "/ClientApp/src/ajs/Images/c_placeholder.png";
            }
          });
          environment.MailsTable.UpdateSource(environment.CurrentQueue, $timeout);
          environment.TotalLength = environment.CurrentQueue.length;
        }, null, true);
      });
    },

    CreateBaseMailFromMail: function (mail: Mail) {
      //var attachments = mail.Attachments;
      //mail.Attachments = null;
      const result = cloneObject(mail);
      //mail.Attachments = attachments;
      //result.Attachments = attachments;
      result.IsInEditMode = true;
      result.Parent = mail;
      return result;
    },

    RevertEmailEditing: function () {
      if (this.SelectedMail.Parent) {
        this.SelectedMail = this.SelectedMail.Parent;
      }
    },

    SendCurrentMail: function () {
      this.IsLoading = true;
      this.BlockExternalContent(this.SelectedMail, false);
      ApiHandlerService.SendRequest("Tasks", "SendMail", { model: this.SelectedMail }).then(function (data) {
        mailEnvironment.RevertEmailEditing();
        alert("Mail wurde verschickt");
      });
    },

    QueryReceipt: function (query: string) {
      const result = globalReceiptQuery(query, $q, $timeout, ContactsService);
      return result;
    },

    RemoveAttachment: function (attachment: Attachment) {
      this.SelectedMail.Attachments = this.SelectedMail.Attachments.filter(f => f !== attachment);
    },

    ProcessOneFile: function () {
      if (this.SelectedMail.UploadingFiles.length) {
        const file = this.SelectedMail.UploadingFiles.pop();
        file.extension = null;
        const split = file.name.split('.');
        if (split.length > 1) {
          file.extension = split.pop();
        }
        const reader = new FileReader();
        (<any>reader).contextFile = file;
        reader.readAsBinaryString(file);
        reader.onload = function () {
          const base64 = btoa(<string>this.result);
          const result = {
            FileName: (<any>this).contextFile.name,
            Extension: (<any>this).contextFile.extension,
            IsInline: false,
            IsNew: true,
            Content: base64,
            FileSize: (<string>this.result).length
          };
          mailEnvironment.CalculateAttachmentSize(result);
          mailEnvironment.SelectedMail.Attachments.push(result);
          mailEnvironment.ProcessOneFile();
        };
      }
      else {
        this.SelectedMail.IsCurrentlyUploading = false;
        $timeout(function () {
          const tmp = mailEnvironment.SelectedMail.Attachments;
          mailEnvironment.SelectedMail.Attachments = null;
          mailEnvironment.SelectedMail.Attachments = tmp;
        }, null, true);
      }
    },

    FileDrop: function (files: any[]) {
      if (this.SelectedMail) {
        if (!this.SelectedMail.IsCurrentlyUploading) {
          this.SelectedMail.IsCurrentlyUploading = true;
          this.SelectedMail.UploadingFiles = [];
          for (let i = 0; i < files.length; i++) {
            this.SelectedMail.UploadingFiles.push(files[i]);
          }
          this.ProcessOneFile();
        }

      }
    },

    GenerateMailReplyHeader: function (mail: Mail) {
      const dateString = moment(mail.Date).format('LLLL');
      let toString = "";
      let ccString = "";
      if (mail.ToRecipients && mail.ToRecipients.length) {
        toString = "<b>An:</b>";
        mail.ToRecipients.forEach(f => {
          toString += " " + f.DisplayName + " &lt;" + f.EMailAddress + "&gt;;";
        });
        toString = toString.substr(0, toString.length - 1);
        toString += "<br />";
      }
      if (mail.CoRecipients && mail.CoRecipients.length) {
        ccString = "<b>Cc:</b>";
        mail.CoRecipients.forEach(f => {
          ccString += " " + f.DisplayName + " &lt;" + f.EMailAddress + "&gt;;";
        });
        ccString = ccString.substr(0, ccString.length - 1);
        ccString += "<br />";
      }
      return `
<p></p>
<div>
    <div style="border:none;border-top:solid #E1E1E1 1.0pt;padding:3.0pt 0cm 0cm 0cm" >
         <p class="MsoNormal">
            <b>Von:</b> ` + mail.Sender.DisplayName + ` &lt;` + mail.Sender.EMailAddress + `&gt;<br />
             <b>Gesendet:</b> `+ dateString + `<br />
             `+ toString + `
             `+ ccString + `
             <b>Betreff:</b> ` + mail.Subject + `
        </p >
    </div >
</div >
<p class="MsoNormal"></p>`;
    },

    InjectIntoBody: function (element: string, body: string) {
      const bodyRegex = /<body[^>]*>((.|[\n\r])*)<\/body>/im;
      const array_matches = bodyRegex.exec(body);
      const position = body.indexOf(array_matches[1]);
      const output = [body.slice(0, position), element, body.slice(position)].join('');
      return output;
    },

    AnswerCurrentMail: function (answerAll: boolean) {
      const copy = this.CreateBaseMailFromMail(this.SelectedMail);
      while (copy.Subject.startsWith("AW:") || copy.Subject.startsWith("WG:")) {
        copy.Subject = copy.Subject.substr(3, copy.Subject.length - 3);
        copy.Subject = copy.Subject.trim();
      }
      copy.Subject = "AW: " + copy.Subject;
      copy.Attachments = copy.Attachments.filter(f => f.IsInline);
      copy.Body = this.InjectIntoBody(this.GenerateMailReplyHeader(this.SelectedMail), copy.Body);
      if (answerAll) {
        copy.ToRecipients = copy.ToRecipients.filter(f => f.EMailAddress !== this.CurrentAddress.EMailAddress);
        copy.ToRecipients.unshift(copy.Sender);
        copy.CcRecipients = copy.CcRecipients.filter(f => f.EMailAddress !== this.CurrentAddress.EMailAddress);

      } else {
        copy.ToRecipients = [copy.Sender];
        copy.CcRecipients = [];
      }
      copy.Sender = this.CurrentAddress;
      this.SelectedMail = copy;
    },

    RedirectCurrentMail: function () {
      const copy = this.CreateBaseMailFromMail(this.SelectedMail);
      while (copy.Subject.startsWith("AW:") || copy.Subject.startsWith("WG:")) {
        copy.Subject = copy.Subject.substr(3, copy.Subject.length - 3);
        copy.Subject = copy.Subject.trim();
      }
      copy.Subject = "WG: " + copy.Subject;
      copy.Body = this.InjectIntoBody(this.GenerateMailReplyHeader(this.SelectedMail), copy.Body);
      copy.ToRecipients = [];
      copy.CcRecipients = [];
      copy.Sender = this.CurrentAddress;
      this.SelectedMail = copy;
    },

    CalculateAttachmentSize: function (a: any) {
      let currentUnit = "B";
      let currentSize = a.FileSize;
      if (currentSize > 1024) {
        currentSize /= 1024;
        currentUnit = "KB";
      }
      if (currentSize > 1024) {
        currentSize /= 1024;
        currentUnit = "MB";
      }
      if (currentSize > 1024) {
        currentSize /= 1024;
        currentUnit = "GB";
      }
      a.CalculatedSize = Math.round(currentSize) + " " + currentUnit;
    },

    AssignCurrentMailToTask: function ($event: Event) {
      $mdPanel.newPanelGroup("AddToTask", {
        maxOpen: 1
      });
      const target = $event.srcElement || $event.target;
      const config = {
        attachTo: angular.element(document.body),
        templateUrl: '/ClientApp/src/ajs/Views/Tasks/TasksSuggestionWidget.htm',
        controller: "TasksSuggestionWidgetController",
        panelClass: 'window-panel-container',
        bindToController: true,
        locals: {
          SelectedCalls: null,
          SelectedMails: [this.SelectedMail],
          tasksChanged: null
        },
        openFrom: $event,
        focusOnOpen: true,
        propagateContainerEvents: true,
        groupName: ["AddToTask"]
      };
      $mdPanel.open(config);
    },

    ItemSelected: function (mail: Mail) {
      mailEnvironment.ShowSlide = true;
      if (!mail.IsLoaded) {
        mailEnvironment.IsLoading = true;
        mail.BlockContent = true;
        ApiHandlerService.SendRequest("Tasks", "LoadMailContent", { model: mail }).then(function (data: Mail) {
          mail.OriginalBody = data.Body;
          mail.Attachments = data.Attachments;
          mail.Attachments.forEach(f => f.Content = arrayBufferToBase64(f.Content));
          mail.Attachments.forEach(a => {
            mailEnvironment.CalculateAttachmentSize(a);
          });
          mail.ToRecipients = data.ToRecipients;
          mail.CcRecipients = data.CcRecipients;
          mail.ToRecipients.forEach(f => {
            f.Image = "/ClientApp/src/ajs/Images/c_placeholder.png";
          });
          mail.CcRecipients.forEach(f => {
            f.Image = "/ClientApp/src/ajs/Images/c_placeholder.png";
          });
          mailEnvironment.BlockExternalContent(mail, mail.BlockContent);
          $timeout(function () {

            mailEnvironment.SelectedMail = mail;
            mailEnvironment.IsLoading = false;
            mail.IsLoaded = true;
          }, 15);

        });
      } else {
        mailEnvironment.IsLoading = true;
        mail.Body = mail.OriginalBody;
        mailEnvironment.BlockExternalContent(mail, mail.BlockContent);
        $timeout(function () {
          mailEnvironment.SelectedMail = mail;
          mailEnvironment.IsLoading = false;
        }, 1);
      }
    }
  };

  api.post<EMailQueueDto[]>("Tasks", "GetEmailQueues").subscribe(data => {
    mailEnvironment.Queues = data;
  });

  mailEnvironment.MailsTable = new HestiaDataTable(StorageService.CreateInstance("MailsTable"), $timeout, <any>mailEnvironment);
  mailEnvironment.MailsTable.ItemClicked = mailEnvironment.ItemSelected;
  mailEnvironment.MailsTable.UpdateColumns([
    new ColumnDefinition({ PropertyPath: 'IsRead', Heading: 'Gelesen', HideHeading: true, Template: "/ClientApp/src/ajs/Views/Tasks/Mails/MailStatusReadTemplate.htm", ColumnWidth: 35, Resizeable: false, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'HasAttachements', Heading: 'Anhang', HideHeading: true, Template: "/ClientApp/src/ajs/Views/Tasks/Mails/MailStatusAttachmentsTemplate.htm", ColumnWidth: 35, Resizeable: false, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'Date', Heading: 'Datum', Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDate }),
    new ColumnDefinition({ PropertyPath: 'SenderDisplayName', Heading: 'Absender', Template: "/ClientApp/src/ajs/Views/Tasks/Mails/MailSenderTemplate.htm", Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'Subject', Heading: 'Betreff', Resizeable: true, Sortable: true })
  ]);
  return mailEnvironment;
}

function base64ToArrayBuffer(b64Data: string) {
  const byteCharacters = atob(b64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  return new Uint8Array(byteNumbers);
}

function arrayBufferToBase64(buffer): string {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const lenght = bytes.byteLength;
  for (let i = 0; i < lenght; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

