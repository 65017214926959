import angular, { IQService } from "angular";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { StorageService } from "src/ajs/Services/StorageService";
import { IinternalCreateScope, internalCreate } from "./aXsSystemSearchInternalCreate";

createaXsSystemSearchWidgetControllerWithLocals.$inject = ['$scope', 'mdPanelRef', '$q', 'ApiHandlerService', 'StorageService', 'FixedModelIdentcode', 'theme'];


export function createaXsSystemSearchWidgetControllerWithLocals(
  $scope: IinternalCreateScope,
  mdPanelRef: angular.material.IPanelRef,
  $q: IQService,
  ApiHandlerService: ApiHandlerService,
  StorageService: StorageService,
  FixedModelIdentcode: string,
  theme: ThemeDto
) {
  internalCreate($scope, mdPanelRef, $q, ApiHandlerService, StorageService, FixedModelIdentcode, theme);
}


