import * as angular from "angular";
import { IUserFlyoutControllerModel } from "./IUserFlyoutControllerModel";

export class UserFlyoutController {

  static $inject = ['mdPanelRef', 'Model'];

  constructor(
    private readonly mdPanelRef: angular.material.IPanelRef,
    public readonly Model: IUserFlyoutControllerModel
  ) { }

  Close(): void {
    this.mdPanelRef.close();
  }
}
