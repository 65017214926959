import { ActionDefinition, ActionGroup, ColumnDefinition, HestiaDataTable } from "src/ajs/Directives/HestiaDataTableDirective";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { cleanupPhoneNumber, convertDate, formatDateShort } from "src/ajs/Utils/HestiaCommon";
import { ApiHandlerService } from '../../Services/ApiHandlerService';
import { SearchBarService } from '../../Services/SearchBarService';
import { StorageService } from '../../Services/StorageService';

export class ContactsUserController {

  ContactsTable: HestiaDataTable;
  SearchText: string;
  FilterTimeout: angular.IPromise<any>;
  Model: any;
  FilteredContacts: any;

  static $inject = ['$scope', '$timeout', 'ApiHandlerService', 'StorageService', 'SearchBarService', 'theme'];

  constructor(
    public readonly $scope,
    private readonly $timeout: angular.ITimeoutService,
    private readonly ApiHandlerService: ApiHandlerService,
    StorageService: StorageService,
    private readonly SearchBarService: SearchBarService,
    theme: ThemeDto,
  ) {
    addDefaultScopeFunctions(this, theme);

    this.SearchBarService.RequireSearchBar(true);

    this.SearchBarService.SearchTextChangedEvent((text) => {
      this.SearchTextChanged(text);
    });

    this.ContactsTable = new HestiaDataTable(StorageService.CreateInstance("ContactsTable"), $timeout, $scope);

    this.ContactsTable.Options.Select = true;

    this.ContactsTable.AddActionGroup(new ActionGroup("Aktionen", [
      new ActionDefinition("Als öffentlich markieren", null, this.MarkAsPublic.bind(this)),
      new ActionDefinition("Als privat markieren", null, this.MarkAsPrivate.bind(this)),
      new ActionDefinition("In Organisation übernehmen", null, this.ImportExchangeContactToSystem.bind(this))
    ]));


    this.ContactsTable.UpdateColumns([
      new ColumnDefinition({ PropertyPath: 'IsSynced', Heading: 'Stat.', ColumnWidth: 80, Resizeable: false, Sortable: true, Template: "/ClientApp/src/ajs/Views/Contacts/ContactStateTemplate.htm" }),
      new ColumnDefinition({ PropertyPath: 'DisplayName', Heading: 'Anzeigename', Resizeable: true, Sortable: true }),
      new ColumnDefinition({ PropertyPath: 'Company', Heading: 'Firma', Resizeable: true, Sortable: true }),
      new ColumnDefinition({ PropertyPath: 'Position', Heading: 'Position', Resizeable: true, Sortable: true }),
      new ColumnDefinition({ PropertyPath: 'HomePage', Heading: 'Homepage', Resizeable: true, Sortable: true }),
      new ColumnDefinition({ PropertyPath: 'Birthday', Heading: 'Geburtstag', Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDateShort }),
      new ColumnDefinition({ PropertyPath: 'PhoneNumbers', Heading: 'Telefonnummer', Resizeable: true, Sortable: true, Template: "/ClientApp/src/ajs/Views/Contacts/ContactPhoneTemplate.htm" }),
      new ColumnDefinition({ PropertyPath: 'MailAddresses', Heading: 'E-Mail', Resizeable: true, Sortable: true, Template: "/ClientApp/src/ajs/Views/Contacts/ContactMailTemplate.htm" })
    ]);

    this.LoadData();

  }

  SearchTextChanged(text: string) {
    this.SearchText = text;
    if (this.FilterTimeout !== null) {
      this.$timeout.cancel(this.FilterTimeout);
    }
    this.FilterTimeout = this.$timeout(() => {
      this.ApplyFilter();
    }, 250);
  };

  LoadData() {
    this.ApiHandlerService.SendRequest("Contacts", "GetUserContacts").then((data) => {
      this.Model = data;
      this.ApplyFilter();
    });
  };

  ApplyFilter() {
    if (this.SearchText && this.SearchText.length) {
      const currentSearch = this.SearchText.toLowerCase();
      this.FilteredContacts = this.Model.Contacts.filter(f => {
        let isIn = false;
        if (f.DisplayName) {
          isIn = isIn || f.DisplayName.toLowerCase().includes(currentSearch);
        }
        if (f.Company) {
          isIn = isIn || f.Company.toLowerCase().includes(currentSearch);
        }
        if (f.Position) {
          isIn = isIn || f.Position.toLowerCase().includes(currentSearch);
        }
        if (f.HomePage) {
          isIn = isIn || f.HomePage.toLowerCase().includes(currentSearch);
        }
        for (let i = 0; i < f.PhoneNumbers.length; i++) {
          if (f.PhoneNumbers[i].Number) {
            isIn = isIn || cleanupPhoneNumber(f.PhoneNumbers[i].Number).toLowerCase().includes(currentSearch);
          }
        }
        for (let i = 0; i < f.MailAddresses.length; i++) {
          if (f.MailAddresses[i].Address) {
            isIn = isIn || f.MailAddresses[i].Address.toLowerCase().includes(currentSearch);
          }
        }
        return isIn;
      });
    }
    else {
      this.FilteredContacts = this.Model.Contacts.slice();
    }
    this.ContactsTable.UpdateSource(this.FilteredContacts, this.$timeout);
  };

  ImportExchangeContactToSystem(model: any) {
    if (!model.IsSynced) {
      this.ApiHandlerService.SendRequest("Contacts", "ImportContact", { contact: model }).then((data) => {
        let index = this.Model.Contacts.indexOf(this.Model.Contacts.filter(f => f.ContactId === model.ContactId)[0]);
        this.Model.Contacts[index] = data;
        index = this.FilteredContacts.indexOf(this.FilteredContacts.filter(f => f.ContactId === model.ContactId)[0]);
        if (index !== -1) {
          this.FilteredContacts[index] = data;
        }
      });
    }
  };

  MarkAsPublic(model: any) {
    this.ApiHandlerService.SendRequest("Contacts", "SetSensitivity", { contact: model, isPrivate: false }).then((data) => {
      let index = this.Model.Contacts.indexOf(this.Model.Contacts.filter(f => f.ContactId === model.ContactId)[0]);
      this.Model.Contacts[index] = data;
      index = this.FilteredContacts.indexOf(this.FilteredContacts.filter(f => f.ContactId === model.ContactId)[0]);
      if (index !== -1) {
        this.FilteredContacts[index] = data;
      }
    });
  }

  MarkAsPrivate(model: any) {
    this.ApiHandlerService.SendRequest("Contacts", "SetSensitivity", { contact: model, isPrivate: true }).then((data) => {
      let index = this.Model.Contacts.indexOf(this.Model.Contacts.filter(f => f.ContactId === model.ContactId)[0]);
      this.Model.Contacts[index] = data;
      index = this.FilteredContacts.indexOf(this.FilteredContacts.filter(f => f.ContactId === model.ContactId)[0]);
      if (index !== -1) {
        this.FilteredContacts[index] = data;
      }
    });
  }

  ChangeSyncStatus() {
    this.ApiHandlerService.SendRequest("Contacts", "ToggleAutoSync").then((data) => {
    });
  }

}
