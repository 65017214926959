import { fixDate } from "src/ajs/Utils/DateUtils";

export interface GetTaskListDto {
  TaskId: number;
  Title: string;
  Queue: string;
  CreatedDate: Date;
  Address: string;
  DueDate: Date | null;
  Priority: string;
  CreatedBy: string;
  Assigned: string;
  Status: string;
  LastModified: Date;
}

export function fixGetTaskListDto(dto: GetTaskListDto) {
  if (dto) {
    if (dto.CreatedDate) {
      dto.CreatedDate = fixDate(dto.CreatedDate);
    }

    if (dto.DueDate) {
      dto.DueDate = fixDate(dto.DueDate);
    }

    if (dto.LastModified) {
      dto.LastModified = fixDate(dto.LastModified);
    }
  }
}
