import angular from "angular";
import { ApiHandlerService } from "../Services/ApiHandlerService";
import { LocalizationService, PluginDict } from "../Services/Localization/LocalizationService";
import { ScreenWidthService } from "../Services/ScreenWidthService";
import { StorageService, StorageServiceInstance } from "../Services/StorageService";
import { addNavigation } from "../Utils/HestiaCommon";

export class ProfileController {

  static $inject = ['$timeout', '$mdToast', 'ApiHandlerService', 'StorageService', 'LocalizationService', 'ScreenWidthService', 'BASE_URL_API1'];

  Storage: StorageServiceInstance;
  Localization: PluginDict;
  IsLoading: boolean = true;
  Model: any;


  constructor(
    $timeout: angular.ITimeoutService,
    private readonly $mdToast: angular.material.IToastService,
    private readonly ApiHandlerService: ApiHandlerService,
    StorageService: StorageService,
    LocalizationService: LocalizationService,
    private readonly ScreenWidthService: ScreenWidthService,
    public readonly BASE_URL_API1: string
  ) {

    this.Storage = StorageService.CreateInstance("Profile");
    this.Localization = LocalizationService.GetPluginDict("Profile");

    addNavigation(<any>this, "BaseData");

    this.ApiHandlerService.SendRequest("User", "GetProfileModel").then((data) => {
      this.Model = data;
      $('#2faqr').qrcode({ text: "otpauth://totp/" + encodeURIComponent("portal.axs.at:" + this.Model.UserName) + "?secret=" + data.TFAKey + "&algorithm=SHA1&digits=6&period=30" });
      this.IsLoading = false;
      $timeout(() => { (<any>this).Navigate(this.Storage.GetOrCreate("ActiveTab", "BaseData"), true); }, 250);
    });
  }


  IsMobile() {
    return this.ScreenWidthService.IsMobile();
  }

  IsPortrait() {
    return this.ScreenWidthService.IsPortrait();
  }

  Change2FA() {
    if (!this.Model.ValidationCode) {
      this.Model.ValidationCode = "";
    }
    if (this.Model.ValidationCode.length !== 6) {
      const toast = this.$mdToast.simple()
        .textContent(this.Localization.Localize("MessageInvalidCode"))
        .position('bottom right')
        .capsule(true);
      this.$mdToast.show(toast);
      this.Model.Is2FA = !this.Model.Is2FA;
    } else {
      this.ApiHandlerService.SendRequest("User", "Change2FA", { verification: this.Model.ValidationCode, state: this.Model.Is2FA }).then((data) => {
        let toast = null;
        if (data.Success) {
          toast = this.$mdToast.simple()
            .textContent(this.Localization.Localize("MessageSuccess"))
            .position('bottom right')
            .capsule(true);
          this.$mdToast.show(toast);
        } else {
          toast = this.$mdToast.simple()
            .textContent(this.Localization.Localize("MessageUnknownError"))
            .position('bottom right')
            .capsule(true);
          this.$mdToast.show(toast);
          this.Model.Is2FA = !this.Model.Is2FA;
        }
      });
    }
  }
}
