import { fixDate } from "src/ajs/Utils/DateUtils";

export interface Phone3CxExtensionDto {
  Id: number;
  EnvironmentId: string;
  Extension: string;
  FirstName: string;
  LastName: string;
  LastStatusText: string;
  LastUpdate: Date;
  IsDeactivated: boolean;
  ValidFromUtc: Date;
  ValidUntilUtc: Date;
}

export function fixPhone3CxExtensionDto(dto: Phone3CxExtensionDto) {
  if (dto.LastUpdate) {
    dto.LastUpdate = fixDate(dto.LastUpdate);
  }
  if (dto.ValidFromUtc) {
    dto.ValidFromUtc = fixDate(dto.ValidFromUtc);
  }
  if (dto.ValidUntilUtc) {
    dto.ValidUntilUtc = fixDate(dto.ValidUntilUtc);
  }
}
