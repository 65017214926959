import moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { Api1Service } from 'src/app/core/services/api1.service';
import { GetAllDataResultDto } from '../Models/Tasks/GetAllDataResultDto';
import { PriorityDto } from '../Models/Tasks/PriorityDto';
import { QueueDto } from '../Models/Tasks/QueueDto';
import { StateDto } from '../Models/Tasks/StateDto';
import { TaskDto } from '../Models/Tasks/TaskDto';
import { SimpleUserDto } from '../Models/User/SimpleUserDto';

export class TaskService {
  static $inject = ['ApiHandlerService', 'Api1ServiceNgx'];

  Hub: any;
  Users: SimpleUserDto[] = null;
  States: StateDto[] = null;
  Priorities: PriorityDto[] = null;
  Queues: QueueDto[];
  CurrentQueueRows: any[];
  Disabled: boolean = false;

  constructor(
    private readonly ApiHandlerService: ApiHandlerService,
    private readonly api: Api1Service
  ) { }

  async LoadData() {
    try {
      const data: GetAllDataResultDto = await this.ApiHandlerService.SendRequest("Tasks", "GetAllData", null, true);
      this.Users = data.Users;
      this.States = data.States;
      this.Priorities = data.Priorities;
      this.Queues = data.Queues;
      this.CurrentQueueRows = [];
      this.GernerateQueueDisplayText();
      console.log("TaskService loaded");
    }
    catch (reason) {
      console.log("TaskService rejected " + reason);
      this.Disabled = true;
    }
  }

  private GernerateQueueDisplayText() {
    if (this.Queues) {
      this.Queues.forEach(f => {
        f.DisplayText = this.GeneratePath(f);
      });
      this.Queues.sort((a, b) => {
        const textA = a.DisplayText.toUpperCase();
        const textB = b.DisplayText.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    }
  }

  private GeneratePath(queue: QueueDto, depth?: boolean) {
    let prefix = "";
    if (queue.Parent !== null) {
      prefix = this.GeneratePath(queue.Parent, true);
    }
    let pathResult = prefix + queue.Description;
    if (depth) {
      pathResult += "::";
    }
    return pathResult;
  }

  async GetTasksByCallNumber(number: string, searchText: string, showCompleted: boolean) {
    const result = await firstValueFrom(this.api.get<TaskDto[]>("Tasks", "GetTasksByCallNumber", { number: number, searchText: searchText, showCompleted: showCompleted ?? false }));
    result.forEach(x => this.FinalizeTask(x));
    return result;
  }

  async GetTasksByAddressId(addressId: number, searchText: string, showCompleted: boolean) {
    const result = await firstValueFrom(this.api.get<TaskDto[]>("Tasks", "GetTasksByAddressId", { addressId: addressId, searchText: searchText, showCompleted: showCompleted ?? false }));
    result.forEach(x => this.FinalizeTask(x));
    return result;
  }

  async GetTasksByText(searchText: string, showCompleted: boolean) {
    const result = await firstValueFrom(this.api.get<TaskDto[]>("Tasks", "GetTasksByText", { searchText: searchText, showCompleted: showCompleted ?? false }));
    result.forEach(x => this.FinalizeTask(x));
    return result;
  }

  private FinalizeTask(value: TaskDto) {
    value.State = this.States.filter(f => f.Id === value.StateId)[0];
    value.CreatedUser = this.Users.filter(f => f.Id === value.CreatedUserId)[0];
    value.CreatedDate = moment(value.CreatedDate);

    if (value.AssignedUserId) {
      value.AssignedUser = this.Users.filter(f => f.Id === value.AssignedUserId)[0];
    }
  }
}
