<form [formGroup]="formGroup">
  <!-- Basisdaten -->
  <div class="section-header">
    <div fxFlex *ngIf="isNew">Aufgabe anlegen</div>
    <div fxFlex *ngIf="!isNew">Aufgabe bearbeiten</div>
    <button mat-icon-button class="small" (click)="closeConfirmation()" *ngIf="!isDialog">
      <mdi-icon icon="close"></mdi-icon>
    </button>
    <button mat-icon-button class="small" [mat-dialog-close]="true" *ngIf="isDialog">
      <mdi-icon icon="close"></mdi-icon>
    </button>
  </div>

  <div class="section-content">
    <div fxLayout="column">
      <div fxLayout="row">

        <mat-form-field appearance="standard" fxFlex *ngIf="isEditing">
          <mat-label>Titel</mat-label>
          <input matInput formControlName="title">
        </mat-form-field>

        <mat-form-field appearance="standard" fxFlex *ngIf="!isEditing">
          <mat-label>Titel</mat-label>
          <input matInput [value]="task?.Title" [readonly]="true">
        </mat-form-field>

        <button mat-icon-button color="primary" class="next-to-input" *ngIf="!isEditing" (click)="isEditing = !isEditing"
          matTooltip="Bearbeiten">
          <mdi-icon icon="pencil"></mdi-icon>
        </button>

        <button mat-icon-button color="accent" class="next-to-input" *ngIf="isEditing" (click)="save(); isEditing = !isEditing" [disabled]="formGroup.invalid"
          matTooltip="Speichern">
          <mdi-icon icon="content-save"></mdi-icon>
        </button>
      </div>

      <div fxLayout="row">
        <mat-form-field appearance="standard" fxFlex="100" *ngIf="isEditing">
          <mat-label>Adresse/Kunde</mat-label>
          <mat-select formControlName="addressId">
            <mat-option>
              <ngx-mat-select-search formControlName="addressFilter"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let x of filteredAddresses$ | async" [value]="x.ErpCachedAddressId">{{x.CachedDisplayName}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="standard" *ngIf="!isEditing" fxFlex>
          <mat-label>Adresse/Kunde</mat-label>
          <input matInput [readonly]="true" [value]="task?.Address?.CachedDisplayName">
        </mat-form-field>
      </div>

      <div fxLayout="row">
        <mat-form-field appearance="standard" fxFlex *ngIf="isEditing">
          <mat-label>Beschreibung</mat-label>
          <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10" formControlName="description"></textarea>
        </mat-form-field>

        <mat-form-field appearance="standard" fxFlex *ngIf="!isEditing">
          <mat-label>Beschreibung</mat-label>
          <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10" [value]="task?.Description" [readonly]="true"></textarea>
        </mat-form-field>
      </div>


      <div fxLayout="row">
        <mat-form-field appearance="standard" fxFlex="100" *ngIf="isEditing">
          <mat-label>Queue</mat-label>
          <mat-select formControlName="queueId">
            <mat-option>
              <ngx-mat-select-search formControlName="queueFilter"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let x of filteredQueueVms$ | async" [value]="x.taskQueue.Id">{{x.displayText}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="standard" fxFlex="100" *ngIf="!isEditing">
          <mat-label>Queue</mat-label>
          <input matInput [readonly]="true" [value]="getQueueDisplayText(this.formGroup.value.queueId)">
        </mat-form-field>

      </div>

      <div fxLayout="row">
        <mat-form-field appearance="standard" fxFlex="50" *ngIf="isEditing">
          <mat-label>Priorität</mat-label>
          <mat-select formControlName="priorityId">
            <mat-option>
              <ngx-mat-select-search formControlName="priorityFilter"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let x of filteredPriorities$| async" [value]="x.Id">{{x.Description}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="standard" fxFlex="50" *ngIf="!isEditing">
          <mat-label>Priorität</mat-label>
          <input matInput [readonly]="true" [value]="getPriorityDisplayText(this.formGroup.value.priorityId)">
        </mat-form-field>

        <mat-form-field appearance="standard" fxFlex="50" *ngIf="isEditing">
          <mat-label>Status</mat-label>
          <mat-select formControlName="stateId">
            <mat-option>
              <ngx-mat-select-search formControlName="stateFilter"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let x of filteredStates$| async" [value]="x.Id">{{x.Description}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="standard" fxFlex="50" *ngIf="!isEditing">
          <mat-label>Status</mat-label>
          <input matInput [readonly]="true" [value]="getStateDisplayText(this.formGroup.value.stateId)">
        </mat-form-field>
      </div>

      <div fxLayout="row">
        <mat-form-field appearance="standard" fxFlex="50" *ngIf="isEditing">
          <mat-label>Zugewiesen an</mat-label>
          <mat-select formControlName="assignedUserId">
            <mat-option>
              <ngx-mat-select-search formControlName="userFilter"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let x of filteredUsers$ | async " [value]="x.Id">{{x.FirstName + " " + x.LastName}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="standard" fxFlex="50" *ngIf="!isEditing">
          <mat-label>Zugewiesen an</mat-label>
          <input matInput [readonly]="true" [value]="task?.AssignedUser?.FirstName + ' ' + task?.AssignedUser?.LastName">
        </mat-form-field>

        <mat-form-field appearance="standard" fxFlex="50" *ngIf="isEditing">
          <mat-label>Fällig am</mat-label>
          <input matInput [matDatepicker]="duePicker" formControlName="dueDate">
          <mat-datepicker-toggle matSuffix [for]="duePicker"></mat-datepicker-toggle>
          <mat-datepicker #duePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="standard" fxFlex="50" *ngIf="!isEditing">
          <mat-label>Fällig am</mat-label>
          <input matInput [readonly]="true" [value]="this.formGroup.value.dueDate | date">
        </mat-form-field>

      </div>
    </div>
  </div>


  <!-- Aktivitäten -->
  <div class="section-header">
    <div fxFlex>Aktivitäten {{ "(" + noteVms?.length +")"}}</div>
    <div>{{calculateActivityDurationSum()}}</div>
  </div>

  <div class="section-content">

    <span *ngIf="!isEditing && !noteVms.length">Keine Aktivitäten</span>

    <div *ngIf="isEditing" class="activity-create">
      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="standard" fxFlex>
          <mat-label>Neue Aktivität</mat-label>
          <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10" formControlName="newActivity"></textarea>
        </mat-form-field>

        <button mat-icon-button color="primary" class="next-to-input" [disabled]="!this.formGroup.value.newActivity" (click)="addNewActivity()">
          <mdi-icon icon="plus"></mdi-icon>
        </button>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="standard" fxFlex class="no-min-width">
          <mat-label>Zeitaufwand (Min.)</mat-label>
          <input matInput type="number" formControlName="newActivityTime">
        </mat-form-field>

        <button mat-button class="add-time-button" color="primary" (click)="addToActivityTime(15)">+15</button>
        <button mat-button class="add-time-button" color="primary" (click)="addToActivityTime(30)">+30</button>
        <button mat-button class="add-time-button" color="primary" (click)="addToActivityTime(45)">+45</button>
        <button mat-button class="add-time-button" color="primary" (click)="addToActivityTime(60)">+60</button>
      </div>

      <mat-divider *ngIf="noteVms.length"></mat-divider>
    </div>


    <div class="activity" *ngFor="let vm of noteVms; let i = index">
      <div [formGroup]="vm.formGroup">
        <span class="activity-header">
          {{vm.note.CreatedUser.FirstName + " " + vm.note.CreatedUser.LastName + " - " + (vm.note.CreatedDate | date:'dd.MM.yyyy HH:mm') }}
        </span>

        <div [innerText]="vm.note.Text" *ngIf="!isEditing"></div>

        <div *ngIf="isEditing" fxLayout="row">
          <mat-form-field appearance="standard" fxFlex class="remove-hint">
            <mat-label>Beschreibung</mat-label>
            <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10" formControlName="text"></textarea>
          </mat-form-field>

          <button mat-icon-button (click)="removeActivity(vm)" color="warn">
            <mdi-icon icon="delete"></mdi-icon>
          </button>
        </div>

        <div *ngIf="vm.note.TimeEntry" class="activity-time-entry">
          <b>Zeitaufwand:</b> {{vm.note.TimeEntry.Minutes}}min
        </div>

        <mat-divider *ngIf="i < noteVms.length -1"></mat-divider>
      </div>
    </div>
  </div>

  <!-- Kontakte -->
  <div class="section-header">
    Kontakte ({{contacts?.length ?? 0}})
  </div>

  <div class="section-content">
    <span *ngIf="!contacts?.length">Keine Kontakte</span>

    <div *ngFor="let contact of contacts">
      <button mat-button color="primary" class="contact-button" (click)="openContactDialog(contact.Id)">
        <span>{{contact.GivenName + " " + contact.LastName }}</span><br>
        <span class="contact-address">{{contact.Address?.CachedDisplayName}}</span>
      </button>
    </div>

    <app-loading-overlay [isLoading]="isLoadingCalls && !isLoadingTask" [diameter]="32" overlay="light"></app-loading-overlay>
  </div>

  <!-- Annrufe -->
  <div class="section-header">
    <div fxFlex>Anrufe ({{calls?.length ?? 0}})</div>
    <div>{{getCallsDuration()}}</div>
  </div>

  <div class="section-content">
    <span *ngIf="!calls?.length">Keine Anrufe</span>

    <table mat-table class="axs-table dense calls-table" *ngIf="calls?.length" [dataSource]="calls">
      <ng-container matColumnDef="start">
        <td mat-cell *matCellDef="let call">
          {{call.StartDateTime | date:'dd.MM.yyyy'}}<br>
          {{call.StartDateTime | date:'HH:mm'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="duration">
        <td mat-cell *matCellDef="let call">
          {{call.EndDateTime - call.StartDateTime | duration }}
        </td>
      </ng-container>

      <ng-container matColumnDef="otherParty">
        <td mat-cell *matCellDef="let call">
          <a href="{{call.OtherPartyNumber | tel}}">
            {{ call.OtherPartyMainContact ? call.OtherPartyMainContact.GivenName + " " + call.OtherPartyMainContact.LastName : call.OtherPartyNumber}}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="extension">
        <td mat-cell *matCellDef="let call">
          {{call.Extension?.Extension ?? call.CleanPartList[0]?.AffectedExtension}}
        </td>
      </ng-container>

      <tr mat-row *matRowDef="let call; columns:['start', 'duration', 'otherParty', 'extension']"></tr>
    </table>

    <app-loading-overlay [isLoading]="isLoadingCalls && !isLoadingTask" [diameter]="32" overlay="light"></app-loading-overlay>
  </div>

</form>

<app-loading-overlay [isLoading]="isLoadingTask" overlay="light"></app-loading-overlay>
