import { EventEmitter, Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RootScopeServiceAjs } from 'src/app/ajs-upgraded-providers';
import { MandatorDto } from '../dto/mandator-dto';
import { CoreService } from './core.service';
import { UserService } from './user.service';

/**
 * Das MandatorService ist zuständig für die Auswahl des aktuellen Mandanten.
 * In den neuen Angular-Komponenten wird nur noch dieses Service verwendet.
 */

@Injectable({
  providedIn: 'root'
})

export class MandatorService {
  private _mandators: MandatorDto[];
  get mandators() { return this._mandators; }

  private _selectedMandator: MandatorDto;
  get selectedMandator() { return this._selectedMandator; }
  readonly selectedMandatorChanged = new EventEmitter<MandatorDto>();

  private _selectedMandatorId: string;
  get selectedMandatorId() { return this._selectedMandatorId; }
  readonly selectedMandatorIdChanged = new EventEmitter<string>();

  private _selectedMandatorIndex: number = 0;
  get selectedMandatorIndex() { return this._selectedMandatorIndex; }
  set selectedMandatorIndex(value: number) { }

  constructor(
    private readonly injector: Injector,
    private readonly coreService: CoreService,
    private readonly userService: UserService
  ) { }

  /** Wird beim Starten der App aufgerufen. */
  appInitialize(): Observable<any> {
    return this.coreService.getMandators().pipe(
      tap(mandators => {
        this._mandators = mandators;

        // Zuletzt gewählten Mandanten auswählen.
        // Wenn nicht vorhanden, einfach den ersten Mandanten nehmen.
        this._selectedMandatorId = localStorage.getItem('selectedMandatorId')?.toLowerCase();
        if (!this._selectedMandatorId || !this._mandators.find(x => x.id.toLowerCase() === this._selectedMandatorId)) {
          this._selectedMandatorId = this._mandators[0]?.id?.toLowerCase();
        }

        this.updateSelectedMandator();
      })
    );
  }

  getDescription(mandatorId: string): string {
    return this.mandators.find(x => x.id.toLowerCase() === mandatorId.toLowerCase())?.description;
  }

  selectMandator(mandatorId: string) {
    mandatorId = mandatorId.toLowerCase();

    if (this._selectedMandatorId !== mandatorId) {
      this._selectedMandatorId = mandatorId;
      this.updateSelectedMandator();

      this.selectedMandatorIdChanged.emit(this._selectedMandatorId);
      this.selectedMandatorChanged.emit(this._selectedMandator);

      localStorage.setItem('selectedMandatorId', mandatorId);

      // Backwards compatibility
      const $rootScope = this.injector.get(RootScopeServiceAjs);
      $rootScope.$broadcast('selectedMandatorChanged');
    }
  }

  selectMandatorIndex(index: number) {
    if (this.mandators && index >= 0 && this.mandators.length > index) {
      this.selectMandator(this.mandators[index].id);
    }
  }

  private updateSelectedMandator() {
    this._selectedMandator = this.mandators.find(x => x.id.toLowerCase() === this._selectedMandatorId);
    this._selectedMandatorIndex = this.mandators.indexOf(this._selectedMandator) || 0;
  }



  getMandators() {
    return this.coreService.getMandators();
  }

  /* getAuthorizedMandators(mandatorIds: string[]) {

     const request: GetAuthorizedMandatorsRequestDto = {
       mandatorIds: mandatorIds
     };

     return this.coreService.getAuthorizedMandators(request);
   }

   getCurrentUserMandatorIds() {
     const currentUser = this.userService.currentUser;
     const authorizedMandatorIds: string[] = [];

     for (const role of currentUser.roles) {
       if (!authorizedMandatorIds.includes(role.mandatorId)) {
         authorizedMandatorIds.push(role.mandatorId);
       }
     }

     return authorizedMandatorIds;
   }
   */

}
