import { Component, OnInit } from '@angular/core';
import { SageExportService } from '../../services/sage-export.service';
import { SageExportContractQueueDto } from '../../dto/sage-export-contract-queue-dto';
import { firstValueFrom } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';
import { SageExportHistoryResultDto } from '../../dto/sage-export-history-result-dto';
import { FormControl } from '@angular/forms';
import { SageExportContractDto } from '../../dto/sage-export-contract-dto';
import { SageExportContractTypeDto } from '../../dto/sage-export-contract-type-dto';
import { SageExportContractGroupDto } from '../../dto/sage-export-contract-group-dto';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SageExportContractProductDto } from '../../dto/sage-export-contract-product-dto';
import { MandatorService } from 'src/app/core/services/mandator.service';

@Component({
  selector: 'app-sage-export-contract-queue-dialog',
  templateUrl: './sage-export-contract-queue-dialog.component.html',
  styleUrls: ['./sage-export-contract-queue-dialog.component.scss']
})
export class SageExportContractQueueDialogComponent implements OnInit {


  sageExportContractQueue: SageExportContractQueueDto;
  sageExportContractQueueHistory: SageExportHistoryResultDto;

  sageExportContractTypes: SageExportContractTypeDto[] = [];
  defaultTypeId: number;

  selectedTab: number = 0;
  constructor(
    private readonly sageExportService: SageExportService,
    private readonly snackBar: MatSnackBar,
    private readonly dialogRef: MatDialogRef<SageExportContractQueueDialogComponent>,
    private readonly dialogService: DialogService,
    private readonly mandatorService: MandatorService
  ) { }

  async ngOnInit() {
    this.loadData();
  }

  private async loadData() {
    this.sageExportContractQueue = await firstValueFrom(this.sageExportService.getSageExportContractQueue(this.mandatorService.selectedMandatorId));
    this.sageExportContractQueueHistory = await firstValueFrom(this.sageExportService.getSageExportQueueHistory(this.mandatorService.selectedMandatorId));
    await this.getQueueStatus();

    for (const group of this.sageExportContractQueueHistory.completedGroups) {
      for (const contract of group.sageExportContracts) {
        contract.isSelected = new FormControl(false);
      }
    }

    this.sageExportContractTypes = await firstValueFrom(this.sageExportService.getSageExportContractTypes());
    this.defaultTypeId = this.sageExportContractTypes.find(x => x.isDefault === true).id;

    for (const group of this.sageExportContractQueue.sageExportContractGroups) {

      if (group.sageContractTypeId) {
        group.contractTypeControl = new FormControl(group.sageContractTypeId);
      } else {
        group.contractTypeControl = new FormControl(this.defaultTypeId);
      }
    }
  }


  async deleteExportContract(exportContractId: number) {
    await firstValueFrom(this.sageExportService.deleteSageExportContract(exportContractId));
    this.loadData();
  }

  closeDialog() {
    this.dialogRef.close(true);
  }

  async exportToSage() {

    await this.updateSageContractTypes();
    await firstValueFrom(this.sageExportService.exportContractsToSage(this.mandatorService.selectedMandatorId));

    this.snackBar.open("Verträge Exportiert", null, { duration: 2000, horizontalPosition: "end", verticalPosition: "bottom" });
    this.dialogRef.close(true);
  }


  historyAnySelected() {
    return this.sageExportContractQueueHistory?.completedGroups.some(x => x.sageExportContracts.some(y => y.isSelected.value));
  }

  hasErrors(queue: SageExportContractQueueDto) {
    if (queue?.sageExportContractGroups?.length && queue.sageExportContractGroups.some(x => x.hasErrors)) {
      return true;
    }
    else {
      return false;
    }

  }

  async getQueueStatus() {
    for (const group of this.sageExportContractQueueHistory.openGroups) {
      if (group.sageExportContracts.length && group.sageExportContracts.every(x => x.sageExportContractProducts.length && x.sageExportContractProducts.every(y => y.auftragStatus === 1))) {
        group.auftragStatus = 1;
      }
      else {
        group.auftragStatus = 0;
      }
      if (group.sageExportContracts.length && group.sageExportContracts.every(x => x.sageExportContractProducts.length && x.sageExportContractProducts.every(y => y.lieferStatus === 1))) {
        group.lieferStatus = 1;
      }
      else {
        group.lieferStatus = 0;
      }
      if (group.sageExportContracts.length && group.sageExportContracts.every(x => x.sageExportContractProducts.length && x.sageExportContractProducts.every(y => y.rechnungsStatus === 1))) {
        group.rechnungsStatus = 1;
      }
      else {
        group.rechnungsStatus = 0;
      }
    }
  }

  async updateContractDates() {

    const contracts: SageExportContractDto[] = [];

    for (const group of this.sageExportContractQueueHistory.completedGroups) {
      for (const contract of group.sageExportContracts) {
        if (contract.isSelected) {
          contracts.push(contract);
        }
      }
    }

    await firstValueFrom(this.sageExportService.updateContractDates(contracts));

    this.dialogRef.close(true);
  }

  async openErrorDialog(group: SageExportContractGroupDto) {
    const result = await this.sageExportService.showSageExportContractErrorDialog(group.sageExportContractQueueId);

    if (result) {
      this.loadData();
    }
  }

  async updateSageContractTypes() {
    for (const group of this.sageExportContractQueue.sageExportContractGroups) {
      group.sageContractTypeId = group.contractTypeControl.value;
    }
    await firstValueFrom(this.sageExportService.updateContractTypes(this.sageExportContractQueue.sageExportContractGroups, this.mandatorService.selectedMandatorId));
  }

  contractComplete(contract: SageExportContractDto) {
    if (contract.sageExportContractProducts.length && contract.sageExportContractProducts.every(x => x.auftragStatus === 1 && x.lieferStatus === 1 && x.rechnungsStatus === 1)) {
      return true;
    }
    else {
      return false;
    }
  }

  async completeSageExportContractGroup(group: SageExportContractGroupDto) {

    if (!await this.dialogService.showConfirmation('Beleg abschließen', 'Soll der Beleg abgeschlossen werden?', 'Abschließen', 'Abbrechen')) {
      return;
    }

    await firstValueFrom(this.sageExportService.completeSageExportContractGroup(group.id));
    await this.loadData();
  }

  async deleteSageExportContractProduct(product: SageExportContractProductDto) {
    if (!await this.dialogService.showConfirmation('Löschen', 'Soll die Position gelöscht werden?', 'Löschen', 'Abbrechen', 'warn')) {
      return;
    }

    await firstValueFrom(this.sageExportService.deleteSageExportContractProduct(product.id));
    await this.loadData();
  }

  async archiveSageExportContractGroup(group: SageExportContractGroupDto) {
    if (!await this.dialogService.showConfirmation('Beleg Archivieren', 'Soll der Beleg archiviert werden?', 'Archivieren', 'Abbrechen')) {
      return;
    }

    await firstValueFrom(this.sageExportService.archiveSageExportContractGroup(group.id));
    await this.loadData();
  }

}
