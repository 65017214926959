import angular, { IScope } from "angular";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { GetUserResultDto } from "src/ajs/Models/Administration/GetUserResultDto";
import { RoleRights } from "src/ajs/Models/Administration/RoleRights";
import { SetUserGroupResultDto } from "src/ajs/Models/Administration/SetUserGroupResultDto";
import { SimpleStringResultDto } from "src/ajs/Models/SimpleStringResult";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { LegacyUserDto } from "src/ajs/Models/User/LegacyUserDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";

createEditUserController.$inject = ['$scope', '$mdToast', '$mdDialog', 'ApiHandlerService', 'ParentUser', 'rights', 'mandatorId', 'parentScope', 'theme'];

export function createEditUserController(
  $scope: IEditUserControllerScope,
  $mdToast: angular.material.IToastService,
  $mdDialog: angular.material.IDialogService,
  ApiHandlerService: ApiHandlerService,
  ParentUser: LegacyUserDto,
  rights: RoleRights,
  mandatorId: string,
  parentScope: any,
  theme: ThemeDto
) {
  addDefaultScopeFunctions($scope, theme);
  $scope.IsLoading = true;
  $scope.IsNew = ParentUser === null;
  $scope.Rights = rights;
  $scope.MandatorId = mandatorId;
  $scope.ParentScope = parentScope;
  if (!$scope.IsNew) {
    $scope.Id = ParentUser.Id;
    ApiHandlerService.SendRequest("Administration", "GetUser", { userId: $scope.Id }).then(function (data: GetUserResultDto) {
      $scope.Model = data;
      $scope.IsLoading = false;
      $scope.Model.AllowEmailEdit = $scope.Model.BaseData.EMail === null;
    });
  } else {
    $scope.IsLoading = false;
    $scope.Model = {} as GetUserResultDto;
    $scope.Model.BaseData = {} as LegacyUserDto;
    $scope.Model.MayEdit = true;
    $scope.Model.IsNew = true;
    $scope.Model.AllowEmailEdit = true;
  }

  $scope.SaveBaseData = function (ev: any) {
    if ($scope.Model.IsNew) {
      const cancel = false;
      const confirm = $mdDialog.prompt()
        .title('Bitte vergeben Sie ein Passwort (mindestens 6 Zeichen).')
        .textContent('Das initiale Passwort wird zur ersten Anmeldung verwendet und muss dannach vom Benutzer geändert werden.')
        .placeholder('Passwort')
        .ariaLabel('Passwort')
        .targetEvent(ev)
        .required(true)
        .ok('Okay!');

      $mdDialog.show(confirm).then(function (result) {
        if (result && result.length >= 6) {
          $scope.Model.NewPassword = result;
          $scope.SaveBaseDataInternal(true);
        }
      }, function () { });
    } else {
      $scope.SaveBaseDataInternal(true);
    }
  };

  $scope.SaveBaseDataInternal = function (updateParent: boolean) {
    ApiHandlerService.SendRequest("Administration", "SaveUserData", { mandatorId: $scope.MandatorId, userId: $scope.Id, model: $scope.Model }).then(function (data: SimpleStringResultDto) {
      if (data.Success) {
        const toast = $mdToast.simple()
          .textContent(data.Result)
          .position('bottom right')
          .capsule(true);
        $mdToast.show(toast);
        if (updateParent) {
          $scope.ParentScope.UsersModel.Initialize();
        }
      }
    });
  };

  $scope.ChangeUserGroupValue = function (group: any) {
    group.IsInGroup = !group.IsInGroup;
    $scope.UserGroupChanged(group);
  };

  $scope.UserGroupChanged = function (group: any) {
    ApiHandlerService.SendRequest("Administration", "SetUserGroup", { userId: $scope.Id, groupId: group.Group.Id, isIn: group.IsInGroup }).then(function (data: SetUserGroupResultDto) {
      if (data.Success) {
        $scope.Model.Roles = data.Roles;
      }
    });
  };

  $scope.Cancel = function () {
    $mdDialog.hide();
  };
}

interface IEditUserControllerScope extends IScope {
  IsLoading: boolean;
  IsNew: boolean;
  Rights: RoleRights;
  MandatorId: string;
  ParentScope: any
  Id: string;
  Model: GetUserResultDto;

  SaveBaseData: (ev: any) => void;
  SaveBaseDataInternal: (updateParent: boolean) => void;
  ChangeUserGroupValue: (group: any) => void;
  UserGroupChanged: (group: any) => void;
  Cancel: () => void;
}
