import angular from "angular";
import moment from "moment";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { AppointmentsWidgetResultDto } from "src/ajs/Models/PublicContacts/AppointmentsWidgetResultDto";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { LocalizationService, PluginDict } from "src/ajs/Services/Localization/LocalizationService";


AppointmentsTemplateController.$inject = ['$scope', 'ApiHandlerService', 'LocalizationService', 'theme'];

export function AppointmentsTemplateController(
  $scope: IAppointmentsTemplateController,
  ApiHandlerService: ApiHandlerService,
  LocalizationService: LocalizationService,
  theme: ThemeDto
) {

  addDefaultScopeFunctions($scope, theme);
  $scope.IsLoading = true;
  $scope.Localization = LocalizationService.GetPluginDict("PublicContacts");

  $scope.LoadData = function () {
    ApiHandlerService.SendRequest("PublicContacts", "GetAppointmentsTemplate").then(function (data: AppointmentsWidgetResultDto) {
      $scope.Model = data;

      if ($scope.Model.Today) {
        $scope.Model.Today.forEach(f => {
          f.Start = moment(f.Start);
          f.End = moment(f.End);
          f.DateFormatted = f.Start.format("DD.MM.YYYY");
          f.DurationFormatted = f.Start.format("HH:mm") + " - " + f.End.format("HH:mm");
        });
      }

      if ($scope.Model.Rest) {
        $scope.Model.Rest.forEach(f => {
          f.Start = moment(f.Start);
          f.End = moment(f.End);
          f.DateFormatted = f.Start.format("DD.MM.YYYY");
          f.DurationFormatted = f.Start.format("HH:mm") + " - " + f.End.format("HH:mm");
        });
      }

      $scope.IsLoading = false;
    });
  };

  $scope.LoadData();
}

interface IAppointmentsTemplateController extends angular.IScope {
  IsLoading: boolean;
  Localization: PluginDict;
  Model: AppointmentsWidgetResultDto;

  LoadData: () => void;
}
