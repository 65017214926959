import { ErpCachedAddressDto } from "src/app/erp/dto/erp-cached-address-dto";
import { ContractTypeDto } from "./contract-type-dto";
import { ContractProductDto } from "./contract-product-dto";
import { fixDate } from "src/ajs/Utils/DateUtils";
import { ContractDataDto } from "./contract-data.dto";

export interface ContractDto {
  contractId: number;
  environmentId: string;
  contractTypeId: number;
  created: Date;
  erpCachedAddressId: number | null;
  officeLineCustomer: string;
  accountable: boolean;
  lastValidDate: Date | null;
  dueDate: Date;
  intervallMonts: number;
  isCancelled: boolean;
  title: string;
  description: string;
  deletedUtc: Date | null;
  hasFaktura: boolean;
  showIntervallSum: boolean;
  billingDate: Date | null;
  cachedAddress: ErpCachedAddressDto;
  type: ContractTypeDto;

  externalContractNumber: string;

  products: ContractProductDto[];
  data: ContractDataDto[];
}

export function fixContractDto(dto: ContractDto) {

  if (dto) {
    if (dto.dueDate) {
      dto.dueDate = fixDate(dto.dueDate);
    }

    if (dto.deletedUtc) {
      dto.deletedUtc = fixDate(dto.deletedUtc);
    }

    if (dto.created) {
      dto.created = fixDate(dto.created);
    }

    if (dto.lastValidDate) {
      dto.lastValidDate = fixDate(dto.lastValidDate);
    }

    if (dto.billingDate) {
      dto.billingDate = fixDate(dto.billingDate);
    }


  }


}
