import { ContactDto } from "src/ajs/Models/Contacts/ContactDto";
import { fixDate } from "src/ajs/Utils/DateUtils";

export interface CallAnalyticsCallDto {
  StartDateTime: Date;
  Duration: number;
  OtherPartyNumber: string;
  MainParticipantExtension: string;
  MainParticipantExtensionDescription: string;
  Contact: ContactDto;
}

export function fixCallAnalyticsDataDto(dto: CallAnalyticsCallDto) {
  dto.StartDateTime = fixDate(dto.StartDateTime);
}
