import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-color-palette',
  templateUrl: './color-palette.component.html',
  styleUrls: ['./color-palette.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPaletteComponent),
      multi: true
    }
  ]
})
export class ColorPaletteComponent implements ControlValueAccessor {

  isDisabled = false;
  private _value: string = '';

  @Input() set value(value: string) {
    if (this._value !== value) {
      this._value = value;
      this.propagateChange(this._value);
    }
  }

  get value() {
    return this._value;
  }

  readonly colors: string[] = [

    '#F44336', // red 400
    //'#FF5722', // deep orange 400
    '#FF9800', // orange 400
    //'#FFC107', // amber 400
    '#FFEB3B', // yellow 400
    //'#CDDC39', // lime 400
    //'#8BC34A', // light green 400
    '#4CAF50', // green 400
    //'#009688', // teal 400
    '#00BCD4', // cyan 400
    //'#03A9F4', // light blue 400
    '#2196F3', // blue 400
    '#3F51B5', // indigo 400
    //'#673AB7', // deep purple 400
    '#9C27B0', // purple 400
    '#E91E63', // pink 400
    '#607D8B', // blue grey 400
    '#9E9E9E', // grey 400
    '#795548', // brown 400

    // '#ffcdd2', // red 100
    // //'#ffccbc', // deep orange 100
    // '#ffe0b2', // orange 100
    // //'#ffecb3', // amber 100
    // '#fff9c4', // yellow 100
    // //'#f0f4c3', // lime 100
    // //'#dcedc8', // light green 100
    // '#c8e6c9', // green 100
    // //'#b2dfdb', // teal 100
    // '#b2ebf2', // cyan 100
    // //'#b3e5fc', // light blue 100
    // '#bbdefb', // blue 100
    // '#c5cae9', // indigo 100
    // //'#d1c4e9', // deep purple 100
    // '#e1bee7', // purple 100
    // '#f8bbd0', // pink 100
    // '#cfd8dc', // blue grey 100
    // '#eeeeee', // grey 200
    // '#d7ccc8', // brown 100
  ];

  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  selectColor(color: string) {
    this.value = color;
  }

  isSelected(color: string): boolean {
    return color && color.toLowerCase() === this.value?.toLowerCase();
  }
}
