import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, debounceTime, firstValueFrom, takeUntil } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { SageArticleService } from 'src/app/sage-articles/services/sage-article.service';
import { SageArticleDto } from 'src/app/sage-articles/dto/sage-article-dto';
import { MandatorService } from 'src/app/core/services/mandator.service';

@Component({
  selector: 'app-article-search-dialog',
  templateUrl: './article-search-dialog.component.html',
  styleUrls: ['./article-search-dialog.component.scss']
})
export class ArticleSearchDialogComponent implements OnInit, OnDestroy {

  $destroy: Subject<void> = new Subject<void>();


  constructor(
    private readonly sageArticleService: SageArticleService,
    private readonly dialogRef: MatDialogRef<ArticleSearchDialogComponent>,
    private readonly mandatorService: MandatorService

  ) { }


  articles: SageArticleDto[] = [];

  articleSearch: FormControl<string> = new FormControl<string>("");

  async ngOnInit() {

    this.articleSearch.valueChanges.pipe(
      takeUntil(this.$destroy),
      debounceTime(300)
    ).subscribe(async () => {
      const result = await firstValueFrom(this.sageArticleService.getArticlesBySearchText(this.articleSearch.value, this.mandatorService.selectedMandatorId));
      this.articles = result;
    });

  }

  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }

  selectArticle(article: SageArticleDto) {
    this.dialogRef.close(article);

  }

}
