createEditHubSourceDialogController.$inject = ['$scope', '$mdDialog', 'ApiHandlerService', 'LocalizationService', 'Parent', 'Source', 'MandatorServiceNgx'];

export function createEditHubSourceDialogController($scope, $mdDialog, ApiHandlerService, LocalizationService, Parent, Source, MandatorServiceNgx) {
  $scope.item = Source;
  $scope.Localization = LocalizationService.GetPluginDict("InformationHub");
  $scope.Submit = function () {
    ApiHandlerService.SendRequest("InformationHub", "SaveSource", { model: $scope.item, mandatorId: MandatorServiceNgx.selectedMandatorId }).then(function (data) {
      Parent.LoadData();
      $mdDialog.hide();
    });
  };
  $scope.Cancel = function () {
    $mdDialog.hide();
  };
}
