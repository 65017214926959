import * as angular from "angular";
import { addDefaultScopeFunctions } from "../Functions/add-default-scope-functions";
import { ThemeDto } from "../Models/ThemeDto";

export class MetaDataController {
  static $inject = ['$scope', 'theme'];

  constructor(
    private readonly $scope: IMetaDataControllerScope,
    private readonly theme: ThemeDto
  ) {
    addDefaultScopeFunctions($scope, theme);
    $scope.SiteTitle = theme.SiteTitle;
    $scope.SiteCompany = theme.SiteCompany;
  }

}

interface IMetaDataControllerScope extends angular.IScope {
  SiteTitle: string;
  SiteCompany: string;
}