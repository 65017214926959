import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom, Subject } from 'rxjs';
import { RemoteStorageService } from 'src/app/core/services/web-storage/remote-storage.service';
import { ColumnEditorDialogData } from '../../components/column-editor-dialog/column-editor-dialog-data';
import { ColumnEditorDialogComponent } from '../../components/column-editor-dialog/column-editor-dialog.component';
import { ColumnConfig } from './column-config';
import { ColumnDefinition } from './column-definition';

/**
 * Zum Festlegen des Keys für die Spaltenkonfiguration im LocalStorage.
 */
export const COLEDITOR_STORAGE_KEY = new InjectionToken<string>('COLEDITOR_STORAGE_KEY');

/**
 * Zum Festlegen der verfügbaren Spalten.
 */
export const COLEDITOR_DEF = new InjectionToken<ColumnDefinition[]>('COLEDITOR_DEF');

/**
 * Zum Festlegen von Spalten, die fix in der Tabelle ganz links angezeigt werden, und die nicht konfiguriert werden können (z.B. für Actions).
 */
export const COLEDITOR_DEF_FIXED_START = new InjectionToken<ColumnDefinition[]>('COLEDITOR_FIXED_START');

/**
 * Zum Festlegen von Spalten, die fix in der Tabelle ganz rechts angezeigt werden, und die nicht konfiguriert werden können (z.B. für Actions).
 */
export const COLEDITOR_DEF_FIXED_END = new InjectionToken<ColumnDefinition[]>('COLEDITOR_FIXED_END');


@Injectable()
export class ColumnEditorService {

  /** Gibt an, ob die Einstellungen geladen sind. */
  readonly loaded$ = new Subject<void>();

  private _currentConfig: ColumnConfig[];

  get currentConfig() {
    return this._currentConfig;
  }

  /** Gibt an, ob die aktuelle Config gleich der Standard-Config ist. */
  isDefault = true;

  displayedColumns: string[] = [];

  constructor(
    private readonly dialog: MatDialog,
    private readonly remoteStorage: RemoteStorageService,
    @Inject(COLEDITOR_STORAGE_KEY) private readonly storageKey: string,
    @Inject(COLEDITOR_DEF) public readonly definitions: ColumnDefinition[],
    @Optional() @Inject(COLEDITOR_DEF_FIXED_START) private readonly definitionsFixedStart: ColumnDefinition[],
    @Optional() @Inject(COLEDITOR_DEF_FIXED_END) private readonly definitionsFixedEnd: ColumnDefinition[]
  ) {
    this.loadFromStorage();
  }

  async showEditor() {
    const data: ColumnEditorDialogData = {
      columnEditorService: this
    };
    await firstValueFrom(this.dialog.open<ColumnEditorDialogComponent>(ColumnEditorDialogComponent, { data: data, width: '420px' }).afterClosed());
  }

  private async loadFromStorage() {
    let loadedConfig: ColumnConfig[] = null;

    try {
      const json = await this.remoteStorage.getItem(this.storageKey);
      if (json) {
        const parsed = JSON.parse(json) as ColumnConfig[];

        // Spalten die es nicht mehr gibt entfernen
        for (let i = parsed.length - 1; i >= 0; i--) {
          if (!this.definitions.some(def => def.name === parsed[i].name)) {
            parsed.splice(i, 1);
          }
        }

        // Spalten die neu hinzugekommen sind als unsichtbar einfügen
        for (let i = 0; i < this.definitions.length; i++) {
          const def = this.definitions[i];
          if (!parsed.some(x => x.name === def.name)) {
            if (i === 0) {
              parsed.splice(0, 0, { name: def.name, isVisible: false });
            }
            else {
              const insertIndex = parsed.findIndex(x => x.name === this.definitions[i - 1].name) + 1;
              parsed.splice(insertIndex, 0, { name: def.name, isVisible: false });
            }
          }
        }

        // nur Laden wenn etwas sichtbar ist
        if (parsed.some(x => x.isVisible)) {
          loadedConfig = parsed;
        }
      }
    }
    catch (err) {
      console.log(err);
    }

    this._currentConfig = loadedConfig ?? this.getDefaultConfig();
    this.updateState();

    this.loaded$.next();
    this.loaded$.complete();
  }

  private async saveToStorage() {
    await this.remoteStorage.setItem(this.storageKey, JSON.stringify(this.currentConfig));
  }

  private getDefaultConfig(): ColumnConfig[] {
    const result: ColumnConfig[] = this.definitions.map(def => <ColumnConfig>{ name: def.name, isVisible: def.isVisibleDefault ?? true });
    return result;
  }

  private updateState() {
    this.displayedColumns = [
      ...this.definitionsFixedStart?.map(x => x.name) ?? [],
      ...this.currentConfig.filter(x => x.isVisible).map(x => x.name),
      ...this.definitionsFixedEnd?.map(x => x.name) ?? []
    ];

    this.isDefault = true;

    const defaultConfig = this.getDefaultConfig();
    if (defaultConfig.length !== this._currentConfig.length) {
      this.isDefault = false;
      return;
    }

    for (let i = 0; i < this._currentConfig.length; i++) {
      if (this._currentConfig[i].name !== defaultConfig[i].name ||
        this._currentConfig[i].isVisible !== defaultConfig[i].isVisible) {
        this.isDefault = false;
        return;
      }
    }
  }

  async setConfig(config: ColumnConfig[]) {
    this._currentConfig = config;
    this.updateState();
    await this.saveToStorage();
  }

  async resetConfig() {
    this._currentConfig = this.getDefaultConfig();
    this.updateState();
    await this.saveToStorage();
  }

  getHeader(name: string) {
    return this.definitions.find(x => x.name === name)?.header;
  }
}


