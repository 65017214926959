import angular from "angular";
import { firstValueFrom } from "rxjs";
import { ActionDefinition, ActionGroup, ColumnDefinition, HestiaDataTable } from "src/ajs/Directives/HestiaDataTableDirective";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { FormatDto } from "src/ajs/Models/Label/FormatDto";
import { GetAllLabelDataDto } from "src/ajs/Models/Label/GetAllLabelDataResultDto";
import { PrinterDto } from "src/ajs/Models/Label/PrinterDto";
import { TemplateDto } from "src/ajs/Models/Label/TemplateDto";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { StorageService, StorageServiceInstance } from "src/ajs/Services/StorageService";
import { addNavigation, addNavigationCallback } from "src/ajs/Utils/HestiaCommon";
import { INavigationScope } from "src/ajs/Utils/INavigationScope";
import { Api1Service } from "src/app/core/services/api1.service";
import { MandatorService } from "src/app/core/services/mandator.service";

createLabelController.$inject = ['$scope', '$mdDialog', '$timeout', 'Api1ServiceNgx', 'StorageService', 'theme', 'MandatorServiceNgx'];

export function createLabelController(
  $scope: ILabelControllerScope,
  $mdDialog: angular.material.IDialogService,
  $timeout: angular.ITimeoutService,
  api: Api1Service,
  StorageService: StorageService,
  theme: ThemeDto,
  mandatorServiceNgx: MandatorService
) {

  mandatorServiceNgx.selectedMandatorIdChanged.subscribe(() => {
    $scope.LoadData();
  });


  $scope.Storage = StorageService.CreateInstance("LabelController");
  addDefaultScopeFunctions($scope, theme);
  addNavigation($scope, "Printers");
  addNavigationCallback($scope, null, function () {
    $timeout(function () {
      $scope.PrintersTable.Update();
      $scope.FormatsTable.Update();
      $scope.TemplatesTable.Update();
    }, 200);
  });

  $scope.CreateFormat = function ($event) {
    $mdDialog.show({
      controller: 'LabelCreateEditFormatController',
      templateUrl: '/ClientApp/src/ajs/Views/Label/CreateEditFormatDialog.htm',
      parent: angular.element(document.body),
      targetEvent: $event,
      locals: {
        Format: null,
        OnSuccess: function () {
          $scope.LoadData();
        }
      },
      clickOutsideToClose: false,
      fullscreen: true
    });
  };
  $scope.UpdateFormat = function ($event, format) {
    $mdDialog.show({
      controller: 'LabelCreateEditFormatController',
      templateUrl: '/ClientApp/src/ajs/Views/Label/CreateEditFormatDialog.htm',
      parent: angular.element(document.body),
      targetEvent: $event,
      locals: {
        Format: format,
        OnSuccess: function () {
          $scope.LoadData();
        }
      },
      clickOutsideToClose: false,
      fullscreen: true
    });
  };

  $scope.CreatePrinter = function ($event) {
    $mdDialog.show({
      controller: 'LabelCreateEditPrinterController',
      templateUrl: '/ClientApp/src/ajs/Views/Label/CreateEditPrinterDialog.htm',
      parent: angular.element(document.body),
      targetEvent: $event,
      locals: {
        Printer: null,
        Formats: $scope.Model.Formats,
        OnSuccess: function () {
          $scope.LoadData();
        }
      },
      clickOutsideToClose: false,
      fullscreen: true
    });
  };
  $scope.UpdatePrinter = function ($event, printer) {
    $mdDialog.show({
      controller: 'LabelCreateEditPrinterController',
      templateUrl: '/ClientApp/src/ajs/Views/Label/CreateEditPrinterDialog.htm',
      parent: angular.element(document.body),
      targetEvent: $event,
      locals: {
        Printer: printer,
        Formats: $scope.Model.Formats,
        OnSuccess: function () {
          $scope.LoadData();
        }
      },
      clickOutsideToClose: false,
      fullscreen: true
    });
  };

  $scope.CreateTemplate = function ($event) {
    $mdDialog.show({
      controller: 'LabelCreateEditTemplateController',
      templateUrl: '/ClientApp/src/ajs/Views/Label/CreateEditTemplateDialog.htm',
      parent: angular.element(document.body),
      targetEvent: $event,
      locals: {
        Template: null,
        Printers: $scope.Model.Printers,
        Formats: $scope.Model.Formats,
        OnSuccess: function () {
          $scope.LoadData();
        }
      },
      clickOutsideToClose: false,
      fullscreen: true
    });
  };
  $scope.UpdateTemplate = function ($event, template) {
    $mdDialog.show({
      controller: 'LabelCreateEditTemplateController',
      templateUrl: '/ClientApp/src/ajs/Views/Label/CreateEditTemplateDialog.htm',
      parent: angular.element(document.body),
      targetEvent: $event,
      locals: {
        Template: template,
        Printers: $scope.Model.Printers,
        Formats: $scope.Model.Formats,
        OnSuccess: function () {
          $scope.LoadData();
        }
      },
      clickOutsideToClose: false,
      fullscreen: true
    });
  };

  $scope.TestPrintLabel = function (model, $event) {
    model.forEach(function (template) {
      $mdDialog.show({
        controller: 'LabelTestPrintController',
        templateUrl: '/ClientApp/src/ajs/Views/Label/TestPrintDialog.htm',
        parent: angular.element(document.body),
        targetEvent: $event,
        locals: {
          Template: template,
          Printers: $scope.Model.Printers
        },
        clickOutsideToClose: false,
        fullscreen: true
      });
    });
  };

  $scope.GetFormat = function (model) {
    return model.Description;
  };

  $scope.FormatDpi = function (value) {
    if (value) {
      return value + " dpi";
    }
    else {
      return '';
    }
  };

  $scope.PrintersTable = new HestiaDataTable(StorageService.CreateInstance("PrintersTable"), $timeout, $scope);
  $scope.PrintersTable.Options.Select = false;
  $scope.PrintersTable.ItemDoubleClicked = function (item, $event) {
    $scope.UpdatePrinter($event, item);
  };
  $scope.PrintersTable.UpdateColumns([
    new ColumnDefinition({ PropertyPath: 'Id', Heading: '#', ColumnWidth: 50, Resizeable: false, Sortable: false }),
    new ColumnDefinition({ PropertyPath: 'Name', Heading: 'Name', Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'Location', Heading: 'Ort', Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'Ip', Heading: 'Adresse', Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'Port', Heading: 'Port', Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'Format', Heading: 'Format', RenderFunction: $scope.GetFormat, Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'Dpi', Heading: 'Dpi', Formatter: $scope.FormatDpi, Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'CanZpl', Heading: 'ZPL', Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'CanEpl', Heading: 'EPL', Resizeable: true, Sortable: true })
  ]);
  $scope.FormatsTable = new HestiaDataTable(StorageService.CreateInstance("FormatsTable"), $timeout, $scope);
  $scope.FormatsTable.ItemDoubleClicked = function (item, $event) {
    $scope.UpdateFormat($event, item);
  };
  $scope.FormatsTable.Options.Select = false;
  $scope.FormatsTable.UpdateColumns([
    new ColumnDefinition({ PropertyPath: 'Id', Heading: '#', ColumnWidth: 50, Resizeable: false, Sortable: false }),
    new ColumnDefinition({ PropertyPath: 'Description', Heading: 'Beschreibung', Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'Width', Heading: 'Breite (mm)', Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'Height', Heading: 'Höhe (mm)', Resizeable: true, Sortable: true })
  ]);
  $scope.TemplatesTable = new HestiaDataTable(StorageService.CreateInstance("TemplatesTable"), $timeout, $scope);
  $scope.TemplatesTable.Options.Select = true;
  $scope.TemplatesTable.ItemDoubleClicked = function (item, $event) {
    $scope.UpdateTemplate($event, item);
  };
  $scope.TemplatesTable.AddActionGroup(new ActionGroup("Aktionen", [
    new ActionDefinition("Testdruck durchführen", 'print', null, $scope.TestPrintLabel)
  ]));
  $scope.TemplatesTable.UpdateColumns([
    new ColumnDefinition({ PropertyPath: 'Id', Heading: '#', ColumnWidth: 50, Resizeable: false, Sortable: false }),
    new ColumnDefinition({ PropertyPath: 'Name', Heading: 'Name', Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'PluginName', Heading: 'Plugin', Resizeable: true, Sortable: true })
  ]);

  $scope.LoadData = async function () {
    $scope.Model = await firstValueFrom(api.post<GetAllLabelDataDto>("Label", "GetAllLabelData", { mandatorId: mandatorServiceNgx.selectedMandatorId }));
    $scope.PrintersTable.UpdateSource($scope.Model.Printers, $timeout);
    $scope.FormatsTable.UpdateSource($scope.Model.Formats, $timeout);
    $scope.TemplatesTable.UpdateSource($scope.Model.Templates, $timeout);
  };

  $scope.LoadData();
  $scope.Navigate($scope.Storage.GetOrCreate("ActiveTab", "Templates"), true);
}



interface ILabelControllerScope extends angular.IScope, INavigationScope {
  FormatDpi: (value: number | string) => string;
  GetFormat: (model: FormatDto) => string;
  TestPrintLabel: (model: TemplateDto[], $event: any) => void;
  UpdateTemplate: ($event: any, template: TemplateDto) => void;
  CreateTemplate: ($event: any) => void;
  UpdatePrinter: ($event: any, printer: PrinterDto) => void;
  Model: GetAllLabelDataDto;
  CreatePrinter: ($event: any) => void;
  UpdateFormat: ($event: any, format: FormatDto) => void;
  LoadData(): unknown;
  CreateFormat: ($event: any) => void;
  Storage: StorageServiceInstance;
  PrintersTable: HestiaDataTable;
  FormatsTable: HestiaDataTable;
  TemplatesTable: HestiaDataTable;
}
