<form [formGroup]="formGroup">
  <div fxLayout="row" fxLayoutAlign="center stretch" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start center">
    <mat-card>
      <mat-card-title>Anmeldung</mat-card-title>

      <mat-card-content>
        <div fxLayout="column">

          <mat-form-field appearance="outline">
            <mat-label>E-Mail-Adresse</mat-label>
            <mat-icon matPrefix>alternate_email</mat-icon>
            <input matInput type="text" formControlName="username">
            <mat-error>Eingabe erforderlich.</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Passwort</mat-label>
            <mat-icon matPrefix>key</mat-icon>
            <input matInput type="password" formControlName="password">
            <mat-error>Eingabe erforderlich.</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Validierungscode (nur bei 2FA)</mat-label>
            <mat-icon matPrefix>dialpad</mat-icon>
            <input matInput type="text" formControlName="code">
          </mat-form-field>

          <div fxLayout="row" fxLayoutAlign="start center">
            <div class="button-container">
              <div class="spinner-overlay" *ngIf="isLoadingUserAuth">
                <mat-spinner diameter="24"></mat-spinner>
              </div>
              <button mat-raised-button color="primary" (click)="loginUserAuth()" type="submit"
                [disabled]="isLoading">Anmelden</button>
            </div>
          </div>
        </div>

        <p class="error" *ngIf="userAuthError">{{ userAuthError }}</p>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="appConfig.offerWindowsAuth">
      <mat-card-title>Single Sign-On</mat-card-title>
      <mat-card-content>
        Automatische Anmeldung mit dem Windows-Benutzer im lokalen Netzwerk.<br><br>

        <div fxLayout="row" fxLayoutAlign="start center">
          <div class="button-container">
            <div class="spinner-overlay" *ngIf="isLoadingWindowsAuth">
              <mat-spinner diameter="24"></mat-spinner>
            </div>
            <button mat-raised-button color="primary" (click)="loginWindowsAuth()" type="button"
              [disabled]="isLoading">Anmelden</button>
          </div>
        </div>

        <p class="error" *ngIf="windowsAuthError">{{ windowsAuthError }}</p>
      </mat-card-content>
    </mat-card>
  </div>
</form>
