import { fixDate } from "src/ajs/Utils/DateUtils";

export interface TaskTimeEntryDto {
  TaskNoteId: number;
  TaskId: number;
  CreatedUserId: string;
  TasksReceiptId: number | null;
  TasksReceiptRowId: number | null;
  CreatedDate: Date;
  Tag: string;
  Minutes: number;
}

export function fixTaskTimeEntryDto(dto: TaskTimeEntryDto) {
  dto.CreatedDate = fixDate(dto.CreatedDate);
}
