import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";

export function phoneNumber(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const result = Validators.pattern(/^[\d+][\d \-/()]*$/gmi)(control);

    // Vom PatternValidator kommt bei Fehlern so ein Objekt zurück:
    //   { pattern: '/abcd/gmi' }
    // Wir ändern das auf dieses Objekt, damit eine vernünftige Fehlermeldung angezeigt werden kann:
    //   { phoneNumber: true }
    if (result?.pattern) {
      return { phoneNumber: true };
    }
    return result;
  };
}
