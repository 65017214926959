import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { firstValueFrom, Subject } from 'rxjs';
import { SimpleUserDto } from 'src/ajs/Models/User/SimpleUserDto';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { FormArrayUtils } from 'src/app/shared/utils/form-array-utils';
import { MatTableDataSourceEx } from 'src/app/shared/utils/mat-table-data-source-ex';
import { SystembuilderServiceReportDto } from '../../dto/systembuilder-service-report-dto';
import { SystembuilderService } from '../../services/systembuilder.service';
import { UserService } from 'src/app/core/services/user.service';
import { MandatorService } from 'src/app/core/services/mandator.service';

@Component({
  selector: 'app-systembuilder-service-report',
  templateUrl: './systembuilder-service-report.component.html',
  styleUrls: ['./systembuilder-service-report.component.scss']
})
export class SystembuilderServiceReportComponent implements AfterViewInit, OnDestroy {

  readonly displayedColumns = ["details", "description", "work", "action"];
  readonly dataSource: MatTableDataSourceEx<UntypedFormGroup> = new MatTableDataSourceEx([]);
  readonly formArray: UntypedFormArray;
  users: SimpleUserDto[] = [];
  systembuilderReports: SystembuilderServiceReportDto[] = [];

  private readonly destroy$ = new Subject<void>();

  @Input() readonly systemIdentCode: string;
  @Output() readonly reportCountEmitter = new EventEmitter<number>();

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly systembuilderService: SystembuilderService,
    private readonly dialogService: DialogService,
    private readonly userService: UserService,
    private readonly mandatorService: MandatorService
  ) {

    this.formArray = this.formBuilder.array([]);
  }

  private updateDataSource() {
    this.dataSource.data = this.formArray.controls as UntypedFormGroup[];
    this.reportCountEmitter.emit(this.dataSource.data.length);
  }

  async ngAfterViewInit() {

    this.users = await firstValueFrom(this.systembuilderService.getEmployees());
    this.systembuilderReports = await firstValueFrom(this.systembuilderService.getSystembuilderServiceReports(this.systemIdentCode));

    for (const report of this.systembuilderReports) {
      const newFormGroup = this.createFormGroup();

      newFormGroup.patchValue({
        id: report.Id,
        employeeId: report.EmployeeId,
        workDate: report.WorkDate,
        workTime: report.WorkTime,
        description: report.Description,
        work: report.Work,

        isEditing: false,
        isNew: false,
      });

      this.formArray.push(newFormGroup);
    }

    this.updateDataSource();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addNewEntry() {
    const newFormGroup = this.createFormGroup();

    newFormGroup.patchValue({
      employeeId: this.userService.currentUser.userId,
      workDate: new Date(),
    });

    this.formArray.insert(0, newFormGroup);
    this.updateDataSource();
  }

  async saveReport(row: UntypedFormGroup) {

    const dto: SystembuilderServiceReportDto = {
      Id: row.value.id,
      SystemIdentcode: this.systemIdentCode,
      EmployeeId: row.value.employeeId,
      Employee: null,
      WorkDate: row.value.workDate,
      WorkTime: row.value.workTime,
      Description: row.value.description,
      Work: row.value.work,
    };

    const result = await firstValueFrom(this.systembuilderService.saveSystembuilderServiceReport(dto));

    const currentReport = this.systembuilderReports.find(x => x.Id === result.Id);

    if (currentReport) {
      Object.assign(currentReport, result);
    }
    else {
      this.systembuilderReports.push(result);
    }

    row.patchValue(
      {
        isNew: false,
        id: result.Id,
      });

    this.updateDataSource();
    this.toggleIsEditing(row);
  }

  getEmployeeName(employeeId: string) {
    const employee = this.users.find(x => x.Id === employeeId);
    let result = "";
    if (employee) {
      result = employee.DisplayName;
    }
    return result;
  }

  toggleIsEditing(row: UntypedFormGroup) {
    row.patchValue({
      isEditing: !row.value.isEditing,
    });
  }

  resetRow(row: UntypedFormGroup) {

    if (row.value.isNew) {

      FormArrayUtils.remove(this.formArray, row);
      this.updateDataSource();
    }
    else {
      const currentReport = this.systembuilderReports.find(x => x.Id === row.value.id);
      row.patchValue(
        {
          employeeId: currentReport.EmployeeId,
          workDate: currentReport.WorkDate,
          workTime: currentReport.WorkTime,
          description: currentReport.Description,
          work: currentReport.Work,
        }
      );
      this.toggleIsEditing(row);

    }
  }

  createFormGroup() {
    return this.formBuilder.group({
      id: [0],
      employeeId: ["", [Validators.required]],
      workDate: [null, [Validators.required]],
      workTime: [0],
      description: [""],
      work: [""],

      isEditing: [true],
      isNew: [true],
    });
  }

  async deleteReport(row: UntypedFormGroup) {

    if (!await this.dialogService.showConfirmation("Bericht löschen", "Bericht wirklich löschen?", "Löschen", "Abbrechen", "warn")) {
      return;
    }

    await firstValueFrom(this.systembuilderService.deleteSystemServiceReport(row.value.id));

    FormArrayUtils.remove(this.formArray, row);
    this.updateDataSource();
  }

  async downloadPdf(row: UntypedFormGroup) {
    window.open('/api/SystemBuilder/DownloadServiceReportAsPdf/?id=' + row.value.id, '_blank');
  }
}
