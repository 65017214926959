import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { ThemeDto } from "src/ajs/Models/ThemeDto";

export class ContactsAdminDialogController {
  static $inject = ['$scope', '$mdDialog', 'theme'];

  constructor(
    $scope,
    private readonly $mdDialog: angular.material.IDialogService,

    theme: ThemeDto,

  ) {
    addDefaultScopeFunctions(this, theme);

  }

  Cancel() {
    this.$mdDialog.hide();
  }
}

