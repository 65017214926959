import * as angular from 'angular';
import * as moment from 'moment';
import { addDefaultScopeFunctions } from 'src/ajs/Functions/add-default-scope-functions';
import { PublicContactDto } from "src/ajs/Models/PublicContacts/PublicContactDto";
import { PublicGroupDto } from "src/ajs/Models/PublicContacts/PublicGroupDto";
import { ThemeDto } from 'src/ajs/Models/ThemeDto';
import { ApiHandlerService } from 'src/ajs/Services/ApiHandlerService';
import { PublicContactsService } from 'src/ajs/Services/PublicContactsService';
import { ScreenWidthService } from 'src/ajs/Services/ScreenWidthService';
import { SearchBarService } from 'src/ajs/Services/SearchBarService';
import { StorageService } from 'src/ajs/Services/StorageService';
import { HasScrollbar } from 'src/ajs/Utils/HestiaCommon';
import { MandatorService } from 'src/app/core/services/mandator.service';
import { Timeline } from '../../Services/Timeline/Timeline';
import { TimelineMode } from '../../Services/Timeline/timeline-mode.enum';


createPublicHolidaysController.$inject = ['$scope', '$document', '$mdDialog', '$q', '$timeout', '$mdPanel', 'ApiHandlerService', 'SearchBarService', 'ScreenWidthService', 'MandatorServiceNgx', 'PublicContactsService', 'TimelineDatePickerService', 'StorageService', 'theme'];

export function createPublicHolidaysController(
  $scope,
  $document,
  $mdDialog,
  $q: angular.IQService,
  $timeout: angular.ITimeoutService,
  $mdPanel,
  ApiHandlerService: ApiHandlerService,
  SearchBarService: SearchBarService,
  ScreenWidthService: ScreenWidthService,
  MandatorServiceNgx: MandatorService,
  PublicContactsService: PublicContactsService,
  TimelineDatePickerService,
  StorageService: StorageService,
  theme: ThemeDto) {

  addDefaultScopeFunctions($scope, theme);
  $scope.IsLoading = true;
  $scope.Groups = [];
  $scope.InvisibleElements = {};
  $scope.GroupSearchHit = {};
  $scope.IsAvailableOnly = false;
  $scope.FilterClass = "contact-filter-all";
  SearchBarService.RequireSearchBar(true);
  $scope.CalculateMarginStyle = function () {
    if ($scope.IsMobile) {
      if (HasScrollbar('#scrollContainer')) {
        return {
          'margin-right': '14px'
        };
      } else {
        return {
          'margin-right': '7px'
        };
      }
    } else {
      return {
        'margin-right': '17px'
      };
    }
  };
  $scope.KeyPress = function (event) {
    var element = $document[0].activeElement;
    if (!(element && element.tagName === "INPUT" || element.tagName === "TEXTAREA")) {
      if (event.key === "ArrowLeft") {
        $scope.Timeline.DayBack(true);
      }
      if (event.key === "ArrowRight") {
        $scope.Timeline.DayForward(true);
      }
      if (event.key === " ") {
        $scope.Timeline.CenterCalendar();
      }
    }

  };

  $document.bind('keyup', $scope.KeyPress);

  SearchBarService.SearchTextChangedEvent(function (text) {
    $scope.SearchTextChanged(text);
  });

  $scope.IsMobile = function () {
    return ScreenWidthService.IsMobile();
  };

  $mdPanel.newPanelGroup('contacts', {
    maxOpen: 1
  });

  $mdPanel.newPanelGroup('calendars', {
    maxOpen: 1
  });

  $scope.OpenDatePicker = function ($event) {
    var target = $event.srcElement || $event.target;
    var position = $mdPanel.newPanelPosition().absolute().right().top();
    var config = {
      attachTo: angular.element(document.body),
      templateUrl: '/ClientApp/src/ajs/Views/PublicContacts/CalendarFlyout.htm',
      panelClass: 'contact-panel-container',
      position: position,
      openFrom: $event,
      scope: $scope,
      clickOutsideToClose: true,
      escapeToClose: true,
      focusOnOpen: false,
      zIndex: 50,
      groupName: ['calendars']
    };

    TimelineDatePickerService.datePickerPanel = $mdPanel.open(config);
  };

  $scope.StorageService = StorageService.CreateInstance('PublicHolidays');
  const timelineMode = $scope.StorageService.GetOrCreate('ShowMonth', true) ? TimelineMode.Month : TimelineMode.Week;

  $scope.Timeline = new Timeline($timeout, timelineMode, TimelineDatePickerService);

  $scope.ToggleWeekMonth = function () {
    $scope.Timeline.ToggleWeekMonth();
    $scope.StorageService.Set('ShowMonth', $scope.Timeline.Options.mode === TimelineMode.Month);
  };

  $scope.$on('$destroy', function () {
    $document.unbind('keyup', $scope.KeyPress);
    if ($scope.PollTimeout) {
      Timeline.active_timeline = null;
      $timeout.cancel($scope.PollTimeout);
    }
  });

  $scope.LoadData = function () {
    $scope.IsLoading = true;
    PublicContactsService.getHolidays(MandatorServiceNgx.selectedMandatorId).subscribe(function (data) {
      $scope.Groups = data.Groups;
      $scope.Extensions = data.Extensions;
      $scope.IsLoading = false;
      $scope.Contacts = $scope.AllContacts();
      angular.forEach($scope.Groups, function (group) {
        $scope.InvisibleElements["#" + group.Id] = false;
      });
      angular.forEach($scope.Contacts, function (contact) {
        if (contact.Phone) {
          contact.CleanPhone = contact.Phone.replace(/\s/g, '');
        }
        if (contact.Mobile) {
          contact.CleanMobile = contact.Mobile.replace(/\s/g, '');
        }
      });
      $scope.Timeline.Options.MinDate = moment(data.MinDate).toDate();
      $scope.Timeline.Options.MaxDate = moment(data.MaxDate).toDate();
      $scope.Timeline.InitializeContactDictionary($scope.Contacts);
      $scope.SearchTextChanged('');
      $scope.UpdateContactStates();
      $scope.Timeline.CalculatedTicks = $scope.Timeline.GetTicks();
      $timeout($scope.Timeline.CenterCalendar(), 1000);
      $timeout($scope.Timeline.DrawHelperLines(true), 1000);
    });
  };

  $scope.ReloadContacts = function () {
    const deferred = $q.defer();
    PublicContactsService.getHolidays(MandatorServiceNgx.selectedMandatorId).subscribe(function (data) {
      const newContacts: PublicContactDto[] = $scope.ExtractContacts(data.Groups);
      angular.forEach($scope.Contacts, function (contact) {
        const newEntry = newContacts.filter(f => f.AccountName === contact.AccountName);
        contact.Appointments = newEntry[0].Appointments;
      });
      $scope.Timeline.InitializeContactDictionary($scope.Contacts);
      $scope.Timeline.UpdateContactColumnSpan();
      $scope.UpdateContactStates();
      deferred.resolve();
    });
    return deferred.promise;
  };

  /*
   * Stati:
   * 0 grau
   * 1 grün
   * 2 gelb
   * 3 orange
   * 4 rot
   * */

  $scope.UpdateContactStates = function () {
    angular.forEach($scope.Contacts, function (contact) {
      $scope.UpdateStateForContact(contact);
    });
  };

  $scope.UpdateStateForContact = function (contact) {
    const calendarStatus = $scope.UpdateCalendarState(contact);
    contact.State = calendarStatus;

    switch (contact.State.Code) {
      case 1:
        contact.State.Class = "contact-state-free";
        break;
      case 2:
        contact.State.Class = "contact-state-busy";
        break;
      case 3:
        contact.State.Class = "contact-state-do-not-disturb";
        break;
      case 4:
        contact.State.Class = "contact-state-not-available";
        break;
      case 0:
      default:
        contact.State.Class = "contact-state-inactive";
        break;
    }
    contact.IsAvailable = contact.State.Code === 1;
    this.SearchTextChanged(this.SearchText);
  };

  $scope.UpdateCalendarState = function (contact) {
    //const now = moment();
    const result = {
      Code: 0
    };
    // Kundenwunsch: In dieser Ansicht ist kein Status erwünscht
    /*var appointments = contact.Appointments.filter(f => f.MomentStart <= now && f.MomentEnd >= now);
    if (appointments.length) {
        result.Code = 2;
        result.Message = "Im Urlaub";
    }*/
    return result;
  };

  $scope.SearchKeyPress = function ($event, input) {
    const code = $event.charCode || $event.keyCode;
    if (code === 27) {
      $scope.SearchText = "";
      input.value = "";
      input.SearchText = "";
      $scope.SearchTextChanged($scope.SearchText);
    }
  };

  $scope.LoadAdministration = function () {
    $scope.IsLoading = true;
    ApiHandlerService.SendRequest("PublicContacts", "GetAdministrationContent").then(function (data) {
      $scope.Administration = data;
      $scope.IsLoading = false;
    });
  };

  $scope.AddGroup = function (event) {
    $mdDialog.show({
      targetEvent: event,
      controller: 'PublicContractsGroupDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/PublicContacts/EditGroupDialog.htm',
      locals: {
        selectedGroup: null,
        parentScope: $scope
      },
      parent: angular.element(document.body)
    });
  };

  $scope.RenameGroup = function (event, group) {
    $mdDialog.show({
      targetEvent: event,
      controller: 'PublicContractsGroupDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/PublicContacts/EditGroupDialog.htm',
      locals: {
        selectedGroup: group,
        parentScope: $scope
      },
      parent: angular.element(document.body)
    });
  };

  $scope.ReorderGroups = function () {
    var order = 1;
    angular.forEach($scope.Administration.Groups, function (v) {
      v.SortOrder = order++;
    });
    ApiHandlerService.SendRequest("PublicContacts", "ReorderGroups", { orderedGroups: $scope.Administration.Groups }).then(function (data) { });
  };

  $scope.RemoveGroup = function (group) {
    ApiHandlerService.SendRequest("PublicContacts", "RemoveGroup", { model: group }).then(function (data) {
      $scope.Administration.Groups = $scope.Administration.Groups.filter(f => f !== group);
    });
  };

  $scope.ExtractContacts = function (groups: PublicGroupDto[]) {
    var result: PublicContactDto[] = [];
    angular.forEach(groups, function (group) {
      angular.forEach(group.Contacts, function (contact) {
        result.push(contact);
      });
    });
    return result;
  };

  $scope.AllContacts = function () {
    var result = [];
    angular.forEach($scope.Groups, function (group) {
      angular.forEach(group.Contacts, function (contact) {
        result.push(contact);
      });
    });
    return result;
  };

  $scope.CleanPhoneString = function (phoneString) {
    if (phoneString) {
      return phoneString.replace('+', '00').replace(/\W/g, '');
    }
  };

  $scope.StopPropagation = function (event) {
    event.stopImmediatePropagation();
  };

  $scope.SearchTextChanged = function (SearchText) {

    if ($scope.SearchTimeout) {
      $timeout.cancel($scope.SearchTimeout);
    }

    $scope.SearchTimeout = $timeout(function () {
      $scope.SearchText = SearchText;
      if (!$scope.SearchText) {
        $scope.SearchText = "";
      }
      if ($scope.SearchText !== null) {
        angular.forEach($scope.GroupSearchHit, function (hit) {
          hit = false;
        });
        angular.forEach($scope.Groups, function (group) {
          var isGroupIn = false;
          if (group && group.Name && group.Name.toUpperCase && SearchText && SearchText.toUpperCase) {
            if (group.Name.toUpperCase().startsWith(SearchText.toUpperCase())) {
              isGroupIn = true;
            }
          }
          angular.forEach(group.Contacts, function (contact) {
            if (SearchText && SearchText.toUpperCase) {
              if (isGroupIn || contact.FirstName.toUpperCase().includes(SearchText.toUpperCase()) || contact.LastName.toUpperCase().includes(SearchText.toUpperCase())) {
                contact.IsHidden = false;
              } else {
                contact.IsHidden = true;
              }
              if ($scope.IsAvailableOnly && !contact.IsAvailable) {
                contact.IsHidden = true;
              }
            } else {
              contact.IsHidden = false;
            }
          });
          $scope.IsGroupVisible(group, SearchText === null || SearchText === '');
        });
        var timeline = $scope.Timeline;
        $scope.SearchTimeout = null;
        $timeout(function () { timeline.DrawHelperLines(); }, 50);
      }
    }, 250);
  };

  $scope.IsGroupVisible = function (group, emptySearch) {
    if (group && group.Contacts) {
      group.IsVisible = group.Contacts.filter(f => !f.IsHidden).length > 0;
    } else {
      group.IsVisible = false;
    }
    $scope.GroupSearchHit[group.Id] = group.IsVisible && (!emptySearch || $scope.IsAvailableOnly);
  };

  $scope.IsContactVisible = function (contact) {
    return !contact.IsHidden;
  };

  $scope.ZoomEvent = function (event, delta) {
    if (event.originalEvent.ctrlKey) {
      if (delta > 0) {
        $scope.Timeline.ZoomIn(event);
      }
      if (delta < 0) {
        $scope.Timeline.ZoomOut(event);
      }
      event.originalEvent.preventDefault();
    }
  };

  $scope.InteractionDown = function (event) {
    $scope.ScrollEvent = {
      CurrentX: event.clientX,
      CurrentY: event.clientY,
      IsScrolling: false
    };
    event.preventDefault();
  };

  $scope.InteractionUp = function (event) {
    if ($scope.ScrollEvent) {
      if ($scope.ScrollEvent.MoveExecuted) {
        $scope.WasScrolling = true;
        $timeout(function () {
          $scope.WasScrolling = false;
        }, 50);
      }
      $scope.ScrollEvent = null;
      $scope.IsMouseDown = false;
      event.preventDefault();
    }
  };

  $scope.InteractionMove = function (event) {
    if ($scope.ScrollEvent) {
      $scope.ScrollEvent.IsScrolling = true;
      var delta = $scope.ScrollEvent.CurrentX - event.clientX;
      var deltaY = $scope.ScrollEvent.CurrentY - event.clientY;
      $scope.ScrollEvent.CurrentY = event.clientY;
      $("#scrollContainer")[0].scrollTop += deltaY;

      var width = $("#tick0").width();
      var minutesPerTick = $scope.Timeline.Options.TickMinutes;
      var pixelPerMinute = width / minutesPerTick;
      var deltaMinutes = delta / pixelPerMinute;
      if (Math.abs(deltaMinutes) > 1) {
        $scope.ScrollEvent.MoveExecuted = true;
        $scope.IsMouseDown = true;
        var time = $scope.Timeline.Options.StartTime;
        $scope.Timeline.Options.SetStartTime(time.setMinutes($scope.Timeline.Options.StartTime.getMinutes() + deltaMinutes));

        $scope.Timeline.UpdateContactColumnSpan();
        var newTicks = $scope.Timeline.GetTicks();
        for (var i = 0; i < newTicks.length; i++) {
          $scope.Timeline.CalculatedTicks[i].Label = newTicks[i].Label;
        }
        $scope.Timeline.CalculateDays();
        $scope.Timeline.DrawHelperLines();
        $scope.Timeline.UpdateTimeLabelsPosition();
        $scope.Timeline.UpdateCurrentTimeLine();
        $scope.ScrollEvent.CurrentX = event.clientX;
      }
      $scope.ScrollEvent.IsScrolling = false;
    }
    event.preventDefault();
  };

  $scope.Toggle = function (id) {
    if (!$scope.WasScrolling) {
      var timeline = this.Timeline;
      $scope.InvisibleElements[id] = !$scope.InvisibleElements[id];
      $(id).toggle("slow", function () {
        timeline.UpdateCurrentTimeLine();
        timeline.UpdateTimeLabelsPosition();
        timeline.DrawHelperLines();
        $scope.$apply();
      });
    }
  };

  $scope.IsVisible = function (id) {
    return $(id).is(":visible");
  };

  $scope.OpenContactDetails = function ($event, contact) {
    var target = $event.srcElement || $event.target;
    while (!target.className.includes("contact-class-row")) {
      target = target.parentElement;
    }

    var position = $mdPanel.newPanelPosition()
      .relativeTo(target)
      .addPanelPosition(
        $mdPanel.xPosition.OFFSET_END,
        $mdPanel.yPosition.ALIGN_TOPS
      );
    var config = {
      attachTo: angular.element(document.body),
      templateUrl: '/ClientApp/src/ajs/Views/PublicContacts/UserFlyout.htm',
      controller: 'UserFlyoutController',
      locals: {
        Model: contact
      },
      controllerAs: 'vm',
      position: position,
      panelClass: 'contact-panel-container',
      openFrom: $event,
      focusOnOpen: true,
      clickOutsideToClose: true,
      escapeToClose: true,
      zIndex: 4,
      propagateContainerEvents: true,
      groupName: ['contacts']
    };

    $mdPanel.open(config);
  };

  $scope.ChangeFilterMode = function () {
    if ($scope.IsAvailableOnly) {
      $scope.IsAvailableOnly = false;
      $scope.FilterClass = "contact-filter-all";
    } else {
      $scope.IsAvailableOnly = true;
      $scope.FilterClass = "contact-filter-available";
    }
    this.SearchTextChanged(this.SearchText);
  };

  $scope.StartIntervall = function ($scope, $timeout, object) {
    $scope.ReloadContacts().then(function () {
      $scope.PollTimeout = $timeout(function () {
        $scope.StartIntervall($scope, $timeout, object);
      }, 60000);
    });
  };

  // Init controller
  $scope.LoadData();
  var object = createPublicHolidaysController;
  $scope.PollTimeout = $timeout(function () {
    $scope.StartIntervall($scope, $timeout, object);
  }, 60000);

  $scope.$on('selectedMandatorChanged', function () {
    $scope.LoadData();
  });
}
