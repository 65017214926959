import angular, { IPromise } from "angular";
import { DragAndDropPanel } from "src/ajs/Directives/DragAndDropPanel";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { GetCreateTaskResultDto } from "src/ajs/Models/Tasks/GetCreateTaskResultDto.";
import { PriorityDto } from "src/ajs/Models/Tasks/PriorityDto";
import { QueueDto } from "src/ajs/Models/Tasks/QueueDto";
import { StateDto } from "src/ajs/Models/Tasks/StateDto";
import { TaskDto } from "src/ajs/Models/Tasks/TaskDto";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { SimpleUserDto } from "src/ajs/Models/User/SimpleUserDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { cloneObject } from 'src/ajs/Utils/HestiaCommon';
import { MandatorService } from "src/app/core/services/mandator.service";

export class BulkTaskEditController {

  static $inject = ['$scope', 'mdPanelRef', 'ApiHandlerService', 'Tasks', 'theme', 'MandatorServiceNgx'];

  constructor(
    $scope: IBulkTaskEditControllerScope,
    mdPanelRef: angular.material.IPanelRef,
    ApiHandlerService: ApiHandlerService,
    Tasks: TaskDto[],
    theme: ThemeDto,
    MandatorServiceNgx: MandatorService
  ) {
    addDefaultScopeFunctions($scope, theme);

    $scope.Users = [];
    $scope.Priorities = [];
    $scope.States = [];
    $scope.Queues = [];

    ApiHandlerService.SendRequest("Tasks", "GetCreateTaskData", { mandatorId: MandatorServiceNgx.selectedMandatorId }).then(function (data: GetCreateTaskResultDto) {
      $scope.Users = data.Users;
      $scope.Priorities = data.Priorities;
      $scope.States = data.States;
      $scope.Queues = data.Queues;
    });

    $scope.DragAndDropPanel = new DragAndDropPanel(Tasks.length + " Aufgaben bearbeiten", mdPanelRef);
    $scope.Tasks = Tasks;
    $scope.UserSearchText = null;
    $scope.SelectedPriority = -1;
    $scope.SelectedQueue = -1;
    $scope.SelectedUser = null;
    $scope.SelectedState = -1;

    $scope.QueryUser = function (searchText: string) {
      if (searchText && searchText.length !== 0 && searchText.toLowerCase) {
        return $scope.Users.filter(u => u.DisplayName.toLowerCase().includes(searchText.toLowerCase()));
      } else {
        return $scope.Users;
      }
    };


    $scope.SaveChanges = async () => {
      const promises: IPromise<any>[] = [];

      $scope.Tasks.forEach(f => {
        const clone: TaskDto = cloneObject(f);
        if ($scope.SelectedState && $scope.SelectedState !== '-1' && $scope.SelectedState !== -1) {
          clone.StateId = (<StateDto>$scope.SelectedState).Id;
        }
        if ($scope.SelectedPriority && $scope.SelectedPriority !== '-1' && $scope.SelectedPriority !== -1) {
          clone.PriorityId = (<PriorityDto>$scope.SelectedPriority).Id;
        }
        if ($scope.SelectedQueue && $scope.SelectedQueue !== '-1' && $scope.SelectedQueue !== -1) {
          clone.QueueId = (<QueueDto>$scope.SelectedQueue).Id;
        }
        if ($scope.SelectedUser) {
          clone.AssignedUserId = (<SimpleUserDto>$scope.SelectedUser).Id;
        }

        clone.MandatorId = MandatorServiceNgx.selectedMandatorId;
        promises.push(ApiHandlerService.SendRequest("Tasks", "UpdateTask", { task: clone }));
      });

      await Promise.all(promises);
      mdPanelRef.close();
    };
  }
}

interface IBulkTaskEditControllerScope extends angular.IScope {
  Tasks: TaskDto[];
  DragAndDropPanel: DragAndDropPanel;
  UserSearchText: string;
  SelectedPriority: number | string | PriorityDto;
  SelectedQueue: number | string | QueueDto;
  SelectedUser: number | string | SimpleUserDto;
  SelectedState: number | string | StateDto;
  Users: SimpleUserDto[];
  Priorities: PriorityDto[];
  States: StateDto[];
  Queues: QueueDto[];

  QueryUser: (searchString: string) => SimpleUserDto[];
  SaveChanges: () => Promise<void>;
}
