<div class="dialog-container">

  <div mat-dialog-title class="dialog-title"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle>

    <div>
      Xml Import
    </div>

    <button mat-icon-button (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>

  </div>

  <div class="attachments-drop-zone" appFileDragOver (filesDropped)="fileDrop($event)">
    <input type="file" (change)="fileChange($event)" />
    <div class="description">
      <mat-icon>file_upload</mat-icon>
      <a href="#">Hier klicken oder Datei ablegen</a>
    </div>
  </div>


  <div class="content-container" *ngIf="readFile">

    <div class="item-container">
      <span> invoiceNumber: {{readFile.invoiceNumber}}</span>
      <span> invoiceDate: {{readFile.invoiceDate}}</span>
      <span> taxedAmount: {{readFile.taxedAmount}}</span>
      <span> vatRate: {{readFile.vatRate}}</span>
      <span> amount: {{readFile.amount}}</span>
      <span> totalGrossAmount: {{readFile.totalGrossAmount}}</span>
      <span> payableAmount: {{readFile.payableAmount}}</span>
    </div>

    <mat-divider></mat-divider>

    <div class="row-container">
      <span class="title-container">Item: </span>
      <span class="title-container">Vertrag:</span>
    </div>

    <mat-divider></mat-divider>

    <div *ngFor="let item of readFile.items" class="item-container">

      <div class="row-container">
        <div class="column-container-50">
          <span> positionNumber: {{item.item.positionNumber}}</span>
          <span> description: {{item.item.description}}</span>
          <span> quantity: {{item.item.quantity}}</span>
          <span> unit: {{item.item.unit}}</span>
          <span> unitPrice: {{item.item.unitPrice}}</span>
          <span> fromDate: {{item.item.fromDate| date}}</span>
          <span> toDate: {{item.item.toDate| date}}</span>
          <span> lineItemAmount: {{item.item.lineItemAmount}}</span>
        </div>

        <div class="column-container-50" *ngIf="item.contract">

          <div class="row-container">
            <div class="column-container-50">
              <span> {{item.contract.type.description }} #{{item.contract.contractId}}</span>
              <span>Titel: {{item.contract.title}}</span>
              <span>Intervall: {{item.contract.intervallMonts}}</span>
              <span>Gültig Bis: {{item.contract.dueDate | date}} </span>
              <span>Verr. Bis: {{item.contract.billingDate| date}} </span>
            </div>

            <div class="column-container-50">
              <button mat-raised-button *ngIf="!isSameValidUntilDate(item)" color="primary" (click)="updateContractValidUtilDate(item)">Gültig bis Datum übernehmen</button>
              <button mat-raised-button *ngIf="isSameValidUntilDate(item)" (click)="updateContractValidUtilDate(item)">Gültig bis Datum übernehmen</button>
              <mat-slide-toggle [formControl]="item.contractAccountableControl" (change)="updateContractAccountable(item)">Zu verrechnen</mat-slide-toggle>
            </div>
          </div>


        </div>

        <div class="column-container" *ngIf="!item.contract && !item.hasXmlContractType">
          <div>
            {{getXmlContractTypeDescription(item)}}
          </div>

          <mat-form-field *ngIf="item.contractTypeControl">
            <mat-label>Vertrags Typ</mat-label>
            <mat-select [formControl]="item.contractTypeControl">
              <mat-option *ngFor="let type of contractTypes" [value]="type.contractTypeId">{{type.description}}</mat-option>
            </mat-select>
          </mat-form-field>

          <button mat-raised-button color="primary" (click)="assignContractType(item)" [disabled]="!item.contractTypeControl.value">Vertragstyp Zuweisen</button>
        </div>

        <div class="column-container" *ngIf="!item.contract && item.hasXmlContractType">
          <span>Kein Vertrag gefunden</span>
        </div>

      </div>

    </div>



  </div>


</div>