import angular from "angular";
import * as $ from "jquery";
import * as moment from "moment";
import { globalValues } from "./globalValues";
import { IReceiptQueryResult } from "./ReceiptQueryResult";
import { ContactsService } from '../Services/ContactsService';
import { INavigationCallbackScope } from "./INavigationCallbackScope";
import { INavigationScope } from "./INavigationScope";

// Alte Hilfsmethoden, die aus hestia-common.js stammen.
// hestia-common.js definierte globale Funktionen, was in webpack Probleme macht
// (und generell keine gute Idee ist). Daher werden die Fuktionen hierher migriert.

export function numberPad(num: number, size?: number): string {
  let s = String(num);
  while (s.length < (size || 2)) {
    s = "0" + s;
  }
  return s;
}

export function arrayPushIfNotExist(array: any[], item: any): boolean {
  if (array.indexOf(item) === -1) {
    array.push(item);
    return true;
  }
  return false;
}

export function arrayRemove(array: any[], elem: any, all: boolean = false): any[] {
  for (let i = array.length - 1; i >= 0; i--) {
    if (array[i] === elem) {
      array.splice(i, 1);
      if (!all)
        break;
    }
  }
  return array;
}

export function stringifyObject(object: any, ignore?: string[]): string {
  let stringfyCache: any[] = [];
  const result = JSON.stringify(object, function (key, value) {
    if (ignore && ignore.filter(f => f === key).length) {
      return undefined;
    }
    if (key === "$$hashKey" || key === "_hestiaDataId") {
      return undefined;
    } else {
      if (typeof value === 'object' && value !== null) {
        if (stringfyCache.indexOf(value) !== -1) {
          // Duplicate reference found
          try {
            // If this value does not reference a parent it can be deduped
            return JSON.parse(JSON.stringify(value));
          } catch (error) {
            // discard key if value cannot be deduped
            return;
          }
        }
        // Store value in our collection
        stringfyCache.push(value);
      }
      return value;
    }
  });
  stringfyCache = null;
  return result;
}

export function cloneObject(object: any, ignore?: string[]): any {
  const result = JSON.parse(stringifyObject(object, ignore));
  return result;
}


export function convertDate(value: moment.MomentInput): moment.Moment {
  return value ? moment(value) : null;
}

export function formatDate(value: moment.Moment | Date): string {
  if (value) {
    value = moment(value);
    return value.format("DD.MM.YYYY HH:mm:ss");
  }
  return null;
}


export function convertTimeSpan(value: number): number {
  return value ? moment.duration(value).asMilliseconds() : null;
}

export function formatDateShort(value: moment.Moment): string {
  if (value) {
    return value.format("DD.MM.YYYY");
  }
  return null;
}


export function copyToClipboard(text) {
  const $temp = $("<textarea>");
  $("body").append($temp);
  $temp.val(text).select();
  document.execCommand("copy");
  $temp.remove();
}


export function cleanupPhoneNumber(number: string): string {
  if (number) {
    return number.replace(/\s/g, '');
  }
  return null;
}

export function copyValues(source: any, destination: any) {
  for (let key in source) {
    destination[key] = source[key];
  }
}


export function addNavigationCallback($scope: INavigationCallbackScope, tag: string, callback: () => void) {
  if (!$scope.NavigationCallbacks) {
    $scope.NavigationCallbacks = [];
  }
  if (tag === null) {
    $scope.DefaultNavigationCallback = callback;
  } else {
    $scope.NavigationCallbacks.push({
      Tag: tag,
      Callback: callback
    });
  }

}

export function formatTime(date: moment.Duration | number, hideSeconds?: boolean): string {
  if (!date) {
    if (hideSeconds === true) {
      return "00:00";
    }
    return "00:00:00";
  }
  let currentDate = null;
  if (!date["asSeconds"]) {
    currentDate = moment.duration(date);
  } else {
    currentDate = date;
  }
  if (hideSeconds === true) {
    if (currentDate.asSeconds() % 60 > 30) {
      currentDate = currentDate.add(1, 'minutes');
    }
  }
  const hours = Math.floor(currentDate.asHours());
  const mins = Math.floor(currentDate.asMinutes()) - hours * 60;
  const sec = Math.floor(currentDate.asSeconds()) - hours * 60 * 60 - mins * 60;
  let result;

  if (hideSeconds === true) {
    result = numberPad(hours) + ":" + numberPad(mins);
  } else {
    result = numberPad(hours) + ":" + numberPad(mins) + ":" + numberPad(sec);
  }

  return result;
}

export function addNavigation($scope: INavigationScope, defaultNavigation: string) {
  $scope.ActiveTagText = "Lade ...";
  $scope.DefaultNavigationItem = defaultNavigation;
  $scope.Navigate = function (tag: string, first?: boolean) {
    $scope.IsNavigating = true;
    if ($scope.Storage.GetOrCreate("ActiveTab", $scope.DefaultNavigationItem) !== tag || first || !$("#b" + tag).hasClass('active')) {
      $scope.NavigationTag = tag;
      //const delay = first ? 0 : 50;
      if ($(".menu-button").length > 0) {
        $scope.Storage.Set("ActiveTab", tag);
        $(".menu-button").removeClass('active');
        $("#b" + tag).addClass('active');
        $scope.ActiveTagText = $("#b" + tag).text();
        if ($scope.ActiveTagText.includes("settings")) {
          $scope.ActiveTagText = $scope.ActiveTagText.trim().substr(8);
        }



        //$(".menuContent").animate({ opacity: 0 }, delay).promise().then(function () {
        $(".menuContent").hide(0);
        if ($("#" + tag + ":first").length || $("." + tag + ":first").length) {
          if ($scope.NavigationCallbacks) {
            const callback = $scope.NavigationCallbacks.filter(f => f.Tag === tag);
            if (callback && callback.length) {
              setTimeout(callback[0].Callback);
            } else {
              if ($scope.DefaultNavigationCallback) {
                setTimeout($scope.DefaultNavigationCallback);
              }
            }
          }
          $("." + tag + ":first").show(0);
          //$("." + tag + ":first").animate({ opacity: 1 }, delay);
          $("#" + tag + ":first").show(0);
          //$("#" + tag + ":first").animate({ opacity: 1 }, delay);
        }
        //});
      }
      else {
        $(".menuContent").hide(0);
      }
    }
  };
}

export function globalReceiptQuery(
  query: string,
  $q: angular.IQService,
  $timeout: angular.ITimeoutService,
  ContactsService: ContactsService,
  CachedAddressId?: number) {

  const deferred = $q.defer<any[]>();
  if (globalValues.EMailReceiptQueryTimeout !== null) {
    $timeout.cancel(globalValues.EMailReceiptQueryTimeout);
  }
  globalValues.EMailReceiptQueryTimeout = $timeout(() => {
    let contacts = [];
    if (query && query.length || !CachedAddressId) {
      contacts = ContactsService.GetContactsBySearchText(query);
    } else {
      contacts = ContactsService.GetContactForAddressId(CachedAddressId);
    }

    const result: IReceiptQueryResult[] = [];
    for (let cIndex = 0; cIndex < contacts.length; cIndex++) {
      if (result.length > 20)
        break;
      const c = contacts[cIndex];
      c.MailAddresses.forEach(m => {
        if (result.length <= 20) {
          const isInList = result.filter(f => f.EMail === m.Address);
          if (isInList.length === 0) {
            result.push({
              EMailAddress: m.Address,
              DisplayName: c.DisplayName,
              Image: "/ClientApp/src/ajs/Images/c_placeholder.png"
            });
          }
        } else {
          return;
        }
      });
    }
    result.sort((a, b) => a.DisplayName > b.DisplayName ? 1 : b.DisplayName > a.DisplayName ? -1 : 0);
    globalValues.EMailReceiptQueryTimeout = null;
    deferred.resolve(result);
  }, 250);
  return deferred.promise;
}

export function HasScrollbar(selector: string) {
  const element = $(selector);
  if (element && element[0]) {
    const scrollHeight = element[0].scrollHeight;
    const height = element.height();
    const result = scrollHeight > height;
    return result;
  }
  return false;
}
