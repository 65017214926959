import angular, { IPromise, IQService, IScope, ITimeoutService } from 'angular';
import * as Chart from 'chart.js';
import moment from 'moment';
import { ActionDefinition, ActionGroup, ColumnDefinition, HestiaDataTable } from 'src/ajs/Directives/HestiaDataTableDirective';
import { cloneObject, convertDate, copyValues, formatDate, numberPad } from 'src/ajs/Utils/HestiaCommon';
import { Api1Service } from 'src/app/core/services/api1.service';
import { ArrayUtils } from 'src/app/shared/utils/array-utils';
import { addDefaultScopeFunctions } from '../Functions/add-default-scope-functions';
import { DefaultChartResultDto } from '../Models/Charting/DefaultChartResultDto';
import { ThemeDto } from '../Models/ThemeDto';
import { ApiHandlerService } from '../Services/ApiHandlerService';
import { GlobalValueService } from '../Services/GlobalValueService';
import { LocalizationService, PluginDict } from '../Services/Localization/LocalizationService';
import { StorageService } from '../Services/StorageService';

function ValidateEmail(mail: string): boolean {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
}

function ValidatePhone(phone: string): boolean {
  return /^\+{0,1}\d+$/.test(phone);
}


const NodeTypeEnum = { "Mandator": 0, "Folder": 1, "Worker": 2, "Client": 3 };
Object.freeze(NodeTypeEnum);

Chart.defaults.LineWithLine = Chart.defaults.line;
Chart.controllers.LineWithLine = Chart.controllers.line.extend({
  draw: function (ease) {
    Chart.controllers.line.prototype.draw.call(this, ease);
    if (!this.chart.isZooming) {
      if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
        const activePoint = this.chart.tooltip._active[0],
          ctx = this.chart.ctx,
          x = activePoint.tooltipPosition().x,
          topY = this.chart.scales['y-axis-0'].top,
          bottomY = this.chart.scales['y-axis-0'].bottom;

        // draw line
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 2;
        ctx.strokeStyle = 'rgba(255,0,0,0.2)';
        ctx.stroke();
        ctx.restore();
      }
    }
  }
});


function checkChartingConfig(myData) {
  if (myData.options) {
    if (myData.options.scales) {
      if (myData.options.scales.xAxes) {
        for (let i = 0; i < myData.options.scales.xAxes.length; i++) {
          if (myData.options.scales.xAxes[i].HideIfTickNumberExceeds) {
            myData.options.scales.xAxes[i].afterBuildTicks = function (axes) {
              if (axes.ticks && axes.ticks.length >= axes.chart.scales['x-axis-0'].ticks.length) {
                axes.hidden = true;
                axes.options.display = false;
              } else {
                axes.hidden = false;
                axes.options.display = true;
              }
            };
          }
        }
      }
    }
  }
  myData.data.datasets.forEach(function (set) {
    if (set.backgroundColor) {
      set.backgroundColor = 'rgba(' + set.backgroundColor.R + ',' + set.backgroundColor.G + ',' + set.backgroundColor.B + ',' + set.backgroundColor.A + ')';
    }
    if (set.pointBackgroundColor && set.pointBackgroundColor.length) {
      for (let i = 0; i < set.pointBackgroundColor.length; i++) {
        set.pointBackgroundColor[i] = 'rgba(' + set.pointBackgroundColor[i].R + ',' + set.pointBackgroundColor[i].G + ',' + set.pointBackgroundColor[i].B + ',' + set.pointBackgroundColor[i].A + ')';
      }
    }
  });
}

createMonitoringController.$inject = ['$scope', '$mdDialog', '$q', '$timeout', 'ApiHandlerService', 'Api1ServiceNgx', 'StorageService', 'MonitoringService', 'LocalizationService', 'GlobalValueService', 'theme'];

export function createMonitoringController(
  $scope: IMonitoringControllerScope,
  $mdDialog: angular.material.IDialogService,
  $q: IQService,
  $timeout: ITimeoutService,
  ApiHandlerService: ApiHandlerService,
  api: Api1Service,
  StorageService: StorageService,
  MonitoringService: any,
  LocalizationService: LocalizationService,
  GlobalValueService: GlobalValueService,
  theme: ThemeDto
) {
  addDefaultScopeFunctions($scope, theme);
  $scope.Localization = LocalizationService.GetPluginDict("Monitoring");
  $scope.SelectedItemCaption = "((SelectedItemCaption))";
  $scope.IsLoading = true;
  $scope.ShowSubElements = true;
  $scope.ToggleSpeed = "fast";
  $scope.SelectedMandator = GlobalValueService.get('currentMandator');

  $scope.QueryGolbalHosts = function (searchString: string) {
    let result = null;
    const deferred = $q.defer();
    const request = ApiHandlerService.SendRequest("Monitoring", "QueryHostByName", { query: searchString }).then(function (data) {
      result = data.Result;
      deferred.resolve(result);
    });
    return deferred.promise;
  };

  $scope.SelectedHostChanged = function (node: any) {
    $scope.HostContent.LoadHostNode(node);
  };

  $scope.$on('bindMandator', function (event, mandator) {
    $scope.SelectedMandator = mandator;
    $scope.NavigationTree.Initialize();
    if ($scope.CurrentMandatorId) {
      try {
        MonitoringService.UnregisterBaseMandatorr($scope.CurrentMandatorId);
      } catch (err) { /*ignore*/ }

    }
    $scope.CurrentMandatorId = $scope.SelectedMandator.Id;
    if ($scope.CurrentMandatorId) {
      try {
        MonitoringService.RegisterBaseMandator($scope.CurrentMandatorId);
      } catch (err) { /*ignore*/ }
    }
  });


  $scope.getFromatedDate = function (value: moment.MomentInput) {
    return formatDate(convertDate(value));
  };
  $scope.GetGbFromKb = function (bytes: number) {
    return (bytes / 1024 / 1024).toFixed(2);
  };
  $scope.GetGbFromBytes = function (bytes: number) {
    return bytes / 1024 / 1024 / 1024;
  };
  $scope.HostContent = {
    IsLoading: true,
    Data: null,
    HostId: null,
    NodeInfo: {},
    CpuLoad: null,
    RamLoad: null,
    DiskLoad: null,
    DiskLoads: null,
    ShouldUpdate: false,
    CpuRamChartingPeriod: 0,
    DiskChartingPeriod: 1,
    Filter: {
      ServiceName: null,
      ServiceDescription: null,
      StartMode: ["Auto", "Manual", "Disabled"],
      States: ["Running", "Stopped"],
      State: ["Running", "Stopped"],
      MonitoredState: ["", "Running", "Stopped"],
      StartModes: ["Auto", "Manual", "Disabled"]
    },
    FormatHealthStatus: function (healthStatus) {
      switch (healthStatus) {
        case 0:
          return "Healty";
        case 1:
          return "Warning";
        case 2:
          return "Unhealty";
        default:
          return "Unknown";
      }
    },
    FormatBusType: function (type) {
      switch (type) {
        case 0:
          return "Unkown";
        case 1:
          return "SCSI";
        case 2:
          return "ATAPI";
        case 3:
          return "ATA";
        case 4:
          return "IEEE1394";
        case 5:
          return "SSA";
        case 6:
          return "Fibre Channel";
        case 7:
          return "USB";
        case 8:
          return "RAID";
        case 9:
          return "iSCSI";
        case 10:
          return "SAS";
        case 11:
          return "SATA";
        case 12:
          return "SD";
        case 13:
          return "MMC";
        case 14:
          return "MAX";
        case 15:
          return "File Backed Virtual";
        case 16:
          return "Storage Spaces";
        case 17:
          return "NVMe";
        default:
          return "Unknown";
      }
    },
    CalculateFailedServices: function () {
      $scope.HostContent.FailedServiceCount = $scope.HostContent.Data.MonitoredServices.filter(f => f.MonitoredState !== f.State).length;
    },
    SetServiceMonitorung: function (service) {
      const filtered = $scope.HostContent.Data.MonitoredServices.filter(s => s === service);
      if (service.MonitoredState && service.MonitoredState !== '') {
        if (!(filtered && filtered.length)) {
          $scope.HostContent.Data.MonitoredServices.push(service);
        }
      } else {
        $scope.HostContent.Data.MonitoredServices = $scope.HostContent.Data.MonitoredServices.filter(s => s !== service);
      }
      $scope.HostContent.CalculateFailedServices();
      $scope.HostContent.FilterServices();
      ApiHandlerService.SendRequest("Monitoring", "SetServiceMonitorung", { hostId: this.NodeInfo.Id, serviceconstiantId: service.ServiceconstiantId, monitoredState: service.MonitoredState });
    },
    CalculateDiskUsage: function (partition) {
      return 100 - partition.LogicalDiskFreeSpace / partition.LogicalDiskSize * 100;
    },
    DiskStateOk: function (partition) {
      return !this.DiskStateWarning(partition) && !this.DiskStateError(partition);
    },
    DiskStateWarning: function (partition) {
      let limit = partition.DiskThresholdWarning;
      if (!limit) {
        limit = this.Data.DiskThresholdWarning;
      }
      if (limit && !this.DiskStateError(partition)) {
        return limit < this.CalculateDiskUsage(partition);
      }
      return undefined;
    },
    DiskStateError: function (partition) {
      let limit = partition.DiskThresholdError;
      if (!limit) {
        limit = this.Data.DiskThresholdError;
      }
      if (limit) {
        return limit < this.CalculateDiskUsage(partition);
      }
      return undefined;
    },
    ChangeOnlineCheck: function () {
      this.Data.GenerateErrorWhenOffline = !this.Data.GenerateErrorWhenOffline;
      ApiHandlerService.SendRequest("Monitoring", "ChangeOnlineChecking", { hostId: this.NodeInfo.Id, state: this.Data.GenerateErrorWhenOffline });
    },
    ChangeMonitoring: function () {
      const object = this;
      if (this.Data.IsMonitored) {
        ApiHandlerService.SendRequest("Monitoring", "DeactivateHostMonitoring", { hostId: this.NodeInfo.Id }).then(function (data) {
          object.Data.IsMonitored = false;
        });
      } else {
        ApiHandlerService.SendRequest("Monitoring", "ActivateHostMonitoring", { hostId: this.NodeInfo.Id }).then(function (data) {
          object.Data.IsMonitored = true;
        });
      }
    },
    PeriodicReload: function () {
      const object = $scope.HostContent;
      if (object.CurrentUrl !== location.href)
        return;
      if ($scope.HostContent.Data) {

        ApiHandlerService.SendRequest("Monitoring", "GetPeriodicData", { hostId: object.HostId }).then(function (data) {
          object.RamLoad = data.RamLoad;
          object.CpuLoad = data.CpuLoad;
          if (data.DiskLoads) {
            object.DiskLoads = data.DiskLoads;
            object.DiskLoad = data.DiskLoads["_Total"];
            if (object.DiskLoad) {
              object.DiskLoad = 100 - object.DiskLoad;
            }
          }
          else {
            object.DiskLoad = null;
          }
          $timeout(object.PeriodicReload, 1000);
        });
      } else {
        if (object.ShouldUpdate) {
          $timeout(object.PeriodicReload, 1000);
        }
      }

    },
    GenerateCharts: function () {
      this.ChartCpuRam();
      this.ChartDisks();
    },
    CpuRamChartingChanged: function () {
      this.ChartCpuRam();
    },
    DiskChartingChanged: function () {
      this.ChartDisks();
    },
    ChartCpuRam: function () {
      const object = $scope.HostContent;
      api.post<DefaultChartResultDto>("Monitoring", "GetCpuRamChart", { hostId: object.HostId, period: object.CpuRamChartingPeriod }).subscribe(function (data) {
        $('#cpu-ram-chart').remove(); // this is my <canvas> element
        $('#cpu-ram-graph-container').append('<canvas id="cpu-ram-chart" style="height: 250px; width: 100%;"><canvas>');
        const ctx = document.querySelector<HTMLCanvasElement>('#cpu-ram-chart').getContext('2d');
        const myData = data.Data[0];
        unsetNullValues(myData);
        checkChartingConfig(myData);
        object.RamChart = new Chart(ctx, myData);
        object.RamChart.update();
      });
    },
    ChartDisks: function () {
      const object = $scope.HostContent;
      api.post<DefaultChartResultDto>("Monitoring", "GetDiskChart", { hostId: object.HostId, period: object.DiskChartingPeriod }).subscribe(function (data) {
        $('#disk-chart').remove(); // this is my <canvas> element
        $('#disk-graph-container').append('<canvas id="disk-chart" style="height: 250px; width: 100%;"><canvas>');
        const ctx = document.querySelector<HTMLCanvasElement>('#disk-chart').getContext('2d');
        const myData = data.Data[0];
        unsetNullValues(myData);
        checkChartingConfig(myData);
        object.DiskChart = new Chart(ctx, myData);
        object.DiskChart.update();

      });
    },
    PrepareNetworkInfo: function () {
      if (this.Data) {
        this.Data.PreparedNetworkAdapterInfo = this.Data.NetworkAdapterInfo.slice();
        this.Data.PreparedNetworkAdapterInfo.forEach(nai => {
          if (nai.IpAddresses) {
            nai.IpAddressesList = nai.IpAddresses.split(";");
          }
          if (nai.MacAddress) {
            nai.MacAddressList = nai.MacAddress.split(";");
          }
          if (nai.Subnets) {
            nai.SubnetsList = nai.Subnets.split(";");
          }
        });
        this.Data.NetworkTeams.forEach(team => {
          team.Adapters.forEach(adapter => {
            adapter.Info = this.Data.PreparedNetworkAdapterInfo.filter(f => f.SystemGuid === adapter.ExternalKey)[0];
            ArrayUtils.remove(this.Data.PreparedNetworkAdapterInfo, adapter.Info);
          });
          team.Interfaces.forEach(intf => {
            if (intf.IsPrimary) {
              intf.Description = intf.Name + " (primary)";
            }
            else {
              intf.Description = intf.Name + " (VLan: " + intf.VLanId + ")";
            }
          });
        });
        this.Data.PreparedNetworkAdapterInfo = this.Data.PreparedNetworkAdapterInfo.filter(f => f.IpAddresses);
      }
    },
    SelectStoragePool: function (pool) {
      this.Data.SelectedStoragePool = pool;
    },
    LoadHostNode: function (model) {
      if (model) {
        const deferred = $q.defer();
        const object = $scope.HostContent;
        $scope.HestiaVisibleElements = {};
        object.HostId = model.Id;
        object.IsLoading = true;
        $scope.IsLoading = true;
        ApiHandlerService.SendRequest("Monitoring", "GetNodeContent", { nodeId: model.Id, type: NodeTypeEnum.Client, includeSub: $scope.ShowSubElements }).then(function (data) {
          object.Data = data;
          object.NodeInfo = {
            Id: data.Id,
            Type: NodeTypeEnum.Client,
            Caption: data.HostName
          };
          object.Data.MonitoredServices = object.Data.ServiceInfo.filter(f => f.MonitoredState && f.MonitoredState !== '');
          object.Data.DiskControllerInfo.forEach(controller => {
            controller.Disks = object.Data.DiskInfo.filter(f => f.ControllerId === controller.PNPDeviceID);
          });
          object.Data.OnlineSince = $scope.FormatOnline($scope.ConvertOnline(object.Data.IsOnline, object.Data), object.Data);
          object.CalculateFailedServices();
          object.ShouldUpdate = true;
          object.CurrentUrl = location.href;
          object.PeriodicReload();
          deferred.resolve();
          object.FilterSoftware();
          object.PrepareNetworkInfo();
          object.IsLoading = false;
          $scope.IsLoading = false;
        });
        return deferred.promise;
      } else {
        return null;
      }

    },
    Clear: function () {
      if (this.RamChart) {
        this.RamChart.destroy();
      }
      if (this.DiskChart) {
        this.DiskChart.destroy();
      }
      $scope.ContentModel.SelectedWorker = null;
      this.Data = null;
      this.HostId = null;
      this.NodeInfo = {};
      this.CpuLoad = null;
      this.RamLoad = null;
      this.DiskLoad = null;
      this.DiskLoads = null;
      this.ShouldUpdate = false;
    },
    GetUsedMemoryPercentage: function () {
      return 50;
    },
    FilterServices: function () {
      $scope.HostContent.Data.ServiceInfo.forEach(f => {
        f.IsFiltered = $scope.HostContent.IsServiceFiltered(f);
      });
    },
    FilterSoftware: function () {
      $scope.HostContent.Data.Software.forEach(f => {
        f.IsFiltered = $scope.HostContent.IsSoftwareFiltered(f);
      });
    },
    IsSoftwareFiltered: function (software) {
      let filtered = false;
      if (!filtered) {
        if (this.Filter.SoftwareName) {
          if (software.Name) {
            filtered = software.Name.toUpperCase().indexOf(this.Filter.SoftwareName.toUpperCase()) === -1;
          } else {
            filtered = true;
          }
        }
      }
      if (!filtered) {
        if (this.Filter.SoftwarePublisher) {
          if (software.Publisher) {
            filtered = software.Publisher.toUpperCase().indexOf(this.Filter.SoftwarePublisher.toUpperCase()) === -1;
          } else {
            filtered = true;
          }

        }
      }
      if (!filtered) {
        if (this.Filter.SoftwareVersion) {
          if (software.Version) {
            filtered = software.Version.toUpperCase().indexOf(this.Filter.SoftwareVersion.toUpperCase()) === -1;
          } else {
            filtered = true;
          }
        }
      }
      if (!filtered) {
        if (this.Filter.SoftwareInstallDate) {
          if (software.InstallDate) {
            filtered = software.InstallDate.toUpperCase().indexOf(this.Filter.SoftwareInstallDate.toUpperCase()) === -1;
          } else {
            filtered = true;
          }
        }
      }
      if (!filtered) {
        if (this.Filter.SoftwareInstallLocation) {
          if (software.InstallLocation) {
            filtered = software.InstallLocation.toUpperCase().indexOf(this.Filter.SoftwareInstallLocation.toUpperCase()) === -1;
          } else {
            filtered = true;
          }
        }
      }
      if (!filtered && !this.Filter.SoftwareShowSystemComponents) {
        filtered = software.IsSystemComponent;
      }
      return filtered;
    },
    IsServiceFiltered: function (service) {
      let filtered = false;
      if (!filtered && this.Filter.StartMode) {
        filtered = this.Filter.StartMode.indexOf(service.StartMode) === -1;
      }
      if (!filtered) {
        filtered = this.Filter.State.indexOf(service.State) === -1;
      }
      if (!filtered) {
        filtered = this.Filter.MonitoredState.indexOf(service.MonitoredState) === -1;
      }
      if (!filtered) {
        if (this.Filter.ServiceName) {
          filtered = service.Name.toUpperCase().indexOf(this.Filter.ServiceName.toUpperCase()) === -1;
        }
      }
      if (!filtered) {
        if (this.Filter.ServiceDescription) {
          let f1 = false;
          let f2 = false;
          if (service.Caption) {
            f1 = service.Caption.toUpperCase().indexOf(this.Filter.ServiceDescription.toUpperCase()) !== -1;
          }
          if (service.Description) {
            f2 = service.Description.toUpperCase().indexOf(this.Filter.ServiceDescription.toUpperCase()) !== -1;
          }
          filtered = !(f1 || f2);
        }
      }
      return filtered;
    },
    RenewHostData: function () {
      const object = this;
      this.IsLoading = true;
      ApiHandlerService.SendRequest("Monitoring", "RequestHostInformation", { hostId: this.HostId }).then(function (data) {
        object.LoadHostNode({ Id: object.HostId });
      });
    },
    ShowDiskAlertDialog: function ($event, partition) {
      $mdDialog.show({
        targetEvent: $event,
        controller: 'AddDiskAlertController',
        templateUrl: '/ClientApp/src/ajs/Views/Monitoring/AddDiskAlert.htm',
        locals: {
          Partition: partition
        },
        parent: angular.element(document.body)
      });
    }
  };

  $scope.ConvertOnline = function (online: boolean, model: any) {
    if (!model.LastBootTime) {
      return -1;
    }
    if (online) {
      const end = moment();
      const duration = moment.duration(end.diff(model.LastSystemCheck));
      const minutes = duration.asMinutes();
      if (minutes < 30) {
        return moment.duration(end.diff(moment(model.LastBootTime))).asSeconds();
      } else {
        return 0;
      }
    }
    return 0;
  };

  $scope.FormatOnline = function (value: number, model: any) {
    if (value === 0) {
      return "";
    }
    if (value === -1) {
      return "";
    }
    value = Math.floor(value);
    const seconds = Math.floor(value % 60);
    value = Math.floor(value / 60);
    const days = Math.floor(value / 1440);
    const hours = Math.floor(value % 1440 / 60);
    const minutes = Math.floor(value % 60);
    return days + ":" + numberPad(hours) + ":" + numberPad(minutes) + ":" + numberPad(seconds);
  };

  $scope.ContentModel = {
    IsLoading: true,
    Storage: StorageService.CreateInstance("MonitoringFilter"),
    Filter: {
      Values: {
        Error: true,
        Warn: true,
        Ok: true,
        Monitored: true,
        Unmonitored: true
      },
      ToggleState: function (property) {
        this.Values[property] = !this.Values[property];
        $scope.ContentModel.Storage.Set("FilterValues", this.Values);
        $scope.ContentModel.ApplyFilter();
      }
    },
    CancelRecipientDialog: function () {
      $mdDialog.cancel(this.EditReceiptDialog);
    },
    ActivateInheritance: function (recipient) {
      recipient.IsInherited = true;
      const object = this;
      ApiHandlerService.SendRequest("Monitoring", "SaveEnvironmentSettings", { model: object.Data }).then(function (data) {
        object.Data = data;
        $timeout(() => object.IsLoading = false);
      });
    },
    ConfirmRecipientDialog: function () {
      if ($scope.ContentModel.EditRecipient.IsNew) {
        $scope.ContentModel.EditRecipient.ExternalReceipt = $scope.ContentModel.EditRecipient.ExternalReceipt.toLowerCase();
        $scope.ContentModel.EditRecipient.NotificationLines = [];
        $scope.ContentModel.EditRecipient.IsEMail = ValidateEmail($scope.ContentModel.EditRecipient.ExternalReceipt);
        $scope.ContentModel.EditRecipient.IsPhone = ValidatePhone($scope.ContentModel.EditRecipient.ExternalReceipt);
        angular.forEach($scope.ContentModel.Data.Types, (value) => {
          $scope.ContentModel.EditRecipient.NotificationLines.push({
            TypeId: value.Id,
            TypeLabel: value.Label,
            SendSms: false,
            SendMail: false
          });
        });
        $scope.ContentModel.EditRecipient.IsNew = false;
        if (!$scope.ContentModel.Data.Recipients.filter(f => f.ExternalReceipt === $scope.ContentModel.EditRecipient.ExternalReceipt).length) {
          $scope.ContentModel.Data.Recipients.push($scope.ContentModel.EditRecipient);
        }
      } else {
        copyValues($scope.ContentModel.EditRecipient, $scope.ContentModel.OriginalRecipient);
      }
      $mdDialog.cancel(this.EditReceiptDialog);
    },
    ValidateRecipientDialog: function (form) {
      const control = form.$$controls.filter(f => f.$name === "FormInput")[0];
      let isValid = false;
      if ($scope.ContentModel.EditRecipient.IsNew) {
        isValid = ValidateEmail($scope.ContentModel.EditRecipient.ExternalReceipt) || ValidatePhone($scope.ContentModel.EditRecipient.ExternalReceipt);
      }
      else if ($scope.ContentModel.EditRecipient.IsPhone) {
        isValid = ValidatePhone($scope.ContentModel.EditRecipient.ExternalReceipt);
      } else if ($scope.ContentModel.EditRecipient.IsEMail) {
        isValid = ValidateEmail($scope.ContentModel.EditRecipient.ExternalReceipt);
      }
      control.$setValidity('FormInput', isValid);
      control.$setDirty();
      control.$setTouched();
    },
    UpdateRecipient: function (recipient, ev) {
      this.EditRecipient = cloneObject(recipient);
      this.OriginalRecipient = recipient;
      this.EditReceiptDialog = {
        contentElement: '#addRecipientDialog',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true
      };
      $mdDialog.show(this.EditReceiptDialog);
    },
    AddRecipient: function (ev) {
      this.EditRecipient = { IsNew: true, ExternalReceipt: "Gugu@gaga.at" };
      this.EditReceiptDialog = {
        contentElement: '#addRecipientDialog',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true
      };
      $mdDialog.show(this.EditReceiptDialog);
    },
    IndeterminateCheckedSms: function (recipient) {
      if (recipient.NotificationLines.length) {
        let hasFalse = false;
        let hasTrue = false;
        for (let i = 0; i < recipient.NotificationLines.length; i++) {
          if (!recipient.NotificationLines[i].SendSms) {
            hasFalse = true;
          }
          if (recipient.NotificationLines[i].SendSms) {
            hasTrue = true;
          }
          if (hasFalse && hasTrue) {
            return true;
          }
        }
      }
      else {
        return false;
      }
      return false;
    },
    AllCheckedSms: function (recipient) {
      if (recipient.NotificationLines.length) {
        for (let i = 0; i < recipient.NotificationLines.length; i++) {
          if (!recipient.NotificationLines[i].SendSms) {
            return false;
          }
        }
      }
      else {
        return false;
      }
      return true;
    },
    IndeterminateCheckedMail: function (recipient) {
      if (recipient.NotificationLines.length) {
        let hasFalse = false;
        let hasTrue = false;
        for (let i = 0; i < recipient.NotificationLines.length; i++) {
          if (!recipient.NotificationLines[i].SendMail) {
            hasFalse = true;
          }
          if (recipient.NotificationLines[i].SendMail) {
            hasTrue = true;
          }
          if (hasFalse && hasTrue) {
            return true;
          }
        }
      }
      else {
        return false;
      }
      return false;
    },
    CheckAllMail: function (recipient) {
      let i = 0;
      if (!recipient.AllMailChecked) {
        for (i = 0; i < recipient.NotificationLines.length; i++) {
          recipient.NotificationLines[i].SendMail = true;
        }
        recipient.AllMailChecked = true;
      } else {
        for (i = 0; i < recipient.NotificationLines.length; i++) {
          recipient.NotificationLines[i].SendMail = false;
        }
        recipient.AllMailChecked = false;
      }
    },
    CheckAllSms: function (recipient) {
      let i = 0;
      if (!recipient.AllSmsChecked) {
        for (i = 0; i < recipient.NotificationLines.length; i++) {
          recipient.NotificationLines[i].SendSms = true;
        }
        recipient.AllSmsChecked = true;
      } else {
        for (i = 0; i < recipient.NotificationLines.length; i++) {
          recipient.NotificationLines[i].SendSms = false;
        }
        recipient.AllSmsChecked = false;
      }
    },
    CheckRecipient: function (recipient) {
      recipient.AllSmsChecked = this.AllCheckedSms(recipient);
      recipient.AllMailChecked = this.AllCheckedMail(recipient);
    },
    AllCheckedMail: function (recipient) {
      if (recipient.NotificationLines.length) {
        for (let i = 0; i < recipient.NotificationLines.length; i++) {
          if (!recipient.NotificationLines[i].SendMail) {
            return false;
          }
        }
      }
      else {
        return false;
      }
      return true;
    },
    IsHostIn: function (f) {
      if (!this.Filter.Values.Error && f.LastState === 2)
        return false;
      if (!this.Filter.Values.Warn && f.LastState === 1)
        return false;
      if (!this.Filter.Values.Ok && f.LastState === 0)
        return false;
      if (!this.Filter.Values.Monitored && f.IsMonitored)
        return false;
      if (!this.Filter.Values.Unmonitored && !f.IsMonitored)
        return false;
      return true;
    },
    AddWatcher: function (event) {
      if (this.Data.Watchers) {
        const watcher = {};
        this.Data.Watchers.push(watcher);
        this.SelectWatcher(watcher);
      }
    },
    SelectWatcher: function (watcher) {
      this.Data.SelectedWatcher = watcher;
    },
    ApplyFilter: function () {
      if (this.Data.Hosts) {
        this.Data.FilteredHosts = this.Data.Hosts.filter(f => {
          return $scope.ContentModel.IsHostIn(f);
        });
        this.Table.UpdateSource(this.Data.FilteredHosts, $timeout);
      }
    },
    Table: new HestiaDataTable(StorageService.CreateInstance("MonitoringHostTable"), $timeout, $scope, $scope.Localization),
    ChartInternetSpeed: function () {
      const object = $scope.ContentModel.SelectedWorker;
      ApiHandlerService.SendRequest("Monitoring", "GetWorkerSpeedChart", { workerIdentifier: object.WorkerIdentifier }).then(function (data) {
        $('#internet-speed-chart').remove(); // this is my <canvas> element
        $('#internet-speed-graph-container').append('<canvas id="internet-speed-chart" style="height: 250px; width: 100%;"><canvas>');
        const ctx = document.querySelector<HTMLCanvasElement>('#internet-speed-chart').getContext('2d');
        const myData = data.Data[0];
        unsetNullValues(myData);
        checkChartingConfig(myData);
        object.SpeedChart = new Chart(ctx, myData);
        object.SpeedChart.update();
      });
    },
    SelectWorker: function (worker) {
      this.SelectedWorker = worker;
      this.ChartInternetSpeed();
    },
    ToggleInternetMonitoring: function (worker) {
      ApiHandlerService.SendRequest("Monitoring", "ToggleInternetMonitoring", { workerIdentifier: worker.WorkerIdentifier }).then(function (data) {
        worker.SpeedTestDisabled = data.Success;
        if (!worker.SpeedTestDisabled) {
          $scope.ContentModel.ChartInternetSpeed();
        }
      });
    },
    InitializeBaseData: function () {



      this.Table.ItemClicked = $scope.HostContent.LoadHostNode;
      this.Table.AddActionGroup(new ActionGroup("L[TextButtonActions]", [
        new ActionDefinition("L[TextSystemquery]", 'autorenew', this.QuerySystem),
        new ActionDefinition("L[TextActivateMonitoring]", 'alarm_on', this.ActivateMonitoring),
        new ActionDefinition("L[TextDeactivateMonitoring]", 'alarm_off', this.DeactivateMonitoring)
      ]));
      this.Table.UpdateColumns([
        new ColumnDefinition({
          PropertyPath: 'LastState', ColumnWidth: 75, Heading: "L[TableHeadingState]", Resizeable: true, Sortable: true, Template: "/ClientApp/src/ajs/Views/Monitoring/StatusTemplate.htm"
        }),
        new ColumnDefinition({ PropertyPath: 'HostName', Heading: "L[TableHeadingHostName]", Resizeable: true, Sortable: true, AllowFilter: true }),
        new ColumnDefinition({ PropertyPath: 'OperatingSystem', Heading: "L[LabelOperatingSystem]", Resizeable: true, Sortable: true }),
        new ColumnDefinition({ PropertyPath: 'Architecture', Align: 'center', Heading: "L[LabelArchitecture]", Resizeable: true, Sortable: true }),
        new ColumnDefinition({ PropertyPath: 'OperatingSystemVersion', Align: 'right', Heading: "L[LabelVersion]", Resizeable: true, Sortable: true }),
        new ColumnDefinition({ PropertyPath: 'LastSystemCheck', Heading: "L[LabelLastCheck]", Align: 'right', Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDate }),
        new ColumnDefinition({ PropertyPath: 'LastBootTime', Heading: "L[LabelLastBoot]", Align: 'right', Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDate }),
        new ColumnDefinition({ PropertyPath: 'IsOnline', Heading: "L[LabelUptime]", Align: 'right', Resizeable: true, Sortable: true, RenderFunction: $scope.ConvertOnline, Formatter: $scope.FormatOnline }),
        new ColumnDefinition({ PropertyPath: 'VmHost', Heading: "L[LabelLastHypervisor]", Resizeable: true, Sortable: true }),
        new ColumnDefinition({ PropertyPath: 'CurrentUser', Heading: "L[LabelLastUser]", Resizeable: true, Sortable: true }),
        new ColumnDefinition({ PropertyPath: 'TotalMemory', Align: 'right', Heading: "L[LabelRam]", Resizeable: true, Sortable: true, RenderFunction: convertRam, Formatter: formatRam }),
        new ColumnDefinition({ PropertyPath: 'LastFreeMemory', Align: 'right', Heading: "L[TableHeadingLastFreeMemory]", Resizeable: true, Sortable: true, RenderFunction: convertRam, Formatter: formatRam }),
        new ColumnDefinition({ PropertyPath: 'CpuUsagePercent', Align: 'right', Heading: "L[TableHeadingCpuUsagePercent]", Resizeable: true, Sortable: true, Formatter: formatPercentage }),
        new ColumnDefinition({ PropertyPath: 'RamUsagePercent', Align: 'right', Heading: "L[TableHeadingRamUsagePercent]", Resizeable: true, Sortable: true, Formatter: formatPercentage }),
        new ColumnDefinition({ PropertyPath: 'DiskUsagePercent', Align: 'right', Heading: "L[TableHeadingDiskUsagePercent]", Resizeable: true, Sortable: true, Formatter: formatPercentage })
      ]);
    },
    AddWarningAddress: function (valid) {
      if (valid && this.Data.AddidtionalWarningMail) {
        this.Data.WarningAddressList.push(this.Data.AddidtionalWarningMail);
        this.Data.AddidtionalWarningMail = "";
      }
    },
    AddErrorAddress: function (valid) {
      if (valid && this.Data.AddidtionalErrorMail) {
        this.Data.ErrorAddressList.push(this.Data.AddidtionalErrorMail);
        this.Data.AddidtionalErrorMail = "";
      }
    },
    RemoveWarningAddress: function (address) {
      ArrayUtils.removeWhere(this.Data.WarningAddressList, (x) => x === address);
    },
    RemoveErrorAddress: function (address) {
      ArrayUtils.removeWhere(this.Data.WarningAddressList, (x) => x === address);
    },
    ActivateMonitoring: function (model) {
      ApiHandlerService.SendRequest("Monitoring", "ActivateHostMonitoring", { hostId: model.Id }).then(function (data) {
        model.IsMonitored = true;
      });
    },
    DeactivateMonitoring: function (model) {
      ApiHandlerService.SendRequest("Monitoring", "DeactivateHostMonitoring", { hostId: model.Id }).then(function (data) {
        model.IsMonitored = false;
      });
    },
    QuerySystem: function (model) {
      model.IsLoading = true;
      ApiHandlerService.SendRequest("Monitoring", "RequestHostInformation", { hostId: model.Id }).then(function (data) {
        model.HostName = data.HostName;
        model.Id = data.Id;
        model.OperatingSystem = data.OperatingSystem;
        model.Architecture = data.Architecture;
        model.LastSystemCheck = data.LastSystemCheck;
        model.LastBootTime = data.LastBootTime;
        model.VmHost = data.VmHost;
        model.CurrentUser = data.CurrentUser;
        model.TotalMemory = data.TotalMemory;
        model.LastFreeMemory = data.LastFreeMemory;
        model.OperatingSystemVersion = data.OperatingSystemVersion;
        model.CpuUsagePercent = data.CpuUsagePercent;
        model.RamUsagePercent = data.RamUsagePercent;
        model.DiskUsagePercent = data.DiskUsagePercent;
        model.LastState = data.LastState;
        model.IsLoading = false;
      });
    },
    SaveSettings: function () {
      const object = this;
      object.IsLoading = true;
      if (object.IsSettingsView) {
        ApiHandlerService.SendRequest("Monitoring", "SaveEnvironmentSettings", { model: object.Data }).then(function (data) {
          object.Data = data;
          $timeout(() => object.IsLoading = false);
        });
      }
    },
    Initialize: function (settings) {
      this.Filter.Values = this.Storage.GetOrCreate("FilterValues", this.Filter.Values);
      const deferred = $q.defer();
      const object = this;
      object.IsLoading = true;
      if (object.CurrentNodeId) {
        MonitoringService.UnregisterFolderWatch(object.CurrentNodeId);
      }
      object.IsSettingsView = settings;
      object.CurrentNodeId = $scope.NavigationTree.SelectedNode.Id;
      MonitoringService.RegisterFolderWatch(object.CurrentNodeId);
      if (object.IsSettingsView) {
        ApiHandlerService.SendRequest("Monitoring", "GetEnvironmentSettings", { id: $scope.NavigationTree.SelectedNode.Id }).then(function (data) {
          object.Data = data;
          deferred.resolve();
          $timeout(() => object.IsLoading = false);
        });
      } else {
        ApiHandlerService.SendRequest("Monitoring", "GetNodeContent", { nodeId: $scope.NavigationTree.SelectedNode.Id, type: $scope.NavigationTree.SelectedNode.Type, includeSub: $scope.ShowSubElements }).then(function (data) {
          object.Data = data;
          object.ApplyFilter();
          deferred.resolve();
          $timeout(() => object.IsLoading = false);
        });
      }
      return deferred.promise;
    }
  };

  $scope.NavigationTree = {
    Data: [],
    IsLoading: true,
    SelectedNode: null,
    Initialize: function () {
      const deferred = $q.defer();
      const object = $scope.NavigationTree;
      object.IsLoading = true;

      ApiHandlerService.SendRequest("Monitoring", "GetNodes", { nodeId: $scope.SelectedMandator.Id, type: NodeTypeEnum.Mandator }).then(function (data) {
        object.Data = data.Nodes;
        // Parent-Referenzen Setzen
        object.SetParentNodes(object.Data, null);
        if (object.Data.length) {
          object.SelectNode(null, object.Data[0]);
        }
        object.IsLoading = false;
        deferred.resolve();
      });
      return deferred.promise;
    },
    ShowContext: function (node) {
      this.ContextNode = node;
    },
    SetParentNodes: function (nodes, parent) {
      for (let i = 0; i < nodes.length; i++) {
        nodes[i].Parent = parent;
        this.SetParentNodes(nodes[i].ChildNodes, nodes[i]);
      }
    },
    GetCurrentParents: function () {
      let currentNode = this.SelectedNode;
      if (currentNode) {
        const result = [];
        if ($scope.HostContent.Data) {
          result.splice(0, 0, $scope.HostContent.NodeInfo);
        }
        result.splice(0, 0, currentNode);

        while (currentNode.Parent) {
          currentNode = currentNode.Parent;
          result.splice(0, 0, currentNode);
        }
        return result;
      }
      return undefined;
    },
    UnselectAllChildren: function (root) {
      for (let i = 0; i < root.length; i++) {
        root[i].IsSelected = false;
        this.UnselectAllChildren(root[i].ChildNodes);
      }
    },
    SelectNode: function ($event: any, node: any, settings: boolean) {
      // Unselect all
      this.UnselectAllChildren(this.Data);
      this.SelectedNode = node;
      node.IsSelected = true;
      $scope.HostContent.Clear();
      $scope.ContentModel.Initialize(settings);
    }
  } as NavigationTreeModel;

  $scope.DeleteWorker = function (worker: any, ev: MouseEvent) {
    const confirm = $mdDialog.confirm()
      .title('Soll der Worker gelöscht werden?')
      .textContent('Nach dem Löschen sind möglicherweise einige Hosts nicht mehr erreichbar. Dieser Vorgang kann nicht rückgängig gemacht werden.')
      .ariaLabel('Delete worker')
      .targetEvent(ev)
      .ok('Ja, löschen!')
      .cancel('Nein, verklickt');

    $mdDialog.show(confirm).then(function () {
      worker.IsLoading = true;
      ApiHandlerService.SendRequest("Monitoring", "DeleteWorker", { workerId: worker.Id }).then(function (data) {
        $scope.ContentModel.Initialize();
        worker.IsLoading = false;
      });
    }, function () { });
  };

  $scope.UpdateHostsList = function (worker: any) {
    worker.IsLoading = true;
    ApiHandlerService.SendRequest("Monitoring", "RequestHostList", { workerId: worker.Id }).then(function (data) {
      $scope.ContentModel.Initialize();
      worker.IsLoading = false;
    });
  };

  $scope.FormatDate = function (date: moment.MomentInput) {
    if (date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    }
    return $scope.Localization.Localize("TextNever");
  };

  $scope.SubElementsChanged = function (showSubElements: boolean) {
    $scope.ShowSubElements = showSubElements;
    $scope.ContentModel.Initialize();
  };

  $scope.ToGB = function (value: number) {
    return Math.round(value / 1024 / 1024 / 1024 * 100) / 100;
  };

  $scope.ToTB = function (value: number) {
    return Math.round(value / 1024 / 1024 / 1024 / 1024 * 100) / 100;
  };

  $scope.ToBestUnit = function (value: number) {
    if ($scope.ToGB(value) >= 1024) {
      return $scope.ToTB(value).toFixed(2) + " TB";
    } else {
      return $scope.ToGB(value).toFixed(2) + " GB";
    }
  };

  $scope.round = function (value: number) {
    return Math.round(value);
  };

  $scope.HasVisiblePartition = function (disk: any) {
    if (disk && disk.Partitions) {
      return disk.Partitions.filter(f => f.HasLogicalDisk).length !== 0;
    }
    return undefined;
  };

  $scope.GetFirstVisiblePartition = function (disk: any) {
    return [disk.Partitions.filter(f => f.HasLogicalDisk)[0]];
  };

  $scope.GetPackageUri = function (id: any) {
    return encodeURI("/Api/Monitoring/GetWorkerInstallPackage/" + id);
  };

  $scope.NavigateToSettings = function ($event: any, node: any) {
    $scope.NavigationTree.SelectNode($event, node, true);
  };

  $scope.OnHostChange = function (hostEntry: any) {
    if ($scope.ContentModel && $scope.ContentModel.Data && $scope.ContentModel.Data.Hosts) {
      // TODO: Check if filter changed and add/remove to/from filtered hosts
      let host = $scope.ContentModel.Data.Hosts.filter(f => f.Id === hostEntry.Id);
      if (host.length) {
        host = host[0];
        const wasInBefore = $scope.ContentModel.IsHostIn(host);
        copyValues(hostEntry, host);
        const isInNow = $scope.ContentModel.IsHostIn(host);
        if (wasInBefore !== isInNow) {
          if (wasInBefore) {
            $scope.ContentModel.ApplyFilter();
          }
        }
        try {
          $scope.$apply();
        } catch (err) { /*ignore*/ }
      }
    }
  };
  $scope.OnWorkerChange = function (workerEntry: any) {
    if ($scope.ContentModel && $scope.ContentModel.Data && $scope.ContentModel.Data.Workers) {
      // TODO: Check if filter changed and add/remove to/from filtered hosts
      let worker = $scope.ContentModel.Data.Workers.filter(f => f.Id === workerEntry.Id);
      if (worker.length) {
        worker = worker[0];
        copyValues(workerEntry, worker);
        try {
          $scope.$apply();
        } catch (err) { /*ignore*/ }
      }
    }
  };

  MonitoringService.ReadyChangesEvent(function () {
    // Hub
    MonitoringService.StructuralChangesEvent($scope.NavigationTree.Initialize);
    MonitoringService.HostChangesEvent($scope.OnHostChange);
    MonitoringService.WorkerChangesEvent($scope.OnWorkerChange);

    // Init
    $scope.ContentModel.InitializeBaseData();
    if ($scope.SelectedMandator) {
      $scope.SelectedItemCaption = $scope.SelectedMandator.Description;
      $scope.NavigationTree.Initialize();
      $scope.CurrentMandatorId = $scope.SelectedMandator.Id;
      if ($scope.CurrentMandatorId) {
        try {
          MonitoringService.RegisterBaseMandator($scope.CurrentMandatorId);
        } catch (err) { /*ignore*/ }
      }
    }
    $scope.IsLoading = false;
  });
}


interface IMonitoringControllerScope extends IScope {
  Localization: PluginDict;
  SelectedItemCaption: string;
  IsLoading: boolean;
  ShowSubElements: boolean;
  ToggleSpeed: string;
  SelectedMandator: any;
  HostContent: any;
  NavigationTree: NavigationTreeModel;
  CurrentMandatorId: any;
  HestiaVisibleElements: any;
  ContentModel: any;

  QueryGolbalHosts: (searchString: string) => IPromise<unknown>;
  SelectedHostChanged: (node: any) => any;
  getFromatedDate: (value: moment.MomentInput) => string;
  GetGbFromKb: (bytes: number) => string;
  GetGbFromBytes: (bytes: number) => number;
  FormatOnline: (value: number, model: any) => string;
  ConvertOnline: (online: boolean, model: any) => number;
  UpdateHostsList: (worker: any) => void;
  FormatDate: (date: moment.MomentInput) => string;
  SubElementsChanged: (showSubElements: boolean) => void;
  ToGB: (value: number) => number;
  ToTB: (value: number) => number;
  ToBestUnit: (value: number) => string;
  round: (value: number) => number;
  HasVisiblePartition: (disk: any) => boolean | undefined;
  GetFirstVisiblePartition: (disk: any) => any;
  GetPackageUri: (id: any) => string;
  NavigateToSettings: ($event: any, node: any) => void;
  OnHostChange: (hostEntry: any) => void;
  OnWorkerChange: (workerEntry: any) => void;
  DeleteWorker: (worker: any, ev: MouseEvent) => void;
}

interface NavigationTreeModel {

  Data: any[];
  IsLoading: boolean;
  SelectedNode: any;
  ContextNode: any;

  Initialize: () => angular.IPromise<unknown>;
  ShowContext: (node: any) => void;
  SetParentNodes: (nodes: any, parent: any) => void;
  GetCurrentParents: () => any[];
  UnselectAllChildren: (root: any[]) => void;
  SelectNode: ($event: any, node: any, settings?: boolean) => void;

}


function unsetNullValues(object) {
  for (let key in object) {
    if (object[key] === null) {
      delete object[key];
    } else {
      if (object[key].length) {
        if (!(typeof object[key] === 'string' || object[key] instanceof String)) {
          for (let i = 0; i < object[key].length; i++) {
            unsetNullValues(object[key][i]);
          }
        }
      }
      else if (key !== "0") {
        unsetNullValues(object[key]);
      }
    }
  }
}

function formatPercentage(value: number): string {
  if (value) {
    return Math.round(value * 100) / 100 + " %";
  } else {
    return "na";
  }
}

function formatRam(value: number): string {
  if (value) {
    return value + " GB";
  }
  return null;
}



function convertRam(value: number): number {
  if (value) {
    value = value / 1024 / 1024;
    return Math.round(value * 100) / 100;
  } else {
    return null;
  }
}
