import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Api2Service } from 'src/app/core/services/api2.service';
import { InfoHubScriptGeneratorDialogComponent } from '../components/info-hub-script-generator-dialog/info-hub-script-generator-dialog.component';
import { Observable, firstValueFrom } from 'rxjs';
import { InformationHubScriptGeneratorVariableDto } from '../dto/information-hub-script-generator-variable-dto';
import { InformationHubEntryDto } from '../dto/information-hub-entry-dto';

const controller = "InformationHub";
@Injectable({
  providedIn: 'root'
})
export class InfoHubService {

  constructor(
    private readonly api: Api2Service,
    private readonly dialog: MatDialog,

  ) { }

  getInformationHubEntry(informationHubEntryId: number): Observable<InformationHubEntryDto> {
    return this.api.get(controller, "GetInformationHubEntry", { informationHubEntryId: informationHubEntryId });
  }
  getInformationHubScriptGeneratorVariables(informationHubEntryId: number): Observable<InformationHubScriptGeneratorVariableDto[]> {
    return this.api.get(controller, "GetInformationHubScriptGeneratorVariables", { informationHubEntryId: informationHubEntryId });
  }

  updateInformationHubScriptGeneratorVariables(variables: InformationHubScriptGeneratorVariableDto[], informationHubEntryId: number) {

    const request =
    {
      informationHubEntryId: informationHubEntryId,
      variables: variables
    };
    return this.api.post(controller, "UpdateInformationHubScriptGeneratorVariables", null, request);
  }

  //Dialog
  async openScriptGeneratorDialog(informationHubEntryId: number) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = {
      informationHubEntryId: informationHubEntryId
    };

    const dialog = this.dialog.open<InfoHubScriptGeneratorDialogComponent>(InfoHubScriptGeneratorDialogComponent, dialogConfig);

    const result = await firstValueFrom(dialog.afterClosed());
    return result;
  }
}
