import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { Api1Service } from 'src/app/core/services/api1.service';
import { GetTimeStatisticsRequestDto } from '../dto/get-time-statistics-request-dto';
import { fixTimeStatisticsDto, TimeStatisticsDto } from '../dto/time-statistics-dto';
import { MandatorService } from 'src/app/core/services/mandator.service';

const controller = "Erp";

@Injectable({
  providedIn: 'root'
})
export class ErpService {

  constructor(
    private readonly api: Api1Service,
    private readonly mandatorService: MandatorService

  ) { }


  getTimeStatistics(addressId: number, startDate: Date, endDate: Date): Observable<TimeStatisticsDto[]> {

    const dto: GetTimeStatisticsRequestDto = {
      addressId: addressId,
      startDate: startDate,
      endDate: endDate
    };

    return this.api.post<TimeStatisticsDto[]>(controller, 'GetTimeStatistics', null, { dto: dto, mandatorId: this.mandatorService.selectedMandatorId }).pipe(
      tap(array => {
        array?.forEach(fixTimeStatisticsDto);
      })
    );
  }
}
