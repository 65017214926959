import { SageExportContractGroupDto, fixSageExportContractGroupDto } from "./sage-export-contract-group-dto";

export interface SageExportContractQueueDto {
  id: number;
  isExported: boolean;
  exportDate: Date | null;
  sageExportContractGroups: SageExportContractGroupDto[];

  // nur zum anzeigen
  auftragStatus: number;
  lieferStatus: number;
  rechnungsStatus: number
}

export function fixSageExportContractQueueDto(dto: SageExportContractQueueDto) {

  if (dto) {
    for (const group of dto.sageExportContractGroups) {
      fixSageExportContractGroupDto(group);
    }
    if (dto.exportDate) {
      dto.exportDate = new Date(dto.exportDate);
    }
  }
}