import { FormatDto } from "src/ajs/Models/Label/FormatDto";
import { PrinterDto } from "src/ajs/Models/Label/PrinterDto";
import { TemplateDto } from "src/ajs/Models/Label/TemplateDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { MandatorService } from "src/app/core/services/mandator.service";

createLabelCreateEditTemplateController.$inject = ['$scope', '$mdDialog', 'ApiHandlerService', 'Template', 'Formats', 'Printers', 'OnSuccess', 'MandatorServiceNgx'];

export function createLabelCreateEditTemplateController(
  $scope,
  $mdDialog,
  ApiHandlerService: ApiHandlerService,
  Template: TemplateDto,
  Formats: FormatDto[],
  Printers: PrinterDto[],
  OnSuccess: () => void,
  mandatorServiceNgx: MandatorService
) {
  $scope.IsInBaseData = true;
  $scope.Template = Template;
  $scope.IsNew = !Template;
  $scope.IsPluginTemplate = Template && Template.PluginLabelId;
  $scope.Formats = Formats;
  $scope.Printers = Printers;
  if ($scope.IsNew) {
    $scope.Template = {
      Ip: null,
      Name: null,
      EscapedContent: null,
      LabelLanguage: "ZPL",
      PluginName: "Custom"
    };
  }
  $scope.OnSuccess = OnSuccess;
  $scope.ErrorText = null;
  $scope.GetFormatDescription = function (variant) {
    if (variant && variant.LabelFormatId) {
      var format = $scope.Formats.filter(f => f.Id === variant.LabelFormatId)[0];
      if (format && format.Description) {
        return format.Description;
      }
    }

  };
  $scope.GetErrorText = function () {
    if (!$scope.Template.Name) {
      $scope.ErrorText = { Line1: "Geben Sie einen Namen ein." };
      return true;
    }
    if (!$scope.Template.Variants || !$scope.Template.Variants.length) {
      $scope.ErrorText = { Line1: "Es wurde keine Variante angelegt." };
      return true;
    }
    if ($scope.Template.DefaultPrinterId) {
      var printer = $scope.Printers.filter(f => f.Id === parseInt($scope.Template.DefaultPrinterId))[0];
      if (printer) {
        var successLabels = $scope.Template.Variants.filter(f => parseInt(f.LabelFormatId) === printer.LabelFormatId && f.Dpi === printer.Dpi);
        if (!(printer.CanEpl && successLabels.filter(f => f.LabelLanguage === "EPL").length || printer.CanZpl && successLabels.filter(f => f.LabelLanguage === "ZPL").length)) {
          $scope.ErrorText = { Line1: "Der Standarddrucker kann dieses Label nicht drucken.", Line2: "Format: " + printer.Format.Description + " @ " + printer.Dpi + " DPI, ZPL: " + printer.CanZpl + ", EPL: " + printer.CanEpl };
          return true;
        }
      }
    }
    return false;
  };

  $scope.Hide = function () {
    $mdDialog.hide();
  };
  $scope.Cancel = function () {
    $mdDialog.cancel();
  };
  $scope.EditVariant = function (variant) {
    if (variant) {
      $scope.SelectedVariant = variant;
    }
    else {
      $scope.SelectedVariant = {
        Id: null,
        Dpi: 203,
        LabelLanguage: "ZPL",
        LabelFormatId: $scope.Formats[0].Id,
        EscapedContent: null
      };
    }
    $scope.IsInBaseData = false;
  };
  $scope.SaveSelectedVariant = function () {
    if (!$scope.Template.Variants) {
      $scope.Template.Variants = [];
    }
    if ($scope.Template.Variants.filter(f => f === $scope.SelectedVariant).length === 0) {
      $scope.Template.Variants.push($scope.SelectedVariant);
    }
    $scope.SelectedVariant = null;
    $scope.IsInBaseData = true;
  };
  $scope.Ok = function () {
    ApiHandlerService.SendRequest("Label", "CreateOrEditTemplate", { template: $scope.Template, mandatorId: mandatorServiceNgx.selectedMandatorId }).then(function (data) {
      if ($scope.OnSuccess) {
        $scope.OnSuccess();
      }
      $mdDialog.hide();
    });
  };
}
