import angular from "angular";
import moment from "moment";
import { ColumnDefinition, HestiaDataTable } from "src/ajs/Directives/HestiaDataTableDirective";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { arrayPushIfNotExist, convertDate, formatDate, formatDateShort } from "src/ajs/Utils/HestiaCommon";
import { MandatorService } from "src/app/core/services/mandator.service";
import { InfoHubService } from "src/app/info-hub/services/info-hub.service";

createInformationHubController.$inject = ['$scope', '$mdDialog', '$q', '$timeout', 'ApiHandlerService', 'StorageService', 'LocalizationService', 'UserService', 'SearchBarService', 'ScreenWidthService', 'theme', 'InfoHubServiceNgx', 'MandatorServiceNgx'];

export function createInformationHubController($scope, $mdDialog, $q, $timeout, ApiHandlerService, StorageService, LocalizationService, UserService, SearchBarService, ScreenWidthService, theme: ThemeDto, InfoHubServiceNgx: InfoHubService, MandatorServiceNgx: MandatorService) {
  addDefaultScopeFunctions($scope, theme);
  $scope.IsLoading = true;
  $scope.$on("setInformationHubArticleId", function (event, args) {
    $scope.SelectArticleId = args;
  });
  $scope.Storage = StorageService.CreateInstance("InformationHubController");
  $scope.Localization = LocalizationService.GetPluginDict("InformationHub");
  SearchBarService.RequireSearchBar(true);

  MandatorServiceNgx.selectedMandatorIdChanged.subscribe(() => {
    $scope.LoadData();
  });

  $scope.IsMobile = function () {
    return ScreenWidthService.IsMobile();
  };

  SearchBarService.SearchTextChangedEvent(function (text) {
    $scope.SearchText = text;
    $scope.FilterChangedDelayed();
  });

  $scope.FilterChangedDelayed = function () {
    if ($scope.FilterChangedTimeout) {
      $timeout.cancel($scope.FilterChangedTimeout);
    }

    $scope.FilterChangedTimeout = $timeout(function () {
      $scope.ApplyFilter();
      $scope.FilterChangedTimeout = null;
    }, 500);
  };

  $scope.ApplyFilter = function () {
    $scope.TagTree.ApplyFilter($scope.SearchText);
  };

  $scope.PrepareEntryModel = function (model) {
    if (model.ValidFrom) {
      model.ValidFrom = moment(model.ValidFrom);
    }
    if (model.ValidUntil) {
      model.ValidUntil = moment(model.ValidUntil);
    }
    if (model.PublishDate) {
      model.PublishDate = moment(model.PublishDate);
    }
    model.Created = moment(model.Created);
    model.Modified = moment(model.Modified);
  };

  $scope.TagTree = {
    Data: [],
    IsLoading: true,
    SelectedNode: null,
    Initialize: function () {
      var deferred = $q.defer();
      var object = $scope.TagTree;
      this.ParentScope = $scope;
      var allTags = {
        Tag: "L[LabelAllTags]",
        Icon: "style",
        HideCount: true,
        IsOpen: true,
        Entries: [],
        Subfolders: []
      };
      var unpublished = {
        Tag: "L[LabelNotPublished]",
        Icon: "visibility_off",
        IsOpen: false,
        Entries: [],
        Subfolders: [],
        IsHidden: <boolean>undefined
      };
      var expired = {
        Tag: "L[LabelExpired]",
        Icon: "history",
        IsOpen: false,
        Entries: [],
        Subfolders: [],
        IsHidden: <boolean>undefined
      };
      var sources = {
        Tag: "L[LabelSources]",
        Icon: "email",
        IsSourcesFolder: true,
        ContextMenuId: 'sourceContextMenu',
        HideCount: true,
        IsOpen: false,
        Entries: [],
        Subfolders: []
      };
      object.Data = [unpublished, expired, allTags, sources];
      if ($scope.Data) {
        $scope.Data.Tags.forEach(folder => {
          folder.Entries = [];
          allTags.Subfolders.push(folder);
        });
        $scope.Data.Entries.forEach(entry => {
          if (entry.ValidFrom && entry.ValidFrom > moment()) {
            expired.Entries.push(entry);
          }
          else if (entry.ValidUntil && entry.ValidUntil < moment()) {
            expired.Entries.push(entry);
          }
          else if (entry.IsPublished) {
            entry.TagIds.forEach(id => {
              var folder = allTags.Subfolders.filter(f => f.Id === id);
              if (folder && folder.length) {
                folder[0].IsTag = true;
                folder[0].Entries.push(entry);
              }
            });
          } else {
            unpublished.Entries.push(entry);
          }
        });
        if (!expired.Entries.length) {
          expired.IsHidden = true;
        }
        if (!unpublished.Entries.length) {
          unpublished.IsHidden = true;
        }
        $scope.Data.Sources.forEach(source => {
          source.ShowAlways = true;
          source.Tag = source.DisplayName;
          source.IsSource = true;
          source.ContextMenuId = "sourceEntryContextMenu";
          object.Data[3].Subfolders.push(source);

        });

        // Parent-Referenzen Setzen
        object.SetParentNodes(object.Data, null);
        if (object.Data.length) {
          object.SelectNode(null, allTags, null, true);
        }
        var tagFolder = object.Data[0];
        tagFolder.Subfolders = tagFolder.Subfolders.sort(function (a, b) {
          return a.Tag.localeCompare(b.Tag);
        });
        object.IsLoading = false;
      }
    },
    FilterEntry: function (entry, searchText) {
      return entry.Title.toLowerCase().includes(searchText);
    },
    ApplyFilter: function () {
      var searchText = $scope.SearchText;
      var node = this.SelectedNode;
      var entries = [];
      if (node.Subfolders && node.Subfolders.length) {
        var nodes = [];
        node.Subfolders.forEach(sf => {
          if (sf.Entries) {
            sf.Entries.forEach(e => {
              arrayPushIfNotExist(entries, e);
            });
          }
        });
      }
      else {
        entries = node.Entries;
      }
      var table = $scope.InformationHubTable;
      if (node.IsSource) {
        table = $scope.InformationHubSourceTable;
      }
      if (searchText) {
        searchText = searchText.toLowerCase();
        table.UpdateSource(entries.filter(f => this.FilterEntry(f, searchText)), $timeout);
      } else {
        table.UpdateSource(entries.slice(), $timeout);
      }
    },
    ShowContext: function (node) {
      this.ContextTitle = node.TagName;
      this.ContextElement = node;
    },
    SetParentNodes: function (nodes, parent) {
      if (nodes) {
        for (var i = 0; i < nodes.length; i++) {
          nodes[i].Parent = parent;
          this.SetParentNodes(nodes[i].Subfolder, nodes[i]);
        }
      }
    },
    UnselectAllChildren: function (root) {
      if (root) {
        for (var i = 0; i < root.length; i++) {
          root[i].IsSelected = false;
          this.UnselectAllChildren(root[i].Subfolders);
        }
      }
    },
    SelectNode: function ($event, node, settings, noNavigate) {
      // Unselect all
      this.UnselectAllChildren($scope.TagTree.Data);
      this.SelectedNode = node;
      if (node.IsSource) {
        this.ParentScope.IsLoading = true;
        var object = <any>this;
        ApiHandlerService.SendRequest("InformationHub", "LoadSourceEntries", { model: node, mandatorId: MandatorServiceNgx.selectedMandatorId }).then(function (data) {
          data.Entries.forEach(object.ParentScope.PrepareEntryModel);
          node.Entries = data.Entries;
          object.ApplyFilter();
          object.ParentScope.IsLoading = false;
        });
      } else {
        this.ApplyFilter();
      }
      if (!noNavigate) {
        $scope.ShowTable = true;
      }
      node.IsSelected = true;
    }
  };

  $scope.TagTree.Initialize();

  $scope.ModifySource = function (ev) {
    $mdDialog.show({
      targetEvent: ev,
      controller: 'EditHubSourceDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/InformationHub/Dialogs/EditSourceDialog.htm',
      locals: {
        Parent: $scope,
        Source: $scope.TagTree.ContextElement
      },
      parent: angular.element(document.body)
    });
  };

  $scope.DeleteSource = function () {
    ApiHandlerService.SendRequest("InformationHub", "DeleteSource", { model: $scope.TagTree.ContextElement, mandatorId: MandatorServiceNgx.selectedMandatorId }).then(function (data) {
      $scope.LoadData();
    });
  };

  $scope.AddMailSource = function (ev) {
    $mdDialog.show({
      targetEvent: ev,
      controller: 'AddMailboxDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/InformationHub/Dialogs/AddMailboxDialog.htm',
      locals: {
        Parent: $scope
      },
      parent: angular.element(document.body)
    });
  };

  $scope.AddFolderSource = function (ev) {
    $mdDialog.show({
      targetEvent: ev,
      controller: 'AddOutlookFolderDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/InformationHub/Dialogs/AddOutlookFolderDialog.htm',
      locals: {
        Parent: $scope
      },
      parent: angular.element(document.body)
    });
  };

  $scope.NavigateToOverview = function () {
    if ($scope.SelectedViewEntry || !$scope.ShowTable) {
      $scope.ActiveTagText = "L[LabelOverview]";
      $scope.IsInModify = false;
      $scope.SelectedViewEntry = null;
    } else {
      $scope.ShowTable = false;
    }

  };

  $scope.CreateEntry = function () {
    $scope.IsInModify = true;
    $scope.ActiveTagText = "L[LabelNewArticle]";
    $scope.SetSelectedViewEntry({
      Id: 0,
      IsNew: true,
      Title: null,
      ShortDescription: null,
      Content: null,
      IsPublished: false,
      Created: moment(),
      Modified: moment(),
      CreatedUser: UserService.Data.User,
      TagIds: [],
      Tags: [],
      FileIds: [],
      Attachments: []
    });
  };

  $scope.CalculateAttachmentSize = function (a) {
    var currentUnit = "B";
    var currentSize = a;
    if (currentSize > 1024) {
      currentSize /= 1024;
      currentUnit = "KB";
    }
    if (currentSize > 1024) {
      currentSize /= 1024;
      currentUnit = "MB";
    }
    if (currentSize > 1024) {
      currentSize /= 1024;
      currentUnit = "GB";
    }
    return Math.round(currentSize) + " " + currentUnit;
  };

  $scope.FileUploaded = function (id, name, fileSize) {
    $scope.SelectedViewEntry.FileIds.push(id);
    var extension = null;
    var split = name.split('.');
    if (split.length > 1) {
      extension = split.pop();
    }
    $scope.SelectedViewEntry.Attachments.push({ FileName: name, FileId: id, FileSize: fileSize, CalculatedSize: $scope.CalculateAttachmentSize(fileSize), Extension: extension });
  };

  $scope.NavigateBack = function () {
    $scope.NavigateToOverview();
  };

  $scope.ModifySelectedEntry = function () {
    $scope.IsInModify = true;
    $scope.ActiveTagText = "L[LabelEditArticle]";
  };

  $scope.QueryTag = function (searchText) {
    if (searchText && searchText.toLowerCase()) {
      return $scope.Data.Tags.filter(f => f.Tag.toLowerCase().includes(searchText.toLowerCase()));
    }
    return [];
  };

  $scope.TransformChip = function (chip) {
    if (chip.Id)
      return chip;
    else {
      var exisiting = $scope.Data.Tags.filter(f => f.Tag.trim().toLowerCase() === chip.trim().toLowerCase());
      if (exisiting.length) {
        return exisiting[0];
      }
      return { Id: 0, Tag: chip };
    }

  };

  $scope.SetSelectedViewEntry = function (model) {
    if (model.TagIds) {
      model.Tags = model.TagIds.map(function (key) {
        var value = $scope.Data.Tags.filter(f => f.Id === key)[0];
        return value;
      });
    }
    if (model.Attachments) {
      model.Attachments.forEach(f => {
        f.CalculatedSize = $scope.CalculateAttachmentSize(f.FileSize);
      });
    }
    if (model.IsMail && !model.Content) {
      model.IsLoading = true;
      $scope.ModifySelectedEntry();
      ApiHandlerService.SendRequest("InformationHub", "LoadMailContent", { entry: model, mandatorId: MandatorServiceNgx.selectedMandatorId }).then(function (data) {
        model.Content = data.Content;
        model.Attachments = data.Attachments;
        model.IsLoading = false;
      });
    }
    $scope.SelectedViewEntry = model;
    $scope.ShowPlainText = model.IsPlainText;
  };

  $scope.Download = function (att) {
    var link = document.createElement("a");
    link.setAttribute("type", "hidden");
    link.href = "/api/FileDownload/DownloadFile/" + att.FileId;
    link.download = att.FileName;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  $scope.RemoveAttachment = function (att) {
    $scope.SelectedViewEntry.Attachments = $scope.SelectedViewEntry.Attachments.filter(f => f !== att);
  };

  $scope.ItemSelected = function (model) {
    $scope.IsInModify = false;
    $scope.ActiveTagText = model.Title;
    $scope.SetSelectedViewEntry(model);
  };

  $scope.RenderUser = function (user) {
    return user.DisplayName;
  };

  $scope.InformationHubSourceTable = new HestiaDataTable(StorageService.CreateInstance("InformationHubSourceTable"), $timeout, $scope, $scope.Localization);
  $scope.InformationHubSourceTable.ItemClicked = $scope.ItemSelected;
  $scope.InformationHubSourceTable.UpdateColumns([
    new ColumnDefinition({ PropertyPath: 'Title', Heading: "L[TableTitle]", ColumnWidth: 300, Resizeable: true, Sortable: true, Template: "/ClientApp/src/ajs/Views/InformationHub/CellTemplates/TitleWithTooltip.htm" }),
    new ColumnDefinition({ PropertyPath: 'Created', Heading: 'L[TableCreated]', IsSorted: true, IsAscending: true, Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDate })
  ]);

  $scope.InformationHubTable = new HestiaDataTable(StorageService.CreateInstance("InformationHubTable"), $timeout, $scope, $scope.Localization);
  $scope.InformationHubTable.ItemClicked = $scope.ItemSelected;
  $scope.InformationHubTable.UpdateColumns([
    new ColumnDefinition({ PropertyPath: 'IsPublished', Heading: ' ', ColumnWidth: 25, Resizeable: false, Sortable: true, Template: "/ClientApp/src/ajs/Views/InformationHub/CellTemplates/IsPublished.htm" }),
    new ColumnDefinition({ PropertyPath: 'Title', Heading: "L[TableTitle]", Resizeable: true, Sortable: true, Template: "/ClientApp/src/ajs/Views/InformationHub/CellTemplates/TitleWithTooltip.htm" }),
    new ColumnDefinition({ PropertyPath: 'PublishDate', Heading: "L[TablePublished]", IsSorted: true, IsAscending: true, Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDate }),
    new ColumnDefinition({ PropertyPath: 'CreatedUser', Heading: 'L[TableAuthor]', Resizeable: true, RenderFunction: $scope.RenderUser, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'ValidFrom', Heading: 'L[TableValidFrom]', Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDateShort }),
    new ColumnDefinition({ PropertyPath: 'ValidUntil', Heading: 'L[TableValidUntil]', Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDateShort }),
    new ColumnDefinition({ PropertyPath: 'Created', Heading: 'L[TableCreated]', Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDate }),
    new ColumnDefinition({ PropertyPath: 'Modified', Heading: 'L[TableEdited]', Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDate })
  ]);

  $scope.SaveEntry = function (publish: boolean, noNavigation?: boolean) {
    if ($scope.SelectedViewEntry && $scope.IsInModify) {
      if (publish) {
        $scope.SelectedViewEntry.IsPublished = true;
      }
      $scope.SelectedViewEntry.Tags = $scope.SelectedViewEntry.Tags.map(m => {
        console.log("Transforming Tag");
        var value = {
          Id: m.Id,
          Tag: m.Tag
        };
        return value;
      });

      $scope.SelectedViewEntry.IsPlainText = $scope.ShowPlainText;
      $scope.isSaving = true;
      ApiHandlerService.SendRequest("InformationHub", "SaveEntry", { entry: $scope.SelectedViewEntry, mandatorId: MandatorServiceNgx.selectedMandatorId }).then(function (data) {
        $scope.LoadData(noNavigation);
        $scope.isSaving = false;
      });
    }
  };

  $scope.UpdateIframe = function () {
    var frame: any = $("#contentFrame")[0];
    var text = $scope.SelectedViewEntry.Content;
    //frame.src = "data:text/html;charset=utf-8," + escape($scope.SelectedViewEntry.Content);
    if (!text.toLowerCase().includes("doctype")) {
      text = "<!DOCTYPE html>" + text;
    }
    var first = true;
    frame.contentWindow.document.write(text);
    var checkFunction = function () {
      if (first) {
        var cssLink = document.createElement("link");
        cssLink.href = "/Content/reset.css";
        cssLink.rel = "stylesheet";
        cssLink.type = "text/css";
        frame.contentWindow.document.head.appendChild(cssLink);
        first = false;
      }
    };
    checkFunction();
  };

  $scope.Publish = function (entry) {
    if ($scope.SelectedViewEntry) {
      $scope.SelectedViewEntry.Tags = $scope.SelectedViewEntry.Tags.map(m => {
        console.log("Transforming Tag");
        var value = {
          Id: m.Id,
          Tag: m.Tag
        };
        return value;
      });
      ApiHandlerService.SendRequest("InformationHub", "PublishEntry", { entry: $scope.SelectedViewEntry, mandatorId: MandatorServiceNgx.selectedMandatorId }).then(function (data) {
        $scope.LoadData();
      });
    }
  };

  $scope.LoadData = function (noNavigation?: boolean) {
    $scope.IsLoading = true;
    ApiHandlerService.SendRequest("InformationHub", "GetInformationHubData", { mandatorId: MandatorServiceNgx.selectedMandatorId }).then(function (data) {
      $scope.Data = data;
      $scope.Data.Entries.forEach($scope.PrepareEntryModel);
      $scope.TagTree.Initialize($scope);

      if (!noNavigation) {
        $scope.NavigateToOverview();
      }

      if ($scope.SelectArticleId) {
        $scope.ItemSelected($scope.Data.Entries.filter(f => f.Id === $scope.SelectArticleId)[0]);
      }
      $scope.IsLoading = false;
    });
  };

  $scope.OpenScriptGeneratorDialog = function () {
    InfoHubServiceNgx.openScriptGeneratorDialog($scope.SelectedViewEntry.Id);
  };

  $scope.TogglePLainText = function () {
    $scope.ShowPlainText = !$scope.ShowPlainText;
  };

  $scope.LoadData();
}
