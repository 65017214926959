<mat-drawer-container fxFlex>
  <mat-drawer #drawer mode="side" position="end" style="width:350px">
    <app-task-edit [taskId]="taskId" (closeRequest)="drawer.close()" (refreshRequest)="refresh$.next()"></app-task-edit>
  </mat-drawer>

  <div class="content-padding" fxLayout="column">
    <!-- Belegstammdaten -->
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h1>Belegstammdaten</h1>

      <div>
        <button mat-icon-button color="warn" *ngIf="isArchivedReceipt && !isSaving" (click)="deleteReceipt()"
          matTooltip="Löschen">
          <mdi-icon icon="delete"></mdi-icon>
        </button>
        <button mat-icon-button color="primary" *ngIf="!isArchivedReceipt && !isSaving" [disabled]="!checkAnyAccounted()" (click)="saveReceipt()"
          matTooltip="Speichern">
          <mdi-icon icon="content-save"></mdi-icon>
        </button>
      </div>
    </div>

    <form [formGroup]="formGroup">
      <div fxLayout="row">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>Belegbezeichnung</mat-label>
          <input matInput type="text" formControlName="name" [readonly]="isArchivedReceipt">
        </mat-form-field>
      </div>

      <div fxLayout="row">
        <mat-form-field appearance="outline" fxFlex="40">
          <mat-label>Fremdbelegnummer</mat-label>
          <input matInput type="text" formControlName="foreignReceiptId" [readonly]="isArchivedReceipt">
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="20">
          <mat-label>Erster Eintrag</mat-label>
          <input matInput type="text" formControlName="firstEntry" [readonly]="true">
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="20">
          <mat-label>Letzter Eintrag</mat-label>
          <input matInput type="text" formControlName="lastEntry" [readonly]="true">
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="20">
          <mat-label>Abrechnungsstichtag</mat-label>
          <input matInput type="text" formControlName="accountingPeriodEnd" [readonly]="true">
        </mat-form-field>
      </div>
    </form>

    <!-- Buchungen -->
    <h1>Buchungen</h1>

    <table mat-table [dataSource]="receiptRows" multiTemplateDataRows class="axs-table receipt-row-table">
      <ng-container matColumnDef="accountingType">
        <td mat-cell *matCellDef="let row" [formGroup]="row.formGroup">
          <mat-button-toggle-group *ngIf="!isArchivedReceipt" formControlName="accountingTypeToggle" class="small">
            <mat-button-toggle [value]="2" (click)="$event.stopPropagation(); resetAccountedTime(row);" matTooltip="Nicht verrechnen">
              <mdi-icon icon="currency-eur-off"></mdi-icon>
            </mat-button-toggle>
            <mat-button-toggle [value]="1" (click)="$event.stopPropagation(); setDefaultAccountedTime(row);" matTooltip="Mit diesem Beleg verrechnen">
              <mdi-icon icon="currency-eur"></mdi-icon>
            </mat-button-toggle>
            <mat-button-toggle [value]="0" (click)="$event.stopPropagation(); resetAccountedTime(row);" matTooltip="Später verrechnen">
              <mdi-icon icon="timer-sand"></mdi-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>

          <mat-button-toggle-group *ngIf="isArchivedReceipt" [value]="row.taskReceiptRow.AccountingType" class="small" [disabled]="true">
            <mat-button-toggle [value]="2" matTooltip="Nicht verrechnen">
              <mdi-icon icon="currency-eur-off"></mdi-icon>
            </mat-button-toggle>
            <mat-button-toggle [value]="1" matTooltip="Mit diesem Beleg verrechnen">
              <mdi-icon icon="currency-eur"></mdi-icon>
            </mat-button-toggle>
            <mat-button-toggle [value]="0" matTooltip="Später verrechnen">
              <mdi-icon icon="timer-sand"></mdi-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </td>

        <td mat-footer-cell *matFooterCellDef>
          <b>Summe:</b>
        </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <td mat-cell *matCellDef="let row">
          <div fxLayout="row" *ngIf="!row.isPhoneSupport">
            <div>
              <button mat-icon-button (click)="$event.stopPropagation(); copyText(row);"
                matTooltip="Titel in Zwischenablage kopieren">
                <mdi-icon icon="content-copy"></mdi-icon>
              </button>
            </div>
            <div fxFlex>

              <div>
                <b><a (click)="$event.stopPropagation(); openSideNav(row)">{{row.task.Title}}</a></b>
              </div>
              <div>
                Zeitraum: {{getRowFirstDate(row.taskReceiptRow) | date}} - {{getRowLastDate(row.taskReceiptRow) | date}}, Status: {{row.task.State.Description}}
              </div>
            </div>
          </div>

          <div *ngIf="row.isPhoneSupport" class="phone-support-title">
            <b>Telefonsupport</b>
          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="durations">
        <td mat-cell *matCellDef="let row">
          <div *ngIf="!row.isPhoneSupport">
            <div class="duration-detail" *ngIf="row.taskDuration">
              <mdi-icon icon="briefcase"></mdi-icon>
              <span>{{row.taskDuration |duration}}</span>
            </div>

            <div class="duration-detail" *ngIf="!row.taskDuration"></div>

            <div class="duration-detail" *ngIf="row.callDuration">
              <mdi-icon icon="phone"></mdi-icon>
              <span>{{row.callDuration|duration}}</span>
            </div>

            <div class="duration-detail" *ngIf="!row.callDuration"></div>

            <div class="duration-detail" *ngIf="row.callDuration||row.taskDuration">
              <mdi-icon icon="sigma"></mdi-icon>
              <span>{{ (row.taskDuration + row.callDuration) |duration}}</span>
            </div>

            <div class="duration-detail" *ngIf="!row.callDuration||row.taskDuration"> </div>

          </div>

          <div *ngIf="row.isPhoneSupport">
            <div class="duration-detail"></div>
            <div class="duration-detail"></div>
            <div class="duration-detail">
              <mdi-icon icon="sigma"></mdi-icon>
              <span> {{ phoneSupportTotalDuration| duration}}</span>
            </div>
          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <div class="duration-detail" matTooltip="Arbeitszeit">
            <mdi-icon icon="briefcase"></mdi-icon>
            <span>{{taskDurationSum|duration}}</span>
          </div>
          <div class="duration-detail" matTooltip="Telefonzeit">
            <mdi-icon icon="phone"></mdi-icon>
            <span>{{callDurationSum|duration}}</span>
          </div>
          <div class="duration-detail" matTooltip="Summe">
            <mdi-icon icon="sigma"></mdi-icon>
            <span>{{totalDurationSum|duration}}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="accountedTime">
        <td mat-cell *matCellDef="let row" [formGroup]="row.formGroup">
          <div fxLayout="row" fxLayoutAlign="start center" *ngIf="row.formGroup.value.accountingTypeToggle === 1 && !isArchivedReceipt">
            <mat-form-field fxFlex appearance="outline" class="remove-hint no-min-width" (click)="$event.stopPropagation();">
              <mat-label></mat-label>
              <input matInput type="number" step=".01" formControlName="accountedTime">
            </mat-form-field>
            <span>h</span>
          </div>

          <div fxLayout="row" fxLayoutAlign="end center" *ngIf="row.formGroup.value.accountingTypeToggle === 1 && isArchivedReceipt">
            <div *ngIf="isArchivedReceipt">{{row.taskReceiptRow.AccountedTime | number}} h </div>
          </div>

        </td>
        <td mat-footer-cell *matFooterCellDef>
          {{ calculateAccountedTimeSum() | number }} h
        </td>
      </ng-container>

      <ng-container matColumnDef="expand">
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button (click)="row.isExpanded = !row.isExpanded; $event.stopPropagation()">
            <mdi-icon icon="chevron-down" *ngIf="!row.isExpanded"></mdi-icon>
            <mdi-icon icon="chevron-up" *ngIf="row.isExpanded"></mdi-icon>
          </button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let row" colspan="5">
          <div class="detail-container" [@detailExpand]="row.isExpanded ? 'expanded' : 'collapsed'">
            <div *ngIf="!row.isPhoneSupport">
              <table
                mat-table
                class="axs-table dense task-table"
                [dataSource]="row.entries"
                matSortDisableClear>

                <ng-container matColumnDef="type">
                  <td mat-cell *matCellDef="let entry">
                    <b *ngIf="entry.Call">Anruf</b>
                    <b *ngIf="entry.Note">Notiz</b>
                  </td>
                </ng-container>

                <ng-container matColumnDef="date">
                  <td mat-cell *matCellDef="let entry">
                    <span *ngIf="entry.Call">{{entry.Call.StartDateTime| date:'dd.MM.yyyy HH:mm' }}</span>
                    <span *ngIf="entry.Note">{{entry.Note.CreatedDate| date:'dd.MM.yyyy HH:mm'}}</span>
                  </td>
                </ng-container>


                <ng-container matColumnDef="duration">
                  <td mat-cell *matCellDef="let entry">
                    <span *ngIf="entry.Call">{{entry.Call.EndDateTime - entry.Call.StartDateTime | duration}}</span>
                    <span *ngIf="entry.Note">{{(entry.Note.TimeEntry.Minutes * 60 * 1000 )| duration}}</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="user">
                  <td mat-cell *matCellDef="let entry">
                    <span *ngIf="entry.Call && entry.Call.Extension">{{ entry.Call.Extension.Extension + " - " + entry.Call.Extension.FirstName + " " + entry.Call.Extension.LastName }}</span>
                    <span *ngIf="entry.Call && !entry.Call.Extension">{{ getExtensionName(entry.Call.CleanPartList[0].AffectedExtension) }}</span>
                    <span *ngIf="entry.Note"> {{ entry.Note.CreatedUser?.FirstName}} {{entry.Note.CreatedUser?.LastName }}</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="description">
                  <td mat-cell *matCellDef="let entry">
                    <span *ngIf="entry.Call">{{getCallDescription(entry.Call)}}</span>
                    <span *ngIf="entry.Note">{{entry.Note.Text}}</span>
                  </td>
                </ng-container>

                <tr mat-row *matRowDef=" let entry; columns:displayedColumns;"></tr>
              </table>
            </div>

            <div *ngIf="row.isPhoneSupport">

              <table mat-table class="axs-table dense contact-table" [dataSource]="phoneSupportContactRows" multiTemplateDataRows>

                <ng-container matColumnDef="contactName">
                  <td mat-cell *matCellDef="let row">
                    <b>{{ row.contactName }}</b>
                  </td>
                </ng-container>

                <ng-container matColumnDef="summary">
                  <td mat-cell *matCellDef="let row">
                    {{row.calls.length}} Anrufe, insgesamt {{row.totalCallDuration|duration}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="expand">
                  <td mat-cell *matCellDef="let row">
                    <button mat-icon-button (click)="row.isExpanded = !row.isExpanded; $event.stopPropagation()">
                      <mdi-icon icon="chevron-down" *ngIf="!row.isExpanded"></mdi-icon>
                      <mdi-icon icon="chevron-up" *ngIf="row.isExpanded"></mdi-icon>
                    </button>
                  </td>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                  <td mat-cell *matCellDef="let contact" colspan="3">
                    <div class="detail-container" [@detailExpand]="contact.isExpanded ? 'expanded' : 'collapsed'">


                      <table mat-table class="axs-table dense contact-call-table" [dataSource]="contact.calls">
                        <ng-container matColumnDef="date">
                          <td mat-cell *matCellDef="let call">
                            {{ call.StartDateTime | date:'dd.MM.yyyy HH:mm'}}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="duration">
                          <td mat-cell *matCellDef="let call">
                            {{ getCallDurationMilliseconds(call) | duration}}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="contactName">
                          <td mat-cell *matCellDef="let call">
                            <a (click)="openContactDialog(call.OtherPartyMainContactId)">{{call.OtherPartyMainContact?.DisplayName}}</a>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="number">
                          <td mat-cell *matCellDef="let call">
                            <a href="{{call.OtherPartyNumber| tel}}">{{call.OtherPartyNumber}}</a>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="extensionDescription">
                          <td mat-cell *matCellDef="let call">
                            <span *ngIf="call.Extension">{{call.Extension?.Extension + " - " + call.Extension?.FirstName + " " + call.Extension?.LastName}}</span>
                            <span *ngIf="!call.Extension"> {{ getExtensionName(call.CleanPartList[0].AffectedExtension) }}</span>
                          </td>
                        </ng-container>

                        <tr mat-row *matRowDef="let call; columns: ['date', 'duration', 'contactName', 'number', 'extensionDescription'];"></tr>
                      </table>
                    </div>
                  </td>
                </ng-container>

                <tr mat-row
                  *matRowDef="let row; columns: ['contactName', 'summary', 'expand']"
                  class="contact-row"
                  [class.contact-row-expanded]="row.isExpanded"
                  (click)="row.isExpanded = !row.isExpanded"></tr>
                <tr mat-row
                  *matRowDef="let row; columns: ['expandedDetail']"
                  class="contact-detail-row"></tr>
              </table>


            </div>
          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <tr mat-row
        *matRowDef="let row; columns: ['accountingType', 'title', 'durations', 'accountedTime', 'expand']"
        class="task-row"
        [class.task-row-expanded]="row.isExpanded"
        (click)="row.isExpanded = !row.isExpanded"></tr>
      <tr mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="task-detail-row"></tr>
      <tr mat-footer-row
        *matFooterRowDef="['accountingType', 'title', 'durations', 'accountedTime', 'expand']"></tr>
    </table>


    <app-loading-overlay [isLoading]="isLoading" overlay="light"></app-loading-overlay>
  </div>
</mat-drawer-container>
