import 'zone.js'; // zone.js wird fürs Bootstrappen benötigt.
import angular from "angular";


// CH: deaktiviert und spellcheck="false" direkt bei <md-autocompletes gesetzt.
// $(document).on('DOMNodeInserted', function (e) {
//   var element = $(e.target);
//   if (element.find('md-autocomplete').length) {
//     element.find("input").attr('spellcheck', false);
//   }
//   if (element.find('.md-datepicker-triangle-button').length) {
//     $(".md-datepicker-triangle-button").attr('tabindex', '-1');
//   }
// });

const HestiaApp = angular.module('HestiaApp');

// CH: deaktiviert, siehe https://www.hesslerdesign.com/blog/user-experience-2/why-window-scrollto-is-harmful-for-mobile-websites/
// window.addEventListener("load", function () {
//   setTimeout(function () {
//     // This hides the address bar:
//     window.scrollTo(0, 1);
//   }, 0);
// });




HestiaApp
  .run(['themeProvider', '$mdTheming', 'theme', 'themes', function (themeProvider, $mdTheming, theme, themes) {
    themes.forEach(currentTheme => {
      const createdTheme = themeProvider.theme(currentTheme.ThemeDescription);
      createdTheme.LogoPath = currentTheme.LogoPath;

      if (currentTheme.PrimaryDefaultNonce) {
        createdTheme.primaryPalette(currentTheme.PrimaryPalette, { 'default': currentTheme.PrimaryDefaultNonce.toString() });
      } else {
        createdTheme.primaryPalette(currentTheme.PrimaryPalette);
      }

      if (currentTheme.SecondaryDefaultNonce) {
        createdTheme.accentPalette(currentTheme.SecondaryPalette, { 'default': currentTheme.SecondaryDefaultNonce.toString() });
      } else {
        createdTheme.accentPalette(currentTheme.SecondaryPalette);
      }

      if (currentTheme.WarnDefaultNonce) {
        createdTheme.warnPalette(currentTheme.WarnPalette, { 'default': currentTheme.WarnDefaultNonce.toString() });
      } else {
        createdTheme.warnPalette(currentTheme.WarnPalette);
      }

      $mdTheming.generateTheme(currentTheme.ThemeDescription);
    });
    themeProvider.setDefaultTheme(theme.ThemeDescription);

    // Workaround für TypeScript
    // unsicher, ob es überhaupt noch benötigt wird
    window['Theme'] = {
      Name: theme.ThemeDescription,
      Definition: theme
    };
  }]);


// CH: deaktiviert weil vermutlich garnicht in Verwendung
// // Other Elements
// HestiaApp.animation('.ng-show-toggle-slidedown', [function () {
//   return {
//     beforeAddClass: function (element, className, done) {
//       if (className === 'ng-hide') {
//         $(element).slideUp({ duration: 400 }, done);
//       } else { done(); }
//     },
//     beforeRemoveClass: function (element, className, done) {
//       if (className === 'ng-hide') {
//         $(element).css({ display: 'none' });
//         $(element).slideDown({ duration: 400 }, done);
//       } else { done(); }
//     }
//   };
// }]);




