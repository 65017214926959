import angular from 'angular';
import { addDefaultScopeFunctions } from 'src/ajs/Functions/add-default-scope-functions';
import { ConnectedGroupDto } from 'src/ajs/Models/PublicContacts/ConnectedGroupDto';
import { QueryLdapGroupsResultDto } from 'src/ajs/Models/PublicContacts/QueryLdapGroupsResultDto';
import { ThemeDto } from 'src/ajs/Models/ThemeDto';
import { ApiHandlerService } from 'src/ajs/Services/ApiHandlerService';
import '../UserFlyout/UserFlyoutController';

creatPublicContractsGroupDialogController.$inject = ['$scope', '$mdDialog', '$q', 'ApiHandlerService', 'selectedGroup', 'parentScope', 'theme'];

export function creatPublicContractsGroupDialogController(
  $scope: IPublicContractsGroupDialogController,
  $mdDialog: angular.material.IDialogService,
  $q: angular.IQService,
  ApiHandlerService: ApiHandlerService,
  selectedGroup: ConnectedGroupDto,
  parentScope: angular.IScope,
  theme: ThemeDto,
) {

  addDefaultScopeFunctions($scope, theme);
  $scope.ParentScope = parentScope;
  $scope.Model = selectedGroup;
  $scope.IsNew = false;

  if ($scope.Model) {
    $scope.SelectedLdapGroup = {
      LdapId: $scope.Model.LdapId,
      LdapGroupName: $scope.Model.LdapDescription,
      DisplayName: $scope.Model.LdapDescription
    };
    $scope.LdapSearchString = $scope.Model.LdapDescription;
  }
  else {
    $scope.SelectedLdapGroup = null;
  }
  if (!$scope.Model) {
    $scope.IsNew = true;
    $scope.Model = {
      Id: null,
      Name: null,
      LdapId: null,
      LdapDescription: null
    } as ConnectedGroupDto;
  }

  $scope.SelectedLdapGroupChanged = function () {
    if ($scope.SelectedLdapGroup !== null) {
      $scope.Model.LdapId = $scope.SelectedLdapGroup.LdapId;
      $scope.Model.LdapDescription = $scope.SelectedLdapGroup.LdapGroupName;
      $scope.Model.HasError = false;
    } else {
      $scope.Model.LdapId = null;
      $scope.Model.LdapDescription = null;
      $scope.Model.HasError = true;
    }
  };

  $scope.QueryLdapGroups = function (searchString) {
    let result = null;
    const deferred = $q.defer();
    const request = ApiHandlerService.SendRequest("PublicContacts", "QueryLdapGroups", { groupId: $scope.Id, query: searchString }).then(function (data: QueryLdapGroupsResultDto) {
      result = data.LdapGroups;
      deferred.resolve(result);
    });
    return deferred.promise;
  };

  $scope.Cancel = function () {
    $mdDialog.hide();
  };

  $scope.Save = function () {
    ApiHandlerService.SendRequest("PublicContacts", "SaveGroup", { model: $scope.Model }).then(function (data: ConnectedGroupDto) {
      if ($scope.IsNew) {
        $scope.ParentScope.Administration.Groups.push($scope.Model);
      }
      $scope.Model.Id = data.Id;
      $scope.Model.Name = data.Name;
      $scope.Model.LdapId = data.LdapId;
      $scope.Model.LdapDescription = data.LdapDescription;
      $mdDialog.hide();
    });
  };
}

interface IPublicContractsGroupDialogController extends angular.IScope {

  ParentScope: any;
  Model: ConnectedGroupDto;
  IsNew: boolean;
  SelectedLdapGroup: SelectedLdapGroupDto;
  LdapSearchString: string;
  Id: string | null;

  SelectedLdapGroupChanged: () => void;
  QueryLdapGroups: (searchString: string) => angular.IPromise<unknown>;
  Cancel: () => void;
  Save: () => void;

}

interface SelectedLdapGroupDto {
  LdapId: string;
  LdapGroupName: string;
  DisplayName: string;

}
