import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ExtensionDto } from 'src/ajs/Models/Phone3cx/ExtensionDto';
import { getBaseUrlSignalR } from 'src/environments/factories';
import { PhoneHub } from './PhoneHub';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  readonly signalRLoaded$ = new Subject<void>();
  private phoneHub: PhoneHub;
  readonly extensionsChanged = new EventEmitter<ExtensionDto[]>();
  readonly callOperation = new EventEmitter<{ operation: 'add' | 'change' | 'delete', callId: number }>();

  constructor() { }

  async appInitialize() {
    const baseUrlSignalR = getBaseUrlSignalR();

    // SignalR Hubs laden
    const loadSignalR = $.ajax({
      url: baseUrlSignalR + "hubs",
      dataType: "script",
      //async: false
    });

    loadSignalR.done(() => {
      this.phoneHub = $.connection['phoneHub'] as PhoneHub;

      this.phoneHub.client.extensionsChanged = (extensions: ExtensionDto[]) => {
        this.extensionsChanged.emit(extensions);
      };
      this.phoneHub.client.callOperation = (operation: 'add' | 'change' | 'delete', callId: number) => {
        this.callOperation.emit({ operation: operation, callId: callId });
      };

      this.signalRLoaded$.next();
      this.signalRLoaded$.complete();
    });
  }
}
