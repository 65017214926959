import angular from "angular";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { StorageService, StorageServiceInstance } from "src/ajs/Services/StorageService";
import { MandatorService } from "src/app/core/services/mandator.service";


createOpenIdentcodeDialogController.$inject = ['$scope', '$mdDialog', 'StorageService', 'ParentScope', 'theme', '$mdToast', 'MandatorServiceNgx'];

export function createOpenIdentcodeDialogController(
  $scope: IOpenIdentcodeDialogControllerScope,
  $mdDialog: angular.material.IDialogService,
  StorageService: StorageService,
  ParentScope,
  theme: ThemeDto,
  $mdToast: angular.material.IToastService,
  mandatorServiceNgx: MandatorService
) {

  addDefaultScopeFunctions($scope, theme);

  $scope.Storage = StorageService.CreateInstance("OpenIdentcodeSearchDialogController");
  $scope.Autocomplete = $scope.Storage.GetOrCreate("Autocomplete", []);
  $scope.ParentScope = ParentScope;
  $scope.QuerySearch = function () {
    return $scope.Autocomplete;
  };

  setTimeout(function () {
    document.querySelector<HTMLElement>('#autoCompleteId').focus();
  }, 600);


  $scope.ExecuteSearch = function () {
    const selectedSystem = $scope.ParentScope.Model.Systems.filter(f => f.Identcode.toUpperCase() === $scope.Identcode.trim().toUpperCase());
    if (selectedSystem.length) {
      $scope.ParentScope.SelectSystem(selectedSystem[0]);
      $scope.Autocomplete = $scope.Autocomplete.filter(f => f !== $scope.Identcode.trim().toUpperCase());
      $scope.Autocomplete.unshift($scope.Identcode.trim().toUpperCase());
      $scope.Autocomplete = $scope.Autocomplete.slice(0, 15);
      $scope.Storage.Set("Autocomplete", $scope.Autocomplete);
      $scope.Hide();
    } else {
      const toast = $mdToast.simple()
        //  .textContent("Identcode '" + result + "' nicht gefunden") // result existiert nicht?
        .textContent("Identcode '" + "' nicht gefunden")
        .position('bottom right')
        .capsule(true);
      $mdToast.show(toast);
    }
  };

  $scope.Hide = function () {
    $mdDialog.hide();
  };

  $scope.Cancel = function () {
    $mdDialog.cancel();
  };
}


interface IOpenIdentcodeDialogControllerScope extends angular.IScope {

  Storage: StorageServiceInstance;
  Autocomplete: any;
  ParentScope: any;
  Identcode: string;

  QuerySearch: () => any;
  ExecuteSearch: () => void;
  Hide: () => void;
  Cancel: () => void;

}
