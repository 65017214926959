<div class="main-container">

  <div class="toolbar">

    <div class="toolbar-header">
      Aufgaben
    </div>

    <div>
      <mat-button-toggle-group class="small" name="userFilter" [(value)]="selectedUserFilterId" class="toolbar-margin">
        <mat-button-toggle [value]="1" (change)="filterChanged()">Relevante Aufgaben</mat-button-toggle>
        <mat-button-toggle [value]="2" (change)="filterChanged()">Meine Aufgaben</mat-button-toggle>
        <mat-button-toggle [value]="3" (change)="filterChanged()">Alle Aufgaben</mat-button-toggle>
      </mat-button-toggle-group>

      <mat-form-field appearance="outline" class="remove-hint">
        <mat-select [(value)]="selectedTaskStateFilterId" (selectionChange)="filterChanged()">
          <mat-option [value]="taskStatePreset.Offene"> {{"Offene Aufgaben"}} </mat-option>
          <mat-option [value]="taskStatePreset.Erledigte"> {{"Erledigte Aufgaben"}} </mat-option>
          <mat-option [value]="taskStatePreset.Geloeschte"> {{"Gelöschte Aufgaben"}} </mat-option>
          <mat-option [value]="taskStatePreset.Alle"> {{"Alle Aufgaben"}} </mat-option>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let state of taskStates" [value]="state.Id"> {{state.Description}} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <button mat-raised-button color="primary" (click)="createTask($event)" class="create-button">
        Aufgabe erstellen
      </button>

      <button mat-stroked-button (click)="columnEditor.showEditor()">
        <mat-icon>view_column</mat-icon> Spalten
      </button>

      <mat-paginator [length]="totalCount" [pageSize]="25" hidePageSize [showFirstLastButtons]="true"></mat-paginator>
    </div>

  </div>

  <div class="toolbar-column">

    <div>
      <mat-button-toggle-group class="small" name="rootQueues" [(value)]="selectedRootQueue">
        <mat-button-toggle [value]="selectedQueueAll" (change)="filterChangeRootQueue(null)">(Alle)</mat-button-toggle>
        <mat-button-toggle *ngFor="let queue of rootQueues" [value]="queue" (change)="filterChangeRootQueue(queue)">{{queue.Description}}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div>
      <mat-button-toggle-group class="small" *ngIf="selectedRootQueue?.Children.length > 0" name="childQueues" [(value)]="selectedChildQueue">
        <mat-button-toggle [value]="selectedQueueAll" (change)="filterChangeChildQueue(null)">(Alle)</mat-button-toggle>
        <mat-button-toggle *ngFor="let queue of selectedRootQueue?.Children" [value]="queue" (change)="filterChangeChildQueue(queue)">{{queue.Description}}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

  </div>

  <mat-divider></mat-divider>

  <div class="content-container">
    <mat-drawer-container>
      <mat-drawer #drawer mode="side" position="end">
        <app-task-edit *ngIf="selectedTaskId" [taskId]="selectedTaskId" (closeRequest)="drawer.close()" (refreshRequest)="refresh$.next()"></app-task-edit>
      </mat-drawer>

      <div>

        <table
          mat-table
          class="axs-table dense task-table"
          [dataSource]="data"
          matSort
          [matSortActive]="sortService.active"
          [matSortDirection]="sortService.direction"
          (matSortChange)="sortService.change($event)"
          matSortDisableClear>

          <ng-container matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox [checked]="anyChecked" (change)="toggleAllChecked($event)"></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox [checked]="row.IsChecked" (change)="toggleChecked(row)"></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Titel </th>
            <td mat-cell *matCellDef="let row" (click)="taskClicked(row.TaskList.TaskId)"> {{row.TaskList.Title}} </td>
          </ng-container>

          <ng-container matColumnDef="queue">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Queue </th>
            <td mat-cell *matCellDef="let row" (click)="taskClicked(row.TaskList.TaskId)"> {{row.TaskList.Queue}} </td>
          </ng-container>

          <ng-container matColumnDef="createdDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Erstellt </th>
            <td mat-cell *matCellDef="let row" (click)="taskClicked(row.TaskList.TaskId)"> {{row.TaskList.CreatedDate | date: "dd.MM.yyyy HH:mm:ss"}} </td>
          </ng-container>

          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Adresse/Kunde </th>
            <td mat-cell *matCellDef="let row" (click)="taskClicked(row.TaskList.TaskId)"> {{row.TaskList.Address}} </td>
          </ng-container>

          <ng-container matColumnDef="dueDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Fällig </th>
            <td mat-cell *matCellDef="let row" (click)="taskClicked(row.TaskList.TaskId)"> {{row.TaskList.DueDate | date: "dd.MM.yyyy"}} </td>
          </ng-container>

          <ng-container matColumnDef="priority">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Priorität </th>
            <td mat-cell *matCellDef="let row" (click)="taskClicked(row.TaskList.TaskId)"> {{row.TaskList.Priority}} </td>
          </ng-container>

          <ng-container matColumnDef="createdBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Erstellt von </th>
            <td mat-cell *matCellDef="let row" (click)="taskClicked(row.TaskList.TaskId)"> {{row.TaskList.CreatedBy}} </td>
          </ng-container>

          <ng-container matColumnDef="assigned">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Zugewiesen an </th>
            <td mat-cell *matCellDef="let row" (click)="taskClicked(row.TaskList.TaskId)"> {{row.TaskList.Assigned}} </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Status </th>
            <td mat-cell *matCellDef="let row" (click)="taskClicked(row.TaskList.TaskId)"> {{row.TaskList.Status}} </td>
          </ng-container>

          <ng-container matColumnDef="lastModified">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Letzte Änderung </th>
            <td mat-cell *matCellDef="let row" (click)="taskClicked(row.TaskList.TaskId)"> {{row.TaskList.LastModified | date: "dd.MM.yyyy HH:mm:ss"}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnEditor.displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: columnEditor.displayedColumns;"></tr>
        </table>

        <app-table-nodata-indicator [noData]="data?.length === 0"></app-table-nodata-indicator>

        <app-loading-overlay [isLoading]="isLoading" overlay="light"></app-loading-overlay>
      </div>

    </mat-drawer-container>

  </div>

  <mat-divider></mat-divider>

  <div class="toolbar">
    <button type="button" mat-raised-button color="primary" [matMenuTriggerFor]="actionMenu" [disabled]="!anyChecked">Aktionen</button>

    <mat-menu #actionMenu="matMenu">
      <button mat-menu-item (click)="editTasks($event)">Tasks bearbeiten</button>
    </mat-menu>
  </div>

</div>