import * as angular from "angular";

export class NgRightClickDirective implements angular.IDirective {

  constructor(
    private readonly $parse: angular.IParseService
  ) { }

  link = (scope: angular.IScope, element: angular.IAugmentedJQuery, attrs: angular.IAttributes) => {
    const fn = this.$parse(attrs.ngRightClick);
    element.bind('contextmenu', function (event) {
      scope.$apply(function () {
        event.preventDefault();
        fn(scope, { $event: event });
      });
    });
  };

  static factory(): angular.IDirectiveFactory {
    const directive = ($parse: angular.IParseService) => new NgRightClickDirective($parse);
    directive.$inject = ['$parse'];
    return directive;
  }
}
