import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InfoHubService } from '../../services/info-hub.service';
import { firstValueFrom } from 'rxjs';
import { InformationHubScriptGeneratorVariableDto } from '../../dto/information-hub-script-generator-variable-dto';
import { FormControl } from '@angular/forms';
import { ArrayUtils } from 'src/app/shared/utils/array-utils';
import { InfoHubScriptGeneratorVm } from './info-hub-script-generator-vm';
import { InformationHubEntryDto } from '../../dto/information-hub-entry-dto';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-info-hub-script-generator-dialog',
  templateUrl: './info-hub-script-generator-dialog.component.html',
  styleUrls: ['./info-hub-script-generator-dialog.component.scss']
})
export class InfoHubScriptGeneratorDialogComponent implements OnInit {

  informationHubEntryId: number;
  informationHubEntry: InformationHubEntryDto;

  variables: InformationHubScriptGeneratorVariableDto[] = [];
  variableVms: InfoHubScriptGeneratorVm[] = [];

  isEditing: boolean = false;

  newVariableNameControl = new FormControl<string>(null);
  newVariableValueControl = new FormControl<string>(null);
  newVariablePatternControl = new FormControl<string>(null);

  scriptText: string = "";

  tabIndex: number = 0;


  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: {
      informationHubEntryId?: number,
    },
    private readonly infoHubService: InfoHubService
  ) { }

  async ngOnInit() {

    if (this.data.informationHubEntryId) {
      this.informationHubEntryId = this.data.informationHubEntryId;
    }

    await this.loadData();
  }

  async loadData() {
    this.variables = await firstValueFrom(this.infoHubService.getInformationHubScriptGeneratorVariables(this.informationHubEntryId));
    this.informationHubEntry = await firstValueFrom(this.infoHubService.getInformationHubEntry(this.informationHubEntryId));

    this.variableVms = [];

    for (const variable of this.variables) {
      const newVm: InfoHubScriptGeneratorVm =
      {
        dto: variable,
        nameControl: new FormControl(variable.variableName),
        valueControl: new FormControl(variable.variableValue),
        patternControl: new FormControl(variable.pattern)
      };

      this.variableVms.push(newVm);
    }
  }

  toggleEdit() {
    this.isEditing = !this.isEditing;
  }

  addVariable() {

    const newVariable: InformationHubScriptGeneratorVariableDto =
    {
      id: 0,
      informationHubEntryId: this.informationHubEntryId,
      variableName: this.newVariableNameControl.value,
      variableValue: this.newVariableValueControl.value,
      sortOrder: 1,
      pattern: this.newVariablePatternControl.value
    };

    if (this.variableVms.length) {
      newVariable.sortOrder = (this.variableVms[this.variableVms.length - 1].dto.sortOrder + 1);
    }

    const newVm: InfoHubScriptGeneratorVm = {
      dto: newVariable,
      nameControl: new FormControl(newVariable.variableName),
      valueControl: new FormControl(newVariable.variableValue),
      patternControl: new FormControl(newVariable.pattern)
    };

    this.variableVms.push(newVm);
    this.newVariableNameControl.patchValue(null);
    this.newVariableValueControl.patchValue(null);
    this.newVariablePatternControl.patchValue(null);
  }

  removeVariable(vm: InfoHubScriptGeneratorVm) {
    ArrayUtils.remove(this.variableVms, vm);
  }

  async save() {

    const variableList: InformationHubScriptGeneratorVariableDto[] = [];
    let index = 0;

    for (const vm of this.variableVms) {
      vm.dto.variableName = vm.nameControl.value;
      vm.dto.variableValue = vm.valueControl.value;
      vm.dto.sortOrder = index;
      vm.dto.pattern = vm.patternControl.value;

      variableList.push(vm.dto);
      index++;
    }

    await firstValueFrom(this.infoHubService.updateInformationHubScriptGeneratorVariables(variableList, this.informationHubEntryId));
    await this.loadData();
    this.isEditing = false;
  }

  generateScript() {

    let content = this.informationHubEntry.content;

    for (const vm of this.variableVms) {
      if (vm.valueControl.value) {
        content = content.replaceAll(vm.nameControl.value, vm.valueControl.value);
      }
    }

    this.scriptText = content;
    this.tabIndex = 1;
  }

  drop(event: CdkDragDrop<InfoHubScriptGeneratorVm[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

}

