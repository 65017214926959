<div class="content-padding">
  <div class="header">
    <h1>Telefonieauswertung</h1>

    <mat-form-field appearance="outline" class="remove-hint">
      <mat-label>Monat wählen</mat-label>
      <input matInput [matDatepicker]="picker" [formControl]="date">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker startView="year" (monthSelected)="monthSelected($event, picker)"></mat-datepicker>
    </mat-form-field>
  </div>

  <mat-table [dataSource]="data?.Addresses" class="cp-table dense" multiTemplateDataRows>
    <ng-container matColumnDef="displayName">
      <mat-header-cell *matHeaderCellDef>Kunde/Adresse</mat-header-cell>
      <mat-cell *matCellDef="let address">{{address.AddressDisplayName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="sums">
      <mat-header-cell *matHeaderCellDef>Anrufe</mat-header-cell>
      <mat-cell *matCellDef="let address">{{address.TotalCalls + " Anrufe, insgesamt"}} {{address.TotalCallDuration | duration}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="toggle">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let address">
        <button mat-icon-button (click)="toggleDetail(address); $event.stopPropagation()">
          <mat-icon *ngIf="!isExpanded(address)">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="isExpanded(address)">keyboard_arrow_up</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let address">
        <div class="detail-container" [@detailExpand]="isExpanded(address) ? 'expanded' : 'collapsed'">
          <div class="detail-content">

            <mat-table [dataSource]="address.Calls" class="cp-table dense detail-table">
              <ng-container matColumnDef="date">
                <mat-cell *matCellDef="let call">
                  <span>{{call.StartDateTime | date: 'dd.MM.yyyy HH:mm'}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="time">
                <mat-cell *matCellDef="let row">
                  <span>{{row.Duration | duration }}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="contact">
                <mat-cell *matCellDef="let row">
                  <a href="#" (click)="phoneService.showPhoneContactDialog(row.Contact.ContactId)">{{row.Contact.DisplayName}}</a>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="number">
                <mat-cell *matCellDef="let row">
                  <a href="{{ row.OtherPartyNumber | tel }}">{{row.OtherPartyNumber}}</a>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="mainExtensionDescription">
                <mat-cell *matCellDef="let row">
                  <span>{{row.MainParticipantExtension + " - " + row.MainParticipantExtensionDescription}}</span>
                </mat-cell>
              </ng-container>

              <mat-row *matRowDef="let row; columns: ['date', 'time', 'contact', 'number', 'mainExtensionDescription']"></mat-row>
            </mat-table>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="['displayName', 'sums', 'toggle']"></mat-header-row>
    <mat-row
      *matRowDef="let address; columns:['displayName', 'sums', 'toggle']"
      class="address-row"
      [class.expanded]="isExpanded(address)"
      (click)="toggleDetail(address)">
    </mat-row>
    <mat-row
      *matRowDef="let address; columns:['expandedDetail']"
      class="detail-row"
      [class.expanded]="isExpanded(address)"></mat-row>
  </mat-table>

  <app-table-nodata-indicator [isLoading]="isLoading" [noData]="!data?.Addresses?.length"></app-table-nodata-indicator>

  <mat-table *ngIf="!isLoading && data?.Extensions?.length" [dataSource]="data?.Extensions" class="cp-table dense extension-table">
    <ng-container matColumnDef="displayName">
      <mat-header-cell *matHeaderCellDef>Nebenstelle</mat-header-cell>
      <mat-cell *matCellDef="let ext">{{ext.MainParticipantExtension + " - " + ext.MainParticipantExtensionDescription}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="sums">
      <mat-header-cell *matHeaderCellDef>Anrufe</mat-header-cell>
      <mat-cell *matCellDef="let ext">{{ext.TotalCalls + " Anrufe, insgesamt" }} {{ext.TotalCallDuration | duration}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="toggle">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let address">
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="['displayName', 'sums', 'toggle']"></mat-header-row>
    <mat-row *matRowDef="let ext; columns:['displayName', 'sums', 'toggle']" class="extension-row"></mat-row>
  </mat-table>
</div>
