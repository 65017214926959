import { FormControl } from "@angular/forms";
import { EPCPositionsTyp } from "src/app/sage-export/enums/epc-positions-typ";

export class ContractDialogProductForm {

  contractProductId: number = 0;
  expressionId: number = 0;
  sortOrder: number;

  positionsTyp: number = EPCPositionsTyp.Artikel;

  articleNumber: FormControl<string> = new FormControl<string>('');
  articleDescription1: FormControl<string> = new FormControl<string>('');
  articleDescription2: FormControl<string> = new FormControl<string>('');
  articleExpressionText: FormControl<string> = new FormControl<string>('');

  // string anstatt number weil sonst die Formatierung auf zwei dezimalstellen nicht möglich ist
  price: FormControl<string> = new FormControl<string>("0,00");
  amount: FormControl<string> = new FormControl<string>("1,00");
  //

  discount: FormControl<number> = new FormControl<number>(0);
  isContractPrice: FormControl<boolean> = new FormControl<boolean>(false);
  isContractDiscount: FormControl<boolean> = new FormControl<boolean>(false);

  langtext: FormControl<string> = new FormControl<string>("");

}
