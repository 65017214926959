
import { ContractDto } from "src/app/contracts/dto/contract-dto";
import { SageExportContractProductDto } from "./sage-export-contract-product-dto";
import { FormControl } from "@angular/forms";

export interface SageExportContractDto {
  id: number;
  contractId: number;
  contract: ContractDto;
  sageExportContractGroupId: number;
  intervallMonths: number;
  validFrom: Date;
  validUntil: Date;
  billedFrom: Date;
  billedUntil: Date;
  sageExportContractProducts: SageExportContractProductDto[];

  // nur im Frontend
  isSelected: FormControl<boolean>;
}

export function fixSageExportContractDto(dto: SageExportContractDto) {

  if (dto) {
    dto.validFrom = new Date(dto.validFrom);
    dto.validUntil = new Date(dto.validUntil);
    dto.billedFrom = new Date(dto.billedFrom);
    dto.billedUntil = new Date(dto.billedUntil);
  }
}