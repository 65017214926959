import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { cleanupPhoneNumber } from "src/ajs/Utils/HestiaCommon";

export class ContactFlyoutController {
  static $inject = ['$scope', 'mdPanelRef', 'theme', 'Contact', 'CallCallback'];

  constructor(
    $scope: any,
    private readonly mdPanelRef: angular.material.IPanelRef,
    theme: ThemeDto,
    public readonly Contact: any,
    public readonly CallCallback: any,
  ) {
    addDefaultScopeFunctions($scope, theme);
  }

  OnCall(number: string) {
    number = cleanupPhoneNumber(number);
    if (this.CallCallback) {
      this.CallCallback(number);
    }
  }

  Close() {
    this.mdPanelRef.close();
  };


}
