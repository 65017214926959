import { addNavigation } from 'src/ajs/Utils/HestiaCommon';
import { ApiHandlerService } from '../Services/ApiHandlerService';
import { ScreenWidthService } from '../Services/ScreenWidthService';
import { SearchBarService } from '../Services/SearchBarService';
import { StorageService, StorageServiceInstance } from '../Services/StorageService';


export class Phone3CXController {
  static $inject = ['$scope', 'ApiHandlerService', 'StorageService', 'SearchBarService', 'ScreenWidthService'];

  readonly Storage: StorageServiceInstance;
  SelectedAddressId: number;

  constructor(
    private readonly $scope,
    private readonly ApiHandlerService: ApiHandlerService,
    private readonly StorageService: StorageService,
    private readonly SearchBarService: SearchBarService,
    public readonly ScreenWidthService: ScreenWidthService
  ) {

    this.ApiHandlerService.SendRequest("Phone3cx", "CheckAccess");

    this.$scope.$on("bindAddress", (event, args) => {
      this.SelectedAddressId = args.Id;
    });

    this.SearchBarService.RequireSearchBar(true);

    this.Storage = this.StorageService.CreateInstance("aXsPhone3CXController");
    this.$scope.Storage = this.Storage;

    addNavigation(this.$scope, "CallList");
  }

  AfterViewInit() {
    this.$scope.Navigate(this.Storage.GetOrCreate("ActiveTab", "CallList"), true);
  }
}
