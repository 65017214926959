import * as angular from 'angular';

export class SignalRService {

  static readonly $inject = ['$timeout', 'BASE_URL_SIGNALR'];

  IsRunning = false;
  Started = false;
  StartedCallbacksCallbacks: (() => void)[] = [];

  constructor(
    private readonly $timeout: angular.ITimeoutService,
    private readonly BASE_URL_SIGNALR: string) {
  }

  Reconnect() {
    if (this.Started) {
      //$.connection.hub.stop();
      $.connection.hub.start().done(() => {
        this.IsRunning = true;
        this.StartedCallbacksCallbacks.forEach((callback) => {
          callback();
        });
      });
    }
  }

  StartedEvent(callback: () => void) {
    let replaced = false;
    for (let i = 0; i < this.StartedCallbacksCallbacks.length; i++) {
      if (this.StartedCallbacksCallbacks[i].toString() === callback.toString()) {
        this.StartedCallbacksCallbacks[i] = callback;
        replaced = true;
      }
    }
    if (!replaced) {
      this.StartedCallbacksCallbacks.push(callback);
    }
  }

  InitService() {
    return new Promise<void>((resolve, reject) => {
      $.connection.hub.url = this.BASE_URL_SIGNALR;
      $.connection.hub.start().done(() => {
        this.IsRunning = true;
        this.Started = true;

        if (this.StartedCallbacksCallbacks) {
          for (const callback of this.StartedCallbacksCallbacks) {
            callback();
          }
        }

        $.connection.hub.disconnected(() => {
          this.IsRunning = false;
          this.$timeout(this.Reconnect.bind(this), 5000);
        });
        resolve();
      });
    });
  }
}
