<cp-detailbar>
  <cp-detailbar-title>
    Benachrichtigungseinstellungen
  </cp-detailbar-title>

  <cp-detailbar-content>
    <button mat-raised-button type="button" color="primary" (click)="save()" [disabled]="formGroup.invalid || formGroup.pristine">
      Speichern
    </button>
  </cp-detailbar-content>
</cp-detailbar>

<div class="content-padding">

  <form [formGroup]=" formGroup">

    <div>
      <h2>Email-Einstellungen</h2>

      <mat-checkbox formControlName="subscriptionCheckbox">Benachrichtigungen per Email senden</mat-checkbox>

      <mat-radio-group formControlName="subscriptionRadio">
        <mat-radio-button value="i"> Sofort benachrichten </mat-radio-button>
        <mat-radio-button value="d"> Täglich benachrichtigen </mat-radio-button>
        <mat-radio-button value="w"> Wöchentlich benachrichtigen </mat-radio-button>
      </mat-radio-group>
    </div>

    <!--
  Für erste nur ein Typ bis wir uns überlegt haben wie wir das übermitteln

  <div >
    <h2>Abonnements</h2>

    <mat-checkbox  formControlName="subscriptionCheckbox"> per Email benachrichten</mat-checkbox>

    <mat-radio-group  formControlName="subscriptionRadio">
      <mat-radio-button value="i"> Sofort benachrichten </mat-radio-button>
      <mat-radio-button value="d"> Täglich benachrichtigen </mat-radio-button>
      <mat-radio-button value="w"> Wöchentlich benachrichtigen </mat-radio-button>
    </mat-radio-group>
  </div>

  <div >
    <h2>Aktivitäten</h2>

    <mat-checkbox  formControlName="activityCheckbox"> per Email benachrichten</mat-checkbox>

    <mat-radio-group  formControlName="activityRadio">
      <mat-radio-button value="i"> Sofort benachrichten </mat-radio-button>
      <mat-radio-button value="d"> Täglich benachrichtigen </mat-radio-button>
      <mat-radio-button value="w"> Wöchentlich benachrichtigen </mat-radio-button>
    </mat-radio-group>
  </div>

  <div >
    <h2>Sonstige</h2>

    <mat-checkbox  formControlName="miscellaneousCheckbox"> per Email benachrichten</mat-checkbox>

    <mat-radio-group  formControlName="miscellaneousRadio">
      <mat-radio-button value="i"> Sofort benachrichten </mat-radio-button>
      <mat-radio-button value="d"> Täglich benachrichtigen </mat-radio-button>
      <mat-radio-button value="w"> Wöchentlich benachrichtigen </mat-radio-button>
    </mat-radio-group>
  </div>
  -->

  </form>
</div>
