<div class="flex-container">

  <h2>Datensatz bearbeiten</h2>

  <mat-form-field>
    <mat-label>
      Bezeichnung
    </mat-label>
    <input matInput type="text" [formControl]="tabNameInput">
  </mat-form-field>


  <div>
    <button mat-raised-button (click)="delete()" color="warn">Datensatz löschen</button>
    <button mat-raised-button (click)="ok()" color="primary">Ok</button>
  </div>

</div>