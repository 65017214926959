import { Injectable } from '@angular/core';
import { Role } from 'src/app/core/constants';
import { UserService } from 'src/app/core/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class PublicCalendarPrivilegesService {

  constructor(
    private readonly userService: UserService
  ) { }

  canRequestAccess(mandatorId: string) {
    return this.userService.hasRoleDeprecated(Role.CalendarRequestAccess, mandatorId);
  }
}

