import { fixGetTaskListDto, GetTaskListDto } from "./get-task-list-dto";

export interface GetTaskListResultDto {
  TotalCount: number;
  TaskList: GetTaskListDto[];
}

export function fixGetTaskListResultDto(dto: GetTaskListResultDto) {
  if (dto) {
    if (dto.TaskList) {
      dto.TaskList.forEach(fixGetTaskListDto);
    }
  }
}
