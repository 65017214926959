import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, merge, takeUntil } from 'rxjs';
import { copyToClipboard } from 'src/ajs/Utils/HestiaCommon';
import { WidgetService } from '../../services/widget.service';


@Component({
  selector: 'app-password-generator-dialog',
  templateUrl: './password-generator-dialog.component.html',
  styleUrls: ['./password-generator-dialog.component.scss']
})
export class PasswordGeneratorDialogComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<void>();

  passwordType = new FormControl<string>("12 GK");
  passwordLenght = new FormControl<number>(12);
  passwordAmount = new FormControl<number>(5);

  useUppercaseLetters = new FormControl<boolean>(true);
  useSpecialLetters = new FormControl<boolean>(false);

  hashAlogrithm = new FormControl<string>("HMAC-SHA-1");
  hashValue = new FormControl<string>("");
  hashKey = new FormControl<string>("8544E3B47ECA58F9583043F8");
  hashResult = new FormControl<string>("");

  passwords: string[] = [];
  passwordCharset: string = "";

  charsetDefault = "abcdefghijkmnopqrstuvwxyz0123456789";
  charsetUppercase = "ABCDEFGHJKLMNPQRSTUVWXYZ";
  charsetSpecial = "!§$%&()=.-_#+@[]{}";

  selectedCopyIndex: number = null;
  selectedCopyPassword: string = "";

  isInHashView: boolean = false;
  isInSliderView: boolean = false;

  constructor(
    private readonly widgetService: WidgetService
  ) { }

  ngOnInit() {
    this.generatePasswords();

    this.passwordType.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(value => {

      switch (value) {
        case "12 GK": {
          this.passwordLenght.patchValue(12);
          this.useUppercaseLetters.patchValue(true);
          this.useSpecialLetters.patchValue(false);
          break;
        }
        case "12 GKS": {
          this.passwordLenght.patchValue(12);
          this.useUppercaseLetters.patchValue(true);
          this.useSpecialLetters.patchValue(true);
          break;
        }
        case "16 GK": {
          this.passwordLenght.patchValue(16);
          this.useUppercaseLetters.patchValue(true);
          this.useSpecialLetters.patchValue(false);
          break;
        }
        case "16 GKS": {
          this.passwordLenght.patchValue(16);
          this.useUppercaseLetters.patchValue(true);
          this.useSpecialLetters.patchValue(true);
          break;
        }
        case "24 GK": {
          this.passwordLenght.patchValue(24);
          this.useUppercaseLetters.patchValue(true);
          this.useSpecialLetters.patchValue(false);
          break;
        }
        case "24 GKS": {
          this.passwordLenght.patchValue(24);
          this.useUppercaseLetters.patchValue(true);
          this.useSpecialLetters.patchValue(true);
          break;
        }
      }

    });

    merge(
      this.hashValue.valueChanges,
      this.hashKey.valueChanges,
      this.hashAlogrithm.valueChanges
    ).pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.calculateHash();
    });

    merge(
      this.passwordLenght.valueChanges,
      this.passwordAmount.valueChanges,
      this.useUppercaseLetters.valueChanges,
      this.useSpecialLetters.valueChanges
    ).pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.generatePasswords();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  generatePasswords() {
    this.passwords = [];
    this.passwordCharset = this.charsetDefault;

    if (this.useUppercaseLetters.value) {
      this.passwordCharset += this.charsetUppercase;
    }
    if (this.useSpecialLetters.value) {
      this.passwordCharset += this.charsetSpecial;
    }

    for (let i = 0; i < this.passwordAmount.value; i++) {
      const password = this.generatePassword();
      this.passwords.push(password);
    }
  }

  generatePassword() {
    let value = "";

    for (let i = 0; i < this.passwordLenght.value; i++) {
      const char = this.passwordCharset.charAt(Math.floor(Math.random() * this.passwordCharset.length));
      value += char;
    }
    return value;

  }

  copyPassword(index: number, password: string) {
    this.selectedCopyIndex = index;
    this.selectedCopyPassword = password;

    copyToClipboard(password);
  }

  toggleHashView() {
    this.isInHashView = !this.isInHashView;
  }

  toggleSliderView() {
    this.isInSliderView = !this.isInSliderView;
  }

  async calculateHash() {

    if ((this.hashKey.value || this.hashAlogrithm.value === "SHA256") && this.hashValue.value) {
      const result = await this.widgetService.generateHash(this.hashValue.value, this.hashKey.value, this.hashAlogrithm.value);

      if (result.Result) {
        this.hashResult.patchValue(result.Result);
      }
      else {
        this.hashResult.patchValue("");
      }

    }
  }

}
