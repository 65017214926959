<div class="dialog-container">

  <h2>Artikel Variante wählen</h2>

  <div class="flex-container">

    <div>
      <div class="title">{{article.expression1Description}}</div>

      <mat-radio-group [formControl]="expression1">

        <div *ngFor="let expression of article?.expressions1">
          <mat-radio-button [value]="expression">
            <span *ngIf="expression">{{expression}}</span>
            <span *ngIf="!expression">(keine)</span>
          </mat-radio-button>

        </div>
      </mat-radio-group>
    </div>

    <div *ngIf="article.expressions2.length">
      <div class="title">{{article.expression2Description}}</div>

      <mat-radio-group [formControl]="expression2">

        <div *ngFor="let expression of article?.expressions2">
          <mat-radio-button [value]="expression">
            <span *ngIf="expression">{{expression}}</span>
            <span *ngIf="!expression">(keine)</span>
          </mat-radio-button>

        </div>
      </mat-radio-group>
    </div>

  </div>

  <div class="flex-end">
    <button mat-raised-button color="primary" (click)="ok()" [disabled]="!isValid()">Ok</button>
  </div>

</div>