import * as angular from "angular";
import * as moment from "moment";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { LocalizationService, PluginDict } from "src/ajs/Services/Localization/LocalizationService";
import { Api1Service } from "src/app/core/services/api1.service";
import { MandatorService } from "src/app/core/services/mandator.service";
import { BirthdayWidgetResult } from "./BirthdayWidgetResult";
import { BmdBirthday } from "./BmdBirthday";

export class BirthdayTemplateController {

  static $inject = ['$scope', '$mdToast', 'ApiHandlerService', 'Api1ServiceNgx', 'LocalizationService', 'MandatorServiceNgx'];
  private isAddingAppointment = false;

  constructor(
    public readonly $scope: IBirthdayTemplateScope,
    private readonly $mdToast: angular.material.IToastService,
    private readonly ApiHandlerService: ApiHandlerService,
    private readonly api: Api1Service,
    LocalizationService: LocalizationService,
    private readonly mandatorService: MandatorService
  ) {
    $scope.IsLoading = true;
    $scope.Localization = LocalizationService.GetPluginDict("PublicContacts");
    $scope.AddToAppointments = this.AddToAppointments.bind(this);
    $scope.CanAddAppointment = this.CanAddAppointment.bind(this);

    this.LoadData();
  }

  AddToAppointments(model: BmdBirthday) {
    if (!this.isAddingAppointment) {
      this.isAddingAppointment = true;
      this.ApiHandlerService.SendRequest("PublicContacts", "AddBirthdayToExchange", { model: model }).then(data => {
        if (data.Success) {
          this.$scope.Model.FoundEntries.push(model.EMail);
          this.$mdToast.show(
            this.$mdToast.simple()
              .textContent(this.$scope.Localization.Localize('BirthdayAdded', model.FirstName, model.LastName))
              .position('bottom right')
              .hideDelay(5000));
        }
        this.isAddingAppointment = false;
      });
    }
  }

  CanAddAppointment(mail: string): boolean {
    if (this.$scope.Model && this.$scope.Model.FoundEntries) {
      return this.$scope.Model.FoundEntries.filter(f => f === mail).length === 0;
    }
    return false;
  }

  private LoadData() {
    this.api.get<BirthdayWidgetResult>("PublicContacts", "GetBirthdayTemplate", { mandatorId: this.mandatorService.selectedMandatorId }).subscribe((data) => {
      this.$scope.Model = data;
      this.$scope.Model.Today.forEach(f => {
        f.Birthday = moment(f.Birthday);
        f.FormattedBirthday = moment(f.Birthday).format("DD.MM.");
      });
      this.$scope.Model.Week.forEach(f => {
        f.Birthday = moment(f.Birthday);
        f.FormattedBirthday = moment(f.Birthday).format("DD.MM.");
      });
      this.$scope.Model.Month.forEach(f => {
        f.Birthday = moment(f.Birthday);
        f.FormattedBirthday = moment(f.Birthday).format("DD.MM.");
      });
      this.$scope.IsLoading = false;
    });
  }
}


interface IBirthdayTemplateScope extends angular.IScope {
  IsLoading: boolean;
  Localization: PluginDict;
  Model: BirthdayWidgetResult;

  AddToAppointments: (model: BmdBirthday) => void;
  CanAddAppointment: (mail: string) => boolean;
}
