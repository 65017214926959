import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-contract-file-note-dialog',
  templateUrl: './contract-file-note-dialog.component.html',
  styleUrls: ['./contract-file-note-dialog.component.scss']
})
export class ContractFileNoteDialogComponent implements OnInit {

  noteInput: FormControl<string> = new FormControl<string>("");


  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: {
      note?: string,
    },
    private readonly dialogRef: MatDialogRef<ContractFileNoteDialogComponent>,
  ) { }

  ngOnInit() {
    if (this.data.note) {
      this.noteInput.patchValue(this.data.note);
    }
  }

  ok() {
    this.dialogRef.close(this.noteInput.value);
  }

  cancel() {
    this.dialogRef.close(null);
  }

}
