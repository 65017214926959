import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Phone3CxCallDto } from "src/app/phone/dto/phone-3cx-call-dto";
import { TaskEntryDto } from "../../dto/task-entry-dto";
import { TasksReceiptRowDto } from "../../dto/tasks-receipt-row-dto";
import { AccountingType } from "./accounting-type.enum";
import { TaskReceiptRowEntryVm } from "./task-receipt-row-entry-vm";

export class TaskReceiptRowVm {

  task: TaskEntryDto | null;
  taskReceiptRow: TasksReceiptRowDto;
  entries: TaskReceiptRowEntryVm[] = [];
  formGroup: UntypedFormGroup;
  isExpanded = false;

  taskDuration: number;
  callDuration: number;

  /**
   * Wenn die ReceiptRow keinen Task hat, handelt es sich um die Telefonsupport-Row.
   * Davon kann es immer nur 0 oder 1 auf einem TaskReceipt geben.
   */
  get isPhoneSupport() {
    return !this.task;
  }

  constructor(
    private readonly formBuilder: UntypedFormBuilder
  ) {
    this.formGroup = this.formBuilder.group({
      accountingTypeToggle: [AccountingType.SpäterVerrechnen],
      accountedTime: [0],
    });

  }

  loadFromDto() {
    this.formGroup.patchValue({
      accountingTypeToggle: this.taskReceiptRow.AccountingType,
      accountedTime: this.taskReceiptRow.AccountedTime
    });

    this.calculateTaskDuration();
    this.calculateCallDuration();
  }

  private calculateTaskDuration() {
    let result = 0;
    for (const note of this.taskReceiptRow.NotesWithTime) {
      if (note) {
        result += (note.TimeEntry.Minutes * 60 * 1000);
      }
    }
    this.taskDuration = result;
  }

  private calculateCallDuration() {
    let result = 0;
    for (const call of this.taskReceiptRow.Calls) {
      if (call) {
        result += (this.getCallDurationMilliseconds(call));
      }
    }
    this.callDuration = result;
  }

  private getCallDurationMilliseconds(call: Phone3CxCallDto) {
    const result = call?.EndDateTime.getTime() - call?.StartDateTime.getTime();
    return result;
  }

}

