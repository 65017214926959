import { DoBootstrap, LOCALE_ID, NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpgradeModule } from '@angular/upgrade/static';
import { OwlDateTimeIntl, OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import 'dayjs/locale/de';
import { MomentModule } from 'ngx-moment';
import { QuillModule } from 'ngx-quill';
import Quill from 'quill';
import { ApiHandlerServiceProvider, mdPanelServiceProvider, rootScopeServiceProvider, routeParamsProvider, SearchBarServiceProvider, userServiceProvider } from './ajs-upgraded-providers';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HestiaMatPaginatorIntl } from './core/localization/german-mat-paginator-intl';
import { OwlGermanDateTimeIntl } from './core/localization/owl-german-date-time-intl';
import { ErpModule } from './erp/erp.module';
import { FileBrowserModule } from './file-browser/file-browser.module';
import { NotificationsModule } from './notifications/notifications.module';
import { PhoneModule } from './phone/phone.module';
import { PublicCalendarModule } from './public-calendar/public-calendar.module';
import { MDI_ICON_BASE_URL } from './shared/components/mdi-icon/mdi-icon.component';
import { SharedModule } from './shared/shared.module';
import { SystembuilderModule } from './systembuilder/systembuilder.module';
import { TaskModule } from './task/task.module';
import { ContractsModule } from './contracts/contracts.module';
import { WidgetsModule } from './widgets/widgets.module';
import { LabelsModule } from './labels/labels.module';
import { SageExportModule } from './sage-export/sage-export.module';
import { InfoHubModule } from './info-hub/info-hub.module';
import { XmlModule } from './xml/xml.module';

// Dates sollen immer in Lokalzeit an die API übergeben werden.
Date.prototype.toJSON = function () {
  const result =
    this.getFullYear().toString().padStart(4, '0') +
    '-' +
    (this.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    this.getDate().toString().padStart(2, '0') +
    'T' +
    this.getHours().toString().padStart(2, '0') +
    ':' +
    this.getMinutes().toString().padStart(2, '0') +
    ':' +
    this.getSeconds().toString().padStart(2, '0') +
    '.' +
    this.getMilliseconds().toString().padStart(3, '0');
  return result;
};

// Im Quill Editor wollen wir <div> statt <p> verwenden, weil der Absatz sonst so groß ist.
const parchment = Quill.import('parchment');
const block = parchment.query('block');
block.tagName = 'DIV';
Quill.register(block, true);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    UpgradeModule,
    CoreModule,
    SharedModule,
    PublicCalendarModule,
    MomentModule.forRoot(),
    QuillModule.forRoot({
      placeholder: "Text eingeben..."
    }),
    OwlDateTimeModule,
    ErpModule,
    FileBrowserModule,
    PhoneModule,
    TaskModule,
    NotificationsModule,
    SystembuilderModule,
    ContractsModule,
    WidgetsModule,
    LabelsModule,
    SageExportModule,
    InfoHubModule,
    XmlModule
  ],
  providers: [
    routeParamsProvider,
    rootScopeServiceProvider,
    userServiceProvider,
    mdPanelServiceProvider,
    ApiHandlerServiceProvider,
    SearchBarServiceProvider,
    { provide: LOCALE_ID, useValue: 'de-AT' },
    { provide: MatPaginatorIntl, useClass: HestiaMatPaginatorIntl },
    { provide: OwlDateTimeIntl, useClass: OwlGermanDateTimeIntl },
    { provide: MDI_ICON_BASE_URL, useValue: '/ClientApp/dist/@mdi' }
    // Weitere Provider befinden sich im CoreModule!
  ],
  bootstrap: []
})
export class AppModule implements DoBootstrap {

  constructor(
    private readonly upgrade: UpgradeModule
  ) {
    console.log('AppModule constructor');
  }

  ngDoBootstrap() {
    console.log('AppModule.ngDoBootstrap');
    this.upgrade.bootstrap(document.body, ['HestiaApp'], { strictDi: true });
  }
}
