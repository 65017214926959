import { fixGetPhoneListDto, PhoneListCallDto } from "./phone-list-call-dto";

export interface GetPhoneListResultDto {
  PhoneList: PhoneListCallDto[];
  TotalCount: number;
}

export function fixGetPhoneListResultDto(dto: GetPhoneListResultDto) {
  if (dto?.PhoneList) {
    for (const call of dto.PhoneList) {
      fixGetPhoneListDto(call);
    }
  }
}
