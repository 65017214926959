StartFromFilter.$inject = [];

export function StartFromFilter() {
  return function (input: any[], start: any) {
    if (input) {
      start = +start; //parse to int
      return input.slice(start);
    }
    return null;
  };
}