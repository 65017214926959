import angular, { IPromise, IQService, IScope } from "angular";
import moment from "moment";
import { DragAndDropPanel } from "src/ajs/Directives/DragAndDropPanel";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { SystemDetailsDto } from "src/ajs/Models/SystemSearch/SystemDetailsDto";
import { SystemDto } from "src/ajs/Models/SystemSearch/SystemDto";
import { SystemQueryResultDto } from "src/ajs/Models/SystemSearch/SystemQueryResultDto";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { StorageService, StorageServiceInstance } from "src/ajs/Services/StorageService";

export function internalCreate(
  $scope: IinternalCreateScope,
  mdPanelRef: angular.material.IPanelRef,
  $q: IQService,
  ApiHandlerService: ApiHandlerService,
  StorageService: StorageService,
  FixedModelIdentcode: string,
  theme: ThemeDto
) {

  addDefaultScopeFunctions($scope, theme);
  $scope.Storage = StorageService.CreateInstance("aXsSystemSearchController");

  $scope.QuerySystems = function (query: string) {
    let result = null;
    const deferred = $q.defer();
    const request = ApiHandlerService.SendRequest("aXsSystemSearch", "QuerySystems", { query: query }).then(function (data: SystemQueryResultDto) {
      result = data.Systems;
      deferred.resolve(result);
    });
    return deferred.promise;
  };

  $scope.SelectedSystemChanged = function (newSystem: SystemDto) {
    if (newSystem) {
      $scope.IsLoading = true;
      $scope.SelectedSystem = newSystem;
      ApiHandlerService.SendRequest("aXsSystemSearch", "QuerySystemDetails", { identcode: newSystem.Identcode }).then(function (data: SystemDetailsDto) {
        $scope.Model = data;
        $scope.Model.BaseData.SystemDate = moment($scope.Model.BaseData.SystemDate);
        $scope.IsLoading = false;
      });
    } else {
      $scope.SelectedSystem = null;
    }
  };

  $scope.DragAndDropPanel = new DragAndDropPanel("aXs Systemsuche", mdPanelRef);

  if (FixedModelIdentcode) {
    $scope.IsLoading = true;
    $scope.HasFixedModel = true;
    $scope.SelectedSystem = true;
    ApiHandlerService.SendRequest("aXsSystemSearch", "QuerySystemDetails", { identcode: FixedModelIdentcode }).then(function (data: SystemDetailsDto) {
      $scope.Model = data;
      $scope.Model.BaseData.SystemDate = moment($scope.Model.BaseData.SystemDate);
      $scope.IsLoading = false;
    });
  }
}

export interface IinternalCreateScope extends IScope {
  Storage: StorageServiceInstance;
  IsLoading: boolean;
  SelectedSystem: boolean | SystemDto;
  Model: SystemDetailsDto;
  DragAndDropPanel: DragAndDropPanel;
  HasFixedModel: boolean;

  QuerySystems: (query: string) => IPromise<unknown>;
  SelectedSystemChanged
}
