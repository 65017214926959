<div class="dialog-container">




  <h2>Artikelsuche</h2>

  <mat-form-field>
    <input matInput type="text" [formControl]="articleSearch">
  </mat-form-field>

  <div class="flex-container">
    <button *ngFor="let article of articles" mat-button class="flex-item" (click)="selectArticle(article)">
      {{article.displayText}}
    </button>

  </div>

</div>