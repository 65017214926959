import { UserDto } from "src/app/core/dto/user-dto";

export interface CalendarShareDto {
  token: string;
  sharedByUser: UserDto;
  sharedWithUser: UserDto;
  isApproved: boolean;
  validUntilUtc: Date;
}

export function fixCalendarShareDto(dto: CalendarShareDto) {
  if (dto) {
    dto.validUntilUtc = new Date(dto.validUntilUtc);
  }
}
