import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { UserDto } from 'src/app/core/dto/user-dto';
import { BusinessCardDialogComponent } from '../components/business-card-dialog/business-card-dialog.component';
import { MessageDialogData } from '../components/message-dialog/message-dialog-data';
import { MessageDialogComponent } from '../components/message-dialog/message-dialog.component';
import { Tag } from '../components/tag-edit-dialog/tag';
import { TagDialogData } from '../components/tag-edit-dialog/tag-edit-dialog-data';
import { TagEditDialogComponent } from '../components/tag-edit-dialog/tag-edit-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private readonly dialog: MatDialog
  ) { }

  async showMessage(title: string, content: string, action1: string = "OK", action1Color: string = 'primary', action2?: string, action2Color?: string, action3?: string, action3Color?: string): Promise<number> {
    const data: MessageDialogData = {
      title: title,
      content: content,
      action1: action1,
      action1Color: action1Color,
      action2: action2,
      action2Color: action2Color,
      action3: action3,
      action3Color: action3Color,
    };
    const result = await firstValueFrom(this.dialog.open<MessageDialogComponent, MessageDialogData, number>(MessageDialogComponent, { data: data, disableClose: true }).afterClosed());

    return result;
  }

  async showConfirmation(title: string, content: string, okAction: string, cancelAction: string, okColor: string = 'primary'): Promise<boolean> {
    const result = await this.showMessage(title, content, okAction, okColor, cancelAction);
    return result === 1;
  }

  openBusinessCard(event: MouseEvent, user: UserDto) {
    if (!user) { return; }

    this.dialog.open(BusinessCardDialogComponent, {
      data: user,

      // Wo hingeklickt wurde
      position: { left: event.clientX + 'px', top: event.clientY + 'px' },

      // Es soll kein Backdrop angezeigt werden. Um das zu erreichen,
      // muss einfach irgendeine backdropClass gesetzt sein.
      // hasBackdrop=false können wir nicht setzen, weil dann der Dialog nicht durch Klick
      // irgendwo auf der Seite geschlossen wird.
      backdropClass: 'business-card-backdrop',

      // Focus auf 'dialog' setzen, weil sonst die erste Telefonnummer fokusiert wird,
      // und das schaut komisch aus.
      autoFocus: 'dialog'
    });
  }


  async showTagEditDialog(tag: Tag, allTags: Tag[]): Promise<Tag> {
    const dialogRef = this.dialog.open<TagEditDialogComponent, TagDialogData, Tag>(TagEditDialogComponent, {
      width: '500px',
      data: {
        tag: tag,
        allTags: allTags
      }
    });

    const result = await firstValueFrom(dialogRef.afterClosed());
    return result;
  }
}
