export class ArrayUtils {
  static pushIfNotExists<T>(array: Array<T>, item: T): boolean {
    if (array && item && !array.some(x => x === item)) {
      array.push(item);
      return true;
    }
    return false;
  }

  static remove<T>(array: Array<T>, item: T): boolean {
    if (array && item) {
      const index = array.indexOf(item);
      if (index >= 0) {
        array.splice(index, 1);
        return true;
      }
    }
    return false;
  }

  /**
   * Entfernt alle Einträge aus dem `array`, auf die das `predicate` Zutrifft.
   */
  static removeWhere<T>(array: Array<T>, predicate: (item: T) => boolean) {
    if (array && predicate) {
      for (let i = 0; i < array.length;) {
        const match = predicate(array[i]);
        if (match) {
          array.splice(i, 1);
        }
        else {
          i++;
        }
      }
    }
  }

  /** Erzeugt eine seichte Kopie des Arrays (d.h. es ist ein neues Array, aber mit denselben Objekten als Items). */
  static copy<T>(source: Array<T>): Array<T> {
    const result = [...source];
    return result;
  }

  /**
   * Stellt sicher, dass ein Array einen bestimmten Eintrag enthält.
   * @param source Das Array.
   * @param element Das Element, das enthalten sein muss.
   * @param predicate Eine Funktion, die zum Vergleichen der Elemente verwenden wird. Wenn keine Funktion
   * übergeben wird, werden die Elemente mit deepEqual verglichen.
   */
  static ensureIncludes<T>(source: Array<T>, element: T, predicate?: (a: T, b: T) => boolean) {
    if (!source || !element) {
      return;
    }

    // check by strict equality
    if (source.indexOf(element) >= 0) {
      return;
    }

    if (predicate) {
      // check by predicate
      for (const existing of source) {
        if (predicate(element, existing)) {
          return;
        }
      }
    }
    else {
      // check by deepEqual
      for (const existing in source) {
        if (deepEqual(element, existing)) {
          return;
        }
      }
    }

    // nicht vorhanden - hinzufügen
    source.push(element);
  }

  /**
   * Stell sicher, dass ein Array einen bestimmten Eintrag enthält, anhand eines keySelectors.
   */
  static ensureIncludesBy<T>(source: Array<T>, element: T, keySelector: (element: T) => any) {
    if (!source || !element) {
      return;
    }

    const keyA = keySelector(element);

    for (const existing of source) {
      const keyB = keySelector(existing);

      if (keyA === keyB) {
        return;
      }
    }
    source.push(element);
  }

  /**
   * Stellt sicher, dass ein Array alle übergebenen Elemente enthält, anhand eines keySelectors.
   */
  static ensureIncludesAllBy<T>(source: Array<T>, elements: Array<T>, keySelector: (element: T) => any) {
    if (elements) {
      for (const element of elements) {
        this.ensureIncludesBy(source, element, keySelector);
      }
    }
  }
}

function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !deepEqual(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false;
    }
  }
  return true;
}

function isObject(object) {
  return object != null && typeof object === 'object';
}
