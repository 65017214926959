import * as angular from "angular";

export class ContextDirective implements angular.IDirective {

  restrict = 'A';
  // scope= '@&'; <-- war im .js so, ist aber kein gültiger Wert

  compile = (tElement, tAttrs, transclude) => {
    return {
      post: function postLink(scope: angular.IScope, iElement: JQLite, iAttrs: angular.IAttributes, controller: angular.IDirectiveController) {
        var ul = $('#' + iAttrs.context);
        var last: number = null;

        ul.css({
          'display': 'none'
        });
        $(iElement).bind('contextmenu', function (event) {
          event.preventDefault();
          ul.css({
            position: "fixed",
            display: "block",
            left: event.clientX + 'px',
            top: event.clientY + 'px'
          });
          last = event.timeStamp;
        });
        $(document).click(function (event) {
          var target = $(event.target);
          if (!target.is(".popover") && !target.parents().is(".popover")) {
            if (last === event.timeStamp)
              return;
            ul.css({
              'display': 'none'
            });
          }
        });
      }
    };
  };

  static factory(): angular.IDirectiveFactory {
    const directive = () => new ContextDirective();
    directive.$inject = [];
    return directive;
  }
}

