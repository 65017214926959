import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { Location } from "@angular/common";
import { Component, HostListener, Input } from '@angular/core';

/**
 * Diese Komponente kann als Direktive auf `<td>` angewendet werden und bewirkt dann zwei Dinge:
 * - Wrapped den Inhalt von `<td>` in ein `<a>` mit Link, damit man den Link kopieren oder in einem
 *   neuen Tab öffnen kann.
 * - Hängt auf das `<td>` ein `(click)` ein, das auch zum Link navigiert. Das wird gemacht, damit man
 *   auf Mobilgeräten nicht den Text treffen muss sondern nur die Zelle.
 */
@Component({
  selector: '[appCellLink]',
  templateUrl: './cell-link.component.html',
  styleUrls: ['./cell-link.component.scss'],
  host: {
    "[class.app-cell-link]": "cellLinkEnabled"
  }
})
export class CellLinkComponent {

  @Input() appCellLink = '';

  @Input()
  get cellLinkEnabled(): boolean {
    return this._cellLinkEnabled;
  }
  set cellLinkEnabled(value: BooleanInput) {
    this._cellLinkEnabled = coerceBooleanProperty(value);
  }
  private _cellLinkEnabled: boolean = true;


  constructor(
    private readonly location: Location,
  ) { }


  @HostListener('click', ['$event'])
  @HostListener('auxclick', ['$event'])
  onClick(e: MouseEvent) {

    const anchor = (e.target as HTMLElement)?.closest('a');
    if (anchor) {
      // Wenn auf ein <a> geklickt wurde, überlassen wir dem Browser die Navigation und das Standardverhalten.
      return;
    }

    if (!this.cellLinkEnabled) {
      return;
    }

    const isMiddleClick = e.button === 1 || (e.button === 0 && (e.metaKey || e.ctrlKey));
    const isPrimaryClick = e.button === 0 && !isMiddleClick;

    if (!isPrimaryClick && !isMiddleClick) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();

    if (isMiddleClick) {
      window.open(this.appCellLink, "_blank");
    }
    else {
      this.location.go(this.appCellLink);
    }
  }
}
