import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import angular from 'angular';
import { catchError, combineLatest, firstValueFrom, forkJoin, map, Observable, of, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { IPanelServiceEx } from 'src/ajs/Services/IPanelServiceEx';
import { mdPanelAjs } from 'src/app/ajs-upgraded-providers';
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { ConnectionStatus } from '../../constants';
import { Phone3CxCallDto } from '../../dto/phone-3cx-call-dto';
import { PhoneService } from '../../services/phone.service';
import { CallGroupVm } from './call-group-vm';
import { CallEntryDto } from 'src/ajs/Models/Phone3cx/CallEntryDto';
import { TaskService } from 'src/app/task/services/task.service';
import { ErpCachedAddressDto } from 'src/app/erp/dto/erp-cached-address-dto-legacy';

@Component({
  selector: 'app-call-details',
  templateUrl: './call-details.component.html',
  styleUrls: ['./call-details.component.scss']
})
export class CallDetailsComponent implements AfterViewInit, OnDestroy {

  private _callId: number;

  @Input() set callId(value: number) {
    if (this._callId !== value) {
      this._callId = value;
      this.refresh$.next();
    }
  }

  get callId() {
    return this._callId;
  }

  @Output() readonly closeRequest = new EventEmitter<void>();
  @Output() readonly refreshRequest = new EventEmitter<void>();

  private readonly refresh$ = new Subject<void>();
  private readonly destroy$ = new Subject<void>();
  private readonly refreshAll$ = new Subject<void>();

  formGroup: UntypedFormGroup;
  isLoading: boolean;
  isEditing: boolean = false;
  call: Phone3CxCallDto;
  callsFromNumber: Phone3CxCallDto[] = [];
  addresses: ErpCachedAddressDto[] = [];

  speakingTime: number;
  totalTime: number;
  callGroupVms: CallGroupVm[] = [];

  filteredAddresses$: Observable<ErpCachedAddressDto[]>;

  //Wird für OpenAddToTaskPanel gebraucht
  oldCallDtos: CallEntryDto[];
  private readonly $mdPanel: IPanelServiceEx;
  //

  constructor(
    private readonly phoneService: PhoneService,
    private readonly taskService: TaskService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly datepipe: DatePipe,
    $mdPanel: mdPanelAjs,
  ) {

    this.$mdPanel = $mdPanel as IPanelServiceEx;

    this.formGroup = this.formBuilder.group({
      note: [""],
      addressId: [null],

      addressFilter: [""],
    });



  }

  async ngAfterViewInit() {

    const baseData = await firstValueFrom(forkJoin([
      this.taskService.getAddresses()
    ]));

    this.addresses = baseData[0];

    this.refresh$.pipe(
      takeUntil(this.destroy$),
      startWith({}),
      tap(() => this.isLoading = true),
      switchMap(() => this.phoneService.getCall(this.callId))
    ).pipe(
      catchError(err => {
        console.log(err);
        return of({} as Phone3CxCallDto);
      })
    ).subscribe(async (call) => {
      this.call = call;
      this.callsFromNumber = await firstValueFrom(this.phoneService.getCallsWithNumber(this.call.OtherPartyNumber, 15));
      this.updateCallVms();
      this.loadFormDto();
      this.getOldCallDto();

      this.isEditing = false;
      this.isLoading = false;
    });

    this.filteredAddresses$ = combineLatest([
      this.taskService.getAddresses(),
      this.formGroup.get('addressFilter').valueChanges.pipe(
        startWith(''),
        map(search => ((search as string) ?? '').toLowerCase().trim())
      )
    ]).pipe(
      takeUntil(this.destroy$),
      map(([addresses, search]) => {
        return addresses.filter(x => x.CachedDisplayName.toLowerCase().includes(search));
      }));


    this.refreshAll$.pipe(
      takeUntil(this.destroy$),
    ).subscribe(() => {
      this.refreshRequest.emit();
      this.refresh$.next();
    });

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.refresh$.complete();
  }


  close() {
    this.closeRequest.emit();
  }

  getSpeakingTime() {
    let result = 0;

    for (const part of this.call.CleanPartList) {
      if (part.EntryEnd) {
        const duration = part.EntryEnd.getTime() - part.EntryStart.getTime();

        if (part.ConnectionStatus === ConnectionStatus.Connected && part.QueueId === null) {
          result += duration;
        }
      }
      else {
        const duration = Date.now() - part.EntryStart.getTime();

        if (part.ConnectionStatus === ConnectionStatus.Connected && part.QueueId === null) {
          result += duration;
        }
      }
    }
    this.speakingTime = result;
  }

  getTotalTime() {
    let result = 0;
    result = this.call?.EndDateTime.getTime() - this.call?.StartDateTime.getTime();
    this.totalTime = result;
  }

  getContactDisplayName() {
    let result = "";
    const firstName = this.call?.OtherPartyMainContact?.GivenName;
    const lastName = this.call?.OtherPartyMainContact?.LastName;

    if (firstName) {
      result = (firstName + " ");
    }
    if (lastName) {
      result += lastName;
    }
    return result;
  }

  async save() {
    this.call.Note = this.formGroup.value.note;
    if (this.call.OtherPartyMainContact) {
      this.call.OtherPartyMainContact.ErpCachedAddressId = this.formGroup.value.addressId;
    }

    await firstValueFrom(this.phoneService.saveCallDetails(this.call));
    this.refreshRequest.emit();
    this.isEditing = !this.isEditing;
  }

  openContactDialog() {
    this.phoneService.showPhoneContactDialog(this.call.OtherPartyMainContactId);
  }

  openTaskEditDialog() {
    this.taskService.showTaskEditDialog(this.call.TaskId);
  }

  openAddToTaskPanel() {
    this.$mdPanel.newPanelGroup("AddToTask", {
      maxOpen: 1
    });

    const config = {
      attachTo: angular.element(document.body),
      templateUrl: '/ClientApp/src/ajs/Views/Tasks/TasksSuggestionWidget.htm',
      controller: "TasksSuggestionWidgetController",
      panelClass: 'window-panel-container',
      bindToController: true,
      locals: {
        SelectedCalls: [this.oldCallDtos[0]],
        SelectedMails: null,
        tasksChanged: this.refreshAll$
      },
      focusOnOpen: true,
      propagateContainerEvents: true,
      groupName: ["AddToTask"],
      onCloseSuccess: () => {
        this.onCloseAddToTaskPanel();
      }
    };
    this.$mdPanel.open(config);
  }

  onCloseAddToTaskPanel() {
    //wird nicht benötigt wegen singalRService in phonelist?
    //this.refreshRequest.emit();

    this.refresh$.next();
  }

  getAddressDisplay(addressId: number) {
    let result = "";
    const address = this.addresses.find(x => x.ErpCachedAddressId === addressId);
    if (address) {
      result = address.CachedDisplayName;
    }
    return result;
  }

  updateCallVms() {
    this.callGroupVms = [];

    for (const call of this.callsFromNumber) {

      let currentLabel = "";

      const today = new Date();
      const yesterday = new Date();
      DateUtils.addDays(yesterday, -1);

      const isToday = (
        today.getDate() === call.StartDateTime.getDate() &&
        today.getDate() === call.StartDateTime.getMonth() &&
        today.getFullYear() === call.StartDateTime.getFullYear()
      );

      const isYesterday = (
        yesterday.getDate() === call.StartDateTime.getDate() &&
        yesterday.getDate() === call.StartDateTime.getMonth() &&
        yesterday.getFullYear() === call.StartDateTime.getFullYear()
      );


      if (isToday) {
        currentLabel = "Heute";
      }
      else if (isYesterday) {
        currentLabel = "Gestern";
      }
      else {
        currentLabel = this.datepipe.transform(call.StartDateTime, 'EEEE, dd.MM.yyyy');
      }

      const currentGroup = this.callGroupVms.find(x => x.label === currentLabel);

      if (currentGroup) {
        currentGroup.calls.push(call);
      }
      else {
        const newGroup: CallGroupVm = {
          label: currentLabel,
          calls: [],
        };
        newGroup.calls.push(call);
        this.callGroupVms.push(newGroup);
      }

    }
  }

  loadFormDto() {
    this.formGroup.patchValue({
      note: this.call.Note,
      addressId: this.call.OtherPartyMainContact?.ErpCachedAddressId
    });

    this.getSpeakingTime();
    this.getTotalTime();
  }

  async getOldCallDto() {
    //OldCallDto
    this.oldCallDtos = await firstValueFrom(this.phoneService.getCallsByIdLegacy([this.callId]));
    if (this.oldCallDtos[0]?.Contacts[0]?.Contact?.MainAddress) {
      this.oldCallDtos[0].Contacts[0].MainAddress = this.oldCallDtos[0].Contacts[0].Contact.MainAddress;
    }
    //

  }

}
