import { fixDate } from "src/ajs/Utils/DateUtils";

export interface TimeStatisticsDto {
  Date: Date;
  CallTime: number; // in Stunden
  TaskTime: number; // in Stunden
  ChargedTime: number; // in Stunden
  TotalTime: number; // in Stunden
}

export function fixTimeStatisticsDto(dto: TimeStatisticsDto) {

  dto.Date = fixDate(dto.Date);
}
