import angular from "angular";
import { CkeditorService } from "../Services/CkeditorService";

createCkeditorDirective.$inject = ['$timeout', 'CkeditorService'];

export function createCkeditorDirective(
  $timeout: angular.ITimeoutService,
  CkeditorService: CkeditorService) {

  return {

    require: '?ngModel',

    scope: {
      cidResolver: '&',
      ckeditorFill: '&',
      ckeditorLanguage: '@',
      onFileUploaded: '&'
    },


    link: function (scope, elm: angular.IAugmentedJQuery, attr: angular.IAttributes, ngModel) {
      scope.element = elm[0];
      scope.editorHeight = 250;
      if (attr.ckeditor !== 'full') {
        scope.initFunction = CKEDITOR.inline;
        scope.configFuntion = CkeditorService.createSmallToolbarConfig;
      } else {
        scope.initFunction = CKEDITOR.replace;
        scope.configFuntion = CkeditorService.createFullToolbarConfig;
      }
      const config = scope.configFuntion();
      if (scope.ckeditorLanguage) {
        config.language = scope.ckeditorLanguage.toLowerCase();
      }
      config.height = scope.editorHeight + "px";
      scope.ck = scope.initFunction(elm[0], config);
      scope.ck.on('fileUploadRequest', function (evt) {
        const fileLoader = evt.data.fileLoader,
          formData = new FormData(),
          xhr = fileLoader.xhr;
        xhr.open('POST', fileLoader.uploadUrl, true);
        formData.append('upload', fileLoader.file, fileLoader.fileName);
        fileLoader.xhr.send(formData);

        // Prevented the default behavior.
        evt.stop();
      }, null, null, 4);
      scope.ck.on('fileUploadResponse', function (evt) {
        const data = evt.data;
        const xhr = data.fileLoader.xhr;
        let response = xhr.responseText;
        response = JSON.parse(response);
        scope.onFileUploaded({ id: response.id, name: response.fileName, fileSize: response.fileSize });
        $timeout(function () {
          scope.ck.fire('change');
        }, 1);
      });

      function updateModel() {
        $timeout(function () {
          if (ngModel) {
            try {
              ngModel.$setViewValue(scope.ck.getData());
            } catch (e) {/*Ignore*/ }
          }
        }, 1);
      }

      scope.urlReplace = "https://localhost/Tasks/#";
      // Find better solution for filling screen
      /*if (scope.ckeditorFill) {
          onResize($("#" + attr.ckeditorFill)[0], function () {
              $timeout(function () {
                  try {
                      var pElement = $("#" + attr.ckeditorFill)[0];
                      scope.ck.resize('100%', pElement.clientHeight);
                  } catch (e) { }

              }, 150);
          });
      }*/

      scope.ck.on('instanceReady', function () {
        $timeout(function () {
          try {
            scope.ck.resize('100%', scope.element.parentElement.clientHeight);
            //updateModel();
          } catch (e) { /* Ignore */ }

        }, 50);
        if (ngModel) {
          scope.ck.setData(ngModel.$viewValue);
        }
      });

      scope.ck.on('change', updateModel);
      scope.ck.on('key', updateModel);
      scope.ck.on('dataReady', updateModel);

      if (ngModel) {
        ngModel.$render = function (value) {
          scope.ck.setData(ngModel.$viewValue);
        };
      }
    }
  };
}

