import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { InfoHubScriptGeneratorDialogComponent } from './components/info-hub-script-generator-dialog/info-hub-script-generator-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    InfoHubScriptGeneratorDialogComponent
  ]
})
export class InfoHubModule { }
