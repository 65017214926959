import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function hexColor(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (typeof control.value === 'string') {
      if (!control.value.match(/#[a-f0-9]{6}$/i)) {
        return { 'hexColor': control.value };
      }
    }
    return null;
  };
}
