import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDEAT from '@angular/common/locales/de-AT';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsConfig, MatTabsModule, MAT_TABS_CONFIG } from '@angular/material/tabs';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import { firstValueFrom, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PhoneSnackbarModule } from '../phone-snackbar/phone-snackbar.module';
import { PhoneSnackbarService } from '../phone-snackbar/services/phone-snackbar.service';
import { LoginComponent } from './components/login/login.component';
import { MandatorSelectorComponent } from './components/mandator-selector/mandator-selector.component';
import { GERMAN_DATE_FORMATS } from './localization/german-date-formats';
import { GermanNativeDateAdapter } from './localization/german-native-date-adapter';
import { OWL_GERMAN_DATETIME_FORMATS } from './localization/owl-german-datetime-formats';
import { OwlGermanNativeDateTimeAdapter } from './localization/owl-german-native-date-time-adapter ';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { AppConfigService } from './services/app-config.service';
import { MandatorService } from './services/mandator.service';
import { SignalRService } from './services/signalr/signalr.service';
import { UserService } from './services/user.service';


dayjs.locale('de');
dayjs.extend(customParseFormat);

registerLocaleData(localeDEAT);


/**
 * Was kommt ins CoreModule?
 * - Components, die unbedingt für die Anwendung gebraucht werden, z.B. Navigation
 * - Services, die in vielen Featuremodulen benötigt werden, z.B. Core, User, Authentication, File
 *
 * Was kommt ins SharedModule?
 * - Pipes, Directives, Components, die häufig in Featuremodulen gebraucht werden, aber nicht essentiell sind
 *
 * https://levelup.gitconnected.com/where-shall-i-put-that-core-vs-shared-module-in-angular-5fdad16fcecc
 */
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FlexLayoutModule,
    MatIconModule,
    MatTabsModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    PhoneSnackbarModule
  ],
  declarations: [
    LoginComponent,
    MandatorSelectorComponent
  ],
  providers: [

    // Localization
    { provide: MAT_DATE_FORMATS, useValue: GERMAN_DATE_FORMATS },
    { provide: OWL_DATE_TIME_FORMATS, useValue: OWL_GERMAN_DATETIME_FORMATS },
    { provide: DateAdapter, useClass: GermanNativeDateAdapter },
    { provide: DateTimeAdapter, useClass: OwlGermanNativeDateTimeAdapter },

    // Animationen für mat-tab-groups deaktivieren
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: "0ms" } as MatTabsConfig },

    // Beim Starten der App müssen Daten geladen werden.
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService, MandatorService, UserService, SignalRService, PhoneSnackbarService],
      useFactory: (appConfigService: AppConfigService, mandator: MandatorService, userService: UserService, signalRService: SignalRService, phoneSnackbarService: PhoneSnackbarService) => {
        signalRService.appInitialize();

        return () => {
          return firstValueFrom(
            forkJoin([
              appConfigService.appInitialize(),
              mandator.appInitialize(),
              userService.reloadUserInfo()
            ]).pipe(
              tap(() => {
                console.log('APP_INITIALIZER finished.');
                phoneSnackbarService.initialize();
              })
            ));
        };
      }
    },
  ]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

}
