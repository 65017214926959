import * as angular from 'angular';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { addDefaultScopeFunctions } from 'src/ajs/Functions/add-default-scope-functions';
import { ExtensionDto } from 'src/ajs/Models/Phone3cx/ExtensionDto';
import { PublicContactDto } from 'src/ajs/Models/PublicContacts/PublicContactDto';
import { PublicGroupDto } from 'src/ajs/Models/PublicContacts/PublicGroupDto';
import { ThemeDto } from 'src/ajs/Models/ThemeDto';
import { ApiHandlerService } from 'src/ajs/Services/ApiHandlerService';
import { IPanelServiceEx } from 'src/ajs/Services/IPanelServiceEx';
import { PhoneService } from 'src/ajs/Services/PhoneService';
import { PublicContactsService } from 'src/ajs/Services/PublicContactsService';
import { ScreenWidthService } from 'src/ajs/Services/ScreenWidthService';
import { SearchBarService } from 'src/ajs/Services/SearchBarService';
import { TimelineDatePickerService } from 'src/ajs/Services/Timeline/TimelineDatePickerService';
import { HasScrollbar } from 'src/ajs/Utils/HestiaCommon';
import { UserDto } from 'src/app/core/dto/user-dto';
import { MandatorService } from 'src/app/core/services/mandator.service';
import { UserService } from 'src/app/core/services/user.service';
import { PublicCalendarPrivilegesService } from 'src/app/public-calendar/services/public-calendar-privileges.service';
import { PublicCalendarService } from 'src/app/public-calendar/services/public-calendar.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { Timeline } from '../../Services/Timeline/Timeline';
import { TimelineMode } from '../../Services/Timeline/timeline-mode.enum';


createPublicCalendarController.$inject = ['$scope', '$window', '$document', '$mdDialog', '$q', '$timeout', '$mdPanel', 'ApiHandlerService', 'PhoneService', 'SearchBarService', 'ScreenWidthService', 'MandatorServiceNgx', 'PublicContactsService', 'TimelineDatePickerService', 'PublicCalendarServiceNgx', 'DialogServiceNgx', 'PublicCalendarPrivilegesServiceNgx', 'UserServiceNgx', 'theme'];

export function createPublicCalendarController(
  $scope: any,
  $window: angular.IWindowService,
  $document: angular.IDocumentService,
  $mdDialog: angular.material.IDialogService,
  $q: angular.IQService,
  $timeout: angular.ITimeoutService,
  $mdPanel: IPanelServiceEx,
  ApiHandlerService: ApiHandlerService,
  PhoneService: PhoneService,
  SearchBarService: SearchBarService,
  ScreenWidthService: ScreenWidthService,
  MandatorServiceNgx: MandatorService,
  PublicContactsService: PublicContactsService,
  TimelineDatePickerService: TimelineDatePickerService,
  PublicCalendarServiceNgx: PublicCalendarService,
  DialogServiceNgx: DialogService,
  PublicCalendarPrivilegesServiceNgx: PublicCalendarPrivilegesService,
  userServiceNgx: UserService,
  theme: ThemeDto
) {

  const scope = $scope as IPublicCalendarControllerScope;
  scope.calendarsSharableWithMe = [];
  scope.calendarsSharedWithMe = [];
  scope.myCalendarSharedWith = [];

  addDefaultScopeFunctions($scope, theme);
  SearchBarService.RequireSearchBar(true);

  $scope.IsLoading = true;
  $scope.IsAdmin = false;
  scope.Groups = [];
  $scope.Administration = {};
  $scope.InvisibleElements = {};
  $scope.GroupsearchHit = {};
  $scope.SearchText = "";
  $scope.IsAvailableOnly = false;
  $scope.FilterClass = "contact-filter-all";
  $scope.CalculateMarginStyle = function () {
    if ($scope.IsMobile) {
      if (HasScrollbar('#scrollContainer')) {
        return {
          'margin-right': '14px'
        };
      } else {
        return {
          'margin-right': '7px'
        };
      }
    } else {
      return {
        'margin-right': '17px'
      };
    }
  };
  $scope.KeyPress = function (event) {
    var element = $document[0].activeElement;
    if (!(element && element.tagName === "INPUT" || element.tagName === "TEXTAREA")) {
      var time = scope.Timeline.Options.StartTime;
      if (event.key === "ArrowLeft") {
        scope.Timeline.Options.SetStartTime(time.setMinutes(scope.Timeline.Options.StartTime.getMinutes() - 60));
        scope.Timeline.UpdateContactColumnSpan();
        var newTicks = scope.Timeline.GetTicks();
        for (var i = 0; i < newTicks.length; i++) {
          scope.Timeline.CalculatedTicks[i].Label = newTicks[i].Label;
        }
        scope.Timeline.CalculateDays();
        scope.Timeline.DrawHelperLines();
        scope.Timeline.UpdateTimeLabelsPosition();
        scope.Timeline.UpdateCurrentTimeLine();
      }
      if (event.key === "ArrowRight") {
        scope.Timeline.Options.SetStartTime(time.setMinutes(scope.Timeline.Options.StartTime.getMinutes() + 60));
        scope.Timeline.UpdateContactColumnSpan();
        newTicks = scope.Timeline.GetTicks();
        for (i = 0; i < newTicks.length; i++) {
          scope.Timeline.CalculatedTicks[i].Label = newTicks[i].Label;
        }
        scope.Timeline.CalculateDays();
        scope.Timeline.DrawHelperLines();
        scope.Timeline.UpdateTimeLabelsPosition();
        scope.Timeline.UpdateCurrentTimeLine();
      }
      if (event.key === " ") {
        scope.Timeline.CenterCalendar();
      }
    }
  };

  $document.bind('keyup', $scope.KeyPress);

  $scope.IsMobile = function () {
    return ScreenWidthService.IsMobile();
  };

  SearchBarService.SearchTextChangedEvent(function (text) {
    $scope.SearchTextChanged(text);
  });


  $mdPanel.newPanelGroup('contacts', {
    maxOpen: 1
  });
  $mdPanel.newPanelGroup('calendars', {
    maxOpen: 1
  });

  $scope.OpenDatePicker = function ($event) {
    const position = $mdPanel.newPanelPosition().absolute().right().top();
    const config = {
      attachTo: angular.element(document.body),
      templateUrl: '/ClientApp/src/ajs/Views/PublicContacts/CalendarFlyout.htm',
      panelClass: 'contact-panel-container',
      position: position,
      openFrom: $event,
      scope: $scope,
      clickOutsideToClose: true,
      escapeToClose: true,
      focusOnOpen: false,
      zIndex: 50,
      groupName: ['calendars']
    };

    TimelineDatePickerService.datePickerPanel = $mdPanel.open(config);
  };

  PhoneService.ExtensionsChangedEvent(function (extensions) {
    scope.Extensions = extensions;
    $scope.UpdateContactStates();
  });

  scope.Timeline = new Timeline($timeout, TimelineMode.Day, TimelineDatePickerService);

  $scope.SwitchViewMode = function () {
    $scope.IsAdmin = !$scope.IsAdmin;
    if ($scope.IsAdmin) {
      $scope.LoadAdministration();
    } else {
      $window.location.reload();
    }
  };

  $scope.$on('$destroy', function () {
    $document.unbind('keyup', $scope.KeyPress);
    if ($scope.PollTimeout) {
      Timeline.active_timeline = null;
      $timeout.cancel($scope.PollTimeout);
    }
  });

  $scope.LoadData = function () {
    $scope.IsLoading = true;

    PublicCalendarServiceNgx.getCalendarsSharableWithMe().subscribe(data => {
      scope.calendarsSharableWithMe = data;
    });

    PublicCalendarServiceNgx.getCalendarsSharedWithMe().subscribe(data => {
      scope.calendarsSharedWithMe = data;
    });

    PublicCalendarServiceNgx.getMyCalendarSharedWith().subscribe(data => {
      scope.myCalendarSharedWith = data;
    });

    PublicContactsService.getCalendar(MandatorServiceNgx.selectedMandatorId).subscribe(function (data) {
      $scope.Data = data;
      scope.Groups = data.Groups;
      scope.Extensions = data.Extensions;
      scope.MayEdit = data.MayEdit;
      scope.IsLoading = false;
      $scope.Contacts = $scope.AllContacts();
      angular.forEach(scope.Groups, function (group) {
        $scope.InvisibleElements["#" + group.Id] = true;
      });
      angular.forEach($scope.Contacts, function (contact) {
        if (contact.Phone) {
          contact.CleanPhone = contact.Phone.replace(/\s/g, '');
        }
        if (contact.Mobile) {
          contact.CleanMobile = contact.Mobile.replace(/\s/g, '');
        }
      });
      scope.Timeline.Options.MinDate = moment(data.MinDate).toDate();
      scope.Timeline.Options.MaxDate = moment(data.MaxDate).toDate();
      scope.Timeline.InitializeContactDictionary($scope.Contacts);
      $scope.SearchTextChanged('');
      $scope.UpdateContactStates();
      scope.Timeline.CalculatedTicks = scope.Timeline.GetTicks();
      $timeout(() => scope.Timeline.CenterCalendar(), 1000);
      $timeout(() => scope.Timeline.DrawHelperLines(true), 1000);
    });
  };

  $scope.ReloadContacts = function () {
    const deferred = $q.defer();
    PublicContactsService.getCalendar(MandatorServiceNgx.selectedMandatorId).subscribe(function (data) {
      const newContacts = $scope.ExtractContacts(data.Groups);
      angular.forEach($scope.Contacts, function (contact) {
        const newEntry = newContacts.filter(f => f.AccountName === contact.AccountName);
        contact.Appointments = newEntry[0].Appointments;
      });
      scope.Timeline.InitializeContactDictionary($scope.Contacts);
      scope.Timeline.UpdateContactColumnSpan();
      $scope.UpdateContactStates();
      deferred.resolve();
    });
    return deferred.promise;
  };

  /*
   * Stati:
   * 0 grau
   * 1 grün
   * 2 gelb
   * 3 orange
   * 4 rot
   * */

  $scope.UpdateContactStates = function () {
    angular.forEach($scope.Contacts, function (contact) {
      $scope.UpdateStateForContact(contact);
    });
  };

  $scope.UpdateStateForContact = function (contact) {
    var phoneStatus = $scope.UpdatePhoneState(contact);
    var calendarStatus = $scope.UpdateCalendarState(contact);
    var holidayState = $scope.UpdateHolidayState(contact);

    if (calendarStatus.Code > phoneStatus.Code) {
      contact.State = calendarStatus;
    } else {
      contact.State = phoneStatus;
    }
    if (holidayState.Code > contact.State.Code) {
      contact.State = holidayState;
    }

    switch (contact.State.Code) {
      case 1:
        contact.State.Class = "contact-state-free";
        break;
      case 2:
        contact.State.Class = "contact-state-busy";
        break;
      case 3:
        contact.State.Class = "contact-state-do-not-disturb";
        break;
      case 4:
        contact.State.Class = "contact-state-not-available";
        break;
      case 0:
      default:
        contact.State.Class = "contact-state-inactive";
        break;
    }
    contact.IsAvailable = contact.State.Code === 1;
    this.SearchTextChanged(this.SearchText);
  };

  $scope.UpdateHolidayState = function (contact) {
    var now = moment();
    var result = {
      Code: 0,
      Message: undefined
    };
    var appointments = contact.Holidays.filter(f => f.MomentStart <= now && f.MomentEnd >= now);
    if (appointments.length) {
      var printEnd = moment(appointments[0].MomentEnd).subtract(1, 'days');
      result.Code = 4;
      result.Message = appointments[0].Subject;
    }
    return result;
  };

  $scope.UpdateCalendarState = function (contact) {
    var now = moment();
    var result = {
      Code: 0,
      Message: undefined
    };
    var appointments = contact.Appointments.filter(f => f.MomentStart <= now && f.MomentEnd >= now);
    if (appointments.length) {
      result.Code = 2;
      result.Message = "In Termin (" + appointments[0].MomentStart.format("HH:mm") + " - " + appointments[0].MomentEnd.format("HH:mm") + ")";
    }
    return result;
  };

  $scope.UpdatePhoneState = function (contact) {
    var result = {
      Code: 0,
      Message: undefined
    };
    if (scope.Extensions && scope.Extensions.length) {
      var extensionLength = scope.Extensions[0].Extension.length;
      if (contact.Phone) {
        var cleanPhone = contact.Phone.replace(/\s/g, '');
        if (cleanPhone.length >= extensionLength) {
          var extension = cleanPhone.substr(cleanPhone.length - extensionLength);
          var extModel: ExtensionDto | ExtensionDto[] = scope.Extensions.filter(e => e.Extension === extension);
          if (extModel && extModel.length > 0) {
            extModel = extModel[0];
            contact.Extension = extModel;
            if (extModel.IsInCall) {
              result.Code = 2;
              result.Message = "Telefoniert...";
            } else if (extModel.StatusText === "Available") {
              result.Code = 1;
            } else if (extModel.StatusText === "Away") {
              result.Code = 3;
              result.Message = "3CX Status: Abwesend";
            } else if (extModel.StatusText === "Out of office") {
              result.Code = 4;
              result.Message = "3CX Status: Nicht stören";
            } else {
              // TODO: Custom States?
              result.Code = 1;
            }
          }
        }
      }
    }
    return result;
  };

  $scope.SearchKeyPress = function ($event, input) {
    var code = $event.charCode || $event.keyCode;
    if (code === 27) {
      $scope.SearchText = "";
      input.value = "";
      input.SearchText = "";
      $scope.SearchTextChanged($scope.SearchText);
    }
  };

  $scope.LoadAdministration = function () {
    $scope.IsLoading = true;
    ApiHandlerService.SendRequest("PublicContacts", "GetAdministrationContent").then(function (data) {
      $scope.Administration = data;
      $scope.IsLoading = false;
    });
  };

  $scope.AddGroup = function (event) {
    $mdDialog.show({
      targetEvent: event,
      controller: 'PublicContractsGroupDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/PublicContacts/EditGroupDialog.htm',
      locals: {
        selectedGroup: null,
        parentScope: $scope
      },
      parent: angular.element(document.body)
    });
  };

  $scope.RenameGroup = function (event, group) {
    $mdDialog.show({
      targetEvent: event,
      controller: 'PublicContractsGroupDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/PublicContacts/EditGroupDialog.htm',
      locals: {
        selectedGroup: group,
        parentScope: $scope
      },
      parent: angular.element(document.body)
    });
  };

  $scope.ReorderGroups = function () {
    var order = 1;
    angular.forEach($scope.Administration.Groups, function (v) {
      v.SortOrder = order++;
    });
    ApiHandlerService.SendRequest("PublicContacts", "ReorderGroups", { orderedGroups: $scope.Administration.Groups }).then(function (data) { });
  };

  $scope.RemoveGroup = function (group) {
    ApiHandlerService.SendRequest("PublicContacts", "RemoveGroup", { model: group }).then(function (data) {
      $scope.Administration.Groups = $scope.Administration.Groups.filter(f => f !== group);
    });
  };

  $scope.ExtractContacts = function (groups) {
    var result = [];
    angular.forEach(groups, function (group) {
      angular.forEach(group.Contacts, function (contact) {
        result.push(contact);
      });
    });
    return result;
  };

  $scope.AllContacts = function () {
    var result = [];
    angular.forEach(scope.Groups, function (group) {
      angular.forEach(group.Contacts, function (contact) {
        result.push(contact);
      });
    });
    return result;
  };

  $scope.CleanPhoneString = function (phoneString) {
    if (phoneString) {
      return phoneString.replace('+', '00').replace(/\W/g, '');
    }
  };

  $scope.StopPropagation = function (event) {
    event.stopImmediatePropagation();
  };

  $scope.SearchTextChanged = function (SearchText) {

    if ($scope.SearchTimeout) {
      $timeout.cancel($scope.SearchTimeout);
    }

    $scope.SearchTimeout = $timeout(function () {
      $scope.SearchText = SearchText;
      if ($scope.SearchText !== null) {
        angular.forEach($scope.GroupsearchHit, function (hit) {
          hit = false;
        });
        angular.forEach(scope.Groups, function (group) {
          var isGroupIn = false;
          if (group && group.Name && group.Name.toUpperCase && SearchText && SearchText.toUpperCase) {
            if (group.Name.toUpperCase().startsWith(SearchText.toUpperCase())) {
              isGroupIn = true;
            }
          }
          angular.forEach(group.Contacts, function (contact) {
            if (isGroupIn || contact.FirstName.toUpperCase().includes(SearchText.toUpperCase()) || contact.LastName.toUpperCase().includes(SearchText.toUpperCase())) {
              contact.IsHidden = false;
            } else {
              contact.IsHidden = true;
            }
            if ($scope.IsAvailableOnly && !contact.IsAvailable) {
              contact.IsHidden = true;
            }
          });
          $scope.IsGroupVisible(group, SearchText === null || SearchText === '');
        });
        var timeline = scope.Timeline;
        $scope.SearchTimeout = null;
        $timeout(function () { timeline.DrawHelperLines(); }, 50);
      }
    }, 250);
  };

  $scope.IsGroupVisible = function (group, emptySearch) {
    if (group && group.Contacts) {
      group.IsVisible = group.Contacts.filter(f => !f.IsHidden).length > 0;
    } else {
      group.IsVisible = false;
    }
    $scope.GroupsearchHit[group.Id] = group.IsVisible && (!emptySearch || $scope.IsAvailableOnly);
  };

  $scope.IsContactVisible = function (contact) {
    return !contact.IsHidden;
  };

  $scope.ZoomEvent = function (event, delta) {
    if (event.originalEvent.ctrlKey) {
      if (delta > 0) {
        scope.Timeline.ZoomIn(event);
      }
      if (delta < 0) {
        scope.Timeline.ZoomOut(event);
      }
      event.originalEvent.preventDefault();
    }
  };

  $scope.InteractionDown = function (event) {
    $scope.ScrollEvent = {
      CurrentX: event.clientX,
      CurrentY: event.clientY,
      IsScrolling: false
    };
    event.preventDefault();
  };

  $scope.InteractionUp = function (event) {
    if ($scope.ScrollEvent) {
      if ($scope.ScrollEvent.MoveExecuted) {
        $scope.WasScrolling = true;
        $timeout(function () {
          $scope.WasScrolling = false;
        }, 50);
      }
      $scope.ScrollEvent = null;
      $scope.IsMouseDown = false;
      event.preventDefault();
    }
  };

  $scope.InteractionMove = function (event) {
    if ($scope.ScrollEvent) {
      $scope.ScrollEvent.IsScrolling = true;
      var delta = $scope.ScrollEvent.CurrentX - event.clientX;
      var deltaY = $scope.ScrollEvent.CurrentY - event.clientY;
      $scope.ScrollEvent.CurrentY = event.clientY;
      $("#scrollContainer")[0].scrollTop += deltaY;

      var width = $("#tick0").width();
      var minutesPerTick = scope.Timeline.Options.TickMinutes;
      var pixelPerMinute = width / minutesPerTick;
      var deltaMinutes = delta / pixelPerMinute;
      if (Math.abs(deltaMinutes) >= 60) {
        $scope.ScrollEvent.MoveExecuted = true;
        $scope.IsMouseDown = true;
        var time = scope.Timeline.Options.StartTime;
        var minutes = 60;
        if (deltaMinutes < 0) {
          minutes = -60;
        }
        scope.Timeline.Options.SetStartTime(time.setMinutes(scope.Timeline.Options.StartTime.getMinutes() + minutes));

        scope.Timeline.UpdateContactColumnSpan();
        var newTicks = scope.Timeline.GetTicks();
        for (var i = 0; i < newTicks.length; i++) {
          scope.Timeline.CalculatedTicks[i].Label = newTicks[i].Label;
        }
        scope.Timeline.CalculateDays();
        scope.Timeline.DrawHelperLines();
        scope.Timeline.UpdateTimeLabelsPosition();
        scope.Timeline.UpdateCurrentTimeLine();
        $scope.ScrollEvent.CurrentX = event.clientX;
      }
      $scope.ScrollEvent.IsScrolling = false;
    }
    event.preventDefault();
  };

  $scope.Toggle = function (id) {
    if (!$scope.WasScrolling) {
      var timeline = this.Timeline;
      $scope.InvisibleElements[id] = !$scope.InvisibleElements[id];
      $(id).toggle("slow", function () {
        timeline.UpdateCurrentTimeLine();
        timeline.UpdateTimeLabelsPosition();
        timeline.DrawHelperLines();
        $scope.$apply();
      });
    }
  };

  $scope.IsVisible = function (id) {
    return $(id).is(":visible");
  };

  $scope.OpenContactDetails = function ($event, contact) {
    var target = $event.srcElement || $event.target;
    while (!target.className.includes("contact-class-row")) {
      target = target.parentElement;
    }

    var position = $mdPanel.newPanelPosition()
      .relativeTo(target)
      .addPanelPosition(
        $mdPanel.xPosition.OFFSET_END,
        $mdPanel.yPosition.ALIGN_TOPS
      );
    var config = {
      attachTo: angular.element(document.body),
      templateUrl: '/ClientApp/src/ajs/Views/PublicContacts/UserFlyout.htm',
      controller: 'UserFlyoutController',
      locals: {
        Model: contact
      },
      controllerAs: 'vm',
      position: position,
      panelClass: 'contact-panel-container',
      openFrom: $event,
      focusOnOpen: true,
      clickOutsideToClose: true,
      escapeToClose: true,
      zIndex: 4,
      propagateContainerEvents: true,
      groupName: ['contacts']
    };

    $mdPanel.open(config);
  };

  $scope.ChangeFilterMode = function () {
    if ($scope.IsAvailableOnly) {
      $scope.IsAvailableOnly = false;
      $scope.FilterClass = "contact-filter-all";
    } else {
      $scope.IsAvailableOnly = true;
      $scope.FilterClass = "contact-filter-available";
    }
    this.SearchTextChanged(this.SearchText);
  };

  $scope.StartIntervall = function ($scope, $timeout, object) {
    $scope.ReloadContacts().then(function () {
      $scope.PollTimeout = $timeout(function () {
        $scope.StartIntervall($scope, $timeout, object);
      }, 60000);
    });
  };

  // Init controller
  $scope.LoadData();
  var object = createPublicCalendarController;
  $scope.PollTimeout = $timeout(function () {
    $scope.StartIntervall($scope, $timeout, object);
  }, 60000);

  $scope.$on('selectedMandatorChanged', function () {
    $scope.LoadData();
  });

  scope.openContactMenu = function ($mdMenu: angular.material.IMenuService, ev) {
    $mdMenu.open(ev);
  };

  scope.isCalendarSharableWithMe = function (contact: PublicContactDto): boolean {
    const exists = scope.calendarsSharableWithMe.findIndex(x => x.userName === contact.AccountName) >= 0;
    return exists && PublicCalendarPrivilegesServiceNgx.canRequestAccess(MandatorServiceNgx.selectedMandatorId);
  };

  scope.isCalendarSharedWithMe = function (contact: PublicContactDto): boolean {
    return scope.calendarsSharedWithMe.findIndex(x => x.userName === contact.AccountName) >= 0;
  };

  scope.isMyCalendarSharedWith = function (contact: PublicContactDto): boolean {
    return scope.myCalendarSharedWith.findIndex(x => x.userName === contact.AccountName) >= 0;
  };

  scope.isMyself = function (contact: PublicContactDto): boolean {
    return contact.AccountName === userServiceNgx.currentUser?.userName;
  };

  scope.requestSharing = async function (contact: PublicContactDto) {
    if (!await DialogServiceNgx.showConfirmation("Zugriff anfordern",
      `Zugriff auf den Kalender von ${contact.FirstName} ${contact.LastName} anfordern?`, "Anfordern", "Abbrechen")) {
      return;
    }

    firstValueFrom(PublicCalendarServiceNgx.requestSharing(contact.AccountName)).then(_ => {
      DialogServiceNgx.showMessage("Zugriff angefordert",
        `Es wurde Zugriff auf den Kalender von ${contact.FirstName} ${contact.LastName} angefordert.`);
    }).catch(_ => {
      DialogServiceNgx.showMessage("Fehler", `Ein Fehler ist aufgetreten.`);
    });
  };
}

interface IPublicCalendarControllerScope extends angular.IScope {

  calendarsSharableWithMe: UserDto[];
  calendarsSharedWithMe: UserDto[];
  myCalendarSharedWith: UserDto[];
  openContactMenu: ($mdMenu: angular.material.IMenuService, ev: any) => void;
  isCalendarSharableWithMe: (contact: PublicContactDto) => boolean;
  isCalendarSharedWithMe: (contact: PublicContactDto) => boolean;
  isMyCalendarSharedWith: (contact: PublicContactDto) => boolean;
  isMyself: (contact: PublicContactDto) => boolean;
  requestSharing: (contact: PublicContactDto) => void;
  Timeline: Timeline;
  Groups: PublicGroupDto[];
  Extensions: ExtensionDto[];
  MayEdit: boolean;
  IsLoading: boolean;

  //   IsAdmin: boolean;
  //   Groups: any[];
  //   Administration: any;
  //   InvisibleElements: any;
  //   GroupSearchHit: any;
  //   SearchText: string;
  //   IsAvailableOnly: boolean;
  //   FilterClass: string;
  //   CalculateMarginStyle: () => void;
  //   IsMobile: () => boolean;
  //   HasScrollbar: (s: string) => boolean;
  //   KeyPress: (ev: any) => void;
  //   SearchTextChanged: (s: string) => void;
  //   OpenDatePicker: (ev: any) => void;
  //   Extensions: any;
  //   UpdateContactStates: () => void;
  //   SwitchViewMode: () => void;
  //   LoadAdministration: () => void;
  //   PollTimeout: any;
  //   LoadData: () => void;
  //   MayEdit: boolean;
  //   Contacts: any;
  //   AllContacts: () => any;
  //   ReloadContacts: () => angular.IPromise<any>;
  //   ExtractContacts: (x: any) => any;
}
