import { Injectable } from "@angular/core";
import { NativeDateTimeAdapter } from "@danielmoncada/angular-datetime-picker";
import * as dayjs from 'dayjs';

/** Ein DateAdapter, der natives JavaScript Date verwendet, aber für deutsche Eingabeformate.
 *  Wird für OwlDateTime benötigt. */
@Injectable()
export class OwlGermanNativeDateTimeAdapter extends NativeDateTimeAdapter {
  override parse(value: any): Date | null {
    const parsed = dayjs(value,
      [
        'DD.MM.YYYY, HH:mm:ss',
        'DD.MM.YYYY HH:mm:ss',
        'DDMMYYYYHHmmss',
        'DD.MM.YYYY, HH:mm',
        'DD.MM.YYYY HH:mm',
        'DDMMYYYYHHmm',
        'DD.MM.YY HH:mm',
        'DDMMYYHHmm',
        'DD.MM.YYYY',
        'DDMMYYYY',
        'DD.MM.YY',
        'DDMMYY',
        'DD.MM.',
        'DD.MM',
        'DDMM'
      ], 'de', true);

    if (parsed.isValid()) {
      return parsed.toDate();
    }
    else {
      return null;
    }
  }
}
