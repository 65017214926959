import { CallAnalyticsAddressDto, fixCallAnalyticsAddressDto } from "./call-analytics-address-dto";
import { CallAnalyticsExtensionDto } from "./call-analytics-extension-dto";

export interface CallAnalyticsDto {
  Addresses: CallAnalyticsAddressDto[];
  Extensions: CallAnalyticsExtensionDto[];
}


export function fixCallAnalyticsDto(dto: CallAnalyticsDto) {
  if (dto?.Addresses) {
    dto.Addresses.forEach(x => fixCallAnalyticsAddressDto(x));
  }
}
