<div class="filter-section">
  <span>Zeitraum:</span>

  <mat-form-field>
    <mat-label>Von</mat-label>
    <input matInput [matDatepicker]="startDatePicker" [formControl]="startDate">
    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #startDatePicker></mat-datepicker>
    <mat-error>{{ startDate.errors | validationError }}</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Bis</mat-label>
    <input matInput [matDatepicker]="endDatePicker" [formControl]="endDate">
    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #endDatePicker></mat-datepicker>
  </mat-form-field>
</div>

<table mat-table [dataSource]="data" matSort (matSortChange)="sortData($event)" class="dense">
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Monat/Jahr</th>
    <td mat-cell *matCellDef="let row">
      <span>{{row.Date | date: 'MMMM yyyy' }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="callTime">
    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear arrowPosition="before">Anrufzeit</th>
    <td mat-cell *matCellDef="let row">
      <span *ngIf="row.CallTime !== 0">{{row.CallTime | number:'1.2-2'}}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="taskTime">
    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear arrowPosition="before">Taskzeit</th>
    <td mat-cell *matCellDef="let row">
      <span *ngIf="row.TaskTime!== 0">{{row.TaskTime | number:'1.2-2'}}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="totalTime">
    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear arrowPosition="before">Gesamt</th>
    <td mat-cell *matCellDef="let row">
      <span *ngIf="row.TotalTime !== 0">{{row.TotalTime| number:'1.2-2'}}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="chargedTime">
    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear arrowPosition="before">Verechnet</th>
    <td mat-cell *matCellDef="let row">
      <span *ngIf="row.ChargedTime !== 0">{{row.ChargedTime| number:'1.2-2'}}</span>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>

<table mat-table [dataSource]="dataAggregateFunctions" matSort class="dense">
  <ng-container matColumnDef="date">
    <td mat-cell *matCellDef="let row">
      <span></span>
    </td>
  </ng-container>

  <ng-container matColumnDef="callTime">
    <td mat-cell *matCellDef="let row, let index = index">
      <span *ngIf="row.CallTime !== 0"><span *ngIf="index == 0">{{"Ø "}}</span> <span *ngIf="index == 1">{{"Σ "}}</span> {{row.CallTime| number:'1.2-2'}}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="taskTime">
    <td mat-cell *matCellDef="let row, let index = index">
      <span *ngIf="row.TaskTime !== 0"><span *ngIf="index == 0">{{"Ø "}}</span> <span *ngIf="index == 1">{{"Σ "}}</span> {{row.TaskTime| number:'1.2-2'}}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="totalTime">
    <td mat-cell *matCellDef="let row, let index = index">
      <span *ngIf="row.TotalTime !== 0"><span *ngIf="index == 0">{{"Ø "}}</span> <span *ngIf="index == 1">{{"Σ "}}</span> {{row.TotalTime| number:'1.2-2'}}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="chargedTime">
    <td mat-cell *matCellDef="let row, let index = index">
      <span *ngIf="row.ChargedTime !== 0"><span *ngIf="index == 0">{{"Ø "}}</span> <span *ngIf="index == 1">{{"Σ "}}</span> {{row.ChargedTime| number:'1.2-2'}}</span>
    </td>
  </ng-container>

  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
