import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import angular from 'angular';
import { firstValueFrom, interval, Subject, takeUntil } from 'rxjs';
import { ExtensionDto } from 'src/ajs/Models/Phone3cx/ExtensionDto';
import { IPanelServiceEx } from 'src/ajs/Services/IPanelServiceEx';
import { mdPanelAjs } from 'src/app/ajs-upgraded-providers';
import { SignalRService } from 'src/app/core/services/signalr/signalr.service';
import { ConnectionStatus } from 'src/app/phone/constants';
import { Phone3CxCallDto } from 'src/app/phone/dto/phone-3cx-call-dto';
import { PhoneService } from 'src/app/phone/services/phone.service';
import { PhoneSnackbarData } from '../../dto/phone-snackbar-data';


@Component({
  selector: 'app-in-call-snackbar',
  templateUrl: './in-call-snackbar.component.html',
  styleUrls: ['./in-call-snackbar.component.scss']
})
export class InCallSnackbarComponent implements OnInit, OnDestroy {

  private readonly destroy$ = new Subject<void>();
  currentCall: Phone3CxCallDto;
  myExtension: ExtensionDto;
  private snackStartDate: Date;
  snackDuration = 0;
  isInCall = true;

  public readonly Undefined = ConnectionStatus.Undefined;
  public readonly Dialing = ConnectionStatus.Dialing;
  public readonly Ringing = ConnectionStatus.Ringing;
  public readonly Connected = ConnectionStatus.Connected;
  public readonly Hold = ConnectionStatus.Hold;
  public readonly Held = ConnectionStatus.Held;

  constructor(
    private readonly phoneService: PhoneService,
    @Inject(MAT_SNACK_BAR_DATA) private readonly data: PhoneSnackbarData,
    private readonly signalRService: SignalRService,
    private $mdPanel: mdPanelAjs,
  ) {

    this.$mdPanel = $mdPanel as IPanelServiceEx;

    this.myExtension = data.myExtension;

    this.signalRService.extensionsChanged.pipe(
      takeUntil(this.destroy$)
    ).subscribe(this.extensionsChanged.bind(this));
  }

  async ngOnInit() {
    this.snackStartDate = new Date();

    interval(1000).pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.snackDuration = new Date().valueOf() - this.snackStartDate.valueOf();
    });


    await this.loadCurrentCall();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private async extensionsChanged(extensions: ExtensionDto[]) {
    this.myExtension = extensions.find(x => x.Extension === this.myExtension.Extension);

    if (!this.myExtension?.Connection) {
      this.isInCall = false;
    }

    await this.loadCurrentCall();
  }

  private async loadCurrentCall() {
    if (this.myExtension?.Connection?.Chid) {
      this.currentCall = await firstValueFrom(this.phoneService.getCallByChid(this.myExtension.Connection.Chid));
    }
  }

  openCallPanel($event) {
    this.$mdPanel.newPanelGroup('InCallPanel', {
      maxOpen: 1
    });
    const config = {
      attachTo: angular.element(document.body),
      templateUrl: '/ClientApp/src/ajs/Views/Phone3CX/InCallPanel.htm',
      controller: 'Phone3CXInCallPanelController',
      panelClass: 'window-panel-container',
      focusOnOpen: true,
      propagateContainerEvents: true,
      groupName: ['InCallPanel']
    };
    this.$mdPanel.open(config);
  }

}
