import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Sort, SortDirection } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { RemoteStorageService } from 'src/app/core/services/web-storage/remote-storage.service';
import { ColumnDefinition } from '../column-editor/column-definition';
import { COLEDITOR_DEF } from '../column-editor/column-editor.service';


/** Zum Festlegen des Keys im Storage. */
export const SORT_STORAGE_KEY = new InjectionToken<string>('SORT_STORAGE_KEY');

/** Legt die Standardrichtugn für die Sortierung fest. */
export const SORT_DEFAULT_DIRECTION = new InjectionToken<SortDirection>('SORT_DEFAULT_DIRECTION');

/** Legt die Standardspalte für die Sortierung fest. */
export const SORT_DEFAULT_ACTIVE = new InjectionToken<string>('SORT_DEFAULT_ACTIVE');


@Injectable()
export class SortService {

  /** Gibt an, ob die Einstellungen geladen sind. */
  readonly loaded$ = new Subject<void>();

  public direction: SortDirection;
  public active: string;

  constructor(
    private readonly remoteStorage: RemoteStorageService,
    @Inject(SORT_STORAGE_KEY) private readonly storageKey: string,
    @Optional() @Inject(SORT_DEFAULT_DIRECTION) private readonly defaultDirection: SortDirection,
    @Inject(SORT_DEFAULT_ACTIVE) private readonly defaultActive: string,
    @Optional() @Inject(COLEDITOR_DEF) private readonly columnDefinitions: ColumnDefinition[],
  ) {
    this.loadFromStorage();
  }

  change(sort: Sort) {
    this.direction = sort.direction;
    this.active = sort.active;
    this.saveToStorage();
  }

  private async loadFromStorage() {
    this.direction = '';
    this.active = '';

    try {
      const json = await this.remoteStorage.getItem(this.storageKey);
      if (json) {
        const parsed = JSON.parse(json) as StorageModel;
        this.direction = parsed.direction;
        this.active = parsed.active;
      }
    }
    catch (err) {
      console.log(err);
    }

    if (this.direction !== 'asc' && this.direction !== 'desc') {
      this.direction = this.defaultDirection ?? 'asc';
    }

    if (!this.active) {
      this.active = this.defaultActive;
    }

    // Wenn COLEDITOR_DEF injected wurde, können wir überprüfen, ob es sich um eine gültige Spalte
    // handelt. Wenn es die Spalte nicht (mehr) gibt, ausleeren.
    if (this.columnDefinitions?.length && this.active) {
      if (!this.columnDefinitions.some(x => x.name === this.active)) {

        if (this.active === this.defaultActive) {
          console.log('Invalid SORT_DEFAULT_ACTIVE');
        }

        this.active = '';
      }
    }

    this.loaded$.next();
    this.loaded$.complete();
  }

  private async saveToStorage() {
    const model: StorageModel = { direction: this.direction, active: this.active };
    await this.remoteStorage.setItem(this.storageKey, JSON.stringify(model));
  }
}


interface StorageModel {
  direction: SortDirection;
  active: string;
}
