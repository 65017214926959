import * as angular from "angular";

export class NgEnterDirective implements angular.IDirective {

  link = (scope: angular.IScope, element: angular.IAugmentedJQuery, attrs: angular.IAttributes) => {

    element.bind("keydown keypress", function (event) {
      if (event.which === 13) {
        scope.$apply(function () {
          scope.$eval(attrs.ngEnter, { 'event': event });
        });

        event.preventDefault();
      }
    });
  };

  static factory(): angular.IDirectiveFactory {
    const directive = () => new NgEnterDirective();
    directive.$inject = [];
    return directive;
  }
}
