import moment from "moment";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { MandatorService } from "src/app/core/services/mandator.service";

createInformationHubTemplateController.$inject = ['$scope', '$rootScope', '$location', '$timeout', 'ApiHandlerService', 'LocalizationService', 'theme', 'MandatorServiceNgx'];

export function createInformationHubTemplateController(
  $scope,
  $rootScope,
  $location,
  $timeout,
  ApiHandlerService,
  LocalizationService,
  theme: ThemeDto,
  MandatorServiceNgx: MandatorService

) {

  addDefaultScopeFunctions($scope, theme);
  $scope.IsLoading = true;
  $scope.Localization = LocalizationService.GetPluginDict("InformationHub");

  $scope.LoadData = function () {
    ApiHandlerService.SendRequest("InformationHub", "GetInformationHubTemplate", { mandatorId: MandatorServiceNgx.selectedMandatorId }).then(function (data) {
      $scope.Model = data;
      $scope.Model.Entries.forEach(e => {
        e.Created = moment(e.Created);
        e.CreatedFormatted = e.Created.format("DD.MM. HH:mm");
      });
      $scope.IsLoading = false;
    });
  };

  $scope.OpenDocument = function (entry) {
    $location.path('/InformationHub');
    $rootScope.$broadcast("setInformationHubArticleId", entry.Id);
    $timeout(function () {
      $rootScope.$broadcast("setInformationHubArticleId", entry.Id);
    }, true);
    $timeout(function () {
      $rootScope.$broadcast("setInformationHubArticleId", entry.Id);
    }, 10);
    $timeout(function () {
      $rootScope.$broadcast("setInformationHubArticleId", entry.Id);
    }, 20);
    $timeout(function () {
      $rootScope.$broadcast("setInformationHubArticleId", entry.Id);
    }, 30);
    $timeout(function () {
      $rootScope.$broadcast("setInformationHubArticleId", entry.Id);
    }, 40);
    $timeout(function () {
      $rootScope.$broadcast("setInformationHubArticleId", entry.Id);
    }, 50);
    $timeout(function () {
      $rootScope.$broadcast("setInformationHubArticleId", entry.Id);
    }, 60);
    $timeout(function () {
      $rootScope.$broadcast("setInformationHubArticleId", entry.Id);
    }, 70);
    $timeout(function () {
      $rootScope.$broadcast("setInformationHubArticleId", entry.Id);
    }, 80);
    $timeout(function () {
      $rootScope.$broadcast("setInformationHubArticleId", entry.Id);
    }, 90);
    $timeout(function () {
      $rootScope.$broadcast("setInformationHubArticleId", entry.Id);
    }, 100);
  };

  $scope.LoadData();
}
