import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, tap } from 'rxjs';
import { CallEntryDto, finalizeCallEntryDto } from 'src/ajs/Models/Phone3cx/CallEntryDto';
import { GetCallsRequestDto } from 'src/ajs/Models/Phone3cx/GetCallsRequestDto';
import { ApiHandlerService } from 'src/ajs/Services/ApiHandlerService';
import { ContactDto, fixContactDto } from 'src/app/contacts/dto/contact-dto';
import { Api1Service } from 'src/app/core/services/api1.service';
import { PhoneContactDialogComponent } from '../components/phone-contact-dialog/phone-contact-dialog.component';
import { CallAnalyticsDto, fixCallAnalyticsDto } from '../dto/call-analytics-dto';
import { CallDetailsSaveRequestDto } from '../dto/call-details-save-request-dto';
import { GetCallsWithNumberRequestDto } from '../dto/get-calls-with-number-request-dto';
import { GetPhoneListRequestDto } from '../dto/get-phone-list-request-dto';
import { fixGetPhoneListResultDto, GetPhoneListResultDto } from '../dto/get-phone-list-result-dto';
import { fixPhone3CxCallDto, Phone3CxCallDto } from '../dto/phone-3cx-call-dto';
import { GetCallsByIdRequestDto } from '../dto/get-calls-by-id-request-dto';

const controller = "Phone3cx";

@Injectable({
  providedIn: 'root'
})
export class PhoneService {

  constructor(
    private readonly api: Api1Service,
    private readonly dialog: MatDialog,
    private readonly ApiHandlerService: ApiHandlerService
  ) { }

  showPhoneContactDialog(contactId?: number, contactDto?: ContactDto) {
    this.dialog.open<PhoneContactDialogComponent>(PhoneContactDialogComponent, {
      data: {
        contactId: contactId,
        contactDto: contactDto,
      },
      //width: '550px',
      backdropClass: 'business-card-backdrop',
    });
  }

  getCallAnalytics(date: Date): Observable<CallAnalyticsDto> {
    return this.api.post<CallAnalyticsDto>(controller, "GetCallAnalytics", null, { date: date }).pipe(
      tap(dto => fixCallAnalyticsDto(dto)));
  }

  getPhoneList(orderBy: string, descending: boolean, pageSize: number, pageIndex: number, search: string, statusFilterAngenommen: boolean, statusFilterVerpasst: boolean, statusFilterEingehend: boolean, statusFilterAusgehend: boolean, statusFilterIntern: boolean, dateFilter: string, addressId?: number): Observable<GetPhoneListResultDto> {

    const request: GetPhoneListRequestDto = {
      OrderBy: orderBy,
      Descending: descending,
      PageSize: pageSize,
      PageIndex: pageIndex,
      Search: search,

      StatusFilterAngenommen: statusFilterAngenommen,
      StatusFilterVerpasst: statusFilterVerpasst,
      StatusFilterEingehend: statusFilterEingehend,
      StatusFilterAusgehend: statusFilterAusgehend,
      StatusFilterIntern: statusFilterIntern,
      DateFilter: dateFilter,

      AddressId: addressId
    };

    return this.api.post<GetPhoneListResultDto>(controller, "GetPhoneListResult", null, request).pipe(tap(x => fixGetPhoneListResultDto(x)));
  }

  removeTaskFromCalls(callIds: number[]) {
    const request: GetCallsRequestDto = { CallIds: callIds };

    return this.api.post<void>(controller, "RemoveTaskFromCalls", null, request);
  }

  getCallByChidForInCallPanel(chid: string): Observable<CallEntryDto> {
    return this.api.get<CallEntryDto>(controller, "GetCallByChidForInCallPanel", { chid: chid });
  }

  getPhoneContactDialogData(contactId: number) {
    return this.api.get<ContactDto>(controller, "GetPhoneContactDialogData", { contactId: contactId }).pipe(tap(x => fixContactDto(x)));
  }

  getCall(id: number) {
    return this.api.get<Phone3CxCallDto>(controller, "GetCall", { id: id }).pipe(tap(x => fixPhone3CxCallDto(x)));
  }

  getCallsWithNumber(phoneNumber: string, amount: number) {
    const request: GetCallsWithNumberRequestDto = {
      PhoneNumber: phoneNumber,
      Amount: amount,
    };
    return this.api.post<Phone3CxCallDto[]>(controller, "GetCallsWithNumber", null, request).pipe(tap(x => x.forEach(fixPhone3CxCallDto)));
  }

  saveCallDetails(call: Phone3CxCallDto) {

    const request: CallDetailsSaveRequestDto = {
      CallId: call.Id,
      Note: call.Note,
      AddressId: call.OtherPartyMainContact?.ErpCachedAddressId
    };

    return this.api.post<void>(controller, "SaveCallDetails", null, request);

  }

  getCallsByIdLegacy(callIds: number[]) {

    const request: GetCallsByIdRequestDto =
    {
      CallIds: callIds
    };

    return this.api.post<CallEntryDto[]>(controller, "GetCallsByIdLegacy", null, request).pipe(
      tap(calls => {
        if (calls) {
          for (const call of calls) {
            finalizeCallEntryDto(call);
          }
        }
      })
    );
  }

  getCallsById(callIds: number[]) {

    const request: GetCallsByIdRequestDto =
    {
      CallIds: callIds
    };

    return this.api.post<Phone3CxCallDto[]>(controller, "GetCallsById", null, request).pipe(tap(x => x.forEach(fixPhone3CxCallDto)));
  }

  getCallByChid(chid: string) {
    return this.api.get<Phone3CxCallDto>(controller, "GetCallByChid", { chid: chid }).pipe(tap(x => fixPhone3CxCallDto(x)));
  }

}
