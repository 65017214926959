import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-nodata-indicator',
  templateUrl: './table-nodata-indicator.component.html',
  styleUrls: ['./table-nodata-indicator.component.scss']
})
export class TableNodataIndicatorComponent {

  @Input() isLoading = false;
  @Input() noData = false;

  constructor() { }
}
