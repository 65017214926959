import { fixTasksReceiptListDto, TasksReceiptListDto } from "./tasks-receipt-list-dto";

export interface GetTaskReceiptListResultDto {
  TotalCount: number;
  TasksReceiptList: TasksReceiptListDto[];
}

export function fixGetTaskReceiptListResultDto(dto: GetTaskReceiptListResultDto) {
  if (dto?.TasksReceiptList) {
    for (const receipt of dto.TasksReceiptList) {
      fixTasksReceiptListDto(receipt);
    }
  }
}
