import { IScope, material } from "angular";
import { CustomColumnDto } from "src/ajs/Models/Document/CustomColumnDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { MandatorService } from "src/app/core/services/mandator.service";

export class EditCustomColumnDialogController {
  static $inject = ['$scope', '$mdDialog', 'ApiHandlerService', 'Element', 'OnSuccess', 'MandatorServiceNgx'];

  constructor(
    private readonly $scope: ICreateEditCustomColumnDialogControllerScope,
    private readonly $mdDialog: material.IDialogService,
    private readonly ApiHandlerService: ApiHandlerService,
    Element: CustomColumnDto,
    OnSuccess: (result: CustomColumnDto) => void,
    private readonly mandatorService: MandatorService
  ) {
    $scope.Title = "Benutzerdefiniertes Feld";
    $scope.Model = Element;
    if (!$scope.Model) {
      $scope.Title = "Benutzerdefiniertes Feld anlegen";
      $scope.Model = {
        Description: null,
        SortOrder: 0
      } as CustomColumnDto;
    }
    $scope.OnSuccess = OnSuccess;

    $scope.Close = this.Close.bind(this);
    $scope.Submit = this.Submit.bind(this);
  }

  Close() {
    this.$mdDialog.hide();
  }

  Submit() {
    if (!this.$scope.IsSubmiting) {
      this.$scope.IsSubmiting = true;
      this.ApiHandlerService.SendRequest("Document", "SaveCustomColumn", { model: this.$scope.Model, mandatorId: this.mandatorService.selectedMandatorId }).then((result: CustomColumnDto) => {
        this.$scope.OnSuccess(result);
        this.$mdDialog.cancel();
      });
    }
  }
}

interface ICreateEditCustomColumnDialogControllerScope extends IScope {
  Title: string;
  Model: CustomColumnDto,
  SortOrder: number;
  OnSuccess: (result: CustomColumnDto) => void;
  IsSubmiting: boolean;

  Close: () => void;
  Submit: () => void;
}
