import * as  angular from 'angular';
import * as MessagePack from '@msgpack/msgpack';
import moment, { Moment } from 'moment';
import { ResponseState } from '../Models/ResponseState';
import { ErrorResultDto, ResultDto } from '../Models/ResultDto';

function convertUTCDateToLocalDate(date: Date): Date {
  const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();
  let newHour = hours + offset;
  if (newHour < 0) {
    newHour = 24 + newHour;
  }
  newDate.setHours(newHour);

  return newDate;
}

export class ApiHandlerService {

  static $inject = ['$q', '$http', '$location', '$mdDialog', 'BASE_URL_API1'];

  LastError: ErrorResultDto;

  constructor(
    private readonly $q: angular.IQService,
    private readonly $http: angular.IHttpService,
    private readonly $location: angular.ILocationService,
    private readonly $mdDialog: angular.material.IDialogService,
    private readonly BASE_URL_API1: string) {
  }

  BytesToTimestamp(byteArray: number[]): number {
    let value = 0;
    for (let i = byteArray.length - 1; i >= 0; i--) {
      value = value * 256 + byteArray[i];
    }
    return value;
  }

  DateUnpacker(buffer: number[]): Moment {
    const timestamp = this.BytesToTimestamp(buffer);
    return moment(timestamp);
  }

  SendRequest(controller: string, action: string, data?: any, noRedirect?: boolean, keepOriginalDate?: boolean) {
    const deferred = this.$q.defer<any>();

    this.$http<any>({
      url: this.BASE_URL_API1 + controller + "/" + action,
      method: 'POST',
      data: data,
      responseType: 'arraybuffer',

      transformResponse: function (data: any, headers: angular.IHttpHeadersGetter, status: number) {
        const currentheaders = headers();
        if (currentheaders['content-type'] && (currentheaders['content-type'].includes("json") || currentheaders['content-type'].includes("text"))) {
          const decodedString = new TextDecoder("utf-8").decode(new Uint8Array(data));
          return JSON.parse(decodedString);
        }
        else {
          if (!keepOriginalDate) {
            const extensionCodec = new MessagePack.ExtensionCodec();
            extensionCodec.register({
              type: MessagePack.EXT_TIMESTAMP,
              decode: (data) => {
                const timeSpec = MessagePack.decodeTimestampToTimeSpec(data);
                const date = new Date(timeSpec.sec * 1e3 + timeSpec.nsec / 1e6);
                const result = convertUTCDateToLocalDate(date);
                return result;
              },
              encode: undefined
            });
            data = MessagePack.decode(data, { extensionCodec });
            //data.data.Data = MessagePack.decode(data.data.Data);
            return data;
          }
          else {
            return MessagePack.decode(data);
          }
        }
      }
    }).then((data) => {
      const result = data.data as ResultDto;

      // TODO: Was wenn ein Servererror durch den Request passiert (data.data ist dann nicht vorhanden)
      switch (result.State) {
        case ResponseState.SUCCESS:
          deferred.resolve(result.Data);
          return;

        case ResponseState.LOGIN_REQUIRED:
          deferred.reject("Login required for Controller: " + controller + "; Action: " + action);
          if (!noRedirect) {
            this.$location.path('/Login');
            this.$mdDialog.hide();
          }
          return;

        case ResponseState.UNAUTHORIZED:
          deferred.reject("Unauthorized Controller: " + controller + "; Action: " + action);
          if (!noRedirect) {
            this.$location.path('/Unauthorized');
            this.$mdDialog.hide();
          }
          return;

        case ResponseState.NOT_FOUND:
          deferred.reject("Not found Controller: " + controller + "; Action: " + action);
          if (!noRedirect) {
            this.$location.path('/NotFound');
            this.$mdDialog.hide();
          }
          return;

        case ResponseState.SERVER_ERROR:
          deferred.reject("Server error Controller: " + controller + "; Action: " + action);
          if (!noRedirect) {
            this.LastError = result;
            this.$location.path('/ServerError');
          }
          this.$mdDialog.hide();
          return;

        case ResponseState.REDIRECT_SUGGESTED:
          deferred.reject("Redirect suggested");
          this.$location.path(data.data.Message);
          this.$mdDialog.hide();
          return;
      }
    });
    return deferred.promise;
  }
}
