<div class="dialog-container">

  <div mat-dialog-title class="dialog-title"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle>

    <div>
      Passwort Generator
    </div>

    <div>

      <button mat-icon-button (click)="toggleHashView()">
        <mat-icon>autorenew</mat-icon>
      </button>

      <button mat-icon-button (click)="toggleSliderView()">
        <mat-icon>view_carousel</mat-icon>
      </button>

      <button mat-icon-button (click)="generatePasswords()">
        <mat-icon>refresh</mat-icon>
      </button>

      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>

  </div>

  <mat-dialog-content class="mat-typography">

    <div class="main-container">

      <div *ngIf="!isInHashView">
        <div *ngIf="!isInSliderView">

          <mat-button-toggle-group class="row-container-wrap" [formControl]="passwordType">
            <mat-button-toggle value="12 GK">12 GK</mat-button-toggle>
            <mat-button-toggle value="12 GKS">12 GKS</mat-button-toggle>
            <mat-button-toggle value="16 GK">16 GK</mat-button-toggle>
            <mat-button-toggle value="16 GKS">16 GKS</mat-button-toggle>
            <mat-button-toggle value="24 GK">24 GK</mat-button-toggle>
            <mat-button-toggle value="24 GKS">24 GKS</mat-button-toggle>
          </mat-button-toggle-group>

        </div>

        <div *ngIf="isInSliderView" class="content-container">

          <div class="row-container">
            <div class="bold">
              Länge
            </div>

            <mat-slider [formControl]="passwordLenght" [value]="passwordLenght.value" class="slider" min="8" step="2" max="40">Länge</mat-slider>

            <mat-form-field class="slider-input">
              <input matInput [formControl]="passwordLenght" [value]="passwordLenght.value" type="number" (wheel)="$event.preventDefault()" min="8" step="2" max="40">
            </mat-form-field>

            <mat-checkbox [formControl]="useUppercaseLetters" class="slider-checkbox">GK</mat-checkbox>

          </div>

          <div class="row-container">
            <div class="bold">
              Anzahl
            </div>

            <mat-slider [formControl]="passwordAmount" [value]="passwordAmount.value" class="slider" min="1" step="1" max="30">Anzahl</mat-slider>

            <mat-form-field class="slider-input">
              <input matInput [formControl]="passwordAmount" [value]="passwordAmount.value" type="number" (wheel)="$event.preventDefault()" min="1" step="1" max="30">
            </mat-form-field>

            <mat-checkbox [formControl]="useSpecialLetters" class="slider-checkbox">S</mat-checkbox>

          </div>

        </div>

        <mat-divider *ngIf="isInSliderView"></mat-divider>

        <div class="password-container">
          <div *ngFor="let password of passwords; let index = index" class="row-container">
            <div class="row-container">
              <div class="password-index">
                {{index + 1}}
              </div>
              <div>
                {{password}}
              </div>

            </div>

            <div>
              <button mat-icon-button (click)="copyPassword(index, password)">
                <mat-icon color="primary" [ngClass]="{'copy-icon-selected': selectedCopyIndex === index}"> file_copy</mat-icon>
              </button>
            </div>

          </div>
        </div>
      </div>

      <div *ngIf="isInHashView" class="content-container">

        <mat-form-field>
          <mat-label>Hashing-Algorithmus</mat-label>
          <mat-select [formControl]=" hashAlogrithm">
            <mat-option value="HMAC-SHA-1">HMAC-SHA-1</mat-option>
            <mat-option value="SHA256">SHA256</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label *ngIf="hashAlogrithm.value === 'HMAC-SHA-1'">Wert (Hex)</mat-label>
          <mat-label *ngIf="hashAlogrithm.value === 'SHA256'">Wert</mat-label>
          <input matInput [formControl]="hashValue">
        </mat-form-field>

        <mat-form-field *ngIf="hashAlogrithm.value === 'HMAC-SHA-1'">
          <mat-label>Schlüssel (Hex)</mat-label>
          <input matInput [formControl]="hashKey">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Hash</mat-label>
          <input matInput [ngClass]="{'small-hash': hashAlogrithm.value === 'SHA256'}" [formControl]="hashResult" readonly>
        </mat-form-field>

      </div>

    </div>

  </mat-dialog-content>


</div>