import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MomentModule } from 'ngx-moment';
import { BusinessCardDialogComponent } from './components/business-card-dialog/business-card-dialog.component';
import { CellLinkComponent } from './components/cell-link/cell-link.component';
import { ColorPaletteComponent } from './components/color-palette/color-palette.component';
import { ColumnEditorDialogComponent } from './components/column-editor-dialog/column-editor-dialog.component';
import { DetailbarActionsDirective } from './components/detailbar/detailbar-actions.directive';
import { DetailbarSubtitleDirective } from './components/detailbar/detailbar-subtitle-directive.ts.directive';
import { DetailbarContentDirective } from './components/detailbar/detailbar-title-actions.directive';
import { DetailbarTitleDirective } from './components/detailbar/detailbar-title.directive';
import { DetailbarComponent } from './components/detailbar/detailbar.component';
import { JournalEntryValueComponent } from './components/journal/journal-entry-value.component';
import { JournalEntryComponent } from './components/journal/journal-entry.component';
import { JournalComponent } from './components/journal/journal.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { MdiIconComponent } from './components/mdi-icon/mdi-icon.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { SearchbarButtonsDirective } from './components/searchbar/searchbar-buttons.directive';
import { SearchbarComponent } from './components/searchbar/searchbar.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { TableNodataIndicatorComponent } from './components/table-nodata-indicator/table-nodata-indicator.component';
import { TagEditDialogComponent } from './components/tag-edit-dialog/tag-edit-dialog.component';
import { UserCellComponent } from './components/user-cell/user-cell.component';
import { FileDragOverDirective } from './directives/file-drag-over.directive';
import { QuillEmoticonDirective } from './directives/quill-emoticon.directive';
import { DurationPipe } from './pipes/duration.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { TelPipe } from './pipes/tel.pipe';
import { ValidationErrorPipe } from './pipes/validation-error.pipe';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSliderModule } from '@angular/material/slider';

/**
 * Was kommt ins SharedModule?
 * - Pipes, Directives, Components, die häufig in Featuremodulen gebraucht werden, aber nicht essentiell sind
 *
 * Was kommt ins CoreModule?
 * - Components, die unbedingt für die Anwendung gebraucht werden, z.B. Navigation
 * - Services, die in vielen Featuremodulen benötigt werden, z.B. Core, User, Authentication, File
 *
 * https://levelup.gitconnected.com/where-shall-i-put-that-core-vs-shared-module-in-angular-5fdad16fcecc
 */
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    HttpClientJsonpModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MomentModule,
    MatDividerModule,
    MatMenuModule,
    MatCheckboxModule,
    DragDropModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatSidenavModule,
    ClipboardModule,
    MatGridListModule,
    MatSliderModule
  ],
  declarations: [
    ValidationErrorPipe,
    FileSizePipe,
    ReplacePipe,
    DurationPipe,
    TelPipe,
    SearchbarComponent,
    SearchbarButtonsDirective,
    DetailbarComponent,
    DetailbarTitleDirective,
    DetailbarSubtitleDirective,
    DetailbarContentDirective,
    DetailbarActionsDirective,
    BusinessCardDialogComponent,
    UserCellComponent,
    LoadingOverlayComponent,
    MessageDialogComponent,
    FileDragOverDirective,
    StarRatingComponent,
    JournalComponent,
    JournalEntryComponent,
    JournalEntryValueComponent,
    ColorPaletteComponent,
    ColumnEditorDialogComponent,
    CellLinkComponent,
    QuillEmoticonDirective,
    TableNodataIndicatorComponent,
    MdiIconComponent,
    TagEditDialogComponent
  ],
  exports: [
    RouterModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatAutocompleteModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxMatSelectSearchModule,
    ValidationErrorPipe,
    FileSizePipe,
    ReplacePipe,
    DurationPipe,
    TelPipe,
    SearchbarComponent,
    SearchbarButtonsDirective,
    DetailbarComponent,
    DetailbarTitleDirective,
    DetailbarSubtitleDirective,
    DetailbarContentDirective,
    DetailbarActionsDirective,
    BusinessCardDialogComponent,
    UserCellComponent,
    LoadingOverlayComponent,
    MomentModule, // für amTimeAgo
    FileDragOverDirective,
    StarRatingComponent,
    JournalComponent,
    JournalEntryComponent,
    JournalEntryValueComponent,
    OwlDateTimeModule,
    ColorPaletteComponent,
    CellLinkComponent,
    QuillEmoticonDirective,
    TableNodataIndicatorComponent,
    MdiIconComponent,
    MatSidenavModule,
    ClipboardModule,
    TagEditDialogComponent,
    MatGridListModule,
    DragDropModule,
    MatSliderModule
  ]
})
export class SharedModule { }
