import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Api2Service } from 'src/app/core/services/api2.service';
import { SageExportContractQueueDialogComponent } from '../components/sage-export-contract-queue-dialog/sage-export-contract-queue-dialog.component';
import { Observable, firstValueFrom, tap } from 'rxjs';
import { SageExportContractQueueDto, fixSageExportContractQueueDto } from '../dto/sage-export-contract-queue-dto';
import { SageExportHistoryResultDto } from '../dto/sage-export-history-result-dto';
import { SageExportContractErrorDialogComponent } from '../components/sage-export-contract-error-dialog/sage-export-contract-error-dialog.component';
import { SageExportContractGroupErrorDto } from '../dto/sage-export-contract-group-error-dto';
import { SageExportContractDto } from '../dto/sage-export-contract-dto';
import { SageExportContractTypeDto } from '../dto/sage-export-contract-type-dto';
import { SageExportContractGroupDto } from '../dto/sage-export-contract-group-dto';
import { AddToSageExportQueueRequest } from '../dto/add-to-sage-export-queue-request';
import { UpdateContractTypesRequest } from '../dto/update-contract-types-request';

const controller = "SageExport";

@Injectable({
  providedIn: 'root'
})
export class SageExportService {

  constructor(
    private readonly api2: Api2Service,
    private readonly dialog: MatDialog,
  ) { }

  exportContractsToSage(mandatorId: string) {
    return this.api2.post(controller, "ExportContractsToSage", { mandatorId: mandatorId });
  }

  addToSageExportQueue(contractIds: number[], mandatorId: string) {

    const request: AddToSageExportQueueRequest = {
      contractIds: contractIds,
      mandatorId: mandatorId
    };

    return this.api2.post(controller, "AddToSageExportQueue", null, request);
  }

  getSageExportContractQueue(mandatorId: string): Observable<SageExportContractQueueDto> {
    return this.api2.get<SageExportContractQueueDto>(controller, "GetSageExportContractQueue", { mandatorId: mandatorId }).pipe(
      tap(fixSageExportContractQueueDto)
    );
  }

  getSageExportQueueHistory(mandatorId: string): Observable<SageExportHistoryResultDto> {
    return this.api2.get<SageExportHistoryResultDto>(controller, "GetSageExportQueueHistory", { mandatorId: mandatorId }).pipe(
      tap(SageExportHistoryResultDto)
    );
  }

  deleteSageExportContract(exportContractId: number) {
    return this.api2.get(controller, "DeleteSageExportContract", { exportContractId: exportContractId });
  }

  getImportErrors(queueId: number, mandatorId: string): Observable<SageExportContractGroupErrorDto[]> {
    return this.api2.get<SageExportContractGroupErrorDto[]>(controller, "GetImportErrors", { queueId: queueId, mandatorId: mandatorId });
  }

  deleteEPCBeleg(errorDto: SageExportContractGroupErrorDto, mandatorId: string) {
    return this.api2.get(controller, "DeleteEPCBeleg", { epcBelegId: errorDto.belegID, sageExportGroupId: errorDto.sageExportContractGroupId, mandatorId: mandatorId });
  }

  updateContractDates(sageExportContracts: SageExportContractDto[]) {
    return this.api2.post(controller, "UpdateContractDates", null, sageExportContracts);
  }

  getSageExportContractTypes(): Observable<SageExportContractTypeDto[]> {
    return this.api2.get(controller, "GetSageExportContractTypes");
  }

  updateContractTypes(groups: SageExportContractGroupDto[], mandatorId: string) {
    const request: UpdateContractTypesRequest = {
      groupDtos: groups,
      mandatorId: mandatorId
    };

    return this.api2.post(controller, "UpdateContractTypes", null, request);
  }

  completeSageExportContractGroup(groupId: number) {
    return this.api2.post(controller, "CompleteSageExportContractGroup", { groupId: groupId });
  }

  deleteSageExportContractProduct(productId: number) {
    return this.api2.post(controller, "DeleteSageExportContractProduct", { productId: productId });
  }

  archiveSageExportContractGroup(groupId: number) {
    return this.api2.post(controller, "ArchiveSageExportContractGroup", { groupId: groupId });
  }

  async showContractSageExportQueueDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.hasBackdrop = false;
    dialogConfig.data = {
    };
    dialogConfig.panelClass = "full-dialog";

    const dialog = this.dialog.open<SageExportContractQueueDialogComponent>(SageExportContractQueueDialogComponent, dialogConfig);

    const ok = await firstValueFrom(dialog.afterClosed());
    return ok;
  }

  async showSageExportContractErrorDialog(queueId: number) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.hasBackdrop = false;
    dialogConfig.data = {
      queueId: queueId
    };
    dialogConfig.panelClass = "full-dialog";

    const dialog = this.dialog.open<SageExportContractErrorDialogComponent>(SageExportContractErrorDialogComponent, dialogConfig);

    const ok = await firstValueFrom(dialog.afterClosed());
    return ok;
  }


}
