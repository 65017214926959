<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button mat-button [matMenuTriggerFor]="dateMenu">
      {{ selectedMonth | date:'MMM YYYY' }}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #dateMenu="matMenu">
      <button mat-menu-item *ngFor="let month of months" (click)="selectMonth(month)">{{ month | date:'MMM YYYY' }}</button>
    </mat-menu>

    <button mat-button class="ellipsis" [matMenuTriggerFor]="contactMenu" *ngIf="selectedContact">
      {{ selectedContact.contact.LastName }}, {{ selectedContact.contact.FirstName }}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #contactMenu="matMenu">
      <button mat-menu-item *ngFor="let contact of contacts" (click)="selectContact(contact)">
        <span>{{ contact.contact.LastName }}, {{ contact.contact.FirstName }}</span>
      </button>

    </mat-menu>

    <span fxFlex></span>

    <button mat-icon-button [matMenuTriggerFor]="moreMenu"
      *ngIf="(selectedContact?.isMyself && myCalendarSharedWith.length) || (selectedContact?.isCalendarSharableWithMe && !selectedContact?.isCalendarSharedWithMe)">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #moreMenu="matMenu">
      <button mat-menu-item *ngIf="selectedContact?.isCalendarSharableWithMe" (click)="requestSharing(selectedContact)">
        <mat-icon>visibility</mat-icon> Kalenderzugriff anfordern
      </button>

    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>

<div class="days" fxFlex>
  <div fxLayout="row" class="day" *ngFor="let day of days">
    <div class="day-header">
      <div class="num">{{ day.date | date:'d' }}</div>
      <div class="weekday">{{ day.date | date:'EE' | replace:'.':'' }}</div>
    </div>
    <div fxFlex class="day-events">
      <div class="no-events" *ngIf="!day.events.length">Keine Termine.</div>
      <div class="event" [class]="publicCalendarService.getAppointmentTypeClass(event)" *ngFor="let event of day.events">
        <div class="subject">{{ event.Subject }}</div>
        <div class="date">{{ getEventTimeAndLocation(event) }}</div>
      </div>
    </div>
  </div>
</div>

<app-loading-overlay [isLoading]="isLoading"></app-loading-overlay>
