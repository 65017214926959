import { ThemeDto } from "../Models/ThemeDto";

/** Ersetzt hestia-common.js addDefaultScopeFunctions */
export function addDefaultScopeFunctions($scope: any, theme: ThemeDto) {
  $scope.Theme = {
    Name: theme?.ThemeDescription,
    Definition: theme
  };
  $scope.HestiaVisibleElements = {};

  $scope.Toggle = function (id: string, id2: string | null) {
    let speed: JQuery.Duration = "slow";
    if ($scope.ToggleSpeed) {
      speed = $scope.ToggleSpeed;
    }
    const visible1 = $scope.IsVisible(id);
    const visible2 = id2 ? $scope.IsVisible(id2) : undefined;

    $(id).toggle(speed, function () {
      $scope.HestiaVisibleElements[id] = !visible1;
      $scope.$apply();
    });
    if (id2) {
      $(id2).toggle(speed, function () {
        $scope.HestiaVisibleElements[id2] = !visible2;
        $scope.$apply();
      });
    }
  };


  $scope.IsVisible = function (id: string, dVisible: boolean) {
    if ($scope.HestiaVisibleElements[id] === undefined) {
      if (dVisible !== undefined) {
        $scope.HestiaVisibleElements[id] = dVisible;
      }
      $scope.HestiaVisibleElements[id] = $(id).is(":visible");
    }
    return $scope.HestiaVisibleElements[id];
  };
}


