import angular from "angular";
import moment from "moment";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { GetPartSearchResultDto } from "src/ajs/Models/Systembuilder/GetPartSearchResultDto";
import { SystemPartDto } from "src/ajs/Models/Systembuilder/SystemPartDto";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { StorageService, StorageServiceInstance } from "src/ajs/Services/StorageService";
import { MandatorService } from "src/app/core/services/mandator.service";

createOpenPartSearchDialogController.$inject = ['$scope', '$mdDialog', 'StorageService', 'ApiHandlerService', 'OnSuccess', 'theme', 'MandatorServiceNgx'];

export function createOpenPartSearchDialogController(
  $scope: OpenPartSearchDialogControllerScope,
  $mdDialog: angular.material.IDialogService,
  StorageService: StorageService,
  ApiHandlerService: ApiHandlerService,
  OnSuccess: (identcode: string) => void,
  theme: ThemeDto,
  mandatorServiceNgx: MandatorService
) {

  addDefaultScopeFunctions($scope, theme);
  $scope.OnSuccess = OnSuccess;
  $scope.Storage = StorageService.CreateInstance("OpenPartSearchDialogController");
  $scope.Autocomplete = $scope.Storage.GetOrCreate("Autocomplete", []);
  setTimeout(function () {
    document.querySelector<HTMLElement>('#autoCompleteId').focus();
  }, 600);
  $scope.QuerySearch = function () {
    return $scope.Autocomplete;
  };

  $scope.ExecuteSearch = function (onlyMatch) {
    if ($scope.SerialNumber) {
      let searchNow = true;
      if (onlyMatch) {
        searchNow = !!$scope.Autocomplete.filter(f => f === $scope.SerialNumber).length;
      }
      if (searchNow) {
        $scope.IsLoading = true;
        ApiHandlerService.SendRequest("Systembuilder", "GetPartSearchResult", { serialNumber: $scope.SerialNumber, mandatorId: mandatorServiceNgx.selectedMandatorId }).then(function (data: GetPartSearchResultDto) {
          if (data.Entries.length) {
            $scope.Autocomplete = $scope.Autocomplete.filter(f => f !== $scope.SerialNumber);
            $scope.Autocomplete.unshift($scope.SerialNumber);
            $scope.Autocomplete = $scope.Autocomplete.slice(0, 15);
            $scope.Storage.Set("Autocomplete", $scope.Autocomplete);
            document.querySelector<HTMLElement>('#autoCompleteId').blur();
          }
          $scope.Entries = data.Entries;
          $scope.Entries.forEach(f => {
            f.InstallDate = moment(f.InstallDate).format("DD.MM.YYYY");
            if (f.UninstallDate) {
              f.UninstallDate = moment(f.UninstallDate).format("DD.MM.YYYY");
            }
          });
          $scope.IsLoading = false;
        });
      }
    }
  };

  $scope.SelectEntry = function (entry) {
    OnSuccess(entry.Identcode);
    $mdDialog.hide();
  };

  $scope.Hide = function () {
    $mdDialog.hide();
  };

  $scope.Cancel = function () {
    $mdDialog.cancel();
  };
}

interface OpenPartSearchDialogControllerScope extends angular.IScope {
  OnSuccess: (identcode: string) => void;
  Storage: StorageServiceInstance;
  Autocomplete: string[];
  QuerySearch: () => string[]
  ExecuteSearch: (onlyMatch: boolean) => void;
  SerialNumber: string;
  IsLoading: boolean;
  Entries: SystemPartDto[];
  SelectEntry: (entry: SystemPartDto) => void;
  Hide: () => void;
  Cancel: () => void;
}
