import * as moment from "moment";
import { ContactsService } from "./ContactsService";
import { DocumentsService } from "./DocumentsService";
import { MonitoringService } from "./MonitoringService";
import { PhoneService } from "./PhoneService";
import { SignalRService } from "./SignalRService";
import { TaskService } from "./TaskService";
import { UserService } from "./UserService";

export class ServiceLocatorService {
  static $inject = ['SignalRService', 'TaskService', 'ContactsService', 'PhoneService', 'UserService', 'MonitoringService', 'DocumentsService'];

  constructor(
    public readonly SignalRService: SignalRService,
    public readonly TaskService: TaskService,
    public readonly ContactsService: ContactsService,
    public readonly PhoneService: PhoneService,
    public readonly UserService: UserService,
    public readonly MonitoringService: MonitoringService,
    public readonly DocumentsService: DocumentsService) {

    MonitoringService.ServiceLocatorService = this;
    ContactsService.ServiceLocator = this;
    DocumentsService.ServiceLocator = this;

    console.log(moment().format("HH:mm:ss") + " Starting all services");
    this.MonitoringService.InitService();
    this.DocumentsService.InitService();

    UserService.Load().then(() => {
      this.UserChanged(true).then(() => {
        SignalRService.InitService().then(() => {
          UserService.RegisterLoadedEvent(this.UserChanged.bind(this));
          SignalRService.StartedEvent(() => {
            this.UserChanged(false);
          });
        });
      });
    });
  }

  async DumpAllData() {
    const start = moment();
    const contactPromise = this.ContactsService.DumpData();
    const documentsPromise = this.DocumentsService.DumpData();
    await Promise.all([contactPromise, documentsPromise]);
    console.log(moment().format("HH:mm:ss") + " DumpAllData finished in " + moment().diff(start) + " ms");
  }

  async LoadAllData() {
    const start = moment();

    // Diagnose:
    // let step = moment();

    // try {
    //   await this.PhoneService.LoadData();
    //   console.log(moment().format("HH:mm:ss") + " PhoneService " + moment().diff(step) + " ms");
    //   step = moment();

    //   await this.ContactsService.LoadData();
    //   console.log(moment().format("HH:mm:ss") + " ContactsService " + moment().diff(step) + " ms");
    //   step = moment();

    //   await this.TaskService.LoadData();
    //   console.log(moment().format("HH:mm:ss") + " TaskService " + moment().diff(step) + " ms");
    //   step = moment();

    //   await this.ContractsService.LoadData();
    //   console.log(moment().format("HH:mm:ss") + " ContractsService " + moment().diff(step) + " ms");
    //   step = moment();

    //   console.log(moment().format("HH:mm:ss") + " LoadAllData finished in " + moment().diff(start) + " ms");
    // } catch (reason) {
    //   console.log("ServiceLocator rejected: " + reason);
    // }


    const phonePromise = this.PhoneService.LoadData();
    const contactPromise = this.ContactsService.LoadData();
    const taskPromise = this.TaskService.LoadData();


    try {
      await Promise.all([phonePromise, contactPromise, taskPromise]);
      console.log(moment().format("HH:mm:ss") + " LoadAllData finished in " + moment().diff(start) + " ms");
    } catch (reason) {
      console.log("ServiceLocator rejected: " + reason);
    }
  }

  FinalizeAllData() {
    const start = moment();
    this.ContactsService.FinalizeData();
    console.log(moment().format("HH:mm:ss") + " FinalizeAllData finished in " + moment().diff(start) + " ms");
  }

  private async UserChanged(initial: boolean): Promise<void> {
    const start = moment();

    if (initial) {
      console.log(moment().format("HH:mm:ss") + " Initial cache load");
    } else {
      console.log(moment().format("HH:mm:ss") + " User has changed");
    }

    await this.DumpAllData();

    if (this.UserService.Data && this.UserService.Data.User) {
      await this.LoadAllData();
      this.FinalizeAllData();
      console.log(moment().format("HH:mm:ss") + " All UserData for " + this.UserService.Data.User.UserName + " loaded in " + moment().diff(start) + " ms");
    }
    else {
      console.log(moment().format("HH:mm:ss") + " User logged out, caches dumped");
    }
  }
}
