import moment from "moment";
import { CallContactDto } from "./CallContactDto";
import { CallPartDto } from "./CallPartDto";

export interface CallEntryDto {
  // -- Eigenschaften aus der API --
  CallId: number;
  OtherPartyNumber: string;
  TaskId: number | null;
  CallDurationMilliseconds: number;
  Parts: CallPartDto[];
  Contacts: CallContactDto[];
  Note: string;

  // -- aus PhoneService.FinalizeCall --
  IsFinalized?: boolean;
  MainContact?: CallContactDto;


  // -- aus PhoneService.UpdateCallTimeStats --
  SpeakingTime?: number;
  IsFinished?: boolean;
  ConnectionTimeFormatted?: string;
  SpeakingTimeFormatted?: string;

  //Phone3CXInCallPanelController
  EditContactMain?: boolean;
}

export function finalizeCallEntryDto(call: CallEntryDto) {
  // TODO: Contacts
  // TODO: MainContact
  call.IsFinalized = true;

  call.SpeakingTime = 0;
  call.IsFinished = true;

  for (const part of call.Parts) {

    part.IsFinished = false;
    if (part.End) {
      part.IsFinished = true;
      part.Duration = moment(part.End).diff(moment(part.Start));
      // part.Type ist anscheinend der ConnectionStatus nicht der Connection Type
      if (part.Type === 3 && !part.IsQueue) {
        call.SpeakingTime += part.Duration;
      }
    }
    else {
      part.IsFinished = false;
      call.IsFinished = false;
      part.Duration = moment().diff(moment(part.Start));
      if (part.Type === 3 && !part.IsQueue) {
        call.SpeakingTime += part.Duration;
      }
    }
  }
  call.ConnectionTimeFormatted = moment.utc(call.CallDurationMilliseconds).format("HH:mm:ss");
  call.SpeakingTimeFormatted = moment.utc(call.SpeakingTime).format("HH:mm:ss");
}
