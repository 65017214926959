import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDto } from 'src/app/core/dto/user-dto';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-business-card-dialog',
  templateUrl: './business-card-dialog.component.html',
  styleUrls: ['./business-card-dialog.component.scss']
})
export class BusinessCardDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly user: UserDto,
    public readonly userService: UserService
  ) { }

  getUserDisplayName(): string {
    if (this.user.firstName || this.user.lastName) {
      return `${this.user.firstName ?? ""} ${this.user.lastName ?? ""}`;
    }
    else if (this.user.displayName) {
      return this.user.displayName;
    }
    else {
      return this.user.userName;
    }
  }
}
