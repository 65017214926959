import * as angular from 'angular';
import * as moment from 'moment';
import { createAddDiskAlertController } from './Controller/AddDiskAlertController';
import { createAdministrationController } from './Controller/Administration/AdministrationController';
import { createEditMandatorGroupController } from './Controller/Administration/EditMandatorGroupController';
import { createEditUserController } from './Controller/Administration/EditUserController';
import { createaXsSystemSearchAdminDialogController } from './Controller/aXsSystemSearch/aXsSystemSearchAdminDialogController';
import { createaXsSystemSearchController } from './Controller/aXsSystemSearch/aXsSystemSearchController';
import { createaXsSystemSearchWidgetController } from './Controller/aXsSystemSearch/aXsSystemSearchWidgetController';
import { createaXsSystemSearchWidgetControllerWithLocals } from './Controller/aXsSystemSearch/aXsSystemSearchWidgetControllerWithLocals';
import { ContactFlyoutController } from './Controller/Contacts/ContactFlyoutController';
import { ContactsAdminDialogController } from './Controller/Contacts/ContactsAdminDialogController';
import { ContactsController } from './Controller/Contacts/ContactsController';
import { ContactsQuickSearchController } from './Controller/Contacts/ContactsQuickSearchController';
import { ContactsUserController } from './Controller/Contacts/ContactsUserController';
import { DashboardController } from './Controller/DashboardController';
import { EditCustomColumnDialogController } from './Controller/Documents/EditCustomColumnDialogController';
import { EditDocumentsCategoryDialogController } from './Controller/Documents/EditDocumentsCategoryDialogController';
import { EditLabelPrintPresetController } from './Controller/Documents/EditLabelPrintPresetController';
import { EditNumberRangeDialogController } from './Controller/Documents/EditNumberRangeDialogController';
import { PrintDocumentLabelDialogController } from './Controller/Documents/PrintDocumentLabelDialogController';
import { PrintLabelViewController } from './Controller/Documents/PrintLabelViewController';
import { createDocumentsCrontroller } from './Controller/DocumentsController';
import { creatEditMandatorPluginsController } from './Controller/EditMandatorPluginsController';
import { createEditTextDialogController } from './Controller/EditTextDialogController';
import { createArticleSearchController } from './Controller/Erp/ArticleSearchController';
import { createErpAdminController } from './Controller/Erp/ErpAdminController';
import { createErpAdminDialgController } from './Controller/Erp/ErpAdminDialogController';
import { createErpContactFlyoutController } from './Controller/Erp/ErpContactFlyoutController';
import { createErpController } from './Controller/Erp/ErpController';
import { createErpSelectorController } from './Controller/Erp/ErpSelectorController';
import { createPrintArticleLabelController } from './Controller/Erp/PrintArticleLabelController';
import { ErrorPageController } from './Controller/ErrorPageController';
import { createAddMailboxDialogController } from './Controller/InformationHub/AddMailboxDialogController';
import { createAddOutlookFolderDialogController } from './Controller/InformationHub/AddOutlookFolderDialogController';
import { createEditHubSourceDialogController } from './Controller/InformationHub/EditHubSourceDialogController';
import { createInformationHubController } from './Controller/InformationHub/InformationHubController';
import { createInformationHubTemplateController } from './Controller/InformationHub/InformationHubTemplateController';
import { createLabelController } from './Controller/Label/LabelController';
import { createLabelCreateEditTemplateController } from './Controller/Label/LabelCreateEditTemplateController';
import { createLabelCreateFormatController } from './Controller/Label/LabelCreateFormatController';
import { createLabelCreatePrinterController } from './Controller/Label/LabelCreatePrinterController';
import { createLabelTestPrintController } from './Controller/Label/LabelTestPrintController';
import { createLabelWidgetController } from './Controller/Label/LabelWidgetController';
import { createMailSendDialogController } from './Controller/MailSendController';
import { MetaDataController } from './Controller/MetaDataController';
import { createMonitoringController } from './Controller/MonitoringController';
import { NavigationController } from './Controller/NavigationController';
import { createPasswordGeneratorController } from './Controller/PasswordGeneratorController';
import { Phone3CXAdminDialogController } from './Controller/Phone3CXAdminDialogController';
import { Phone3CXController } from './Controller/Phone3CXController';
import { createPhone3CXInCallPanelController } from './Controller/Phone3CXInCallPanelController';
import { Phone3CXUserController } from './Controller/Phone3CXUserController';
import { createEditColumnDialogController } from './Controller/PriceList/EditColumnDialogController';
import { createEditDownloadDialogController } from './Controller/PriceList/EditDownloadDialogController';
import { createEditFileDialogController } from './Controller/PriceList/EditFileDialogController';
import { createEditTransformationDialogController } from './Controller/PriceList/EditTransformationDialogController';
import { createEditVendorDialogController } from './Controller/PriceList/EditVendorDialogController';
import { createPriceListController } from './Controller/PriceList/PriceListController';
import { ProfileController } from './Controller/ProfileController';
import { AppointmentsTemplateController } from './Controller/PublicContacts/AppointmentsTemplateController';
import { BirthdayTemplateController } from './Controller/PublicContacts/BirthdayTemplateController';
import { createPublicCalendarController } from './Controller/PublicContacts/PublicCalendarController';
import { PublicContactsAdminDialogController } from './Controller/PublicContacts/PublicContactsAdminDialogController';
import { creatPublicContractsGroupDialogController } from './Controller/PublicContacts/PublicContractsGroupDialogController';
import { createPublicHolidaysController } from './Controller/PublicContacts/PublicHolidaysController';
import { PublicPhonebookController } from './Controller/PublicPhonebook/PublicPhonebookController';
import { SmsSendController } from './Controller/SmsSendController';
import { createSubnetCalculatorController } from './Controller/SubnetCalculatorController';
import { createDeletePartController } from './Controller/Systembuilder/DeletePartController';
import { createMoveSerialDialogController } from './Controller/Systembuilder/MoveSerialDialogController';
import { createOpenIdentcodeDialogController } from './Controller/Systembuilder/OpenIdentcodeDialogController';
import { createOpenPartSearchDialogController } from './Controller/Systembuilder/OpenPartSearchDialogController';
import { createSystembuilderController } from './Controller/Systembuilder/SystembuilderController';
import { createSystembuilderWidgetController, createSystembuilderWidgetControllerWithLocals } from './Controller/Systembuilder/SystembuilderWidgetController';
import { BulkTaskEditController } from './Controller/Tasks/BulkTaskEditController';
import { createTasksSuggestionWidgetCController } from './Controller/Tasks/TasksSuggestionWidgetController';
import { createTasksController } from './Controller/TasksController';
import { UserBarController } from './Controller/UserBarController';
import { UserFlyoutController } from './Controller/UserFlyout/UserFlyoutController';
import { AfterViewInitDirective } from './Directives/AfterViewInitDirective';
import { AppendIconDirective } from './Directives/AppendIconDirective';
import { createCkeditorDirective } from './Directives/CkeditorDirective';
import { ContextDirective } from './Directives/ContextDirective';
import { createConvortToNumberDirective } from './Directives/ConvertToNumberDirective';
import { createDragAndDropPanelDirective } from './Directives/DragAndDropPanelDirective';
import { createFileDropzoneDirective } from './Directives/FileDropzoneDirective';
import { HestiaDataTableDirective } from './Directives/HestiaDataTableDirective';
import { createInputClearDirective } from './Directives/InputClearDirective';
import { InvalidIfDirective } from './Directives/InvalidIfDirective';
import { LoaderDirective } from './Directives/LoaderDirective';
import { MandatorSelectorDirective } from './Directives/MandatorSelectorDirective';
import { NgEnterDirective } from './Directives/NgEnterDirective';
import { NgRightClickDirective } from './Directives/NgRightClickDirective';
import { PdfDirective } from './Directives/PdfDirective';
import { createWebPreviewDirective } from './Directives/WebPreviewDirective';
import { DurationFilter } from './Filters/DurationFilter';
import { HestiaTableFilter } from './Filters/HestiaTableFilter';
import { MomentFormatFilter } from './Filters/MomentFormatFilter';
import { OrderObjectFilter } from './Filters/OrderObjectFilter';
import { StartFromFilter } from './Filters/StartFromFilter';
import { TelFilter } from './Filters/TelFilter';
import { TrustedFilter } from './Filters/TrustedFilter';
import { ApiHandlerService } from './Services/ApiHandlerService';
import { CkeditorService } from './Services/CkeditorService';
import { ContactsService } from './Services/ContactsService';
import { DocumentsService } from './Services/DocumentsService';
import { DragAndDropPanelService } from './Services/DragAndDropPanelService';
import { GlobalValueService } from './Services/GlobalValueService';
import { LocalizationService } from './Services/Localization/LocalizationService';
import { MonitoringService } from './Services/MonitoringService';
import { PhoneService } from './Services/PhoneService';
import { PriceImportService } from './Services/PriceImportService';
import { PublicContactsService } from './Services/PublicContactsService';
import { ScreenWidthService } from './Services/ScreenWidthService';
import { SearchBarService } from './Services/SearchBarService';
import { ServiceLocatorService } from './Services/ServiceLocatorService';
import { SignalRService } from './Services/SignalRService';
import { StorageService } from './Services/StorageService';
import { TaskService } from './Services/TaskService';
import { TimelineDatePickerService } from './Services/Timeline/TimelineDatePickerService';
import { UserService } from './Services/UserService';

const app = angular.module('HestiaApp',
  [
    'ngMaterial',
    'ngRoute',
    'ui.tree',
    'ngStorage',
    'ngSanitize',
    'angular-sortable-view',
    'monospaced.mousewheel',
    'theme',
    'themes',
    'angular.bind.notifier',
    'angular.filter',
    'ngMaterialDatePicker',
    'ngMdBadge',
    'datetime', // https://github.com/eight04/angular-datetime
  ]);
export default app;

console.log('HestiaApp is now defined.');

app.config(['$sceDelegateProvider', function ($sceDelegateProvider: angular.ISCEDelegateProvider) {
  $sceDelegateProvider.resourceUrlWhitelist([
    // Allow same origin resource loads.
    'self',
    // Allow loading from our assets domain. **.
    'http://smsmodem.axs.at/**'
  ]);
}]);

app.config(['$mdDateLocaleProvider', function ($mdDateLocaleProvider: angular.material.IDateLocaleProvider) {

  moment.locale('de');
  // Set month and week names for the general $mdDateLocale service
  const localeData = moment.localeData();
  $mdDateLocaleProvider.months = localeData.months();
  $mdDateLocaleProvider.shortMonths = moment.monthsShort();
  $mdDateLocaleProvider.days = localeData.weekdays();
  $mdDateLocaleProvider.shortDays = localeData.weekdaysMin();
  // Optionaly let the week start on the day as defined by moment's locale data
  $mdDateLocaleProvider.firstDayOfWeek = localeData.firstDayOfWeek();

  $mdDateLocaleProvider.firstDayOfWeek = 1;
  $mdDateLocaleProvider.formatDate = function (date) {
    return date ? moment(date).format('DD.MM.YYYY') : '';
  };

  // Eigenschaft fehlt im Interface
  $mdDateLocaleProvider['isDateComplete'] = function (dateString: string) {
    return true;
  };
  $mdDateLocaleProvider.parseDate = function (dateString) {
    let m = moment(dateString, 'DD.MM.YYYY', true);
    if (!m.isValid()) {
      m = moment(dateString, 'D.M.YYYY', true);
    }
    if (!m.isValid()) {
      m = moment(dateString, 'D.M.YY', true);
    }
    if (!m.isValid()) {
      m = moment(dateString, 'D.M', true);
      if (m.isValid()) {
        m.set('year', moment().year());
      }
    }
    if (!m.isValid()) {
      m = moment(dateString, 'DD,MM,YYYY', true);
    }
    if (!m.isValid()) {
      m = moment(dateString, 'D,M,YYYY', true);
    }
    if (!m.isValid()) {
      m = moment(dateString, 'D,M,YY', true);
    }
    if (!m.isValid()) {
      m = moment(dateString, 'D,M', true);
      if (m.isValid()) {
        m.set('year', moment().year());
        const date = m.toDate();
      }
    }
    return m.isValid() ? m.toDate() : new Date(NaN);
  };
}]);

app.config(['$locationProvider', function ($locationProvider: angular.ILocationProvider) {
  $locationProvider.html5Mode({
    enabled: true,
    requireBase: false
  });
}]);

app.config(['$mdThemingProvider', '$provide', function ($mdThemingProvider: angular.material.IThemingProvider, $provide: angular.auto.IProvideService) {
  $mdThemingProvider.generateThemesOnDemand(true);
  $mdThemingProvider.alwaysWatchTheme(true);
  $provide.value('themeProvider', $mdThemingProvider);
}]);



// nötig zum Booten der Anwendung
app.run(['ServiceLocatorService', function (ServiceLocatorService: ServiceLocatorService) {
  console.log('app.run ServiceLocatorService');
}]);


app.service('TimelineDatePickerService', TimelineDatePickerService);
app.service('GlobalValueService', GlobalValueService);
app.service('CkeditorService', CkeditorService);
app.service('ContactsService', ContactsService);
app.service('ServiceLocatorService', ServiceLocatorService);
app.service('PublicContactsService', PublicContactsService);
app.service('PriceImportService', PriceImportService);
app.factory('DragAndDropPanelService', DragAndDropPanelService);


app.factory('LocalizationService', LocalizationService);
app.factory('StorageService', StorageService);
app.factory('PhoneService', PhoneService);
app.factory('TaskService', TaskService);
app.factory('SearchBarService', SearchBarService);
app.factory('ScreenWidthService', ScreenWidthService);
app.factory('DocumentsService', DocumentsService);
app.factory('MonitoringService', MonitoringService);
app.factory('ApiHandlerService', ApiHandlerService);
app.factory('SignalRService', SignalRService);
app.factory('UserService', UserService);


app.directive('appendIcon', AppendIconDirective.factory());
app.directive('mandatorSelector', MandatorSelectorDirective.factory());
app.directive('ngEnter', NgEnterDirective.factory());
app.directive('ngRightClick', NgRightClickDirective.factory());
app.directive('context', ContextDirective.factory());
app.directive('pdf', PdfDirective.factory());
app.directive('dragAndDropPanel', createDragAndDropPanelDirective);
app.directive('hestiaDataTable', HestiaDataTableDirective.factory());
app.directive('loader', LoaderDirective.factory());
app.directive('invalidIf', InvalidIfDirective.factory());
app.directive('afterViewInit', AfterViewInitDirective.factory());
app.directive('ckeditor', createCkeditorDirective);
app.directive('convertToNumber', createConvortToNumberDirective);
app.directive('inputClear', createInputClearDirective);
app.directive("webPreview", createWebPreviewDirective);
app.directive('fileDropzone', createFileDropzoneDirective);


app.filter('trusted', TrustedFilter);
app.filter('HestiaTableFilter', HestiaTableFilter);
app.filter('startFrom', StartFromFilter);
app.filter('orderObject', OrderObjectFilter);
app.filter('duration', DurationFilter);
app.filter('tel', TelFilter);
app.filter('momentFormat', MomentFormatFilter);


app.controller('BirthdayTemplateController', BirthdayTemplateController);
app.controller('UserFlyoutController', UserFlyoutController);
app.controller('MetaDataController', MetaDataController);
app.controller('ErrorPageController', ErrorPageController);
app.controller('Phone3CXUserController', Phone3CXUserController);
app.controller('Phone3CXAdminDialogController', Phone3CXAdminDialogController);
app.controller('UserBarController', UserBarController);
app.controller('SmsSendController', SmsSendController);
app.controller('SmsSendParamsController', SmsSendController);
app.controller('EditNumberRangeDialogController', EditNumberRangeDialogController);
app.controller('EditDocumentsCategoryDialogController', EditDocumentsCategoryDialogController);
app.controller('EditLabelPrintPresetController', EditLabelPrintPresetController);
app.controller('EditCustomColumnDialogController', EditCustomColumnDialogController);
app.controller('PrintDocumentLabelDialogController', PrintDocumentLabelDialogController);
app.controller('PrintLabelViewController', PrintLabelViewController);
app.controller('BulkTaskEditController', BulkTaskEditController);
app.controller('ProfileController', ProfileController);
app.controller('ContactsController', ContactsController);
app.controller('ContactsQuickSearchController', ContactsQuickSearchController);
app.controller('ContactsUserController', ContactsUserController);
app.controller('ContactFlyoutController', ContactFlyoutController);
app.controller('ContactsAdminDialogController', ContactsAdminDialogController);
app.controller('Phone3CXController', Phone3CXController);
app.controller('PublicCalendarController', createPublicCalendarController);
app.controller('NavigationController', NavigationController);
app.controller('EditMandatorPluginsController', creatEditMandatorPluginsController);
app.controller('DashboardController', DashboardController);
app.controller('EditTextDialogController', createEditTextDialogController);
app.controller('Phone3CXInCallPanelController', createPhone3CXInCallPanelController);
app.controller('PublicContactsAdminDialogController', PublicContactsAdminDialogController);
app.controller('PublicContractsGroupDialogController', creatPublicContractsGroupDialogController);
app.controller('AppointmentsTemplateController', AppointmentsTemplateController);
app.controller('ErpSelectorController', createErpSelectorController);
app.controller('ErpAdminController', createErpAdminController);
app.controller('ErpContactFlyoutController', createErpContactFlyoutController);
app.controller('ErpController', createErpController);
app.controller('PrintArticleLabelController', createPrintArticleLabelController);
app.controller('ErpAdminDialogController', createErpAdminDialgController);
app.controller('ArticleSearchController', createArticleSearchController);
app.controller('EditUserController', createEditUserController);
app.controller('EditMandatorGroupController', createEditMandatorGroupController);
app.controller('AdministrationController', createAdministrationController);
app.controller('aXsSystemSearchAdminDialogController', createaXsSystemSearchAdminDialogController);
app.controller('aXsSystemSearchWidgetController', createaXsSystemSearchWidgetController);
app.controller('aXsSystemSearchWidgetControllerWithLocals', createaXsSystemSearchWidgetControllerWithLocals);
app.controller('aXsSystemSearchController', createaXsSystemSearchController);
app.controller('DocumentsController', createDocumentsCrontroller);
app.controller('MailSendDialogController', createMailSendDialogController);
app.controller('PasswordGeneratorController', createPasswordGeneratorController);
app.controller('SubnetCalculatorController', createSubnetCalculatorController);
app.controller('EditVendorDialogController', createEditVendorDialogController);
app.controller('EditDownloadDialogController', createEditDownloadDialogController);
app.controller('EditFileDialogController', createEditFileDialogController);
app.controller('EditColumnDialogController', createEditColumnDialogController);
app.controller('EditTransformationDialogController', createEditTransformationDialogController);
app.controller('PriceListController', createPriceListController);
app.controller('AddDiskAlertController', createAddDiskAlertController);
app.controller('MonitoringController', createMonitoringController);
app.controller('TasksSuggestionWidgetController', createTasksSuggestionWidgetCController);
app.controller('TasksController', createTasksController);
app.controller('DeletePartController', createDeletePartController);
app.controller('MoveSerialDialogController', createMoveSerialDialogController);
app.controller('OpenPartSearchDialogController', createOpenPartSearchDialogController);
app.controller('LabelWidgetController', createLabelWidgetController);
app.controller('OpenIdentcodeSearchDialogController', createOpenIdentcodeDialogController);
app.controller('SystembuilderWidgetController', createSystembuilderWidgetController);
app.controller('SystembuilderWidgetControllerWithLocals', createSystembuilderWidgetControllerWithLocals);
app.controller('SystembuilderController', createSystembuilderController);
app.controller('PublicHolidaysController', createPublicHolidaysController);
app.controller('PublicPhonebookController', PublicPhonebookController);
app.controller('LabelCreateEditFormatController', createLabelCreateFormatController);
app.controller('LabelCreateEditPrinterController', createLabelCreatePrinterController);
app.controller('LabelCreateEditTemplateController', createLabelCreateEditTemplateController);
app.controller('LabelTestPrintController', createLabelTestPrintController);
app.controller('LabelController', createLabelController);
app.controller("InformationHubTemplateController", createInformationHubTemplateController);
app.controller("EditHubSourceDialogController", createEditHubSourceDialogController);
app.controller("AddOutlookFolderDialogController", createAddOutlookFolderDialogController);
app.controller("AddMailboxDialogController", createAddMailboxDialogController);
app.controller('InformationHubController', createInformationHubController);



app.component('ajsDashboard', {
  template: `<ng-include src="'/ClientApp/src/ajs/Views/Dashboard/Dashboard.htm'" layout="column" flex="100"></ng-include>`,
  controller: 'DashboardController'
});
app.component('ajsPublicCalendar', {
  template: `<ng-include src="'/ClientApp/src/ajs/Views/PublicContacts/PublicCalendar.htm'" layout="column" flex="100"></ng-include>`,
  controller: 'PublicCalendarController'
});
app.component('ajsPublicPhonebook', {
  template: `<ng-include src="'/ClientApp/src/ajs/Views/PublicContacts/PublicPhoneBook.htm'" layout="column" flex="100"></ng-include>`,
  controller: 'PublicPhonebookController'
});
app.component('ajsPublicHolidays', {
  template: `<ng-include src="'/ClientApp/src/ajs/Views/PublicContacts/PublicHolidays.htm'" layout="column" flex="100"></ng-include>`,
  controller: 'PublicHolidaysController'
});

app.component('ajsServerError', {
  template: `<ng-include src="'/ClientApp/src/ajs/Views/ServerError.htm'" layout="column" flex="100"></ng-include>`,
  controller: 'ErrorPageController'
});

app.component('ajsUnauthorized', {
  template: `<ng-include src="'/ClientApp/src/ajs/Views/Unauthorized.htm'" layout="column" flex="100"></ng-include>`,
  controller: 'ErrorPageController'
});

app.component('ajsProfile', {
  template: `<ng-include src="'/ClientApp/src/ajs/Views/User/Profile.htm'" layout="column" flex="100"></ng-include>`,
  controller: 'ProfileController'
});

app.component('ajsContacts', {
  template: `<ng-include src="'/ClientApp/src/ajs/Views/Contacts/Contacts.htm'" layout="column" flex="100"></ng-include>`,
  controller: 'ContactsController'
});

app.component('ajsPhone3cx', {
  template: `<ng-include src="'/ClientApp/src/ajs/Views/Phone3CX/Phone3CX.htm'" layout="column" flex="100"></ng-include>`,
  controller: 'Phone3CXController'
});


