import * as angular from 'angular';

export class ScreenWidthService {

  static $inject = ['$window', '$rootScope'];

  MOBILE_SCREEN_WIDTH: number = 770;
  PORTRAIT_SCREEN_WIDTH: number = 1220;


  constructor(
    private readonly $window: angular.IWindowService,
    $rootScope: angular.IRootScopeService) {

    angular.element($window).bind('resize', function () {
      $rootScope.$apply();
    });
  }

  IsMobile() {
    return this.$window.innerWidth < this.MOBILE_SCREEN_WIDTH;
  }

  IsPortrait() {
    return this.$window.innerWidth < this.PORTRAIT_SCREEN_WIDTH;
  }
}
