import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { FormatDto } from "src/ajs/Models/Label/FormatDto";
import { PrinterDto } from "src/ajs/Models/Label/PrinterDto";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { MandatorService } from "src/app/core/services/mandator.service";

createLabelCreatePrinterController.$inject = ['$scope', '$mdDialog', 'ApiHandlerService', 'Printer', 'Formats', 'OnSuccess', 'theme', 'MandatorServiceNgx'];

export function createLabelCreatePrinterController(
  $scope,
  $mdDialog,
  ApiHandlerService: ApiHandlerService,
  Printer: PrinterDto,
  Formats: FormatDto[],
  OnSuccess: () => void,
  theme: ThemeDto,
  mandatorServiceNgx: MandatorService
) {

  addDefaultScopeFunctions($scope, theme);
  $scope.Printer = Printer;
  $scope.IsNew = !Printer;
  $scope.Formats = Formats;
  if ($scope.IsNew) {
    $scope.Printer = {
      Ip: null,
      Port: 9100,
      Name: null,
      Location: null,
      CanZpl: true,
      CanEpl: true,
      LabelFormatId: $scope.Formats[0].Id
    };
  }
  $scope.OnSuccess = OnSuccess;

  $scope.Hide = function () {
    $mdDialog.hide();
  };
  $scope.Cancel = function () {
    $mdDialog.cancel();
  };
  $scope.Ok = function () {
    ApiHandlerService.SendRequest("Label", "CreateOrEditPrinter", { printer: $scope.Printer, mandatorId: mandatorServiceNgx.selectedMandatorId }).then(function (data) {
      if ($scope.OnSuccess) {
        $scope.OnSuccess();
      }
      $mdDialog.hide();
    });
  };
}
