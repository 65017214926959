import * as angular from 'angular';
import * as moment from 'moment';
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { SignalRService } from 'src/app/core/services/signalr/signalr.service';
import { CallEntryDto } from '../Models/Phone3cx/CallEntryDto';
import { ConnectionDto } from '../Models/Phone3cx/ConnectionDto';
import { ExtensionDto } from '../Models/Phone3cx/ExtensionDto';
import { UserConfigurationDto } from '../Models/Phone3cx/UserConfigurationDto';


export class PhoneService {
  static $inject = ['$timeout', 'ApiHandlerService', 'SignalRServiceNgx'];

  Disabled: boolean = false;
  Configuration: UserConfigurationDto;
  CurrentExtension: string = null;
  private LastConnectionChangedCallbacks: (() => void)[] = [];
  private ExtensionsChangedCallbacks: ((extension: ExtensionDto[]) => void)[] = [];
  LastConnectionInfo: ConnectionDto = null;

  constructor(
    private readonly $timeout: angular.ITimeoutService,
    private readonly ApiHandlerService: ApiHandlerService,
    private readonly SignalRService: SignalRService
  ) {

    this.SignalRService.extensionsChanged.subscribe((extensions: ExtensionDto[]) => {
      for (const callback of this.ExtensionsChangedCallbacks) {
        callback(extensions);
      }

      const myData = extensions.find(f => f.Extension === this.CurrentExtension);
      if (myData) {
        this.$timeout(() => {
          if (myData.Connection) {
            this.LastConnectionInfo = myData.Connection; // wird noch f�r InCallPanel gebraucht
          }
        }, 50);
      }
    });
  }

  UpdateCallTimeStats(call: CallEntryDto) {
    call.SpeakingTime = 0;
    call.IsFinished = true;

    for (const part of call.Parts) {

      part.IsFinished = false;
      if (part.End) {
        part.IsFinished = true;
        part.Duration = moment(part.End).diff(moment(part.Start));
        // part.Type ist anscheinend der ConnectionStatus nicht der Connection Type
        if (part.Type === 3 && !part.IsQueue) {
          call.SpeakingTime += part.Duration;
        }
      }
      else {
        part.IsFinished = false;
        call.IsFinished = false;
        part.Duration = moment().diff(moment(part.Start));
        if (part.Type === 3 && !part.IsQueue) {
          call.SpeakingTime += part.Duration;
        }
      }
    }
    call.ConnectionTimeFormatted = moment.utc(call.CallDurationMilliseconds).format("HH:mm:ss");
    call.SpeakingTimeFormatted = moment.utc(call.SpeakingTime).format("HH:mm:ss");
  }

  private async LoadConfiguration() {
    try {
      const data: UserConfigurationDto = await this.ApiHandlerService.SendRequest("Phone3cx", "GetUserConfiguration", null, true);
      this.Configuration = data;

      if (this.Configuration?.Extension) {
        this.CurrentExtension = this.Configuration.Extension;
      }
    }
    catch (reason) {
      console.log("Phone Service - LoadConfiguration rejected: " + reason);
    }
  }

  async LoadData() {
    try {
      await this.LoadConfiguration();
      console.log("PhoneService loaded");
    }
    catch (reason) {
      console.log("PhoneService rejected: " + reason);
      this.Disabled = true;
    }
  }

  LastConnectionChangedEvent(callback: any) {
    let replaced = false;
    for (let i = 0; i < this.LastConnectionChangedCallbacks.length; i++) {
      if (this.LastConnectionChangedCallbacks[i].toString() === callback.toString()) {
        this.LastConnectionChangedCallbacks[i] = callback;
        replaced = true;
      }
    }
    if (!replaced) {
      this.LastConnectionChangedCallbacks.push(callback);
    }
  }

  ExtensionsChangedEvent(callback: any) {
    let replaced = false;
    for (let i = 0; i < this.ExtensionsChangedCallbacks.length; i++) {
      if (this.ExtensionsChangedCallbacks[i].toString() === callback.toString()) {
        this.ExtensionsChangedCallbacks[i] = callback;
        replaced = true;
      }
    }
    if (!replaced) {
      this.ExtensionsChangedCallbacks.push(callback);
    }
  }
}
