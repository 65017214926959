import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss']
})
export class LoadingOverlayComponent {

  @Input() isLoading: boolean = false;
  @Input() align: 'center' | 'top' = 'center';
  @Input() overlay: 'dark' | 'light' = 'dark';
  @Input() diameter = 100;

  @HostBinding('class.align-top') get alignTop() {
    return this.align === 'top';
  }

  @HostBinding('class.overlay-light') get overlayLight() {
    return this.overlay === 'light';
  }
}
