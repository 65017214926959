import angular, { IQService } from "angular";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { StorageService } from "src/ajs/Services/StorageService";
import { IinternalCreateScope, internalCreate } from "./aXsSystemSearchInternalCreate";

createaXsSystemSearchWidgetController.$inject = ['$scope', 'mdPanelRef', '$q', 'ApiHandlerService', 'StorageService', 'theme'];

export function createaXsSystemSearchWidgetController(
  $scope: IinternalCreateScope,
  mdPanelRef: angular.material.IPanelRef,
  $q: IQService,
  ApiHandlerService: ApiHandlerService,
  StorageService: StorageService,
  theme: ThemeDto
) {

  internalCreate($scope, mdPanelRef, $q, ApiHandlerService, StorageService, null, theme);
}
