import * as angular from 'angular';
import { addNavigation } from 'src/ajs/Utils/HestiaCommon';
import { Api1Service } from 'src/app/core/services/api1.service';
import { CachedAddressDto } from '../Models/Erp/CachedAddressDto';
import { TaskDto } from '../Models/Tasks/TaskDto';
import { ThemeDto } from '../Models/ThemeDto';
import { ApiHandlerService } from '../Services/ApiHandlerService';
import { ContactsService } from '../Services/ContactsService';
import { IPanelServiceEx } from '../Services/IPanelServiceEx';
import { SearchBarService } from '../Services/SearchBarService';
import { StorageService } from '../Services/StorageService';
import { INavigationScope } from '../Utils/INavigationScope';
import { createTaskEditBaseController, ITaskEditBaseControllerScope } from './TaskEditBaseController';
import { MailEnvironment } from './Tasks/MailEnvironment';

createTasksController.$inject = ['$scope', '$mdPanel', '$q', '$timeout', 'ApiHandlerService', 'Api1ServiceNgx', 'StorageService', 'SearchBarService', 'ContactsService', 'theme'];

export function createTasksController(
  $scope: ITaskControllerScope,
  $mdPanel: IPanelServiceEx,
  $q: angular.IQService,
  $timeout: angular.ITimeoutService,
  ApiHandlerService: ApiHandlerService,
  api: Api1Service,
  StorageService: StorageService,
  SearchBarService: SearchBarService,
  ContactsService: ContactsService,
  theme: ThemeDto
) {

  createTaskEditBaseController($scope, StorageService, theme);

  $scope.Mails = MailEnvironment(StorageService, ApiHandlerService, api, ContactsService, $timeout, $q, $mdPanel);

  $scope.$on("bindAddress", function (event: any, args: any) {
    $scope.SelectedAddress = args;
    $scope.SelectedAddressId = args.Id;
  });

  SearchBarService.RequireSearchBar(true);

  $scope.Storage = StorageService.CreateInstance("TasksController");


  addNavigation($scope, "Favorites");

  $scope.ApplyServiceUpdate = function (operation: string, task: TaskDto) {
    if (operation === "reload") {
      $scope.Mails.SetQueues();
    }
  };

  $scope.BulkTaskEdit = function (tasks: TaskDto[], $event: Event, resolve?: () => void) {
    $mdPanel.newPanelGroup("BulkTaskEdit", {
      maxOpen: 1
    });

    const config = {
      attachTo: angular.element(document.body),
      templateUrl: '/ClientApp/src/ajs/Views/Tasks/BulkTaskEdit.htm',
      controller: "BulkTaskEditController",
      panelClass: 'window-panel-container',
      bindToController: true,
      locals: {
        ParentScope: $scope,
        Tasks: tasks
      },
      openFrom: $event,
      focusOnOpen: true,
      propagateContainerEvents: true,
      groupName: ["BulkTaskEdit"],
      onCloseSuccess: resolve
    };
    $mdPanel.open(config);
  };

  let tab = $scope.Storage.GetOrCreate("ActiveTab", "Listview");
  if (tab === 'TimeReceiptEdit') {
    tab = 'Listview';
  }

  $scope.Navigate(tab, true);
}

interface ITaskControllerScope extends angular.IScope, INavigationScope, ITaskEditBaseControllerScope {
  BulkTaskEdit: (tasks: any, $event: Event) => void;
  SelectedAddress: CachedAddressDto; // TODO: prüfen ob überhaupt noch verwendet
  SelectedAddressId: number;
  Mails: any;
  ApplyServiceUpdate: (operation: string, task: TaskDto) => void;
}

