import angular, { IScope, ITimeoutService } from "angular";
import moment from "moment";
import { ColumnDefinition, HestiaDataTable } from "src/ajs/Directives/HestiaDataTableDirective";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { SystemDto } from "src/ajs/Models/SystemSearch/SystemDto";
import { SystemQueryResultDto } from "src/ajs/Models/SystemSearch/SystemQueryResultDto";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { StorageService, StorageServiceInstance } from "src/ajs/Services/StorageService";
import { addNavigation, convertDate, formatDateShort } from "src/ajs/Utils/HestiaCommon";
import { INavigationScope } from "src/ajs/Utils/INavigationScope";
import { MandatorService } from "src/app/core/services/mandator.service";

createaXsSystemSearchController.$inject = ['$scope', '$timeout', '$mdPanel', 'ApiHandlerService', 'StorageService', 'theme', 'MandatorServiceNgx'];


export function createaXsSystemSearchController(
  $scope: IaXsSystemSearchControllerScope,
  $timeout: ITimeoutService,
  $mdPanel: angular.material.IPanelService,
  ApiHandlerService: ApiHandlerService,
  StorageService: StorageService,
  theme: ThemeDto,
  mandatorServiceNgx: MandatorService
) {

  $scope.Filter = {} as FilterModel;
  $scope.Filter.Addresses = {};

  $scope.Storage = StorageService.CreateInstance("aXsSystemSearchController");
  addDefaultScopeFunctions($scope, theme);
  addNavigation($scope, "AllSystems");

  $scope.$on("bindAddress", function (event, args) {
    $scope.HasFixedAddress = true;
    $scope.Filter.SelectedAddress = args;
    $scope.Filter.SelectedAddressId = args.Id;
    $scope.ApplyFilter();
    $timeout(function () {
      $scope.SystemsTable.Update();
    }, 50);
  });

  $scope.$on("applyFilters", function (event, args) {
    $timeout(function () {
      $scope.SystemsTable.Update();
    }, 50);
  });


  $scope.GetType = function (id: number) {
    const type = $scope.Model.Types.filter(t => t.Id === id);
    if (type && type.length) {
      return type[0].Name;
    }
    return "Unbekannt";
  };

  $scope.GetUser = function (id: number) {
    const user = $scope.Model.Users.filter(t => t.Id === id);
    if (user && user.length) {
      return user[0].UserName;
    }
    return "Unbekannt";
  };

  $scope.SystemsTable = new HestiaDataTable(StorageService.CreateInstance("SystemsTable"), $timeout, $scope);
  //$scope.ContractsTable.ItemDoubleClicked = $scope.OpenContractDialog;
  $scope.SystemsTable.Options.Select = false;
  $scope.SystemsTable.UpdateColumns([
    new ColumnDefinition({ PropertyPath: 'Identcode', Heading: ' ', ColumnWidth: 50, Resizeable: false, Sortable: false, Template: "/ClientApp/src/ajs/Views/aXsSystemSearch/ViewSystemTemplate.htm" }),
    new ColumnDefinition({ PropertyPath: 'Identcode', Heading: 'Identcode', Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'SystemDate', Heading: 'Datum', Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDateShort }),
    new ColumnDefinition({ PropertyPath: 'AddressDisplayText', Heading: 'Kunde', Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'SystemTypeId', Heading: 'Typ', Resizeable: true, Sortable: true, RenderFunction: $scope.GetType }),
    new ColumnDefinition({ PropertyPath: 'SystemUserId', Heading: 'Mitarbeiter', Resizeable: true, Sortable: true, RenderFunction: $scope.GetUser }),
    new ColumnDefinition({ PropertyPath: 'System', Heading: 'Computername', Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'SystemIp', Heading: 'IP-Adresse', Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'ClientUser', Heading: 'Benutzer', Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'Memo', Heading: 'Memo', Resizeable: true, Sortable: true })
  ]);

  $scope.QueryAddresses = function (query: string) {
    const result = [];
    angular.forEach($scope.Filter.Addresses, function (value, key) {
      if (value.toUpperCase().includes(query.toUpperCase())) {
        result.push({ Id: Number(key), DisplayText: value });
      }
    });
    return result;
  };

  $scope.LoadSystems = function () {
    ApiHandlerService.SendRequest("aXsSystemSearch", "GetAllSystems", { mandatorId: mandatorServiceNgx.selectedMandatorId }).then(function (data: SystemQueryResultDto) {
      $scope.Model = data;
      angular.forEach($scope.Model.Systems, function (system) {
        system.SystemDate = moment(system.SystemDate);
        if (system.CachedAddressId) {
          if (!$scope.Filter.Addresses[system.CachedAddressId]) {
            $scope.Filter.Addresses[system.CachedAddressId] = system.AddressDisplayText;
          }
        }
      });
      $scope.ApplyFilter();
      $scope.IsLoading = false;
    });
  };

  $scope.ClearFilter = function () {
    let addresses = [];
    if ($scope.Filter && $scope.Filter.Addresses) {
      addresses = $scope.Filter.Addresses;
    }
    let oldAddressId = null;
    let oldAddress = null;
    if ($scope.Filter) {
      oldAddressId = $scope.Filter.SelectedAddressId;
      oldAddress = $scope.Filter.SelectedAddress;
    }
    $scope.Filter = {
      SelectedTypeId: null,
      SelectedUserId: null,
      AddressSearchText: null,
      SearchText: null,
      SelectedAddress: null,
      SelectedAddressId: null,
      Addresses: addresses
    };
    if ($scope.HasFixedAddress) {
      $scope.Filter.SelectedAddressId = oldAddressId;
      $scope.Filter.SelectedAddress = oldAddress;
    }
    $scope.FilterChanged();
  };

  $scope.FilterChanged = function () {
    // TODO: Remember?
    $scope.ApplyFilter();
  };

  $scope.FilterChangedDelayed = function () {
    if ($scope.FilterChangedTimeout) {
      $timeout.cancel($scope.FilterChangedTimeout);
    }

    $scope.FilterChangedTimeout = $timeout(function () {
      $scope.FilterChanged();
      $scope.FilterChangedTimeout = null;
    }, 500);
  };

  $scope.OpenSystemPanel = function (model: SystemDto, $event: any) {
    const target = $event.srcElement || $event.target;
    const config = {
      attachTo: angular.element(document.body),
      templateUrl: '/ClientApp/src/ajs/Views/aXsSystemSearch/aXsSystemSearchWidget.htm',
      controller: 'aXsSystemSearchWidgetControllerWithLocals',
      panelClass: 'window-panel-container',
      openFrom: $event,
      locals: {
        FixedModelIdentcode: model.Identcode
      },
      focusOnOpen: true,
      propagateContainerEvents: true,
      groupName: ['aXsSystemSearch']
    };

    $mdPanel.open(config);
  };

  $scope.ApplyFilter = function () {
    if ($scope.Model) {
      $scope.FilteredSystems = $scope.Model.Systems.slice();
      if ($scope.Filter.SelectedTypeId) {
        $scope.FilteredSystems = $scope.FilteredSystems.filter(f => f.SystemTypeId === $scope.Filter.SelectedTypeId);
      }
      if ($scope.Filter.SelectedUserId) {
        $scope.FilteredSystems = $scope.FilteredSystems.filter(f => f.SystemUserId === $scope.Filter.SelectedUserId);
      }
      if ($scope.Filter.SelectedAddress) {
        $scope.FilteredSystems = $scope.FilteredSystems.filter(f => f.CachedAddressId === $scope.Filter.SelectedAddress.Id);
      }
      if ($scope.Filter.SearchText) {
        const transformedSearch = $scope.Filter.SearchText.trim().toUpperCase();
        if (transformedSearch.length) {
          $scope.FilteredSystems = $scope.FilteredSystems.filter(f => f.System && f.System.toUpperCase().includes(transformedSearch) ||
            f.SystemIp && f.SystemIp.toUpperCase().includes(transformedSearch) ||
            f.Memo && f.Memo.toUpperCase().includes(transformedSearch) ||
            f.ClientUser && f.ClientUser.toUpperCase().includes(transformedSearch));
        }
      }
      $scope.SystemsTable.UpdateSource($scope.FilteredSystems, $timeout);
    }
  };

  $scope.GetFixedAddressLength = function () {
    if ($scope.Model) {
      return $scope.Model.Systems.filter(c => c.CachedAddressId === $scope.Filter.SelectedAddressId).length;
    }
    return 0;
  };

  $scope.ClearFilter();
  $scope.LoadSystems();
  $scope.Navigate($scope.Storage.GetOrCreate("ActiveTab", "AllSystems"), true);
}

interface IaXsSystemSearchControllerScope extends IScope, INavigationScope {
  Filter: FilterModel;
  Storage: StorageServiceInstance;
  HasFixedAddress: boolean;
  SystemsTable: HestiaDataTable;
  Model: SystemQueryResultDto;
  IsLoading: boolean;
  FilterChangedTimeout: angular.IPromise<void>;
  FilteredSystems: SystemDto[];

  ApplyFilter: () => void;
  GetType: (id: number) => string;
  GetUser: (id: number) => string;
  QueryAddresses: (query: string) => { Id: number, DisplayText: string }[]
  LoadSystems: () => void;
  ClearFilter: () => void;
  FilterChanged: () => void;
  FilterChangedDelayed: () => void;
  OpenSystemPanel: (model: SystemDto, $event: any) => void;
  GetFixedAddressLength: () => void;

}


interface FilterModel {
  Addresses: any;
  SelectedTypeId: number;
  SelectedAddressId: number;
  SearchText: string;
  SelectedAddress: any;
  SelectedUserId: number;
  AddressSearchText: string;

}
