import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Subject, debounceTime, startWith, takeUntil } from 'rxjs';
import { CustomLabelLineDto } from 'src/ajs/Models/Label/CustomLabelLineDto';
import { LoadCustomLabelsResultDto } from 'src/ajs/Models/Label/LoadCustomLabelsResultDto';
import { PrinterDto } from 'src/ajs/Models/Label/PrinterDto';
import { CustomLabelDto } from 'src/app/labels/dto/CustomLabelDto';
import { LabelService } from 'src/app/labels/services/label.service';

@Component({
  selector: 'app-label-print-dialog',
  templateUrl: './label-print-dialog.component.html',
  styleUrls: ['./label-print-dialog.component.scss']
})
export class LabelPrintDialogComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<void>();

  isEditing: boolean = false;


  //list
  labels: CustomLabelDto[] = [];
  printers: PrinterDto[] = [];
  filteredLabels: CustomLabelDto[] = [];

  searchFilter = new FormControl<string>("");

  //edit
  formGroup: FormGroup<{
    printerId: FormControl<number>,
    name: FormControl<string>,
    amount: FormControl<number>,
    logoType: FormControl<number>,
    logoHeight: FormControl<number>

    repeaterStartValue: FormControl<number>,
    repeaterEndValue: FormControl<number>,
    repeaterPrefix: FormControl<string>,
    repeaterSuffix: FormControl<string>,
    repeaterTextSuffix: FormControl<string>

    lines: FormArray<FormControl<CustomLabelLineDto>>
  }>;

  selectedLabel: CustomLabelDto;


  constructor(
    private readonly labelService: LabelService

  ) { }

  async ngOnInit() {

    this.formGroup = new FormGroup({
      printerId: new FormControl<number>(null),
      name: new FormControl<string>(""),
      amount: new FormControl<number>(1),
      logoType: new FormControl<number>(0),
      logoHeight: new FormControl<number>(112),

      repeaterStartValue: new FormControl<number>(null),
      repeaterEndValue: new FormControl<number>(null),
      repeaterPrefix: new FormControl<string>(""),
      repeaterSuffix: new FormControl<string>(""),
      repeaterTextSuffix: new FormControl<string>(""),

      lines: new FormArray<FormControl<CustomLabelLineDto>>([])
    });

    await this.loadData();


    this.searchFilter.valueChanges.pipe(
      debounceTime(300),
      takeUntil(this.destroy$),
      startWith("")
    ).subscribe(value => {
      this.filteredLabels = this.labels.filter(x => x.Name.toLowerCase().includes(value.toLowerCase().trim()));
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async loadData() {

    const result: LoadCustomLabelsResultDto = await this.labelService.getLabelCustomLabels();
    this.labels = result.Labels;
    this.printers = result.Printing.Printers;
  }

  newLabel() {
    this.isEditing = true;
  }

  editLabel(label: CustomLabelDto) {
    this.isEditing = true;
    this.selectedLabel = label;

    this.formGroup.controls.name.patchValue(this.selectedLabel.Name);
    this.formGroup.controls.printerId.patchValue(this.selectedLabel.PrinterId);


    for (const line of this.selectedLabel.Lines) {
      this.formGroup.controls.lines.push(new FormControl<CustomLabelLineDto>(line));
    }


    const test = "";

  }

  cancelEdit() {
    this.isEditing = false;
  }

  changeLogoType(type: number) {
    this.formGroup.controls.logoType.patchValue(type);
    //this.selectedLabel.LogoType = type;
  }

  hasRepeater() {
    return false;
  }


}
