import { Pipe, PipeTransform } from "@angular/core";

/**
 * Formatiert eine Dauer als lesbaren String.
 * @param base `s`, wenn value in Sekunden übergeben wird. Standard `ms`.
 * @param hideSeconds Ob Sekunden angezeigt werden sollen. Standard `false`.
 * @param hideHours Ob Stunden angezeigt werden sollen wenn die Dauer unter einer Stunde ist. Standard `false`
 */
@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {
  transform(value: any, base?: 'ms' | 's', hideSeconds?: boolean, hideHours?: boolean) {
    if (typeof value !== 'number') {
      return '';
    }

    let ms = value;

    if (base === 's') {
      ms = ms * 100;
    }
    return this.format(ms, hideSeconds, hideHours);
  }

  private format(ms: number, hideSeconds?: boolean, hideHours?: boolean): string {
    let seconds = ms / 1000;
    const hours = Math.trunc(seconds / 3600);
    seconds = seconds % 3600;
    const minutes = Math.trunc(seconds / 60);
    seconds = Math.round(seconds % 60);

    let result = "";

    if (hideHours && hours === 0) {
      result = minutes.toString().padStart(2, '0');
    }
    else {
      result = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0');
    }

    if (!hideSeconds) {
      result += ":" + seconds.toString().padStart(2, '0');
    }
    return result;
  }
}
