import { EventEmitter } from "@angular/core";
import { IPromise, IRootScopeService, ITimeoutService } from "angular";

export class SearchBarService {

  static $inject = ['$rootScope', '$timeout'];

  ShowSearchBar: boolean = false;
  SearchText: string = null;
  HasFocus: boolean;
  SearchTextChangedCallbacks: ((text: string) => void)[] = [];
  readonly searchTextChange = new EventEmitter<string>();
  private pendingSearchTimeout: IPromise<void>;

  constructor(
    $rootScope: IRootScopeService,
    private readonly $timeout: ITimeoutService) {

    $rootScope.$on("$locationChangeStart", (event: any, next: string, current: string) => {
      // SearchBar bei Seitenwechsel ausblenden, weil sie standardmäßig ausgeblendet sein soll.
      if (next !== current) {
        this.ShowSearchBar = false;
      }
    });
  }

  Focus() {
    this.HasFocus = true;
    $("#SearchBarInput").focus();
  }

  LoseFocus() {
    if (!this.SearchText) {
      this.HasFocus = false;
    }
  }

  SearchTextChangedEvent(callback: (text: string) => void) {
    let replaced = false;
    for (let i = 0; i < this.SearchTextChangedCallbacks.length; i++) {
      if (this.SearchTextChangedCallbacks[i].toString() === callback.toString()) {
        this.SearchTextChangedCallbacks[i] = callback;
        replaced = true;
      }
    }
    if (!replaced) {
      this.SearchTextChangedCallbacks.push(callback);
    }
  }

  SearchTextChanged(text: string) {
    this.SearchText = text;

    this.searchTextChange.emit(text);

    if (this.pendingSearchTimeout) {
      this.$timeout.cancel(this.pendingSearchTimeout);
    }

    this.pendingSearchTimeout = this.$timeout(() => {
      this.SearchTextChangedCallbacks.forEach((callback) => {
        callback(text);
      });
    }, 200);
  }

  RequireSearchBar(require: boolean) {
    this.SearchText = null;
    if (require) {
      this.ShowSearchBar = true;
    } else {
      this.ShowSearchBar = false;
    }
  }
}
