<div class="dialog-container">



  <div mat-dialog-title class="dialog-title"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle>

    <div>
      Etikettendruck
    </div>

    <div>

      <button mat-icon-button *ngIf="isEditing" (click)="cancelEdit()">
        <mat-icon>arrow_back</mat-icon>
      </button>

      <button mat-icon-button *ngIf="isEditing">
        <mat-icon>save</mat-icon>
      </button>

      <button mat-icon-button *ngIf="!isEditing" (click)="newLabel()">
        <mat-icon>add</mat-icon>
      </button>

      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>


  </div>

  <mat-dialog-content class="mat-typography">

    <div class="main-container" [formGroup]="formGroup">

      <!--Label Liste-->
      <div *ngIf="!isEditing">
        <div class="content-container">

          <mat-form-field>
            <mat-label>Suchtext</mat-label>
            <input matInput type="text" [formControl]="searchFilter">
          </mat-form-field>
        </div>
        <mat-divider></mat-divider>

        <div class="content-container">
          <div *ngIf="!labels.length">
            Keine Einträge
          </div>

          <div *ngFor="let label of filteredLabels" (click)="editLabel(label)" class="label-list-entry">
            {{label.Name}}
          </div>

        </div>
      </div>

      <!--Label Edit-->
      <div *ngIf="isEditing">

        <div class="content-container">
          <div class="row-container">
            <mat-form-field>
              <mat-label>Drucker</mat-label>
              <mat-select formControlName="printerId">
                <mat-option *ngFor="let printer of printers" [value]="printer.Id">
                  {{printer.Name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div class="row-container">
              <mat-icon [ngClass]="{'logo-icon-color': !formGroup.value.logoType}" class="cursor-pointer" (click)="changeLogoType(0)">border_clear</mat-icon>
              <mat-icon [ngClass]="{'logo-icon-color': formGroup.value.logoType === 1}" class="cursor-pointer" (click)="changeLogoType(1)">border_left</mat-icon>
              <mat-icon [ngClass]="{'logo-icon-color': formGroup.value.logoType === 2}" class="cursor-pointer" (click)="changeLogoType(2)">border_top</mat-icon>
              <mat-icon [ngClass]="{'logo-icon-color': formGroup.value.logoType === 3}" class="cursor-pointer" (click)="changeLogoType(3)">border_right</mat-icon>

              <div *ngIf="formGroup.value.logoType">
                <mat-form-field>
                  <mat-label>Logo-Höhe</mat-label>
                  <input matInput type="number" max="500" formControlName="logoHeight" (wheel)="$event.preventDefault()">
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="row-container">
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input matInput type="text" formControlName="name">
            </mat-form-field>

            <mat-form-field>
              <mat-label>Anzahl</mat-label>
              <input matInput type="number" formControlName="amount" (wheel)="$event.preventDefault()">
            </mat-form-field>

            <button mat-icon-button color="primary">
              <mat-icon>print</mat-icon>
            </button>
          </div>

        </div>

        <mat-divider></mat-divider>

        <div class="content-container" *ngIf="hasRepeater()">

          <div class="row-container">
            <mat-form-field>
              <mat-label>Anfangswert</mat-label>
              <input matInput type="number" formControlName="repeaterStartValue" (wheel)="$event.preventDefault()">
            </mat-form-field>

            <mat-form-field>
              <mat-label>Endwert</mat-label>
              <input matInput type="number" formControlName="repeaterEndValue" (wheel)="$event.preventDefault()">
            </mat-form-field>
          </div>


          <div class="row-container">
            <mat-form-field>
              <mat-label>Prefix</mat-label>
              <input matInput type="text" formControlName="repeaterPrefix">
            </mat-form-field>

            <mat-form-field>
              <mat-label>Suffix</mat-label>
              <input matInput type="text" formControlName="repeaterSuffix">
            </mat-form-field>
          </div>


          <div class="row-container">
            <mat-form-field>
              <mat-label>Text Prefix</mat-label>
              <input matInput type="text" formControlName="repeaterTextSuffix">
            </mat-form-field>

          </div>

        </div>

        <div *ngFor="let line of formGroup.controls.lines.controls let i = index" class="row-container">
          <div>
            {{i+1}}
          </div>

          <div>
            <mat-form-field>
              <input matInput type="text" [formControl]="line">
            </mat-form-field>

          </div>

        </div>

      </div>


    </div>




  </mat-dialog-content>

  <mat-dialog-actions>


  </mat-dialog-actions>

</div>