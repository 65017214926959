OrderObjectFilter.$inject = [];

export function OrderObjectFilter() {
  return (obj) => {
    const result = {};
    for (const key in obj) {
      result[obj[key].SortOrder] = obj[key];
    }
    return result;
  };
}

