<div fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="column" fxLayoutAlign="space-between start" fxFlex>
    <div fxLayout="row">
      <span *ngIf="!currentCall?.OtherPartyMainContact">{{currentCall?.OtherPartyNumber}}</span>
      <span *ngIf="currentCall?.OtherPartyMainContact">{{currentCall?.OtherPartyMainContact?.DisplayName}}</span>
    </div>

    <div fxLayout="row">
      <span>{{ snackDuration | duration }} {{currentCall?.OtherPartyMainContact?.Address?.CachedDisplayName}}</span>
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="center center">
    <button mat-icon-button (click)="openCallPanel($event)">
      <mat-icon *ngIf="myExtension?.Connection?.Status == Dialing" style="color:white">
        dialpad
      </mat-icon>
      <mat-icon *ngIf="myExtension?.Connection?.Status == Ringing" style="color:orange">
        notification_important
      </mat-icon>
      <mat-icon *ngIf="myExtension?.Connection?.Status == Connected" style="color:white">
        call
      </mat-icon>
      <mat-icon *ngIf="!isInCall" style="color:red">
        call_end
      </mat-icon>
      <mat-icon *ngIf="myExtension?.Connection?.Status == Hold || myExtension.Connection?.Status == Held" style="color:white">
        pause
      </mat-icon>
    </button>
  </div>

</div>
