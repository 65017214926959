import angular, { IPromise, IQService, ISCEService, IScope, ITimeoutService } from 'angular';
import moment from 'moment';
import { ColumnDefinition, HestiaDataTable } from 'src/ajs/Directives/HestiaDataTableDirective';
import { DictEntryDto, EntryDto } from 'src/ajs/Models/Document/EntryDto';
import { addNavigation, cloneObject, convertDate, formatDate, formatDateShort } from 'src/ajs/Utils/HestiaCommon';
import { ArrayUtils } from 'src/app/shared/utils/array-utils';
import { addDefaultScopeFunctions } from '../Functions/add-default-scope-functions';
import { CategoryColumnDto } from '../Models/Document/CategoryColumnDto';
import { CategoryDto } from '../Models/Document/CategoryDto';
import { CustomColumnDto } from '../Models/Document/CustomColumnDto';
import { FolderDto } from '../Models/Document/FolderDto';
import { GetDocumentsEnvironmentDto } from '../Models/Document/GetDocumentsEnvironmentDto';
import { LabelPrintPresetDto } from '../Models/Document/LabelPrintPresetDto';
import { NumberRangeDto } from '../Models/Document/NumberRangeDto';
import { QueryTagResultModel } from '../Models/Document/TagDto';
import { QueryAddressDto } from '../Models/Erp/QueryAddressDto';
import { ThemeDto } from '../Models/ThemeDto';
import { ApiHandlerService } from '../Services/ApiHandlerService';
import { DocumentsService } from '../Services/DocumentsService';
import { StorageService, StorageServiceInstance } from '../Services/StorageService';
import { INavigationScope } from '../Utils/INavigationScope';
import { MandatorService } from 'src/app/core/services/mandator.service';


createDocumentsCrontroller.$inject = ['$scope', '$sce', '$mdDialog', '$mdToast', '$q', '$timeout', 'ApiHandlerService', 'StorageService', 'DocumentsService', 'theme', 'MandatorServiceNgx'];

export function createDocumentsCrontroller(
  $scope: IDocumentsCrontrollerScope,
  $sce: ISCEService,
  $mdDialog: angular.material.IDialogService,
  $mdToast: angular.material.IToastService,
  $q: IQService,
  $timeout: ITimeoutService,
  ApiHandlerService: ApiHandlerService,
  StorageService: StorageService,
  DocumentsService: DocumentsService,
  theme: ThemeDto,
  mandatorServiceNgx: MandatorService
) {
  addDefaultScopeFunctions($scope, theme);
  $scope.IsLoading = true;
  $scope.Service = DocumentsService;
  $scope.SelectedDocument = null;
  $scope.Storage = StorageService.CreateInstance("DocumentsController");
  $scope.Filter = $scope.Storage.GetOrCreate("Filter", {
    ShowNew: true,
    ShowProcessing: true,
    ShowTagging: true,
    ShowFinished: true
  });
  $scope.Filter.Toggle = function (path: any) {
    this[path] = !this[path];
    $scope.Storage.Set("Filter", this);
    $scope.UpdateFolders();
    $scope.DocumentTree.SelectNode(null, $scope.DocumentTree.SelectedNode);
  };
  $scope.IsAdminView = false;
  $(document).keyup(function (e) {
    if (e.key === "Escape") {
      $scope.SelectedDocument = null;
      $scope.$apply();
    }
  });

  mandatorServiceNgx.selectedMandatorIdChanged.subscribe(() => {
    $scope.LoadData();
  });

  addNavigation($scope, "overview");
  $scope.DocumentTree = {
    Data: [],
    IsLoading: true,
    SelectedNode: null,
    Initialize: function () {
      const deferred = $q.defer();
      const object = $scope.DocumentTree;
      object.Data = [
        {
          FolderName: "DMS Scan Umgebung",
          Icon: "scanner",
          IsOpen: false,
          Entries: [],
          Subfolders: []
        } as FolderDto, {
          FolderName: "Kategorien",
          Icon: "category",
          IsOpen: true,
          Entries: [],
          Subfolders: []
        } as FolderDto
      ];
      if ($scope.Model) {
        $scope.Model.Folders.forEach(folder => {
          folder.IsScanFolder = true;
          object.Data[0].Subfolders.push(folder);
        });
        $scope.Model.Categories.forEach(category => {
          category.FolderName = category.Description;
          category.IsCategory = true;
          category.Entries = [];
          object.Data[1].Subfolders.push(<FolderDto><unknown>category);
        });
        // Parent-Referenzen Setzen
        object.SetParentNodes(object.Data, null);
        if (object.Data.length) {
          object.SelectNode(null, object.Data[0]);
        }
        object.IsLoading = false;
      }
    },
    ShowContext: function (node: FolderDto) {
      this.ContextTitle = node.FolderName;
    },
    GetFlatFolders: function (root: FolderDto) {
      const object = $scope.DocumentTree;
      const result = [];
      if (!root) {
        return this.GetFlatFolders(this.Data[0]).concat(this.GetFlatFolders(this.Data[1]));
      } else {
        if (root.Id) {
          result.push(root);
        }
        if (root.Subfolders) {
          root.Subfolders.forEach((value) => {
            object.GetFlatFolders(value).forEach((entry) => {
              result.push(entry);
            });
          });
        }
        return result;
      }
    },
    SetParentNodes: function (nodes: FolderDto[], parent: FolderDto) {
      if (nodes) {
        for (let i = 0; i < nodes.length; i++) {
          nodes[i].Parent = parent;
          this.SetParentNodes(nodes[i].Subfolders, nodes[i]);
        }
      }
    },
    UnselectAllChildren: function (root: FolderDto[]) {
      if (root) {
        for (let i = 0; i < root.length; i++) {
          root[i].IsSelected = false;
          this.UnselectAllChildren(root[i].Subfolders);
        }
      }
    },
    SelectNode: function ($event, node: FolderDto, settings) {
      // Unselect all

      this.UnselectAllChildren($scope.DocumentTree.Data);
      $scope.ActiveTagText = node.FolderName;
      node.IsSelected = true;
      this.SelectedNode = node;
      let table = $scope.GeneralDocumentsTable;
      if (node.TableDefinition) {
        table = node.TableDefinition;
      }
      $scope.DocumentsTable = null;
      $timeout(() => {
        $scope.FilteredEntries = node.Entries.slice();
        $scope.DocumentsTable = table;
        $scope.DocumentsTable.UpdateSource($scope.FilteredEntries, $timeout);
        $scope.DocumentsTable.Update();
      });

    }
  } as DocumentTreeModel;


  $scope.DocumentTree.Initialize();

  $scope.DownloadItem = function (item: EntryDto) {
    const link = document.createElement("a");
    link.setAttribute("type", "hidden");
    link.href = "/api/FileDownload/DownloadFile/" + item.FileId;
    link.download = item.FileName;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  $scope.ItemSelected = function (item: EntryDto, ignore: boolean) {
    $scope.SelectedDocument = cloneObject(item, ["Category"]);
    const onFaded = function () {
      // Prevent loop on category
      if ($scope.SelectedDocument.CategoryId) {
        $scope.SelectedDocument.Category = $scope.Model.Categories.filter(f => f.Id === $scope.SelectedDocument.CategoryId)[0];
      }
      $scope.DocumentUrl = $sce.trustAsResourceUrl("/Api/FileDownload/GetPdf/" + item.FileId);
      if (ignore !== true) {
        $timeout(() => {
          $scope.ScrollIntoView(item);
        });
      }
      /*$(".PdfViewContainer").css("visibility", "hidden");
      $scope.ValidateForm();
      $timeout(() => {
          $(".documentDataContainer").fadeIn(250);
      });*/
    };
    onFaded();
    /*if ($(".documentDataContainer").length)
        $(".documentDataContainer").fadeOut(250, onFaded);
    else
        onFaded();*/
  };
  $scope.SearchAddress = function (query: string) {
    let result = null;
    const deferred = $q.defer();
    const request = ApiHandlerService.SendRequest("Erp", "QueryAddress", { query: query, mandatorId: mandatorServiceNgx.selectedMandatorId }).then(function (data: QueryAddressDto) {
      result = [];
      data.Result.forEach(f => {
        result.push(f.CachedAddress);
      });
      deferred.resolve(result);
    });
    return deferred.promise;
  };
  $scope.ScrollIntoView = function (item: EntryDto) {
    const parent = $('#DocumentScrollContainer');
    const element = $('#Document' + item.Id);
    const topPos = element[0].offsetTop;
    parent[0].scrollTop = topPos - parent[0].offsetTop;
  };

  $scope.IsEntryInFolder = function (folder: FolderDto | CategoryDto, entry: EntryDto) {
    if (folder) {
      let isIn = false;
      if (folder.IsScanFolder) {
        isIn = entry.SourceFolderId === folder.Id && !entry.CategoryId;
      }
      if (folder.IsCategory) {
        isIn = entry.CategoryId === folder.Id;
      }
      if (isIn) {
        if (!$scope.Filter.ShowNew && entry.State === 0) {
          isIn = false;
        }
        if (!$scope.Filter.ShowProcessing && entry.State === 1) {
          isIn = false;
        }
        if (!$scope.Filter.ShowTagging && entry.State === 2) {
          isIn = false;
        }
        if (!$scope.Filter.ShowFinished && entry.State === 3) {
          isIn = false;
        }
      }
      return isIn;
    }
    return false;
  };
  $scope.DefaultCompare = function (a, b, columnId) {
    let aValue = "";
    let bValue = "";
    if (a && a.value && a.value[columnId] && a.value[columnId].Value) {
      aValue = a.value[columnId].Value;
    }
    if (b && b.value && b.value[columnId] && b.value[columnId].Value) {
      bValue = b.value[columnId].Value;
    }
    if (aValue === "") {
      return 1;
    }
    if (bValue === "") {
      return -1;
    }
    return aValue.localeCompare(bValue);
  };
  $scope.FormatColumnValue = function (value: any, model, column) {
    if (model.Values[column.ColumnId]) {
      if (column.Column.Type === "Decimal") {
        return parseFloat(model.Values[column.ColumnId].Value).toFixed(2);
      } else if (column.Column.Type === "Date") {
        return moment(model.Values[column.ColumnId].Value).format("DD.MM.YYYY");
      }
      else {
        return model.Values[column.ColumnId].Value;
      }
    } else {
      return null;
    }
  };
  $scope.UpdateFolders = function () {
    const folders = $scope.DocumentTree.GetFlatFolders();
    folders.forEach(folder => {
      if (folder.IsCategory) {
        if (!folder.TableDefinition) {
          folder.TableDefinition = new HestiaDataTable(StorageService.CreateInstance(folder.Id + "_DocumentsTable"), $timeout, $scope);
          folder.TableDefinition.ItemClicked = $scope.ItemSelected;
          const columns = [
            new ColumnDefinition({ PropertyPath: 'Status', Heading: 'Status', HideHeading: true, ColumnWidth: 35, Resizeable: false, Sortable: true, Template: "/ClientApp/src/ajs/Views/Documents/TableTemplates/StateTemplate.htm" }),
            new ColumnDefinition({ PropertyPath: 'LabelNumber', Heading: 'Label', Resizeable: true, Sortable: true }),
            new ColumnDefinition({ PropertyPath: 'SourceAddress', Heading: 'Quelle', Resizeable: true, Sortable: true, Template: "/ClientApp/src/ajs/Views/Common/TableTemplates/CachedAddressTemplate.htm" }),
            new ColumnDefinition({ PropertyPath: 'TargetAddress', Heading: 'Ziel', Resizeable: true, Sortable: true, Template: "/ClientApp/src/ajs/Views/Common/TableTemplates/CachedAddressTemplate.htm" }),
            new ColumnDefinition({ PropertyPath: 'ReceiptNumber', Heading: 'Belegnummer', Resizeable: true, Sortable: true }),
            new ColumnDefinition({ PropertyPath: 'FileName', Heading: 'Datei', IsVisible: false, Resizeable: true, Sortable: true }),
            new ColumnDefinition({ PropertyPath: 'Pages', Align: 'center', Heading: 'Seiten', Resizeable: true, Sortable: true }),
            new ColumnDefinition({ PropertyPath: 'DocumentDate', Heading: 'Datum', Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDateShort }),
            new ColumnDefinition({ PropertyPath: 'Created', Heading: 'Erstellt', IsVisible: false, Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDate }),
            new ColumnDefinition({ PropertyPath: 'Modified', Heading: 'Bearbeitet', IsVisible: false, Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDate })
          ];
          folder.Columns.forEach(column => {
            const columnDefinition = $scope.Model.CustomColumns.filter(f => f.Id === column.ColumnId)[0];
            let align = "left";
            if (columnDefinition.Type === "Number" || columnDefinition.Type === "Decimal") {
              align = "right";
            }
            let template = null;
            if (columnDefinition.Type === "Boolean") {
              template = "/ClientApp/src/ajs/Views/Common/BoolToCheckboxDisabledTemplate.htm";
            }
            columns.push(new ColumnDefinition({ PropertyPath: 'Values', Align: align, Heading: columnDefinition.Description, Template: template, Resizeable: true, Sortable: true, SortValueSelector: function (a, b) { return $scope.DefaultCompare(a, b, column.ColumnId); }, RenderFunction: function (value, model) { return $scope.FormatColumnValue(value, model, column); } }));
          });
          folder.TableDefinition.UpdateColumns(columns);
        }
      }
      folder.Entries = $scope.Model.Entries.filter(f => $scope.IsEntryInFolder(folder, f));
      folder.EntryCount = folder.Entries.length;
    });
  };
  $scope.GeneralDocumentsTable = new HestiaDataTable(StorageService.CreateInstance("DocumentsTable"), $timeout, $scope);
  $scope.DocumentsTable = $scope.GeneralDocumentsTable;
  $scope.DocumentsTable.ItemClicked = $scope.ItemSelected;
  $scope.DocumentsTable.UpdateColumns([
    new ColumnDefinition({ PropertyPath: 'Status', Heading: 'Status', HideHeading: true, ColumnWidth: 35, Resizeable: false, Sortable: true, Template: "/ClientApp/src/ajs/Views/Documents/TableTemplates/StateTemplate.htm" }),
    new ColumnDefinition({ PropertyPath: 'LabelNumber', Heading: 'Label', Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'SourceAddress', Heading: 'Quelle', Resizeable: true, Sortable: true, Template: "/ClientApp/src/ajs/Views/Common/TableTemplates/CachedAddressTemplate.htm" }),
    new ColumnDefinition({ PropertyPath: 'TargetAddress', Heading: 'Ziel', Resizeable: true, Sortable: true, Template: "/ClientApp/src/ajs/Views/Common/TableTemplates/CachedAddressTemplate.htm" }),
    new ColumnDefinition({ PropertyPath: 'ReceiptNumber', Heading: 'Belegnummer', Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'FileName', Heading: 'Datei', IsVisible: false, Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'Pages', Align: 'center', Heading: 'Seiten', Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'DocumentDate', Heading: 'Datum', Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDateShort }),
    new ColumnDefinition({ PropertyPath: 'Created', Heading: 'Erstellt', IsVisible: false, Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDate }),
    new ColumnDefinition({ PropertyPath: 'Modified', Heading: 'Bearbeitet', IsVisible: false, Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDate })
  ]);
  $scope.QueryTag = function (search: string) {
    if (search && search.toLowerCase()) {
      const deferred = $q.defer();
      ApiHandlerService.SendRequest("Document", "QueryTag", { query: search }).then(function (result: QueryTagResultModel) {
        deferred.resolve(result.Tags);
      });
      return deferred.promise;
    }
    return [];
  };
  $scope.TransformChip = function (chip: any) {
    if (chip.Id)
      return chip;
    else {
      return { Id: 0, Tag: chip };
    }
  };
  $scope.SetForm = function (form: any) {
    $scope.DocumentEditingForm = form;
  };
  $scope.ValidateForm = function () {
    $timeout(() => {
      $scope.DocumentEditingForm.$$controls.forEach(c => {
        c.$setDirty();
        c.$setTouched();
        c.$validate();
      });
    });
  };
  $scope.FinalizeDocument = function (document: EntryDto) {
    if (document) {
      const jsonDict: { [key: string]: any } = {};
      if (document.Values.forEach) {
        document.Values.forEach((value: DictEntryDto) => {
          jsonDict[value.key] = value.value;
          if (value.value.DateValue) {
            value.value.DateValue = moment(value.value.DateValue);
          }
        });
      }
      document.DocumentDate = moment(document.DocumentDate);
      if (document.ValidUntil) {
        document.ValidUntil = moment(document.ValidUntil);
      }
      document.Values = jsonDict;
      document.CreatedFormatted = moment(document.Created).format("DD.MM.YYYY HH:mm:ss");
      document.ModifiedFormatted = moment(document.Modified).format("DD.MM.YYYY HH:mm:ss");
      const category = $scope.Model.Categories.filter(f => f.Id === document.CategoryId);
      if (category.length) {
        document.Category = category[0];
      }
    }
    return document;
  };
  $scope.CategoryChanged = function () {
    if ($scope.SelectedDocument.CategoryId) {
      $scope.SelectedDocument.Category = $scope.Model.Categories.filter(f => f.Id === $scope.SelectedDocument.CategoryId)[0];
    } else {
      $scope.SelectedDocument.Category = null;
    }
    $scope.ValidateForm();
  };
  $scope.DeleteDocument = function ($event: any) {
    const doc = cloneObject($scope.SelectedDocument, ['Category']);
    const dummyValues = [];
    for (const key in doc.Values) {
      const entry = { 'key': key, 'value': doc.Values[key] };
      entry.value.ColumnId = parseInt(key);
      entry.value.Type = $scope.Model.CustomColumns.filter(f => f.Id === entry.value.ColumnId)[0].Type;
      dummyValues.push(entry);
    }
    doc.Values = dummyValues;
    const confirm = $mdDialog.prompt()
      .title('Dokument löschen')
      .textContent('Bitte geben Sie einen Grund für das Löschen des Dokuments an.')
      .placeholder('Löschgrund')
      .ariaLabel('Löschgrund')
      .targetEvent($event)
      .required(true)
      .ok('Löschen')
      .cancel('Abbrechen');

    $mdDialog.show(confirm).then(function (result) {
      ApiHandlerService.SendRequest("Document", "DeleteDocument", { model: doc, reason: result, mandatorId: mandatorServiceNgx.selectedMandatorId }).then(function (result) {
        $scope.ValidateForm();
        const toast = $mdToast.simple()
          .textContent("Dokument wurde erfolgreich gelöscht.")
          .position('bottom right')
          .capsule(true);
        $mdToast.show(toast);
      });
    }, function () {

    });

  };
  $scope.SaveDocument = function () {
    const doc = cloneObject($scope.SelectedDocument, ["Category"]);
    const dummyValues = [];
    for (const key in doc.Values) {
      const entry = { 'key': key, 'value': doc.Values[key] };
      entry.value.ColumnId = parseInt(key);
      entry.value.Type = $scope.Model.CustomColumns.filter(f => f.Id === entry.value.ColumnId)[0].Type;
      dummyValues.push(entry);
    }
    doc.Values = dummyValues;
    ApiHandlerService.SendRequest("Document", "SaveDocument", { model: doc, mandatorId: mandatorServiceNgx.selectedMandatorId }).then(function (result) {
      if ($scope.SelectedDocument) {
        $scope.SelectedDocument = $scope.FinalizeDocument(result);
        $scope.ValidateForm();
        const toast = $mdToast.simple()
          .textContent("Dokument wurde erfolgreich gespeichert.")
          .position('bottom right')
          .capsule(true);
        $mdToast.show(toast);
      }
    });
  };
  $scope.SelectNext = function () {
    if ($scope.SelectedDocument.Id) {
      $scope.FilteredEntries.sort(function (a, b) { return a.CurrentIndex - b.CurrentIndex; });
      const foundElement = $scope.FilteredEntries.filter(f => f.Id === $scope.SelectedDocument.Id);
      if (foundElement.length) {
        const newIndex = $scope.FilteredEntries.indexOf(foundElement[0]) + 1;
        if (newIndex < $scope.FilteredEntries.length) {
          $scope.ItemSelected($scope.FilteredEntries[newIndex]);
        } else {
          if ($scope.FilteredEntries.length === 1) {
            $scope.NavigateBack();
          }
          else {
            $scope.ItemSelected($scope.FilteredEntries[0]);
          }
        }
      }
    }
  };
  $scope.DocumentChanged = function (type: string, document: EntryDto) {
    $scope.FinalizeDocument(document);
    $timeout(() => {
      if (type === "add") {
        $scope.Model.Entries.push(document);
        if ($scope.IsEntryInFolder($scope.DocumentTree.SelectedNode, document)) {
          $scope.FilteredEntries.push(document);
        }
      }
      if (type === "update") {
        const ex = $scope.Model.Entries.filter(f => f.Id === document.Id)[0];
        const inFilter = $scope.FilteredEntries.filter(f => f.Id === document.Id);
        if (inFilter.length) {
          if (!$scope.IsEntryInFolder($scope.DocumentTree.SelectedNode, document)) {
            if ($scope.SelectedDocument && document.Id === $scope.SelectedDocument.Id) {
              $scope.SelectNext();
            }
            ArrayUtils.remove($scope.FilteredEntries, inFilter[0]);
          } else if ($scope.SelectedDocument && document.Id === $scope.SelectedDocument.Id) {
            $scope.ItemSelected(document);
          }
        }
        for (const key in document) {
          ex[key] = document[key];
        }
      }
      if (type === "delete") {
        const ex = $scope.Model.Entries.filter(f => f.Id === document.Id)[0];
        const inFilter = $scope.FilteredEntries.filter(f => f.Id === document.Id);
        if (inFilter.length) {
          if ($scope.SelectedDocument && document.Id === $scope.SelectedDocument.Id) {
            $scope.SelectNext();
          }
          ArrayUtils.remove($scope.FilteredEntries, inFilter[0]);
        }
        ArrayUtils.remove($scope.Model.Entries, ex);
      }
      $scope.UpdateFolders();
    });
  };
  $scope.LoadData = function () {
    ApiHandlerService.SendRequest("Document", "GetDocumentsEnvironment", { mandatorId: mandatorServiceNgx.selectedMandatorId }).then(function (data: GetDocumentsEnvironmentDto) {
      $scope.Model = data; /*GetDocumentsEnvironmentDto*/
      $scope.Model.Entries.forEach(f => $scope.FinalizeDocument(f));
      $scope.Model.Categories.forEach(cat => {
        cat.Columns.forEach((col: CategoryColumnDto) => {
          col.Column = $scope.Model.CustomColumns.filter(f => f.Id === col.ColumnId)[0];
        });
      });
      $scope.DocumentTree.Initialize();
      $scope.Service.DocumentChangesEvent($scope.DocumentChanged);
      $scope.UpdateFolders();
      $scope.IsLoading = false;
    });
  };

  $scope.ReorderCategoriesUi = function () {
    for (let i = 0; i < $scope.Model.Categories.length; i++) {
      $scope.Model.Categories[i].SortOrder = i;
    }
    $scope.ReorderCategories();
  };

  $scope.ReorderCategories = function () {
    const sendModel = [];
    $scope.Model.Categories.forEach(c => sendModel.push({ Id: c.Id, SortOrder: c.SortOrder }));
    ApiHandlerService.SendRequest("Document", "UpdateCategoryOrdering", { models: sendModel }).then(function (data) {

    });
  };

  $scope.ReorderPrintPresetsUi = function () {
    for (let i = 0; i < $scope.Model.LabelPrintPresets.length; i++) {
      $scope.Model.LabelPrintPresets[i].SortOrder = i;
    }
    $scope.ReorderPrintPresets();
  };

  $scope.ReorderPrintPresets = function () {
    ApiHandlerService.SendRequest("Document", "UpdatePrintPresetOrdering", { models: $scope.Model.LabelPrintPresets }).then(function (data) {

    });
  };

  $scope.RemoveCategory = function (category: any) {
    ApiHandlerService.SendRequest("Document", "RemoveCategory", { model: category }).then(function (data: GetDocumentsEnvironmentDto) {
      $scope.Model = data;
      $scope.DocumentTree.Initialize();
      $scope.IsLoading = false;
    });
  };

  $scope.ChangeView = function () {
    $scope.IsAdminView = !$scope.IsAdminView;
    if ($scope.IsAdminView) {
      $scope.ActiveTagText = "Administration";
    } else {
      $scope.ActiveTagText = $scope.DocumentTree.SelectedNode.FolderName;
      $scope.NavigateBack();
      $scope.LoadData();
    }
  };
  $scope.EditLabelPrintPreset = function (preset: any, event: any) {
    $mdDialog.show({
      controller: 'EditLabelPrintPresetController',
      templateUrl: '/ClientApp/src/ajs/Views/Documents/Dialogs/EditLabelPrintPresetDialog.htm',
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        Ranges: $scope.Model.NumberRanges,
        Categories: $scope.Model.Categories,
        OnSuccess: (data) => {
          if (data === null) {
            $scope.Model.LabelPrintPresets = $scope.Model.LabelPrintPresets.filter(f => f.Id !== preset.Id);
            if ($scope.SelectCategory) {
              $scope.SelectViewCategory($scope.SelectCategory);
            }
          } else {
            $scope.Model.LabelPrintPresets.forEach((preset, index) => {
              if (preset.Id === data.Id) {
                $scope.Model.LabelPrintPresets[index] = data;
                if ($scope.SelectCategory) {
                  $scope.SelectViewCategory($scope.SelectCategory);
                }
              }
            });
          }
        },
        Element: cloneObject(preset)
      },
      clickOutsideToClose: false,
      fullscreen: true
    });
  };
  $scope.AddLabelPrintPreset = function (event: any) {
    $mdDialog.show({
      controller: 'EditLabelPrintPresetController',
      templateUrl: '/ClientApp/src/ajs/Views/Documents/Dialogs/EditLabelPrintPresetDialog.htm',
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        Ranges: $scope.Model.NumberRanges,
        Categories: $scope.Model.Categories,
        OnSuccess: (data) => {
          $scope.Model.LabelPrintPresets.push(data);
          $scope.ReorderPrintPresetsUi();
          if ($scope.SelectCategory) {
            $scope.SelectViewCategory($scope.SelectCategory);
          }
        },
        Element: null
      },
      clickOutsideToClose: false,
      fullscreen: true
    });
  };
  $scope.EditCategory = function (category: CategoryDto, event: any) {
    $mdDialog.show({
      controller: 'EditDocumentsCategoryDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/Documents/Dialogs/EditDocumentsCategoryDialog.htm',
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        OnSuccess: (data) => {
          $scope.Model.Categories.forEach((category, index) => {
            if (category.Id === data.Id) {
              $scope.Model.Categories[index] = data;
            }
          });
        },
        Element: cloneObject(category, ['Entries', 'TableDefinition']),
        ColumnDefinitions: $scope.Model.CustomColumns
      },
      clickOutsideToClose: false,
      fullscreen: true
    });
  };
  $scope.AddCategory = function (event: any) {
    $mdDialog.show({
      controller: 'EditDocumentsCategoryDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/Documents/Dialogs/EditDocumentsCategoryDialog.htm',
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        OnSuccess: (data) => {
          $scope.Model.Categories.push(data);
          $scope.ReorderCategoriesUi();
        },
        Element: null,
        ColumnDefinitions: $scope.Model.CustomColumns
      },
      clickOutsideToClose: false,
      fullscreen: true
    });
  };
  $scope.EditNumberRange = function (range: NumberRangeDto, event: any) {
    $mdDialog.show({
      controller: 'EditNumberRangeDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/Documents/Dialogs/EditNumberRangeDialog.htm',
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        Categories: $scope.Model.Categories,
        OnSuccess: (data) => {
          $scope.Model.NumberRanges.forEach((range, index) => {
            if (range.Id === data.Id) {
              $scope.Model.NumberRanges[index] = data;
            }
          });
        },
        Element: cloneObject(range, ["AllowedCategories"])
      },
      clickOutsideToClose: false,
      fullscreen: true
    });
  };
  $scope.AddNumberRange = function (event: any) {
    $mdDialog.show({
      controller: 'EditNumberRangeDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/Documents/Dialogs/EditNumberRangeDialog.htm',
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        Categories: $scope.Model.Categories,
        OnSuccess: (data) => $scope.Model.NumberRanges.push(data),
        Element: null
      },
      clickOutsideToClose: false,
      fullscreen: true
    });
  };

  $scope.EditCustomColumn = function (column: CustomColumnDto, event: any) {
    $mdDialog.show({
      controller: 'EditCustomColumnDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/Documents/Dialogs/EditCustomColumnDialog.htm',
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        OnSuccess: (data) => {
          $scope.Model.CustomColumns.forEach((column, index) => {
            if (column.Id === data.Id) {
              $scope.Model.CustomColumns[index] = data;
            }
          });
        },
        Element: cloneObject(column)
      },
      clickOutsideToClose: false,
      fullscreen: true
    });
  };
  $scope.AddCustomColumn = function (event: any) {
    $mdDialog.show({
      controller: 'EditCustomColumnDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/Documents/Dialogs/EditCustomColumnDialog.htm',
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        OnSuccess: (data) => $scope.Model.CustomColumns.push(data),
        Element: null
      },
      clickOutsideToClose: false,
      fullscreen: true
    });
  };
  $scope.SelectViewCategory = function (category: CategoryDto) {
    $scope.Model.Categories.forEach(f => f.IsSelected = false);
    $scope.SelectCategory = category;
    $scope.SelectCategory.IsSelected = true;
    $scope.CategoryPrinterPresets = $scope.Model.LabelPrintPresets.filter(f => f.DocumentsCategoryId === category.Id);
  };

  $scope.OpenPrintDialog = function (event: any) {
    $mdDialog.show({
      controller: 'PrintDocumentLabelDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/Documents/Dialogs/PrintDocumentLabelDialog.htm',
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        Categories: $scope.Model.Categories,
        Presets: $scope.Model.LabelPrintPresets,
        Printing: $scope.Model.Printing,
        Ranges: $scope.Model.NumberRanges
      },
      clickOutsideToClose: false,
      fullscreen: true
    });
  };
  $scope.NavigateBack = function () {
    //$scope.DocumentsTable.UpdateSource($scope.FilteredEntries, $timeout);
    $scope.SelectedDocument = null;
  };
  $scope.PadLeft = function (number, length: number) {
    return (Array(length + 1).join('0') + number).slice(-length);
  };

  $scope.LoadData();
  $scope.Navigate("Overview", true);
}

interface IDocumentsCrontrollerScope extends IScope, INavigationScope {
  IsLoading: boolean;
  Service: DocumentsService;
  SelectedDocument
  Storage: StorageServiceInstance;
  Filter: any;
  DocumentTree: DocumentTreeModel;
  IsAdminView: boolean;
  Model: GetDocumentsEnvironmentDto;
  ActiveTagText: string;
  GeneralDocumentsTable: HestiaDataTable;
  DocumentsTable: HestiaDataTable;
  FilteredEntries: EntryDto[];
  DocumentUrl: any;
  DocumentEditingForm: any;
  SelectCategory: CategoryDto;
  CategoryPrinterPresets: LabelPrintPresetDto[];

  UpdateFolders: () => void;
  DownloadItem: (item: EntryDto) => void;
  ItemSelected: (item: EntryDto, ignore?: boolean) => void;
  ScrollIntoView: (item: EntryDto) => void;
  SearchAddress: (query: string) => IPromise<unknown>;
  IsEntryInFolder: (folder: FolderDto | CategoryDto, entry: EntryDto) => boolean;
  DefaultCompare: (a, b, columnId) => number;
  FormatColumnValue: (value: any, model: any, column: any) => any;
  QueryTag: (search: string) => any[] | IPromise<unknown>;
  TransformChip: (chip: any) => { Id: number, Tag: any };
  SetForm: (form: any) => void;
  ValidateForm: () => void;
  FinalizeDocument: (document: EntryDto) => EntryDto;
  CategoryChanged: () => void;
  DeleteDocument: ($event: any) => void;
  SaveDocument: () => void;
  SelectNext: () => void;
  NavigateBack: () => void;
  DocumentChanged: (type: string, document: EntryDto) => void;
  LoadData: () => void;
  ReorderCategoriesUi: () => void;
  ReorderCategories: () => void;
  ReorderPrintPresetsUi: () => void;
  ReorderPrintPresets: () => void;
  RemoveCategory: (category: any) => void;
  ChangeView: () => void;
  EditLabelPrintPreset: (preset: any, event: any) => void;
  AddLabelPrintPreset: (event: any) => void;
  EditCategory: (category: CategoryDto, event: any) => void;
  AddCategory: (event: any) => void;
  EditNumberRange: (range: NumberRangeDto, event: any) => void;
  AddNumberRange: (event: any) => void;
  EditCustomColumn: (column: CustomColumnDto, event: any) => void;
  AddCustomColumn: (event: any) => void;
  SelectViewCategory: (category: CategoryDto) => void;
  OpenPrintDialog: (event: any) => void;
  PadLeft: (number, length: number) => void

}


interface DocumentTreeModel {

  SelectedNode: FolderDto | CategoryDto;
  Data: FolderDto[];
  IsLoading: boolean;
  ContextTitle: string;

  SelectNode: ($event: any, node: FolderDto | CategoryDto, settings?: any) => void;
  Initialize: () => void;
  SetParentNodes: (nodes: FolderDto[], parent: FolderDto) => void;
  ShowContext: (node: FolderDto) => void;
  GetFlatFolders: (root?: FolderDto) => any[];
  UnselectAllChildren: (root: FolderDto[]) => void;
}
