import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';

// Haupteinstiegspunkt von AngularJS
import './ajs/main';
import { getBaseUrlApi1, getBaseUrlApi2, getBaseUrlSignalR } from './environments/factories';


const BASE_URL_API1 = getBaseUrlApi1();
const BASE_URL_API2 = getBaseUrlApi2();
const BASE_URL_SIGNALR = getBaseUrlSignalR();

console.log('BASE_URL_API1', BASE_URL_API1);
console.log('BASE_URL_API2', BASE_URL_API2);
console.log('BASE_URL_SIGNALR', BASE_URL_SIGNALR);

export const providers = [
  { provide: 'BASE_URL_API1', useValue: BASE_URL_API1, deps: [] },
  { provide: 'BASE_URL_API2', useValue: BASE_URL_API2, deps: [] },
  { provide: 'BASE_URL_SIGNALR', useValue: BASE_URL_SIGNALR, deps: [] }
];

if (environment.production) {
  enableProdMode();
}

// Bootstrapping wird in HestiaApp.boot gemacht
// platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
