import { IPromise, IQService, IScope, ITimeoutService, material } from "angular";
import { AddressResultDto } from "src/ajs/Models/Contracts/AddressResultDto";
import { AddressDto } from "src/ajs/Models/Document/AddressDto";
import { CategoryDto } from "src/ajs/Models/Document/CategoryDto";
import { NumberRangeDto } from "src/ajs/Models/Document/NumberRangeDto";
import { QueryAddressDto } from "src/ajs/Models/Erp/QueryAddressDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { LabelPrintPresetDto } from "../../Models/Document/LabelPrintPresetDto";
import { CachedAddressDto } from "../../Models/Erp/CachedAddressDto";
import { MandatorService } from "src/app/core/services/mandator.service";

export class EditLabelPrintPresetController {
  static $inject = ['$scope', '$timeout', '$q', '$mdDialog', 'ApiHandlerService', 'Categories', 'Ranges', 'Element', 'OnSuccess', 'MandatorServiceNgx'];

  constructor(
    private readonly $scope: IEditLabelPrintPresetControllerScope,
    private readonly $timeout: ITimeoutService,
    private readonly $q: IQService,
    private readonly $mdDialog: material.IDialogService,
    private readonly ApiHandlerService: ApiHandlerService,
    Categories: CategoryDto[],
    Ranges: NumberRangeDto[],
    Element: LabelPrintPresetDto,
    private readonly OnSuccess: (data: LabelPrintPresetDto) => void,
    private readonly MandatorServiceNgx: MandatorService
  ) {
    $scope.Title = "Druckvorlage bearbeiten";
    $scope.Model = Element;
    $scope.Categories = Categories;
    $scope.Ranges = Ranges;
    $scope.OnSuccess = OnSuccess;

    $scope.RangeChanged = this.RangeChanged.bind(this);
    $scope.ValidateForm = this.ValidateForm.bind(this);
    $scope.SourceChanged = this.SourceChanged.bind(this);
    $scope.TargetChanged = this.TargetChanged.bind(this);
    $scope.SearchAddress = this.SearchAddress.bind(this);
    $scope.Close = this.Close.bind(this);
    $scope.CleanModel = this.CleanModel.bind(this);
    $scope.Delete = this.Delete.bind(this);
    $scope.Submit = this.Submit.bind(this);
    $scope.ValidateForm = this.ValidateForm.bind(this);


    if (!$scope.Model) {
      $scope.Title = "Druckvorlage anlegen";
      $scope.Model = {
        Caption: null,
        DocumentsNumberRangeId: null,
        DocumentsCategoryId: null,
        SourceAddressId: null,
        TargetAddressId: null,
        CategoryRequired: true,
        SourceRequired: false,
        TargetRequired: false,
        CanChangeCategory: false,
        CanChangeSource: true,
        CanChangeTarget: true
      } as LabelPrintPresetDto;
    } else {
      if ($scope.Model.TargetAddressId) {
        ApiHandlerService.SendRequest("Erp", "GetAddress", { addressId: $scope.Model.TargetAddressId }).then(data => {
          $scope.SelectedTargetAddress = data;
        });
      }
      if ($scope.Model.SourceAddressId) {
        ApiHandlerService.SendRequest("Erp", "GetAddress", { addressId: $scope.Model.SourceAddressId }).then(data => {
          $scope.SelectedSourceAddress = data;
        });
      }
    }
  }

  RangeChanged() {
    const range = this.$scope.Ranges.filter(f => f.Id === this.$scope.Model.DocumentsNumberRangeId);
    if (range.length) {
      this.$scope.SelectedRange = range[0];
      this.$scope.SelectedRange.AllowedCategories = [];
      this.$scope.SelectedRange.AllowedCategoryIds.forEach(f => {
        this.$scope.SelectedRange.AllowedCategories.push(this.$scope.Categories.filter(x => x.Id === f)[0]);
      });
    } else {
      this.$scope.SelectedRange = null;
    }
  }

  SourceChanged(address: CachedAddressDto) {
    if (address)
      this.$scope.Model.SourceAddressId = address.Id;
    else
      this.$scope.Model.SourceAddressId = null;
  }

  TargetChanged(address: CachedAddressDto) {
    if (address)
      this.$scope.Model.TargetAddressId = address.Id;
    else
      this.$scope.Model.TargetAddressId = null;
  }

  SearchAddress(query: string): IPromise<AddressResultDto[]> {
    const result: AddressResultDto[] = [];
    const deferred = this.$q.defer<AddressResultDto[]>();
    const request = this.ApiHandlerService.SendRequest("Erp", "QueryAddress", { query: query, mandatorId: this.MandatorServiceNgx.selectedMandatorId }).then((data: QueryAddressDto) => {
      data.Result.forEach(f => {
        result.push(f.CachedAddress);
      });
      deferred.resolve(result);
    });
    return deferred.promise;
  }

  Close() {
    this.$mdDialog.hide();
  }

  CleanModel() {
    if (this.$scope.Model.DocumentsCategoryId) {
      this.$scope.Model.DocumentsCategoryId = this.$scope.Model.DocumentsCategoryId;
    }
    this.$scope.Model.DocumentsNumberRangeId = this.$scope.Model.DocumentsNumberRangeId;
  }

  Delete() {
    this.CleanModel();
    this.ApiHandlerService.SendRequest("Document", "DeleteLabelPrintPreset", { model: this.$scope.Model, mandatorId: this.MandatorServiceNgx.selectedMandatorId }).then(result => {
      this.OnSuccess(null);
      this.$mdDialog.cancel();
    });
  }

  Submit() {
    if (!this.$scope.IsSubmiting) {
      this.$scope.IsSubmiting = true;
      this.CleanModel();
      this.ApiHandlerService.SendRequest("Document", "SaveLabelPrintPreset", { model: this.$scope.Model, mandatorId: this.MandatorServiceNgx.selectedMandatorId }).then(result => {
        this.OnSuccess(result);
        this.$mdDialog.cancel();
      });
    }
  }

  ValidateForm() {
    this.$timeout(() => {
      this.$scope.EditLabelPrintPresetDialogForm.$$controls.forEach(c => {
        c.$setDirty();
        c.$validate();
      });
    });
  }

}


interface IEditLabelPrintPresetControllerScope extends IScope {
  Title: string;
  Model: LabelPrintPresetDto;
  Categories: CategoryDto[];
  OnSuccess: (data: LabelPrintPresetDto) => void;
  EditLabelPrintPresetDialogForm: any;
  Ranges: NumberRangeDto[];
  SelectedTargetAddress: AddressDto;
  SelectedSourceAddress: AddressDto;
  SelectedRange: NumberRangeDto;
  IsSubmiting: boolean;

  RangeChanged: () => void;
  SourceChanged: (address: CachedAddressDto) => void;
  TargetChanged: (address: CachedAddressDto) => void;
  SearchAddress: (query: string) => IPromise<AddressResultDto[]>;
  Close: () => void;
  CleanModel: () => void;
  Delete: () => void;
  Submit: () => void;
  ValidateForm: () => void;

}
