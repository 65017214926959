import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MatTreeModule } from '@angular/material/tree';
import { FolderTreeComponent } from './components/folder-tree/folder-tree.component';
import { FileListComponent } from './components/file-list/file-list.component';
import { FileBrowserDialogComponent } from './components/file-browser-dialog/file-browser-dialog.component';
import { FileBrowserComponent } from './components/file-browser/file-browser.component';
import { BreadcrumbBarComponent } from './components/breadcrumb-bar/breadcrumb-bar.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatTreeModule
  ],
  declarations: [
    FileBrowserComponent,
    FolderTreeComponent,
    FileListComponent,
    FileBrowserDialogComponent,
    BreadcrumbBarComponent
  ]
})
export class FileBrowserModule { }
