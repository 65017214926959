import angular from "angular";
import { firstValueFrom, Subscription } from "rxjs";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { ContactDto } from "src/app/contacts/dto/contact-dto";
import { ContactPhoneNumberDto } from "src/app/contacts/dto/contact-phone-number-dto";
import { ContactsService } from '../../Services/ContactsService';

export class ContactsQuickSearchController {
  static $inject = ['$scope', '$mdPanel', 'mdPanelRef', '$timeout', 'ContactsService', 'theme'];

  Contacts: ContactDto[] = [];
  IsLoading = false;
  private searchSubscription: Subscription = null;

  constructor(
    public readonly $scope: any,
    private readonly $mdPanel: any,
    private readonly mdPanelRef: angular.material.IPanelRef,
    private readonly $timeout: angular.ITimeoutService,
    private readonly ContactsService: ContactsService,
    theme: ThemeDto
  ) {

    $scope.Theme = {
      Name: theme?.ThemeDescription,
      Definition: theme
    };

    this.$timeout(() => {
      const input = $("#contact-search-input");
      input[0].focus();
    }, 150);

    this.loadLastContacts();
  }

  Clear() {
    this.Contacts = [];
  }

  Close() {
    this.mdPanelRef.close();
  }

  SendSms($event: any, contact: ContactDto, number: ContactPhoneNumberDto) {
    this.$mdPanel.newPanelGroup("SmsSend", {
      maxOpen: 1
    });
    const config = {
      attachTo: angular.element(document.body),
      templateUrl: '/ClientApp/src/ajs/Views/SmsSend/SmsSendWidget.htm',
      controller: 'SmsSendParamsController',
      panelClass: 'window-panel-container',
      openFrom: $event,
      focusOnOpen: true,
      locals: {
        Contact: contact, // TODO!!
        Number: number
      },
      propagateContainerEvents: true,
      groupName: ["SmsSend"]
    };
    this.$mdPanel.open(config);
  }

  SearchTextChanged(text: string) {
    if (text) {
      this.IsLoading = true;

      this.searchSubscription?.unsubscribe();
      this.searchSubscription = this.ContactsService.getContactsBySearchText(text).subscribe(result => {
        this.Contacts = result;
        this.IsLoading = false;
      });
    }
    else {
      this.loadLastContacts();
    }
  }

  private async loadLastContacts() {
    this.IsLoading = true;
    this.Contacts = await firstValueFrom(this.ContactsService.getLastUsedContacts());
    this.IsLoading = false;
  }
}
