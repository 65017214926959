import { ITimeoutService } from "angular";
import { StorageService, StorageServiceInstance } from "../StorageService";
import { LocalizationData } from './LocalizationData';
import { LocalizationLanguage } from "./LocalizationLanguage";

/** Dieses Service ist eine einzige Katastrophe und sollte ersetzt werden, z.B. durch angular-translate. */
export class LocalizationService {
  static $inject = ['$timeout', 'StorageService'];

  readonly Storage: StorageServiceInstance;
  readonly Dict: { [key: string]: { [key: string]: { [key: string]: string } } };
  readonly Languages: LocalizationLanguage[];
  readonly Fallback: string;
  UserLanguage: string;

  constructor(
    private readonly $timeout: ITimeoutService,
    private readonly StorageService: StorageService
  ) {
    this.Storage = StorageService.CreateInstance("LocalizationService");
    const data = this.createLocalizationData();

    this.Dict = data.Dicts;
    this.Languages = data.Languages;
    this.Fallback = data.Fallback;

    const storedCode = this.Storage.GetOrCreate("LanguageCode", null);
    if (!(storedCode && this.SetUserLanguage(storedCode))) {
      let browserCode = navigator.language || navigator['userLanguage'];
      if (browserCode.includes("-")) {
        browserCode = browserCode.substring(0, browserCode.indexOf("-"));
      }
      browserCode = browserCode.toUpperCase();

      this.SetUserLanguage(browserCode);
    }
  }

  public SetUserLanguage(code: string) {
    if (this.Languages.filter(f => f.Code === code).length) {
      this.UserLanguage = code;
      this.Storage.Set("LanguageCode", code);
      return true;
    } else {
      this.UserLanguage = this.Fallback;
      this.Storage.Set("LanguageCode", this.Fallback);
      return false;
    }
  }

  public GetPluginDict(plugin: string) {
    return new PluginDict(this.Dict[plugin], this);
  }


  public ChangeLanguage(language: string) {
    this.$timeout(() => this.SetUserLanguage(language), null, true);
  }

  private createLocalizationData(): LocalizationData {
    const result: LocalizationData = {
      "Dicts": {
        "Core": {
          "DE": {
            "CancelButtonText": "Abbrechen",
            "EditTextHeading": "Text bearbeiten",
            "InputMandatorLabel": "Mandant",
            "SaveButtonText": "Speichern",
            "TextTableCurrentEntries": "Seite {0} von {1}",
            "TextTableEntreis": "Einträge pro Seite"
          },
          "EN": {
            "CancelButtonText": "Cancel",
            "EditTextHeading": "Edit text",
            "InputMandatorLabel": "Mandator",
            "SaveButtonText": "Save",
            "TextTableCurrentEntries": "Page {0} out of {1}",
            "TextTableEntreis": "Entries per page"
          }
        },
        "Dashboard": {
          "EN": {
            "LabelMessages": "Messages",
            "LabelWidgets": "Widgets",
            "TextAppointmentsTemplate": "Today: {0}, 7 days: {1}",
            "TextBirthdayShortDescription": "Today: {0}, 7 days: {1}, 31 days: {2}",
            "TextCake": "Today is cake-day",
            "TextInformationHubTemplate": "Uploads this week: {0}",
            "TextLoading": "Loading dashboard data...",
            "TextNotLoggedIn": "You are not logged in, log in to use all features.",
            "TextTodaysBirthday": "Today is {0} {1}'s birthday. Hurray!",
            "TextWelcome": "Welcome back, {0}",
            "TitleAppointmentsTemplate": "Current appointments",
            "TitleBirthdayTemplate": "Upcoming birthdays",
            "TitleInformationHubTemplate": "Most recent Documents"
          },
          "DE": {
            "LabelMessages": "Nachrichten",
            "LabelWidgets": "Widgets",
            "TextAppointmentsTemplate": "Heute: {0}, 7 Tage: {1}",
            "TextBirthdayShortDescription": "Heute: {0}, 7 Tage: {1}, 31 Tage: {2}",
            "TextCake": "Heute gibt es Torte",
            "TextInformationHubTemplate": "Neue Dokumente dieser Woche: {0}",
            "TextLoading": "Dashboard wird geladen...",
            "TextNotLoggedIn": "Du bist nicht angemeldet. Melde dich an um alle Funktionen verwenden zu können.",
            "TextTodaysBirthday": "{0} {1} hat heute Geburtstag. Hurra!",
            "TextWelcome": "Wilkommen zurück, {0}",
            "TitleAppointmentsTemplate": "Aktuelle Termine",
            "TitleBirthdayTemplate": "Bevorstehende Geburtstage",
            "TitleInformationHubTemplate": "Aktuelle Dokumente"
          }
        },
        "InformationHub": {
          "DE": {
            "HeadingInformationHub": "Informations Zentrum",
            "LabelAddFolder": "Öffentlichen Ordner hinzufügen",
            "LabelAddMailbox": "Postfach hinzufügen",
            "LabelAddTag": "Tag hinzufügen *",
            "LabelAllowDeleteSource": "Löschen erlauben",
            "LabelAllTags": "Alle Tags",
            "LabelArticleInformation": "Artikelinformation",
            "LabelAuthor": "Autor",
            "LabelBack": "Zurück",
            "LabelButtonPublish": "Veröffentlichen",
            "LabelButtonSave": "Speichern",
            "LabelCreated": "Erstellt",
            "LabelDeleteSource": "Quelle löschen",
            "LabelEditArticle": "Artikel bearbeiten",
            "LabelEdited": "Zuletzt geändert",
            "LabelEditingInformation": "Bearbeitungsinformation",
            "LabelEditRemoteSource": "Quelle bearbeiten",
            "LabelEditSource": "Quelle bearbeiten",
            "LabelExpired": "Abgelaufen",
            "LabelFindFolder": "Öffentlichen Ordner suchen",
            "LabelFindMailbox": "Postfach suchen",
            "LabelNewArticle": "Neuen Artikel anlegen",
            "LabelNoFolderFound": "Es wurde kein öffentlicher Ordner gefunden.",
            "LabelNoMailboxFound": "Es wurde kein Postfach gefunden.",
            "LabelNotPublished": "Nicht veröffentlicht",
            "LabelOverview": "Übersicht",
            "LabelPublicSource": "Öffentlich",
            "LabelPublishing": "Veröffentlichungsinformationen",
            "LabelShortDescription": "Kurzbeschreibung",
            "LabelSourceContext": "Artikelquellen",
            "LabelSourceDescription": "Quellenbezeichnung",
            "LabelSources": "Quellen",
            "LabelTags": "Tags",
            "LabelTitle": "Titel",
            "LabelValidFrom": "Gültig ab",
            "LabelValidUntil": "Gültig bis",
            "TableAuthor": "Autor",
            "TableCreated": "Erstellt am",
            "TableEdited": "Zuletzt geändert",
            "TablePublished": "Veröffentlicht",
            "TableTitle": "Titel",
            "TableValidFrom": "Gültig ab",
            "TableValidUntil": "Gültig bis",
            "TooltipEdit": "Bearbeiten",
            "TooltipNewArticle": "Neuen Artikel anlegen",
            "TooltipPublish": "Veröffentlichen"
          },
          "EN": {
            "HeadingInformationHub": "Information hub",
            "LabelAddFolder": "Add public folder",
            "LabelAddMailbox": "Add mailbox",
            "LabelAddTag": "Add tag *",
            "LabelAllowDeleteSource": "allow deleting",
            "LabelAllTags": "All tags",
            "LabelArticleInformation": "Article information",
            "LabelAuthor": "Author",
            "LabelBack": "Back",
            "LabelButtonPublish": "Publish",
            "LabelButtonSave": "Save",
            "LabelCreated": "Created",
            "LabelDeleteSource": "Delete source",
            "LabelEditArticle": "Editing article",
            "LabelEdited": "Last edited",
            "LabelEditingInformation": "Editing information",
            "LabelEditRemoteSource": "Find public folder",
            "LabelEditSource": "Edit source",
            "LabelExpired": "Expired",
            "LabelFindFolder": "Find public folder",
            "LabelFindMailbox": "Find mailbox",
            "LabelNewArticle": "Creating new article",
            "LabelNoFolderFound": "No public folder found.",
            "LabelNoMailboxFound": "No mailbox found.",
            "LabelNotPublished": "Not published",
            "LabelOverview": "Overview",
            "LabelPublicSource": "public",
            "LabelPublishing": "Publishing information",
            "LabelShortDescription": "Description",
            "LabelSourceContext": "Sources",
            "LabelSourceDescription": "Source description",
            "LabelSources": "Sources",
            "LabelTags": "Tags",
            "LabelTitle": "Title",
            "LabelValidFrom": "Valid from",
            "LabelValidUntil": "Valid to",
            "TableAuthor": "Author",
            "TableCreated": "Created",
            "TableEdited": "Last edited",
            "TablePublished": "Published",
            "TableTitle": "Title",
            "TableValidFrom": "Valid from",
            "TableValidUntil": "Valid to",
            "TooltipEdit": "Edit",
            "TooltipNewArticle": "Creat new article",
            "TooltipPublish": "Publish"
          }
        },
        "Login": {
          "EN": {
            "ButtonLogin": "Log in",
            "HeadingLogin": "Log in",
            "Input2FA": "Validation code (only for 2FA)",
            "InputLabelMail": "Email",
            "InputLabelPassword": "Password",
            "InputPlaceholderMail": "Enter email address",
            "InputPlaceholderPassword": "Enter your password",
            "MessageLoginError": "Authentication failed"
          },
          "DE": {
            "ButtonLogin": "Anmelden",
            "HeadingLogin": "Anmelden",
            "Input2FA": "Validierungscode (nur bei aktivierter 2FA)",
            "InputLabelMail": "E-Mail Adresse",
            "InputLabelPassword": "Passwort",
            "InputPlaceholderMail": "E-Mail Adresse eingeben",
            "InputPlaceholderPassword": "Passwort eingeben",
            "MessageLoginError": "Anmeldung ist fehlgeschlagen"
          }
        },
        "Monitoring": {
          "DE": {
            "ButtonActivatedMonitoring": "Monitoring deaktivieren",
            "ButtonActivateOnline": "Onlinestatusüberprüfung aktivieren",
            "ButtonDeactivatedMonitoring": "Monitoring aktivieren",
            "ButtonDeactivateOnline": "Onlinestatusüberprüfung deaktivieren",
            "ButtonRefresh": "Aktualisieren",
            "ButtonSaveChanges": "Änderungen übernehmen",
            "ConfigHeadingThreshold": "Schwellenwerte",
            "Heading": "Monitoring",
            "HostHeadingBasicData": "Basisdaten",
            "HostHeadingHardware": "Hardware",
            "HostHeadingSoftware": "Software",
            "HostHeadingStoragePools": "Speicherpools",
            "InputDiskError": "Festplattenfehler (% belegt)",
            "InputDiskWarning": "Festplattenwarnung (% belegt)",
            "InputMuteDiskWarning": "Festplattenbenachrichtigung und -anzeige deaktivieren",
            "InputSubfolder": "Unterordner",
            "LabelAdapterRAM": "Speicher",
            "LabelArchitecture": "Architektur",
            "LabelCaption": "Titel",
            "LabelCores": "Kerne",
            "LabelCurrentClockSpeed": "Letzte Taktrate",
            "LabelCurrentVoltage": "Letzte Spannung",
            "LabelDays": "{0} Tage",
            "LabelDefaultGateways": "Standard Gateways",
            "LabelDelete": "Löschen",
            "LabelDescription": "Beschreibung",
            "LabelDeviceId": "Geräte ID",
            "LabelDeviceLocator": "Slot",
            "LabelDnsDomain": "Domain",
            "LabelDnsHostName": "DNS Host",
            "LabelDriverDate": "Treiberdatum",
            "LabelDriverVersion": "Treiberversion",
            "LabelEnabledCores": "Aktive Kerne",
            "LabelFirmwareVersion": "Firmwareversion",
            "LabelFriendlyName": "Anzeigename",
            "LabelHost": "Host",
            "LabelInstallDate": "Installationsdatum",
            "LabelInstallLocation": "Installationspfad",
            "LabelIpAddresses": "IP Adressen",
            "LabelIsDhcp": "DHCP",
            "LabelL2CacheSize": "L2 Cache Größe",
            "LabelL2CacheSpeed": "L2 Cache Takt",
            "LabelL3CacheSize": "L3 Cache Größe",
            "LabelL3CacheSpeed": "L3 Cache Takt",
            "LabelLastBoot": "Zuletzt gestartet",
            "LabelLastCheck": "Zuletzt überprüft",
            "LabelLastHypervisor": "Hypervisor",
            "LabelLastUser": "Letzter Benutzer",
            "LabelMacAddress": "MAC Adresse",
            "LabelManufacturer": "Hersteller",
            "LabelMaxClock": "Max. Taktrate",
            "LabelMediaType": "Medientyp",
            "LabelMemoryDescriptor": "Beschreibung",
            "LabelMemoryPartnumber": "Hersteller (Teilenummer)",
            "LabelModel": "Model",
            "LabelName": "Name",
            "LabelNumberOfLogicalProcessors": "Logische Einheiten",
            "LabelOperatingSystem": "Betriebssystem",
            "LabelPartitionsCount": "# Partitionen",
            "LabelPartNumber": "Teilenummer",
            "LabelPhysicalDisks": "Physische Datenträger",
            "LabelPNPDevice": "PnP Geräte ID",
            "LabelProduct": "Produkt",
            "LabelPublisher": "Herausgeber",
            "LabelRam": "RAM",
            "LabelRefreshHostlist": "Hostliste aktualisieren",
            "LabelRevision": "Revision",
            "LabelScsiBus": "SCSI Bus",
            "LabelScsiPort": "SCSI Port",
            "LabelScsiUnit": "SCSI Einheit",
            "LabelSerialNumber": "Seriennummer",
            "LabelShowSystemComponents": "Systemkomponenten anzeigen",
            "LabelShowUnusedControllers": "Alle anzeigen",
            "LabelSize": "Größe",
            "LabelSocketDesignation": "Sockel",
            "LabelStatus": "Status",
            "LabelSubnets": "Subnets",
            "LabelSystemFamily": "Systemfamilie",
            "LabelSystemName": "Systemname",
            "LabelSystemSKUNumber": "SKU",
            "LabelSystemType": "Typ",
            "LabelTag": "Tag",
            "LabelThreadCount": "Threadanzahl",
            "LabelType": "Typ",
            "LabelUptime": "Betriebszeit",
            "LabelVersion": "Version",
            "LabelVirtualDisks": "Virtuelle Datenträger",
            "LabelVoltageCaps": "Maximalspannung",
            "LabelWorkerIdentifier": "Arbeiter Kennung",
            "NotificationClientOffline": "Client offline",
            "NotificationDiskError": "Fesplattenbelegung Fehler",
            "NotificationDiskWarning": "Festplattenbelegung Warnung",
            "NotificationServiceError": "Falscher Service Status",
            "NotificationSotragePoolWarning": "Speicherpool Warnung",
            "NotificationWatcherError": "Watcher Fehler",
            "NotificationWatcherWarning": "Watcher Warnung",
            "NotificationWorkerOffline": "Worker offline",
            "OptionNone": "keine",
            "OptionRunning": "Wird ausgeführt",
            "OptionStopped": "Gestoppt",
            "TableHeadingCpuUsagePercent": "CPU %",
            "TableHeadingDiskUsagePercent": "Festplatte %",
            "TableHeadingHostName": "Host Name",
            "TableHeadingLastFreeMemory": "RAM frei",
            "TableHeadingMonitoring": "Überwachunng",
            "TableHeadingRamUsagePercent": "RAM %",
            "TableHeadingServiceDescription": "Service Beschreibung/Titel",
            "TableHeadingServiceName": "Service Name",
            "TableHeadingStartType": "Start Typ",
            "TableHeadingState": "Status",
            "TextActivateMonitoring": "Monitoring aktivieren",
            "TextAddExtenalErrNotification": "Externe Fehleradresse hinzufügen",
            "TextAddExtenalWarnNotification": "Externe Warnungsadresse hinzufügen",
            "TextAdPath": "AD Pfad",
            "TextButtonActions": "Aktionen",
            "TextChooseElement": "Wählen Sie ein Element aus",
            "TextComputerSystemInformation": "System",
            "TextCores": "Kerne",
            "TextCpuInformation": "CPU",
            "TextCpuRamChart": "CPU / RAM Auslastung",
            "TextDeactivateMonitoring": "Monitoring deaktivieren",
            "TextDiskAndPartitions": "Festplatten & Partitionen",
            "TextDiskChart": "Festplattenbelegung",
            "TextDiskSpaceSummary": "{0} GB frei von  {1} GB ({2} % belegt)",
            "TextEmptyFolder": "Dieser Ordner ist leer",
            "TextExtenalErrNotification": "Externe Fehleradressen",
            "TextExtenalWarnNotification": "Externe Warnungsadressen",
            "TextGraphicsInformation": "Grafik",
            "TextHeadingDiskWarning": "Benachrichtigungen für {0} ({1})",
            "TextHosts": "Hosts",
            "TextHostsListHeader": "Hosts ({0} von {1})",
            "TextInternetSpeedChart": "Bandbreiten Verlauf",
            "TextLabelCpu": "CPU",
            "TextLabelDisk": "Festplatte",
            "TextMainboardInformation": "Hauptplatine",
            "TextMemoryInformation": "Arbeitsspeicher ({0} von {1} Bänken belegt)",
            "TextMySettings": "Meine Einstellungen",
            "TextNetworkAdapterInformation": "Netzwerkadapter",
            "TextNetworkTeamInformation": "Netzwerk Teams",
            "TextNever": "nie",
            "TextNotActive": "Monitoring ist nicht aktiviert",
            "TextNotifications": "Benachrichtigungen",
            "TextReachesHosts": "Erreicht <b>{0}</b> Hosts",
            "TextScsiInformation": "Disk-Controller",
            "TextServiceErrors": "Dienstefehler",
            "TextServices": "Dienste",
            "TextSoftwareVersion": "Software-Version",
            "TextSpeedTestDisabled": "Verbindungsüberprüfung deaktiviert",
            "TextSpeedTestEnabled": "Verbindungsüberprüfung aktiviert",
            "TextSpeedTestLastResult": "U: {0} MBps, D: {1} MBps, L: {2} ms",
            "TextStoragePoolUsage": "{0} von {1} zugewiesen",
            "TextSystemInformation": "Systeminformation",
            "TextSystemquery": "Systemabfrage",
            "TextWorkerInformation": "Arbeiter-Information",
            "TextWorkers": "Arbeiter"
          },
          "EN": {
            "ButtonActivatedMonitoring": "Deactivate monitoring",
            "ButtonActivateOnline": "Activate online check",
            "ButtonDeactivatedMonitoring": "Activate monitoring",
            "ButtonDeactivateOnline": "Deactivate online check",
            "ButtonRefresh": "Refresh",
            "ButtonSaveChanges": "Save changes",
            "ConfigHeadingThreshold": "Thresholds",
            "Heading": "Monitoring",
            "HostHeadingBasicData": "Basic data",
            "HostHeadingHardware": "Hardware",
            "HostHeadingSoftware": "Software",
            "HostHeadingStoragePools": "Storage pools",
            "InputDiskError": "Disk error (% usage)",
            "InputDiskWarning": "Disk warning (% usage)",
            "InputMuteDiskWarning": "Mute disk notifications and hide error",
            "InputSubfolder": "Subfolders",
            "LabelAdapterRAM": "Memory",
            "LabelArchitecture": "Architecture",
            "LabelCaption": "Title",
            "LabelCores": "Cores",
            "LabelCurrentClockSpeed": "Last clock",
            "LabelCurrentVoltage": "Last voltage",
            "LabelDays": "{0} days",
            "LabelDefaultGateways": "Default gateways",
            "LabelDelete": "Delete",
            "LabelDescription": "Description",
            "LabelDeviceId": "Device ID",
            "LabelDeviceLocator": "Slot",
            "LabelDnsDomain": "Domain",
            "LabelDnsHostName": "DNS host",
            "LabelDriverDate": "Driver date",
            "LabelDriverVersion": "Driver version",
            "LabelEnabledCores": "Enabled cores",
            "LabelFirmwareVersion": "Firmware version",
            "LabelFriendlyName": "Friendly name",
            "LabelHost": "Host",
            "LabelInstallDate": "Install date",
            "LabelInstallLocation": "Install path",
            "LabelIpAddresses": "IP addresses",
            "LabelIsDhcp": "DHCP",
            "LabelL2CacheSize": "L2 cache size",
            "LabelL2CacheSpeed": "L2 cache speed",
            "LabelL3CacheSize": "L3 cache size",
            "LabelL3CacheSpeed": "L3 cache speed",
            "LabelLastBoot": "Last boot",
            "LabelLastCheck": "Last checked",
            "LabelLastHypervisor": "Hypervisor",
            "LabelLastUser": "Last user",
            "LabelMacAddress": "MAC address",
            "LabelManufacturer": "Manufacturer",
            "LabelMaxClock": "Max. clock",
            "LabelMediaType": "Media type",
            "LabelMemoryDescriptor": "Description",
            "LabelMemoryPartnumber": "Manufacturer (Part number)",
            "LabelModel": "Model",
            "LabelName": "Name",
            "LabelNumberOfLogicalProcessors": "Logical units",
            "LabelOperatingSystem": "Operatign system",
            "LabelPartitionsCount": "# partitions",
            "LabelPartNumber": "Part number",
            "LabelPhysicalDisks": "Phyiscal disks",
            "LabelPNPDevice": "PnP device ID",
            "LabelProduct": "Product",
            "LabelPublisher": "Publisher",
            "LabelRam": "Memory",
            "LabelRefreshHostlist": "Refresh hosts list",
            "LabelRevision": "Revision",
            "LabelScsiBus": "SCSI bus",
            "LabelScsiPort": "SCSI port",
            "LabelScsiUnit": "SCSI unit",
            "LabelSerialNumber": "Serial number",
            "LabelShowSystemComponents": "Show system components",
            "LabelShowUnusedControllers": "Show all",
            "LabelSize": "Size",
            "LabelSocketDesignation": "Socket",
            "LabelStatus": "State",
            "LabelSubnets": "Subnets",
            "LabelSystemFamily": "System family",
            "LabelSystemName": "System name",
            "LabelSystemSKUNumber": "SKU",
            "LabelSystemType": "Type",
            "LabelTag": "Tag",
            "LabelThreadCount": "Thread count",
            "LabelType": "Type",
            "LabelUptime": "Uptime",
            "LabelVersion": "Version",
            "LabelVirtualDisks": "Virtual disks",
            "LabelVoltageCaps": "Voltace cap",
            "LabelWorkerIdentifier": "Worker identifier",
            "NotificationClientOffline": "Client offline",
            "NotificationDiskError": "Disk space error",
            "NotificationDiskWarning": "Disk space warning",
            "NotificationServiceError": "Wrong service state",
            "NotificationSotragePoolWarning": "Storage pool warning",
            "NotificationWatcherError": "Watcher error",
            "NotificationWatcherWarning": "Watcher warning",
            "NotificationWorkerOffline": "Worker offline",
            "OptionNone": "none",
            "OptionRunning": "Running",
            "OptionStopped": "Stopped",
            "TableHeadingCpuUsagePercent": "CPU %",
            "TableHeadingDiskUsagePercent": "Disk %",
            "TableHeadingHostName": "Host name",
            "TableHeadingLastFreeMemory": "Free memory",
            "TableHeadingMonitoring": "Monitoring",
            "TableHeadingRamUsagePercent": "Memory %",
            "TableHeadingServiceDescription": "Service description",
            "TableHeadingServiceName": "Service name",
            "TableHeadingStartType": "Start type",
            "TableHeadingState": "State",
            "TextActivateMonitoring": "Activate monitoring",
            "TextAddExtenalErrNotification": "Add external address for errors\t",
            "TextAddExtenalWarnNotification": "Add external address for warnings",
            "TextAdPath": "AD path",
            "TextButtonActions": "Actions",
            "TextChooseElement": "Choose an element",
            "TextComputerSystemInformation": "System",
            "TextCores": "Cores",
            "TextCpuInformation": "CPU",
            "TextCpuRamChart": "CPU / RAM usage",
            "TextDeactivateMonitoring": "Deactivate monitoring",
            "TextDiskAndPartitions": "Disks & partitions",
            "TextDiskChart": "Disk usage",
            "TextDiskSpaceSummary": "{0} GB free of {1} GB ({2} % in use)",
            "TextEmptyFolder": "This folder is empty",
            "TextExtenalErrNotification": "External addresses for errors",
            "TextExtenalWarnNotification": "External addresses for warnings\t",
            "TextGraphicsInformation": "Graphics",
            "TextHeadingDiskWarning": "Notifications for {0} ({1})",
            "TextHosts": "Hosts",
            "TextHostsListHeader": "Hosts ({0} out of {1})",
            "TextInternetSpeedChart": "Bandwidth history",
            "TextLabelCpu": "CPU",
            "TextLabelDisk": "Disk",
            "TextMainboardInformation": "Mainboard",
            "TextMemoryInformation": "Memory ({0} out of {1} slots in use)",
            "TextMySettings": "My settings",
            "TextNetworkAdapterInformation": "Network adapters",
            "TextNetworkTeamInformation": "Network teams",
            "TextNever": "never",
            "TextNotActive": "Monitoring is not activated",
            "TextNotifications": "Notifications",
            "TextReachesHosts": "Reaches <b>{0}</b> hosts",
            "TextScsiInformation": "Disk controllers",
            "TextServiceErrors": "Service error",
            "TextServices": "Services",
            "TextSoftwareVersion": "Software version",
            "TextSpeedTestDisabled": "Connection test disabled",
            "TextSpeedTestEnabled": "Connection test enabled",
            "TextSpeedTestLastResult": "U: {0} MBps, D: {1} MBps, L: {2} ms",
            "TextStoragePoolUsage": "{0} out of {1} in use",
            "TextSystemInformation": "System information",
            "TextSystemquery": "System query",
            "TextWorkerInformation": "Worker information",
            "TextWorkers": "Workers"
          }
        },
        "Navigation": {
          "EN": {
            "3CXPhoneApp": "3CX Phone App",
            "CallList": "Employees",
            "Contacts": "Contacts",
            "ContractApp": "Contracts App",
            "CreateTask": "Create task",
            "Dashboard": "Dashboard",
            "DocumentManagement": "Document management",
            "EmployeeCalendar": "Employee calendar",
            "ERPApp": "ERP App",
            "HolidayCalendar": "Holiday calendar",
            "InformationHub": "Information hub",
            "LabelPrint": "Label print",
            "Monitoring": "Monitoring",
            "PasswordGenerator": "Password generator",
            "Systembuilder": "Systembuilder",
            "SystemList": "System list",
            "SystemSearch": "System search",
            "Tasks": "Tasks",
            "Akquisen": "Acquisitions",
            "Projekte": "Projects",
            "Firmen": "Contractors",
            "Administration": "Administration",
            "Dokumente": "Documents",
            "Meine Aufgaben": "My Tasks",
            "Timeline": "Timeline"
          },
          "DE": {
            "3CXPhoneApp": "3CX Telefonanbindung",
            "CallList": "Mitarbeiter",
            "Contacts": "Kontakte",
            "ContractApp": "Vertrags-Anwendung",
            "CreateTask": "Aufgabe erstellen",
            "Dashboard": "Dashboard",
            "DocumentManagement": "Dokumentenverwaltung",
            "EmployeeCalendar": "Mitarbeiterkalender",
            "ERPApp": "ERP-Anwendung",
            "HolidayCalendar": "Urlaubskalender",
            "InformationHub": "Informations Zentrum",
            "LabelPrint": "Labeldruck",
            "Monitoring": "Computer-Überwachung",
            "PasswordGenerator": "Passwort Generator",
            "Systembuilder": "Systembuilder",
            "SystemList": "Systemliste",
            "SystemSearch": "Systemsuche",
            "Tasks": "Aufgaben",
            "Akquisen": "Akquisen",
            "Projekte": "Projekte",
            "Firmen": "Firmen & Kontakte",
            "Administration": "Administration",
            "Dokumente": "Dokumente",
            "Meine Aufgaben": "Meine Aufgaben",
            "Timeline": "Timeline"
          }
        },
        "Profile": {
          "DE": {
            "ButtonRemoveProfile": "Profil löschen",
            "ButtonSaveProfile": "Profil speichern",
            "LabelEmail": "E-Mail-Adresse",
            "LabelFirstname": "Vorname",
            "LabelLastname": "Nachname",
            "LabelUsername": "Benutzername",
            "MessageInvalidCode": "Validierungscode ist ungültig.",
            "MessageSuccess": "Änderung war erfolgreich.",
            "MessageUnknownError": "Ein Fehler ist aufgetreten, bitte überprüfen sie den Code.",
            "Text2Fa": "Zwei-Faktor Authentifizierung",
            "Text2FaInfo": " Scannen sie den untenstehenden Code auf Ihrem Gerät ein um und geben Sie den aktuellen Code ein um die Zwei-Faktor-Authentifizierung zu (de)aktivieren.",
            "Text2FaValidation": "Zwei-Faktor Validierung",
            "TextMasterdata": "Stammdaten"
          },
          "EN": {
            "ButtonRemoveProfile": "Remove profile",
            "ButtonSaveProfile": "Save profile",
            "LabelEmail": "Email address",
            "LabelFirstname": "First name",
            "LabelLastname": "Last name",
            "LabelUsername": "User name",
            "MessageInvalidCode": "Validation code is invalid",
            "MessageSuccess": "2FA has been changed successfully",
            "MessageUnknownError": "An unknown error occurred, check your code",
            "Text2Fa": "Two factor authentication",
            "Text2FaInfo": "Scan the QR code with your authenticator app and enter the code to activate/deactivate 2FA",
            "Text2FaValidation": "2FA validation code",
            "TextMasterdata": "Master data"
          }
        },
        "PublicContacts": {
          "EN": {
            "AddBirthday": "Import selected birthdays",
            "BirthdayAdded": "{0} {1} has been added to your outlook calendar.",
            "BirthdayRemoved": "{0} {1} has been removed from outlook calendar.",
            "LabelBirthday": "Birthday",
            "LabelCompany": "Company",
            "LabelDepartment": "Department",
            "LabelMandator": "Branch",
            "LabelDisplayName": "Name",
            "LabelFax": "Fax",
            "LabelMail": "E-mail",
            "LabelMobile": "Mobile",
            "LabelPhone": "Phone",
            "LabelTitle": "Position",
            "RemoveBirthday": "Remove selected birthdays from Exchange",
            "SyncLabel": "Sync to Exchange",
            "TextButtonActions": "Actions",
            "TextDays": "days",
            "TextEmplyeeListHeader": "Employees ({0} out of {1})",
            "TextMonth": "31 days",
            "TextNoAppointments": "No appointments",
            "TextNoLocation": "(no location)",
            "TextToday": "today",
            "TextTommorow": "tommorow",
            "TextWeek": "7 days",
            "TooltipAllContacts": "Back to all contacts",
            "TooltipPrintList": "Print list",
            "TooltipTableSettings": "Table properties",
            "TooltipUpcomingBirthdays": "Upcoming birthdays"
          },
          "DE": {
            "AddBirthday": "Ausgewählte Geburtstage importieren",
            "BirthdayAdded": "{0} {1} wurde zum Outlook Kalender hinzugefügt.",
            "BirthdayRemoved": "{0} {1} wurde aus dem Outlook Kalender entfernt.",
            "LabelBirthday": "Geburtstag",
            "LabelCompany": "Firma",
            "LabelDepartment": "Abteilung",
            "LabelMandator": "Niederlassung",
            "LabelDisplayName": "Name",
            "LabelFax": "Fax",
            "LabelMail": "E-Mail",
            "LabelMobile": "Mobil",
            "LabelPhone": "Festnetz",
            "LabelTitle": "Position",
            "RemoveBirthday": "Ausgewählte Geburtstage aus Exchange löschen",
            "SyncLabel": "In Exchange übernehmen",
            "TextButtonActions": "Aktionen",
            "TextDays": "Tagen",
            "TextEmplyeeListHeader": "Mitarbeiter ({0} von {1})",
            "TextMonth": "31 Tage",
            "TextNoAppointments": "Keine Termine",
            "TextNoLocation": "(keine Ortsangabe)",
            "TextToday": "heute",
            "TextTommorow": "morgen",
            "TextWeek": "7 Tage",
            "TooltipAllContacts": "Alle Kontakte anzeigen",
            "TooltipPrintList": "Liste drucken",
            "TooltipTableSettings": "Tabelleneigenschaften",
            "TooltipUpcomingBirthdays": "Bevorstehende Geburtstage"
          }
        },
        "UserBar": {
          "DE": {
            "PlaceholderSearchText": "Suche",
            "TextLogout": "Abmelden",
            "TextProfile": "Profil"
          },
          "EN": {
            "PlaceholderSearchText": "Search",
            "TextLogout": "Log out",
            "TextProfile": "Profile"
          }
        }
      },
      "Languages": [
        {
          "Entries": [],
          "Code": "DE",
          "Name": "Deutsch",
          "SystemDefault": false
        },
        {
          "Entries": [],
          "Code": "EN",
          "Name": "English",
          "SystemDefault": true
        }
      ],
      "Fallback": "EN"
    };
    return result;
  }

}

export class PluginDict {
  constructor(
    public readonly Dicitonary: { [key: string]: { [key: string]: string } },
    public readonly LocalizationService: LocalizationService) {
  }

  public Merge(pluginDict: PluginDict) {
    for (const key in pluginDict.Dicitonary) {
      for (const subKey in pluginDict.Dicitonary[key]) {
        this.Dicitonary[key][subKey] = pluginDict.Dicitonary[key][subKey];
      }
    }
  }

  public LocalizeResponse(message: string, param1: string = undefined, param2: string = undefined, param3: string = undefined, param4: string = undefined, param5: string = undefined) {
    if (message && message.startsWith("L[") && message.endsWith("]")) {
      const key = message.substring(2, message.length - 1);
      return this.Localize(key, param1, param2, param3, param4, param5);
    } else {
      return message;
    }
  }

  public LocalizeWithList(key: string, paramsList: string) {
    if (this.Dicitonary[this.LocalizationService.UserLanguage][key]) {
      let result = this.Dicitonary[this.LocalizationService.UserLanguage][key];
      if (paramsList && paramsList.length) {
        for (let i = 0; i < paramsList.length; i++) {
          result = this.replaceAll(result, "{" + i + "}", paramsList[i]);
        }
      }
      return result;
    } else {
      if (this.Dicitonary[this.LocalizationService.Fallback][key]) {
        return this.Dicitonary[this.LocalizationService.Fallback][key];
      }
      return "L[" + key + "]";
    }
  }

  public Localize(key: string, param1: string = undefined, param2: string = undefined, param3: string = undefined, param4: string = undefined, param5: string = undefined) {
    if (this.Dicitonary[this.LocalizationService.UserLanguage][key]) {
      let result = this.Dicitonary[this.LocalizationService.UserLanguage][key];
      if (param1 !== undefined) {
        result = this.replaceAll(result, "{0}", param1);
      }
      if (param2 !== undefined) {
        result = this.replaceAll(result, "{1}", param2);
      }
      if (param3 !== undefined) {
        result = this.replaceAll(result, "{2}", param3);
      }
      if (param4 !== undefined) {
        result = this.replaceAll(result, "{3}", param4);
      }
      if (param5 !== undefined) {
        result = this.replaceAll(result, "{4}", param5);
      }
      return result;
    } else {
      if (this.Dicitonary[this.LocalizationService.Fallback][key]) {
        return this.Dicitonary[this.LocalizationService.Fallback][key];
      }
      return "L[" + key + "]";
    }
  }

  private replaceAll(s: string, search: string, replacement: string): string {
    return s.split(search).join(replacement);
  }
}
