import angular, { IQService, IScope, ITimeoutService } from "angular";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { StorageService } from "src/ajs/Services/StorageService";
import { cloneObject } from "src/ajs/Utils/HestiaCommon";

createEditVendorDialogController.$inject = ['$scope', '$mdDialog', '$q', '$timeout', 'ApiHandlerService', 'StorageService', 'Model', 'OnSubmit', 'theme'];

export function createEditVendorDialogController(
  $scope: IEditVendorDialogControllerScope,
  $mdDialog: angular.material.IDialogService,
  $q: IQService,
  $timeout: ITimeoutService,
  ApiHandlerService: ApiHandlerService,
  StorageService: StorageService,
  Model,
  OnSubmit,
  theme: ThemeDto
) {
  addDefaultScopeFunctions($scope, theme);
  $scope.SubmitCallback = OnSubmit;
  if (Model) {
    $scope.Model = cloneObject(Model);
  }

  $scope.Save = function () {
    $scope.SubmitCallback($scope.Model);
    $mdDialog.hide();
  };

  $scope.Close = function () {
    $mdDialog.hide();
  };
}

interface IEditVendorDialogControllerScope extends IScope {
  Model: any

  Save: () => void;
  Close: () => void;
  SubmitCallback
}
