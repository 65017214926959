import angular from "angular";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { SearchBarService } from "src/ajs/Services/SearchBarService";
import { UserService } from 'src/ajs/Services/UserService';

// Diese Datei enthält AngularJS Services, die für Angular geupgraded werden.
// https://coderlifeline.com/upgrade-built-in-angularjs-services-for-use-in-angular/


export abstract class RouteParamsAjs implements angular.route.IRouteParamsService {
  [key: string]: any
}

export const routeParamsProvider = {
  provide: RouteParamsAjs,
  useFactory: i => i.get("$routeParams"),
  deps: ['$injector']
};

// ----------

export abstract class RootScopeServiceAjs {
  [key: string]: any
}

export const rootScopeServiceProvider = {
  provide: RootScopeServiceAjs,
  useFactory: i => i.get("$rootScope"),
  deps: ['$injector']
};

// ----------


export const userServiceProvider = {
  provide: UserService,
  useFactory: i => i.get('UserService'),
  deps: ['$injector']
};

// ----------

export abstract class mdPanelAjs {
  [key: string]: any
}

export const mdPanelServiceProvider = {
  provide: mdPanelAjs,
  useFactory: i => i.get('$mdPanel'),
  deps: ['$injector']
};

// ----------

export const ApiHandlerServiceProvider = {
  provide: ApiHandlerService,
  useFactory: i => i.get('ApiHandlerService'),
  deps: ['$injector']
};

// ----------


export const SearchBarServiceProvider = {
  provide: SearchBarService,
  useFactory: i => i.get('SearchBarService'),
  deps: ['$injector']
};
