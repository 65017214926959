import { EventEmitter, Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { Api2Service } from 'src/app/core/services/api2.service';
import { fixGetNotificationsDto, GetNotificationsDto } from '../dto/get-notifications-dto';
import { GetNotificationsRequestDto } from '../dto/get-notifications-request-dto';
import { MarkAsReadRequestDto } from '../dto/mark-as-read-request-dto';
import { NotificationSettingsDto } from '../dto/notification-settings-dto';

const controller = "Notification";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  /** Wird ausgelöst, wenn sich die Anzahl der ungelesenen Notifications wahrscheinlich geändert hat. */
  readonly unreadCountChanged = new EventEmitter<void>();

  constructor(private readonly api: Api2Service) { }

  getNotifications(orderBy: string, descending: boolean, pageSize: number, pageIndex: number, search: string, showAll: boolean): Observable<GetNotificationsDto> {

    const request: GetNotificationsRequestDto = {
      orderBy: orderBy,
      descending: descending,
      pageSize: pageSize,
      pageIndex: pageIndex,
      search: search,
      showAll: showAll
    };

    return this.api.post<GetNotificationsDto>(controller, 'GetNotifications', null, request).pipe(
      tap(fixGetNotificationsDto)
    );
  }

  markAsRead(notificationIds: number[]): Observable<void> {

    const request: MarkAsReadRequestDto = {
      notificationIds: notificationIds
    };

    return this.api.post<void>(controller, 'MarkAsRead', null, request).pipe(
      tap(() => this.unreadCountChanged.emit())
    );
  }

  getUnreadCount(): Observable<number> {

    return this.api.get<number>(controller, 'GetUnreadCount');
  }

  saveNotificationSettings(dto: NotificationSettingsDto): Observable<void> {
    return this.api.post<void>(controller, 'SaveNotificationSettings', null, dto);
  }

  getNotificationSettigs(): Observable<NotificationSettingsDto> {
    return this.api.get<NotificationSettingsDto>(controller, 'GetNotificationSettigs');
  }
}
