import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { catchError, debounceTime, firstValueFrom, forkJoin, merge, of, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { COLEDITOR_DEF, COLEDITOR_STORAGE_KEY, ColumnEditorService } from 'src/app/shared/services/column-editor/column-editor.service';
import { SortService, SORT_DEFAULT_ACTIVE, SORT_STORAGE_KEY } from 'src/app/shared/services/sort/sort.service';
import { TaskService } from '../../services/task.service';
import { SearchBarService } from 'src/ajs/Services/SearchBarService';
import { MatPaginator } from '@angular/material/paginator';
import { GetTaskReceiptListResultDto } from '../../dto/get-task-receipt-list-result-dto';
import { MatTableDataSourceEx } from 'src/app/shared/utils/mat-table-data-source-ex';
import { TasksReceiptListDto } from '../../dto/tasks-receipt-list-dto';
import { UntypedFormControl } from '@angular/forms';
import { RemoteStorageService } from 'src/app/core/services/web-storage/remote-storage.service';
import { MandatorService } from 'src/app/core/services/mandator.service';

@Component({
  selector: 'app-task-receipt-list',
  templateUrl: './task-receipt-list.component.html',
  styleUrls: ['./task-receipt-list.component.scss'],
  providers: [
    ColumnEditorService,
    { provide: COLEDITOR_STORAGE_KEY, useValue: 'task-receipt.columns' },
    {
      provide: COLEDITOR_DEF, useValue: [
        { name: 'name', header: 'Belegbezeichnung' },
        { name: 'address', header: 'Adresse/Kunde' },
        { name: 'accountedTime', header: 'Abgerechnet' },
        { name: 'accountingPeriodEnd', header: 'Stichtag' },
        { name: 'totalTime', header: 'Gesamtzeit' },
        { name: 'timeEntries', header: 'Zeiteinträge' },
        { name: 'taskCalls', header: 'Task Anrufe' },
        { name: 'otherCalls', header: 'Andere Anrufe' }
      ]
    },
    SortService,
    { provide: SORT_STORAGE_KEY, useValue: 'task-receipt.sort' },
    { provide: SORT_DEFAULT_ACTIVE, useValue: 'address' },
  ]
})
export class TaskReceiptListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  totalCount: number = 0;
  isLoading: boolean = false;

  private readonly destroy$ = new Subject<void>();
  private readonly refresh$ = new Subject<void>();

  readonly dataSource: MatTableDataSourceEx<TasksReceiptListDto> = new MatTableDataSourceEx<TasksReceiptListDto>();
  readonly showToggle = new UntypedFormControl(true);

  private _addressId: number;
  @Input() set addressId(value: number) {
    if (this._addressId !== value) {
      this._addressId = value;
      this.refresh$.next();
    }
  }
  get addressId(): number {
    return this._addressId;
  }

  constructor(
    public readonly columnEditor: ColumnEditorService,
    public readonly sortService: SortService,
    public readonly taskService: TaskService,
    private readonly SearchBarService: SearchBarService,
    private readonly remoteStorage: RemoteStorageService,
    private readonly mandatorService: MandatorService
  ) {

  }

  async ngOnInit() {

    const result = await firstValueFrom(forkJoin([
      this.remoteStorage.getBoolean('task-receipt.showToggle'),
      this.columnEditor.loaded$,
      this.sortService.loaded$
    ]));

    this.showToggle.setValue(result[0]);
    this.showToggle.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe((value: boolean) => this.remoteStorage.setBoolean("task-receipt.showToggle", value));
  }

  ngAfterViewInit() {

    merge(
      this.showToggle.valueChanges,
      this.sort.sortChange,
      this.paginator.page,
      this.SearchBarService.searchTextChange.pipe(debounceTime(300)),
      this.refresh$,
      this.mandatorService.selectedMandatorIdChanged
    ).pipe(
      takeUntil(this.destroy$),
      startWith({}),
      tap(() => this.isLoading = true),
      switchMap(() => this.taskService.getTaskReceiptList(
        this.sort.active,
        this.sort.direction === "desc",
        this.paginator.pageSize,
        this.paginator.pageIndex,
        this.SearchBarService.SearchText,
        this.showToggle.value,
        this.addressId,
        this.mandatorService.selectedMandatorId

      ).pipe(
        catchError(err => {
          console.log(err);
          return of({ TotalCount: 0, TasksReceiptList: [] } as GetTaskReceiptListResultDto);
        })
      ))
    ).subscribe(result => {
      this.isLoading = false;

      if (result) {
        this.totalCount = result.TotalCount;
        this.dataSource.data = result.TasksReceiptList;

      }


    });

    this.dataSource.sort = this.sort;



  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.refresh$.complete();
  }

  filterChanged() {
    this.refresh$.next();
  }





}
