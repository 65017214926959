import * as angular from 'angular';
import { addDefaultScopeFunctions } from "../Functions/add-default-scope-functions";
import { ThemeDto } from '../Models/ThemeDto';

createDragAndDropPanelDirective.$inject = ['$timeout', 'DragAndDropPanelService', 'theme'];

export function createDragAndDropPanelDirective($timeout: angular.ITimeoutService, DragAndDropPanelService: any, theme: ThemeDto) {

  return {
    restrict: 'E',
    link: link,
    scope: {
      ngModel: "="
    },
    templateUrl: '/ClientApp/src/ajs/Views/Common/DragAndDropPanel.htm',
    transclude: true
  };

  function link($scope, $elem, $attrs, $ctrl, $transclude) {
    addDefaultScopeFunctions($scope, theme);
    $scope.ParentScope = $scope.$parent;
    var parent = $($elem[0].parentElement);
    //addDefaultScopeFunctions($scope.ParentScope);
    $scope.DragEvent = {
      TotalLeft: 0,
      TotalTop: 0,
      CurrentX: 0,
      CurrentY: 0,
      Container: $elem[0].parentElement
    };

    $scope.AddFocusedStyle = function () {
      //$scope.Classes = "focused-panel";
    };

    $scope.RemoveFocusedStyle = function () {
      $scope.Classes = null;
    };

    $scope.SetIndex = function (zIndex) {
      $scope.DragEvent.Container.parentElement.style.zIndex = zIndex;
    };

    $scope.RegisterInterceptor = function (callback) {
      $scope.ngModel.mdPanelRef.config.onDomRemoved = callback;
    };

    $scope.FocusElement = function () {
      DragAndDropPanelService.MoveToFront($scope);
    };

    DragAndDropPanelService.AddPanel($scope);
    $transclude($scope.$parent, function (clone, scope) {
      $elem.find('.content').append(clone);
    });

    $scope.Close = function () {
      if ($scope.ngModel.OnClose) {
        $scope.ngModel.OnClose();
      }
      DragAndDropPanelService.RemovePanel($scope);
      $scope.ngModel.mdPanelRef.close();
    };

    $scope.MouseDown = function ($event) {
      $scope.IsDragging = true;
      $scope.DragEvent.CurrentX = $event.clientX;
      $scope.DragEvent.CurrentY = $event.clientY;
      DragAndDropPanelService.MoveToFront($scope);
      $event.preventDefault();
    };

    $scope.StopDragging = function () {
      $scope.IsDragging = false;
    };

    $scope.MouseMove = function ($event) {
      if ($scope.IsDragging) {
        var currentX = $event.clientX;
        var currentY = $event.clientY;
        var deltaX = $scope.DragEvent.CurrentX - currentX;
        var deltaY = $scope.DragEvent.CurrentY - currentY;
        $scope.DragEvent.TotalLeft += deltaX;
        $scope.DragEvent.TotalTop += deltaY;
        $scope.DragEvent.CurrentX = currentX;
        $scope.DragEvent.CurrentY = currentY;
        var signX = $scope.DragEvent.TotalLeft >= 0 ? "-" : "+";
        var signY = $scope.DragEvent.TotalTop >= 0 ? "-" : "+";
        $scope.DragEvent.Container.style.top = "calc(50% " + signY + " " + Math.abs($scope.DragEvent.TotalTop) + "px)";
        $scope.DragEvent.Container.style.left = "calc(50% " + signX + " " + Math.abs($scope.DragEvent.TotalLeft) + "px)";
      }
    };
    $timeout(function () {
      $scope.FocusElement();
    }, 100);

  }
}