import { fixPhone3CxCallDto, Phone3CxCallDto } from "src/app/phone/dto/phone-3cx-call-dto";
import { fixTaskEntryDto, TaskEntryDto } from "./task-entry-dto";
import { fixTaskNoteDto, TaskNoteDto } from "./task-note-dto";

export interface TasksReceiptRowDto {
  Id: number;
  TasksReceiptId: number;
  TaskId: number | null;
  AccountingType: number;
  AccountedTime: number;
  Task: TaskEntryDto;
  Calls: Phone3CxCallDto[];
  NotesWithTime: TaskNoteDto[];
}

export function fixTasksReceiptRowDto(dto: TasksReceiptRowDto) {
  if (dto.Task) {
    fixTaskEntryDto(dto.Task);
  }

  if (dto.Calls) {
    for (const call of dto.Calls) {
      fixPhone3CxCallDto(call);
    }
  }

  if (dto.NotesWithTime) {
    for (const note of dto.NotesWithTime) {
      fixTaskNoteDto(note);
    }
  }


}
