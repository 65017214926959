import angular from "angular";

export function createConvortToNumberDirective() {
  return {
    require: 'ngModel',

    link: function (scope, element: angular.IAugmentedJQuery, attrs: angular.IAttributes, ngModel) {
      ngModel.$parsers.push(function (val) {
        if (val === 'null')
          return null;
        return val !== null ? parseInt(val, 10) : null;
      });

      ngModel.$formatters.push(function (val) {
        return val !== null ? '' + val : null;
      });
    }
  };
}

