import angular from "angular";
import { Api1Service } from "src/app/core/services/api1.service";
import { AppConfigService } from "src/app/core/services/app-config.service";
import { MandatorService } from "src/app/core/services/mandator.service";
import { UserService } from "src/app/core/services/user.service";
import { addDefaultScopeFunctions } from "../Functions/add-default-scope-functions";
import { DashboardDataDto } from "../Models/Dashboard/DashboardDataDto";
import { ThemeDto } from "../Models/ThemeDto";
import { LocalizationService, PluginDict } from "../Services/Localization/LocalizationService";
import { StorageService, StorageServiceInstance } from "../Services/StorageService";



export class DashboardController {
  static $inject = ['$scope', '$location', 'StorageService', 'LocalizationService', 'MandatorServiceNgx', 'Api1ServiceNgx', 'theme', 'AppConfigServiceNgx', 'UserServiceNgx'];

  constructor(
    $scope: IDashboardControllerScope,
    $location: angular.ILocationService,
    StorageService: StorageService,
    LocalizationService: LocalizationService,
    MandatorServiceNgx: MandatorService,
    api: Api1Service,
    theme: ThemeDto,
    private readonly appConfigService: AppConfigService,
    userService: UserService
  ) {

    $scope.isCp = false;
    $scope.isLoggedIn = userService.isLoggedIn;


    if (this.appConfigService.environment === "CP") {
      $scope.isCp = true;
    }

    addDefaultScopeFunctions($scope, theme);

    $scope.HasOverflow = function (id: string) {
      let element = $(id) as any;
      if (element && element.length) {
        element = element[0];
      }
      if (element.scrollWidth > element.offsetWidth) {
        return true;
      }
      return false;
    };

    $scope.IsLoading = true;
    $scope.Storage = StorageService.CreateInstance("DashboardController");
    $scope.Localization = LocalizationService.GetPluginDict("Dashboard");

    $scope.ConfirmedMessages = $scope.Storage.GetOrCreate("ConfirmedMessages", []);

    $scope.ConfirmMessage = function (message) {
      message.IsConfirmed = true;
      $scope.ConfirmedMessages.push(message.MessageKey);
      $scope.Storage.Set("ConfirmedMessages", $scope.ConfirmedMessages);
    };

    $scope.MessagesCount = function () {
      if ($scope.Model && $scope.Model.Messages && $scope.Model.Messages.length) {
        return $scope.Model.Messages.filter(f => !f.IsConfirmed).length;
      } else {
        return 0;
      }
    };

    $scope.Navigate = function (destination: string) {
      $location.path(destination);
    };


    $scope.LoadData = function () {
      $scope.ActiveTagText = "TextLoading";
      api.get<DashboardDataDto>('Dashboard', 'GetCurrentDashboardData', { mandatorId: MandatorServiceNgx.selectedMandatorId }).subscribe((data) => {
        $scope.Model = data;
        $scope.Model.Messages.forEach(m => {
          if ($scope.ConfirmedMessages.filter(f => f === m.MessageKey).length > 0) {
            m.IsConfirmed = true;
          }
        });
        if (data.IsLoggedIn) {
          $scope.ActiveTagText = "TextWelcome";
        } else {
          $scope.ActiveTagText = "TextNotLoggedIn";
        }

        $scope.IsLoading = false;
      });
    };
    $scope.LoadData();

    $scope.$on('selectedMandatorChanged', function () {
      $scope.LoadData();
    });
  }
}

interface IDashboardControllerScope extends angular.IScope {
  Model: DashboardDataDto;
  IsLoading: boolean;
  Storage: StorageServiceInstance;
  Localization: PluginDict;
  ConfirmedMessages: any[];
  ConfirmMessage: (message) => void;
  MessagesCount: () => number;
  Navigate: (destination: string) => void;
  LoadData: () => void;
  ActiveTagText: string;
  HasOverflow: (id: string) => boolean;

  isCp: boolean;
  isLoggedIn: boolean;
}
