import { Component, Input, OnInit } from '@angular/core';
import { UserDto } from 'src/app/core/dto/user-dto';
import { UserService } from 'src/app/core/services/user.service';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'cp-user-cell',
  templateUrl: './user-cell.component.html',
  styleUrls: ['./user-cell.component.scss']
})
export class UserCellComponent implements OnInit {

  @Input() user: UserDto;
  @Input() canOpenBusinessCard: boolean = true;

  constructor(
    private readonly dialog: DialogService,
    public readonly userService: UserService
  ) { }

  ngOnInit() {
  }

  clicked(event: MouseEvent) {
    if (this.canOpenBusinessCard && event && this.user) {
      this.dialog.openBusinessCard(event, this.user);
    }
  }

  getUserDisplayName(): string {
    if (!this.user) {
      return "";
    }

    if (this.user.firstName || this.user.lastName) {
      return `${this.user.firstName ?? ""} ${this.user.lastName ?? ""}`;
    }
    else if (this.user.displayName) {
      return this.user.displayName;
    }
    else {
      return this.user.userName;
    }
  }
}
