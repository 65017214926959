import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { ColorService } from 'src/app/core/services/color.service';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import { Tag } from './tag';
import { TagDialogData } from './tag-edit-dialog-data';
import { tagUnique } from './tag-unique.validator';

@Component({
  selector: 'app-tag-edit-dialog',
  templateUrl: './tag-edit-dialog.component.html',
  styleUrls: ['./tag-edit-dialog.component.scss']
})
export class TagEditDialogComponent implements OnDestroy {


  private readonly destroy$ = new Subject<void>();
  readonly formGroup: FormGroup<{
    id: FormControl<number>,
    name: FormControl<string>,
    color: FormControl<string>
  }>;
  lastValidColor: string;
  isSaving = false;

  get isNew(): boolean {
    return !this.data.tag.id;
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly dialogRef: MatDialogRef<TagEditDialogComponent, Tag>,
    public readonly colorService: ColorService,
    @Inject(MAT_DIALOG_DATA) private readonly data: TagDialogData,
  ) {
    const randomColor = this.getRandomColor();
    this.lastValidColor = randomColor;

    this.formGroup = this.formBuilder.group({
      id: [0],
      name: ['', [Validators.maxLength(50), Validators.required, tagUnique(this.data.allTags)]],
      color: [randomColor, [Validators.maxLength(7), Validators.required, CustomValidators.hexColor]],
    });

    this.formGroup.controls.color.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      const ctrl = this.formGroup.controls.color;
      if (ctrl.status === "VALID") {
        this.lastValidColor = ctrl.value;
      }
    });

    this.formGroup.patchValue({
      id: this.data.tag.id,
      name: this.data.tag.name,
      color: this.data.tag.color ?? randomColor
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  cancel() {
    this.dialogRef.close(null);
  }

  save() {
    const result: Tag = this.formGroup.getRawValue();
    this.dialogRef.close(result);
  }

  private getRandomColor() {
    const result = "#" + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
    return result;
  }

  setRandomColor() {
    this.formGroup.patchValue({
      color: this.getRandomColor()
    });
  }
}

