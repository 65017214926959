import { MatDateFormats } from "@angular/material/core";

/** Die Date-Formats werden für den mat-date-picker benötigt. */
export const GERMAN_DATE_FORMATS: MatDateFormats = {
  parse: {
    // Bei JavaScript Date kann man zum Parsen kein Format angeben.
    // Wir setzen den Wert deshalb null und verwenden den HestiaNativeDateAdapter.
    dateInput: null,
  },
  display: {
    dateInput: { year: 'numeric', month: '2-digit', day: '2-digit' },
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};


