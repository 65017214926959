import angular from "angular";
import moment from "moment";
import { Subject } from "rxjs";
import { DragAndDropPanel } from "src/ajs/Directives/DragAndDropPanel";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { CallEntryDto } from "src/ajs/Models/Phone3cx/CallEntryDto";
import { EMailDto } from "src/ajs/Models/Tasks/EMailDto";
import { TaskDto } from "src/ajs/Models/Tasks/TaskDto";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { StorageService, StorageServiceInstance } from "src/ajs/Services/StorageService";
import { TaskService } from "src/ajs/Services/TaskService";
import { cloneObject } from "src/ajs/Utils/HestiaCommon";
import { ArrayUtils } from "src/app/shared/utils/array-utils";
import { TaskService as TaskServiceNgx } from 'src/app/task/services/task.service';


createTasksSuggestionWidgetCController.$inject = ['$scope', 'mdPanelRef', 'ApiHandlerService', 'StorageService', 'TaskService', 'theme', 'SelectedCalls', 'SelectedMails', 'TaskServiceNgx', 'tasksChanged'];

export function createTasksSuggestionWidgetCController(
  $scope: ITaskSuggestionWidgetControllerScope,
  mdPanelRef: angular.material.IPanelRef,
  ApiHandlerService: ApiHandlerService,
  StorageService: StorageService,
  TaskService: TaskService,
  theme: ThemeDto,
  SelectedCalls: CallEntryDto[],
  SelectedMails: EMailDto[],
  taskServiceNgx: TaskServiceNgx,
  tasksChanged: Subject<void>
) {

  if (SelectedCalls) {
    $scope.Call = SelectedCalls[0];
    $scope.Calls = SelectedCalls;
  }
  if (SelectedMails) {
    $scope.Mail = SelectedMails[0];
    $scope.Mails = SelectedMails;
  }

  $scope.Storage = StorageService.CreateInstance("TasksController");
  $scope.IsLoading = false;
  $scope.Model = {};
  $scope.DragAndDropPanel = new DragAndDropPanel("Zur bestehenden Aufgabe hinzufügen", mdPanelRef);
  addDefaultScopeFunctions($scope, theme);

  $scope.OpenTaskPanelMultiple = async (calls: CallEntryDto[], $event: Event) => {
    const callIds = [];

    for (const call of calls) {
      callIds.push(call.CallId);
    }
    const result = await taskServiceNgx.showTaskEditDialog(null, callIds);

    if (result && tasksChanged) {
      tasksChanged.next();
    }
  };

  $scope.CreateNewTask = function ($event: Event) {
    if ($scope.Calls) {
      $scope.OpenTaskPanelMultiple($scope.Calls, $event);
    }
    else if ($scope.Call) {
      $scope.OpenTaskPanelMultiple([$scope.Call], $event);
    }
    mdPanelRef.close();
  };

  $scope.SuggestionsByTextOnly = [];
  $scope.SearchText = null;

  $scope.SearchTextChanged = async function () {
    $scope.SuggestionsByTextOnly = await TaskService.GetTasksByText($scope.SearchText, $scope.ShowCompleted);
    if ($scope.Call) {
      $scope.SuggestionsByNumber = await TaskService.GetTasksByCallNumber($scope.Call.OtherPartyNumber, $scope.SearchText, $scope.ShowCompleted);
    } else {
      $scope.SuggestionsByNumber = [];
    }

    $scope.SuggestionsByAddress = [];
    if ($scope.Call && $scope.Call.Contacts.length) {
      const contact = $scope.Call.Contacts[0];
      if (contact.MainAddress) {
        $scope.SuggestionsByAddress = await TaskService.GetTasksByAddressId(contact.MainAddress.Id, $scope.SearchText, $scope.ShowCompleted);
      }
    }

    for (const value of $scope.SuggestionsByNumber) {
      if ($scope.SuggestionsByAddress) {
        ArrayUtils.removeWhere($scope.SuggestionsByAddress, x => x.Id === value.Id);
      }
      if ($scope.SuggestionsByTextOnly) {
        ArrayUtils.removeWhere($scope.SuggestionsByTextOnly, x => x.Id === value.Id);
      }
    }

    for (const value of $scope.SuggestionsByAddress) {
      if ($scope.SuggestionsByTextOnly) {
        ArrayUtils.removeWhere($scope.SuggestionsByTextOnly, x => x.Id === value.Id);
      }
    }
  };
  $scope.SearchTextChanged();

  $scope.AddToTask = async function (task: TaskDto) {
    const clone: TaskDto = cloneObject(task);

    if ($scope.Mails) {
      for (const oMail of $scope.Mails) {
        const mail: EMailDto = cloneObject(oMail);
        mail.Date = moment(mail.Date);
        const data = await ApiHandlerService.SendRequest("Tasks", "AddMail", { task: clone, mailModel: mail });
        oMail.Id = data.Id;
      }
    }

    if ($scope.Calls) {
      for (const call of $scope.Calls) {
        await ApiHandlerService.SendRequest("Tasks", "AddCall", { task: clone, callId: call.CallId });
      }
    }

    mdPanelRef.close();
  };
}

interface ITaskSuggestionWidgetControllerScope extends angular.IScope {
  Call: CallEntryDto;
  Calls: CallEntryDto[];
  Mail: EMailDto;
  Mails: EMailDto[];
  Storage: StorageServiceInstance;
  IsLoading: boolean;
  Model: any;
  DragAndDropPanel: DragAndDropPanel;
  SearchText: string;
  ShowCompleted: boolean;
  SuggestionsByTextOnly: TaskDto[];
  SuggestionsByNumber: TaskDto[];
  SuggestionsByAddress: TaskDto[];

  OpenTaskPanelMultiple: (calls: CallEntryDto[], $event: Event) => void;
  CreateNewTask: ($event: Event) => void;
  SearchTextChanged: () => void;
  AddToTask: (task: TaskDto) => Promise<void>;
}
