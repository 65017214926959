
import { ContractDto, fixContractDto } from "src/app/contracts/dto/contract-dto";
import { XmlItemDto, fixXmlItemDto } from "./xml-item-dto";
import { FormControl } from "@angular/forms";

export interface ReadXmlResultItemDto {
  item: XmlItemDto;
  contract: ContractDto;
  hasXmlContractType: boolean;

  /// Nur im Frontend
  contractTypeControl?: FormControl<number>;
  contractAccountableControl?: FormControl<boolean>;
}

export function fixReadXmlResultItemDto(dto: ReadXmlResultItemDto) {
  if (dto) {
    if (dto.item) {
      fixXmlItemDto(dto.item);
    }
    if (dto.contract) {
      fixContractDto(dto.contract);
    }
  }
}