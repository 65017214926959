import * as angular from "angular";

export class MandatorSelectorDirective implements angular.IDirective {

  restrict = 'E';

  scope = {
    ngModel: "="
  };

  templateUrl = '/ClientApp/src/ajs/Views/Common/MandatorSelector.htm';

  constructor() { }

  static factory(): angular.IDirectiveFactory {
    const directive = () => new MandatorSelectorDirective();
    directive.$inject = [];
    return directive;
  }
}
