import angular from "angular";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { PrintArticleDto } from "src/ajs/Models/Erp/PrintArticleDto";
import { QueryArticleResultDto } from "src/ajs/Models/Erp/QueryArticleResultDto";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { MandatorService } from "src/app/core/services/mandator.service";

createPrintArticleLabelController.$inject = ['$scope', '$mdDialog', 'ApiHandlerService', 'PrinterId', 'theme', 'MandatorServiceNgx'];

export function createPrintArticleLabelController(
  $scope: IPrintArticleLabelControllerScope,
  $mdDialog: angular.material.IDialogService,
  ApiHandlerService: ApiHandlerService,
  PrinterId: number,
  theme: ThemeDto,
  MandatorServiceNgx: MandatorService
) {
  addDefaultScopeFunctions($scope, theme);
  $scope.IsLoading = true;
  $scope.Model = {} as PrintArticleDto;
  $scope.Model.Quantity = 1;
  $scope.Model.ArticleNumber = null;
  $scope.Model.PrinterId = PrinterId;
  $scope.SearchText = null;
  $scope.SelectedArticle = null;

  $scope.CanExecute = function () {
    return $scope.Model.Quantity && $scope.Model.Quantity > 0 && $scope.Model.ArticleNumber !== null;
  };

  $scope.SelectionChanged = function () {
    if ($scope.SelectedArticle) {
      $scope.Model.ArticleNumber = $scope.SelectedArticle.ArticleNumber;
    } else {
      $scope.Model.ArticleNumber = null;
    }
  };

  $scope.QueryArticle = function (searchText) {
    return new Promise(resolve => {
      ApiHandlerService.SendRequest("Erp", "QueryArticleBySearchText", { query: searchText, mandatorId: MandatorServiceNgx.selectedMandatorId }).then(function (data: QueryArticleResultDto) {
        resolve(data.Result);
      });
    });
  };

  $scope.Print = function () {
    ApiHandlerService.SendRequest("Erp", "PrintArticleLabel", { model: $scope.Model, mandatorId: MandatorServiceNgx.selectedMandatorId }).then(_ => { });
  };

  $scope.Cancel = function () {
    $mdDialog.hide();
  };
}

interface IPrintArticleLabelControllerScope extends angular.IScope {
  IsLoading: boolean;
  Model: PrintArticleDto;
  SearchText: string;
  SelectedArticle: { ArticleNumber: string };

  CanExecute: () => void;
  SelectionChanged: () => void;
  QueryArticle: (searchText: string) => Promise<unknown>;
  Print: () => void;
  Cancel: () => void;
}

