import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'cp-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { 'class': 'cp-searchbar' }
})
export class SearchbarComponent {

  @Input()
  searchFormControl: UntypedFormControl;

}

