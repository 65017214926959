import angular, { IScope } from "angular";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { ConfigurationDto } from "src/ajs/Models/SystemSearch/ConfigurationDto";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";


createaXsSystemSearchAdminDialogController.$inject = ['$scope', '$mdDialog', 'ApiHandlerService', 'theme'];

export function createaXsSystemSearchAdminDialogController(
  $scope: IaXsSystemSearchAdminDialogControllerScope,
  $mdDialog: angular.material.IDialogService,

  ApiHandlerService: ApiHandlerService,
  theme: ThemeDto
) {
  addDefaultScopeFunctions($scope, theme);
  $scope.IsLoading = true;
  $scope.Model = {} as ConfigurationDto;
  $scope.Model.Type = 0;

  ApiHandlerService.SendRequest("aXsSystemSearch", "GetConfiguration").then(function (data: ConfigurationDto) {
    $scope.Model = data;
  });


  $scope.SaveData = function () {
    ApiHandlerService.SendRequest("aXsSystemSearch", "SetConfiguration", $scope.Model).then(function (data: ConfigurationDto) {
      $scope.Model = data;
      $scope.Cancel();
    });
  };

  $scope.Cancel = function () {
    $mdDialog.hide();
  };
}


interface IaXsSystemSearchAdminDialogControllerScope extends IScope {
  IsLoading: boolean;
  Model: ConfigurationDto

  SaveData: () => void;
  Cancel: () => void;
}
