import { environment } from "./environment";

/** Gibt die Url der legacy ASP.net MVC5 zurück. */
function getBaseUrlAspNetMvc() {
  if (environment.baseUrlAspNetMvc) {
    return ensureTrailingSlash(environment.baseUrlAspNetMvc);
  }
  return ensureTrailingSlash(document.getElementsByTagName('base')[0].href);
}

/** Gibt die Url für den legacy SignalR Hub zurück. */
export function getBaseUrlSignalR() {
  if (environment.baseUrlSignalR) {
    return ensureTrailingSlash(environment.baseUrlSignalR);
  }
  return getBaseUrlAspNetMvc() + "signalr/";
}

/** Gibt die Url der legacy Api1 zurück. Die Api1 liegt hardcodiert im Pfad /Api der ASP.net MVC5 App. */
export function getBaseUrlApi1() {
  if (environment.baseUrlApi1) {
    return ensureTrailingSlash(environment.baseUrlApi1);
  }

  // Wenn wir ng serve verwenden, müssen wir die Anfragen über den Angular Dev Proxy schicken,
  // weil sonst Set-Cookie ignoriert wird und man sich nicht einloggen kann.
  if (!environment.production) {
    const isNgServe = document.getElementsByTagName('base')[0].href.includes('http://localhost:4200/');
    if (isNgServe) {
      return "http://localhost:4200/api1/";
    }
  }
  return getBaseUrlAspNetMvc() + "Api/";
}

/** Gibt die Url der neuen .NET Core Api zurück. */
export function getBaseUrlApi2() {
  if (environment.baseUrlApi2) {
    return ensureTrailingSlash(environment.baseUrlApi2);
  }

  // Die .NET6 Api sollte im IIS als virtuelles Unterverzeichnis /api2 eingehängt werden.
  return ensureTrailingSlash(document.getElementsByTagName('base')[0].href) + "api2/";
}

function ensureTrailingSlash(path: string): string {
  if (path?.length) {
    if (path[path.length - 1] !== '/') {
      path += '/';
    }
  }
  return path;
}
