import angular, { IPromise, IScope, ITimeoutService } from 'angular';
import { copyToClipboard } from 'src/ajs/Utils/HestiaCommon';
import { DragAndDropPanel } from '../Directives/DragAndDropPanel';
import { addDefaultScopeFunctions } from '../Functions/add-default-scope-functions';
import { GenerateHashResultDto } from '../Models/GenerateHashResultDto';
import { ThemeDto } from '../Models/ThemeDto';
import { ApiHandlerService } from '../Services/ApiHandlerService';
import { StorageService, StorageServiceInstance } from '../Services/StorageService';


createPasswordGeneratorController.$inject = ['$scope', 'mdPanelRef', '$timeout', 'StorageService', 'ApiHandlerService', 'theme'];

export function createPasswordGeneratorController(
  $scope: IPasswordGeneratorControllerScope,
  mdPanelRef: angular.material.IPanelRef,
  $timeout: ITimeoutService,
  StorageService: StorageService,
  ApiHandlerService: ApiHandlerService,
  theme: ThemeDto
) {
  addDefaultScopeFunctions($scope, theme);
  $scope.Storage = StorageService.CreateInstance("PasswordGeneratorController");
  $scope.CharsetDefault = "abcdefghijklmnopqrstuvwxyz0123456789";
  $scope.CharsetUppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  $scope.CharsetSpecial = "!§$%&()=.-_#+@[]{}";
  $scope.PasswordLength = $scope.Storage.GetOrCreate("PasswordLength", 8);
  $scope.UpperCaseLetters = $scope.Storage.GetOrCreate("UpperCaseLetters", true);
  $scope.PasswordCount = $scope.Storage.GetOrCreate("PasswordCount", 5);
  $scope.SpecialChars = $scope.Storage.GetOrCreate("SpecialChars", true);
  $scope.Hash = {
    Secret: "8544E3B47ECA58F9583043F8",
    Algorithm: 'HMAC-SHA-1',
    Value: null,
    Result: null
  };

  $scope.Refresh = function () {
    $scope.Passwords = [];
    let charset = $scope.CharsetDefault;
    if ($scope.UpperCaseLetters) {
      charset += $scope.CharsetUppercase;
    }
    if ($scope.SpecialChars) {
      charset += $scope.CharsetSpecial;
    }
    for (let i = 0; i < $scope.PasswordCount; i++) {
      $scope.Passwords.push({ RowNumber: i + 1, Password: $scope.GeneratePassword(charset, $scope.PasswordLength) });
    }
  };

  $scope.FilterChanged = function (buttonId: any, length: number, count: number, upper: any, special: any) {
    $(".pwbutton").removeClass("md-accent");
    if (buttonId) {
      $("#" + buttonId).addClass("md-accent");
      $scope.Storage.Set("ButtonId", buttonId);
    }
    if (length) {
      $scope.PasswordLength = length;
    }
    if (count) {
      $scope.PasswordCount = count;
    }
    if (upper !== undefined) {
      $scope.UpperCaseLetter = upper;
    }
    if (special !== undefined) {
      $scope.SpecialChars = special;
    }
    $scope.Storage.Set("PasswordLength", $scope.PasswordLength);
    $scope.Storage.Set("UpperCaseLetters", $scope.UpperCaseLetters);
    $scope.Storage.Set("PasswordCount", $scope.PasswordCount);
    $scope.Storage.Set("SpecialChars", $scope.SpecialChars);
    $scope.Refresh();
  };

  $scope.GeneratePassword = function (charset: string, length: number) {
    let retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  $scope.CopyToClipboard = function (password: any) {
    for (let i = 0; i < $scope.Passwords.length; i++) {
      $scope.Passwords[i].LastCopied = false;
    }
    password.Copied = true;
    password.LastCopied = true;

    copyToClipboard(password.Password);
  };

  $scope.SetFilterValues = function (length: number, uppercase: any, specialchars: any, buttonId: any) {
    $scope.PasswordLength = length;
    $scope.UpperCaseLetters = uppercase;
    $scope.SpecialChars = specialchars;
    $scope.FilterChanged(buttonId);
    $scope.Refresh();
  };

  $scope.ChangeView = function () {
    $scope.IsInHashView = !$scope.IsInHashView;
  };

  $scope.TryCalculateHash = function () {
    if ($scope.CurrentTimeout) {
      $timeout.cancel($scope.CurrentTimeout);
    }
    $scope.CurrentTimeout = $timeout(function () {
      if (($scope.Hash.Secret || $scope.Hash.Algorithm === 'SHA256') && $scope.Hash.Value) {
        ApiHandlerService.SendRequest("Toolbox", "GenerateHash", {
          value: $scope.Hash.Value, secret: $scope.Hash.Secret, algorithm: $scope.Hash.Algorithm
        }).then(function (data: GenerateHashResultDto) {
          $scope.Hash.Result = data.Result;
          $scope.Hash.Value = data.Input;
          $scope.Hash.Secret = data.Key;
          $scope.CurrentTimeout = null;
        });
      }
    }, 1000);

  };

  $scope.DragAndDropPanel = new DragAndDropPanel("Passwortgenerator", mdPanelRef);
  $scope.DragAndDropPanel.AddFunction("autorenew", $scope.ChangeView);
  $scope.DragAndDropPanel.AddFunction("view_carousel", () => { $scope.Toggle('#FilterContent', '#FilterButtons'); });

  $scope.DragAndDropPanel.AddFunction("refresh", $scope.Refresh);

  $timeout(function () {
    $scope.FilterChanged($scope.Storage.GetOrCreate("ButtonId", null));
  }, 50);
}

interface IPasswordGeneratorControllerScope extends IScope {

  Storage: StorageServiceInstance;
  CharsetDefault: string;
  CharsetUppercase: string;
  CharsetSpecial: string;
  PasswordLength: number;
  UpperCaseLetters: any;
  PasswordCount: number;
  SpecialChars: any;
  Hash: {
    Secret: string,
    Algorithm: string,
    Value: string,
    Result: string
  }
  Passwords: any[];
  UpperCaseLetter: any;
  IsInHashView: boolean;
  CurrentTimeout: IPromise<void>;
  DragAndDropPanel: DragAndDropPanel;

  Refresh: () => void;
  GeneratePassword: (charset: string, length: number) => string;
  FilterChanged: (buttonId: any, length?: number, count?: number, upper?: any, special?: any) => void;
  CopyToClipboard: (password: any) => void;
  ChangeView: () => void;
  SetFilterValues: (length: number, uppercase: any, specialchars: any, buttonId: any) => void;
  TryCalculateHash: () => void;
  Toggle: (content: string, filer: string) => void;
}
