import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { filter, merge, skipWhile, startWith, Subject, switchMap, takeUntil } from 'rxjs';
import { TimeStatisticsDto } from '../../dto/time-statistics-dto';
import { ErpService } from '../../services/erp.service';

@Component({
  selector: 'app-time-statistics',
  templateUrl: './time-statistics.component.html',
  styleUrls: ['./time-statistics.component.scss']
})
export class TimeStatisticsComponent implements OnDestroy {

  private _addressId: number;

  @Input() set addressId(value: number) {
    if (this._addressId !== value) {
      this._addressId = value;
      this.refresh$.next();
    }
  }

  get addressId(): number {
    return this._addressId;
  }

  data: TimeStatisticsDto[] = [];
  dataAggregateFunctions: TimeStatisticsDto[] = [];

  displayedColumns: string[] = ["date", "callTime", "taskTime", "totalTime", "chargedTime"];

  @ViewChild(MatSort) sort: MatSort;

  readonly startDate = new UntypedFormControl();
  readonly endDate = new UntypedFormControl();
  private readonly destroy$ = new Subject<void>();
  private readonly refresh$ = new Subject<void>();

  constructor(
    private erpService: ErpService,
  ) {
    this.startDate.patchValue(new Date(new Date().getFullYear() + "-01-01"));
    this.endDate.patchValue(new Date(new Date().getFullYear() + "-12-31"));

    merge(
      this.startDate.valueChanges,
      this.endDate.valueChanges,
      this.refresh$
    ).pipe(
      takeUntil(this.destroy$),
      startWith({}),
      filter(() => this.startDate.value && this.endDate.value),
      switchMap(() => this.erpService.getTimeStatistics(
        this.addressId,
        this.startDate.value,
        this.endDate.value,
      ))
    ).subscribe(result => {
      this.data = result;
      this.sortData(this.sort);
      this.calculateAggregateFunctions();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.refresh$.complete();
  }

  private calculateAggregateFunctions() {

    if (this.data) {
      let sumCallTime: number = 0;
      let sumTaskTime: number = 0;
      let sumChargedTime: number = 0;
      let sumTotalTime: number = 0;

      for (const x of this.data) {
        sumCallTime += x.CallTime;
        sumTaskTime += x.TaskTime;
        sumChargedTime += x.ChargedTime;
        sumTotalTime += x.TotalTime;
      }

      const meanCallTime = sumCallTime / this.data.length;
      const meanTaskTime = sumTaskTime / this.data.length;
      const meanChargedTime = sumChargedTime / this.data.length;
      const meanTotalTime = sumTotalTime / this.data.length;

      const meanAggregateFunctionsEntry = {
        Date: null,
        CallTime: meanCallTime,
        TaskTime: meanTaskTime,
        ChargedTime: meanChargedTime,
        TotalTime: meanTotalTime
      };

      const sumAggregateFunctionEntry = {
        Date: null,
        CallTime: sumCallTime,
        TaskTime: sumTaskTime,
        ChargedTime: sumChargedTime,
        TotalTime: sumTotalTime
      };

      this.dataAggregateFunctions = [];
      this.dataAggregateFunctions.push(meanAggregateFunctionsEntry);
      this.dataAggregateFunctions.push(sumAggregateFunctionEntry);
    }

  }

  sortData(sort: Sort) {

    if (this.data) {
      const data = this.data.slice();
      if (!sort.active || sort.direction === '') {
        this.data = data;
        return;
      }

      this.data = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'date':
            return this.compare(a.Date, b.Date, isAsc);
          case 'callTime':
            return this.compare(a.CallTime, b.CallTime, isAsc);
          case 'taskTime':
            return this.compare(a.TaskTime, b.TaskTime, isAsc);
          case 'totalTime':
            return this.compare(a.TotalTime, b.TotalTime, isAsc);
          case 'chargedTime':
            return this.compare(a.ChargedTime, b.ChargedTime, isAsc);
          default:
            return 0;
        }
      });
    }

  }


  private compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
