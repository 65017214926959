import { ReadXmlResultItemDto, fixReadXmlResultItemDto } from "./read-xml-result-item-dto";

export interface ReadXmlResultDto {
  invoiceNumber: string;
  invoiceDate: string;
  taxedAmount: string;
  vatRate: string;
  amount: string;
  totalGrossAmount: string;
  payableAmount: string;
  items: ReadXmlResultItemDto[];
}

export function fixReadXmlResultDto(dto: ReadXmlResultDto) {

  if (dto) {
    for (const item of dto.items) {
      fixReadXmlResultItemDto(item);
    }
  }

}