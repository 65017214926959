import { OwlDateTimeFormats } from "@danielmoncada/angular-datetime-picker";

/** Die DateTime Formats werden für OwlDateTime benötigt. */

export const OWL_GERMAN_DATETIME_FORMATS: OwlDateTimeFormats = {
  parseInput: null,
  fullPickerInput: { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' },
  datePickerInput: { year: 'numeric', month: '2-digit', day: '2-digit' },
  timePickerInput: { hour: '2-digit', minute: '2-digit' },
  monthYearLabel: { year: 'numeric', month: 'short' },
  dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
  monthYearA11yLabel: { year: 'numeric', month: 'long' },
};
