import * as angular from "angular";
import { ITimeoutService } from "angular";
import * as moment from "moment";
import { Duration } from "moment";
import { AppointmentDto } from "src/ajs/Models/PublicContacts/AppointmentDto";
import { AppointmentType } from "src/ajs/Models/PublicContacts/AppointmentType";
import { PublicContactDto } from "src/ajs/Models/PublicContacts/PublicContactDto";
import { TimelineMode } from "./timeline-mode.enum";
import { TimelineDatePickerService } from './TimelineDatePickerService';
import { TimelineOptions } from "./TimelineOptions";

interface CalculatedDay {
  HtmlId: string;
  ColSpan: number;
  Id: number;
  Title: string;
}

interface CalculatedTick {
  Id: string;
  Label: string;
  MobileLabel: string;
}

interface TimelineEntry {
  Id?: string;
  Title?: string;
  Class: string;
  Type?: AppointmentType;
  Addition?: AppointmentType;
  Duration?: moment.Duration;
  Colspan: number;
  WholeDay?: boolean;
  StartDate?: moment.Moment | Date;
  EndDate?: moment.Moment | Date;
  Start?: string;
  End?: string;
  Location?: string;
  Children?: TimelineEntry[];
}

export class Timeline {

  public static active_timeline: Timeline = null;

  Options: TimelineOptions;
  ContactColumns: any = {};
  HolidayColumns: any = {};
  CalculatedTicks: CalculatedTick[] = [];
  CalculatedDays: CalculatedDay[] = [];
  DrawDateLine: boolean = false;
  LastHeight: number;

  get isWeekView(): boolean {
    return this.Options.mode === TimelineMode.Week;
  }


  constructor(
    private readonly $timeout: ITimeoutService,
    readonly mode: TimelineMode,
    readonly TimelineDatePickerService: TimelineDatePickerService
  ) {
    this.Options = new TimelineOptions(mode, TimelineDatePickerService);


    /* Object init code */
    Timeline.active_timeline = this;
    this.$timeout(() => this.CheckTimelineConsitancy(this), 1000);
  }

  public SetStartTime(date: any) {
    if (this.Options.Hours < 24) {
      this.ToggleHours();
    }
    this.Options.SetStartTime(date);
    this.CalculatedTicks = this.GetTicks();
    this.UpdateContactColumnSpan();
    this.CalculateDays();

    this.UpdateTimeLabelsPosition();
    this.UpdateCurrentTimeLine();
    this.DrawHelperLines(true);
  };

  public ToggleHours() {
    if (this.Options.Hours === 16) {
      this.Options.Hours = 24;
      this.Options.StartTime.setHours(0);
      this.Options.StartTime.setMinutes(0);
      this.Options.StartTime.setMilliseconds(0);
      this.Options.Ticks = 12;
      this.Options.TickMinutes = 120;
    } else {
      this.Options.Hours = 16;
      this.Options.StartTime.setHours(6);
      this.Options.StartTime.setMinutes(0);
      this.Options.StartTime.setMilliseconds(0);
      this.Options.Ticks = 16;
      this.Options.TickMinutes = 60;
    }
    this.CalculatedTicks = this.GetTicks();
    this.UpdateContactColumnSpan();
    this.CalculateDays();

    this.UpdateTimeLabelsPosition();
    this.UpdateCurrentTimeLine();
    this.DrawHelperLines(true);
  };

  public ToggleWeekMonth() {
    if (this.Options.mode === TimelineMode.Week) {
      this.Options.mode = TimelineMode.Month;
      this.Options.Hours = 672;
      this.Options.Ticks = 28;
      this.Options.TickMinutes = 1440;
    }
    else {
      this.Options.mode = TimelineMode.Week;
      this.Options.Hours = 168;
      this.Options.Ticks = 7;
      this.Options.TickMinutes = 1440;
    }

    this.CalculatedTicks = this.GetTicks();
    this.UpdateContactColumnSpan();
    this.CalculateDays();

    this.UpdateTimeLabelsPosition();
    this.UpdateCurrentTimeLine();
    this.DrawHelperLines(true);
  }

  public GetColumns(): number {
    return this.Options.Ticks * this.Options.TickMinutes;
  };

  public GetTicks(): CalculatedTick[] {
    var ticks: CalculatedTick[] = [];
    for (var i = 0; i < this.Options.Ticks; i++) {
      var currentTick: CalculatedTick = {
        Id: "tick" + i,
        Label: '',
        MobileLabel: ''
      };

      if (this.Options.mode === TimelineMode.Day) {
        currentTick.Label = moment(this.Options.StartTime).add(this.Options.TickMinutes * i, 'm').format('HH:mm');
      }
      else if (this.Options.mode === TimelineMode.Week) {
        currentTick.Label = moment(this.Options.StartTime).locale('de').add(this.Options.TickMinutes * i, 'm').format('dddd DD.MM');
        currentTick.MobileLabel = moment(this.Options.StartTime).locale('de').add(this.Options.TickMinutes * i, 'm').format('DD.MM');
      }
      else if (this.Options.mode === TimelineMode.Month) {
        currentTick.Label = moment(this.Options.StartTime).add(this.Options.TickMinutes * i, 'm').format('DD.MM.');
      }

      ticks.push(currentTick);
    }
    return ticks;
  };

  public DayBack(onlyOne: boolean) {
    if (!this.Options.IsMinimum) {
      var days = 1;
      if (this.isWeekView && !onlyOne) {
        days = 7;
      }
      else if (this.Options.mode === TimelineMode.Month) {
        days = 7;
      }

      var time = this.Options.StartTime;
      this.SetStartTime(time.setDate(this.Options.StartTime.getDate() - days));
      this.UpdateContactColumnSpan();
      var newTicks = this.GetTicks();
      for (var i = 0; i < newTicks.length; i++) {
        this.CalculatedTicks[i].Label = newTicks[i].Label;
      }
      this.CalculateDays();
      this.DrawHelperLines();
      this.UpdateTimeLabelsPosition();
      this.UpdateCurrentTimeLine();
    }
  };

  public DayForward(onlyOne: boolean) {
    if (!this.Options.IsMaximum) {
      var days = 1;
      if (this.isWeekView && !onlyOne) {
        days = 7;
      }
      var time = this.Options.StartTime;
      this.SetStartTime(time.setDate(this.Options.StartTime.getDate() + days));
      this.UpdateContactColumnSpan();
      var newTicks = this.GetTicks();
      for (var i = 0; i < newTicks.length; i++) {
        this.CalculatedTicks[i].Label = newTicks[i].Label;
      }
      this.CalculateDays();
      this.DrawHelperLines();
      this.UpdateTimeLabelsPosition();
      this.UpdateCurrentTimeLine();
    }
  };

  public CenterCalendar() {
    //this.Options.TickMinutes = 90;
    var hours = this.Options.Hours === 16 ? 6 : 0;
    this.Options.StartTime = new Date();
    this.Options.StartTime.setHours(hours);
    this.Options.StartTime.setMinutes(0);
    this.Options.StartTime.setSeconds(0);
    this.Options.StartTime.setMilliseconds(0);
    if (this.isWeekView) {
      this.Options.StartTime = moment(this.Options.StartTime).startOf('isoWeek').toDate();
    }
    this.SetStartTime(this.Options.StartTime);
    this.UpdateContactColumnSpan();
    var newTicks = this.GetTicks();
    if (this.CalculatedTicks && this.CalculatedTicks.length) {
      for (var i = 0; i < newTicks.length; i++) {
        this.CalculatedTicks[i].Label = newTicks[i].Label;
      }
    }
    this.CalculateDays();
    this.DrawHelperLines();
    this.UpdateTimeLabelsPosition();
    this.UpdateCurrentTimeLine();
  };

  public CalculateDays() {
    if (this.Options.mode === TimelineMode.Week) {
      const weekStart = new Date(this.Options.StartTime);
      const weekEnd = this.Options.CaclulatedEndTime().add(-1, 'day');
      const startWeek = moment(weekStart).isoWeek();
      const endWeek = moment(weekEnd).isoWeek();

      if (startWeek === endWeek) {
        this.DrawDateLine = false;
        this.CalculatedDays = [{
          Id: 1,
          HtmlId: "day-column-1",
          Title: "KW " + moment(weekStart).isoWeek() + ", " + weekStart.getFullYear(),
          ColSpan: this.GetColumns()
        }];
      }
      else {
        this.DrawDateLine = true;

        let firstColumns: number = 0;
        for (var i = 1; i <= 7; i++) {
          var newWeek = moment(weekStart).add(i, 'day').isoWeek();
          if (newWeek !== startWeek) {
            firstColumns = this.GetColumns() / 7 * i;
            break;
          }
        }

        this.CalculatedDays = [{
          Id: 1,
          HtmlId: "day-column-1",
          Title: "KW " + startWeek + ", " + weekStart.getFullYear(),
          ColSpan: firstColumns
        }, {
          Id: 2,
          HtmlId: "day-column-2",
          Title: "KW " + endWeek + ", " + weekStart.getFullYear(),
          ColSpan: this.GetColumns() - firstColumns
        }];
      }
      this.DrawHelperLines();
    }
    else if (this.Options.mode === TimelineMode.Month) {
      this.DrawDateLine = true;

      const startDate = moment(this.Options.StartTime);
      const startKw = startDate.isoWeek();

      let firstWeekColSpan = this.Options.TickMinutes * 7;
      for (var i = 1; i <= 7; i++) {
        var nextKw = moment(startDate).add(i, 'day').isoWeek();
        if (nextKw !== startKw) {
          firstWeekColSpan = this.GetColumns() / 7 / 4 * i;
          break;
        }
      }

      const fullWeekColspan = 1440 * 7;
      const week5ColSpan = this.GetColumns() - firstWeekColSpan - 3 * fullWeekColspan;
      const week2StartDate = moment(startDate).add(7, 'day');
      const week3StartDate = moment(startDate).add(14, 'day');
      const week4StartDate = moment(startDate).add(21, 'day');

      this.CalculatedDays = [
        { Id: 1, HtmlId: "day-column-1", Title: "KW " + startKw + ", " + startDate.year(), ColSpan: firstWeekColSpan },
        { Id: 2, HtmlId: "day-column-2", Title: "KW " + week2StartDate.isoWeek() + ", " + week2StartDate.year(), ColSpan: fullWeekColspan },
        { Id: 3, HtmlId: "day-column-3", Title: "KW " + week3StartDate.isoWeek() + ", " + week3StartDate.year(), ColSpan: fullWeekColspan },
        { Id: 4, HtmlId: "day-column-4", Title: "KW " + week4StartDate.isoWeek() + ", " + week4StartDate.year(), ColSpan: fullWeekColspan },
      ];

      if (week5ColSpan > 0) {
        const week5StartDate = moment(startDate).add(28, 'day');
        this.CalculatedDays.push(
          { Id: 5, HtmlId: "day-column-5", Title: "KW " + week5StartDate.isoWeek() + ", " + week5StartDate.year(), ColSpan: week5ColSpan }
        );
      }
    }
    else {
      var startDate = new Date(this.Options.StartTime);
      var endDate = new Date(startDate.getTime() + this.Options.TickMinutes * this.Options.Ticks * 60000);
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      startDate.setMilliseconds(0);
      endDate.setHours(0);
      endDate.setMinutes(0);
      endDate.setSeconds(0);
      endDate.setMilliseconds(0);
      var minutes = Math.round((endDate.getTime() - this.Options.StartTime.getTime()) / 60000);

      if (minutes > 0 && minutes !== this.GetColumns()) {
        this.DrawDateLine = true;
        this.CalculatedDays = [{
          Id: 1,
          HtmlId: "day-column-1",
          Title: moment(startDate).locale('de').format("dddd, DD.MM.YYYY"),
          ColSpan: minutes
        }, {
          Id: 2,
          HtmlId: "day-column-2",
          Title: moment(endDate).locale('de').format("dddd, DD.MM.YYYY"),
          ColSpan: this.GetColumns() - minutes
        }];
      } else {
        this.DrawDateLine = false;
        this.CalculatedDays = [{
          Id: 1,
          HtmlId: "day-column-1",
          Title: moment(startDate).locale('de').format("dddd, DD.MM.YYYY"),
          ColSpan: this.GetColumns()
        }];
      }
    }

  };

  public InitializeContactDictionary(contacts: PublicContactDto[]) {
    for (const contact of contacts) {
      for (const appointment of contact.Appointments) {
        appointment.MomentStart = moment(appointment.Start);
        appointment.MomentEnd = moment(appointment.End);
      }

      for (const appointment of contact.Holidays) {
        appointment.MomentStart = moment(appointment.Start);
        appointment.MomentEnd = moment(appointment.End);
      }

      for (let i = 1; i < contact.Appointments.length; i++) {
        if (contact.Appointments[i - 1].MomentEnd > contact.Appointments[i].MomentStart) {
          if (!contact.Appointments[i - 1].IsAccumulated) {
            contact.Appointments[i - 1].IsAccumulated = true;
            contact.Appointments[i - 1].Children = [];
            contact.Appointments[i - 1].Children.push(contact.Appointments[i - 1]);
            contact.Appointments[i - 1].ChildCount = 1;
          }
          if (contact.Appointments[i].MomentEnd > contact.Appointments[i - 1].MomentEnd) {
            contact.Appointments[i - 1].MomentEnd = contact.Appointments[i].MomentEnd;
          }
          contact.Appointments[i - 1].Children.push(contact.Appointments[i]);
          contact.Appointments[i - 1].ChildCount++;
          contact.Appointments.splice(i, 1);
          i--;
        }
      }

      for (let i = 1; i < contact.Holidays.length; i++) {
        if (contact.Holidays[i - 1].MomentEnd > contact.Holidays[i].MomentStart) {
          if (!contact.Holidays[i - 1].IsAccumulated) {
            contact.Holidays[i - 1].IsAccumulated = true;
            contact.Holidays[i - 1].Children = [];
            contact.Holidays[i - 1].Children.push(contact.Holidays[i - 1]);
            contact.Holidays[i - 1].ChildCount = 1;
          }
          if (contact.Holidays[i].MomentEnd > contact.Holidays[i - 1].MomentEnd) {
            contact.Holidays[i - 1].MomentEnd = contact.Holidays[i].MomentEnd;
          }
          contact.Holidays[i - 1].Children.push(contact.Holidays[i]);
          contact.Holidays[i - 1].ChildCount++;
          contact.Holidays.splice(i, 1);
          i--;
        }
      }
    }
    this.GenerateContactColumns(contacts);
  };

  public ZoomIn(event) {
    if (!this.isWeekView) {
      var minTickMinutes = 10;
      if (this.Options.TickMinutes > minTickMinutes) {
        var currentTickNumber = this.GetMouseOverTick(event);
        var currentTickTime = new Date(this.Options.StartTime);
        currentTickTime.setMinutes(currentTickTime.getMinutes() + this.Options.TickMinutes * currentTickNumber);
        this.Options.TickMinutes -= 10;
        this.Options.TickMinutes = Math.max(this.Options.TickMinutes, minTickMinutes);
        this.Options.SetStartTime(new Date(currentTickTime.getTime() - this.Options.TickMinutes * currentTickNumber * 60000));
        this.UpdateContactColumnSpan();
        var newTicks = this.GetTicks();
        for (var i = 0; i < newTicks.length; i++) {
          this.CalculatedTicks[i].Label = newTicks[i].Label;
        }
        this.CalculateDays();
        this.DrawHelperLines();
        this.UpdateTimeLabelsPosition();
        this.UpdateCurrentTimeLine();
      }
    }
  };

  public ZoomOut(event) {
    if (!this.isWeekView) {
      var maxTickMinutes = 24 * 60 / this.Options.Ticks;
      if (this.Options.TickMinutes < maxTickMinutes) {
        var currentTickNumber = this.GetMouseOverTick(event);
        var currentTickTime = new Date(this.Options.StartTime);
        currentTickTime.setMinutes(currentTickTime.getMinutes() + this.Options.TickMinutes * currentTickNumber);
        this.Options.TickMinutes += 10;
        this.Options.TickMinutes = Math.min(this.Options.TickMinutes, maxTickMinutes);
        this.Options.SetStartTime(new Date(currentTickTime.getTime() - this.Options.TickMinutes * currentTickNumber * 60000));

        //this.Options.StartTime = this.Options.CalculateCurrentStartTime();
        this.UpdateContactColumnSpan();
        var newTicks = this.GetTicks();
        for (var i = 0; i < newTicks.length; i++) {
          this.CalculatedTicks[i].Label = newTicks[i].Label;
        }
        this.CalculateDays();
        this.DrawHelperLines();
        this.UpdateTimeLabelsPosition();
        this.UpdateCurrentTimeLine();
      }
    }
  };

  public GetMouseOverTick(event) {
    var mouseX = event.originalEvent.clientX;
    for (var i = 0; i < this.CalculatedTicks.length; i++) {
      var tick = $("#" + this.CalculatedTicks[i].Id)[0].getBoundingClientRect();
      var left = tick.left;
      var width = tick.width;
      if (mouseX >= left && mouseX <= left + width)
        return i;
    }
    return 0;
  };

  public UpdateContactColumnSpan() {
    var object = this;
    var currentEnd = object.Options.CaclulatedEndTime();
    var currentStart = moment(object.Options.StartTime);
    var columnCount = object.GetColumns();
    angular.forEach(this.HolidayColumns, function (contactList) {
      angular.forEach(contactList, function (entry) {
        var durationSum = 0;
        var entryStart = entry.StartDate;
        if (entryStart < currentStart) {
          entryStart = currentStart;
        }
        var entryEnd = entry.EndDate;
        if (entryEnd > currentEnd) {
          entryEnd = currentEnd;
        }
        if (entryStart >= currentStart && entryStart < currentEnd) {
          var duration = moment.duration(entryEnd.diff(entryStart)).asMinutes();
          if (duration < 0) {
            duration = 0;
          }
          if (durationSum + duration >= columnCount) {
            entry.Colspan = columnCount - durationSum;
            durationSum = columnCount;
          }
          else {
            durationSum += duration;
            entry.Colspan = duration;
          }
        } else {
          entry.Colspan = 0;
        }
      });
    });
    angular.forEach(this.ContactColumns, function (contactList) {
      angular.forEach(contactList, function (entry) {
        var durationSum = 0;
        var entryStart = entry.StartDate;
        if (entryStart < currentStart) {
          entryStart = currentStart;
        }
        var entryEnd = entry.EndDate;
        if (entryEnd > currentEnd) {
          entryEnd = currentEnd;
        }
        if (entryStart >= currentStart && entryStart < currentEnd) {
          var duration = moment.duration(entryEnd.diff(entryStart)).asMinutes();
          if (duration < 0) {
            duration = 0;
          }
          if (durationSum + duration >= columnCount) {
            entry.Colspan = columnCount - durationSum - 1;
            durationSum = columnCount;
          }
          else {
            durationSum += duration;
            entry.Colspan = duration;
          }
        } else {
          entry.Colspan = 0;
        }
      });
    });
  };

  public GenerateEntryList(appointments: AppointmentDto[]) {
    var currentEntryList: TimelineEntry[] = [];
    var currentStart = moment(this.Options.MinDate);
    var currentEnd = moment(this.Options.MaxDate);
    var free = 0;
    if (appointments.length && currentStart < currentEnd) {
      let duration: Duration = moment.duration(0);
      var currentEntryId = 0;
      var last_id = "first";
      while (appointments.length) {
        if (appointments[0].MomentStart > currentStart) {
          duration = moment.duration(currentStart.diff(appointments[0].MomentStart));
          currentEntryList.push({
            Id: last_id + "_free",
            Duration: duration,
            StartDate: currentStart,
            EndDate: appointments[0].MomentStart,
            Class: "calendar-free",
            Colspan: 0
          });
          currentStart = appointments[0].MomentStart;
        } else {
          var end = appointments[0].MomentEnd;
          if (end > currentEnd) {
            end = currentEnd;
          }
          duration = moment.duration(end.diff(currentStart));
          last_id = appointments[0].Id;
          if (appointments[0].IsAccumulated) {
            var children: TimelineEntry[] = [];
            for (var x = 0; x < appointments[0].Children.length; x++) {
              children.push({
                Title: appointments[0].Children[x].Subject,
                Colspan: 0,
                Class: "calendar-booked",
                Duration: duration,
                WholeDay: Math.abs(appointments[0].Children[x].MomentStart.diff(appointments[0].Children[x].MomentEnd, 'hours', true)) >= 24,
                Start: appointments[0].Children[x].MomentStart.format("HH:mm"),
                End: appointments[0].Children[x].MomentEnd.format("HH:mm"),
                Location: appointments[0].Children[x].Location
              });
            }
            currentEntryList.push({
              Id: appointments[0].Id,
              Title: "Überlappende Termine",
              Class: "calendar-accumulated",
              Duration: duration,
              Type: 2,
              Colspan: 0,
              WholeDay: Math.abs(appointments[0].MomentStart.diff(appointments[0].MomentEnd, 'hours', true)) >= 24,
              Start: appointments[0].MomentStart.format("HH:mm"),
              End: appointments[0].MomentEnd.format("HH:mm"),
              StartDate: currentStart,
              EndDate: end,
              Location: appointments[0].Location,
              Children: children
            });
          }
          else {
            const entry: TimelineEntry = {
              Id: appointments[0].Id,
              Title: appointments[0].Subject,
              Class: "calendar-booked",
              Type: 1,
              Addition: appointments[0].Type,
              Duration: duration,
              Colspan: 0,
              WholeDay: Math.abs(appointments[0].MomentStart.diff(appointments[0].MomentEnd, 'hours', true)) >= 24,
              StartDate: currentStart,
              EndDate: end,
              Start: appointments[0].MomentStart.format("HH:mm"),
              End: appointments[0].MomentEnd.format("HH:mm"),
              Location: appointments[0].Location
            };
            switch (appointments[0].Type) {
              case AppointmentType.CalendarPrivate:
                entry.Class += " calendar-private";
                break;
              case AppointmentType.CalendarOof:
                entry.Class += " calendar-oof";
                break;
              case AppointmentType.CalendarElswhere:
                entry.Class += " calendar-elswhere";
                break;
              case AppointmentType.CalendarTentative:
                entry.Class += " calendar-tentative";
                break;
              case AppointmentType.HolidayNotApproved:
                entry.Class += " holiday-not-approved";
                entry.Type = appointments[0].Type;
                break;
              case AppointmentType.HolidayApproved:
                entry.Class += " holiday-approved";
                entry.Type = appointments[0].Type;
                break;
              case AppointmentType.HolidayUnknown:
                entry.Class += " holiday-unknown";
                entry.Type = appointments[0].Type;
                break;
              case AppointmentType.HolidayTradeSchool:
                entry.Class += " holiday-trade-school";
                entry.Type = appointments[0].Type;
                break;
              case AppointmentType.HolidayCompanyEvent:
                entry.Class += " holiday-company-event";
                entry.Type = appointments[0].Type;
                break;
            }
            currentEntryList.push(entry);
          }
          currentStart = end;
          appointments.shift();
        }
      }
      if (currentStart < currentEnd) {
        duration = moment.duration(currentStart.diff(currentEnd));
        currentEntryList.push({
          Id: "last",
          Class: "calendar-free",
          StartDate: currentStart,
          EndDate: this.Options.MaxDate,
          Duration: duration,
          Colspan: 1
        });
      }
      currentStart = moment(this.Options.StartTime);
    } else {
      currentEntryList.push({
        Id: "last",
        Class: "calendar-free",
        StartDate: this.Options.MinDate,
        EndDate: this.Options.MaxDate,
        Colspan: 0
      });
    }
    return currentEntryList;
  };

  public GenerateContactColumns(contacts: PublicContactDto[]) {
    var object = this;
    if (!this.ContactColumns)
      this.ContactColumns = {};
    if (!this.HolidayColumns)
      this.HolidayColumns = {};
    angular.forEach(contacts, function (contact) {

      if (!object.ContactColumns[contact.AccountName]) {
        object.ContactColumns[contact.AccountName] = [];
      }
      var appointments = contact.Appointments.filter(f => true);
      var holidays = contact.Holidays.filter(f => true);

      object.ContactColumns[contact.AccountName] = object.GenerateEntryList(appointments);
      object.HolidayColumns[contact.AccountName] = object.GenerateEntryList(holidays);
    });
  };

  /* Render loop function */
  public CheckTimelineConsitancy(object: Timeline) {
    if (object === Timeline.active_timeline) {
      if ($("#timeline")[0]) {
        if (object.Options.StartTime === null) {
          object.CenterCalendar();
          object.CalculateDays();
          object.UpdateContactColumnSpan();
          object.CalculatedTicks = object.GetTicks();
          // The startTime changed, so we need to update the whole layout
          object.DrawHelperLines();
        }
        // Always draw the current time marker
        object.DrawHelperLines();
        object.UpdateCurrentTimeLine();
        object.UpdateTimeLabelsPosition();
      }
      this.$timeout(function () { object.CheckTimelineConsitancy(object); }, 50);
    }
  };

  /* Rendering */
  public DrawHelperLines(force: boolean = false) {
    var timeline = $("#timeline-container")[0];
    var tick = $("#tick0")[0];
    try {
      if (timeline && tick) {
        var offsetHeight = timeline.offsetHeight;
        var position = $(tick).position();
        var currentHeight = timeline.offsetHeight - position.top - 8;
        var firstLine = $("#v_" + this.CalculatedTicks[0].Id)[0];
        if (this.LastHeight !== currentHeight || force || !firstLine) {
          this.LastHeight = offsetHeight;
          if (force) {
            this.LastHeight = 0;
          }
          var first = true;
          var object = this;
          angular.forEach(this.CalculatedTicks, function (t) {
            var tick = $("#" + t.Id)[0];
            if (tick) {
              var newLine = $("#v_" + t.Id)[0];
              if (!newLine) {
                newLine = document.createElement("div");
                tick.append(newLine);
                newLine.id = "v_" + t.Id;
                newLine.className = "vertical-line";
              }
              if (first) {
                newLine.className = "vertical-line no-border";
                first = false;
              }

              var position = $(tick).position();
              if (position) {
                object.LastHeight = currentHeight;
                newLine.style.height = object.LastHeight + "px";
              }
            }
          });
          this.UpdateTimeLabelsPosition();
        }
      }
      if (this.DrawDateLine && this.Options.mode === TimelineMode.Week) {
        var dateBox = $("#day-column-2")[0];
        if (dateBox) {
          let dayLine = $("#day-line")[0];
          if (!dayLine) {
            dayLine = document.createElement("div");
            dateBox.append(dayLine);
            dayLine.id = "day-line";
            dayLine.className = "vertical-day-line";
          }
          dayLine.style.height = timeline.offsetHeight - 20 - 10 + "px";
        }
      }
      else if (this.DrawDateLine && this.Options.mode === TimelineMode.Month) {
        $("[id^=day-column-").each((index, dateBox) => {
          if (dateBox) {
            let dayLine: any = $(dateBox).children("#day-line")[0];
            if (!dayLine) {
              dayLine = document.createElement("div");
              dateBox.append(dayLine);
              dayLine.id = "day-line";
              dayLine.className = "vertical-day-line";
            }
            dayLine.style.height = timeline.offsetHeight - 20 - 10 + "px";
          }
        });
      }
      else {
        $(".vertical-day-line").remove();
      }

    } catch (exception) { /* igonre */ }
  };

  public UpdateCurrentTimeLine() {
    try {
      if (!this.isWeekView) {
        if ($("#tick0")[0]) {
          var timeline = $("#timeline-container")[0];
          var d = new Date();
          var diff = d.getTime() - this.Options.StartTime.getTime();
          var diffMinutes = Math.floor(diff / 1000 / 60);// minutes
          var divIdIdentifier = Math.floor(diffMinutes / this.Options.TickMinutes);
          var remainderMinutes = diffMinutes % this.Options.TickMinutes;
          var seconds = d.getSeconds();
          remainderMinutes = remainderMinutes + seconds / 60;
          if (divIdIdentifier >= 0 && divIdIdentifier < this.Options.Ticks) {
            var newLine = $(".current-time-line")[0];
            if (!newLine) {
              newLine = document.createElement("div");
              newLine.className = "current-time-line";
              newLine.style.top = 18 + "px";
            }
            var tick = $("#tick" + divIdIdentifier);
            var position = tick.position();
            var widht = tick.width();
            newLine.style.height = timeline.offsetHeight - position.top - 6 - 20 + "px";
            newLine.style.left = widht / this.Options.TickMinutes * remainderMinutes + "px";
            if (newLine.parentElement && newLine.parentElement !== tick[0]) {
              newLine.parentElement.removeChild(newLine);
            }
            if (newLine.parentElement === null) {
              tick.append(newLine);
            }
          }
          else {
            $(".current-time-line").remove();
          }
        }
      }
    } catch (exception) { /* igonre */ }
  };

  public UpdateTimeLabelsPosition() {
    if (!this.isWeekView) {
      if ($("#tick0")[0]) {
        var column = $("#tick0");
        var width = column.width();
        $(".time-label").css("margin-left", "-" + column.width() + "px");
      }
    }
  };
}

