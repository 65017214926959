import * as angular from "angular";
import { AppConfigService } from "src/app/core/services/app-config.service";
import { MandatorService } from "src/app/core/services/mandator.service";
import { UserService } from "src/app/core/services/user.service";
import { createMandatorSelectorModel, MandatorSelectorModel } from "../Directives/MandatorSelectorModel";
import { ThemeDto } from "../Models/ThemeDto";
import { ApiHandlerService } from "../Services/ApiHandlerService";
import { GlobalValueService } from "../Services/GlobalValueService";
import { LocalizationLanguage } from "../Services/Localization/LocalizationLanguage";
import { LocalizationService, PluginDict } from "../Services/Localization/LocalizationService";
import { ScreenWidthService } from "../Services/ScreenWidthService";
import { SearchBarService } from "../Services/SearchBarService";
import { SignalRService } from "../Services/SignalRService";
import { StorageService } from "../Services/StorageService";
import { UserService as LegacyUserService } from "../Services/UserService";

export class UserBarController {
  static $inject = ['$scope', '$rootScope', '$location', '$mdPanel', '$q', 'UserService', 'UserServiceNgx', 'SearchBarService', 'SignalRService', 'ScreenWidthService', 'LocalizationService', 'ApiHandlerService', 'StorageService', 'theme', 'MandatorServiceNgx', 'GlobalValueService', 'BASE_URL_API1', 'AppConfigServiceNgx'];

  constructor(
    $scope: IUserBarControllerScope,
    $rootScope: angular.IRootScopeService,
    $location: angular.ILocationService,
    $mdPanel: any,
    $q: angular.IQService,
    UserService: LegacyUserService,
    UserServiceNgx: UserService,
    SearchBarService: SearchBarService,
    SignalRService: SignalRService,
    ScreenWidthService: ScreenWidthService,
    LocalizationService: LocalizationService,
    ApiHandlerService: ApiHandlerService,
    StorageService: StorageService,
    theme: ThemeDto,
    MandatorServiceNgx: MandatorService,
    GlobalValueService: GlobalValueService,
    BASE_URL_API1: string,
    appConfig: AppConfigService
  ) {

    $scope.Theme = {
      Name: theme?.ThemeDescription,
      Definition: theme
    };

    $scope.BASE_URL_API1 = BASE_URL_API1;
    $scope.UseTabbedMandatorSelector = appConfig.environment === "CP";
    $scope.ShowNotifications = appConfig.environment === "CP";
    $scope.MandatorServiceNgx = MandatorServiceNgx;

    $scope.UserService = UserService;
    $scope.SearchBarService = SearchBarService;
    $scope.SignalR = SignalRService;
    $scope.Localization = LocalizationService.GetPluginDict("UserBar");
    $scope.Login = function () {
      $location.path('/Login');
    };

    $scope.Languages = LocalizationService.Languages;
    $scope.SelectedLanguage = LocalizationService.UserLanguage;

    $scope.LanguageChanged = (lang) => {
      LocalizationService.ChangeLanguage(lang);
    };

    $scope.IsMobile = () => {
      return ScreenWidthService.IsMobile();
    };

    $(window).keydown((e) => {
      if (e.ctrlKey && e.keyCode === ' '.charCodeAt(0)) {
        if (UserService.Data && UserService.Data.IsLoggedIn && UserService.Data.HasContactAccess) {
          e.preventDefault();
          $scope.ShowContactQuickSearch(e);
        }
      }
    });

    $scope.MandatorSelector = createMandatorSelectorModel($rootScope, ApiHandlerService, StorageService, $q, LocalizationService, UserService, GlobalValueService, MandatorServiceNgx);
    $scope.MandatorSelector.Initialize();

    $scope.MandatorServiceNgx.selectedMandatorIdChanged.subscribe(x => {
      $scope.MandatorSelector.Initialize();
    }
    );

    $scope.ShowContactQuickSearch = ($event) => {
      //const target = $event.srcElement || $event.target;
      /*while (!target.className.includes("contact-class-row")) {
          target = target.parentElement;
      }*/
      const position = $mdPanel.newPanelPosition()
        .relativeTo(document.body)
        .addPanelPosition(
          $mdPanel.xPosition.ALIGN_END,
          $mdPanel.yPosition.ALIGN_TOPS
        ).withOffsetY(50).withOffsetX(-190);
      const config = {
        attachTo: angular.element(document.body),
        templateUrl: '/ClientApp/src/ajs/Views/Contacts/QuickSearchPanel.htm',
        controller: 'ContactsQuickSearchController',
        controllerAs: 'ctrl',
        position: position,
        panelClass: 'contact-quicksearch-panel-container',
        openFrom: $event,
        focusOnOpen: true,
        clickOutsideToClose: true,
        escapeToClose: true,
        zIndex: 25,
        propagateContainerEvents: true,
        groupName: ['contacts']
      };

      $mdPanel.open(config);
    };

    $scope.Profile = () => {
      $location.path('/Profile');
    };

    $scope.Logout = () => {
      UserServiceNgx.logout().subscribe(() => {
        Promise.all([
          UserService.Load(),
          UserServiceNgx.reloadUserInfo()
        ]).then(() => {
          $location.path('/Login');
        });
      });
    };

    $scope.Administration = () => {
      $location.path('/Administration');
    };


    $scope.GetBackgroundColor = () => {
      if (MandatorServiceNgx?.selectedMandator?.hexColor) {
        return MandatorServiceNgx?.selectedMandator?.hexColor;
      }
      else {
        return "rgb(21, 101, 192)";
      }
    };

  }
}

interface IUserBarControllerScope extends angular.IScope {
  UseTabbedMandatorSelector: boolean;
  MandatorServiceNgx: MandatorService;
  UserService: any;
  SearchBarService: any;
  SignalR: any;
  Localization: PluginDict;
  Login: () => void;
  Languages: LocalizationLanguage[];
  SelectedLanguage: string;
  LanguageChanged: (lang: string) => void;
  IsMobile: () => boolean;
  ShowContactQuickSearch: (e: any) => void;
  MandatorSelector: MandatorSelectorModel;
  Profile: () => void;
  Logout: () => void;
  Administration: () => void;
  GetBackgroundColor: () => string;
  BASE_URL_API1: string;
  ShowNotifications: boolean;
  Theme: {
    Name: string,
    Definition: ThemeDto
  };
}
