import * as angular from "angular";
import { ActionDefinition, ActionGroup, ColumnDefinition, HestiaDataTable } from 'src/ajs/Directives/HestiaDataTableDirective';
import { addNavigation, convertDate, formatDateShort } from 'src/ajs/Utils/HestiaCommon';
import { addDefaultScopeFunctions } from '../../Functions/add-default-scope-functions';
import { ContactDto } from '../../Models/Contacts/ContactDto';
import { ThemeDto } from '../../Models/ThemeDto';
import { ApiHandlerService } from '../../Services/ApiHandlerService';
import { ContactsService } from '../../Services/ContactsService';
import { SearchBarService } from '../../Services/SearchBarService';
import { StorageService, StorageServiceInstance } from '../../Services/StorageService';
import { ConsolidationModel } from './ConsolidationModel';

export class ContactsController implements angular.IController {
  static $inject = ['$scope', '$mdDialog', '$timeout', 'ApiHandlerService', 'StorageService', 'SearchBarService', 'ContactsService', 'theme'];

  ConsolidationModel: ConsolidationModel;
  SearchText: string;
  FilterTimeout: angular.IPromise<any>;
  FilteredContacts: ContactDto[];
  ContactsLength: number;
  ContactsTable: HestiaDataTable;
  Storage: StorageServiceInstance;

  constructor(
    private readonly $scope: any,
    private readonly $mdDialog: angular.material.IDialogService,
    private readonly $timeout: angular.ITimeoutService,
    private readonly ApiHandlerService: ApiHandlerService,
    StorageService: StorageService,
    private readonly SearchBarService: SearchBarService,
    private readonly ContactsService: ContactsService,
    theme: ThemeDto,
  ) {
    this.SearchBarService.SearchTextChangedEvent((text) => {
      this.SearchTextChanged(text);
    });

    // Init SearchBarService
    SearchBarService.RequireSearchBar(true);

    // Init Storage and Navigation
    this.Storage = StorageService.CreateInstance("ContactsController");
    addDefaultScopeFunctions(this, theme);
    addNavigation(<any>this, "Contacts");

    this.ConsolidationModel = new ConsolidationModel(this.$scope, this.$timeout, this.ApiHandlerService, this.ContactsService);

    $(document).keyup((e) => {
      if (e.key === "Escape") {
        // TODO
        this.$scope.$apply();
      }
    });

    this.ContactsService.ContactsChangedEvent(this.ContactsChanged.bind(this));

    this.ContactsTable = new HestiaDataTable(StorageService.CreateInstance("AllContactsTable"), $timeout, $scope);

    this.ContactsTable.Options.Select = true;

    this.ContactsTable.AddActionGroup(new ActionGroup("Aktionen", [
      new ActionDefinition("Hauptadresse setzen", null, null, this.LinkToErpCustomerMain.bind(this)),
      new ActionDefinition("Mit Kunden assoziieren", null, null, this.LinkToErpCustomerAssociate.bind(this))
    ]));

    this.ContactsTable.UpdateColumns([
      new ColumnDefinition({ PropertyPath: 'DisplayName', Heading: 'Anzeigename/Firma', Resizeable: true, Sortable: true, Template: "/ClientApp/src/ajs/Views/Contacts/ContactDisplayTemplate.htm" }),
      new ColumnDefinition({ PropertyPath: 'Department', Heading: 'Abteilung/Position', Resizeable: true, Sortable: true, Template: "/ClientApp/src/ajs/Views/Contacts/ContactDepartmentTemplate.htm" }),
      new ColumnDefinition({ PropertyPath: 'MainAddress', Heading: 'Hauptkunde', RenderFunction: this.ConvertEprAddress, Resizeable: true, Sortable: true }),
      new ColumnDefinition({ PropertyPath: 'HomePage', Heading: 'Homepage', Resizeable: true, Sortable: true }),
      new ColumnDefinition({ PropertyPath: 'Birthday', Heading: 'Geburtstag', Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDateShort }),
      new ColumnDefinition({ PropertyPath: 'PhoneNumbers', Heading: 'Telefonnummer', Resizeable: true, Sortable: true, Template: "/ClientApp/src/ajs/Views/Contacts/ContactPhoneTemplate.htm" }),
      new ColumnDefinition({ PropertyPath: 'MailAddresses', Heading: 'E-Mail', Resizeable: true, Sortable: true, Template: "/ClientApp/src/ajs/Views/Contacts/ContactMailTemplate.htm" }),
      new ColumnDefinition({ PropertyPath: 'AssociatedAddresses', Heading: 'Verknüpfte Rollen', Resizeable: true, Sortable: true, Template: "/ClientApp/src/ajs/Views/Contacts/ContactAssociatedAddressesTemplate.htm" })
    ]);

    this.ApplyFilter();
    this.$timeout(() => (<any>this).Navigate(this.Storage.GetOrCreate("ActiveTab", "Contacts"), true), 2000);
  }

  SearchTextChanged(text: string) {
    this.SearchText = text;

    if (this.FilterTimeout !== null) {
      this.$timeout.cancel(this.FilterTimeout);
    }
    this.FilterTimeout = this.$timeout(() => {
      this.ApplyFilter();
    }, 250);
  }


  QueryContact(query: string) {
    const result = this.ContactsService.GetContactsBySearchText(query).slice(0, 15);
    for (let i = 0; i < result.length; i++) {
      const f = result[i];
      if (!f.DisplayText) {
        f.DisplayText = "[" + f.ContactId + "] " + f.DisplayName;
      }
    }
    return result;
  }

  ApplyFilter() {
    this.FilteredContacts = this.ContactsService.GetContactsBySearchText(this.SearchText);
    this.ContactsLength = this.ContactsService.Contacts.length;
    this.ContactsTable.UpdateSource(this.FilteredContacts, this.$timeout);
  }

  private ContactsChanged(action: string, contact: ContactDto | number) {
    this.$timeout(() => {
      this.ContactsLength = this.ContactsService.Contacts.length;
      if (action === "reload") {
        this.ApplyFilter();
      }
      else if (action === "add") {
        if (this.ContactsService.IsEntryIn(<ContactDto>contact, this.SearchText)) {
          this.FilteredContacts.push(<ContactDto>contact);
        }
      }
      else if (action === "delete") {
        const found = this.FilteredContacts.filter(f => f.ContactId === contact);
        if (found && found.length) {
          const index = this.FilteredContacts.indexOf(found[0]);
          this.FilteredContacts.splice(index, 1);
        }
      }
      else if (action === "update") {
        const found = this.FilteredContacts.filter(f => f.ContactId === (<ContactDto>contact).ContactId);
        if (found && found.length) {
          const index = this.FilteredContacts.indexOf(found[0]);
          this.FilteredContacts[index] = <ContactDto>contact;
        }
      }
      else {
        alert("Aktion '" + action + "' ist nicht implementiert");
      }
    });
  }

  private LinkToErpCustomerMain(models: any, event: any) {
    this.$mdDialog.show({
      targetEvent: event,
      controller: 'ErpSelectorController',
      templateUrl: '/ClientApp/src/ajs/Views/Erp/SelectorDialog.htm',
      locals: {
        TextAddition: false,
        TextLabel: null
      },
      parent: angular.element(document.body)
    }).then((result) => {
      if (result !== -1) {
        const ids = models.map(a => a.ContactId);
        let cachedAddressId = null;
        if (result !== null) {
          cachedAddressId = result.Id;
        }
        this.ApiHandlerService.SendRequest("Contacts", "LinkContactsToCachedAddressMain", { contactIds: ids, cachedAddressId: cachedAddressId }).then((data) => {
          const filtered = this.ContactsService.Contacts.filter(f => ids.indexOf(f.ContactId) !== -1);
          for (let i = 0; i < filtered.length; i++) {
            filtered[i].MainAddress = result;
          }
        });
      }
    });
  }

  private LinkToErpCustomerAssociate(models: any, event: any) {
    this.$mdDialog.show({
      targetEvent: event,
      controller: 'ErpSelectorController',
      templateUrl: '/ClientApp/src/ajs/Views/Erp/SelectorDialog.htm',
      locals: {
        TextAddition: true,
        TextLabel: "Verknüpfungsbeschreibung"
      },
      parent: angular.element(document.body)
    }).then((result) => {
      if (result !== -1 && result !== null) {
        const ids = models.map(a => a.ContactId);
        this.ApiHandlerService.SendRequest("Contacts", "LinkContactsToCachedAssociate", { contactIds: ids, cachedAddressId: result.Id, text: result.Text }).then((data) => {
          const filtered = this.ContactsService.Contacts.filter(f => ids.indexOf(f.ContactId) !== -1);
          for (let i = 0; i < filtered.length; i++) {
            const contained = filtered[i].AssociatedAddresses.filter(f => f.Id === result.Id);
            if (contained && contained.length) {
              const index = filtered[i].AssociatedAddresses.indexOf(contained[0]);
              filtered[i].AssociatedAddresses.splice(index, 1);
            }
            filtered[i].AssociatedAddresses.push(result);
          }
        });
      }
    });
  }

  private ConvertEprAddress(value: any) {
    if (value !== null) {
      return value.DisplayText;
    }
    return null;
  }
}

