import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

/**
 * Service für die alte .NET Framework Api (Hestia.Next).
 */
@Injectable({
  providedIn: 'root'
})
export class Api1Service {

  private readonly defaultHttpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8' }),
    withCredentials: true // withCredentials ist nötig, damit das AuthCookie mitgesendet wird.
  };

  constructor(
    private readonly http: HttpClient,
    @Inject('BASE_URL_API1') private readonly BASE_URL_API1: string
  ) {
  }

  get<T>(controller: string, action: string, params: any = null): Observable<T> {
    const url = this.getUrl(controller, action, params);
    return this.http.get<T>(url, this.defaultHttpOptions).pipe(
      map(result => this.handleMartinStatusCodes(result))
    );
  }

  post<T>(controller: string, action: string, params: any = null, body: any = null): Observable<T> {
    const url = this.getUrl(controller, action, params);
    return this.http.post<T>(url, body, this.defaultHttpOptions).pipe(
      map(result => this.handleMartinStatusCodes(result))
    );
  }

  private getUrl(controller: string, action: string, params: any) {
    let result = this.BASE_URL_API1 + controller + "/" + action;

    if (params) {
      let httpParams = new HttpParams();

      for (const param in params) {
        const value = params[param];

        if (value !== null && value !== undefined) {
          httpParams = httpParams.set(param, value);
        }
      }
      result += '?' + httpParams.toString();
    }
    return result;
  }

  /** Backwards Compatibility für Martin Logik */
  private handleMartinStatusCodes<T>(result: T): T {
    if (result) {
      if ("MessagePack" in result &&
        "State" in result &&
        "Message" in result &&
        "ExceptionMessage" in result &&
        "StackTrace" in result &&
        "RedirectUrl" in result &&
        "Data" in result) {

        if (result["MessagePack"]) {
          console.trace("MessagePack wird von ApiService nicht unterstützt.");
          return null;
        }
        else if (result["State"] === 0) {
          return result["Data"] as T;
        }
        else {
          return null;
        }
      }
    }
    return result;
  }
}
