import * as angular from "angular";
import { addDefaultScopeFunctions } from "../Functions/add-default-scope-functions";
import { ThemeDto } from "../Models/ThemeDto";
import { ApiHandlerService } from "../Services/ApiHandlerService";

export class ErrorPageController {
  static $inject = ['ApiHandlerService', 'theme'];

  ServerErrorMessage: string;
  ServerErrorStackTrace: string;

  constructor(
    private readonly ApiHandlerService: ApiHandlerService,
    private readonly theme: ThemeDto
  ) {

    if (ApiHandlerService.LastError) {
      this.ServerErrorMessage = ApiHandlerService.LastError.ExceptionMessage;
      this.ServerErrorStackTrace = ApiHandlerService.LastError.StackTrace;
    }
  }
}