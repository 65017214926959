
import angular from "angular";
import { AppConfigService } from "src/app/core/services/app-config.service";
import { addDefaultScopeFunctions } from "../Functions/add-default-scope-functions";
import { NavigationLinkDto } from "../Models/Navigation/NavigationLinkDto";
import { NavigationResultDto } from "../Models/Navigation/NavigationResultDto";
import { ThemeDto } from "../Models/ThemeDto";
import { ApiHandlerService } from "../Services/ApiHandlerService";
import { LocalizationService, PluginDict } from "../Services/Localization/LocalizationService";
import { ScreenWidthService } from "../Services/ScreenWidthService";
import { StorageService, StorageServiceInstance } from "../Services/StorageService";
import { UserService } from "../Services/UserService";
import { UserService as UserServiceNgx } from '../../app/core/services/user.service';
import { Role } from "src/app/core/constants";
import { TaskService } from "src/app/task/services/task.service";
import { IPanelServiceEx } from "../Services/IPanelServiceEx";
import { WidgetService } from "src/app/widgets/services/widget.service";

export class NavigationController {

  static $inject = [
    '$scope',
    '$mdPanel',
    '$location',
    'UserService',
    'ApiHandlerService',
    'StorageService',
    'ScreenWidthService',
    'LocalizationService',
    'theme',
    'AppConfigServiceNgx',
    'UserServiceNgx',
    'TaskServiceNgx',
    'WidgetServiceNgx',
  ];


  constructor(
    private readonly $scope: INavigationControllerScope,
    $mdPanel: IPanelServiceEx,
    private readonly $location: angular.ILocationService,
    UserService: UserService,
    ApiHandlerService: ApiHandlerService,
    StorageService: StorageService,
    ScreenWidthService: ScreenWidthService,
    LocalizationService: LocalizationService,
    theme: ThemeDto,
    appConfig: AppConfigService,
    userServiceNgx: UserServiceNgx,
    private readonly taskServiceNgx: TaskService,
    private readonly widgetService: WidgetService,

  ) {
    addDefaultScopeFunctions($scope, theme);
    $scope.IsLoading = true;
    $scope.Storage = StorageService.CreateInstance("NavigationController");
    $scope.LogoPath = theme.LogoPath;
    $scope.SiteTitle = theme.SiteTitle;
    $scope.Localization = LocalizationService.GetPluginDict("Navigation");
    $scope.MenuIsOpen = $scope.Storage.GetOrCreate("MenuIsOpen", false);

    $scope.$on('$locationChangeStart', () => {
      $scope.IsNavigating = true;
    });

    $scope.DeactivateNavigating = () => {
      $scope.IsNavigating = false;
    };

    $scope.$on('$locationChangeSuccess', () => {
      this.UpdateActiveLink();
    });

    $scope.LoadData = () => {
      ApiHandlerService.SendRequest("Navigation", "GetNavigation").then((data: NavigationResultDto) => {
        $scope.Links = data.Links.filter(f => f.HrefTarget !== "_mdPanel");
        $scope.Widgets = data.Links.filter(f => f.HrefTarget === "_mdPanel");

        const isAdmin = userServiceNgx.isGlobalAdmin ||
          userServiceNgx.currentUser.roles.some(x => x.roleName === Role.CoreAdmin);
        const isLoggedIn = userServiceNgx.isLoggedIn;


        $scope.IsLoading = false;
        this.UpdateActiveLink();
      });
    };

    $scope.IsMobile = () => {
      return ScreenWidthService.IsMobile();
    };

    $scope.openLink = ($event, link: string | NavigationLinkDto) => {
      if (typeof link === "string") {
        if (link === "/") {
          $location.path('/');
        }
      }
      //TaskWidget mit neuer Component
      else if (link.Href === "TasksWidgetController") {
        this.taskServiceNgx.showTaskEditDialog();
        $scope.LoadData();
      }
      else if (link.Href === "PasswordGeneratorController") {
        this.widgetService.showPasswordGeneratorDialog();
      }
      else if (link.HrefTarget === "_mdPanel") {
        let path = link.Href.replace("Controller", "");
        path = path.replace("Widget", "");

        $mdPanel.newPanelGroup(path, {
          maxOpen: 1
        });

        const config = {
          attachTo: angular.element(document.body),
          templateUrl: '/ClientApp/src/ajs/Views/' + path + '/' + path + 'Widget.htm',
          controller: link.Href,
          panelClass: 'window-panel-container',
          openFrom: $event,
          focusOnOpen: true,
          propagateContainerEvents: true,
          groupName: [path],
          locals: {}
        };

        // Workaround
        if (link.Href === "SmsSendController") {
          config.locals = {
            Contact: null,
            Number: null
          };
        }

        $mdPanel.open(config);
      }
      else {
        $scope.Links.forEach(f => f.IsActive = false);
        link.IsActive = true;
        $location.search({}); // query params leeren
        $location.path('/' + link.Href);
      }
    };

    $scope.isCP = () => {
      if (appConfig.environment === 'CP') {
        return true;
      }
      else {
        return false;
      }

    };

    UserService.RegisterLoadedEvent($scope.LoadData);
    $scope.LoadData();
  }

  private UpdateActiveLink() {
    if (this.$scope.Links) {
      for (const l of this.$scope.Links) {
        l.IsActive = false;

        if ((this.$location['$$path'] as string)?.startsWith("/" + l.Href)) {
          l.IsActive = true;
        }
      }
    }
  }
}

interface INavigationControllerScope extends angular.IScope {
  IsLoading: boolean;
  Storage: StorageServiceInstance;
  LogoPath: string;
  SiteTitle: string;
  Localization: PluginDict;
  MenuIsOpen: boolean;
  IsNavigating: boolean;
  DeactivateNavigating: () => void;
  Links: NavigationLinkDto[];
  LoadData: () => void;
  Widgets: NavigationLinkDto[];
  IsMobile: () => void;
  openLink: (event: any, link: string | NavigationLinkDto) => void;
  Theme: {
    Name: string,
    Definition: ThemeDto
  };
  isCP: () => boolean;
}
