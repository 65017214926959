import { ServiceLocatorService } from "./ServiceLocatorService";

export class DocumentsService {

  static $inject = [];

  CurrentMandatorId: any = null;
  ReadyChangesCallback: (() => void)[] = [];
  DocumentChangesCallbacks: ((type, document) => void)[] = [];
  Hub: any;
  ServiceLocator: ServiceLocatorService;

  constructor() {

    this.Hub = (<any>$).connection.documentsHub;

    if (this.Hub) {
      this.Hub.client.fileUpdated = (file: any) => {
        this.DocumentChangesCallbacks.forEach((callback) => {
          callback("update", file);
        });
      };

      this.Hub.client.fileDeleted = (file: any) => {
        this.DocumentChangesCallbacks.forEach((callback) => {
          callback("delete", file);
        });
      };
    }
    else {
      console.warn("documentsHub not started.");
    }
  }


  private RegisterMandator(id: string) {
    if (this.Hub) {
      this.Hub.server.registerForMandator(id);
    }
  }

  private UnregisterMandator(id: string) {
    if (this.Hub) {
      this.Hub.server.unregisterForMandator(id);
    }
  }

  DocumentChangesEvent(callback: (type, document) => void) {
    let replaced = false;
    for (let i = 0; i < this.DocumentChangesCallbacks.length; i++) {
      if (this.DocumentChangesCallbacks[i].toString() === callback.toString()) {
        this.DocumentChangesCallbacks[i] = callback;
        replaced = true;
      }
    }
    if (!replaced) {
      this.DocumentChangesCallbacks.push(callback);
    }
  }

  ReadyChangesEvent(callback: () => void) {
    if (this.ServiceLocator.SignalRService.Started) {
      callback();
    }
    else {
      let replaced = false;
      for (let i = 0; i < this.ReadyChangesCallback.length; i++) {
        if (this.ReadyChangesCallback[i].toString() === callback.toString()) {
          this.ReadyChangesCallback[i] = callback;
          replaced = true;
        }
      }
      if (!replaced) {
        this.ReadyChangesCallback.push(callback);
      }
    }
  }

  DumpData() {
    this.SetMandator();
  }

  private SetMandator() {
    if (this.ServiceLocator.SignalRService.Started) {
      const newMandatorId = this.ServiceLocator.UserService.GetMandatorId();
      if (newMandatorId !== this.CurrentMandatorId) {
        if (this.CurrentMandatorId) {
          this.UnregisterMandator(this.CurrentMandatorId);
          this.CurrentMandatorId = null;
        }
        if (newMandatorId) {
          this.RegisterMandator(newMandatorId);
          this.CurrentMandatorId = newMandatorId;
        }
      }
    }
  }

  InitService() {
    this.ReadyChangesEvent(this.SetMandator.bind(this));
    this.ServiceLocator.SignalRService.StartedEvent(() => {
      this.ReadyChangesCallback.forEach(f => {
        f();
      });
    });
  }
}
