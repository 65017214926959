export class TimelineDatePickerService {

  /** Referenz auf den DatePicker rechts über der Timeline */
  datePickerPanel: any = null;

  static $inject = [];

  constructor() { }
}

