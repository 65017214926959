import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContractService } from '../services/contract.service';
import { Observable, Subject, firstValueFrom, forkJoin, map, startWith, takeUntil } from 'rxjs';
import { MatTabGroup } from '@angular/material/tabs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/ajs/Services/UserService';
import { ContractDialogProductForm } from './contract-dialog-product-form';
import { ArrayUtils } from 'src/app/shared/utils/array-utils';
import { MatTableDataSourceEx } from 'src/app/shared/utils/mat-table-data-source-ex';
import { MatSort } from '@angular/material/sort';
import { formatNumber } from '@angular/common';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ErpCachedAddressDto } from 'src/app/erp/dto/erp-cached-address-dto';
import { ContractTypeDto } from '../dto/contract-type-dto';
import { SageArticleService } from 'src/app/sage-articles/services/sage-article.service';
import { SageArticleDto } from 'src/app/sage-articles/dto/sage-article-dto';
import { SageArticleVariantDto } from 'src/app/sage-articles/dto/sage-article-variant-dto';
import { SageArticleVariantExpressionDto } from 'src/app/sage-articles/dto/sage-article-variant-expression-dto';
import { ContractProductDto } from '../dto/contract-product-dto';
import { ContractDto } from '../dto/contract-dto';
import { ContractDataNoteDto } from '../dto/contract-data-note-dto';
import { ContractDataDto } from '../dto/contract-data.dto';
import { ContractDataStoredFileDto } from '../dto/contract-data-stored-file-dto';
import { ContractDialogTabVm } from '../viewmodels/contract-dialog-tab-vm';
import { UserDto } from 'src/app/core/dto/user-dto';
import { EPCPositionsTyp } from 'src/app/sage-export/enums/epc-positions-typ';
import { MandatorService } from 'src/app/core/services/mandator.service';

@Component({
  selector: 'app-contract-dialog',
  templateUrl: './contract-dialog.component.html',
  styleUrls: ['./contract-dialog.component.scss'],
})
export class ContractDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatTabGroup) matTabGroup: MatTabGroup;
  @ViewChild(MatSort) sort: MatSort;

  formGroup: FormGroup<{
    addressId: FormControl<number>,
    contractTypeId: FormControl<number>,
    intervall: FormControl<number>,
    validDate: FormControl<Date>,
    title: FormControl<string>,
    description: FormControl<string>,
    cancelled: FormControl<boolean>,
    account: FormControl<boolean>,
    hasFaktura: FormControl<boolean>,
    billingDate: FormControl<Date>,
    externalContractNumber: FormControl<string>
  }>;

  destroy$: Subject<void> = new Subject<void>();

  newTabText: FormControl<string> = new FormControl<string>('');
  addressSearch: FormControl<string> = new FormControl<string>('');

  filteredAddresses$: Observable<ErpCachedAddressDto[]>;
  filteredTitles$: Observable<string[]>;

  contract: ContractDto;
  erpCachenAddresses: ErpCachedAddressDto[];
  contractTypes: ContractTypeDto[];
  titles: string[];
  products: ContractProductDto[] = [];

  contractId: number = 0;
  selectedTabIndex: number = 0;
  alwaysActiveTabs: number = 2;

  editNotes: boolean = false;
  isLoading: boolean = false;
  isReady: boolean = false;

  displayedColumns = ["search", "articleNumber.value", "articleDescription1.value", "articleDescription2.value", "articleExpressionText.value", "amount.value", "price.value", "discount.value", "sum", "action"];
  dataSource = new MatTableDataSourceEx<ContractDialogProductForm>();

  suggestedTabTitles: string[] = [];
  contractDialogTabVms: ContractDialogTabVm[] = [];
  users: UserDto;

  showIntervallSum = new FormControl<boolean>(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: {
      contractId?: number,
      addressId?: number
    },
    private readonly contractService: ContractService,
    private readonly formbuilder: FormBuilder,
    private readonly dialogRef: MatDialogRef<ContractDialogComponent>,
    private readonly dialogService: DialogService,
    private readonly sageArticleService: SageArticleService,
    private readonly userService: UserService,
    private readonly mandatorService: MandatorService
  ) {

    this.formGroup = this.formbuilder.group({
      addressId: new FormControl<number>(null, Validators.required),
      contractTypeId: new FormControl<number>(null, Validators.required),
      intervall: new FormControl<number>(null, [Validators.required, Validators.min(1)]),
      validDate: new FormControl<Date>(null, Validators.required),
      title: new FormControl<string>(null, Validators.required),
      description: new FormControl<string>(''),
      cancelled: new FormControl<boolean>(false),
      account: new FormControl<boolean>(false),
      hasFaktura: new FormControl<boolean>(false),
      billingDate: new FormControl<Date>(null),
      externalContractNumber: new FormControl<string>(null)
    });

  }

  async ngOnInit() {

    this.isLoading = true;

    if (this.data.contractId) {
      this.contractId = this.data.contractId;
    }

    const queryResult = await firstValueFrom(forkJoin([
      // this.contractService.getContract(this.contractId),
      this.contractService.getErpCachedAddresses(this.mandatorService.selectedMandatorId),
      this.contractService.getContractTypes(),
      this.contractService.getTitles(),
      this.contractService.getContractProducts(this.contractId),
      this.contractService.getContractSuggestedTabTitles()
    ]));

    if (this.contractId) {
      this.contract = await firstValueFrom(this.contractService.getContract(this.contractId));
    }
    else {
      this.contract =
      {
        contractId: 0,
        environmentId: "",
        contractTypeId: 0,
        created: new Date(),
        erpCachedAddressId: null,
        officeLineCustomer: "",
        accountable: false,
        lastValidDate: null,
        dueDate: new Date(),
        intervallMonts: 1,
        isCancelled: false,
        title: "",
        description: "",
        deletedUtc: null,
        hasFaktura: false,
        showIntervallSum: false,
        billingDate: null,
        cachedAddress: null,
        type: null,
        externalContractNumber: "",
        products: [],
        data: []
      };
    }

    // this.contract = queryResult[0];
    this.erpCachenAddresses = queryResult[0];
    this.contractTypes = queryResult[1];
    this.titles = queryResult[2];
    this.products = queryResult[3];
    this.suggestedTabTitles = queryResult[4];

    this.formGroup.patchValue({
      addressId: this.contract.erpCachedAddressId,
      contractTypeId: this.contract.contractTypeId,
      intervall: this.contract.intervallMonts,
      validDate: this.contract.dueDate,
      title: this.contract.title,
      description: this.contract.description,
      cancelled: this.contract.isCancelled,
      account: this.contract.accountable,
      hasFaktura: this.contract.hasFaktura,
      billingDate: this.contract.billingDate,
      externalContractNumber: this.contract.externalContractNumber
    });

    this.showIntervallSum.patchValue(this.contract.showIntervallSum);

    if (!this.contractId && this.data.addressId) {
      this.formGroup.controls.addressId.patchValue(this.data.addressId);
    }

    for (const data of this.contract.data) {
      const vm: ContractDialogTabVm = {
        dto: data,
        noteControl: new FormControl<string>("")
      };
      this.contractDialogTabVms.push(vm);
    }

    this.filteredAddresses$ = this.addressSearch.valueChanges.pipe(
      takeUntil(this.destroy$),
      startWith({}),
      map(() => {
        const search = (this.addressSearch.value ?? '').toLowerCase().trim();
        return this.erpCachenAddresses.filter(x => x.cachedDisplayName.toLowerCase().trim().includes(search));
      })
    );

    this.filteredTitles$ = this.formGroup.controls.title.valueChanges.pipe(
      takeUntil(this.destroy$),
      startWith({}),
      map(() => {
        const search = (this.formGroup.controls.title.value ?? '').toLowerCase().trim();
        return this.titles.filter(x => x.toLowerCase().trim().includes(search));
      })
    );

    for (const product of this.products) {

      const productForm = new ContractDialogProductForm();
      productForm.contractProductId = product.id;
      productForm.expressionId = product.expressionId;
      productForm.sortOrder = product.sortOrder;

      productForm.articleNumber.patchValue(product.articleNumber);
      productForm.articleDescription1.patchValue(product.description1);
      productForm.articleDescription2.patchValue(product.description2);
      productForm.articleExpressionText.patchValue(product.expressionText);
      productForm.amount.patchValue(formatNumber(product.amount, "de-AT", "1.2-2"));
      productForm.price.patchValue(formatNumber(product.price, "de-AT", "1.2-2"));
      productForm.discount.patchValue(product.discount);
      productForm.isContractPrice.patchValue(product.isContractPrice);
      productForm.isContractDiscount.patchValue(product.isContractDiscount);
      productForm.langtext.patchValue(product.langtext);
      productForm.positionsTyp = product.positionsTyp;

      this.dataSource.data.push(productForm);
    }

    this.updateAllPrices();

    this.isLoading = false;
    this.isReady = true;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getLastValidDate() {
    let result = null;
    if (this.contract?.lastValidDate) {
      result = this.contract?.lastValidDate;
    }
    return result;
  }

  tabButtonClick(heading: string, fromInput?: boolean) {
    if (heading) {

      const tab = this.matTabGroup._allTabs.find(x => x.textLabel === heading);

      if (tab) {
        this.selectedTabIndex += tab.position;
      }
      else {
        const newData: ContractDataDto =
        {
          contractDataId: 0,
          contractId: this.contractId,
          title: heading,
          notes: [],
          files: [],
        };

        this.contract.data.push(newData);

        const newVm: ContractDialogTabVm = {
          dto: newData,
          noteControl: new FormControl<string>("")
        };

        this.contractDialogTabVms.push(
          newVm
        );

        this.selectedTabIndex = this.contract.data.length + 1;
        this.editNotes = true;

        if (fromInput) {
          if (this.suggestedTabTitles.length < 5) {
            this.suggestedTabTitles.push(heading);
          }
        }

      }
    }
  }

  async saveContract() {

    this.isLoading = true;

    this.commitToDto();

    await firstValueFrom(this.contractService.saveContract(this.contract));

    this.isLoading = false;

    this.dialogRef.close();
  }

  async saveContractAndCreateNew() {

    this.isLoading = true;

    this.commitToDto();

    const result = await firstValueFrom(this.contractService.saveContract(this.contract));

    if (result) {
      this.contract.contractId = 0;
      this.contract.title = "";
      this.contract.description = "";
      this.contract.data = [];
      this.contract.products = [];

      this.formGroup.patchValue({
        title: "",
        description: "",
      });

      this.products = [];
      this.contractDialogTabVms = [];
      this.dataSource.data = [];
    }

    this.isLoading = false;
  }

  removeNote(tab: ContractDataDto, note: ContractDataNoteDto) {
    ArrayUtils.remove(tab.notes, note);
  }

  removeFile(tab: ContractDataDto, file: ContractDataStoredFileDto) {
    ArrayUtils.remove(tab.files, file);
  }

  onFiledrop(tab: ContractDataDto, event: any) {
    const file = event[0];
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = () => {
      const newContractDataStoredFile: ContractDataStoredFileDto = {
        contractDataId: tab.contractDataId,
        storedFileId: 0,
        note: "",
        file: {
          storedFileId: 0,
          folderInfoId: null,
          fileName: file.name,
          storedFileDataId: 0,
          fileSize: 0,
          created: new Date(),
          modified: new Date(),
          deleted: null,
          createdUserId: null,
          modifiedUserId: null,
          deletedUserId: null,
          indexedContent: "",
          fileDataHash: "",
          fileData: {
            id: 0,
            data: btoa(<string>reader.result),
          }
        }
      };
      tab.files.push(newContractDataStoredFile);
    };
  }

  addProduct() {
    const newEntry = new ContractDialogProductForm();
    newEntry.positionsTyp = EPCPositionsTyp.Artikel;
    newEntry.sortOrder = this.dataSource.data.length;

    this.dataSource.data.push(newEntry);
    this.dataSource.data = this.dataSource.data;

    const table: HTMLTableElement = document.querySelector('#table');

    if (table) {
      const rows = table.tBodies[0].rows;
      const cells = rows[rows.length - 2].cells;
      const cell = cells[1];

      const input = <HTMLElement>cell.children[0].children[0].children[0].children[0].children[0];

      input.focus();
    }
  }

  async removeProduct(product: ContractDialogProductForm) {

    if (product.contractProductId) {
      if (await this.dialogService.showConfirmation("Artikel wirklich entfernen?", null, "Entfernen", "Abbrechen", "warn")) {
        ArrayUtils.remove(this.dataSource.data, product);
        this.dataSource.data = this.dataSource.data;

        for (let i = 0; i < this.dataSource.data.length; i++) {
          const entry = this.dataSource.data[i];
          entry.sortOrder = i;
        }
      }
    }
    else {
      ArrayUtils.remove(this.dataSource.data, product);
      this.dataSource.data = this.dataSource.data;

      for (let i = 0; i < this.dataSource.data.length; i++) {
        const entry = this.dataSource.data[i];
        entry.sortOrder = i;
      }
    }

  }

  addText() {
    const newEntry = new ContractDialogProductForm();
    newEntry.positionsTyp = EPCPositionsTyp.Textposition;
    newEntry.amount.patchValue("0");
    newEntry.sortOrder = this.dataSource.data.length;

    this.dataSource.data.push(newEntry);
    this.dataSource.data = this.dataSource.data;

    const table: HTMLTableElement = document.querySelector('#table');

    if (table) {
      const rows = table.tBodies[0].rows;
      const cells = rows[rows.length - 1].cells;
      const cell = cells[1];

      const input = <HTMLElement>cell.children[0].children[0].children[0].children[0].children[0];

      input.focus();
    }
  }

  async openFileNoteDialog(file: ContractDataStoredFileDto) {
    const result = await this.contractService.showContractFileNoteDialog(file.note);

    if (result !== null) {
      file.note = result;
    }
  }

  async openTabEditDialog(tab: ContractDataDto) {
    const result = await this.contractService.showContractTabEditDialog(tab);

    if (result === "delete") {
      ArrayUtils.remove(this.contract.data, tab);
    }
  }

  async openArticleVariationDialog(article: SageArticleVariantDto) {
    const result: SageArticleVariantExpressionDto = await this.contractService.showArticleVariationDialog(article);

    return result;
  }

  async openArticleSearchDialog(product: ContractDialogProductForm) {
    const article: SageArticleDto = await this.contractService.showArticleSearchDialog();

    if (article?.articleNumber) {
      this.updateArticle(article, product);
    }
  }

  async getArticleByArticleNumber(product: ContractDialogProductForm) {

    const article = await firstValueFrom(this.sageArticleService.getArticleByArticleNumber(product.articleNumber.value, this.mandatorService.selectedMandatorId));

    if (article.articleNumber) {
      this.updateArticle(article, product);
    }
  }

  async updateArticle(article: SageArticleDto, product: ContractDialogProductForm) {
    if (article.articleNumber !== null) {
      product.articleNumber.patchValue(article.articleNumber);
      product.articleDescription1.patchValue(article.description1);
      product.articleDescription2.patchValue(article.description2);
      product.articleExpressionText.patchValue("");

      const result = await firstValueFrom(this.sageArticleService.getArticleVariations(product.articleNumber.value.trim(), this.mandatorService.selectedMandatorId));

      if (result.expressions1.length > 1 || result.expressions2.length > 1) {

        const articleVariationResult: SageArticleVariantExpressionDto = await this.openArticleVariationDialog(result);

        let expression = "";

        if (articleVariationResult.expression1) {
          expression = articleVariationResult.expression1;
        }

        if (articleVariationResult.expression2) {
          expression = expression + ", " + articleVariationResult.expression2;
        }

        if (expression) {
          product.articleExpressionText.patchValue(expression);
        }

        if (articleVariationResult.expressionId) {
          product.expressionId = articleVariationResult.expressionId;
        }
      }

      this.updatePrice(product);
    }
  }

  getDebitorId() {
    return this.erpCachenAddresses.find(x => x.erpCachedAddressId === this.formGroup.controls.addressId.value).debitorIdentifier;
  }

  getSum(product: ContractDialogProductForm) {

    let result = 0;

    const price = this.parseStringToNumber(product.price.value.replace(/\s/g, ""));
    const amount = this.parseStringToNumber(product.amount.value.replace(/\s/g, ""));

    const articlePrice = price * amount;
    const discount = articlePrice / 100 * product.discount.value;
    result = (articlePrice - discount);

    return result;
  }

  getSumFormated(product: ContractDialogProductForm) {
    const result = formatNumber(this.getSum(product), "de-AT", "1.2-2");

    return result;
  }

  async updatePrice(product: ContractDialogProductForm) {

    if (product.articleNumber.value && product.amount.value && (!product.isContractPrice.value || !product.isContractDiscount.value)) {

      const priceResult = await firstValueFrom(this.sageArticleService.getArticlePrice(product.articleNumber.value, product.expressionId, this.parseStringToNumber(product.amount.value), this.getDebitorId(), this.mandatorService.selectedMandatorId));

      if (priceResult.articleNumber) {
        if (!product.isContractPrice.value) {
          product.price.patchValue(formatNumber(priceResult.price, "de-AT", "1.2-2"));
        }
        if (!product.isContractDiscount.value) {
          product.discount.patchValue(priceResult.discount);
        }
      }
    }
  }

  async updateAllPrices() {
    for (const product of this.dataSource.data) {
      this.updatePrice(product);
    }
  }

  getTotalSum() {
    let result = 0;

    for (const product of this.dataSource.data) {
      result += this.getSum(product);
    }

    const resultFormated = formatNumber(result, "de-AT", "1.2-2");

    return resultFormated;
  }

  getIntervallSum() {
    let result = 0;

    for (const product of this.dataSource.data) {
      result += this.getSum(product);
    }

    if (this.showIntervallSum.value) {
      result = result * this.formGroup.value.intervall;
    }

    const resultFormated = formatNumber(result, "de-AT", "1.2-2");

    return resultFormated;
  }

  async deleteContract() {
    if (await this.dialogService.showConfirmation("Vertrag wirklich löschen?", null, "Löschen", "Abbrechen", "warn")) {
      await firstValueFrom(this.contractService.deleteContract(this.contract.contractId));
      this.dialogRef.close();
    }
  }

  commitToDto() {
    this.contract.erpCachedAddressId = this.formGroup.controls.addressId.value;
    this.contract.contractTypeId = this.formGroup.controls.contractTypeId.value;
    this.contract.intervallMonts = this.formGroup.controls.intervall.value;
    this.contract.dueDate = this.formGroup.controls.validDate.value;
    this.contract.title = this.formGroup.controls.title.value;
    this.contract.description = this.formGroup.controls.description.value;
    this.contract.isCancelled = this.formGroup.controls.cancelled.value;
    this.contract.accountable = this.formGroup.controls.account.value;
    this.contract.hasFaktura = this.formGroup.controls.hasFaktura.value;
    this.contract.showIntervallSum = this.showIntervallSum.value;
    this.contract.billingDate = this.formGroup.controls.billingDate.value;
    this.contract.externalContractNumber = this.formGroup.controls.externalContractNumber.value;
    this.contract.environmentId = this.mandatorService.selectedMandator.id;

    if (this.contractDialogTabVms.length) {
      for (const vm of this.contractDialogTabVms) {
        const data = this.contract.data.find(x => x.contractDataId === vm.dto.contractDataId);

        if (data && vm.noteControl.value) {
          const newNote: ContractDataNoteDto = {
            contractDataNoteId: 0,
            contractDataId: data.contractDataId,
            created: new Date(),
            modified: new Date(),
            text: vm.noteControl.value,
            modifiedUserId: this.userService.Data.User.Id,
            createdUserId: this.userService.Data.User.Id,
          };

          data.notes.push(newNote);
        }
      }
    }

    const productList = this.dataSource.data.slice();

    if (productList.length) {

      this.contract.products = [];

      let index = 0;

      for (const product of productList) {
        const contractProductDto: ContractProductDto =
        {
          id: product.contractProductId,
          contractId: this.contract.contractId,
          articleNumber: product.articleNumber.value,
          description1: product.articleDescription1.value,
          description2: product.articleDescription2.value,
          expressionId: product.expressionId,
          expressionText: product.articleExpressionText.value,
          amount: this.parseStringToNumber(product.amount.value),
          price: this.parseStringToNumber(product.price.value),
          discount: product.discount.value,
          isContractPrice: product.isContractPrice.value,
          isContractDiscount: product.isContractDiscount.value,
          langtext: product.langtext.value,
          positionsTyp: product.positionsTyp,
          sortOrder: index
        };

        if (!contractProductDto.discount) {
          contractProductDto.discount = 0;
        }

        this.contract.products.push(contractProductDto);
        index++;
      }

    }
  }

  getContractType(contractTypeId: number) {
    return this.contractTypes?.find(x => x.contractTypeId === contractTypeId)?.description;
  }

  getAdress(erpCachedAddressId: number) {
    return this.erpCachenAddresses?.find(x => x.erpCachedAddressId === erpCachedAddressId)?.cachedDisplayName;
  }

  parseDate(event: any, control: FormControl<Date>) {

    const value: string = event.currentTarget.value;
    let newDate: Date;

    let splitString;

    if (value.includes(",")) {
      splitString = value.split(",");
    }
    else if (value.includes(".")) {
      splitString = value.split(".");
    }

    if (splitString) {
      let day = splitString[0];
      let month = splitString[1];
      let year = splitString[2];

      const parseDay = parseInt(day);

      if (parseDay < 10) {
        day = "0" + parseDay;
      }

      const parseMonth = parseInt(month);

      if (parseMonth < 10) {
        month = "0" + parseMonth;
      }

      if (year?.length && year.length === 2) {
        year = "20" + year;
      }
      else if (!year?.length) {
        year = new Date().getFullYear().toString();
      }

      const result = year + "-" + month + "-" + day;

      newDate = new Date(result);
    }

    if (newDate) {
      control.patchValue(newDate);
    }

  }

  parseStringToNumber(value: string) {
    let result = 0;

    if (value.includes(",")) {
      value = value.replace(",", ".");
    }

    result = Number.parseFloat(value.replace(/\s/g, ""));

    return result;
  }

  getExtension(file: ContractDataStoredFileDto) {
    const splitFileName = file.file.fileName.split(".");
    return splitFileName[1];
  }

  showArticleRow(index: number, row: ContractDialogProductForm) {
    if (row.positionsTyp === EPCPositionsTyp.Artikel) {
      return true;
    }
    else {
      return false;
    }
  }

  sortDown(position: ContractDialogProductForm) {
    if (position.sortOrder < this.dataSource.data.length - 1) {
      const currentIndex = this.dataSource.data.indexOf(position);
      position.sortOrder++;

      const nextPosition = this.dataSource.data[currentIndex + 1];
      nextPosition.sortOrder--;

      this.dataSource.data = this.dataSource.data.sort((a, b) => a.sortOrder - b.sortOrder);
    }
  }

  sortUp(position: ContractDialogProductForm) {
    if (position.sortOrder > 0) {
      const currentIndex = this.dataSource.data.indexOf(position);
      position.sortOrder--;

      const nextPosition = this.dataSource.data[currentIndex - 1];
      nextPosition.sortOrder++;

      this.dataSource.data = this.dataSource.data.sort((a, b) => a.sortOrder - b.sortOrder);
    }
  }


}
