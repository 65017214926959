import { Location } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'cp-detailbar',
  templateUrl: './detailbar.component.html',
  styleUrls: ['./detailbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { 'class': 'cp-detailbar' }
})
export class DetailbarComponent {

  /** Wenn dieses Event verwendet wird, wird es beim Klicken auf "zurück" ausgelöst. 
   *  Ansonsten in der Browser History einen Schritt zurück. */
  @Output() readonly backClick = new EventEmitter<any>();

  constructor(
    private readonly location: Location
  ) { }

  backClicked() {
    if (this.backClick.observed) {
      this.backClick.emit();
    }
    else {
      this.location.back();
    }
  }
}
