import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { NotificationSettingsDto } from '../../dto/notification-settings-dto';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent implements OnInit, AfterViewInit, OnDestroy {

  readonly formGroup: UntypedFormGroup;
  private readonly destroy$ = new Subject<void>();
  notificationSettings: NotificationSettingsDto;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly notificaitonService: NotificationService
  ) {
    /*
    wenn null, keine Mail verschicken
    "i" .. instant = Sofort
    "d" .. daily = Täglich
    "w" .. weekly = Wöchentlich
  */
    this.formGroup = this.formBuilder.group({
      subscriptionCheckbox: [true],
      subscriptionRadio: [{ value: 'i', disabled: false }],
      //activityCheckbox: [true],
      //activityRadio: [{ value: 'i', disabled: false }],
      //miscellaneousCheckbox: [true],
      //miscellaneousRadio: [{ value: 'i', disabled: false }]
    });

    this.formGroup.get("subscriptionCheckbox").valueChanges.pipe(
      takeUntil(this.destroy$),
    ).subscribe(
      (value) => {
        if (value) {
          this.formGroup.get("subscriptionRadio").enable();
        }
        else {
          this.formGroup.get("subscriptionRadio").disable();
        }
      });
  }

  async ngOnInit() {
    await this.loadFromDto();
  }

  ngAfterViewInit() {



    //Fürs erste vewenden wir nur einen Typ
    /*
    this.formGroup.get("activityCheckbox").valueChanges.pipe(
      takeUntil(this.destroy$),
    ).subscribe(
      (value) => {
        if (value) {
          this.formGroup.get("activityRadio").enable();
        }
        else {
          this.formGroup.get("activityRadio").disable();
        }
      });

    this.formGroup.get("miscellaneousCheckbox").valueChanges.pipe(
      takeUntil(this.destroy$),
    ).subscribe(
      (value) => {
        if (value) {
          this.formGroup.get("miscellaneousRadio").enable();
        }
        else {
          this.formGroup.get("miscellaneousRadio").disable();
        }
      });

      */
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async save() {

    let dto: NotificationSettingsDto;

    if (this.notificationSettings) {
      dto = Object.assign(this.notificationSettings);
    }
    else {
      dto = {} as NotificationSettingsDto;
    }

    if (this.formGroup.value.subscriptionCheckbox) {
      dto.subscriptionMailFrequency = this.formGroup.value.subscriptionRadio;
    }
    else {
      dto.subscriptionMailFrequency = null;
    }

    // Fürs Erste verwenden wir nur einen Typ
    /*
    if (this.formGroup.value.activityCheckbox) {
      dto.activityMailFrequency = this.formGroup.value.activityRadio;
    }
    else {
      dto.activityMailFrequency = null;
    }

    if (this.formGroup.value.miscellaneousCheckbox) {
      dto.miscellaneousMailFrequency = this.formGroup.value.miscellaneousRadio;
    }
    else {
      dto.miscellaneousMailFrequency = null;
    }
    */


    await firstValueFrom(this.notificaitonService.saveNotificationSettings(dto));
    this.formGroup.markAsPristine();
  }

  async loadFromDto() {
    this.notificationSettings = await firstValueFrom(this.notificaitonService.getNotificationSettigs());

    if (this.notificationSettings.subscriptionMailFrequency) {
      this.formGroup.patchValue({
        subscriptionCheckbox: true,
        subscriptionRadio: this.notificationSettings.subscriptionMailFrequency,
      });
    }
    else {
      this.formGroup.patchValue({
        subscriptionCheckbox: false,
      });
    }

    this.formGroup.markAsPristine();
    // Fürs erste vewenden wir nur einen Typ

    /*
    if (this.notificationSettings.activityMailFrequency) {
      this.formGroup.patchValue({
        activityCheckbox: true,
        activityRadio: this.notificationSettings.activityMailFrequency,
      });
    }
    else {
      this.formGroup.patchValue({
        activityCheckbox: false,
      });
    }

    if (this.notificationSettings.miscellaneousMailFrequency) {
      this.formGroup.patchValue({
        miscellaneousCheckbox: true,
        miscellaneousRadio: this.notificationSettings.miscellaneousMailFrequency,
      });
    }
    else {
      this.formGroup.patchValue({
        miscellaneousCheckbox: false,
      });
    }
    */

  }
}
