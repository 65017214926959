import * as angular from 'angular';
import { ThemeDto } from '../Models/ThemeDto';
import { UserServiceDto } from '../Models/User/UserServiceDto';

export class UserService {
  static $inject = ['$q', '$http', 'themeProvider', 'hestiaThemes', 'BASE_URL_API1'];

  IsMinimalistic = false;

  Data: UserServiceDto = {
    User: null,
    IsLoggedIn: false,
    IsSingleSignOn: false,
    IsAdmin: false,
    IsLoading: true,
    ThemeId: undefined
  } as UserServiceDto;

  LoadedCallbacks = [];
  RouteProvider = null;

  constructor(
    private readonly $q: angular.IQService,
    private readonly $http: angular.IHttpService,
    private readonly themeProvider: angular.material.IThemingProvider,
    private readonly hestiaThemes: ThemeDto[],
    private readonly BASE_URL_API1: string
  ) {
  }

  RegisterLoadedEvent(callback) {
    let replaced = false;
    for (let i = 0; i < this.LoadedCallbacks.length; i++) {
      if (this.LoadedCallbacks[i].toString() === callback.toString()) {
        this.LoadedCallbacks[i] = callback;
        replaced = true;
      }
    }
    if (!replaced) {
      this.LoadedCallbacks.push(callback);
    }
  }

  GetMandatorId() {
    if (this.Data && this.Data.User) {
      return this.Data.User.MandatorId;
    }
    return null;
  }

  private GetUserData() {
    return this.$http.post<UserServiceDto>(this.BASE_URL_API1 + "User/GetUserData", null);
  }

  Load() {
    return new Promise<void>((resolve, reject) => {
      this.$q.when(this.GetUserData()).then((data) => {
        angular.copy(data.data, this.Data);
        let theme = this.hestiaThemes[0];
        this.hestiaThemes.forEach(iTheme => {
          if (this.Data.ThemeId) {
            if (iTheme.Id === this.Data.ThemeId) {
              theme = iTheme;
            } else {
              if (iTheme.IsSystemDefaultTheme) {
                theme = iTheme;
              }
            }

          }
        });
        angular.module('theme', []).constant('theme', theme);
        // Breaking Change: globalRouteProvider ist eine globale Variable. Das ist mit webpack nicht kompatibel.
        // es wurde in Kauf genommen, dass durch diese Änderung der theme.LandingPagePath immer der gleiche ist.
        // globalRouteProvider.when("/", { redirectTo: theme.LandingPagePath });
        this.themeProvider.setDefaultTheme(theme.ThemeDescription);
        // CH 2022-04-12: deaktiviert; wird in HestiaApp.js einmal gesetzt, aber beim Benutzerwechsel nicht mehr.
        // es wird in Kauf genommen, dass sich das Theme nachträglich nicht mehr ändern kann.
        // setTheme(theme);
        this.Data.IsLoading = false;
        this.LoadedCallbacks.forEach(function (callback, index) {
          callback();
        });
        resolve();
      });
    });
  }
}
