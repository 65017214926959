import angular, { IPromise, IQService, IScope, ITimeoutService, forEach } from 'angular';
import { globalReceiptQuery } from 'src/ajs/Utils/HestiaCommon';
import { addDefaultScopeFunctions } from '../Functions/add-default-scope-functions';
import { AddressDto } from '../Models/Document/AddressDto';
import { MailMetaDto } from '../Models/Mail/MailMetaDto';
import { MailReceiptDto } from '../Models/Mail/MailReceiptDto';
import { ThemeDto } from '../Models/ThemeDto';
import { ApiHandlerService } from '../Services/ApiHandlerService';
import { ContactsService } from '../Services/ContactsService';
import { MandatorService } from 'src/app/core/services/mandator.service';

createMailSendDialogController.$inject = ['$scope', '$mdDialog', '$q', '$timeout', 'ApiHandlerService', 'ContactsService', 'Parameters', 'OnSubmit', 'theme', 'MandatorServiceNgx'];

export function createMailSendDialogController(
  $scope: IMailSendDialogControllerScope,
  $mdDialog: angular.material.IDialogService,
  $q: IQService,
  $timeout: ITimeoutService,
  ApiHandlerService: ApiHandlerService,
  ContactsService: ContactsService,
  Parameters: MailMetaDto,
  OnSubmit,
  theme: ThemeDto,
  mandatorServiceNgx: MandatorService
) {
  addDefaultScopeFunctions($scope, theme);
  $scope.SubmitCallback = OnSubmit;
  $scope.QueryTimeout = null;
  $scope.CachedAddressId = null;
  $scope.Model = {
    Receipts: [],
    BccReceipts: [],
    Subject: null,
    Body: null
  } as MailMetaDto;
  if (Parameters) {
    if (Parameters.Subject) {
      $scope.Model.Subject = Parameters.Subject;
    }
    if (Parameters.Receipts) {

      const firstReceipt = Parameters.Receipts[0];
      const email = firstReceipt?.EMailAddress;

      if (email && email.toString().includes(";")) {
        const splitMails = email.toString().split(";");

        $scope.Model.Receipts = [];

        for (let mail of splitMails) {

          mail = mail.trim();

          const newReceipt: MailReceiptDto =
          {
            DisplayName: mail,
            EMailAddress: mail
          };

          $scope.Model.Receipts.push(newReceipt);
        }
      }
      else {
        $scope.Model.Receipts = Parameters.Receipts;
      }

    }
    if (Parameters.CachedAddressId) {
      $scope.CachedAddressId = Parameters.CachedAddressId;
      if (!Parameters.Receipts) {
        ApiHandlerService.SendRequest("Erp", "GetAddress", { addressId: Parameters.CachedAddressId, mandatorId: mandatorServiceNgx.selectedMandatorId }).then(function (data: AddressDto) {
          if (data.EMail) {
            $scope.Model.Receipts.push({
              EMailAddress: data.EMail,
              DisplayName: data.EMail,
              Image: "/ClientApp/src/ajs/Images/c_placeholder.png"
            });
          } else if (data.BillingEMail) {
            $scope.Model.Receipts.push({
              EMailAddress: data.BillingEMail,
              DisplayName: data.BillingEMail,
              Image: "/ClientApp/src/ajs/Images/c_placeholder.png"
            });
          }
        });
      }
    }
  }
  $scope.ReceiptsChanged = function () {
    $scope.Model.Receipts.forEach(f => {
      if (!f.EMailAddress) {
        const model = {
          EMailAddress: f,
          DisplayName: f,
          Image: "/ClientApp/src/ajs/Images/c_placeholder.png"
        } as MailReceiptDto;
        $scope.Model.Receipts.push(model);
      }
    });
    $scope.Model.Receipts = $scope.Model.Receipts.filter(f => f.EMailAddress);
    $scope.Model.BccReceipts.forEach(f => {
      if (!f.EMailAddress) {
        const model = {
          EMailAddress: f,
          DisplayName: f,
          Image: "/ClientApp/src/ajs/Images/c_placeholder.png"
        } as MailReceiptDto;
        $scope.Model.BccReceipts.push(model);
      }
    });
    $scope.Model.BccReceipts = $scope.Model.BccReceipts.filter(f => f.EMailAddress);
  };

  $scope.QueryReceipt = function (query: string) {
    return globalReceiptQuery(query, $q, $timeout, ContactsService, $scope.CachedAddressId);
  };

  $scope.Send = function () {
    $scope.SubmitCallback($scope.Model);
    $mdDialog.hide();
  };

  $scope.Close = function () {
    $mdDialog.hide();
  };
}

interface IMailSendDialogControllerScope extends IScope {

  QueryTimeout: any;
  CachedAddressId: number;
  Model: MailMetaDto;

  SubmitCallback
  ReceiptsChanged: () => void;
  QueryReceipt: (query: string) => IPromise<any[]>
  Send: () => void;
  Close: () => void;
}

