import angular from "angular";
import { addDefaultScopeFunctions } from "../Functions/add-default-scope-functions";
import { ThemeDto } from "../Models/ThemeDto";
import { LocalizationService } from "../Services/Localization/LocalizationService";


createEditTextDialogController.$inject = ['$scope', '$mdDialog', 'LocalizationService', 'theme', 'CurrentText', 'OnSave'];

export function createEditTextDialogController(
  $scope,
  $mdDialog: angular.material.IDialogService,
  LocalizationService: LocalizationService,
  theme: ThemeDto,
  CurrentText: string,
  OnSave: (text: string) => void) {

  addDefaultScopeFunctions($scope, theme);
  $scope.Localization = LocalizationService.GetPluginDict("Core");
  $scope.CurrentText = CurrentText;
  $scope.OnSave = OnSave;

  $scope.FetchTabPress = function ($event) {
    if ($event.keyCode === 9) {
      $scope.InsertText($event.currentTarget, "\t");
      $event.preventDefault();
      $event.stopPropagation();
      return false;
    }
    return undefined;
  };

  $scope.InsertText = function (input, text) {
    if (input === undefined) { return; }
    let pos = 0;
    let browser = input.selectionStart || input.selectionStart === "0" ? "ff" : document['selection'] ? "ie" : false;
    let range;

    if (browser === "ie") {
      input.focus();
      range = document['selection'].createRange();
      range.moveStart("character", -input.value.length);
      pos = range.text.length;
    }
    else if (browser === "ff") {
      pos = input.selectionStart;
    }

    let front = input.value.substring(0, pos);
    let back = input.value.substring(pos, input.value.length);
    input.value = front + text + back;
    pos = pos + text.length;
    if (browser === "ie") {
      input.focus();
      range = document['selection'].createRange();
      range.moveStart("character", -input.value.length);
      range.moveStart("character", pos);
      range.moveEnd("character", 0);
      range.select();
    }
    else if (browser === "ff") {
      input.selectionStart = pos;
      input.selectionEnd = pos;
      input.focus();
    }
    //angular.element(input).trigger('input');
    input.focus();
  };

  $scope.Save = function () {
    if ($scope.OnSave) {
      $scope.OnSave($scope.CurrentText);
    }
    $mdDialog.hide();
  };

  $scope.Cancel = function () {
    $mdDialog.hide();
  };
}
