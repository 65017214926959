import { Injectable } from '@angular/core';
import { NativeDateAdapter } from "@angular/material/core";
import * as dayjs from 'dayjs';

/** Ein DateAdapter, der natives JavaScript Date verwendet, aber für deutsche Eingabeformate.
 * Wird für mat-date-picker benötigt. */
@Injectable()
export class GermanNativeDateAdapter extends NativeDateAdapter {

  override parse(value: any): Date | null {
    const parsed = dayjs(value,
      [
        'DD.MM.YYYY',
        'DDMMYYYY',
        'DD.MM.YY',
        'DDMMYY',
        'DD.MM.',
        'DD.MM',
        'DDMM'
      ], 'de', true);

    if (parsed.isValid()) {
      return parsed.toDate();
    }
    else {
      return null;
    }
  }
}
