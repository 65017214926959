import angular from "angular";

/** Ruft die übergebene Funktion auf, sobald der View geladen wurde. */
export class AfterViewInitDirective implements angular.IDirective {
  constructor(
    private readonly $parse: angular.IParseService
  ) { }

  link = (scope: angular.IScope, element: angular.IAugmentedJQuery, attrs: angular.IAttributes) => {
    const fn = this.$parse(attrs.afterViewInit);
    fn(scope);
  };

  static factory(): angular.IDirectiveFactory {
    const directive = ($parse: angular.IParseService) => new AfterViewInitDirective($parse);
    directive.$inject = ['$parse'];
    return directive;
  }
}
