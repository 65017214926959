import * as angular from 'angular';
import { Subject } from 'rxjs';
import { addDefaultScopeFunctions } from '../Functions/add-default-scope-functions';
import { ThemeDto } from '../Models/ThemeDto';
import { StorageService, StorageServiceInstance } from '../Services/StorageService';

export function createTaskEditBaseController(
  $scope: ITaskEditBaseControllerScope,
  StorageService: StorageService,
  theme: ThemeDto,
) {
  addDefaultScopeFunctions($scope, theme);

  $scope.SelectedAddress = null;
  $scope.Storage = StorageService.CreateInstance("TasksController");
  $scope.RefreshTasksRequested = new Subject<void>();
}

export interface ITaskEditBaseControllerScope extends angular.IScope {

  RefreshTasksRequested: Subject<void>;
  SelectedAddress: any;
  Storage: StorageServiceInstance;
}
