<app-loading-overlay [isLoading]="isLoading" overlay="light"></app-loading-overlay>
<div class="main-container">

  <div class="header-bar">
    <!-- <h1 fxFlex></h1> -->

    <div class="filter-container">
      <mat-form-field class="header-margin">
        <mat-label>Vertragsgruppe</mat-label>

        <mat-select [formControl]="contractTypeId">

          <mat-option>
            <ngx-mat-select-search [formControl]="contractTypeSearch"></ngx-mat-select-search>
          </mat-option>

          <mat-option [value]="0">
            alle anzeigen
          </mat-option>

          <mat-option *ngFor="let type of filteredContractTypes$ | async " [value]="type.contractTypeId">
            {{type.description }}
          </mat-option>

        </mat-select>
      </mat-form-field>

      <mat-form-field class="header-margin">
        <mat-label>Adresse</mat-label>

        <mat-select [formControl]="addressIdFilter">

          <mat-option>
            <ngx-mat-select-search [formControl]="addressSearch"></ngx-mat-select-search>
          </mat-option>

          <mat-option [value]=" null">
            (keine)
          </mat-option>

          <mat-option *ngFor="let address of filteredAddresse$| async" [value]="address.erpCachedAddressId">
            {{address.cachedDisplayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="header-margin">
        <mat-label>Textsuche</mat-label>
        <input matInput [formControl]="search">
      </mat-form-field>

      <mat-form-field class="header-margin">
        <mat-label>Verträge</mat-label>

        <mat-select [formControl]="contractFilter">
          <mat-option [value]="0">
            Alle Verträge
          </mat-option>
          <mat-option [value]="1">
            Verrechenbare Verträge
          </mat-option>
          <mat-option [value]="2">
            Aktive Verträge
          </mat-option>
          <mat-option [value]="3">
            Abgelaufene Verträge
          </mat-option>
          <mat-option [value]="4">
            Innerhalb 30 Tage ablaufend
          </mat-option>
        </mat-select>
      </mat-form-field>


      <mat-slide-toggle [formControl]="isCanceled" class="header-margin">Stornierte Verträge</mat-slide-toggle>

      <button mat-icon-button color="primary" (click)="resetFilter()" matTooltip="Filter zurücksetzten">
        <mat-icon>restart_alt</mat-icon>
      </button>

      <button mat-icon-button (click)="openDialog(0)" matTooltip="Neuen Vertrag anlegen"><mat-icon color="primary">note_add</mat-icon></button>

      <!-- <button (click)="openLabelPrintDialog()">test</button> -->

    </div>

    <div class="flex-row">

      <button mat-icon-button color="primary" matTooltip="Xml import" (click)="openXmlImportDialog()">
        <mat-icon>
          login
        </mat-icon>
      </button>

      <button mat-icon-button color="primary" matTooltip="Export Warteschlange" (click)="openSageExportQueueDialog()">
        <mat-icon>
          logout
        </mat-icon>
      </button>

      <button mat-stroked-button (click)="columnEditor.showEditor()">
        <mat-icon>view_column</mat-icon> Spalten
      </button>

      <mat-paginator [length]="totalCount" [pageSize]="50" hidePageSize [showFirstLastButtons]="true"></mat-paginator>

    </div>

  </div>

  <mat-divider></mat-divider>

  <div class="table-container">

    <table
      mat-table
      class="axs-table dense"
      [dataSource]="dataSource"
      matSort
      [matSortActive]="sortService.active"
      [matSortDirection]="sortService.direction"
      (matSortChange)="sortService.change($event)"
      matSortDisableClear>

      <ng-container matColumnDef="checkbox">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="selectAll($event.checked)" [checked]="allSelected()" [indeterminate]="someSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox [formControl]="row.isSelected"></mat-checkbox>
        </td>
      </ng-container>


      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" class="fit-content">
          <button mat-icon-button (click)="openDialog(row.contract.contractId)">
            <mat-icon color="primary">edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="isInSageExportContractQueue">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" class="fit-content">
          <button mat-icon-button *ngIf="!isContractInSageExportContractQueue(row.contract)" (click)="addToSageExportQueue(row.contract.contractId)">
            <mat-icon>logout</mat-icon>
          </button>

          <button mat-icon-button *ngIf="isContractInSageExportContractQueue(row.contract)">
            <mat-icon color="primary"> logout</mat-icon>
          </button>
        </td>
      </ng-container>


      <ng-container matColumnDef="isAccounted">
        <th mat-header-cell *matHeaderCellDef disableClear>
          <button mat-icon-button *ngIf="!isCanceled.value" (click)="toggleAccountedFilter()">
            <mat-icon *ngIf="!accountedFilter">euro</mat-icon>
            <mat-icon *ngIf="accountedFilter" color="warn">euro</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let row" class="fit-content">
          <button mat-icon-button *ngIf="row.contract.isCancelled">
            <mat-icon color="warn">cancel</mat-icon>
          </button>

          <button mat-icon-button *ngIf="!row.contract.isCancelled && row.contract.accountable">
            <mat-icon color="warn">euro</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="warning">
        <th mat-header-cell *matHeaderCellDef disableClear>
          <button mat-icon-button (click)="toggleWarningFilter()">
            <mat-icon *ngIf="!warningFilter">warning</mat-icon>
            <mat-icon *ngIf="warningFilter" color="warn">warning</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let row" class="fit-content">
          <button mat-icon-button *ngIf="row.contract.hasFaktura && !row.contract.products.length" matTooltip="Keine Produkte in Faktura angelegt!">
            <mat-icon color="warn">warning</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Id</th>
        <td mat-cell *matCellDef="let row" class="fit-content">
          {{'#' + row.contract.contractId}}
        </td>
      </ng-container>

      <ng-container matColumnDef="dueDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Gültig bis</th>
        <td mat-cell *matCellDef="let row" class="fit-content">
          {{row.contract.dueDate | date}}
        </td>
      </ng-container>

      <ng-container matColumnDef="billingDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Verrechnet bis</th>
        <td mat-cell *matCellDef="let row" class="fit-content">
          {{row.contract.billingDate | date}}
        </td>
      </ng-container>

      <ng-container matColumnDef="intervall">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Int</th>
        <td mat-cell *matCellDef="let row" class="fit-content">
          {{row.contract.intervallMonts}}
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Typ</th>
        <td mat-cell *matCellDef="let row" class="fit-content">
          {{row.contract.type.description}}
        </td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Adresse</th>
        <td mat-cell *matCellDef="let row" class="fit-content">
          {{row.contract.cachedAddress.cachedDisplayName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Titel</th>
        <td mat-cell *matCellDef="let row" class="fit-content">
          <b>{{row.contract.title}}</b>
        </td>
      </ng-container>

      <ng-container matColumnDef="externalContractNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Externe Vertragsnummer</th>
        <td mat-cell *matCellDef="let row" class="fit-content">
          {{row.contract.externalContractNumber}}
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Beschreibung</th>
        <td mat-cell *matCellDef="let row" class="fit-content">
          {{row.contract.description}}
        </td>
      </ng-container>

      <ng-container matColumnDef="whitespace">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="columnEditor.displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: columnEditor.displayedColumns;"></tr>
    </table>
  </div>

  <mat-divider></mat-divider>

  <div class="footer-bar">
    <button type="button" mat-raised-button color="primary" [matMenuTriggerFor]="actionMenu" [disabled]="!someSelected() && !allSelected()">Aktionen</button>

    <mat-menu #actionMenu="matMenu">
      <button mat-menu-item (click)="addSelectedToSageExportQueue()">In Export Warteschlange hinzufügen</button>
    </mat-menu>

    <button type="button" mat-raised-button color="primary" [matMenuTriggerFor]="excelMenu" (click)="calculateSelectedAmount()"> Listen download </button>


    <mat-menu #excelMenu="matMenu">
      <button mat-menu-item (click)="exportSelectedToExcel()">
        Ausgewählte als xlsx downloaden ({{selectedAmount + " von " + totalCount }})
      </button>

      <button mat-menu-item (click)="exportExcelAll()">
        Aktuellen Filter als xlsx downloaden
      </button>
    </mat-menu>

  </div>
</div>