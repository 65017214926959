<!-- Workaround: nötig, weil mehrere ng-content nicht funktionieren. -->
<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<!--
  Funktionsweise:

  Um den Inhalt einer Tabellenzelle <td> wird ein <a> gewrappet. Somit kann der Inhalt wie
  ein normales <a> Element angeklickt werden, und Mittelklick und Kontextmenü funktionieren wie bei jedem
  normalen Link.

  Das <a> Element nimmt aber nicht das gesamte <td> eine. Damit man mobil aber trotzdem irgendwo im
  <td> hinklicken kann, reagieren wir zusätzlich über HostListener auf (click) und (auxclick) des <td>.
  Ein Klick auf <a> löst  aber auch die Events des <td> aus, prüfen wir ob innerhalb eines <a> geklickt wurde.

  Es kann sein, dass sich innerhalb des <a> ein weiteres <a> befindet
  (z.B. wenn der Zelleninhalt <div [innerHTML]="">) ist.

  Daraus ergibt sich:
  - wenn auf das <a> Element geklickt wird, übernimmt der Browser über href die Navigation
  - wenn außerhalb des <a> aber innerhalb des <td> geklickt wird, reagieren wir auf die click Events und
    übernehmen die Navigation
  - sollte sich innerhalb des <a> Elements ein weiteres <a> Element befindet (z.B. durch <div [innerHTML]="">)
    übernimmt auch der Browser über href die Navigation.
  - wenn !cellLinkEnabled ist das alles hinfällig, weil wir kein <a> Element einfügen. <a> Elemente innerhalb
    werden trotzdem behandelt.
-->

<a *ngIf="cellLinkEnabled" class="app-cell-link-anchor" [href]="appCellLink">

  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>
<div *ngIf="!cellLinkEnabled">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>
