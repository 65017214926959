import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { CountryDto } from '../dto/country-dto';
import { GetAuthorizedMandatorsRequestDto } from '../dto/get-authorized-mandators-request-dto';
import { MandatorDto } from '../dto/mandator-dto';
import { Api2Service } from './api2.service';

const controller = 'Core';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  // Client-side cache
  private readonly _1DayMs = 24 * 60 * 60 * 1000;
  private getMandators$: Observable<MandatorDto[]>;
  private getCountries$: Observable<CountryDto[]>;


  constructor(
    private readonly api2: Api2Service
  ) { }

  getMandators(): Observable<MandatorDto[]> {
    if (!this.getMandators$) {
      this.getMandators$ = this.api2.get<MandatorDto[]>(controller, 'GetMandators').pipe(
        shareReplay(1, this._1DayMs),
        catchError(() => of(undefined))
      );
    }
    return this.getMandators$;
  }

  getCountries(): Observable<CountryDto[]> {
    if (!this.getCountries$) {
      this.getCountries$ = this.api2.get<CountryDto[]>(controller, 'GetCountries').pipe(
        shareReplay(1, this._1DayMs),
        catchError(() => of(undefined))
      );
    }
    return this.getCountries$;
  }

  getAuthorizedMandators(request: GetAuthorizedMandatorsRequestDto): Observable<MandatorDto[]> {
    return this.api2.post<MandatorDto[]>(controller, 'GetAuthorizedMandators', null, request);
  }
}
