<div class="content-container">
  <h1>Kalender teilen</h1>

  <form class="content-is-approved" *ngIf="!isRevoked && dto && !dto.isApproved" fxLayout="column" [formGroup]="formGroup">
    <div fxLayout="row wrap" fxLayoutAlign="start center">
      <cp-user-cell [user]="dto.sharedWithUser"></cp-user-cell>
      <div>&nbsp;hat Zugriff auf Ihren Kalender beantragt.</div>
    </div>

    <div class="share-actions">
      <mat-radio-group formControlName="isIndefinitely">
        <mat-radio-button [value]="true">Zugriff auf unbestimmte Zeit erlauben</mat-radio-button>

        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <mat-radio-button [value]="false">Zugriff erlauben bis:</mat-radio-button>
          <mat-form-field>
            <mat-label>Datum</mat-label>
            <input matInput [matDatepicker]="dp" formControlName="validUntilUtc">
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp></mat-datepicker>
            <mat-error>{{ formGroup.get('validUntilUtc').errors | validationError }}</mat-error>
          </mat-form-field>
        </div>

      </mat-radio-group>

      <button mat-raised-button color="primary" [disabled]="formGroup.invalid" (click)="approve()">Zugriff erteilen</button>
    </div>
  </form>

  <div class="content-is-not-approved" *ngIf="!isRevoked && dto && dto.isApproved">
    <div fxLayout="row wrap" fxLayoutAlign="start center">
      <div>Sie haben</div>
      <cp-user-cell [user]="dto.sharedWithUser"></cp-user-cell>
      <div *ngIf="isApprovedIndefinitely()">
        auf unbestimmte Zeit Zugriff auf Ihren Kalender erteilt.
      </div>
      <div *ngIf="!isApprovedIndefinitely()">
        bis zum <b>{{ dto.validUntilUtc | date }}</b> Zugriff auf Ihren Kalender erteilt.
      </div>
    </div>

    <button mat-raised-button color="primary" (click)="revoke()">Zugriff jetzt widerrufen</button>
  </div>

  <div class="content-revoked" *ngIf="isRevoked">
    <div fxLayout="row wrap" fxLayoutAlign="start center">
      <div>Sie haben</div>
      <cp-user-cell [user]="dto.sharedWithUser"></cp-user-cell>
      <div>den Zugriff auf Ihren Kalender entzogen.</div>
    </div>
    <button mat-raised-button color="primary" (click)="goHome()">OK</button>
  </div>
</div>

<app-loading-overlay [isLoading]="isLoading"></app-loading-overlay>
