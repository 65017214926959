import angular, { IQService } from "angular";
import moment from "moment";
import { DragAndDropPanel } from "src/ajs/Directives/DragAndDropPanel";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { SystemDto } from "src/ajs/Models/Systembuilder/SystemDto";
import { SystemQueryResultDto } from "src/ajs/Models/Systembuilder/SystemQueryResultDto";
import { SystemDetailsDto } from "src/ajs/Models/SystemSearch/SystemDetailsDto";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { StorageService, StorageServiceInstance } from "src/ajs/Services/StorageService";

createSystembuilderWidgetController.$inject = ['$scope', 'mdPanelRef', '$q', 'ApiHandlerService', 'StorageService', 'theme'];
createSystembuilderWidgetControllerWithLocals.$inject = ['$scope', 'mdPanelRef', '$q', 'ApiHandlerService', 'StorageService', 'FixedModelIdentcode', 'theme'];

export function createSystembuilderWidgetControllerWithLocals(
  $scope: ISystembuilderWidgetControllerScope,
  mdPanelRef: angular.material.IPanelRef,
  $q: IQService,
  ApiHandlerService: ApiHandlerService,
  StorageService: StorageService,
  FixedModelIdentcode: string,
  theme: ThemeDto) {
  internalCreate($scope, mdPanelRef, $q, ApiHandlerService, StorageService, FixedModelIdentcode, theme);
}

export function createSystembuilderWidgetController(
  $scope: ISystembuilderWidgetControllerScope,
  mdPanelRef: angular.material.IPanelRef,
  $q: IQService,
  ApiHandlerService: ApiHandlerService,
  StorageService: StorageService,
  theme: ThemeDto) {
  internalCreate($scope, mdPanelRef, $q, ApiHandlerService, StorageService, null, theme);
}

function internalCreate(
  $scope: ISystembuilderWidgetControllerScope,
  mdPanelRef: angular.material.IPanelRef,
  $q: IQService,
  ApiHandlerService: ApiHandlerService,
  StorageService: StorageService,
  FixedModelIdentcode: string,
  theme: ThemeDto
) {
  addDefaultScopeFunctions($scope, theme);
  $scope.Storage = StorageService.CreateInstance("SystembuilderController");

  $scope.QuerySystems = function (query: string) {
    let result: SystemDto[] = null;
    const deferred = $q.defer<SystemDto[]>();
    const request = ApiHandlerService.SendRequest("Systembuilder", "QuerySystems", { query: query }).then(function (data: SystemQueryResultDto) {
      result = data.Systems;
      deferred.resolve(result);
    });
    return deferred.promise;
  };

  $scope.SelectedSystemChanged = function (newSystem: any) {
    if (newSystem) {
      $scope.IsLoading = true;
      $scope.SelectedSystem = newSystem;
      ApiHandlerService.SendRequest("Systembuilder", "QuerySystemDetails", { identcode: newSystem.Identcode }).then(function (data: SystemDetailsDto) {
        $scope.Model = data;
        $scope.Model.BaseData.SystemDate = moment($scope.Model.BaseData.SystemDate);
        $scope.IsLoading = false;
      });
    } else {
      $scope.SelectedSystem = null;
    }
  };

  $scope.DragAndDropPanel = new DragAndDropPanel("aXs Systemsuche", mdPanelRef);

  if (FixedModelIdentcode) {
    $scope.IsLoading = true;
    $scope.HasFixedModel = true;
    $scope.SelectedSystem = true;
    ApiHandlerService.SendRequest("Systembuilder", "QuerySystemDetails", { identcode: FixedModelIdentcode }).then(function (data: SystemDetailsDto) {
      $scope.Model = data;
      $scope.Model.BaseData.SystemDate = moment($scope.Model.BaseData.SystemDate);
      $scope.IsLoading = false;
    });
  }
}


interface ISystembuilderWidgetControllerScope extends angular.IScope {

  Storage: StorageServiceInstance;
  IsLoading: boolean;
  SelectedSystem: any;
  Model: SystemDetailsDto;
  DragAndDropPanel: DragAndDropPanel;
  HasFixedModel: boolean;

  QuerySystems: (query: string) => angular.IPromise<SystemDto[]>;
  SelectedSystemChanged: (newSystem: any) => void
}
