import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, firstValueFrom, forkJoin, map, Observable, of, tap, throwError } from 'rxjs';
import { LoginRequestDto } from 'src/ajs/Models/LoginRequestDto';
import { LoginResultDto } from 'src/ajs/Models/LoginResultDto';
import { UserConfigurationDto } from 'src/ajs/Models/Phone3cx/UserConfigurationDto';
import { Role } from '../constants';
import { UserDto } from '../dto/user-dto';
import { UserInfoDto } from '../dto/user-info-dto';
import { Api1Service } from './api1.service';
import { Api2Service } from './api2.service';

const noUserId = "00000000-0000-0000-0000-000000000000";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly emptyUser: UserInfoDto = { userId: noUserId, userName: null, roles: [], isGlobalAdmin: false, firstName: null, lastName: null, phoneExtension: null };

  /** Infos über den aktuell angemeldeten Benutzer. */
  currentUser: UserInfoDto = this.emptyUser;

  /** Gibt zurück, ob ein Benutzer angemeldet ist. */
  get isLoggedIn(): boolean {
    return this.currentUser.userId && this.currentUser.userId !== noUserId;
  }

  get isGlobalAdmin() {
    return this.currentUser.isGlobalAdmin;
  }

  get userId() {
    return this.currentUser.userId;
  }

  constructor(
    @Inject('BASE_URL_API1') private readonly BASE_URL_API1: string,
    private readonly http: HttpClient,
    private readonly api1: Api1Service,
    private readonly api2: Api2Service
  ) { }

  private getCurrentUserInfo(): Observable<UserInfoDto> {
    return this.api2.get<UserInfoDto>('User', 'GetCurrentUserInfo');
  }

  private getLegacyUserConfig(): Observable<UserConfigurationDto> {
    return this.api1.post<UserConfigurationDto>("Phone3cx", "GetUserConfiguration");
  }

  /**
   * Die Login/Logout-Logik läuft noch über die AngularJS-App.
   * diese Funktion wird von der AngularJS-App aufgerufen, wenn sich der aktuelle User ändern.
   */
  reloadUserInfo(): Promise<any> {
    return firstValueFrom(forkJoin([
      this.getCurrentUserInfo(),
      this.getLegacyUserConfig()
    ]).pipe(
      tap(([userInfo, legacyUserConfig]) => {
        userInfo.phoneExtension = legacyUserConfig?.Extension;
        this.currentUser = userInfo;
        console.log('Current userId is ' + userInfo.userId);
      }),
      catchError(err => {
        this.currentUser = this.emptyUser;
        return throwError(() => err);
      }))
    );
  }

  getProfilePicture(user: UserDto): string {
    return this.BASE_URL_API1 + 'PublicContacts/GetUserProfilePicture?accountName=' + user?.userName;
  }

  getOwnProfilePicture(): string {
    return this.BASE_URL_API1 + 'PublicContacts/GetUserProfilePicture?accountName=' + this.currentUser.userName;
  }

  loginLegacy(username: string, password: string, code: string): Observable<void> {
    // das Login wird noch über die alte API gemacht
    const dto: LoginRequestDto = {
      UserName: username,
      Password: password,
      Validation: code,
      StayLoggedIn: true
    };

    return this.http.post<LoginResultDto>(this.BASE_URL_API1 + "User/Login", dto, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8' }),
    }).pipe(
      map(result => {
        if (!result.Success) {
          throw new Error(result.Message);
        }
      })
    );
  }

  loginWindowsAuth(): Observable<void> {
    return this.api2.post<void>('User', 'LoginWindowsAuth');
  }

  logout(): Observable<void> {
    return this.api2.post<void>('User', 'Logout').pipe(
      // Fehler ignorieren
      catchError(err => {
        console.log(err);
        return of();
      })
    );
  }

  /**
   * Gibt zurück, ob der angemeldet Benutzer in diesem Mandanten grundlegende Leserechte hat.
   */
  canRead(mandatorId: string): boolean {
    return this.hasRoleDeprecated(Role.CoreUser, mandatorId);
  }

  /**
   * Gibt zurück, ob der angemeldete Benutzer in einem Mandanten eine Rolle explizit hat.
   * Admins und GlobalAdmins müssen die Rolle auch explizit haben.
   */
  hasRoleExplicit(mandatorId: string, roleName: string,): boolean {
    return this.currentUser.roles.some(x => x.roleName === roleName && x.mandatorId === mandatorId);
  }

  /**
   * Gibt zurück, ob der angemeldete Benutzer die Rolle im Mandanten hat.
   * Admins und GlobalAdmins haben die Rolle auch implizit.
   */
  hasRoleDeprecated(roleName: string, mandatorId: string): boolean {
    return this.currentUser.roles.some(x => this.isGlobalAdmin ||
      (x.mandatorId === mandatorId && (x.roleName === roleName || x.roleName === Role.CoreAdmin)));
  }

  /**
   * Gibt zurück, ob der angemeldete Benutzer die Rolle im Mandanten hat.
   * Admins und GlobalAdmins haben die Rolle auch implizit.
   */
  hasRole(mandatorId: string, roleName: string): boolean {
    return this.currentUser.roles.some(x => this.isGlobalAdmin ||
      (x.mandatorId === mandatorId && (x.roleName === roleName || x.roleName === Role.CoreAdmin)));
  }

  /**
   * Gibt zurück, ob der angemeldete Benutzer eine der Rollen im Mandanten hat.
   * Admins und GlobalAdmins haben die Rolle auch implizit.
   */
  hasAnyRole(mandatorId: string, ...roleNames: string[]) {
    return roleNames.some(x => this.hasRole(mandatorId, x));
  }

  /**
   * Gibt zurück, ob der angemeldete Benutzer die Rolle in irgendeinem Mandanten hat.
   * Admins und GlobalAdmins haben die Rolle auch implizit.
   */
  hasRoleAnyMandator(roleName: string): boolean {
    return this.currentUser.roles.some(x => this.isGlobalAdmin || x.roleName === roleName || x.roleName === Role.CoreAdmin);
  }
}
