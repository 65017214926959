<div class="flex-container">

  <h2>Notiz verfassen</h2>

  <mat-form-field>
    <mat-label>
      Notiz
    </mat-label>
    <input matInput type="text" [formControl]="noteInput">
  </mat-form-field>


  <div>
    <button mat-raised-button (click)="cancel()" color="warn">Abbrechen</button>
    <button mat-raised-button (click)="ok()" color="primary">Ok</button>
  </div>

</div>