import { ngStorage } from 'ngstorage';
import { MandatorService } from 'src/app/core/services/mandator.service';


export class StorageService {

  readonly StorageModule: ngStorage.StorageService;

  static readonly $inject = ['$localStorage', 'MandatorServiceNgx'];

  constructor(
    private readonly $localStorage: ngStorage.StorageService,
    private readonly mandatorService: MandatorService
  ) {
    this.StorageModule = $localStorage;
  }

  CreateInstance(identifier: string): StorageServiceInstance {
    const instance = new StorageServiceInstance(identifier, this.$localStorage, this.mandatorService);
    return instance;
  }
}

export class StorageServiceInstance {

  private readonly Dictionary;

  constructor(
    private readonly identifier: string,
    private readonly $localStorage: ngStorage.StorageService,
    private readonly mandatorService: MandatorService
  ) {
    this.Dictionary = this.$localStorage[this.identifier];
    if (!this.Dictionary) {
      this.$localStorage[this.identifier] = this.Dictionary = {};
    }
  }

  Get(key: string): any {
    if (this.Dictionary[key] !== undefined) {

      if (key === "LastSelectedAddress") {
        if (this.Dictionary.LastSelectedAddress.CachedAddress.MandatorId === this.mandatorService.selectedMandatorId) {
          return this.Dictionary[key];
        }
      }
      else {
        return this.Dictionary[key];
      }
    }
    throw "Key '" + key + "' not found";
  }

  GetOrCreate(key: string, defaultValue: any): any {
    try {
      return this.Get(key);
    } catch (err) {
      this.Set(key, defaultValue);
      return defaultValue;
    }
  }

  Set(key: string, value: any) {
    this.Dictionary[key] = value;
    this.$localStorage[this.identifier] = this.Dictionary;
    return value;
  }
}