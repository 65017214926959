
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { ColumnEditorService } from '../column-editor/column-editor.service';
import { ExcelColumnDefinition } from './excel-column-definition';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { DateUtils } from '../../utils/date-utils';

/**
 * Zum Festlegen von Spalteneigenschaften für den Excel-Export.
 */
export const EXCEL_EXPORT_COLDEF = new InjectionToken<ExcelColumnDefinition[]>('EXCEL_EXPORT_COLDEF');

@Injectable()
export class ExcelExportService {

  constructor(
    private readonly columnEditor: ColumnEditorService,
    @Optional() @Inject(EXCEL_EXPORT_COLDEF) private readonly excelColumnDefinitions: ExcelColumnDefinition[]
  ) { }

  async exportArrayAsExcel(array: any[], fileName: string) {

    if (!array?.length) {
      return;
    }

    const sheetData = this.transformToSheetData(array);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(fileName);

    const workSheetColumns: Partial<ExcelJS.Column>[] = [];

    for (const property in sheetData[0]) {
      const newColumn = { header: property, key: property, style: null };

      if (this.isDateColumn(property, sheetData)) {
        newColumn.style = { numFmt: 'dd.mm.yyyy hh:mm' };
      }

      workSheetColumns.push(newColumn);
    }

    worksheet.columns = workSheetColumns;
    worksheet.addRows(sheetData);
    worksheet.getRow(1).font = { bold: true };

    worksheet.autoFilter = {
      from: "A1",
      to: worksheet.columns[worksheet.columns.length - 1].letter + worksheet.rowCount,
    };

    const columnMaxWidth = 40;
    const columnMinWidth = 10;

    for (const column of worksheet.columns) {
      let currentWidth = 0;

      column.eachCell(cell => {
        let columnWidth;

        if (cell.value instanceof Date) {
          columnWidth = 18;
        }
        else {
          columnWidth = cell.value ? cell.value.toString().length * 1.1 : 0;
        }

        if (columnWidth > currentWidth) {
          currentWidth = columnWidth;
        }
      });

      column.width = currentWidth < columnMinWidth ? columnMinWidth : currentWidth > columnMaxWidth ? columnMaxWidth : currentWidth;
    }

    const buffer: Buffer = await (workbook as any).xlsx.writeBuffer({ base64: true });
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    saveAs(blob, fileName);
  }

  private getPropertyValue(item: any, name: string, excelColDef: ExcelColumnDefinition): any {
    if (excelColDef?.valueResolver) {
      const result = excelColDef.valueResolver(item);
      return result;
    }
    else if (excelColDef?.propertyPath) {
      const result = excelColDef.propertyPath.split('.').reduce((o, i) => o ? o[i] : null, item);
      return result;
    }
    else {
      return item[name];
    }
  }

  private isDateColumn(property: string, array: any[]) {
    for (const entry of array) {
      if (entry[property] instanceof Date) {
        return true;
      }
    }
    return false;
  }

  private transformToSheetData(array: any[]): any[] {
    const resultArray = [];

    for (const item of array) {

      const newObject = {};

      for (const columnName of this.columnEditor.displayedColumns) {

        const columnDefinition = this.columnEditor.definitions.find(x => x.name === columnName);

        if (columnDefinition) {

          let currentExcelColumnDefinition: ExcelColumnDefinition;

          if (this.excelColumnDefinitions) {
            const excelColumnDefinition = this.excelColumnDefinitions.find(x => x.name === columnName);
            if (excelColumnDefinition) {
              currentExcelColumnDefinition = excelColumnDefinition;
            }
          }
          if (!currentExcelColumnDefinition || currentExcelColumnDefinition && !currentExcelColumnDefinition.noExport) {
            let value = this.getPropertyValue(item, columnName, currentExcelColumnDefinition);

            // exceljs konvertiert Datumswerte in UTC, daher vorher den UTC-Offset addieren
            if (value instanceof Date) {
              value = DateUtils.addMinutes(value, value.getTimezoneOffset() * -1);
            }
            newObject[columnDefinition.header] = value;
          }
        }
      }
      resultArray.push(newObject);
    }
    return resultArray;
  }
}


