import { DownloadEventDto } from "../Models/PriceImport/DownloadEventDto";
import { FileEventDto } from "../Models/PriceImport/FileEventDto";
import { VendorEventDto } from "../Models/PriceImport/VendorEventDto";

export class PriceImportService {
  static $inject = [];
  private readonly Hub: any;
  readonly DownloadChangedCallbacks: ((dto: DownloadEventDto) => void)[] = [];
  readonly FileChangedCallbacks: ((dto: FileEventDto) => void)[] = [];
  readonly VendorChangedCallbacks: ((dto: VendorEventDto) => void)[] = [];

  constructor() {
    this.Hub = (<any>$.connection).priceImportHub;

    if (this.Hub) {
      this.Hub.client.vendorChanged = (dl) => {
        this.VendorChangedCallbacks.forEach((callback) => {
          callback(dl);
        });
      };

      this.Hub.client.fileChanged = (dl) => {
        this.FileChangedCallbacks.forEach((callback) => {
          callback(dl);
        });
      };
      this.Hub.client.downloadChanged = (dl) => {
        this.DownloadChangedCallbacks.forEach((callback) => {
          callback(dl);
        });
      };
    }
    else {
      console.warn("priceImportHub not started.");
    }
  }

  RegisterMandator(id) {
    if (this.Hub) {
      this.Hub.server.registerMandator(id);
    }
  }

  UnregisterMandator(id) {
    if (this.Hub) {
      this.Hub.server.unregisterMandator(id);
    }
  }
}
