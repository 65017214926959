import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { LabelPrintDialogComponent } from "../components/label-print-dialog/label-print-dialog.component";
import { firstValueFrom } from "rxjs";
import { PasswordGeneratorDialogComponent } from "../components/password-generator-dialog/password-generator-dialog.component";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";

const controller = "widget";

@Injectable({
  providedIn: 'root'
})

export class WidgetService {

  constructor(
    private readonly dialog: MatDialog,
    private readonly apiHandlerService: ApiHandlerService
  ) { }

  async showPasswordGeneratorDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = {

    };

    const dialog = this.dialog.open<PasswordGeneratorDialogComponent>(PasswordGeneratorDialogComponent, dialogConfig);

    const ok = await firstValueFrom(dialog.afterClosed());
    return ok;
  }


  async showLabelPrintDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.hasBackdrop = false;
    dialogConfig.data = {

    };

    const dialog = this.dialog.open<LabelPrintDialogComponent>(LabelPrintDialogComponent, dialogConfig);

    const ok = await firstValueFrom(dialog.afterClosed());
    return ok;
  }

  //

  async generateHash(value: string, key: string, algorithm: string) {

    return this.apiHandlerService.SendRequest("Toolbox", "GenerateHash", {
      value: value,
      secret: key,
      algorithm: algorithm
    });


  }



}