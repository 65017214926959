import angular from "angular";
import moment from "moment";
import { ColumnDefinition, HestiaDataTable } from "src/ajs/Directives/HestiaDataTableDirective";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { QueryAddressDto } from "src/ajs/Models/Erp/QueryAddressDto";
import { QueryAdministrationDto } from "src/ajs/Models/Erp/QueryAdministrationDto";
import { SlaDto } from "src/ajs/Models/Erp/SlaDto";
import { SlaTimeEntryDto } from "src/ajs/Models/Erp/SlaTimeEntryDto";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { StorageService, StorageServiceInstance } from "src/ajs/Services/StorageService";
import { addNavigation } from "src/ajs/Utils/HestiaCommon";
import { INavigationScope } from "src/ajs/Utils/INavigationScope";
import { MandatorService } from "src/app/core/services/mandator.service";

createErpAdminController.$inject = ['$scope', '$q', '$timeout', 'ApiHandlerService', 'StorageService', 'theme', 'MandatorServiceNgx'];

export function createErpAdminController(
  $scope: ICreateErpAdminControllerScope,
  $q: angular.IQService,
  $timeout: angular.ITimeoutService,
  ApiHandlerService: ApiHandlerService,
  StorageService: StorageService,
  theme: ThemeDto,
  mandatorServiceNgx: MandatorService
) {
  $scope.Storage = StorageService.CreateInstance("ErpAdminController");
  addDefaultScopeFunctions($scope, theme);
  addNavigation($scope, "ServiceLevelAgreement");
  $scope.SelectedSla = null;

  $scope.LoadData = function () {
    ApiHandlerService.SendRequest("Erp", "QueryAdministration", { mandatorId: mandatorServiceNgx.selectedMandatorId }).then(function (data: QueryAdministrationDto) {
      $scope.Sla = data.SlaData.Sla;
      $scope.SelectedSla = null;
      $scope.Sla.forEach(f => {
        f.TimeEntries.forEach(t => {
          t.StartTime = moment(t.StartTime);
          t.EndTime = moment(t.EndTime);
          t.StartTimeFormatted = moment(t.StartTime).format("HH:mm");
          t.EndTimeFormatted = moment(t.EndTime).format("HH:mm");
        });
        f.AddressCount = f.Addresses.length;
      });

      $scope.SlaTable.UpdateSource($scope.Sla, $timeout);
    });
  };

  mandatorServiceNgx.selectedMandatorIdChanged.subscribe(() => {
    $scope.LoadData();
  });


  $scope.RemoveAddress = function (sla: SlaDto, address) {
    sla.Addresses = sla.Addresses.filter(f => f !== address);
  };
  $scope.AddTiming = function (sla: SlaDto) {
    sla.TimeEntries.push({
      Id: 0,
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
      Holiday: false,
      StartTime: null,
      EndTime: null
    } as SlaTimeEntryDto);
  };
  $scope.AddNewSla = function () {
    $scope.SelectedSla = {
      Id: 0,
      Name: null,
      Description: null,
      IsActive: true,
      IsCustomerSpecific: false,
      Addresses: [],
      TimeEntries: []
    } as SlaDto;
  };
  $scope.AddressAdded = function (sla: SlaDto) {
    if (sla && sla.SelectedAddress) {
      if (!sla.Addresses) {
        sla.Addresses = [];
      }
      if (!sla.Addresses.filter(f => f.Address.Id === sla.SelectedAddress.Id).length) {
        sla.Addresses.push({
          Id: 0,
          Pin: 0,
          Address: sla.SelectedAddress
        });
      }
      sla.SelectedAddress = null;
      $scope.AddressSearchText = null;
    }
  },
    $scope.QueryAddress = function (searchString: string) {
      let result = null;
      const deferred = $q.defer();
      const request = ApiHandlerService.SendRequest("Erp", "QueryAddress", { groupId: $scope.Id, query: searchString, mandatorId: mandatorServiceNgx.selectedMandatorId }).then(function (data: QueryAddressDto) {
        result = [];
        data.Result.forEach(f => {
          result.push(f.CachedAddress);
        });
        deferred.resolve(result);
      });
      return deferred.promise;
    };

  $scope.SaveSlaChanges = function (sla: SlaDto) {
    sla.TimeEntries.forEach(f => {
      f.StartTime = moment(new Date(<string>f.StartTime).toISOString()).format("HH:mm");
      f.EndTime = moment(new Date(<string>f.EndTime).toISOString()).format("HH:mm");
    });
    ApiHandlerService.SendRequest("Erp", "SaveSla", { sla: sla, mandatorId: mandatorServiceNgx.selectedMandatorId }).then(function (data) {
      $scope.LoadData();
      $scope.SelectedSla = null;
    });
  };

  $scope.RemoveTiming = function (sla: SlaDto, timing: SlaTimeEntryDto) {
    sla.TimeEntries = sla.TimeEntries.filter(f => f !== timing);
  };
  $scope.HasTimeEntry = function (dayId: number, sla: SlaDto) {
    if (sla && sla.TimeEntries) {
      return sla.TimeEntries.filter(f => f.Day === dayId).length;
    }
    return false;
  };
  $scope.SlaSelected = function (sla: SlaDto) {
    $scope.SelectedSla = sla;
  };
  $scope.SlaTable = new HestiaDataTable(StorageService.CreateInstance("SlaTable"), $timeout, $scope);
  $scope.SlaTable.ItemClicked = $scope.SlaSelected;
  $scope.SlaTable.Options.Select = false;
  $scope.SlaTable.UpdateColumns([
    new ColumnDefinition({ PropertyPath: 'Name', Heading: 'Name', Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'AddressCount', Heading: 'Kunden zugewiesen', Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'IsCustomerSpecific', Heading: 'Kundenspezifisch', Align: 'center', Template: "/ClientApp/src/ajs/Views/Common/BoolToCheckboxTemplate.htm", Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'IsActive', Heading: 'Aktiv', Align: 'center', Template: "/ClientApp/src/ajs/Views/Common/BoolToCheckboxTemplate.htm", Resizeable: true, Sortable: true })
  ]);

  $scope.LoadData();
  $scope.Navigate($scope.Storage.GetOrCreate("ActiveTab", "ServiceLevelAgreement"), true);
}


interface ICreateErpAdminControllerScope extends angular.IScope, INavigationScope {
  Storage: StorageServiceInstance;
  SelectedSla: SlaDto;
  Sla: SlaDto[];
  CurrentAddressId: any;
  SlaTable: HestiaDataTable;
  AddressSearchText: any;
  Id: number;

  LoadData: () => void;
  RemoveAddress: (sla, address) => void;
  AddTiming: (sla) => void;
  AddNewSla: () => void;
  AddressAdded: (sla) => void;
  QueryAddress: (searchString) => angular.IPromise<unknown>;
  SaveSlaChanges: (sla) => void;
  RemoveTiming: (sla, timing) => void;
  HasTimeEntry: (dayId, sla) => boolean | number;
  SlaSelected: (sla) => void;
}
