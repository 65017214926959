
import { fixDate } from "src/ajs/Utils/DateUtils";
import { fixPhone3CxExtensionDto, Phone3CxExtensionDto } from "./phone-3cx-extension-dto";
import { fixPhone3CxQueueDto, Phone3CxQueueDto } from "./phone-3cx-queue-dto";

export interface Phone3CxCallPartEntryDto {
  Id: number;
  Phone3CxCallId: number;
  CallConnectionId: number;
  CallSipIdentifier: string;
  Chid: string;
  CallId: number;
  PreviousCallId: number | null;
  Qdn: string;
  DialedNumber: string;
  EntryStart: Date;
  EntryEnd: Date | null;
  AffectedExtension: string;
  ExtensionId: number | null;
  QueueId: number | null;
  OtherPartyNumber: string;
  OtherPartyExtensionId: number | null;
  OtherPartyQueueId: number | null;
  ConnectionStatus: number;
  ConnectionType: number;
  DurationMilliseconds: number;

  Extension: Phone3CxExtensionDto
  Queue: Phone3CxQueueDto
}

export function fixPhone3CxCallPartEntryDto(dto: Phone3CxCallPartEntryDto) {
  if (dto.EntryStart) {
    dto.EntryStart = fixDate(dto.EntryStart);
  }
  if (dto.EntryEnd) {
    dto.EntryEnd = fixDate(dto.EntryEnd);
  }

  if (dto.Extension) {
    fixPhone3CxExtensionDto(dto.Extension);
  }

  if (dto.Queue) {
    fixPhone3CxQueueDto(dto.Queue);
  }
}
