import angular from "angular";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { CachedAddressDto } from "src/ajs/Models/Erp/CachedAddressDto";
import { QueryAddressDto } from "src/ajs/Models/Erp/QueryAddressDto";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { MandatorService } from "src/app/core/services/mandator.service";

createErpSelectorController.$inject = ['$scope', '$mdDialog', '$q', 'ApiHandlerService', 'TextAddition', 'TextLabel', 'theme', 'MandatorServiceNgx'];

export function createErpSelectorController(
  $scope: ICreateErpSelectorControllerScope,
  $mdDialog: angular.material.IDialogService,
  $q: angular.IQService,
  ApiHandlerService: ApiHandlerService,
  TextAddition: boolean = false,
  TextLabel: any = null,
  theme: ThemeDto,
  mandatorServiceNgx: MandatorService

) {
  addDefaultScopeFunctions($scope, theme);
  $scope.Cancel = function () {
    $mdDialog.hide(-1);
  };
  $scope.TextAddition = TextAddition;
  $scope.TextLabel = TextLabel;
  $scope.SelectedAddress = null;
  $scope.SearchText = null;
  $scope.AdditionalText = null;

  $scope.QueryAddress = function (searchString: string) {
    let result = null;
    const deferred = $q.defer();
    const request = ApiHandlerService.SendRequest("Erp", "QueryAddress", { groupId: $scope.Id, query: searchString, mandatorId: mandatorServiceNgx.selectedMandatorId }).then(function (data: QueryAddressDto) {
      result = data.Result;
      deferred.resolve(result);
    });
    return deferred.promise;
  };

  $scope.Changed = function (value: string) {
    $scope.AdditionalText = value;
  };

  $scope.Confirm = function () {
    if ($scope.SelectedAddress) {
      $scope.SelectedAddress.CachedAddress.Text = $scope.AdditionalText;
      $mdDialog.hide($scope.SelectedAddress.CachedAddress);
    }
    else {
      $mdDialog.hide(null);
    }
  };
}

interface ICreateErpSelectorControllerScope extends angular.IScope {

  TextAddition: boolean;
  TextLabel: any;
  SelectedAddress: { CachedAddress: CachedAddressDto };
  SearchText: any;
  AdditionalText: string;
  Id: any;

  Cancel: () => void;
  QueryAddress: (searchString) => angular.IPromise<unknown>;
  Changed: (value) => void;
  Confirm: () => void;

}
