import { GetContactsResultDto } from "src/ajs/Models/PublicContacts/GetContactsResultDto";
import { Api1Service } from "src/app/core/services/api1.service";
import { GetPhonebookDataResultDto } from "../Models/PublicContacts/GetPhonebookDataResultDto";

export class PublicContactsService {

  static $inject = ['Api1ServiceNgx'];

  constructor(
    private readonly api: Api1Service
  ) {
  }

  getCalendar(mandatorId: string) {
    return this.api.post<GetContactsResultDto>('PublicContacts', 'GetCalendar', { mandatorId: mandatorId });
  }


  getPhonebookdata(mandatorId: string) {
    return this.api.post<GetPhonebookDataResultDto>('PublicContacts', 'GetPhonebookdata', { mandatorId: mandatorId });
  }

  togglePhonebookSync() {
    return this.api.post<any>('PublicContacts', 'TogglePhonebookSync');
  }

  getHolidays(mandatorId: string) {
    return this.api.post<GetContactsResultDto>('PublicContacts', 'getHolidays', { mandatorId: mandatorId });
  }
}

