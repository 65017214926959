import * as angular from "angular";

const directiveName = 'appendIcon';

/** Wird verwendet, um ein md-icon an ein md-autocomplete anzuhänge. */
export class AppendIconDirective implements angular.IDirective {

  restrict = 'A';

  constructor(
    private readonly $timeout: angular.ITimeoutService,
    private readonly $compile: angular.ICompileService
  ) { }

  link = (scope: angular.IScope, element: angular.IAugmentedJQuery, attrs: angular.IAttributes) => {
    this.$timeout(() => {
      let pContainer = element[0].querySelector('md-input-container');
      let useNoFloatLogic = false;

      if (!pContainer) {
        useNoFloatLogic = true;
        pContainer = element[0];
      }

      const container = angular.element(pContainer);
      if (!useNoFloatLogic) {
        container.addClass('md-icon-left');
        const icon = this.$compile('<md-icon class="material-icons">' + attrs[directiveName] + '</md-icon>')(scope);
        container.append(icon);
      }
      else {
        const input = element[0].querySelector('md-autocomplete-wrap').querySelector("input");
        const icontainer = angular.element(input);
        icontainer.css('padding-left', '40px');
        container.addClass('md-icon-left');
        const icon = this.$compile('<md-icon style="position: absolute; margin-top: 8px; margin-left:7px;" class="material-icons">' + attrs[directiveName] + '</md-icon>')(scope);
        container.prepend(icon);
      }
    });
  }

  static factory(): angular.IDirectiveFactory {
    const directive = ($timeout: angular.ITimeoutService, $compile: angular.ICompileService) => new AppendIconDirective($timeout, $compile);
    directive.$inject = ['$timeout', '$compile'];
    return directive;
  }
}
