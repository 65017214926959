import * as angular from 'angular';
import { ColumnDefinition } from '../Directives/HestiaDataTableDirective';

export function HestiaTableFilter() {
  return function (array: any[], key: string, columns: ColumnDefinition[]) {
    if (!angular.isArray(array)) {
      return [];
    }
    if (!columns) {
      return [];
    }

    const column: ColumnDefinition = columns.find(c => c.PropertyPath === key);

    const nonEmpty = array.filter((item) => {
      if (key && column) {
        const value = column.GetValue(item);
        return value != null;
      } else {
        return item[key] != null;
      }
    });

    const empty = array.filter((item) => {
      if (key && column) {
        const value = column.GetValue(item);
        return value == null;
      } else {
        return item[key] == null;
      }
    });

    const all = nonEmpty.concat(empty);

    return all.filter((item) => {
      const filterColumns = columns.filter(c => c.FilterText);
      for (let i = 0; i < filterColumns.length; i++) {
        const column = filterColumns[i];
        const value = column.GetValue(item);
        if (value.toLowerCase().indexOf(column.FilterText.toLowerCase()) !== -1)
          return true;
        else
          return false;
      }
      return true;
    });
  }

}