
import { fixDate } from "src/ajs/Utils/DateUtils";
import { fixTasksReceiptRowDto, TasksReceiptRowDto } from "./tasks-receipt-row-dto";

export interface TaskReceiptEditDto {
  Name: string;
  ForeignReceiptId: string;
  AccountingPeriodEnd: Date;
  Address: string;
  TaskReceiptRows: TasksReceiptRowDto[];
}

export function fixTaskReceiptEditDto(dto: TaskReceiptEditDto) {
  dto.AccountingPeriodEnd = fixDate(dto.AccountingPeriodEnd);

  if (dto.TaskReceiptRows) {
    for (const row of dto.TaskReceiptRows) {
      fixTasksReceiptRowDto(row);
    }
  }
}
