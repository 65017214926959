import angular from "angular";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { ConfigurationDto } from "src/ajs/Models/PublicContacts/ConfigurationDto";
import { QueryLdapGroupsResultDto } from "src/ajs/Models/PublicContacts/QueryLdapGroupsResultDto";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { StorageService } from "src/ajs/Services/StorageService";
import { UserService } from "src/app/core/services/user.service";

PublicContactsAdminDialogController.$inject = ['$scope', '$location', '$http', '$mdToast', '$mdDialog', '$q', '$timeout', 'UserService', 'ApiHandlerService', 'StorageService', 'theme'];

export function PublicContactsAdminDialogController(
  $scope: IPublicContactsAdminDialogControllerScope,
  _$location: angular.ILocationService,
  _$http: angular.IHttpService,
  _$mdToast: angular.material.IToastService,
  $mdDialog: any,
  $q: angular.IQService,
  _$timeout: angular.ITimeoutService,
  _UserService: UserService,
  ApiHandlerService: ApiHandlerService,
  _StorageService: StorageService,
  theme: ThemeDto,
) {

  addDefaultScopeFunctions($scope, theme);
  $scope.IsLoading = true;
  $scope.Model = {} as ConfigurationDto;
  $scope.Model.Type = 0;

  $scope.QueryLdapGroups = function (searchString) {
    let result = null;
    const deferred = $q.defer();
    const request = ApiHandlerService.SendRequest("PublicContacts", "QueryLdapGroups", { groupId: $scope.Id, query: searchString }).then(function (data: QueryLdapGroupsResultDto) {
      result = data.LdapGroups;
      deferred.resolve(result);
    });
    return deferred.promise;
  };

  $scope.SelectedLdapGroupChanged = function () {

  };

  ApiHandlerService.SendRequest("PublicContacts", "GetConfiguration").then(function (data: ConfigurationDto) {
    $scope.Model = data;
  });

  $scope.SaveData = function () {
    ApiHandlerService.SendRequest("PublicContacts", "SetConfiguration", $scope.Model).then(function (data: ConfigurationDto) {
      $scope.Model = data;
      $scope.Cancel();
    });
  };

  $scope.Cancel = function () {
    $mdDialog.hide();
  };
}

interface IPublicContactsAdminDialogControllerScope extends angular.IScope {
  IsLoading: boolean;
  Model: ConfigurationDto;
  Id: any;

  QueryLdapGroups: (searchString: string) => angular.IPromise<unknown>;
  SelectedLdapGroupChanged: () => void;
  SaveData: () => void;
  Cancel: () => void;

}
