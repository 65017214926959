import * as angular from 'angular';
import { ThemeDto } from './Models/ThemeDto';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'src/app/app.module';
import * as moment from 'moment';
import { providers } from 'src/main';
import { getBaseUrlApi1, getBaseUrlSignalR } from 'src/environments/factories';

const initInjector = angular.injector(['ng']);
const $http = initInjector.get('$http');
const HestiaApp = angular.module('HestiaApp');

window.moment = moment;

const baseUrlApi1 = getBaseUrlApi1();
const baseUrlSignalR = getBaseUrlSignalR();

// // SignalR Hubs laden
// $.ajax({
//   url: baseUrlSignalR + "hubs",
//   dataType: "script",
//   async: false
// });
// -> nach SignalRService verschoben

// Bevor die app gestartet werden kann, brauchen wir noch Daten von der API
$http.post(baseUrlApi1 + "Administration/LoadApplicationThemes", null)
  .then(data => {
    const themeData = data.data as ThemeDto[];

    // Theme
    let currentTheme: ThemeDto = themeData[0];
    themeData.forEach(t => {
      if (t.IsSystemDefaultTheme) {
        currentTheme = t;
      }
    });
    angular.module('theme', []).constant('theme', currentTheme);
    angular.module('themes', []).constant('themes', themeData);
    HestiaApp.value('hestiaThemes', themeData);
    document.title = themeData[0].SiteTitle;

    // HestiaApp starten
    angular.element(document).ready(function () {
      // strictDi: dadurch werden Fehler beim uglifyen vermieden.
      // siehe https://docs.angularjs.org/guide/di#using-strict-dependency-injection

      console.log('HestiaApp.js bootstrapping...');

      // AngularJS bootstrapping:
      //angular.bootstrap(document, ['HestiaApp'], { strictDi: true });

      // Angular ngUpgrade bootstrapping:
      platformBrowserDynamic(providers)
        .bootstrapModule(AppModule)
        .catch(err => console.log(err));
    });
  });

