createAddMailboxDialogController.$inject = ['$scope', '$mdDialog', '$q', 'ApiHandlerService', 'LocalizationService', 'Parent', 'MandatorServiceNgx'];

export function createAddMailboxDialogController($scope, $mdDialog, $q, ApiHandlerService, LocalizationService, Parent, MandatorServiceNgx) {
  $scope.SearchString = null;
  $scope.SelectedMailbox = null;
  $scope.Localization = LocalizationService.GetPluginDict("InformationHub");
  $scope.QueryMailboxes = function (searchString) {
    var deferred = $q.defer();
    ApiHandlerService.SendRequest("InformationHub", "QueryMailboxes", { query: searchString, mandatorId: MandatorServiceNgx.selectedMandatorId }).then(function (data) {
      deferred.resolve(data.Results);
    });
    return deferred.promise;
  };
  $scope.Submit = function () {
    if ($scope.SelectedMailbox) {
      ApiHandlerService.SendRequest("InformationHub", "AddMailboxSource", { model: $scope.SelectedMailbox, mandatorId: MandatorServiceNgx.selectedMandatorId }).then(function (data) {
        Parent.LoadData();
        $mdDialog.hide();
      });
    }
  };
  $scope.Cancel = function () {
    $mdDialog.hide();
  };
}
