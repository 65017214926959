import { MatTableDataSource } from "@angular/material/table";

export class MatTableDataSourceEx<T> extends MatTableDataSource<T> {

  private readonly sortingDataAccessors: { sortHeaderId: string, dataAccessor: (data: T) => string | number }[] = [];

  constructor(initialData?: T[]) {
    super(initialData);

    this.sortingDataAccessor = this.extendedSortingDataAccessor;
  }

  /**
   * Legt für eine Spalte fest, welcher Wert für die Sortierung verwendet werden soll.
   * Überschreibt damit die Standardsortierung für diese Spalte.
   */
  addSortingDataAccessor(sortHeaderId: string, dataAccessor: (data: T) => string | number) {
    this.sortingDataAccessors.push({
      sortHeaderId: sortHeaderId,
      dataAccessor: dataAccessor
    });
  }

  private extendedSortingDataAccessor(data: T, sortHeaderId: string): string | number {
    let result: string | number;

    // Wenn es einen sortingDataAccessor für diese Spalte gibt, wird dieser verwendet.
    const override = this.sortingDataAccessors.find(x => x.sortHeaderId === sortHeaderId);
    if (override) {
      result = override.dataAccessor(data);
    }
    // Property path im SortHeader unterstützen, also z.B. "user.mandator.description"
    else if (sortHeaderId.includes('.')) {
      result = <string | number><unknown>sortHeaderId.split('.').reduce((o, i) => o ? o[i] : null, data);
    }
    else {
      result = data[sortHeaderId];
    }

    // case-insensitive sortieren
    // https://github.com/angular/components/issues/9205
    if (typeof result === "string") {
      return result?.toLowerCase();
    }
    else {
      return result;
    }
  }

  applyFilter() {
    // Der Filter wird neu angewendet, wenn sich die filter-Eigenschaft ändert und die
    // filter-Eigenschaft nicht null/leer ist. Daher setzen wir wenn vorhanden den bestehenden
    // Wert nochmal rein, oder wenn kein Wert vorhanden ist, ein Unicode Zeichen wo die Wahrscheinlichkeit
    // sehr gering ist, dass es in einem Text vorkommt.
    if (this.filter) {
      this.filter = this.filter;
    }
    else {
      this.filter = "◙";
    }
  }

  updateData() {
    // Man muss eine Kopie zuweisen, sonst kriegt die mat-table die Änderung nicht mit.
    // TODO: Christian: ist slice wirklich nötig?
    if (this.data) {
      this.data = this.data.slice();
    }
  }
}
