<form [formGroup]="formGroup">

  <!-- Anrufdetails -->
  <div class="section-header">
    <div fxFlex>Anrufdetails</div>
    <button mat-icon-button class="small" (click)="close()">
      <mdi-icon icon="close" (click)="close()"></mdi-icon>
    </button>
  </div>

  <div class="section-content">

    <div class="row">
      <mat-form-field appearance="standard" class="flex-50">
        <mat-label>Gespräch</mat-label>
        <input matInput [readonly]="true" [value]="speakingTime|duration">
      </mat-form-field>

      <mat-form-field appearance="standard" class="flex-50">
        <mat-label>Gesamt</mat-label>
        <input matInput [readonly]="true" [value]="totalTime|duration">
      </mat-form-field>

    </div>

    <div class="row">
      <mat-form-field appearance="standard" class="flex-50">
        <mat-label>Datum</mat-label>
        <input matInput [readonly]="true" [value]="call?.StartDateTime | date">
      </mat-form-field>

      <mat-form-field appearance="standard" class="flex-50">
        <mat-label>Teilnehmer</mat-label>
        <input matInput [readonly]="true" [value]="call?.OtherPartyNumber">
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field appearance="standard" class="flex-100" *ngIf="!isEditing">
        <mat-label>Notiz</mat-label>
        <input matInput [readonly]="true" [value]="call?.Note">
      </mat-form-field>

      <mat-form-field appearance="standard" class="flex-100" *ngIf="isEditing">
        <mat-label>Notiz bearbeiten</mat-label>
        <input matInput formControlName="note">
      </mat-form-field>

      <button mat-icon-button *ngIf="isEditing" color="primary" (click)="save()" matTooltip="Speichern" matTooltipPosition="before">
        <mdi-icon icon="content-save"></mdi-icon>
      </button>
      <button mat-icon-button *ngIf="!isEditing" color="primary" (click)="isEditing = !isEditing" matTooltip="Bearbeiten" matTooltipPosition="before">
        <mdi-icon icon="pencil"></mdi-icon>
      </button>

    </div>

  </div>

  <!-- Kontakt und Aufgaben -->
  <div class="section-header">
    <div fxFlex>Kontakt und Aufgaben</div>
  </div>

  <div class="section-content">

    <div class="row">
      <mat-form-field appearance="standard" class="flex-100">
        <mat-label>Kontaktname</mat-label>
        <input matInput [readonly]="true" [value]="getContactDisplayName()">
      </mat-form-field>

      <button mat-icon-button color="primary" (click)="openContactDialog()" matTooltip="Kontakt Details" matTooltipPosition="before" *ngIf="call?.OtherPartyMainContact">
        <mdi-icon icon="card-account-details"></mdi-icon>
      </button>

    </div>

    <div class="row">
      <mat-form-field appearance="standard" class="flex-100" *ngIf="!isEditing || (isEditing && !call?.OtherPartyMainContact)">
        <mat-label>Adresse/Kunde</mat-label>
        <input matInput [readonly]="true" [value]="getAddressDisplay(call?.OtherPartyMainContact?.ErpCachedAddressId)">
      </mat-form-field>


      <mat-form-field appearance="standard" fxFlex="100" *ngIf="isEditing && call?.OtherPartyMainContact">
        <mat-label>Adresse/Kunde bearbeiten</mat-label>
        <mat-select formControlName="addressId">
          <mat-option>
            <ngx-mat-select-search formControlName="addressFilter"></ngx-mat-select-search>
          </mat-option>
          <mat-option [value]="null">(keine)</mat-option>
          <mat-option *ngFor="let x of filteredAddresses$ | async" [value]="x.ErpCachedAddressId">{{x.CachedDisplayName}}</mat-option>
        </mat-select>
      </mat-form-field>


    </div>

    <div class="row">

      <div class="flex-100 content-container">
        <div *ngIf="!call?.Task">
          Dieser Anruf ist keiner Aufgabe zugeordnet.
          <button mat-mini-fab color="primary" (click)="openAddToTaskPanel()" matTooltip="Anruf einer Aufgabe zuweisen" matTooltipPosition="before">
            <mdi-icon icon="playlist-check"></mdi-icon>
          </button>
        </div>
        <div *ngIf="call?.Task">
          <div>Zugewiesene Aufgabe:</div>
          <a class="pointer" (click)="openTaskEditDialog()">{{call?.Task?.Title}}</a>
        </div>
      </div>

    </div>



  </div>

  <!-- Flowchart -->
  <div class="section-header">
    <div fxFlex>Flowchart</div>
  </div>

  <div class="section-content">

    <div class="row-spacebetween">

      <div class="flex-20 flex-center">
        <b>{{ call?.CleanPartList[0]?.EntryStart | date:'HH:mm:ss' }}</b>
      </div>

      <div class="flex-40 flex-center">
        <button mat-mini-fab color="primary" class="flow-chart-fab" *ngIf="call?.CleanPartList[0]?.ConnectionType === 0" matTooltip="Eingehend" matTooltipPosition="before">
          <mdi-icon icon="call-received"></mdi-icon>
        </button>
        <button mat-mini-fab color="primary" class="flow-chart-fab" *ngIf="call?.CleanPartList[0]?.ConnectionType === 1" matTooltip="Ausgehend" matTooltipPosition="before">
          <mdi-icon icon="call-made"></mdi-icon>
        </button>
        <button mat-mini-fab color="primary" class="flow-chart-fab" *ngIf="call?.CleanPartList[0]?.ConnectionType === 2" matTooltip="Intern" matTooltipPosition="before">
          <mdi-icon icon="call-merge"></mdi-icon>
        </button>
      </div>

      <div class="flex-40 flex-center"></div>
    </div>



    <div *ngFor="let part of call?.CleanPartList">

      <div class="row-center">
        <div class="divider"></div>
      </div>

      <div class="row-spacebetween">

        <div class="flex-20 flex-center">
          <b>{{part.DurationMilliseconds| duration:'ms':false:true }}</b>
        </div>

        <div class="flex-40 flex-center">

          <button mat-mini-fab class="flow-chart-fab" *ngIf="part.QueueId !== null" matTooltip="In Warteschlange" matTooltipPosition="before">
            <mdi-icon icon="ear-hearing"></mdi-icon>
          </button>

          <button mat-mini-fab class="flow-chart-fab" *ngIf="part.QueueId === null && (part.ConnectionStatus === 1 || part.ConnectionStatus === 2)" matTooltip="Läuten" matTooltipPosition="before">
            <mdi-icon icon="bell-ring"></mdi-icon>
          </button>

          <button mat-mini-fab class="flow-chart-fab" *ngIf="part.QueueId === null && part.ConnectionStatus === 3" matTooltip="Im Gespräch" color="primary" matTooltipPosition="before">
            <mdi-icon icon="phone"></mdi-icon>
          </button>

          <button mat-mini-fab class="flow-chart-fab" *ngIf="part.QueueId === null && (part.ConnectionStatus === 4 || part.ConnectionStatus === 5)" matTooltip="Anruf gehalten" color="primary"
            matTooltipPosition="before">
            <mdi-icon icon="pause-circle-outline"></mdi-icon>
          </button>

        </div>

        <div class="flex-40 flex-center column">
          <div class="flex-100 flex-center">{{ part?.Extension?.Extension ?? part?.Queue?.Extension }}</div>

          <div class="flex-100 flex-center">
            <span *ngIf="part?.Extension?.FirstName"> {{part?.Extension?.FirstName}} {{part?.Extension?.LastName}}</span>
            <span *ngIf="part?.Queue?.Name">{{part?.Queue?.Name}}</span>
          </div>

        </div>

      </div>
    </div>

    <div class="row-center">
      <div class="divider"></div>
    </div>

    <div class="row-spacebetween">

      <div class="flex-20 flex-center">
        <b *ngIf="call?.CleanPartList[call.CleanPartList.length-1]?.EntryEnd">{{call?.CleanPartList[call.CleanPartList.length-1]?.EntryEnd | date:'HH:mm:ss' }}</b>
        <b *ngIf="!call?.CleanPartList[call.CleanPartList.length-1]?.EntryEnd">jetzt</b>
      </div>

      <div class="flex-40 flex-center">
        <button mat-mini-fab color="warn" class="flow-chart-fab" matTooltip="Anruf beendet" matTooltipPosition="before">
          <mdi-icon icon="phone-hangup"></mdi-icon>
        </button>

      </div>

      <div class="flex-40 flex-center"></div>
    </div>

  </div>


  <!-- Anrufe -->
  <div class="section-header">
    <div fxFlex>Anrufe von {{call?.OtherPartyNumber}}</div>
  </div>

  <div class="section-content">

    <div *ngFor="let vm of callGroupVms">
      <div class="row label">
        {{vm.label}}
      </div>

      <div class="row-center " *ngFor="let call of vm.calls">
        <div class="flex-5">
          <mdi-icon *ngIf="call.ConnectionType === 0 && call.IsAnswered" icon="call-received" class="icon-primary" matTooltip="Anruf eingegangen" matTooltipPosition="right"></mdi-icon>
          <mdi-icon *ngIf="call.ConnectionType === 0 && !call.IsAnswered" icon="call-missed" class="icon-warn" matTooltip="Anruf verpasst" matTooltipPosition="right"></mdi-icon>
          <mdi-icon *ngIf="call.ConnectionType === 1 && call.IsAnswered" icon="call-made" class="icon-primary" matTooltip="Anruf ausgegangen" matTooltipPosition="right"></mdi-icon>
          <!--Icon noch nicht ganz richtig im original wurde call_missed_outgoing verwendet das gibt es aber bei mdi-icon nicht -->
          <mdi-icon *ngIf="call.ConnectionType === 1 && !call.IsAnswered" icon="call-missed" class="icon-warn" matTooltip="Ausgehender Anruf verpasst" matTooltipPosition="right"></mdi-icon>
          <mdi-icon *ngIf="call.ConnectionType === 2 && call.IsAnswered" icon="call-merge" class="icon-primary" matTooltip="Interner Anruf eingegangen" matTooltipPosition="right"></mdi-icon>
          <mdi-icon *ngIf="call.ConnectionType === 2 && !call.IsAnswered" icon="call-merge" class="icon-warn" matTooltip="Interner Anruf verpasst" matTooltipPosition="right"></mdi-icon>
        </div>

        <div class="flex-30">
          {{call.StartDateTime | date:'HH:mm' }}
        </div>


        <div class="flex-35">
          {{(call.EndDateTime.getTime() - call.StartDateTime.getTime()) | duration }}
        </div>

        <div class="flex-30">
          <span *ngIf="call.Extension" [matTooltip]="call.Extension.FirstName + ' ' + call.Extension?.LastName" matTooltipPosition="before">{{call.Extension.Extension}}</span>
          <span *ngIf="call.QueueId" [matTooltip]="call.Queue.Name" matTooltipPosition="before">{{call.Queue.Extension}}</span>
        </div>

      </div>


    </div>


  </div>


</form>

<app-loading-overlay [isLoading]="isLoading" overlay="light"></app-loading-overlay>
