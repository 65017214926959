import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, InjectionToken, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { map, Observable, Subscription, take } from 'rxjs';

// Teile dieses Codes stammen von https://github.com/angular/components/blob/14.2.3/src/material/icon/icon.ts

/** Mit diesem InjectionToken wird der Basispfad festgelet, wo die .svg Icons liegen. */
export const MDI_ICON_BASE_URL = new InjectionToken<string>('MDI_ICON_BASE_URL');

/**
 * Mit dieser Komponente können die Icons von https://materialdesignicons.com/ verwendet werden,
 * z.B. `<mdi-icon icon="bell"></mdi-icon>`
 */
@Component({
  selector: 'mdi-icon',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./mdi-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    'class': 'mdi-icon',
    '[class.mdi-icon-no-color]': 'color !== "primary" && color !== "accent" && color !== "warn"',
  }
})
export class MdiIconComponent implements OnDestroy {

  get icon() {
    return this._icon;
  }

  @Input() set icon(value: string) {
    if (this._icon !== value) {
      if (value) {
        this.updateIcon(value);
      }
      else {
        this.clearSvgElement();
      }
      this._icon = value;
    }
  }

  private _icon: string = "";
  private currentFetch = Subscription.EMPTY;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly http: HttpClient,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(MDI_ICON_BASE_URL) private readonly baseUrl: string) { }

  ngOnDestroy() {
    this.currentFetch.unsubscribe();
  }

  private clearSvgElement() {
    const element: HTMLElement = this.elementRef.nativeElement;
    let childCount = element.childNodes.length;

    // Remove existing non-element child nodes and SVGs, and add the new SVG element. Note that
    // we can't use innerHTML, because IE will throw if the element has a data binding.
    while (childCount--) {
      const child = element.childNodes[childCount];

      // 1 corresponds to Node.ELEMENT_NODE. We remove all non-element nodes in order to get rid
      // of any loose text nodes, as well as any SVG elements in order to remove any old icons.
      if (child.nodeType !== 1 || child.nodeName.toLowerCase() === 'svg') {
        child.remove();
      }
    }
  }

  private updateIcon(icon: string) {
    this.currentFetch.unsubscribe();

    this.currentFetch = this.fetchIcon(icon)
      .pipe(take(1))
      .subscribe({
        next: svg => this.setSvgElement(svg),
        error: (err: Error) => console.log(`Error retrieving icon ${icon}! ${err.message}`)
      });
  }

  private fetchIcon(icon: string): Observable<SVGElement> {
    const url = this.baseUrl + '/' + icon + ".svg";
    return this.http.get(url, { responseType: 'text' }).pipe(
      map(str => { return this.svgElementFromString(str); })
    );
  }

  private svgElementFromString(str: string): SVGElement {
    const div = this.document.createElement('DIV');
    div.innerHTML = str;
    const svg = div.querySelector('svg') as SVGElement;

    if (!svg) {
      throw Error('<svg> tag not found');
    }
    return svg;
  }

  private setSvgElement(svg: SVGElement) {
    this.clearSvgElement();
    (this.elementRef.nativeElement as HTMLElement).appendChild(svg);
  }
}
