import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorService {

  constructor() { }

  /** Gibt für eine Hintergrundfarbe zurück, ob der Text darauf besser in schwarz oder weiß angezeigt werden soll. */
  getOptimalTextColor(backgroundColor: string): string {
    if (!backgroundColor) {
      return backgroundColor;
    }

    // Intensität der Farbe bestimmen und bei >150 schwarz verwenden.
    // https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
    const parsed = this.parseColor(backgroundColor);
    const red = parsed[0];
    const green = parsed[1];
    const blue = parsed[2];
    const intensity = red * 0.299 + green * 0.587 + blue * 0.114;

    if (intensity > 180) {
      return '#000000';
    }
    else {
      return '#ffffff';
    }
  }

  private parseColor(color: string): number[] {
    // https://stackoverflow.com/questions/11068240/what-is-the-most-efficient-way-to-parse-a-css-color-in-javascript
    const match6 = color.match(/^#([0-9a-f]{6})$/i);
    if (match6) {
      return [
        parseInt(match6[1].substring(0, 2), 16),
        parseInt(match6[1].substring(2, 4), 16),
        parseInt(match6[1].substring(4, 6), 16)
      ];
    }

    const match3 = color.match(/^#([0-9a-f]{3})$/i);
    if (match3) {
      // mit 0x11 multipliziert ergibt das den richtigen Wert
      return [
        parseInt(match3[1].charAt(0), 16) * 0x11,
        parseInt(match3[1].charAt(1), 16) * 0x11,
        parseInt(match3[1].charAt(2), 16) * 0x11
      ];
    }

    throw 'Could not parse color "' + color + '".';
  }

}
