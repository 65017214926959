import * as angular from "angular";
import { material } from "angular";
import { addDefaultScopeFunctions } from "../Functions/add-default-scope-functions";
import { ConfigurationDto } from "../Models/Phone3cx/ConfigurationDto";
import { ThemeDto } from "../Models/ThemeDto";
import { ApiHandlerService } from "../Services/ApiHandlerService";

export class Phone3CXAdminDialogController {

  static $inject = ['$scope', '$mdDialog', 'ApiHandlerService', 'theme'];

  constructor(
    private readonly $scope: IPhone3CXAdminDialogControllerScope,
    private readonly $mdDialog: material.IDialogService,
    private readonly ApiHandlerService: ApiHandlerService,
    private readonly theme: ThemeDto
  ) {
    addDefaultScopeFunctions($scope, theme);
    $scope.IsLoading = true;
    $scope.Model = {
      Parameter1: undefined,
      SyncCallHistory: undefined
    };
    ApiHandlerService.SendRequest("Phone3cx", "GetConfiguration").then(data => {
      this.$scope.Model = data;
    });
    $scope.SaveData = this.SaveData.bind(this);
    $scope.Cancel = this.Cancel.bind(this);
  }

  SaveData() {
    this.ApiHandlerService.SendRequest("Phone3cx", "SetConfiguration", this.$scope.Model).then(data => {
      this.$scope.Model = data;
      this.Cancel();
    });
  }

  Cancel() {
    this.$mdDialog.hide();
  }
}

interface IPhone3CXAdminDialogControllerScope extends angular.IScope {
  IsLoading: boolean;
  Model: ConfigurationDto;

  SaveData: () => void;
  Cancel: () => void;
}
