<div class="dialog-container">
  <div mat-dialog-title class="dialog-title"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle>

    <div>
      Sage Export Warteschlange
    </div>

    <button mat-icon-button (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>

  </div>

  <mat-dialog-content class="mat-typography">

    <mat-tab-group [(selectedIndex)]="selectedTab">
      <mat-tab label="Neu">
        <div *ngIf="!sageExportContractQueue || sageExportContractQueue.sageExportContractGroups?.length <= 0">
          Keine Einträge
        </div>

        <mat-accordion>
          <mat-expansion-panel hideToggle *ngFor="let group of sageExportContractQueue?.sageExportContractGroups">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ group.erpCachedAddress.cachedDisplayName }}
              </mat-panel-title>
              <mat-panel-description>

                <div class="radio-container">
                  <mat-radio-group *ngIf="sageExportContractTypes.length && group.contractTypeControl" (click)="$event.stopPropagation()" [formControl]="group.contractTypeControl"
                    (change)="updateSageContractTypes()">
                    <mat-radio-button *ngFor="let type of sageExportContractTypes" [value]="type?.id" [matTooltip]="type?.tooltip">{{type?.description}}</mat-radio-button>
                  </mat-radio-group>
                </div>

              </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-accordion>
              <mat-expansion-panel hideToggle *ngFor="let exportContract of group.sageExportContracts">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{"#" + exportContract.contract.contractId + " | " + exportContract.contract.title + " | " + exportContract.contract.type?.description }}
                  </mat-panel-title>
                  <mat-panel-description>
                    <div class="panel-description-container">

                      <div>
                        {{"Gültig von " + (exportContract.validFrom | date) + " bis " + (exportContract.validUntil | date) + " | "}}
                        {{"Verechnet von " + (exportContract.billedFrom | date) + " bis " + (exportContract.billedUntil | date) }}
                      </div>

                      <button mat-icon-button color="warn" (click)="$event.stopPropagation()" (click)="deleteExportContract(exportContract.id)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <mat-divider></mat-divider>

                <div *ngFor="let exportProduct of exportContract.sageExportContractProducts" class="product-container">
                  <div *ngIf="exportProduct.positionsTyp === 1 ">
                    <span>{{" | " + exportProduct.articleNumber }} </span>
                    <span *ngIf="exportProduct.description1">{{" | " + exportProduct.description1 }}</span>
                    <span *ngIf="exportProduct.description2">{{" | " + exportProduct.description2 }}</span>
                    <span>{{" | Anzahl: " + exportProduct.amount }}</span>
                    <span>{{" | Preis: € " + exportProduct.price}}</span>
                  </div>

                  <div *ngIf="exportProduct.positionsTyp === 3">
                    <span>{{exportProduct.langtext}}</span>
                  </div>

                </div>

              </mat-expansion-panel>
            </mat-accordion>

          </mat-expansion-panel>

        </mat-accordion>

      </mat-tab>

      <mat-tab label="Offen">

        <mat-accordion>

          <mat-expansion-panel hideToggle *ngFor="let group of sageExportContractQueueHistory?.openGroups">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ group.erpCachedAddress.cachedDisplayName }}
              </mat-panel-title>
              <mat-panel-description>
                <div class="panel-description-container">
                  <div>
                    Export Datum: {{group.sageExportContractQueue?.exportDate | date}}
                  </div>
                  <div>
                    <mat-icon *ngIf="group.hasErrors" color="warn" matTooltip="Import Fehler" (click)="$event.stopPropagation()" (click)="openErrorDialog(group)">warning</mat-icon>
                  </div>

                  <div>
                    <button mat-raised-button color="primary" (click)="completeSageExportContractGroup(group)" (click)="$event.stopPropagation()">
                      Abschließen
                    </button>
                  </div>

                  <div class="status-container">
                    <mat-icon matTooltip="Importiert" [ngClass]="{'red-icon': group?.auftragStatus !== 1, 'green-icon': group?.auftragStatus === 1 }">login</mat-icon>
                    <mat-icon matTooltip="Lieferschein" [ngClass]="{'red-icon': group?.lieferStatus !== 1, 'green-icon': group?.lieferStatus === 1 }">local_shipping</mat-icon>
                    <mat-icon matTooltip="Rechnung" [ngClass]="{'red-icon': group?.rechnungsStatus !== 1, 'green-icon': group?.rechnungsStatus === 1 }">euro</mat-icon>
                  </div>
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>


            <mat-accordion>
              <mat-expansion-panel hideToggle *ngFor=" let exportContract of group.sageExportContracts">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{"#" + exportContract.contract.contractId + " | " + exportContract.contract.title + " | " + exportContract.contract.type?.description }}
                  </mat-panel-title>
                  <mat-panel-description>
                    <div class="panel-description-container">

                      <div>
                        {{"Gültig von " + (exportContract.validFrom | date) + " bis " + (exportContract.validUntil | date) + " | "}}
                        {{"Verechnet von " + (exportContract.billedFrom | date) + " bis " + (exportContract.billedUntil | date) }}
                      </div>


                      <div>
                        <mat-icon *ngIf="contractComplete(exportContract)" class="green-icon">check_circle</mat-icon>
                      </div>

                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <mat-divider></mat-divider>

                <div *ngFor="let exportProduct of exportContract.sageExportContractProducts" class="product-container">
                  <div class="product-info-containter" *ngIf="exportProduct.positionsTyp === 1 ">
                    <span>{{" | " + exportProduct.articleNumber }} </span>
                    <span *ngIf="exportProduct.description1">{{" | " + exportProduct.description1 }}</span>
                    <span *ngIf="exportProduct.description2">{{" | " + exportProduct.description2 }}</span>
                    <span>{{" | Anzahl: " + exportProduct.amount }}</span>
                    <span>{{" | Preis: € " + exportProduct.price}}</span>
                    <span *ngIf="exportProduct.belegNummer">{{" | Belegnummer: " + exportProduct.belegNummer }}</span>
                  </div>

                  <div class="product-info-containter" *ngIf="exportProduct.positionsTyp === 3">
                    <span>{{exportProduct.langtext}}</span>
                  </div>


                  <div class="product-delete-container">
                    <button mat-icon-button color="warn" matTooltip="Position löschen" (click)="deleteSageExportContractProduct(exportProduct)">
                      <mat-icon>
                        delete
                      </mat-icon>
                    </button>
                  </div>

                  <div class="status-container">
                    <mat-icon matTooltip="Importiert" [ngClass]="{'red-icon': exportProduct?.auftragStatus !== 1, 'green-icon': exportProduct?.auftragStatus === 1 }">login</mat-icon>
                    <mat-icon matTooltip="Lieferschein" [ngClass]="{'red-icon': exportProduct?.lieferStatus !== 1, 'green-icon': exportProduct?.lieferStatus === 1 }">local_shipping</mat-icon>
                    <mat-icon matTooltip="Rechnung" [ngClass]="{'red-icon': exportProduct?.rechnungsStatus !== 1, 'green-icon': exportProduct?.rechnungsStatus === 1 }">euro</mat-icon>
                  </div>
                </div>
              </mat-expansion-panel>

            </mat-accordion>

          </mat-expansion-panel>
        </mat-accordion>

      </mat-tab>

      <mat-tab label="Abgeschlossen">

        <mat-accordion>

          <mat-expansion-panel hideToggle *ngFor="let group of sageExportContractQueueHistory?.completedGroups">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ group.erpCachedAddress.cachedDisplayName }}
              </mat-panel-title>
              <mat-panel-description>

                <div class="panel-description-container">

                  <div>
                    Export Datum: {{group.sageExportContractQueue?.exportDate | date}}
                  </div>

                  <div>
                    <button mat-raised-button color="primary" (click)="archiveSageExportContractGroup(group)" (click)="$event.stopPropagation()">
                      Archivieren
                    </button>
                  </div>
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-accordion>
              <mat-expansion-panel hideToggle *ngFor="let exportContract of group.sageExportContracts">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{"#" + exportContract.contract.contractId + " | " + exportContract.contract.title + " | " + exportContract.contract.type?.description }}
                  </mat-panel-title>
                  <mat-panel-description>
                    <div class="panel-description-container">

                      <div>
                        {{"Gültig von " + (exportContract.validFrom | date) + " bis " + (exportContract.validUntil | date) + " | "}}
                        {{"Verechnet von " + (exportContract.billedFrom | date) + " bis " + (exportContract.billedUntil | date) }}
                      </div>

                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" [formControl]="exportContract.isSelected"></mat-checkbox>
                      </div>

                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <mat-divider></mat-divider>

                <div *ngFor="let exportProduct of exportContract.sageExportContractProducts" class="product-container">

                  <div *ngIf="exportProduct.positionsTyp === 1">
                    <span>{{ " | " + exportProduct.articleNumber }} </span>
                    <span *ngIf="exportProduct.description1">{{" | " + exportProduct.description1 }}</span>
                    <span *ngIf="exportProduct.description2">{{" | " + exportProduct.description2 }}</span>
                    <span>{{" | Anzahl: " + exportProduct.amount }}</span>
                    <span>{{" | Preis: € " + exportProduct.price}}</span>
                    <span *ngIf="exportProduct.belegNummer">{{" | Belegnummer: " + exportProduct.belegNummer }}</span>
                  </div>

                  <div *ngIf="exportProduct.positionsTyp === 3">
                    <span>{{exportProduct.langtext}}</span>
                  </div>


                  <div></div>
                </div>

              </mat-expansion-panel>

            </mat-accordion>

          </mat-expansion-panel>
        </mat-accordion>


      </mat-tab>

      <mat-tab label="Archiviert">

        <mat-accordion>

          <mat-expansion-panel hideToggle *ngFor="let group of sageExportContractQueueHistory?.archivedGroups">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ group.erpCachedAddress.cachedDisplayName }}
              </mat-panel-title>
              <mat-panel-description>

                <div class="panel-description-container">

                  <div>
                    Export Datum: {{group.sageExportContractQueue?.exportDate | date}}
                  </div>

                  <div>
                  </div>
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-accordion>
              <mat-expansion-panel hideToggle *ngFor="let exportContract of group.sageExportContracts">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{"#" + exportContract.contract.contractId + " | " + exportContract.contract.title + " | " + exportContract.contract.type?.description }}
                  </mat-panel-title>
                  <mat-panel-description>
                    <div class="panel-description-container">

                      <div>
                        {{"Gültig von " + (exportContract.validFrom | date) + " bis " + (exportContract.validUntil | date) + " | "}}
                        {{"Verechnet von " + (exportContract.billedFrom | date) + " bis " + (exportContract.billedUntil | date) }}
                      </div>

                      <div>
                      </div>

                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <mat-divider></mat-divider>

                <div *ngFor="let exportProduct of exportContract.sageExportContractProducts" class="product-container">

                  <div *ngIf="exportProduct.positionsTyp === 1">
                    <span>{{ " | " + exportProduct.articleNumber }} </span>
                    <span *ngIf="exportProduct.description1">{{" | " + exportProduct.description1 }}</span>
                    <span *ngIf="exportProduct.description2">{{" | " + exportProduct.description2 }}</span>
                    <span>{{" | Anzahl: " + exportProduct.amount }}</span>
                    <span>{{" | Preis: € " + exportProduct.price}}</span>
                    <span *ngIf="exportProduct.belegNummer">{{" | Belegnummer: " + exportProduct.belegNummer }}</span>
                  </div>

                  <div *ngIf="exportProduct.positionsTyp === 3">
                    <span>{{exportProduct.langtext}}</span>
                  </div>


                  <div></div>
                </div>

              </mat-expansion-panel>

            </mat-accordion>

          </mat-expansion-panel>
        </mat-accordion>


      </mat-tab>

    </mat-tab-group>

  </mat-dialog-content>

  <mat-divider></mat-divider>

  <mat-dialog-actions>

    <div class="footer-container">

      <div>
      </div>

      <div>
        <button mat-raised-button color="primary" *ngIf="selectedTab === 0" [disabled]="!sageExportContractQueue?.sageExportContractGroups?.length" (click)="exportToSage()">Exportieren</button>
        <button mat-raised-button color="primary" *ngIf="selectedTab === 2" [disabled]="!historyAnySelected()" (click)="updateContractDates()">Datum weiter schreiben</button>
      </div>

    </div>

  </mat-dialog-actions>

</div>