<h2 mat-dialog-title *ngIf="data.title">{{data.title}}</h2>
<mat-dialog-content class="mat-typography preserve-line-breaks">{{data.content}}</mat-dialog-content>
<mat-dialog-actions align="end">

  <button type="button" mat-stroked-button *ngIf="data.action3 && !data.action3Color" [color]="data.action3Color" (click)="action(3)">{{data.action3}}</button>
  <button type="button" mat-raised-button *ngIf="data.action3 && data.action3Color" [color]="data.action3Color" (click)="action(3)">{{data.action3}}</button>

  <button type="button" mat-stroked-button *ngIf="data.action2 && !data.action2Color" [color]="data.action2Color" (click)="action(2)">{{data.action2}}</button>
  <button type="button" mat-raised-button *ngIf="data.action2 && data.action2Color" [color]="data.action2Color" (click)="action(2)">{{data.action2}}</button>

  <button type="button" mat-stroked-button *ngIf="data.action1 && !data.action1Color" [color]="data.action1Color" (click)="action(1)">{{data.action1}}</button>
  <button type="button" mat-raised-button *ngIf="data.action1 && data.action1Color" [color]="data.action1Color" (click)="action(1)">{{data.action1}}</button>
</mat-dialog-actions>
