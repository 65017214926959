import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { FormatDto } from "src/ajs/Models/Label/FormatDto";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { MandatorService } from "src/app/core/services/mandator.service";

createLabelCreateFormatController.$inject = ['$scope', '$mdDialog', 'ApiHandlerService', 'Format', 'OnSuccess', 'theme', 'MandatorServiceNgx'];

export function createLabelCreateFormatController(
  $scope,
  $mdDialog,
  ApiHandlerService: ApiHandlerService,
  Format: FormatDto,
  OnSuccess: () => void,
  theme: ThemeDto,
  mandatorServiceNgx: MandatorService
) {

  addDefaultScopeFunctions($scope, theme);
  $scope.Format = Format;
  $scope.IsNew = !Format;
  if ($scope.IsNew) {
    $scope.Format = {
      Width: null,
      Height: null
    };
  }
  $scope.OnSuccess = OnSuccess;

  $scope.Hide = function () {
    $mdDialog.hide();
  };
  $scope.Cancel = function () {
    $mdDialog.cancel();
  };
  $scope.Ok = function () {
    ApiHandlerService.SendRequest("Label", "CreateOrEditFormat", { format: $scope.Format, mandatorId: mandatorServiceNgx.selectedMandatorId }).then(function (data) {
      if ($scope.OnSuccess) {
        $scope.OnSuccess();
      }
      $mdDialog.hide();
    });
  };
}
