<mat-card class="mat-typography">
  <mat-card-title-group>
    <mat-card-title>{{ getUserDisplayName() }}</mat-card-title>
    <mat-card-subtitle>{{user.department}}</mat-card-subtitle>
    <img mat-card-md-image [src]="userService.getProfilePicture(user)" />
  </mat-card-title-group>
  <mat-card-content>
    <div class="contact-line" *ngIf="user.businessPhone">
      <mat-icon>phone</mat-icon>
      <a href="{{user.businessPhone| tel}}">{{user.businessPhone}}</a>
    </div>
    <div class="contact-line" *ngIf="user.mobilePhone">
      <mat-icon>phone_android</mat-icon>
      <a href="{{user.mobilePhone| tel}}">{{user.mobilePhone}}</a>
    </div>
    <div class="contact-line" *ngIf="user.eMail">
      <mat-icon>mail</mat-icon>
      <a href="mailto:{{user.eMail}}">{{user.eMail}}</a>
    </div>
  </mat-card-content>
</mat-card>
