import { fixDate } from "src/ajs/Utils/DateUtils";

export interface Phone3CxQueueDto {
  Id: number;
  EnvironmentId: string;
  Extension: string;
  Name: string;
  LastUpdate: string;
  IsDeactivated: Date;
  ValidFromUtc: Date;
  ValidUntilUtc: Date;
}


export function fixPhone3CxQueueDto(dto: Phone3CxQueueDto) {
  if (dto) {
    if (dto.LastUpdate) {
      fixDate(dto.LastUpdate);
    }
    if (dto.ValidFromUtc) {
      fixDate(dto.ValidFromUtc);
    }
    if (dto.ValidUntilUtc) {
      fixDate(dto.ValidUntilUtc);
    }
  }
}
