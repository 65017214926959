import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { TaskNoteDto } from "../../dto/task-note-dto";

export class TaskNoteVm {
  note: TaskNoteDto;
  formGroup: UntypedFormGroup;

  constructor(
    formbuilder: UntypedFormBuilder,
    noteDto: TaskNoteDto
  ) {
    this.formGroup = formbuilder.group({
      text: [null]
    });

    this.note = noteDto;
    this.loadFromDto();

  }

  loadFromDto() {
    this.formGroup.patchValue({
      text: this.note.Text
    });
  }

}
