import angular, { ITimeoutService } from "angular";
import moment from "moment";
import { ColumnDefinition, HestiaDataTable } from "src/ajs/Directives/HestiaDataTableDirective";
import { FolderDto } from "src/ajs/Models/StoredFiles/FolderDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { StorageService } from "src/ajs/Services/StorageService";
import { cloneObject, convertDate, formatDate, formatDateShort } from "src/ajs/Utils/HestiaCommon";
import { DocumentMetaDataDto } from "../../Models/Erp/DocumentMetaDataDto";
import { DocumentsResultDto } from "../../Models/Erp/DocumentsResultDto";
import { MandatorService } from "src/app/core/services/mandator.service";

export class DocumentEnvironment {

  FlatFolders: FolderDto[];
  CurrentAddressId: number;
  SelectedFileDetails: DocumentMetaDataDto;
  IsLoading: boolean;
  FolderModel: FolderDto[];
  FileMetaData: DocumentMetaDataDto[];
  SelectedFolder: FolderDto;
  Loaded: boolean;
  FolderTable: HestiaDataTable;
  SelectedFiles: DocumentMetaDataDto[];

  constructor(
    addressId: number,
    private readonly $timeout: ITimeoutService,
    StorageService: StorageService,
    private readonly ApiHandlerService: ApiHandlerService,
    private readonly $mdDialog: angular.material.IDialogService,
    private readonly mandatorService: MandatorService,
  ) {
    this.CurrentAddressId = addressId;

    this.FolderTable = new HestiaDataTable(StorageService.CreateInstance("FolderTable"), $timeout, this);
    this.FolderTable.ItemClicked = this.ItemDetailsSelected.bind(this);
    this.FolderTable.Options.Select = false;
    this.FolderTable.UpdateColumns([
      new ColumnDefinition({ PropertyPath: 'Extension', Heading: 'Typ', ColumnWidth: 50, Resizeable: false, Sortable: true, Template: "/ClientApp/src/ajs/Views/Erp/FileExtensionTemplate.htm" }),
      new ColumnDefinition({ PropertyPath: 'Title', Heading: 'Bezeichnung/Datei', Resizeable: true, Sortable: true }),
      new ColumnDefinition({ PropertyPath: 'Folder', Heading: 'Ordner', SortValueSelector: this.FolderSortValueSelector, RenderFunction: this.ConvertFolder, Resizeable: true, Sortable: true }),
      new ColumnDefinition({ PropertyPath: 'FileDate', Heading: 'Dateidatum', Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDateShort }),
      new ColumnDefinition({ PropertyPath: 'Created', IsVisible: false, Heading: 'Erstellt', Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDate }),
      new ColumnDefinition({ PropertyPath: 'CreatedUser', IsVisible: false, Heading: 'Ersteller', SortValueSelector: this.UserSortValueSelector, Resizeable: true, Sortable: true, RenderFunction: this.ConvertUser }),
      new ColumnDefinition({ PropertyPath: 'Modified', IsVisible: false, Heading: 'Bearbeitet', Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDate }),
      new ColumnDefinition({ PropertyPath: 'ModifiedUser', IsVisible: false, Heading: 'Bearbeiter', SortValueSelector: this.UserSortValueSelector, Resizeable: true, Sortable: true, RenderFunction: this.ConvertUser })
    ]);

    this.LoadData();

  }

  LinkFileFolders(folder: FolderDto) {
    if (folder.Depth === 1) {
      folder.DisplayName = folder.Name;
    }
    else if (folder.Depth > 1) {
      folder.DisplayName = folder.ParentFolder.DisplayName + '/' + folder.Name;
    } else {
      folder.DisplayName = "";
    }
    folder.Files.forEach((file) => {
      file.Folder = folder;
    });
    this.FlatFolders.push(folder);

    folder.Subfolders.forEach((subfolder) => {
      subfolder.ParentFolder = folder;
      this.LinkFileFolders(subfolder);
    });
  }

  OpenEditTextDialog($event: any) {
    this.showEditTextDialog(this.$mdDialog, $event, this.SelectedFileDetails.TextPreview, (newText) => {
      this.SelectedFileDetails.TextPreview = newText;
      this.SaveTextFile(this.SelectedFileDetails);
    });
  }

  showEditTextDialog($mdDialog: angular.material.IDialogService, $event: any, text: string, onSave: any) {
    $mdDialog.show({
      targetEvent: $event,
      controller: 'EditTextDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/Common/Dialogs/EditTextDialog.htm',
      locals: {
        CurrentText: text,
        OnSave: onSave
      },
      parent: angular.element(document.body)
    });
  }

  LoadData() {
    this.IsLoading = true;
    const request = this.ApiHandlerService.SendRequest("Erp", "GetDocuments", { addressId: this.CurrentAddressId }).then((data: DocumentsResultDto) => {
      this.$timeout(() => {
        this.FlatFolders = [];
        this.FolderModel = [data.AddressFolder];
        this.FileMetaData = data.MetaData;
        data.AddressFolder.IsOpen = true;

        this.LinkFileFolders(data.AddressFolder);
        // TODO - Should work diffrent on reload
        if (this.SelectedFolder) {
          this.SelectFolder(null, this.FlatFolders.filter(f => f.Id === this.SelectedFolder.Id)[0]);
        } else {
          this.SelectFolder(null, data.AddressFolder);
        }
        this.SelectedFileDetails = null;
        this.$timeout(() => {
          this.Loaded = true;
          this.FolderTable.Update();
          this.IsLoading = false;
        }, 50);
      });
    });
  }

  DeleteFile(fileMeta: DocumentMetaDataDto, $event: any) {
    const confirm = this.$mdDialog.confirm()
      .title('Wollen sie diesen Eintrag entfernen?')
      .textContent('Dieser Vorgang löscht den Eintrag mit allen Kindelementen.')
      .ariaLabel('Delete file')
      .targetEvent($event)
      .ok('Löschen!')
      .cancel('Mhh, nein!');

    this.$mdDialog.show(confirm).then(() => {
      this.ApiHandlerService.SendRequest("Erp", "DeleteFileMetadata", { id: fileMeta.MetaDataId }).then((data) => {
        this.LoadData();
      });
    }, function () { }.bind(this));
  }

  SaveMetaData(fileMeta: DocumentMetaDataDto) {
    const clone = cloneObject(fileMeta);
    clone.DestinationFolderId = fileMeta.Folder.Id;
    clone.Folder = null;
    if (fileMeta.IsNew && fileMeta.IsText) {
      clone.Data = btoa(unescape(encodeURIComponent(fileMeta.TextPreview)));
    } else {
      if (fileMeta.IsTextEdit) {
        this.SaveTextFile(fileMeta);
      }
    }
    this.ApiHandlerService.SendRequest("Erp", "SaveFileMetadata", { metaData: clone, mandatorId: this.mandatorService.selectedMandatorId }).then((data) => {
      this.LoadData();
    });
  }

  CreateTextDocument() {
    this.SelectedFileDetails = {
      Folder: this.SelectedFolder,
      MetaDataId: 0,
      StoredFileId: null,
      FolderId: null,
      Title: "Neues Textdokument",
      Note: null,
      Created: moment(),
      Modified: moment(),
      EditMeta: true,
      Extension: "txt",
      IsNew: true,
      IsText: true,
      FileDate: moment().startOf('day'),
      IsSingleFile: true,
      Data: "",
      HasPreview: true,
      TextPreview: "",
      AddressId: this.CurrentAddressId,
      FullFileName: "Neues Textdokument.txt"
    } as DocumentMetaDataDto;
    if (!this.SelectedFolder || this.SelectedFolder.Depth === 0) {
      this.SelectedFileDetails.Folder = null;
    }
  }

  FileDrop(location: string, files: any, folder: FolderDto) {
    const multidrop = files.length > 1;
    let selectedFolder = this.SelectedFolder;
    if (location === 'folder') {
      selectedFolder = folder;
    }
    if (selectedFolder.Depth === 0) {
      selectedFolder = null;
    }
    if ((location === 'root' || location === 'folder') && !multidrop) {
      const file = files[0];
      let extension = null;
      let fileName = file.name;
      let extensionWithDot = "";

      const split = file.name.split('.');
      if (split.length > 1) {
        extension = split.pop();
        extensionWithDot = "." + extension;
      }
      if (extension) {
        fileName = fileName.substring(0, fileName.lastIndexOf(extensionWithDot));
      }
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = () => {
        this.$timeout(() => {
          this.SelectedFileDetails = {
            Folder: selectedFolder,
            MetaDataId: 0,
            StoredFileId: null,
            FolderId: null,
            Title: fileName,
            Note: null,
            Created: moment(),
            Modified: moment(),
            EditMeta: true,
            Extension: extension,
            IsNew: true,
            IsSingleFile: true,
            FileDate: moment().startOf('day'),
            Data: btoa(reader.result as string),
            AddressId: this.CurrentAddressId,
            FullFileName: fileName + extensionWithDot
          } as DocumentMetaDataDto;
          this.SetFallbackExtension(this.SelectedFileDetails);
        });
      };
    } else {
      alert("File drop on: " + location + "\nMultidrop: " + multidrop + "\n\nOperation not implementet");
    }
  }

  SetFallbackExtension(fileMeta: DocumentMetaDataDto) {
    if (fileMeta.Extension && fileMeta.Extension.length > 3) {
      fileMeta.FallbackExtension = fileMeta.Extension.substring(0, 3);
    }
  }

  ItemDetailsSelected(fileMeta: DocumentMetaDataDto) {
    this.SelectedFileDetails = cloneObject(fileMeta);
    this.SelectedFileDetails.FileDate = moment(this.SelectedFileDetails.FileDate);
    this.SelectedFileDetails.HasPreview = fileMeta.Extension === "txt" || fileMeta.Extension === "pdf";
    if (fileMeta.Extension === "txt") {
      this.ApiHandlerService.SendRequest("FileDownload", "GetFileTextContent", { id: fileMeta.StoredFileId }).then((data) => {
        this.$timeout(() => {
          this.SelectedFileDetails.TextPreview = data.Result;
        });
      });
    }
  }

  SendFile(fileMeta: DocumentMetaDataDto, event: any) {
    this.$mdDialog.show({
      targetEvent: event,
      controller: 'MailSendDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/MailSend/MailSendDialog.htm',
      locals: {
        Parameters: {
          Subject: fileMeta.FullFileName,
          CachedAddressId: this.CurrentAddressId
        },
        OnSubmit: (result) => {
          this.ApiHandlerService.SendRequest("Erp", "SendDocumentAsMail", { document: fileMeta, mailMeta: result, mandatorId: this.mandatorService.selectedMandatorId }).then((data) => {
          });
        }
      },
      parent: angular.element(document.body)
    });
  }

  OpenFile(fileMeta: DocumentMetaDataDto) {
    return "/Api/FileDownload/Open/" + fileMeta.StoredFileId;
  }

  TitleChanged(fileMeta: DocumentMetaDataDto) {
    fileMeta.FullFileName = fileMeta.Title;
    if (fileMeta.Extension) {
      fileMeta.FullFileName += "." + fileMeta.Extension;
    }
  }

  PreviewFile(fileMeta: DocumentMetaDataDto) {
    const win = window.open();
    const getUrl = window.location;
    const base = getUrl.protocol + "//" + getUrl.host + "/";
    win.document.write('<!DOCTYPE html><html><head><link id="favicon" rel="shortcut icon" type="image/svg+xml" href="' + base + 'Content/icons/vivid/' + fileMeta.Extension + '.svg"/></head><body style="position: absolute; margin:0px; height:100%; width:100%; overflow: hidden;"><embed src="/Api/FileDownload/Open/' + fileMeta.StoredFileId + '" style="width:100%; height:100%; position:absolute;"></embed></body></html>');
    win.document['icon'] = "";
    win.document.title = fileMeta.Title;
    this.$timeout(() => {
      win.stop();
    }, 5000);
    win.focus();
  }

  ConvertUser(value: any) {
    return value.DisplayName;
  }

  ConvertFolder(value: any, model: any) {
    return value.Name;
  }

  SaveTextFile(fileMeta: DocumentMetaDataDto) {
    this.ApiHandlerService.SendRequest("FileDownload", "SetFileTextContent", { id: fileMeta.StoredFileId, text: fileMeta.TextPreview }).then(function (data) {
      fileMeta.IsTextEdit = false;
    });
  }

  FolderSortValueSelector(left, right) {
    return ('' + left.value.Name).localeCompare(right.value.Name);
  }

  UserSortValueSelector(left, right) {
    return ('' + left.value.DisplayName).localeCompare(right.value.DisplayName);
  }


  SearchTextChanged(searchText: string) {
    if (this.FolderModel) {
      this.SelectFolder(null, this.FolderModel[0], searchText);
    }
  }

  QueryFolders(searchText: string) {
    const cleanSearch = searchText.toLowerCase();
    return this.FlatFolders.filter(f => f.DisplayName.toLowerCase().includes(cleanSearch) && f.Depth > 0);
  }

  UnselectFolders(folder: FolderDto) {
    folder.IsSelected = false;
    folder.Subfolders.forEach((value) => {
      this.UnselectFolders(value);
    });
  }

  SelectFolder($event: any, folder: FolderDto, searchText?: string) {
    if (this.FolderModel) {
      this.FolderModel.forEach((value) => {
        this.UnselectFolders(value);
      });
    }
    folder.IsSelected = true;
    this.SelectedFolder = folder;
    if (!this.SelectedFiles) {
      this.SelectedFiles = [];
    }
    this.SelectedFiles.length = 0;
    let files = this.SelectedFolder.Files;
    let folders = this.SelectedFolder.Subfolders;
    if (folder === this.FolderModel[0]) {
      const allFiles = [];
      const allFolders = [];
      folder.Subfolders.forEach((value) => {
        allFiles.push(value.Files);
        allFolders.push(value.Subfolders);
      });
      files = [].concat.apply([], allFiles);
      folders = [].concat.apply([], allFolders);
    }
    files.forEach((value) => {
      let fileArray = this.FileMetaData.filter(f => f.StoredFileId === value.Id);

      if (fileArray && fileArray.length) {
        let file = fileArray[0];
        file.FullFileName = value.FullName;
        file.Folder = value.Folder;
        if (value.Extension) {
          file.Extension = value.Extension.toLowerCase();
          this.SetFallbackExtension(file);
        }
        if (searchText) {
          if (file.Title.toLowerCase().includes(searchText.toLowerCase()) ||
            file.Note && file.Note.toLowerCase().includes(searchText.toLowerCase())) {
            this.SelectedFiles.push(file);
          }
        }
        else {
          this.SelectedFiles.push(file);
        }
      }
    });
    folders.forEach((value) => {
      let fileArray = this.FileMetaData.filter(f => f.FolderId === value.Id);
      if (fileArray && fileArray.length) {
        let file = fileArray[0];
        file.Extension = "folder";
        file.Folder = value.Parent;
        this.SelectedFiles.push(file);
      }

    });
    this.FolderTable.UpdateSource(this.SelectedFiles, this.$timeout);
  }



}


