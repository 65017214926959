import angular from 'angular';
import moment from 'moment';
import { ActionDefinition, ActionGroup, ColumnDefinition, HestiaDataTable } from 'src/ajs/Directives/HestiaDataTableDirective';
import { addDefaultScopeFunctions } from 'src/ajs/Functions/add-default-scope-functions';
import { ThemeDto } from 'src/ajs/Models/ThemeDto';
import { ApiHandlerService } from 'src/ajs/Services/ApiHandlerService';
import { IPanelServiceEx } from 'src/ajs/Services/IPanelServiceEx';
import { LocalizationService } from 'src/ajs/Services/Localization/LocalizationService';
import { PhoneService } from 'src/ajs/Services/PhoneService';
import { PublicContactsService } from 'src/ajs/Services/PublicContactsService';
import { ScreenWidthService } from 'src/ajs/Services/ScreenWidthService';
import { SearchBarService } from 'src/ajs/Services/SearchBarService';
import { StorageService } from 'src/ajs/Services/StorageService';
import { MandatorService } from 'src/app/core/services/mandator.service';
import * as XLSX from 'xlsx';
import '../UserFlyout/UserFlyoutController';



PublicPhonebookController.$inject = ['$scope', '$mdToast', '$mdPanel', '$timeout', 'ApiHandlerService', 'StorageService', 'PhoneService', 'SearchBarService', 'ScreenWidthService', 'LocalizationService', 'MandatorServiceNgx', 'PublicContactsService', 'theme'];

export function PublicPhonebookController(
  $scope,
  $mdToast: angular.material.IToastService,
  $mdPanel: IPanelServiceEx,
  $timeout: angular.ITimeoutService,
  ApiHandlerService: ApiHandlerService,
  StorageService: StorageService,
  PhoneService: PhoneService,
  SearchBarService: SearchBarService,
  ScreenWidthService: ScreenWidthService,
  LocalizationService: LocalizationService,
  MandatorServiceNgx: MandatorService,
  PublicContactsService: PublicContactsService,
  theme: ThemeDto) {

  addDefaultScopeFunctions($scope, theme);
  SearchBarService.RequireSearchBar(true);
  $scope.IsLoading = true;
  $scope.SearchString = "";
  $scope.Localization = LocalizationService.GetPluginDict("PublicContacts");
  SearchBarService.SearchTextChangedEvent(function (text) {
    $scope.SearchString = text;
    $scope.UpdateFilter();
  });

  $scope.IsMobile = function () {
    return ScreenWidthService.IsMobile();
  };

  $scope.ToggleSync = function () {
    PublicContactsService.togglePhonebookSync().subscribe(function (result) {
      // Scheint so nicht zu funktionieren und unötig zu sein da IsExchangeSyncActive sowie so mit einem ng-model am toggle hängt
      //  $scope.Model.IsExchangeSyncActive = !!result;
    });
  };

  $scope.CreateCsvEntry = function (value) {
    var part = '';
    if (value) {
      part = '"=""' + value + '"""';
    }
    return part + ";";
  };

  $scope.DownloadCsc = function () {
    var filename = 'phonelist.csv';
    var data = [];
    data.push(['Nachname', 'Vorname', 'Firma', 'Abteilung', 'Position', 'E-Mail', 'Festnetz', 'Mobil', 'Fax']);
    var result = '';
    //result += 'Nachname;Vorname;Firma;Abteilung;Titel;E-Mail;Festnetz;Mobil;Fax;\n';
    $scope.Model.Entries.forEach(f => {
      var row = [];
      row.push(f.LastName);
      row.push(f.FirstName);
      row.push(f.Company);
      row.push(f.Department);
      row.push(f.Title);
      row.push(f.EMail);
      row.push(f.Phone);
      row.push(f.Mobile);
      row.push(f.Fax);
      data.push(row);
      /*result += $scope.CreateCsvEntry(f.LastName);
      result += $scope.CreateCsvEntry(f.FirstName);
      result += $scope.CreateCsvEntry(f.Company);
      result += $scope.CreateCsvEntry(f.Department);
      result += $scope.CreateCsvEntry(f.Title);
      result += $scope.CreateCsvEntry(f.EMail);
      result += $scope.CreateCsvEntry(f.Phone);
      result += $scope.CreateCsvEntry(f.Mobile);
      result += $scope.CreateCsvEntry(f.Fax);
      result += "\n";*/
    });
    //console.log(result);
    /*var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=ISO-8859-1,' + encodeURIComponent(result));
    element.setAttribute('download', 'phonelist.csv');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);*/
    var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, 'Telefonliste');
    XLSX.writeFile(wb, 'phonelist.xlsx');
  };

  $scope.AddAppointments = function (selectedEntries) {
    selectedEntries.forEach(user => {
      if (user.DateOfBirth) {
        var model = Object.create(user);
        Object.assign(model, user);
        model.Birthday = user.DateOfBirth;

        ApiHandlerService.SendRequest("PublicContacts", "AddBirthdayToExchange", { model: model }).then(function (data) {
          if (data.Success) {
            $mdToast.show(
              $mdToast.simple()
                .textContent($scope.Localization.Localize('BirthdayAdded', user.FirstName, user.LastName))
                .position('bottom right')
                .hideDelay(5000));
            user.IsImported = true;
          }
        });
      }
    });
  };

  $scope.RemoveAppointments = function (selectedEntries) {
    selectedEntries.forEach(user => {
      if (user.DateOfBirth) {
        var model = Object.create(user);
        Object.assign(model, user);
        model.Birthday = user.DateOfBirth;

        ApiHandlerService.SendRequest("PublicContacts", "RemoveBirthdayFromExchange", { model: user }).then(function (data) {
          if (data.Success) {
            $mdToast.show(
              $mdToast.simple()
                .textContent($scope.Localization.Localize('BirthdayRemoved', user.FirstName, user.LastName))
                .position('bottom right')
                .hideDelay(5000));
            user.IsImported = false;
          }
        });
      }
    });
  };

  $scope.LoadData = function () {
    $scope.IsLoading = true;
    $scope.UpcomingBirthdays = [];
    $scope.NextBirthday = null;
    $scope.FilterBirthday = false;

    $scope.ToggleBirthdayFiltering = function () {
      $scope.FilterBirthday = !$scope.FilterBirthday;
      $scope.UpdateFilter();
    };

    PublicContactsService.getPhonebookdata(MandatorServiceNgx.selectedMandatorId).subscribe(data => {
      $scope.Model = data;
      if ($scope.Model && $scope.Model.Entries) {
        $scope.Model.Entries.forEach(x => {

          if (x.DateOfBirth) {
            x.DateOfBirth = moment(x.DateOfBirth);
            x.NextBirthday = moment(x.NextBirthday);
            x.FormattedBirthday = moment(x.DateOfBirth).add(1, 'days').format("DD. MMMM");

            if (x.DaysUntilNextBirthday <= 7) {
              $scope.UpcomingBirthdays.push(x);
            }

            if (!$scope.NextBirthday) {
              $scope.NextBirthday = x;
            }

            if ($scope.NextBirthday.DaysUntilNextBirthday > x.DaysUntilNextBirthday) {
              $scope.NextBirthday = x;
            }
          }

          x.TableDisplayName = x.LastName + ", " + x.FirstName;
        });
      }

      $scope.Extensions = data.Extensions;

      $scope.EmplyeesTable.ClearActionGroups();

      if (data.IsAnonymized) {
        //$scope.EmplyeesTable.ColumnDefinitions = $scope.EmplyeesTable.ColumnDefinitions.filter(f => f !== $scope.BirthdayColumn);
      } else {
        //if (!$scope.EmplyeesTable.ColumnDefinitions.filter(f => f !== $scope.BirthdayColumn).length) {
        //    $scope.EmplyeesTable.ColumnDefinitions.splice(2, 0, $scope.BirthdayColumn);
        // }
        if (data.HasExchange) {
          $scope.EmplyeesTable.Options.Select = true;
          $scope.EmplyeesTable.AddActionGroup(new ActionGroup("L[TextButtonActions]", [
            new ActionDefinition("L[AddBirthday]", 'cake', null, $scope.AddAppointments),
            new ActionDefinition("L[RemoveBirthday]", 'delete', null, $scope.RemoveAppointments)
          ]));
        }
      }
      $scope.EmplyeesTable.UpdateColumns($scope.EmplyeesTable.ColumnDefinitions);
      $scope.UpdateFilter();
      $scope.UpdateContactStatesPhonebook();
      $scope.IsLoading = false;
    });
  };

  $scope.FormatBirthday = function (value, model) {
    if (value) {
      if ($scope.EmplyeesTable.IsPrint) {
        return value.format("DD.MM.");
      } else {
        var days = model.DaysUntilNextBirthday;
        switch (days) {
          case 0:
            return $scope.Localization.Localize("TextToday");
          case 1:
            return $scope.Localization.Localize("TextTommorow");
          default:
            return value.format("DD.MM.") + " (in " + days + " " + $scope.Localization.Localize("TextDays") + ")";
        }
      }
    }
    return null;
  };

  $scope.RenderBirthday = function (value) {
    if (value) {
      return moment(value);
    }
    return null;
  };

  $scope.EmplyeesTable = new HestiaDataTable(StorageService.CreateInstance("EmplyeesTable"), $timeout, $scope, $scope.Localization);
  $scope.EmplyeesTable.Options.Select = false;
  //$scope.EmplyeesTable.ItemClicked = $scope.HostContent.LoadHostNode;
  /*$scope.EmplyeesTable.AddActionGroup(new ActionGroup("L[TextButtonActions]", [
      new ActionDefinition("L[TextSystemquery]", 'autorenew', this.QuerySystem),
      new ActionDefinition("L[TextActivateMonitoring]", 'alarm_on', this.ActivateMonitoring),
      new ActionDefinition("L[TextDeactivateMonitoring]", 'alarm_off', this.DeactivateMonitoring)
  ]));*/
  $scope.BirthdayColumn = new ColumnDefinition({ PropertyPath: 'DateOfBirth', Heading: "L[LabelBirthday]", Align: "center", Formatter: $scope.FormatBirthday, RenderFunction: $scope.RenderBirthday, Resizeable: true, Sortable: true });

  $scope.EmplyeesTable.UpdateColumns([
    new ColumnDefinition({ PropertyPath: 'State', Heading: "Status", HideHeading: true, ColumnWidth: 44, Resizeable: false, Sortable: false, AllowFilter: false, NoPrint: true, CellClass: 'unset-padding', Template: "/ClientApp/src/ajs/Views/PublicContacts/TableTemplates/PhoneBookStateTemplate.htm" }),
    new ColumnDefinition({ PropertyPath: 'TableDisplayName', Heading: "L[LabelDisplayName]", Resizeable: true, Sortable: true }),
    $scope.BirthdayColumn,
    new ColumnDefinition({ PropertyPath: 'EMail', Heading: "L[LabelMail]", Resizeable: true, Sortable: true, Template: "/ClientApp/src/ajs/Views/PublicContacts/TableTemplates/PhoneBookEmailTemplate.htm" }),
    new ColumnDefinition({ PropertyPath: 'Company', Heading: "L[LabelCompany]", IsVisible: false, Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'Title', Heading: "L[LabelTitle]", IsVisible: false, Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'Branch', Heading: "L[LabelMandator]", Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'Department', Heading: "L[LabelDepartment]", Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'Phone', Heading: "L[LabelPhone]", Resizeable: true, Sortable: true, Template: "/ClientApp/src/ajs/Views/PublicContacts/TableTemplates/PhoneBookPhoneTemplate.htm" }),
    new ColumnDefinition({ PropertyPath: 'Mobile', Heading: "L[LabelMobile]", Resizeable: true, Sortable: true, Template: "/ClientApp/src/ajs/Views/PublicContacts/TableTemplates/PhoneBookMobileTemplate.htm" }),
    new ColumnDefinition({ PropertyPath: 'Fax', Heading: "L[LabelFax]", IsVisible: false, Resizeable: true, Sortable: true, Template: "/ClientApp/src/ajs/Views/PublicContacts/TableTemplates/PhoneBookFaxTemplate.htm" })
  ]);

  $scope.EmplyeesTable.ItemClicked = function (contact, $event) {

    const target = $event.srcElement || $event.target;
    const position = $mdPanel.newPanelPosition()
      .relativeTo(target)
      .addPanelPosition(
        $mdPanel.xPosition.OFFSET_END,
        $mdPanel.yPosition.ALIGN_TOPS
      );
    const config = {
      attachTo: angular.element(document.body),
      templateUrl: '/ClientApp/src/ajs/Views/PublicContacts/UserFlyout.htm',
      controller: 'UserFlyoutController',
      locals: {
        Model: contact
      },
      controllerAs: 'vm',
      position: position,
      panelClass: 'contact-panel-container',
      openFrom: $event,
      focusOnOpen: true,
      clickOutsideToClose: true,
      escapeToClose: true,
      zIndex: 4,
      propagateContainerEvents: true,
      groupName: ['contacts']
    };

    $mdPanel.open(config);
  };


  PhoneService.ExtensionsChangedEvent(function (extensions) {
    $scope.Extensions = extensions;
    $scope.UpdateContactStatesPhonebook();
    $scope.$apply();
  });

  $scope.UpdateContactStatesPhonebook = function () {
    if ($scope.Model && $scope.Model.Entries) {
      angular.forEach($scope.Model.Entries, function (contact) {
        $scope.UpdateStateForContact(contact);
      });
    }
  };

  $scope.UpdateStateForContact = function (contact) {
    contact.State = $scope.UpdatePhoneState(contact);
    switch (contact.State.Code) {
      case 1:
        contact.State.Class = "contact-state-free";
        break;
      case 2:
        contact.State.Class = "contact-state-busy";
        break;
      case 3:
        contact.State.Class = "contact-state-do-not-disturb";
        break;
      case 4:
        contact.State.Class = "contact-state-not-available";
        break;
      case 0:
      default:
        contact.State.Class = "contact-state-inactive";
        break;
    }
    contact.IsAvailable = contact.State.Code === 1;
  };

  $scope.UpdatePhoneState = function (contact) {
    var result = {
      Code: 0,
      Message: undefined
    };
    if ($scope.Extensions && $scope.Extensions.length) {
      var extensionLength = $scope.Extensions[0].Extension.length;
      if (contact.Phone) {
        var cleanPhone = contact.Phone.replace(/\s/g, '');
        if (cleanPhone.length >= extensionLength) {
          var extension = cleanPhone.substr(cleanPhone.length - extensionLength);
          var extModel = $scope.Extensions.filter(e => e.Extension === extension);
          if (extModel && extModel.length > 0) {
            extModel = extModel[0];
            contact.Extension = extModel;
            if (extModel.IsInCall) {
              result.Code = 2;
              result.Message = "Telefoniert...";
            } else if (extModel.StatusText === "Available") {
              result.Code = 1;
            } else if (extModel.StatusText === "Away") {
              result.Code = 3;
              result.Message = "3CX Status: Abwesend";
            } else if (extModel.StatusText === "Out of office") {
              result.Code = 4;
              result.Message = "3CX Status: Nicht stören";
            } else {
              // TODO: Custom States?
              result.Code = 1;
            }
          }
        }
      }
    }
    return result;
  };

  $scope.UpdateFilter = function () {
    angular.forEach($scope.Model.Entries, function (entry) {
      if ($scope.FilterBirthday) {
        if (entry.DaysUntilNextBirthday !== null && entry.DaysUntilNextBirthday <= 7) {
          entry.IsHidden = false;
        } else {
          entry.IsHidden = true;
        }
      } else {
        if ($scope.SearchString === null || $scope.SearchString === "") {
          entry.IsHidden = false;
        } else {
          var cleanSearch = $scope.SearchString.toUpperCase();
          if (entry.LastName && entry.LastName.toUpperCase().includes(cleanSearch) || entry.FirstName && entry.FirstName.toUpperCase().includes(cleanSearch) ||
            entry.EMail && entry.EMail.toUpperCase().includes(cleanSearch) || entry.Department && entry.Department.toUpperCase().includes(cleanSearch)) {
            entry.IsHidden = false;
          } else {
            entry.IsHidden = true;
          }
        }
      }
    });
    $scope.Model.FilteredEntries = $scope.Model.Entries.filter(f => !f.IsHidden);
    $scope.EmplyeesTable.UpdateSource($scope.Model.FilteredEntries, $timeout);
  };

  $scope.ClearSearchText = function () {
    $scope.SearchString = "";
    $scope.UpdateFilter();
  };

  $scope.LoadData();
  $scope.$on('selectedMandatorChanged', function () {
    $scope.LoadData();
  });
}
