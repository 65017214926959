
import { fixDate } from "src/ajs/Utils/DateUtils";
import { LegacyUserDto } from "src/app/core/dto/legacy-user-dto";
import { fixTaskTimeEntryDto, TaskTimeEntryDto } from "./task-time-entry-dto";

export interface TaskNoteDto {
  Id: number;
  TaskId: number;
  CreatedUserId: string;
  ModifiedUserId: string;
  CreatedDate: Date;
  ModifiedDate: Date;
  Text: string;

  CreatedUser: LegacyUserDto
  TimeEntry: TaskTimeEntryDto
}

export function fixTaskNoteDto(dto: TaskNoteDto) {
  dto.CreatedDate = fixDate(dto.CreatedDate);
  dto.ModifiedDate = fixDate(dto.ModifiedDate);

  if (dto.TimeEntry) {
    fixTaskTimeEntryDto(dto.TimeEntry);
  }
}
