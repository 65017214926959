import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColumnConfig } from '../../services/column-editor/column-config';
import { DialogService } from '../../services/dialog.service';
import { ColumnEditorDialogData } from './column-editor-dialog-data';
import { ColumnVM } from './column.vm';

@Component({
  selector: 'app-column-editor-dialog',
  templateUrl: './column-editor-dialog.component.html',
  styleUrls: ['./column-editor-dialog.component.scss']
})
export class ColumnEditorDialogComponent {

  readonly columns: ColumnVM[];
  readonly isDefault: boolean;

  constructor(
    private readonly dialogRef: MatDialogRef<ColumnEditorDialogComponent>,
    private readonly dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public readonly data: ColumnEditorDialogData
  ) {
    this.columns = this.data.columnEditorService.currentConfig.map(cfg => <ColumnVM>{
      definition: this.data.columnEditorService.definitions.find(def => def.name === cfg.name),
      isVisible: cfg.isVisible
    });

    // in Variable, weil sont beim Ändern und Speichern der Zurücksetzen-Button kurz aufblitzt.
    this.isDefault = data.columnEditorService.isDefault;
  }

  drop(event: CdkDragDrop<ColumnConfig[]>) {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
  }

  save() {
    const config: ColumnConfig[] = this.columns.map(x => <ColumnConfig>{
      name: x.definition.name,
      isVisible: x.isVisible
    });
    this.data.columnEditorService.setConfig(config);
    this.dialogRef.close(null);
  }

  cancel() {
    this.dialogRef.close(null);
  }

  isSomeColumnVisible(): boolean {
    return this.columns.some(x => x.isVisible);
  }

  async reset() {
    if (!await this.dialogService.showConfirmation('Zurücksetzen', 'Spalteneinstellungen wirklich auf Standard zurücksetzen?', 'Zurücksetzen', 'Abbrechen')) {
      return;
    }
    this.data.columnEditorService.resetConfig();
    this.dialogRef.close(null);
  }
}

