import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContractDataDto } from '../dto/contract-data.dto';

@Component({
  selector: 'app-contract-tab-edit-dialog',
  templateUrl: './contract-tab-edit-dialog.component.html',
  styleUrls: ['./contract-tab-edit-dialog.component.scss']
})
export class ContractTabEditDialogComponent implements OnInit {

  tabNameInput: FormControl<string> = new FormControl<string>("");

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: {
      tab?: ContractDataDto,
    },
    private readonly dialogRef: MatDialogRef<ContractTabEditDialogComponent>,
  ) { }

  ngOnInit() {
    if (this.data.tab.title) {
      this.tabNameInput.patchValue(this.data.tab.title);
    }
  }


  ok() {
    this.data.tab.title = this.tabNameInput.value;
    this.dialogRef.close();
  }

  delete() {
    this.dialogRef.close("delete");
  }

}
