import * as angular from 'angular';
import { firstValueFrom, map } from 'rxjs';
import { ContactDto } from 'src/app/contacts/dto/contact-dto';
import { ContactPhoneNumberDto } from 'src/app/contacts/dto/contact-phone-number-dto';
import { Api1Service } from 'src/app/core/services/api1.service';
import { DragAndDropPanel } from '../Directives/DragAndDropPanel';
import { SimpleStringResultDto } from '../Models/SimpleStringResult';
import { ContactsService } from '../Services/ContactsService';
import { StorageService, StorageServiceInstance } from '../Services/StorageService';
import { cleanupPhoneNumber } from '../Utils/HestiaCommon';

export class SmsSendController {
  static $inject = ['$scope', '$mdToast', 'mdPanelRef', 'StorageService', 'Api1ServiceNgx', 'ContactsService', 'Contact', 'Number'];

  constructor(
    $scope: ISmsSendControllerScope,
    $mdToast: angular.material.IToastService,
    mdPanelRef: angular.material.IPanelRef,
    StorageService: StorageService,
    api: Api1Service,
    ContactsService: ContactsService,
    Contact: ContactDto,
    Number: ContactPhoneNumberDto
  ) {

    $scope.Storage = StorageService.CreateInstance("SmsSendController");
    $scope.DragAndDropPanel = new DragAndDropPanel("Sms", mdPanelRef);
    $scope.Model = {
      Recipient: null,
      Content: null
    } as ISmsSendControllerModel;

    if (Contact?.Id > 0) {
      $scope.Model.Contact = Contact;
    }

    if (Number) {
      $scope.Model.Recipient = cleanupPhoneNumber(Number.Number);
    }

    $scope.QueryContact = async function (query: string) {
      if (query) {
        const contacts$ = ContactsService.getContactsBySearchText(query).pipe(
          map(array => array.filter(x => x.DisplayName))
        );
        return await firstValueFrom(contacts$);
      }
      return [];
    };

    $scope.SendSms = async function () {
      $scope.IsLoading = true;

      const data = await firstValueFrom(api.post<SimpleStringResultDto>("Toolbox", "SendSms", null,
        { recipient: $scope.Model.Recipient, message: $scope.Model.Content }));

      $mdToast.show(
        $mdToast.simple()
          .textContent(data.Result)
          .position('bottom right')
          .hideDelay(10000));

      $scope.IsLoading = false;
    };

    $scope.CleanPhoneNumber = function (number: string) {
      return cleanupPhoneNumber(number);
    };
  }
}

interface ISmsSendControllerScope extends angular.IScope {
  Storage: StorageServiceInstance;
  DragAndDropPanel: DragAndDropPanel;
  Model: ISmsSendControllerModel;
  QueryContact: (query: string) => Promise<ContactDto[]>;
  SendSms: () => void;
  CleanPhoneNumber: (number: string) => string;
  IsLoading: boolean;
}

interface ISmsSendControllerModel {
  Contact: ContactDto;
  Recipient: string;
  Content: string;
}
