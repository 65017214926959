export interface XmlItemDto {
  positionNumber: string;
  description: string;
  quantity: string;
  unit: string;
  unitPrice: string;
  fromDate: Date;
  toDate: Date;
  lineItemAmount: string;
}

export function fixXmlItemDto(dto: XmlItemDto) {
  if (dto) {
    if (dto.fromDate) {
      dto.fromDate = new Date(dto.fromDate);
    }
    if (dto.toDate) {
      dto.toDate = new Date(dto.toDate);
    }
  }
}