import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe, formatDate, Location } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDrawer } from '@angular/material/sidenav';
import moment from 'moment';
import { merge, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { TaskReceiptDto } from 'src/ajs/Models/Tasks/TaskReceiptDto';
import { TaskReceiptRowDto } from 'src/ajs/Models/Tasks/TaskReceiptRowDto';
import { fixDate } from 'src/ajs/Utils/DateUtils';
import { copyToClipboard } from 'src/ajs/Utils/HestiaCommon';
import { RouteParamsAjs } from 'src/app/ajs-upgraded-providers';
import { Phone3CxCallDto } from 'src/app/phone/dto/phone-3cx-call-dto';
import { PhoneService } from 'src/app/phone/services/phone.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { TaskReceiptEditDto } from '../../dto/task-receipt-edit-dto';
import { TasksReceiptRowDto } from '../../dto/tasks-receipt-row-dto';
import { TaskService } from '../../services/task.service';
import { AccountingType } from './accounting-type.enum';
import { TaskReceiptPhoneSupportVm } from './task-receipt-phone-support.vm';
import { TaskReceiptRowEntryVm } from './task-receipt-row-entry-vm';
import { TaskReceiptRowVm } from './task-receipt-row-vm';
import { MandatorService } from 'src/app/core/services/mandator.service';


@Component({
  selector: 'app-task-receipt-edit',
  templateUrl: './task-receipt-edit.component.html',
  styleUrls: ['./task-receipt-edit.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class TaskReceiptEditComponent implements OnInit, OnDestroy {

  private readonly $routeParams: angular.route.IRouteParamsService;
  displayedColumns = ["type", "date", "duration", "user", "description"];
  displayedColumnsPhoneSuppport = ["date", "duration", "contactName", "number", "extensionDescription"];
  isLoading: boolean;
  private taskReceipEditDto: TaskReceiptEditDto;
  receiptRows: TaskReceiptRowVm[] = [];
  private taskRows: TaskReceiptRowVm[] = [];
  private phoneSupportRow: TaskReceiptRowVm = null;
  phoneSupportContactRows: TaskReceiptPhoneSupportVm[] = [];
  formGroup: UntypedFormGroup;
  private addressId: number;
  private receiptId: number;
  isArchivedReceipt = false;
  private settlementDate: Date;
  isSaving = false;

  taskDurationSum: number;
  callDurationSum: number;
  totalDurationSum: number;
  phoneSupportTotalDuration: number;

  public readonly refresh$ = new Subject<void>();
  private readonly destroy$ = new Subject<void>();

  // SideNav
  @ViewChild(MatDrawer) drawer: MatDrawer;
  taskId: number;

  constructor(
    private readonly taskService: TaskService,
    $routeParams: RouteParamsAjs,
    private readonly location: Location,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly phoneService: PhoneService,
    @Inject(LOCALE_ID) private locale: string,
    private readonly dialogService: DialogService,
    private readonly datePipe: DatePipe,
    private readonly mandatorService: MandatorService
  ) {
    this.$routeParams = $routeParams as angular.route.IRouteParamsService;

    this.formGroup = this.formBuilder.group({
      name: [""],
      foreignReceiptId: [null],
      firstEntry: [""],
      lastEntry: [""],
      accountingPeriodEnd: [""],
    });
  }

  async ngOnInit() {

    const addressId = parseInt(this.$routeParams['addressId']);
    const receiptId = parseInt(this.$routeParams['receiptId']);
    this.settlementDate = fixDate(parseInt(this.$routeParams['settlementDate']));
    if (!this.settlementDate) {
      this.settlementDate = new Date();
    }

    if (!addressId && !receiptId) {
      this.location.go('/Tasks');
      return;
    }

    if (receiptId) {
      this.isArchivedReceipt = true;
      this.receiptId = receiptId;
    }

    if (addressId) {
      this.addressId = addressId;
    }

    merge(
      this.refresh$,
      this.mandatorService.selectedMandatorIdChanged
    ).pipe(
      takeUntil(this.destroy$),
      startWith({}),
      tap(() => this.isLoading = true),
      switchMap(() => this.taskService.getTaskReceiptEditData(addressId, receiptId, this.settlementDate, this.mandatorService.selectedMandatorId))
    ).subscribe((result) => {
      this.taskReceipEditDto = result;
      this.loadData();
      this.isLoading = false;
    });


  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.refresh$.complete();
  }

  fixDate(date: string) {
    return fixDate(date);
  }

  getCallDescription(call: Phone3CxCallDto) {
    let result = "";
    let formattedDirection = "Unbekannt";
    switch (call.CleanPartList[0].ConnectionType) {
      case 0:
        formattedDirection = "Eingehend";
        break;
      case 1:
        formattedDirection = "Ausgehend";
        break;
      case 2:
        formattedDirection = "Intern";
        break;
    }

    result = formattedDirection + " | ";
    if (call.OtherPartyMainContact) {
      result += call.OtherPartyMainContact.DisplayName;
    } else {
      result += call.OtherPartyNumber;
    }

    return result;
  }

  private calculatePhoneSupportContactTotalDuration(row: TaskReceiptPhoneSupportVm) {
    let totalDuration = 0;
    for (const call of row.calls) {
      totalDuration += (this.getCallDurationMilliseconds(call));
    }
    return totalDuration;
  }

  private calculatePhoneSupportTotalDuration() {
    let totalDuration = 0;
    for (const row of this.phoneSupportContactRows) {
      totalDuration += this.calculatePhoneSupportContactTotalDuration(row);
    }
    return totalDuration;
  }

  private calculateTaskDurationSum() {
    let totalDuration = 0;

    for (const row of this.receiptRows) {
      if (row.taskDuration) {
        totalDuration += row.taskDuration;
      }
    }

    return totalDuration;
  }

  private calculateCallDurationSum() {
    let totalDuration = 0;

    for (const row of this.receiptRows) {
      if (row.callDuration && row.task) {
        totalDuration += row.callDuration;
      }
    }

    return totalDuration;
  }

  private calculateTotalDurationSum() {
    return this.taskDurationSum + this.callDurationSum + this.calculatePhoneSupportTotalDuration();
  }

  calculateAccountedTimeSum() {
    let result = 0;

    if (this.receiptRows) {
      for (const row of this.receiptRows) {
        result += row.formGroup.value.accountedTime;
      }
    }
    return result;
  }

  getRowFirstDate(row: TasksReceiptRowDto) {
    let result;

    if (row.Calls.length) {
      result = row.Calls[0].StartDateTime;
    }
    else if (row.NotesWithTime.length) {
      result = row.NotesWithTime[0].CreatedDate;
    }

    for (const call of row.Calls) {
      const date = call.StartDateTime;
      if (result > date) {
        result = date;
      }
    }

    for (const entry of row.NotesWithTime) {
      const date = entry.CreatedDate;
      if (result > date) {
        result = date;
      }
    }
    return result;
  }

  getRowLastDate(row: TasksReceiptRowDto) {
    let result;

    if (row.Calls.length) {
      result = row.Calls[0].StartDateTime;
    }
    else if (row.NotesWithTime.length) {
      result = row.NotesWithTime[0].CreatedDate;
    }

    for (const call of row.Calls) {
      const date = call.StartDateTime;
      if (result < date) {
        result = date;
      }
    }

    for (const entry of row.NotesWithTime) {
      const date = entry.CreatedDate;
      if (result < date) {
        result = date;
      }
    }
    return result;
  }

  getAllRowsFirstDate() {
    let result;

    if (this.taskReceipEditDto.TaskReceiptRows[0].Calls.length) {
      result = this.taskReceipEditDto.TaskReceiptRows[0].Calls[0].StartDateTime;
    }
    else {
      result = this.taskReceipEditDto.TaskReceiptRows[0].NotesWithTime[0].CreatedDate;
    }

    for (const row of this.taskReceipEditDto.TaskReceiptRows) {

      const rowResult = this.getRowFirstDate(row);

      if (rowResult < result) {
        result = rowResult;
      }
    }

    return result;
  }


  getAllRowsLastDate() {
    let result;

    if (this.taskReceipEditDto.TaskReceiptRows[0].Calls.length) {
      result = this.taskReceipEditDto.TaskReceiptRows[0].Calls[0].StartDateTime;
    }
    else {
      result = this.taskReceipEditDto.TaskReceiptRows[0].NotesWithTime[0].CreatedDate;
    }

    for (const row of this.taskReceipEditDto.TaskReceiptRows) {

      const rowResult = this.getRowLastDate(row);

      if (rowResult > result) {
        result = rowResult;
      }
    }

    return result;
  }

  getCallDurationMilliseconds(call: Phone3CxCallDto) {
    const result = call?.EndDateTime.getTime() - call?.StartDateTime.getTime();
    return result;
  }

  getExtensionName(extension: string) {
    let result = extension;

    if (extension === "201") {
      result += " - AXS Extended";
    }
    else {
      result += " - AXS";
    }
    return result;
  }

  async saveReceipt() {
    const allCallIds = [];
    const allTimeEntryIds = [];
    const receiptRows: TaskReceiptRowDto[] = [];

    for (const row of this.taskRows) {

      if (row.formGroup.value.accountingTypeToggle !== AccountingType.SpäterVerrechnen) {

        const currentRow: TaskReceiptRowDto = {
          TaskId: row.task.Id,
          AccountingType: row.formGroup.value.accountingTypeToggle,
          AccountedTime: row.formGroup.value.accountedTime,
          CallIds: [],
          TimeEntryIds: [],
          Id: undefined,
          ReceiptId: undefined
        };


        for (const entry of row.entries) {
          if (entry.Call) {
            currentRow.CallIds.push(entry.Call.Id);
            allCallIds.push(entry.Call.Id);
          }
          else if (entry.Note) {
            currentRow.TimeEntryIds.push(entry.Note.TimeEntry.TaskNoteId);
            allTimeEntryIds.push(entry.Note.TimeEntry.TaskNoteId);
          }
        }

        receiptRows.push(currentRow);
      }
    }

    if (this.phoneSupportRow && this.phoneSupportRow.formGroup.value.accountingTypeToggle !== AccountingType.SpäterVerrechnen) {
      const currentRow: TaskReceiptRowDto = {
        TaskId: null,
        AccountingType: this.phoneSupportRow.formGroup.value.accountingTypeToggle,
        AccountedTime: this.phoneSupportRow.formGroup.value.accountedTime,
        CallIds: [],
        TimeEntryIds: [],
        Id: undefined,
        ReceiptId: undefined
      };

      for (const entry of this.phoneSupportRow.entries) {
        currentRow.CallIds.push(entry.Call.Id);
        allCallIds.push(entry.Call.Id);
      }

      receiptRows.push(currentRow);
    }

    const requestModel: TaskReceiptDto = {
      Id: undefined,
      Name: this.formGroup.value.name,
      ForeignReceiptId: this.formGroup.value.foreignReceiptId,
      Address: undefined,
      AccountingPeriodEnd: moment(this.settlementDate),
      AddressId: this.addressId,
      Created: new Date(),
      MandatorId: this.mandatorService.selectedMandatorId,
      CallIds: allCallIds,
      TimeEntryIds: allTimeEntryIds,
      Rows: receiptRows
    };

    this.isSaving = true;

    await this.taskService.createReceipt(requestModel);

    this.isSaving = false;

    this.location.go("/Tasks");

  }

  async deleteReceipt() {

    if (!await this.dialogService.showConfirmation("Beleg löschen", "Beleg wirklich löschen?", "Löschen", "Abbrechen", "warn")) {
      return;
    }

    this.isSaving = true;

    await this.taskService.deleteReceipt(this.receiptId, this.mandatorService.selectedMandatorId);

    this.isSaving = false;

    this.location.go("/Tasks");

  }

  copyText(row: TaskReceiptRowVm) {
    copyToClipboard("Aufgabe: " + row.task.Title + "\nZeitraum: "
      + this.datePipe.transform(this.getRowFirstDate(row.taskReceiptRow), 'dd.MM.yyyy')
      + " - " + formatDate(this.getRowLastDate(row.taskReceiptRow), 'dd.MM.yyyy', this.locale));
  }

  openSideNav(row: TaskReceiptRowVm) {

    this.taskId = row.task.Id;

    if (!this.drawer.opened) {
      this.drawer.open();
    }

  }

  loadData() {

    this.receiptRows = [];
    this.taskRows = [];
    this.phoneSupportRow = null;
    this.phoneSupportContactRows = [];
    this.callDurationSum = 0;
    this.taskDurationSum = 0;
    this.totalDurationSum = 0;

    this.formGroup.patchValue({
      name: this.taskReceipEditDto.Name,
      foreignReceiptId: this.taskReceipEditDto.ForeignReceiptId,
      accountingPeriodEnd: formatDate(this.taskReceipEditDto.AccountingPeriodEnd, 'dd.MM.yyyy', this.locale),
      firstEntry: formatDate(this.getAllRowsFirstDate(), 'dd.MM.yyyy', this.locale),
      lastEntry: formatDate(this.getAllRowsLastDate(), 'dd.MM.yyyy', this.locale),
    });

    this.formGroup.patchValue({
      accountingPeriodEnd: formatDate(this.settlementDate, 'dd.MM.yyyy', this.locale)
    });

    if (this.taskReceipEditDto.Address) {
      this.formGroup.patchValue({
        name: this.taskReceipEditDto.Address + " bis " + formatDate(this.settlementDate, 'dd.MM.yyyy', this.locale)
      });
    }

    for (const row of this.taskReceipEditDto.TaskReceiptRows) {
      const rowVm: TaskReceiptRowVm = new TaskReceiptRowVm(this.formBuilder);
      rowVm.taskReceiptRow = row;
      rowVm.loadFromDto();

      if (row.Task) {
        rowVm.task = row.Task;

        for (const call of row.Calls) {
          const entry = {
            Call: call,
            Note: null,
            Date: call.StartDateTime
          };
          rowVm.entries.push(entry);
        }

        for (const note of row.NotesWithTime) {
          const entry = {
            Call: null,
            Note: note,
            Date: note.CreatedDate
          };
          rowVm.entries.push(entry);
        }
        this.taskRows.push(rowVm);
      }
      else {
        this.phoneSupportRow = rowVm;

        for (const call of row.Calls) {
          const phoneSupportRowVm = this.phoneSupportContactRows.find(x => x.contactName === call.OtherPartyMainContact?.DisplayName);

          if (phoneSupportRowVm) {
            phoneSupportRowVm.calls.push(call);
          }
          else {
            const newPhoneSupportRowVm: TaskReceiptPhoneSupportVm = {
              contactName: call.OtherPartyMainContact?.DisplayName,
              calls: [call],
              isExpanded: false,
              totalCallDuration: 0,
            };
            this.phoneSupportContactRows.push(newPhoneSupportRowVm);
          }
        }
      }

      for (const row of this.phoneSupportContactRows) {
        row.totalCallDuration = this.calculatePhoneSupportContactTotalDuration(row);
      }

      this.phoneSupportContactRows.sort((a: TaskReceiptPhoneSupportVm, b: TaskReceiptPhoneSupportVm) => {
        return a.totalCallDuration > b.totalCallDuration ? -1 : 1;
      });

      rowVm.entries.sort((a: TaskReceiptRowEntryVm, b: TaskReceiptRowEntryVm) => {
        return a.Date.valueOf() > b.Date.valueOf() ? -1 : 1;
      });
    }

    this.receiptRows.push(...this.taskRows);
    if (this.phoneSupportRow) {
      this.receiptRows.push(this.phoneSupportRow);
    }

    this.taskDurationSum = this.calculateTaskDurationSum();
    this.callDurationSum = this.calculateCallDurationSum();
    this.totalDurationSum = this.calculateTotalDurationSum();
    this.phoneSupportTotalDuration = this.calculatePhoneSupportTotalDuration();
  }


  openContactDialog(contactId: number) {
    this.phoneService.showPhoneContactDialog(contactId);
  }

  setDefaultAccountedTime(row: TaskReceiptRowVm) {
    let accountedTime;

    accountedTime = (row.taskDuration + row.callDuration) / 1000 / 60;
    const remainder = accountedTime % 15;

    if (remainder > 7) {
      accountedTime += 15 - remainder;
    }
    else {
      accountedTime -= remainder;
    }
    accountedTime /= 60;

    row.formGroup.patchValue({ accountedTime: accountedTime });
  }

  resetAccountedTime(row: TaskReceiptRowVm) {
    row.formGroup.patchValue({ accountedTime: 0 });
  }

  checkAnyAccounted() {
    for (const row of this.receiptRows) {
      if (row.formGroup?.value.accountingTypeToggle === AccountingType.Verrechnen || row.formGroup?.value.accountingTypeToggle === AccountingType.NichtVerrechnen) {
        return true;
      }
    }
    return false;
  }

}
