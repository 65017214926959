import { CallAnalyticsCallDto, fixCallAnalyticsDataDto } from "./call-analytics-call-dto";

export interface CallAnalyticsAddressDto {
  AddressId: number;
  AddressDisplayName: string;
  TotalCalls: number;
  TotalCallDuration: number;
  Calls: CallAnalyticsCallDto[];
}


export function fixCallAnalyticsAddressDto(dto: CallAnalyticsAddressDto) {
  for (const call of dto.Calls) {
    fixCallAnalyticsDataDto(call);
  }
}
