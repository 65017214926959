import { Pipe, PipeTransform } from "@angular/core";

/**
 * Macht aus einer Telefonnummer, die als `string` übergeben wird einen tel:-Link.
 */
@Pipe({ name: 'tel' })
export class TelPipe implements PipeTransform {

  transform(value: string) {
    if (!value) {
      return '';
    }
    const result = 'tel:' + value.replace(/\s/g, '');
    return result;
  }
}
