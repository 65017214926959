import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { merge, Subject, switchMap, takeUntil, timer, catchError, of } from 'rxjs';

import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-notification-userbar',
  templateUrl: './notification-userbar.component.html',
  styleUrls: ['./notification-userbar.component.scss']
})
export class NotificationNavbarComponent implements OnInit, OnDestroy {

  notificationCount: number;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly notificationService: NotificationService,
    private readonly location: Location,
  ) { }

  ngOnInit() {
    merge(
      timer(0, 10000),
      this.notificationService.unreadCountChanged
    ).pipe(
      takeUntil(this.destroy$),
      switchMap(() => this.notificationService.getUnreadCount()),
      catchError(_ => of(0))
    ).subscribe(count => {
      this.notificationCount = count;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
