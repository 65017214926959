import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSort } from '@angular/material/sort';
import { catchError, debounceTime, firstValueFrom, forkJoin, merge, Observable, of, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { IPanelServiceEx } from 'src/ajs/Services/IPanelServiceEx';
import { SearchBarService } from 'src/ajs/Services/SearchBarService';
import { mdPanelAjs } from 'src/app/ajs-upgraded-providers';
import { COLEDITOR_DEF, COLEDITOR_DEF_FIXED_START, COLEDITOR_STORAGE_KEY, ColumnEditorService } from 'src/app/shared/services/column-editor/column-editor.service';
import { SortService, SORT_DEFAULT_ACTIVE, SORT_DEFAULT_DIRECTION, SORT_STORAGE_KEY } from 'src/app/shared/services/sort/sort.service';
import { GetTaskListResultDto } from '../../dto/get-task-list-result-dto';
import { TaskQueueDto } from '../../dto/task-queue-dto';
import { TaskStateDto } from '../../dto/task-state-dto';
import { TaskStatePreset } from '../../dto/task-state-preset';
import { TaskService } from '../../services/task.service';
import { BulkTaskEditEventData } from './bulk-task-edit-event-data';
import { TaskListVm } from './task-list-vm';
import { TaskQueueVM } from './task-queue-vm';
import { MandatorService } from 'src/app/core/services/mandator.service';


@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
  providers: [
    ColumnEditorService,
    { provide: COLEDITOR_STORAGE_KEY, useValue: 'task-list.columns' },
    {
      provide: COLEDITOR_DEF, useValue: [
        { name: 'title', header: 'Titel' },
        { name: 'queue', header: 'Queue' },
        { name: 'createdDate', header: 'Erstellt' },
        { name: 'address', header: 'Adresse/Kunde' },
        { name: 'dueDate', header: 'Fällig' },
        { name: 'priority', header: 'Priorität' },
        { name: 'createdBy', header: 'Erstellt von' },
        { name: 'assigned', header: 'Zugewiesen an' },
        { name: 'status', header: 'Status' },
        { name: 'lastModified', header: 'Letzte Änderung' },
      ]
    },
    {
      provide: COLEDITOR_DEF_FIXED_START, useValue: [
        { name: 'checkbox', header: '' },
      ]
    },
    SortService,
    { provide: SORT_STORAGE_KEY, useValue: 'task-list.sort' },
    { provide: SORT_DEFAULT_ACTIVE, useValue: 'createdDate' },
    { provide: SORT_DEFAULT_DIRECTION, useValue: 'desc' },
  ]
})
export class TaskListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  totalCount: number = 0;
  data: TaskListVm[] = [];
  private readonly destroy$ = new Subject<void>();
  public readonly refresh$ = new Subject<void>();
  private readonly $mdPanel: IPanelServiceEx;
  isLoading = false;
  anyChecked = false;
  readonly taskStatePreset = TaskStatePreset;
  taskStates: TaskStateDto[] = [];
  taskQueues: TaskQueueDto[] = [];
  selectedTaskStateFilterId: number = TaskStatePreset.Offene;
  selectedUserFilterId: number = 1;
  rootQueues: TaskQueueVM[] = [];
  selectedRootQueue: TaskQueueVM;
  selectedChildQueue: TaskQueueVM;

  selectedQueueAll: TaskQueueVM =
    {
      Id: 0,
      Description: ("Alle"),
      Children: [],
    };

  selectedTaskId;
  @ViewChild(MatDrawer) drawer: MatDrawer;

  // Für createTaskFromCurrentSelection
  selectedQueue: TaskQueueVM;
  private _addressId: number;

  @Input() set addressId(value: number) {
    if (this._addressId !== value) {
      this._addressId = value;

      this.refresh$.next();
    }
  }

  get addressId() {
    return this._addressId;
  }

  @Output() readonly bulkTaskEdit = new EventEmitter<BulkTaskEditEventData>();
  @Input() refreshRequested: Observable<void>;

  constructor(
    public readonly sortService: SortService,
    public readonly columnEditor: ColumnEditorService,
    public readonly taskService: TaskService,
    $mdPanel: mdPanelAjs,
    private readonly SearchBarService: SearchBarService,
    private readonly mandatorService: MandatorService
  ) {
    this.$mdPanel = $mdPanel as IPanelServiceEx;
  }

  async ngOnInit() {
    await firstValueFrom(this.taskService.createTaskEnviroment(this.mandatorService.selectedMandatorId));
  }

  async ngAfterViewInit() {
    const result = await firstValueFrom(forkJoin([
      this.taskService.getTaskStates(),

      this.columnEditor.loaded$,
      this.sortService.loaded$
    ]));

    this.taskStates = result[0];

    if (this.refreshRequested) {
      this.refreshRequested.pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        this.refresh$.next();
      });
    }

    this.mandatorService.selectedMandatorIdChanged.pipe(
      takeUntil(this.destroy$),
      startWith({})
    )
      .subscribe(async () => {
        this.taskQueues = await firstValueFrom(this.taskService.getTaskQueues(this.mandatorService.selectedMandatorId));
        this.rootQueues = [];

        if (this.taskQueues) {
          for (const queue of this.taskQueues) {
            if (queue.ParentTaskQueueId == null) {
              const vm: TaskQueueVM = {
                Id: queue.Id,
                Description: queue.Description,
                Children: [],
              };
              this.rootQueues.push(vm);
            }
          }

          for (const queue of this.taskQueues) {
            if (queue.ParentTaskQueueId != null) {
              const rootQueue: TaskQueueVM = this.rootQueues.find(x => x.Id === queue.ParentTaskQueueId);
              if (rootQueue) {
                const vm: TaskQueueVM = {
                  Id: queue.Id,
                  Description: queue.Description,
                  Children: [],
                };
                rootQueue.Children.push(vm);
              }
            }
          }

          this.selectedRootQueue = this.selectedQueueAll;
          this.selectedChildQueue = null;
        }
      });

    merge(
      this.sort.sortChange,
      this.paginator.page,
      this.SearchBarService.searchTextChange.pipe(debounceTime(300)),
      this.mandatorService.selectedMandatorIdChanged,
      this.refresh$
    ).pipe(
      takeUntil(this.destroy$),
      startWith({}),
      tap(() => this.isLoading = true),
      switchMap(() => this.taskService.getTaskList(
        this.sort.active,
        this.sort.direction === "desc",
        this.paginator.pageSize,
        this.paginator.pageIndex,
        this.SearchBarService.SearchText,
        this.selectedTaskStateFilterId,
        this.selectedUserFilterId,
        this.addressId,
        this.findTaskQueue(this.selectedRootQueue?.Id),
        this.findTaskQueue(this.selectedChildQueue?.Id),
        this.mandatorService.selectedMandatorId,
      ).pipe(
        catchError(err => {
          console.log(err);
          return of({ TotalCount: 0, TaskList: [] } as GetTaskListResultDto);
        })
      ))
    ).subscribe(result => {
      this.isLoading = false;

      if (result) {
        this.totalCount = result.TotalCount;
        this.data = [];
        if (result.TaskList) {
          for (const x of result.TaskList) {
            const vm: TaskListVm = {
              TaskList: x,
              IsChecked: false
            };
            this.data.push(vm);
          }
        }
        this.anyChecked = false;
      }
    });



  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.refresh$.complete();
  }

  toggleAllChecked(event: MatCheckboxChange) {

    if (event.checked) {
      for (const x of this.data) {
        x.IsChecked = true;
      }
      this.anyChecked = true;
    }
    else {
      for (const x of this.data) {
        x.IsChecked = false;
      }
      this.anyChecked = false;
    }
  }

  toggleChecked(vm: TaskListVm) {
    vm.IsChecked = !vm.IsChecked;

    this.checkAnyChecked();
  }

  checkAnyChecked() {
    if (this.data.some(x => x.IsChecked)) {
      this.anyChecked = true;
    }
    else {
      this.anyChecked = false;
    }
  }

  filterChanged() {
    this.refresh$.next();
  }


  filterChangeRootQueue(queue: TaskQueueVM) {
    this.selectedQueue = queue;
    this.selectedChildQueue = this.selectedQueueAll;
    this.refresh$.next();
  }

  filterChangeChildQueue(queue: TaskQueueVM) {
    this.selectedQueue = queue;
    this.refresh$.next();
  }


  findTaskQueue(id: number) {

    const result: TaskQueueDto = this.taskQueues?.find(x => x.Id === id);
    if (result) {
      return result;
    }
    else
      return null;
  }


  async createTask($event: Event) {
    const result = await this.taskService.showTaskEditDialog();

    if (result) {
      this.refresh$.next();
    }

  }

  async editTasks($event: Event) {
    const taskIds: number[] = [];

    for (const task of this.data) {
      if (task.IsChecked) {
        taskIds.push(task.TaskList.TaskId);
      }
    }

    const tasks = await firstValueFrom(this.taskService.getTasksById(taskIds));

    this.bulkTaskEdit.emit({
      tasks: tasks,
      resolve: () => {
        this.refresh$.next();
      }
    });
  }

  async taskClicked(taskId: number) {

    this.selectedTaskId = taskId;

    if (!this.drawer.opened) {
      this.drawer.open();
    }
  }

}
