import { Directive, EventEmitter, HostBinding, HostListener, Output } from "@angular/core";

/** Fügt die css class "file-drag-over" zu einem Element hinzu, wenn Dateien darauf gezogen werden. */
@Directive({
  selector: '[appFileDragOver]'
})
export class FileDragOverDirective {
  @HostBinding('class.file-drag-over') fileDragOver: boolean;

  /** Wird ausgelöst, wenn Dateien auf dem Element abgelegt werden. */
  @Output() filesDropped = new EventEmitter<FileList>();


  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    // nur wenn ein file draufgezogen wird
    for (let i = 0; i < event.dataTransfer.items.length; i++) {
      if (event.dataTransfer.items[i].kind === "file") {
        this.fileDragOver = true;
        break;
      }
    }
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.fileDragOver = false;
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.fileDragOver = false;

    if (event.dataTransfer.files.length) {
      this.filesDropped.emit(event.dataTransfer.files);
    }
  }
}
