<h2 mat-dialog-title>Spalten bearbeiten</h2>
<mat-dialog-content class="mat-typography">
  <div cdkDropList class="column-list" (cdkDropListDropped)="drop($event)">
    <div cdkDrag class="column-item" *ngFor="let col of columns" fxLayout="row" fxLayoutAlign="start center">
      <mat-checkbox [(ngModel)]="col.isVisible" fxFlex>{{ col.definition.header }}</mat-checkbox>
      <mat-icon>drag_handle</mat-icon>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button *ngIf="!isDefault" type="button" mat-icon-button class="justify-start" (click)="reset()">
    <mat-icon>replay</mat-icon>
  </button>
  <button type="button" mat-stroked-button (click)="cancel()">Abbrechen</button>
  <button type="button" mat-raised-button color="primary" (click)="save()" [disabled]="!isSomeColumnVisible()">Speichern</button>
</mat-dialog-actions>
