import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AppConfigDto } from '../dto/app-config-dto';
import { Api2Service } from './api2.service';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService implements AppConfigDto {

  googleMapsApiKey: string;
  maxFileSize: number;
  offerWindowsAuth: boolean;
  requireTFA: boolean;
  environment: 'AXS' | 'CP';
  documentsRootDirs: string[];
  legacyProjectRootDirs: string[];
  siteUrl: string;

  constructor(
    private readonly api2: Api2Service
  ) { }

  /** Wird beim Starten der App aufgerufen. */
  appInitialize(): Promise<void> {
    return firstValueFrom<AppConfigDto>(this.api2.get('Core', 'GetAppConfig')).then(data => {
      Object.assign(this, data);
    });
  }
}
