import angular, { IScope } from "angular";
import moment from "moment";
import { ColumnDefinition, HestiaDataTable } from "src/ajs/Directives/HestiaDataTableDirective";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { BaseReceiptPositionDto } from "src/ajs/Models/Erp/BaseReceiptPositionDto";
import { GetReceiptsDto } from "src/ajs/Models/Erp/GetReceiptsDto";
import { QueryAddressDto } from "src/ajs/Models/Erp/QueryAddressDto";
import { PaymentState, ReceiptDto } from "src/ajs/Models/Erp/ReceiptDto";
import { LabelPrintDataDto } from "src/ajs/Models/Label/LabelPrintDataDto";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { IPanelServiceEx } from "src/ajs/Services/IPanelServiceEx";
import { SearchBarService } from "src/ajs/Services/SearchBarService";
import { StorageService, StorageServiceInstance } from "src/ajs/Services/StorageService";
import { addNavigation, addNavigationCallback, convertDate, copyToClipboard, formatDateShort } from "src/ajs/Utils/HestiaCommon";
import { INavigationScope } from "src/ajs/Utils/INavigationScope";
import { ContactDto } from 'src/app/contacts/dto/contact-dto';
import { ContactMailAddressDto } from "src/app/contacts/dto/contact-mail-address.dto";
import { ContactPhoneNumberDto } from "src/app/contacts/dto/contact-phone-number-dto";
import { PhoneService } from "src/app/phone/services/phone.service";
import { DocumentEnvironment } from "./DocumentEnviroment";
import { ReceiptEnvironment } from "./ReceiptEnviroment";
import { MandatorService } from "src/app/core/services/mandator.service";


createErpController.$inject = ['$scope', '$mdPanel', '$mdToast', '$mdDialog', '$q', '$timeout', 'ApiHandlerService', 'StorageService', 'SearchBarService', 'theme', 'PhoneServiceNgx', 'MandatorServiceNgx'];

export function createErpController(
  $scope: IErpControllerScope,
  $mdPanel: IPanelServiceEx,
  $mdToast: angular.material.IToastService,
  $mdDialog: angular.material.IDialogService,
  $q: angular.IQService,
  $timeout: angular.ITimeoutService,
  ApiHandlerService: ApiHandlerService,
  StorageService: StorageService,
  SearchBarService: SearchBarService,
  theme: ThemeDto,
  phoneServiceNgx: PhoneService,
  mandatorServiceNgx: MandatorService
) {

  mandatorServiceNgx.selectedMandatorIdChanged.subscribe(() => {
    if ($scope.ReceiptEnvironment) {
      $scope.ReceiptEnvironment.LoadData(true);
    }

    if ($scope.GoodsReceipts) {
      $scope.GoodsReceipts.LoadReceipts();
    }


    $scope.SelectedAddress = null;
    $scope.AddressSearchText = "";
  });


  SearchBarService.RequireSearchBar(true);
  $scope.Storage = StorageService.CreateInstance("ErpController");
  addDefaultScopeFunctions($scope, theme);

  $scope.IsAdminView = $scope.Storage.GetOrCreate("IsAdminView", false);
  addNavigation($scope, "BaseData");

  ApiHandlerService.SendRequest("Erp", "CreateErpEnviroment", { mandatorId: mandatorServiceNgx.selectedMandatorId });

  addNavigationCallback($scope, null, function () {
    $scope.$broadcast("applyFilters", null);
  });

  addNavigationCallback($scope, "Systems", function () {
    $scope.$broadcast("navigateSystems", null);
  });

  $scope.AutocompleteClicked = function () {
    //$('#AddressSearchAutocomplete').autocomplete("search");
    //$scope.AddressSearchString = $scope.SelectedAddress;
  };

  addNavigationCallback($scope, 'Documents', function () {
    $timeout(function () {
      if ($scope.SelectedAddress && $scope.SelectedAddress.CachedAddress) {
        if ($scope.DocumentEnvironment) {
          $scope.DocumentEnvironment.LoadData();
        } else {
          $scope.DocumentEnvironment = new DocumentEnvironment($scope.SelectedAddress.CachedAddress.Id, $timeout, StorageService, ApiHandlerService, $mdDialog, mandatorServiceNgx);
        }
      }
    }, 50);
  });

  addNavigationCallback($scope, 'Receipts', function () {
    $timeout(function () {
      if ($scope.SelectedAddress && $scope.SelectedAddress.CachedAddress) {
        if ($scope.ReceiptEnvironment) {
          $scope.ReceiptEnvironment.LoadData();
        } else {
          $scope.ReceiptEnvironment = new ReceiptEnvironment($scope, $timeout, StorageService, ApiHandlerService, $mdDialog, SearchBarService, mandatorServiceNgx, $scope.SelectedAddress.CachedAddress.Id,);
        }
      }
    }, 50);
  });
  addNavigationCallback($scope, 'AllReceipts', function () {
    $timeout(function () {
      if ($scope.ReceiptEnvironment) {
        $scope.ReceiptEnvironment.LoadData(true);
      } else {
        $scope.ReceiptEnvironment = new ReceiptEnvironment($scope, $timeout, StorageService, ApiHandlerService, $mdDialog, SearchBarService, mandatorServiceNgx, null, true);
      }
    }, 50);
  });
  addNavigationCallback($scope, 'GoodsReceipts', function () {
    $scope.GoodsReceipts.LoadReceipts();
  });
  $scope.GoodsReceipts = {
    Receipts: null,
    SelectedReceipt: null,
    FilteredCount: 0,
    Filter: {
      ShowPrinted: false
    },
    PrintArticleDialog: function () {
      $mdDialog.show({
        targetEvent: <MouseEvent>event,
        controller: 'PrintArticleLabelController',
        templateUrl: '/ClientApp/src/ajs/Views/Erp/PrintArticleLabel.htm',
        locals: {
          PrinterId: $scope.GoodsReceipts.Printing.SelectedPrinterId
        },
        parent: angular.element(document.body)
      });
    },
    LoadReceipts: function () {
      if ($scope.IsAdminView) {
        this.IsLoading = true;
        ApiHandlerService.SendRequest("Erp", "GetGoodsReceipts", { mandatorId: mandatorServiceNgx.selectedMandatorId }).then(function (data: GetReceiptsDto) {
          $scope.GoodsReceipts.Receipts = data.Receipts;
          $scope.GoodsReceipts.Printing = data.Printing;
          let currentId = null;
          if ($scope.GoodsReceipts.Printing?.Printers && $scope.GoodsReceipts.Printing?.Printers?.length) {
            currentId = $scope.GoodsReceipts.Printing.Printers[0].Id;
          }

          if ($scope.GoodsReceipts.Printing) {
            $scope.GoodsReceipts.Printing.SelectedPrinterId = $scope.Storage.GetOrCreate("SelectedPrinterId", currentId);
          }

          $scope.GoodsReceipts.Receipts.forEach(f => {
            f.Date = moment(f.Date);
            f.DateFormatted = f.Date.format("DD.MM.YYYY");
          });
          $scope.GoodsReceipts.ApplyFilter();
          $scope.GoodsReceipts.IsLoading = false;
        });
      }
    },
    PrinterChanged: function () {
      $scope.Storage.Set("SelectedPrinterId", $scope.GoodsReceipts.Printing.SelectedPrinterId);
    },
    ToggleVisibility: function () {
      $scope.GoodsReceipts.Filter.ShowPrinted = !$scope.GoodsReceipts.Filter.ShowPrinted;
      $scope.GoodsReceipts.ApplyFilter();
    },
    ChangePrintState: function (position: BaseReceiptPositionDto, state: number) {
      position.PrintState = state;
      $scope.GoodsReceipts.SavePosition(position);
    },
    SavePosition: function (position: BaseReceiptPositionDto) {
      ApiHandlerService.SendRequest("Erp", "SaveLabelPrintEntry", { entry: position, mandatorId: mandatorServiceNgx.selectedMandatorId }).then(function (data: any) {

      });
    },
    PrintReceipt: function (receipt: ReceiptDto, what: number) {
      ApiHandlerService.SendRequest("Erp", "PrintGoodsReceipt", { model: receipt, printerId: $scope.GoodsReceipts.Printing.SelectedPrinterId, what: what, mandatorId: mandatorServiceNgx.selectedMandatorId }).then(function (data: any) {
        receipt.IsPrinted = true;
        $scope.GoodsReceipts.ApplyFilter();
      });
    },
    SetReceiptPrinted: function (receipt: ReceiptDto) {
      ApiHandlerService.SendRequest("Erp", "SetReceiptPrinted", { model: receipt, mandatorId: mandatorServiceNgx.selectedMandatorId }).then(function (data: any) {
        receipt.IsPrinted = true;
        $scope.GoodsReceipts.ApplyFilter();
      });
    },
    BulkPrint: function () {
      const receipts = $scope.GoodsReceipts.Receipts.filter(f => !f.IsPrinted);
      ApiHandlerService.SendRequest("Erp", "BulkPrintGoodsReceipts", { models: receipts, printerId: $scope.GoodsReceipts.Printing.SelectedPrinterId, mandatorId: mandatorServiceNgx.selectedMandatorId }).then(function (data: any) {
        receipts.forEach(f => f.IsPrinted = true);
        $scope.GoodsReceipts.ApplyFilter();
      });
    },
    SetSearchText: function (text: string) {
      if ($scope.GoodsReceipts.SearchTimeout) {
        $timeout.cancel($scope.GoodsReceipts.SearchTimeout);
      }
      $scope.GoodsReceipts.SearchTimeout = $timeout(t => {
        $scope.GoodsReceipts.SearchText = text;
        $scope.GoodsReceipts.ApplyFilter();
        $scope.GoodsReceipts.SearchTimeout = null;
      }, 500);
    },
    ApplyFilter: function (text) {
      if ($scope.GoodsReceipts && $scope.GoodsReceipts.Receipts) {
        let entries = $scope.GoodsReceipts.Receipts.slice();
        if (!$scope.GoodsReceipts.Filter.ShowPrinted) {
          entries = entries.filter(f => !f.IsPrinted);
        }
        if ($scope.GoodsReceipts.SearchText) {
          const search = $scope.GoodsReceipts.SearchText.toLowerCase();
          entries = entries.filter(f => f.ReceiptIdentifier.includes(search) || f.Matchcode.toLowerCase().includes(search) || f.Reference && f.Reference.toLowerCase().includes(search));
        }

        $scope.GoodsReceipts.UnprintedReceiptsCount = entries.filter(f => !f.IsPrinted).length;
        $scope.GoodsReceipts.FilteredCount = entries.length;
        $scope.GoodsReceipts.ReceiptsTable.UpdateSource(entries, $timeout);
      }
    },
    ItemDetailsSelected: function (model: ReceiptDto) {
      model.IsLoading = true;
      $scope.GoodsReceipts.SelectedReceipt = model;
      ApiHandlerService.SendRequest("Erp", "GetGoodsReceiptPositions", { model: model, mandatorId: mandatorServiceNgx.selectedMandatorId }).then(function (data) {
        model.Positions = data.Positions;
        model.Positions.forEach(p => {
          p.UnitPriceFormatted = p.UnitPrice.toFixed(2) + " €";
          p.Total = p.UnitPrice * p.Quantitiy;
          p.TotalFormatted = p.Total.toFixed(2) + " €";
        });
        model.IsLoading = false;
      });
    },
    Init: function () {
      this.ReceiptsTable = new HestiaDataTable(StorageService.CreateInstance("GoodsReceiptsTable"), $timeout, this);
      this.ReceiptsTable.ItemClicked = this.ItemDetailsSelected.bind(this);
      this.ReceiptsTable.Options.Select = false;
      this.ReceiptsTable.UpdateColumns([
        new ColumnDefinition({ PropertyPath: 'ReceiptIdentifier', Heading: 'Belegnummer', Resizeable: true, Sortable: true }),
        new ColumnDefinition({ PropertyPath: 'Date', Heading: 'Datum', Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDateShort }),
        new ColumnDefinition({ PropertyPath: 'Type', Heading: 'Belegart', Resizeable: true, Sortable: true }),
        new ColumnDefinition({ PropertyPath: 'Matchcode', Heading: 'Matchcode', Resizeable: true, Sortable: true }),
        new ColumnDefinition({ PropertyPath: 'Sum', Heading: 'Nettobetrag', Resizeable: true, Sortable: true, Align: 'stretch', Template: "/ClientApp/src/ajs/Views/Erp/ReceiptSumTemplate.htm" }),
        new ColumnDefinition({ PropertyPath: 'Reference', Heading: 'Fremdbeleg', Resizeable: true, Sortable: true }),
        new ColumnDefinition({ PropertyPath: 'Creator', Heading: 'Bearbeiter', Resizeable: true, Sortable: true })
      ]);
    }
  } as GoodsReceiptDto;

  $scope.GoodsReceipts.Init();

  $scope.ChangeView = function () {
    $scope.IsAdminView = !$scope.IsAdminView;
    $scope.Storage.Set("IsAdminView", $scope.IsAdminView);
    if ($scope.IsAdminView) {
      $scope.Navigate("GoodsReceipts", true);
    } else {
      $scope.Navigate("BaseData", true);
    }
  };

  $scope.SearchTextChanged = function (text: string) {
    $scope.SearchText = text;
    if ($scope.FilterTimeout !== null) {
      $timeout.cancel($scope.FilterTimeout);
    }
    $scope.FilterTimeout = $timeout(function () {
      if ($scope.DocumentEnvironment && $scope.DocumentEnvironment.SearchTextChanged) {
        $scope.DocumentEnvironment.SearchTextChanged(text);
      }
      if ($scope.ReceiptEnvironment && $scope.ReceiptEnvironment.ApplyFilter) {
        $scope.ReceiptEnvironment.ApplyFilter(text);
      }
      $scope.ApplyFilter();
    }, 250);
  };

  SearchBarService.SearchTextChangedEvent(function (text: string) {
    $scope.SearchTextChanged(text);
    $scope.GoodsReceipts.SetSearchText(text);
  });

  $scope.SelectedAddress = $scope.Storage.GetOrCreate("LastSelectedAddress", null);

  $mdPanel.newPanelGroup('contacts', {
    maxOpen: 1
  });

  $scope.ApplyFilter = function () {
    if ($scope.SearchText && $scope.SearchText.length) {
      if ($scope.SelectedAddress) {
        const cleanText = $scope.SearchText.toLowerCase();
        $scope.SelectedAddress.Contacts.filter(f => {
          let isIn = false;
          if (f.Matchcode.toLowerCase().includes(cleanText)) {
            isIn ||= true;
          }
          f.Hide = !isIn;
        });
        $scope.SelectedAddress.ExchangeContacts.filter(f => {
          let isIn = false;
          if (f.DisplayName.toLowerCase().includes(cleanText)) {
            isIn ||= true;
          }
          f.Hide = !isIn;
        });
        $scope.SelectedAddress.ExchangeAssociatedContacts.filter(f => {
          let isIn = false;
          if (f.DisplayName.toLowerCase().includes(cleanText)) {
            isIn ||= true;
          }
          f.Hide = !isIn;
        });
      }
    } else {
      if ($scope.SelectedAddress) {
        $scope.SelectedAddress.Contacts.filter(f => {
          f.Hide = false;
        });
        $scope.SelectedAddress.ExchangeContacts.filter(f => {
          f.Hide = false;
        });
        $scope.SelectedAddress.ExchangeAssociatedContacts.filter(f => {
          f.Hide = false;
        });
      }
    }
  };

  $scope.QueryAddress = function (searchString: string) {
    let result = null;
    if (searchString === null || searchString === '') {
      const addressCount = $scope.Storage.GetOrCreate("SelectedAddressCount", {});
      const filterDate = moment().subtract(14, 'day');
      for (const key in addressCount) {
        if (!addressCount[key].Access) {
          delete addressCount[key];
        }
        addressCount[key].Access = addressCount[key].Access.filter(d => moment(d) > filterDate);
        if (!addressCount[key].Access.length) {
          delete addressCount[key];
        }
      }
      $scope.Storage.Set("SelectedAddressCount", addressCount);
      let arrayobject = Object.keys(addressCount).map(function (key) {
        return addressCount[key];
      });

      arrayobject = arrayobject.filter(x => x.Address?.CachedAddress?.EnvironmentId === mandatorServiceNgx.selectedMandatorIndex);

      if (arrayobject.length) {
        arrayobject = arrayobject.filter(f => f.Access);
        arrayobject.sort(function (first, second) {
          return second.Access.length - first.Access.length;
        });
        arrayobject = arrayobject.map(function (element) {
          return element.Address;
        });
        if (arrayobject.length > 10) {
          return arrayobject.slice(0, 9);
        } else {
          return arrayobject;
        }
      }
    }

    const deferred = $q.defer();
    const request = ApiHandlerService.SendRequest("Erp", "QueryAddress", { groupId: $scope.Id, query: searchString, mandatorId: mandatorServiceNgx.selectedMandatorId }).then(function (data: QueryAddressDto) {
      result = data.Result;
      deferred.resolve(result);
    });
    return deferred.promise;
  };

  $scope.SelectedAddressChanged = function () {
    if ($scope.SelectedAddress !== null) {
      $scope.IsLoading = true;
      $scope.Navigate($scope.Storage.GetOrCreate("ActiveTab", "BaseData"), true);
      const addressCount = $scope.Storage.GetOrCreate("SelectedAddressCount", {});
      if (addressCount[$scope.SelectedAddress.CachedAddress.Id]) {
        addressCount[$scope.SelectedAddress.CachedAddress.Id].Address = $scope.SelectedAddress,
          addressCount[$scope.SelectedAddress.CachedAddress.Id].Access.push(moment());
      } else {
        addressCount[$scope.SelectedAddress.CachedAddress.Id] = {
          Address: $scope.SelectedAddress,
          Access: [moment()]
        };
      }
      $scope.Storage.Set("SelectedAddressCount", addressCount);
      $scope.DocumentEnvironment = null;
      $scope.ReceiptEnvironment = null;
      $scope.Storage.Set("LastSelectedAddress", $scope.SelectedAddress);
      $scope.$broadcast("bindAddress", $scope.SelectedAddress.CachedAddress);
      $scope.SelectedAddress.ContactsLoading = true;
      ApiHandlerService.SendRequest("Erp", "GetExchangeContacts", { addressId: $scope.SelectedAddress.CachedAddress.Id }).then(function (data) {
        $scope.SelectedAddress.ExchangeContacts = data.Contacts;
        $scope.SelectedAddress.ExchangeAssociatedContacts = data.AssociatedContacts;
        $scope.SelectedAddress.ContactsLoading = false;
        $scope.SearchTextChanged(SearchBarService.SearchText);
        $timeout(() => $scope.IsLoading = false);
      });
    }
  };

  $scope.CopyCustomerDataToClipboard = function () {
    let copyText = $scope.SelectedAddress.NameLine1;
    if ($scope.SelectedAddress.NameLine2) {
      copyText += "\r\n" + $scope.SelectedAddress.NameLine2;
    }
    if ($scope.SelectedAddress.Street) {
      copyText += "\r\n" + $scope.SelectedAddress.Street;
    }
    if ($scope.SelectedAddress.PostalCity) {
      copyText += "\r\n" + $scope.SelectedAddress.PostalCity;
    }
    if ($scope.SelectedAddress.VAT) {
      copyText += "\r\n" + $scope.SelectedAddress.VAT;
    }
    if ($scope.SelectedAddress.EMail) {
      copyText += "\r\n" + $scope.SelectedAddress.EMail;
    }
    if ($scope.SelectedAddress.Phone) {
      copyText += "\r\n" + $scope.SelectedAddress.Phone;
    }
    copyToClipboard(copyText);
  };

  $scope.OpenContactDetails = function ($event: any, contact: any) {

    if (contact.ContactId) {
      phoneServiceNgx.showPhoneContactDialog(contact.ContactId);
    }
    else {
      const contactDto: ContactDto = {
        Id: null,
        EnvironmentId: null,
        PortalUniqueIdentifier: null,
        DisplayName: contact.Matchcode,
        Company: null,
        Position: contact.Position,
        HomePage: null,
        Birthday: null,
        AssistantName: null,
        Body: null,
        GivenName: contact.FirstName,
        MiddleName: null,
        LastName: contact.LastName,
        Suffix: null,
        Initials: null,
        Nickname: null,
        Department: contact.Department,
        OfficeLocation: null,
        Profession: null,
        SpouseName: null,
        ErpCachedAddressId: contact.AddressId,

        Address: null,
        MailAddresses: [],
        PhoneNumbers: [],
      };

      if (contact.EMail) {
        const mailDto: ContactMailAddressDto = {
          Id: null,
          ContactId: null,
          SyncLabel: null,
          Label: null,
          Address: contact.EMail,
        };
        contactDto.MailAddresses.push(mailDto);
      }

      if (contact.Mobile) {
        const phoneDto: ContactPhoneNumberDto = {
          Id: null,
          ContactId: null,
          SyncLabel: null,
          Label: null,
          Number: contact.Mobile,
        };
        contactDto.PhoneNumbers.push(phoneDto);
      }

      if (contact.Phone) {
        const phoneDto: ContactPhoneNumberDto = {
          Id: null,
          ContactId: null,
          SyncLabel: null,
          Label: null,
          Number: contact.Phone,
        };
        contactDto.PhoneNumbers.push(phoneDto);
      }

      phoneServiceNgx.showPhoneContactDialog(null, contactDto);
    }
  };


  $scope.PrintLabels = function ($event) {

    var quantity = 1;

    if ($event.shiftKey) {
      quantity = 5;
    }

    ApiHandlerService.SendRequest("Label", "PrintPluginTemplate", {
      plugin: "Erp",
      pluginTemplateId: "AddressLabel",
      paramDict: [
        {
          Key: "Name",
          Value: $scope.SelectedAddress.NameLine1
        }, {
          Key: "NameZusatz",
          Value: $scope.SelectedAddress.NameLine2
        }, {
          Key: "Strasse",
          Value: $scope.SelectedAddress.Street
        }, {
          Key: "PlzOrt",
          Value: $scope.SelectedAddress.PostalCity
        }, {
          Key: "Quantity",
          Value: quantity
        }
      ]
    },
      true,
    ).then(

      function (data) {
        $timeout(function () {
          if (!data.Success) {
            alert("Fehler beim Druck: " + data.Result);
          }
        });
      },

      function (reason) {
        $timeout(function () {
          const toast = $mdToast.simple()
            .textContent("Fehler beim Drucken")
            .position('bottom right');

          $mdToast.show(toast);
        });
      },

    );
  };



  // Init Code
  $timeout($scope.SelectedAddressChanged, 100);
}

interface IErpControllerScope extends IScope, INavigationScope {
  Storage: StorageServiceInstance;
  IsAdminView: any;
  SelectedAddress: any;
  DocumentEnvironment;
  ReceiptEnvironment;
  GoodsReceipts: GoodsReceiptDto;
  SearchText: string;
  FilterTimeout: any;
  Id: any;
  IsLoading: boolean;
  AddressSearchText: string;


  AutocompleteClicked: () => void;
  ChangeView: () => void;
  SearchTextChanged: (text: string) => void;
  ApplyFilter: () => void;
  QueryAddress: (string: string) => void;
  SelectedAddressChanged: () => void;
  CopyCustomerDataToClipboard: () => void;
  OpenContactDetails: ($event: any, contact: any) => void;
  PrintLabels: ($event: any) => void;
}


interface GoodsReceiptDto extends IScope {
  Receipts: ReceiptDto[];
  FilteredCount: number;
  Filter: {
    ShowPrinted: boolean
  };
  Printing: LabelPrintDataDto;
  IsLoading: boolean;
  SearchTimeout: any;
  SearchText: string;
  UnprintedReceiptsCount: number;
  ReceiptsTable: HestiaDataTable;
  SelectedReceipt: ReceiptDto;


  PrintArticleDialog: () => void;
  LoadReceipts: () => void;
  PrinterChanged: () => void;
  ApplyFilter: (text?: string) => void;
  ToggleVisibility: () => void;
  ChangePrintState: (position, state) => void;
  SavePosition: (position) => void;
  PrintReceipt: (receipt, what) => void;
  SetReceiptPrinted: (receipt) => void;
  BulkPrint: () => void;
  SetSearchText: (text) => void;
  ItemDetailsSelected: (model) => void;
  Init: () => void;
}
