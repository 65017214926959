import angular from 'angular';
import { arrayRemove } from 'src/ajs/Utils/HestiaCommon';

export class DragAndDropPanelService {
  static $inject = ['$timeout'];

  constructor($timeout) {
    var dragAndDropSvc = <any>this;

    dragAndDropSvc.CurrentPanels = [];

    dragAndDropSvc.UpdatePositioning = function () {
      let baseIndex = 100 + dragAndDropSvc.CurrentPanels.length;
      let first = true;
      $timeout(function () {
        angular.forEach(dragAndDropSvc.CurrentPanels, function (panel) {
          panel.SetIndex(baseIndex--);
          if (first) {
            panel.AddFocusedStyle();
            first = false;
          } else {
            panel.RemoveFocusedStyle();
          }
        });
      }, true);
    };

    dragAndDropSvc.AddPanel = function (dragAndDropPanel, internal) {
      if (!internal) {
        dragAndDropPanel.RegisterInterceptor(function () {
          dragAndDropSvc.RemovePanel(dragAndDropPanel);
          dragAndDropPanel.ngModel.mdPanelRef.destroy();
        });
      }
      dragAndDropSvc.CurrentPanels.unshift(dragAndDropPanel);
      dragAndDropSvc.UpdatePositioning();
    };

    dragAndDropSvc.RemovePanel = function (dragAndDropPanel) {
      arrayRemove(dragAndDropSvc.CurrentPanels, dragAndDropPanel);
    };

    dragAndDropSvc.MoveToFront = function (dragAndDropPanel) {
      dragAndDropSvc.RemovePanel(dragAndDropPanel);
      dragAndDropSvc.AddPanel(dragAndDropPanel, true);
    };

    dragAndDropSvc.StopAllDragging = function () {
      angular.forEach(dragAndDropSvc.CurrentPanels.filter(f => f.IsDragging), function (panel) {
        panel.StopDragging();
      });
    };

    dragAndDropSvc.PerformAllDragging = function (event) {
      angular.forEach(dragAndDropSvc.CurrentPanels.filter(f => f.IsDragging), function (panel) {
        panel.MouseMove(event);
      });
    };

    document.addEventListener('mouseout', function (e: any) {
      e = e ? e : window.event;
      const from = e.relatedTarget || e['toElement'];
      if (!from || from.nodeName === "HTML") {
        dragAndDropSvc.StopAllDragging();
      }
    }, true);

    document.addEventListener('mouseup', function (event) {
      dragAndDropSvc.StopAllDragging();
      if (event) {
        event.preventDefault();
      }
    }, true);

    document.addEventListener('mousemove', function (event) {
      dragAndDropSvc.PerformAllDragging(event);
    }, true);
    return dragAndDropSvc;
  }


}
