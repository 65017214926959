import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

/**
 * Gibt eine benutzerfreundliche Fehlermeldung für den Validierungsfehler eines FormFields zurück.
 * Wenn es mehrere Validierungsfehler gibt, wird nur einer angezeigt.
 */
@Pipe({ name: 'validationError' })
export class ValidationErrorPipe implements PipeTransform {

  transform(value: ValidationErrors | null): any {
    if (!value) {
      return null;
    }

    if (value.required) {
      return 'Eingabe erforderlich.';
    }
    else if (value.maxlength) {
      return 'Eingabe zu lang.';
    }
    else if (value.minlength) {
      return 'Eingabe zu kurz.';
    }
    else if (value.max) {
      if (value.max.max === 0) {
        return 'Wert muss negativ sein.';
      }
      else if (typeof value.max.max === 'number') {
        return `Wert darf höchstens ${value.max.max.toLocaleString('de-AT')} sein.`;
      }
      return 'Wert zu hoch.';
    }
    else if (value.min) {
      if (value.min.min === 0) {
        return 'Wert muss positiv sein.';
      }
      else if (value.min.min === 1) {
        return 'Wert muss größer Null sein.';
      }
      else if (typeof value.min.min === 'number') {
        return `Wert muss mindestens ${value.min.min.toLocaleString('de-AT')} sein.`;
      }
      return 'Wert zu niedrig.';
    }
    else if (value.email) {
      return 'Keine gültige E-Mail-Adresse.';
    }
    else if (value.phoneNumber) {
      return 'Keine gültige Telefonnummer.';
    }
    else if (value.pattern) {
      return 'Ungültige Eingabe.';
    }
    else if (value.minToday) {
      return 'Datum darf nicht in der Vergangenheit liegen.';
    }
    else if (value.hexColor) {
      return 'Ungültige Farbe.';
    }

    // Fallback
    for (const property in value) {
      return value[property];
    }

    return null;
  }
}
