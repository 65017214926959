export class DateUtils {
  static today(): Date {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  }

  static addDays(date: Date, days: number): Date {
    const result = new Date(date.valueOf());
    result.setDate(result.getDate() + days);
    return result;
  }

  static addMonths(date: Date, months: number): Date {
    const result = new Date(date.valueOf());
    result.setMonth(result.getMonth() + months);
    return result;
  }

  static addHours(date: Date, hours: number): Date {
    const result = new Date(date.valueOf());
    result.setHours(result.getHours() + hours);
    return result;
  }

  static addMinutes(date: Date, minutes: number): Date {
    const result = new Date(date.valueOf());
    result.setMinutes(result.getMinutes() + minutes);
    return result;
  }
}
