import { Observable, of, tap } from "rxjs";
import { ContactDto, fixContactDto } from "src/app/contacts/dto/contact-dto";
import { Api1Service } from "src/app/core/services/api1.service";
import { ContactDto as LegacyContactDto } from "../Models/Contacts/ContactDto";
import { GetAllContactsDto } from "../Models/Contacts/GetAllContactsDto";
import { cleanupPhoneNumber } from "../Utils/HestiaCommon";
import { ApiHandlerService } from "./ApiHandlerService";
import { ServiceLocatorService } from "./ServiceLocatorService";

export class ContactsService {
  static $inject = ['ApiHandlerService', 'Api1ServiceNgx'];

  Hub: any;
  Contacts: LegacyContactDto[] = [];
  PhoneNumberLabels: any = null;
  EmailLabels: any = null;
  PostalLabels: any = null;
  ContactsChangedCallbacks: ((action: string, contact?: LegacyContactDto | number) => void)[] = [];
  ServiceLocator: ServiceLocatorService;
  Disabled: boolean;

  constructor(
    private readonly ApiHandlerService: ApiHandlerService,
    private readonly api1: Api1Service
  ) {
    this.Hub = (<any>$).connection.contactsHub;

    if (this.Hub) {
      this.Hub.client.contactAdded = (contact: LegacyContactDto) => {
        this.Contacts.push(contact);
        this.ContactsChangedCallbacks.forEach((callback) => {
          callback("add", contact);
        });
      };

      this.Hub.client.contactUpdated = (contact: LegacyContactDto) => {
        const update = this.Contacts.filter(c => c.ContactId === contact.ContactId);
        if (update && update.length) {
          const index = this.Contacts.indexOf(update[0]);
          this.Contacts[index] = contact;
        }
        this.ContactsChangedCallbacks.forEach((callback) => {
          callback("update", contact);
        });
      };

      this.Hub.client.contactDeleted = (contactId: number) => {
        this.Contacts = this.Contacts.filter(c => c.ContactId !== contactId);
        this.ContactsChangedCallbacks.forEach((callback) => {
          callback("delete", contactId);
        });
      };
    }
    else {
      console.warn('contactsHub not started.');
    }
  }

  GetContactById(contactId: number) {
    const contact = this.Contacts.filter(f => f.ContactId === contactId);
    if (contact && contact.length) {
      return contact[0];
    }
    return null;
  }

  GetContactsBySearchText(searchText: string) {
    return this.Contacts.filter(f => this.IsEntryIn(f, searchText));
  }


  GetContactForAddressId(addressId: number) {
    return this.Contacts.filter(f => f && f.MainAddress && f.MainAddress.Id === addressId);
  }

  IsEntryIn(f: LegacyContactDto, searchText: string) {
    let isIn = true;
    if (f.IsPublic || f.OwnedBy.filter(x => x === this.ServiceLocator.UserService.Data.User.Id)) {
      if (searchText && searchText.length) {
        const currentSearch = searchText.toLowerCase();
        isIn = false;

        if (f.DisplayName) {
          isIn = isIn || f.DisplayName.toLowerCase().includes(currentSearch);
        }

        if (f.Company) {
          isIn = isIn || f.Company.toLowerCase().includes(currentSearch);
        }

        if (f.Position) {
          isIn = isIn || f.Position.toLowerCase().includes(currentSearch);
        }

        if (f.HomePage) {
          isIn = isIn || f.HomePage.toLowerCase().includes(currentSearch);
        }

        if (f.MainAddress && f.MainAddress.DisplayText) {
          isIn = isIn || f.MainAddress.DisplayText.toLowerCase().includes(currentSearch);
        }

        for (let i = 0; i < f.AssociatedAddresses.length; i++) {
          if (f.AssociatedAddresses[i].DisplayText) {
            isIn = isIn || f.AssociatedAddresses[i].DisplayText.toLowerCase().includes(currentSearch);
          }
          if (f.AssociatedAddresses[i].Text) {
            isIn = isIn || f.AssociatedAddresses[i].Text.toLowerCase().includes(currentSearch);
          }
        }

        for (let i = 0; i < f.PhoneNumbers.length; i++) {
          if (f.PhoneNumbers[i].Number) {
            isIn = isIn || cleanupPhoneNumber(f.PhoneNumbers[i].Number).toLowerCase().includes(currentSearch);
          }
        }

        for (let i = 0; i < f.MailAddresses.length; i++) {
          if (f.MailAddresses[i].Address) {
            isIn = isIn || f.MailAddresses[i].Address.toLowerCase().includes(currentSearch);
          }
        }
      }
    } else {
      isIn = false;
    }
    return isIn;
  }

  ContactsChangedEvent(callback: ((action: string, contact?: LegacyContactDto | number) => void)) {
    let replaced = false;
    for (let i = 0; i < this.ContactsChangedCallbacks.length; i++) {
      if (this.ContactsChangedCallbacks[i].toString() === callback.toString()) {
        this.ContactsChangedCallbacks[i] = callback;
        replaced = true;
      }
    }
    if (!replaced) {
      this.ContactsChangedCallbacks.push(callback);
    }
  }

  FindContactsByNumber(number: string): LegacyContactDto[] {
    return this.Contacts.filter(f => f.PhoneNumbers.filter(n => n.Number === number).length);
  }

  async DumpData() {
    this.Contacts = [];
    this.PhoneNumberLabels = null;
    this.EmailLabels = null;
    this.PostalLabels = null;
    this.ContactsChangedCallbacks.forEach((callback) => {
      callback("reload");
    });
  }

  async LoadData() {
    try {
      const data: GetAllContactsDto = await this.ApiHandlerService.SendRequest("Contacts", "GetMandatorContacts", null, true);
      this.Contacts = data.Contacts;
      this.PhoneNumberLabels = data.PhoneNumberLabels;
      this.EmailLabels = data.EmailLabels;
      this.PostalLabels = data.PostalLabels;
      console.log("ContactsService loaded");
    }
    catch (reason) {
      console.log("Contact Service rejected: " + reason);
    }
  }

  FinalizeData() {
    for (const callback of this.ContactsChangedCallbacks) {
      callback("reload");
    }
  }

  getLastUsedContacts(): Observable<ContactDto[]> {
    return this.api1.get<ContactDto[]>("Contacts", "GetLastUsedContacts").pipe(
      tap(array => array?.forEach(fixContactDto))
    );
  }

  getContactsBySearchText(searchText: string): Observable<ContactDto[]> {
    if (!searchText) {
      return of([]);
    }

    return this.api1.get<ContactDto[]>("Contacts", "GetContactsBySearchText", { searchText: searchText }).pipe(
      tap(array => array?.forEach(fixContactDto))
    );
  }
}


