import * as angular from "angular";
import { material } from "angular";
import { CategoryDto } from "src/ajs/Models/Document/CategoryDto";
import { NumberRangeDto } from "src/ajs/Models/Document/NumberRangeDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { MandatorService } from "src/app/core/services/mandator.service";

export class EditNumberRangeDialogController {
  static $inject = ['$scope', '$mdDialog', 'ApiHandlerService', 'Categories', 'Element', 'OnSuccess', 'MandatorServiceNgx'];

  constructor(
    private readonly $scope: IEditNumberrangeDialogControllerScope,
    private readonly $mdDialog: material.IDialogService,

    private readonly ApiHandlerService: ApiHandlerService,
    Categories: CategoryDto,
    Element: NumberRangeDto,
    OnSuccess: (data: NumberRangeDto) => void,
    private readonly mandatorService: MandatorService
  ) {
    $scope.Title = "Nummernkreis bearbeiten";
    $scope.Model = Element;
    $scope.Categories = Categories;
    $scope.OnSuccess = OnSuccess;

    $scope.CleanPrefixInput = this.CleanPrefixInput.bind(this);
    $scope.Close = this.Close.bind(this);
    $scope.CleanModel = this.CleanModel.bind(this);
    $scope.Submit = this.Submit.bind(this);

    if (!$scope.Model) {
      $scope.Title = "Nummernkreis anlegen";
      $scope.Model = {
        Id: null,
        Description: null,
        Prefix: null,
        FormatLength: 6,
        NextNumber: 1,
        AllowedCategoryIds: [],
        MandatorTitle: null
      } as NumberRangeDto;
    }
  }

  CleanPrefixInput() {
    this.$scope.Model.Prefix = this.$scope.Model.Prefix.toUpperCase().trim();
    this.$scope.Model.Prefix = this.$scope.Model.Prefix.replace(/[^A-Z]/gi, '');
    if (this.$scope.Model.Prefix.length > 4) {
      this.$scope.Model.Prefix = this.$scope.Model.Prefix.substr(0, 4);
    }
  }

  Close() {
    this.$mdDialog.hide();
  }

  CleanModel() {
    for (var i = 0; i < this.$scope.Model.AllowedCategoryIds.length; i++) {
      this.$scope.Model.AllowedCategoryIds[i] = this.$scope.Model.AllowedCategoryIds[i];
    }
    this.$scope.Model.FormatLength = this.$scope.Model.FormatLength;
  }

  Submit() {
    if (!this.$scope.IsSubmiting) {
      this.$scope.IsSubmiting = true;
      this.$scope.CleanModel();
      this.ApiHandlerService.SendRequest("Document", "SaveNumberRange", { model: this.$scope.Model, mandatorId: this.mandatorService.selectedMandatorId }).then(result => {
        this.$scope.OnSuccess(result);
        this.$mdDialog.cancel();
      });
    }
  }
}

interface IEditNumberrangeDialogControllerScope extends angular.IScope {
  Title: string;
  Model: NumberRangeDto;
  Categories: CategoryDto;
  OnSuccess: (data: NumberRangeDto) => void;
  IsSubmiting: boolean;

  CleanPrefixInput: () => void;
  Close: () => void;
  CleanModel: () => void;
  Submit: () => void;

}
