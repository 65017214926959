import { PrinterDto } from "src/ajs/Models/Label/PrinterDto";
import { TemplateDto } from "src/ajs/Models/Label/TemplateDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";

createLabelTestPrintController.$inject = ['$scope', '$mdDialog', 'ApiHandlerService', 'Template', 'Printers'];

export function createLabelTestPrintController(
  $scope,
  $mdDialog,
  ApiHandlerService: ApiHandlerService,
  Template: TemplateDto,
  Printers: PrinterDto[]) {

  $scope.Template = Template;
  $scope.Printers = Printers;
  $scope.SelectedPrinterId = null;

  $scope.AvailablePrinters = $scope.Printers.filter(f => {
    return true;
  });

  if ($scope.AvailablePrinters.length) {
    $scope.SelectedPrinterId = $scope.AvailablePrinters[0].Id;
  }

  $scope.Hide = function () {
    $mdDialog.hide();
  };
  $scope.Cancel = function () {
    $mdDialog.cancel();
  };
  $scope.Ok = function () {
    ApiHandlerService.SendRequest("Label", "PrintTemplate", { templateId: $scope.Template.Id, printerId: $scope.SelectedPrinterId, parameters: [] }).then(function (data) {
      if (data.Success) {
        alert("Druck wurde erfolgreich durchgeführt");
      } else {
        alert("Fehler beim Drucken: " + data.Result);
      }
    });
  };
}
