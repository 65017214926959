<mat-card class="mat-typography">
  <mat-card-title-group>
    <mat-card-title *ngIf="contact?.GivenName || contact?.LastName">{{ contact?.Suffix }} {{ contact?.GivenName }} {{ contact?.LastName }}</mat-card-title>
    <mat-card-title *ngIf="!(contact?.GivenName || contact?.LastName) && contact?.DisplayName">{{ contact?.DisplayName}}</mat-card-title>
    <mat-card-subtitle>{{ contact?.Address?.CachedDisplayName }}</mat-card-subtitle>
  </mat-card-title-group>
  <mat-card-content>
    <div fxLayout="column">
      <div fxLayout="row" class="value-row" *ngIf="contact?.Company">
        <div class="label">Firma:</div>
        <div class="value">{{ contact.Company }}</div>
      </div>

      <div fxLayout="row" class="value-row" *ngIf="contact?.Department">
        <div class="label">Abteilung:</div>
        <div class="value">{{ contact.Department }}</div>
      </div>

      <div fxLayout="row" class="value-row" *ngIf="contact?.Position">
        <div class="label">Position:</div>
        <div class="value">{{ contact.Position }}</div>
      </div>


      <div fxLayout="row" class="mail-phone-row">
        <div fxLayout="column" class="phone-column">
          <div fxLayout="row" *ngFor="let number of contact?.PhoneNumbers; let i=index" class="phone-row">
            <div class="phone-icon">
              <mat-icon *ngIf="i === 0">phone</mat-icon>
              <mat-icon *ngIf="i > 0"></mat-icon>
            </div>

            <div fxLayout="column">
              <div class="phone-number"><a href="{{number.Number| tel}}">{{number.Number}}</a></div>
              <div class="phone-label">{{number.Label}}</div>
            </div>
          </div>
        </div>

        <div fxLayout="column" class="mail-column">
          <div fxLayout="row" *ngFor="let mail of contact?.MailAddresses; let i=index" class="mail-row">
            <div class="mail-icon">
              <mat-icon *ngIf="i === 0">mail</mat-icon>
              <mat-icon *ngIf="i > 0"></mat-icon>
            </div>

            <div fxLayout="column">
              <div class="mail-address"><a href="mailto:{{mail.Address}}"> {{mail.Address}}</a></div>
              <div class="mail-label">{{mail.Label}}</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </mat-card-content>
</mat-card>
