import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { minToday } from "./min-today";
import { phoneNumber } from "./phone-number.validator";
import { hexColor } from "./hex-color.validator";
import { urlNormalizer } from "./url-normalizer";
import { integer } from "./integer.validator";

export class CustomValidators {
  /**
   * Stellt sicher, dass die Eingabe mit http:// oder https:// beginnt.
   * Beim FormControl sollte
   *    updateOn: 'blur'
   * gesetzt werden, damit das Protokoll erst beim Verlassen des Feldes eingefügt wird. */
  static urlNormalizer(control: AbstractControl) {
    return urlNormalizer()(control);
  }

  /** Stellt sicher, dass ein Datumswert größer gleich heute ist. */
  static minToday(control: AbstractControl) {
    return minToday()(control);
  }

  /** Required abhängig von einer Bedingung. */
  static requiredWhen(predicate: boolean) {
    if (predicate) {
      return Validators.required;
    }
    else {
      return Validators.nullValidator;
    }
  }

  /** Stellt sicher, dass die Eingabe eine gültige Telefonnummer ist. */
  static phoneNumber(control: AbstractControl) {
    return phoneNumber()(control);
  }

  /** Stellt sicher, dass die Eingabe eine gültige Hex-Farbe ist (z.B. #ffeedd). */
  static hexColor(control: AbstractControl) {
    return hexColor()(control);
  }

  /** Stellt sicher, dass die Eingabe eine Ganzzahl ist. */
  static integer(control: AbstractControl) {
    return integer()(control);
  }
}
