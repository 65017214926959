import angular from 'angular';
import { firstValueFrom, Subscription } from 'rxjs';
import { SignalRService } from 'src/app/core/services/signalr/signalr.service';
import { PhoneService } from 'src/app/phone/services/phone.service';
import { TaskService } from 'src/app/task/services/task.service';
import { DragAndDropPanel } from '../Directives/DragAndDropPanel';
import { QueryAddressDto } from '../Models/Erp/QueryAddressDto';
import { CallEntryDto } from '../Models/Phone3cx/CallEntryDto';
import { ConnectionDto } from '../Models/Phone3cx/ConnectionDto';
import { TaskDto } from '../Models/Tasks/TaskDto';
import { ThemeDto } from '../Models/ThemeDto';
import { ApiHandlerService } from "../Services/ApiHandlerService";
import { IPanelServiceEx } from '../Services/IPanelServiceEx';
import { PhoneService as PhoneServiceLegacy } from '../Services/PhoneService';
import { MandatorService } from 'src/app/core/services/mandator.service';


createPhone3CXInCallPanelController.$inject = ['$scope', '$mdPanel', 'mdPanelRef', '$timeout', '$q', 'SignalRServiceNgx', 'ApiHandlerService', 'PhoneService', 'theme', 'PhoneServiceNgx', 'TaskServiceNgx', 'MandatorServiceNgx'];

export function createPhone3CXInCallPanelController(
  $scope: IPhone3CXInCallPanelControllerScope,
  $mdPanel: IPanelServiceEx,
  mdPanelRef: angular.material.IPanelRef,
  $timeout: angular.ITimeoutService,
  $q: angular.IQService,
  signalRService: SignalRService,
  ApiHandlerService: ApiHandlerService,
  PhoneServiceLegacy: PhoneServiceLegacy,
  theme: ThemeDto,
  phoneService: PhoneService,
  taskService: TaskService,
  MandatorServiceNgx: MandatorService
) {

  $scope.Theme = {
    Name: theme?.ThemeDescription,
    Definition: theme
  };

  $scope.DragAndDropPanel = new DragAndDropPanel("Anrufdetails", mdPanelRef);
  $scope.CurrentConnection = PhoneServiceLegacy.LastConnectionInfo;
  $scope.Note = { Value: null };

  $scope.Initialize = async () => {

    if ($scope.CurrentConnection) {
      $scope.SelectedCall = await firstValueFrom(phoneService.getCallByChidForInCallPanel($scope.CurrentConnection.Chid));
      $scope.SelectedCallTask = $scope.SelectedCall?.TaskId ?
        (await firstValueFrom(taskService.getTasksById([$scope.SelectedCall.TaskId])))[0] : null;
    }
    if ($scope.SelectedCall) {
      $scope.Note.Value = $scope.SelectedCall.Note;
    }
    $scope.HasNoteChanges = false;
    $scope.AutoUpdateTimeout = null;

  };

  $scope.Initialize();


  $scope.CheckAutoUpdate = function () {
    if ($scope.AutoUpdateTimeout) {
      $timeout.cancel($scope.AutoUpdateTimeout);
      $scope.AutoUpdateTimeout = null;
    }
    if (!$scope.SelectedCall || !$scope.SelectedCall.IsFinished) {
      if ($scope.SelectedCall) {
        PhoneServiceLegacy.UpdateCallTimeStats($scope.SelectedCall);
      }
      $scope.AutoUpdateTimeout = $timeout($scope.CheckAutoUpdate, 1000);
    }
  };
  $scope.$on('$destroy', function () {
    if ($scope.AutoUpdateTimeout) {
      $timeout.cancel($scope.AutoUpdateTimeout);
      $scope.AutoUpdateTimeout = null;
    }
  });

  $scope.CheckAutoUpdate();

  $scope.UpdateConnection = async () => {
    if (PhoneServiceLegacy.LastConnectionInfo && PhoneServiceLegacy.LastConnectionInfo.Chid) {
      $scope.CurrentConnection = PhoneServiceLegacy.LastConnectionInfo;
    }
    if ($scope.CurrentConnection) {
      $scope.SelectedCall = await firstValueFrom(phoneService.getCallByChidForInCallPanel($scope.CurrentConnection.Chid));
      $scope.SelectedCallTask = $scope.SelectedCall?.TaskId ?
        (await firstValueFrom(taskService.getTasksById([$scope.SelectedCall.TaskId])))[0] : null;
      $scope.CheckAutoUpdate();
      $scope.$apply();
    }
  };

  $scope.ActivateAddressEdit = function (call) {
    call.EditContactMain = true;
  };

  $scope.QueryAddress = function (searchString) {
    let result = null;
    const deferred = $q.defer();
    const request = ApiHandlerService.SendRequest("Erp", "QueryAddress", { query: searchString, mandatorId: MandatorServiceNgx.selectedMandatorId }).then(function (data: QueryAddressDto) {
      result = data.Result;
      deferred.resolve(result);
    });
    return deferred.promise;
  };

  $scope.AddressChangedForContact = function (contact, call) {
    let addressId = null;
    if (contact.MainAddress && contact.MainAddress.CachedAddress) {
      addressId = contact.MainAddress.CachedAddress.Id;
      call.EditContactMain = false;
    }
    ApiHandlerService.SendRequest("Contacts", "LinkContactsToCachedAddressMain", { contactIds: [contact.ContactId], cachedAddressId: addressId }).then(function (data) { });
  };
  $scope.OpenAddToTaskPanelMultiple = function (calls, $event) {
    $mdPanel.newPanelGroup("AddToTask", {
      maxOpen: 1
    });
    const target = $event.srcElement || $event.target;
    const config = {
      attachTo: angular.element(document.body),
      templateUrl: '/ClientApp/src/ajs/Views/Tasks/TasksSuggestionWidget.htm',
      controller: "TasksSuggestionWidgetController",
      panelClass: 'window-panel-container',
      bindToController: true,
      locals: {
        SelectedCalls: calls,
        SelectedMails: null,
        tasksChanged: null
      },
      openFrom: $event,
      focusOnOpen: true,
      propagateContainerEvents: true,
      groupName: ["AddToTask"]
    };
    $mdPanel.open(config);
  };
  $scope.OpenAddToTaskPanel = function (call, $event) {
    $mdPanel.newPanelGroup("AddToTask", {
      maxOpen: 1
    });
    const target = $event.srcElement || $event.target;
    const config = {
      attachTo: angular.element(document.body),
      templateUrl: '/ClientApp/src/ajs/Views/Tasks/TasksSuggestionWidget.htm',
      controller: "TasksSuggestionWidgetController",
      panelClass: 'window-panel-container',
      bindToController: true,
      locals: {
        SelectedCalls: [call],
        SelectedMails: null,
        tasksChanged: null
      },
      openFrom: $event,
      focusOnOpen: true,
      propagateContainerEvents: true,
      groupName: ["AddToTask"]
    };
    $mdPanel.open(config);
  };

  $scope.NoteChanged = function () {
    if (!$scope.Note.Value || !$scope.Note.Value.length) {
      $scope.Note.Value = null;
    }
    $scope.HasNoteChanges = $scope.Note.Value !== $scope.SelectedCall.Note;
  };
  $scope.SaveNote = function () {
    ApiHandlerService.SendRequest("Phone3cx", "SaveCallNote", { callId: $scope.SelectedCall.CallId, note: $scope.Note.Value }).then(function (data) {
      $scope.SelectedCall.Note = $scope.Note.Value;
      $scope.HasNoteChanges = false;
    });
  };
  PhoneServiceLegacy.LastConnectionChangedEvent($scope.UpdateConnection);

  $scope.callOperationSubscription = signalRService.callOperation.subscribe(ev => {
    $timeout(async function () {
      if ($scope.SelectedCall && ev.callId === $scope.SelectedCall.CallId) {
        $scope.SelectedCall = (await phoneService.getCallsByIdLegacy([$scope.SelectedCall.CallId]))[0];
        $scope.SelectedCallTask = $scope.SelectedCall?.TaskId ?
          (await firstValueFrom(taskService.getTasksById([$scope.SelectedCall.TaskId])))[0] : null;
      }
    });
  });

  $scope.$on('$destroy', function destroy() {
    $scope.callOperationSubscription?.unsubscribe();
    $scope.callOperationSubscription = null;
  });
}


interface IPhone3CXInCallPanelControllerScope extends angular.IScope {
  DragAndDropPanel: DragAndDropPanel;
  CurrentConnection: ConnectionDto;
  Note: { Value: string };
  SelectedCall: CallEntryDto;
  SelectedCallTask: TaskDto;
  HasNoteChanges: boolean;
  AutoUpdateTimeout: any;
  Theme: { Name: string, Definition: ThemeDto };
  callOperationSubscription: Subscription;

  CheckAutoUpdate: () => void;
  UpdateConnection: () => void;
  ActivateAddressEdit: (call: CallEntryDto) => void;
  QueryAddress: (searchString: string) => angular.IPromise<unknown>;
  AddressChangedForContact: (contact, call: CallEntryDto) => void;
  OpenAddToTaskPanelMultiple: (calls: CallEntryDto[], $event: any) => void;
  OpenAddToTaskPanel: (call: CallEntryDto, $event: any) => void;
  NoteChanged: () => void;
  SaveNote: () => void;
  CallsChanged: (operation: 'add' | 'remove' | 'update' | 'reload', call?: CallEntryDto) => void;
  Initialize: () => void;

}
