import { UserDto } from "src/app/core/dto/user-dto";

export interface NotificationDto {
  id: number;
  createdUtc: Date;
  createdByUserId: string | null;
  createdByUser: UserDto;
  userId: string;
  user: UserDto;
  title: string;
  url: string;
  icon: string;
  color: string;
  readUtc: Date;
}

export function fixNotificationDto(dto: NotificationDto) {
  if (dto) {
    dto.createdUtc = new Date(dto.createdUtc);

    if (dto.readUtc) {
      dto.readUtc = new Date(dto.readUtc);
    }
  }

}
