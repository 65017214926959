import angular from "angular";
import { ServiceLocatorService } from "./ServiceLocatorService";

export class MonitoringService {
  static $inject = [];

  ReadyChangesCallback: (() => void)[] = [];
  StructuralChangesCallbacks: any[] = [];
  HostChangesCallbacks: ((hostEntry) => void)[] = [];
  WorkerChangesCallbacks: ((workerEntry) => void)[] = [];

  Hub: any;
  ServiceLocatorService: ServiceLocatorService;

  constructor() {

    this.Hub = (<any>$).connection.monitoringHub;
    if (this.Hub?.client) {
      this.Hub.client.structuralChanges = () => {
        this.StructuralChangesCallbacks.forEach((callback) => {
          callback();
        });
      };

      this.Hub.client.hostChanges = (hostEntry: any) => {
        this.HostChangesCallbacks.forEach((callback) => {
          callback(hostEntry);
        });
      };

      this.Hub.client.workerChanges = (workerEntry: any) => {
        this.WorkerChangesCallbacks.forEach((callback) => {
          callback(workerEntry);
        });
      };
    }
    else {
      console.warn('monitoringHub not started.');
    }
  }

  RegisterBaseMandator(id: string) {
    this.Hub.server.registerBaseMandator(id);
  }

  UnregisterBaseMandator(id: string) {
    this.Hub.server.unregisterBaseMandator(id);
  }

  RegisterFolderWatch(id: string) {
    this.Hub.server.registerFolderWatch(id);
  }

  UnregisterFolderWatch(id: string) {
    this.Hub.server.unregisterFolderWatch(id);
  }

  StructuralChangesEvent(callback: any) {
    var replaced = false;
    for (var i = 0; i < this.StructuralChangesCallbacks.length; i++) {
      if (this.StructuralChangesCallbacks[i].toString() === callback.toString()) {
        this.StructuralChangesCallbacks[i] = callback;
        replaced = true;
      }
    }
    if (!replaced) {
      this.StructuralChangesCallbacks.push(callback);
    }
  }

  ReadyChangesEvent(callback: () => void) {
    if (this.ServiceLocatorService.SignalRService.Started) {
      callback();
    }
    else {
      var replaced = false;
      for (var i = 0; i < this.ReadyChangesCallback.length; i++) {
        if (this.ReadyChangesCallback[i].toString() === callback.toString()) {
          this.ReadyChangesCallback[i] = callback;
          replaced = true;
        }
      }
      if (!replaced) {
        this.ReadyChangesCallback.push(callback);
      }
    }
  }

  HostChangesEvent(callback: (hostEntry) => void) {
    var replaced = false;
    for (var i = 0; i < this.HostChangesCallbacks.length; i++) {
      if (this.HostChangesCallbacks[i].toString() === callback.toString()) {
        this.HostChangesCallbacks[i] = callback;
        replaced = true;
      }
    }
    if (!replaced) {
      this.HostChangesCallbacks.push(callback);
    }
  }

  WorkerChangesEvent(callback: (workerEntry) => void) {
    var replaced = false;
    for (var i = 0; i < this.WorkerChangesCallbacks.length; i++) {
      if (this.WorkerChangesCallbacks[i].toString() === callback.toString()) {
        this.WorkerChangesCallbacks[i] = callback;
        replaced = true;
      }
    }
    if (!replaced) {
      this.WorkerChangesCallbacks.push(callback);
    }
  }

  InitService() {
    this.ServiceLocatorService.SignalRService.StartedEvent(() => {
      this.ReadyChangesCallback.forEach(f => {
        f();
      });
      this.ReadyChangesCallback = [];
    });
  }

}
