import { fixDate } from "src/ajs/Utils/DateUtils";

export interface TasksReceiptListDto {
  TaskReceiptId: number;
  Name: string;
  Address: string;
  AccountingPeriodEnd: Date;
  AccountedTime: number;
  TimeEntryDuration: number;
  TaskCallDuration: number;
  OtherCallDuration: number;
  TotalTimeDuration: number;
}

export function fixTasksReceiptListDto(dto: TasksReceiptListDto) {

  if (dto.AccountingPeriodEnd) {
    dto.AccountingPeriodEnd = fixDate(dto.AccountingPeriodEnd);
  }
}
