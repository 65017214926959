import { Component } from '@angular/core';
import { MandatorService } from '../../services/mandator.service';

@Component({
  selector: 'app-mandator-selector',
  templateUrl: './mandator-selector.component.html',
  styleUrls: ['./mandator-selector.component.scss']
})
export class MandatorSelectorComponent {

  constructor(
    public readonly mandatorService: MandatorService
  ) {
  }
}
