<div class="main-container">

  <div class="toolbar">

    <div>
      <div class="header">Ausgewählter Stichtag:</div>
      <mat-form-field appearance="outline" class="remove-hint">
        <mat-label>Datum Auswählen</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="filterDate" [max]="today">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <div>
      <button mat-stroked-button (click)="columnEditor.showEditor()">
        <mat-icon>view_column</mat-icon> Spalten
      </button>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="scroll" fxFlex>
    <table
      mat-table
      class="axs-table dense task-table"
      [dataSource]="dataSource"
      matSort
      [matSortActive]="sortService.active"
      [matSortDirection]="sortService.direction"
      (matSortChange)="sortService.change($event)"
      matSortDisableClear>

      <ng-container matColumnDef="checkbox">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox [checked]="anyChecked" (change)="toggleAllChecked($event)"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox [checked]="row.IsChecked" (change)="toggleChecked(row)"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Addresse/Kunde </th>
        <td mat-cell *matCellDef="let row" [appCellLink]="'/task-receipt/edit/?addressId=' + row.TaskTime.AddressId + '&settlementDate=' + filterDate.value.valueOf()"> {{row.TaskTime.Address}} </td>
      </ng-container>

      <ng-container matColumnDef="totalTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Gesamtzeit </th>
        <td mat-cell *matCellDef="let row" [appCellLink]="'/task-receipt/edit/?addressId=' + row.TaskTime.AddressId + '&settlementDate=' + filterDate.value.valueOf()">
          {{calculateTotalCallDuration(row.TaskTime) |duration }}</td>
      </ng-container>

      <ng-container matColumnDef="timeEntries">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Zeiteinträge </th>
        <td mat-cell *matCellDef="let row" [appCellLink]="'/task-receipt/edit/?addressId=' + row.TaskTime.AddressId + '&settlementDate=' + filterDate.value.valueOf()"> {{row.TaskTime.TimeEntryDuration
          |
          duration}} </td>
      </ng-container>

      <ng-container matColumnDef="taskCalls">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Taskanrufe </th>
        <td mat-cell *matCellDef="let row" [appCellLink]="'/task-receipt/edit/?addressId=' + row.TaskTime.AddressId + '&settlementDate=' + filterDate.value.valueOf()"> {{row.TaskTime.TaskCallDuration|
          duration}} </td>
      </ng-container>

      <ng-container matColumnDef="otherCalls">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Andere Anrufe </th>
        <td mat-cell *matCellDef="let row" [appCellLink]="'/task-receipt/edit/?addressId=' + row.TaskTime.AddressId + '&settlementDate=' + filterDate.value.valueOf()">
          {{row.TaskTime.OtherCallDuration|
          duration}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnEditor.displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: columnEditor.displayedColumns;"></tr>
    </table>

    <app-table-nodata-indicator [noData]="dataSource?.data.length === 0"></app-table-nodata-indicator>

    <app-loading-overlay [isLoading]="isLoading" overlay="light"></app-loading-overlay>
  </div>

  <mat-divider></mat-divider>

  <div class="toolbar">
    <button type="button" mat-raised-button color="primary" [matMenuTriggerFor]="actionMenu" [disabled]="!anyChecked">Aktionen</button>

    <mat-menu #actionMenu="matMenu">
      <button mat-menu-item (click)="doNotAccount()">Als nicht verrechenbar abschließen</button>

    </mat-menu>
  </div>
</div>