import { IPromise, IQService, IScope, ITimeoutService, material } from "angular";
import { AddressResultDto } from "src/ajs/Models/Contracts/AddressResultDto";
import { CategoryDto } from "src/ajs/Models/Document/CategoryDto";
import { GetDocumentsEnvironmentDto } from "src/ajs/Models/Document/GetDocumentsEnvironmentDto";
import { LabelPrintPresetDto } from "src/ajs/Models/Document/LabelPrintPresetDto";
import { NumberRangeDto } from "src/ajs/Models/Document/NumberRangeDto";
import { QueryAddressDto } from "src/ajs/Models/Erp/QueryAddressDto";
import { LabelPrintDataDto } from "src/ajs/Models/Label/LabelPrintDataDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { StorageService, StorageServiceInstance } from "src/ajs/Services/StorageService";
import { UserService } from "src/ajs/Services/UserService";
import { MandatorService } from "src/app/core/services/mandator.service";

export class PrintLabelViewController {

  static $inject = ['$scope', '$mdDialog', '$mdToast', '$q', '$timeout', 'ApiHandlerService', 'StorageService', 'UserService', 'MandatorServiceNgx'];
  constructor(
    private readonly $scope: IPrintLabelViewControllerScope,
    private readonly $mdDialog: material.IDialogService,
    private readonly $mdToast: material.IToastService,
    private readonly $q: IQService,
    private readonly $timeout: ITimeoutService,
    private readonly ApiHandlerService: ApiHandlerService,
    StorageService: StorageService,

    UserService: UserService,
    private readonly mandatorServiceNgx: MandatorService
  ) {
    UserService.IsMinimalistic = true;
    $scope.Title = "Dokument-Etiketten drucken";
    $scope.SelectedPreset = null;
    $scope.Storage = StorageService.CreateInstance("PrintDocumentLabelDialogController");
    $scope.SelectedCategoryId = null;

    // New Code
    $scope.FilteredPresets = [];
    $scope.SelectedCategoryView = null;

    $scope.LoadData = this.LoadData.bind(this);
    $scope.SetPrintQuantity = this.SetPrintQuantity.bind(this);
    $scope.NavigateBack = this.NavigateBack.bind(this);
    $scope.SelectCategory = this.SelectCategory.bind(this);
    $scope.Close = this.Close.bind(this);
    $scope.SelectedPrinterChanged = this.SelectedPrinterChanged.bind(this);
    $scope.ValidateForm = this.ValidateForm.bind(this);
    $scope.SearchAddress = this.SearchAddress.bind(this);
    $scope.SelectPreset = this.SelectPreset.bind(this);
    $scope.PrintLabels = this.PrintLabels.bind(this);
  }

  SetPrintQuantity(quantity: number) {
    this.$scope.Printing.PrintQuantity = quantity;
  }

  NavigateBack() {
    this.$scope.SelectedCategoryView = null;
  }

  SelectCategory(category: CategoryDto) {
    this.$scope.SelectedCategoryView = category;
    if (this.$scope.SelectedCategoryView) {
      this.$scope.FilteredPresets = this.$scope.Presets.filter(f => f.DocumentsCategoryId === this.$scope.SelectedCategoryView.Id);
    }
  }

  LoadData() {
    // ALSO NEW CODE
    this.$scope.SelectedCategoryView = null;
    //-----
    this.ApiHandlerService.SendRequest("Document", "GetDocumentsEnvironment", { ignoreEntries: true }).then(data => {
      this.$scope.Model = data;
      this.$scope.Model.Categories.forEach(cat => {
        cat.Columns.forEach(col => {
          col.Column = this.$scope.Model.CustomColumns.filter(f => f.Id === col.ColumnId)[0];
        });
      });
      this.$scope.Printing = this.$scope.Model.Printing;
      this.$scope.Presets = this.$scope.Model.LabelPrintPresets;
      this.$scope.Ranges = this.$scope.Model.NumberRanges;
      this.$scope.Categories = this.$scope.Model.Categories;
      this.$scope.Printing.PrintQuantity = 1;
      this.$scope.SelectedPreset = null;
      var currentId = null;
      if (this.$scope.Printing.Printers.length) {
        currentId = this.$scope.Printing.Printers[0].Id;
      }
      this.$scope.Printing.SelectedPrinterId = this.$scope.Storage.GetOrCreate("SelectedPrinterId", currentId);
      this.$scope.IsLoading = false;
    });
  }

  Close() {
    this.$mdDialog.hide();
  }

  SelectedPrinterChanged() {
    this.$scope.Storage.Set("SelectedPrinterId", this.$scope.Printing.SelectedPrinterId);
  }

  ValidateForm() {
    this.$timeout(() => {
      this.$scope.PrintDocumentLabelDialogForm.$$controls.forEach(c => {
        c.$setDirty();
        c.$setTouched();
        c.$validate();
      });
    });
  }

  SearchAddress(query: string): IPromise<AddressResultDto[]> {
    const result: AddressResultDto[] = [];
    const deferred = this.$q.defer<AddressResultDto[]>();
    const request = this.ApiHandlerService.SendRequest("Erp", "QueryAddress", { query: query, mandatorId: this.mandatorServiceNgx.selectedMandatorId }).then((data: QueryAddressDto) => {
      data.Result.forEach(f => {
        result.push(f.CachedAddress);
      });
      deferred.resolve(result);
    });
    return deferred.promise;
  }

  SelectPreset(preset: LabelPrintPresetDto) {
    this.$scope.SelectedPreset = preset;
    this.$scope.Printing.SourceAddress = preset.SourceAddress;
    this.$scope.Printing.TargetAddress = preset.TargetAddress;
    this.$scope.SelectedCategoryId = preset.DocumentsCategoryId;
    var range = this.$scope.Ranges.filter(f => f.Id === this.$scope.SelectedPreset.DocumentsNumberRangeId)[0];
    this.$scope.AllowedCategories = [];
    range.AllowedCategoryIds.forEach(id => {
      this.$scope.AllowedCategories.push(this.$scope.Categories.filter(f => f.Id === id)[0]);
    });
    this.$scope.ValidateForm();
  }

  PrintLabels() {
    if (!this.$scope.IsPrinting) {
      this.$scope.IsPrinting = true;
      this.ApiHandlerService.SendRequest("Document", "PrintLabels",
        {
          quantity: this.$scope.Printing.PrintQuantity,
          printerId: this.$scope.Printing.SelectedPrinterId,
          rangeId: this.$scope.SelectedPreset.DocumentsNumberRangeId,
          source: this.$scope.Printing.SourceAddress,
          target: this.$scope.Printing.TargetAddress,
          category: this.$scope.Categories.filter(f => f.Id === this.$scope.SelectedCategoryId)[0],
          mandatorId: this.mandatorServiceNgx.selectedMandatorId
        }).then(data => {
          this.$scope.IsPrinting = false;
          var toast = this.$mdToast.simple()
            .textContent("Etiketten wurden gedruckt")
            .position('bottom left')
            .capsule(true);
          this.$mdToast.show(toast);
        });
    }
  }

}

interface IPrintLabelViewControllerScope extends IScope {
  Title: string;
  Ranges: NumberRangeDto[];
  SelectedPreset: LabelPrintPresetDto;
  Storage: StorageServiceInstance;
  SelectedCategoryId: number;
  FilteredPresets: LabelPrintPresetDto[];
  SelectedCategoryView: CategoryDto;
  IsPrinting: boolean;
  Printing: LabelPrintDataDto;
  Categories: CategoryDto[];
  AllowedCategories: CategoryDto[];
  PrintDocumentLabelDialogForm: any;
  IsLoading: boolean;
  Model: GetDocumentsEnvironmentDto;
  Presets: LabelPrintPresetDto[];

  LoadData: () => void;
  SetPrintQuantity: (quantity: number) => void;
  NavigateBack: () => void;
  SelectCategory: (category: CategoryDto) => void;
  Close: () => void;
  SelectedPrinterChanged: () => void;
  ValidateForm: () => void;
  SearchAddress: (query: string) => IPromise<AddressResultDto[]>;
  SelectPreset: (preset: LabelPrintPresetDto) => void;
  PrintLabels: () => void;
}
