<mat-tab-group id="tabbed-selector"
  [selectedIndex]="mandatorService.selectedMandatorIndex"
  (selectedIndexChange)="mandatorService.selectMandatorIndex($event)">
  <mat-tab *ngFor="let m of mandatorService.mandators" [label]="m.description">
  </mat-tab>
</mat-tab-group>

<button mat-button id="dropdown-selector" [matMenuTriggerFor]="dropdownMenu">
  {{ mandatorService.selectedMandator?.description }}
  <mat-icon>arrow_drop_down</mat-icon>
</button>

<mat-menu #dropdownMenu>
  <button mat-menu-item *ngFor="let m of mandatorService.mandators" (click)="mandatorService.selectMandator(m.id)">{{ m.description }}</button>
</mat-menu>