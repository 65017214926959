import angular from "angular";

createWebPreviewDirective.$inject = ['$timeout'];


export function createWebPreviewDirective($timeout: angular.ITimeoutService) {
  return {
    require: '?sourceUrl',

    scope: {
      sourceUrl: '@',
      sourceWidth: '@',
      sourceHeight: '@',
      noClick: '@'
    },

    calculateFrameStyle: function (scope, $timeout: angular.ITimeoutService) {
      const previewWidth = scope.element.width();
      const previewHeight = scope.element.height();
      const contentWidth = parseInt(scope.sourceWidth);
      const contentHeight = parseInt(scope.sourceHeight);
      const xRatio = previewWidth / contentWidth;
      const yRatio = previewHeight / contentHeight;
      const ratio = Math.min(xRatio, yRatio);

      const marginBottom = previewHeight - contentHeight;
      const marginRight = previewWidth - contentWidth;

      scope.frame.css(
        {
          'width': contentWidth,
          'height': contentHeight,
          'transform': 'scale(' + ratio + ')',
          'margin-bottom': marginBottom,
          'margin-right': marginRight,
          'transform-origin': '0 0'
        });
      $timeout(function () {
        if (ratio !== 0) {
          if (xRatio !== yRatio) {
            const frameHeight = scope.frame.height() * ratio;
            const frameWidth = scope.frame.width() * ratio;
            if (ratio === xRatio) {

              const offsetTop = (previewHeight - frameHeight) / 2;
              scope.frame.css(
                {
                  'margin-top': offsetTop,
                  'margin-left': 0
                }
              );
            } else {

              const offsetLeft = (previewWidth - frameWidth) / 2;
              scope.frame.css(
                {
                  'margin-left': offsetLeft,
                  'margin-top': 0
                });
            }
          }
        }
      }, 100);

    },

    link: function (scope, element: angular.IAugmentedJQuery, attrs: angular.IAttributes) {
      scope.element = element;
      element.css('position', 'relative');
      element.css('overflow', 'hidden');
      element.append("<div style='width: 100%; height:100%; background-color:transparent; z-index:10; position:absolute;'></div>");
      element.append('<iframe style="position: relative;" src="' + scope.sourceUrl + '" frameborder="0"></iframe>');
      scope.frame = element.find("iframe");
      scope.overlay = element.find("div");
      scope.overlay.click(function (e) {
        if (!scope.noClick) {
          e.preventDefault();
          e.stopImmediatePropagation();
          const win = window.open(scope.sourceUrl, '_blank');
          win.focus();
        }
      });
      this.calculateFrameStyle(scope, $timeout);
      // TODO: Find better solution
      //onResize(element[0], this.calculateFrameStyle.bind(this, scope, $timeout));
    }
  };
}


