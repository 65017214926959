import angular from 'angular';
import { Role } from 'src/app/core/constants';
import { UserService } from 'src/app/core/services/user.service';
import app from './HestiaApp.module';
import { ThemeDto } from './Models/ThemeDto';

const redirectLogin = ['$q', 'UserServiceNgx', '$location', function ($q: angular.IQService, userService: UserService, $location: angular.ILocationService) {
  if (userService.isLoggedIn) {
    // Benutzer ist angemeldet, es muss nichts gemacht werden.
    return undefined;
  }
  else {
    // Benutzer auf Login-Weiterleiten; anschließend die Url wieder aufrufen
    return '/Login?redirect=' + encodeURIComponent($location.path());
  }
}];

const requireAnyRole = function (roles: string[]): angular.Injectable<(...deps: any[]) => angular.IPromise<string | undefined> | string | undefined> | undefined {
  return ['UserServiceNgx', function (userService: UserService, $location: angular.ILocationService) {

    if (!userService.isLoggedIn) {
      return '/Login?redirect=' + encodeURIComponent($location.path());
    }

    if (!roles.some(x => userService.hasRoleAnyMandator(x))) {
      return '/Unauthorized';
    }

    return undefined;
  }];
};

app.config(['$routeProvider', 'theme', function ($routeProvider: angular.route.IRouteProvider, theme: ThemeDto) {

  $routeProvider
    .when("/", { redirectTo: theme.LandingPagePath })
    .when("/Login", { template: '<app-login layout="column" flex="100"></app-login>' })
    .when("/Dashboard", { template: '<ajs-dashboard layout="column" flex="100"></ajs-dashboard>' })
    .when("/PublicCalendar", { template: '<ajs-public-calendar layout="column" flex="100"></ajs-public-calendar>' })
    .when("/PublicPhonebook", { template: '<ajs-public-phonebook layout="column" flex="100"></ajs-public-phonebook>' })
    .when("/PublicHolidays", { template: '<ajs-public-holidays layout="column" flex="100"></ajs-public-holidays>' })
    .when("/Unauthorized", { template: '<ajs-unauthorized  layout="column" flex="100"></ajs-unauthorized>' })
    .when("/ServerError", { template: '<ajs-server-error layout="column" flex="100"></ajs-server-error>' })
    .when("/Profile", { template: '<ajs-profile layout="column" flex="100"></ajs-profile>' })
    .when("/Contacts", { template: '<ajs-contacts layout="column" flex="100"></ajs-contacts>' })
    .when("/Phone3CX", { template: '<ajs-phone3cx layout="column" flex="100"></ajs-phone3cx>' })

    .when("/InformationHub", { templateUrl: "/ClientApp/src/ajs/Views/InformationHub/InformationHub.htm", controller: 'InformationHubController' })
    .when("/Monitoring", { templateUrl: "/ClientApp/src/ajs/Views/Monitoring/Monitoring.htm", controller: 'MonitoringController' })
    .when("/Erp", { templateUrl: "/ClientApp/src/ajs/Views/Erp/Erp.htm", controller: 'ErpController' })
    .when("/Tasks", { templateUrl: "/ClientApp/src/ajs/Views/Tasks/Tasks.htm", controller: 'TasksController' })
    .when("/Contracts", { templateUrl: "/ClientApp/src/ajs/Views/Contracts/Contracts.htm", })

    .when("/SystemSearch", { templateUrl: "/ClientApp/src/ajs/Views/aXsSystemSearch/aXsSystemSearch.htm", controller: "aXsSystemSearchController" })
    .when("/DocumentManager", { templateUrl: "/ClientApp/src/ajs/Views/Documents/Documents.htm", controller: "DocumentsController" })
    .when("/Administration", { templateUrl: "/ClientApp/src/ajs/Views/Administration/Administration.htm", controller: 'AdministrationController' })

    .when("/Systembuilder", { templateUrl: "/ClientApp/src/ajs/Views/Systembuilder/Systembuilder.htm", controller: 'SystembuilderController' })
    .when("/Label", { templateUrl: "/ClientApp/src/ajs/Views/Label/Label.htm", controller: 'LabelController' })
    .when("/PriceList", { templateUrl: "/ClientApp/src/ajs/Views/PriceList/PriceList.htm", controller: 'PriceListController' })
    .when("/Document/PrintLabelUi", { templateUrl: "/ClientApp/src/ajs/Views/Documents/Documents.htm", controller: "DocumentsController" })

    // Acquisitions
    .when('/acquisitions', { template: '<app-acquisition-list class="mat-typography"></app-acquisition-list>', resolveRedirectTo: redirectLogin })
    .when('/acquisitions/create', { template: '<app-acquisition-create class="mat-typography"></app-acquisition-create>', resolveRedirectTo: redirectLogin })
    .when('/acquisitions/edit/:id', { template: '<app-acquisition-edit class="mat-typography"></app-acquisition-edit>', resolveRedirectTo: redirectLogin })
    .when('/acquisitions/tags', { template: '<app-acquisition-tag-list class="mat-typography"></app-acquisition-tag-list>', resolveRedirectTo: requireAnyRole([Role.CoreAdmin]) })

    // Contractors
    .when('/contractors', { template: '<app-contractor-list class="mat-typography"></app-contractor-list>', resolveRedirectTo: redirectLogin })
    .when('/contractors/edit/:id', { template: '<app-contractor-edit class="mat-typography"></app-contractor-edit>', resolveRedirectTo: redirectLogin })
    .when('/contractors/create', { template: '<app-contractor-edit class="mat-typography"></app-contractor-edit>', resolveRedirectTo: redirectLogin })

    // Projects
    .when('/projects', { template: '<app-project-list class="mat-typography"></app-project-list>', resolveRedirectTo: redirectLogin })
    .when('/projects/create', { template: '<app-project-edit class="mat-typography"></app-project-edit>', resolveRedirectTo: redirectLogin })
    .when('/projects/edit/:id', { template: '<app-project-edit class="mat-typography"></app-project-edit>', resolveRedirectTo: redirectLogin })

    // PublicCalendar
    .when('/public-calendar/approve-sharing/:token', { template: '<app-approve-sharing class="mat-typography"></app-approve-sharing>' })

    // Administration
    .when('/administration/user-roles', { template: '<app-user-roles class="mat-typography"></app-user-roles>', resolveRedirectTo: requireAnyRole([Role.CoreAdmin]) })
    .when('/administration', { template: '<app-admin-menu class="mat-typography"></app-admin-menu>', resolveRedirectTo: requireAnyRole([Role.CoreAdmin]) })
    .when('/administration/project-types', { template: '<app-project-type-list class="mat-typography"></app-project-type-list>', resolveRedirectTo: requireAnyRole([Role.CoreAdmin]) })
    .when('/administration/project-manager', { template: '<app-project-manager-list class="mat-typography"></app-project-manager-list>', resolveRedirectTo: requireAnyRole([Role.CoreAdmin]) })
    .when('/administration/document-tags', { template: '<app-document-tag-list class="mat-typography"></app-document-tag-list>', resolveRedirectTo: requireAnyRole([Role.CoreAdmin]) })
    .when('/administration/contractor-tags', { template: '<app-contractor-tag-list class="mat-typography"></app-contractor-tag-list>', resolveRedirectTo: requireAnyRole([Role.CoreAdmin]) })
    .when('/administration/document-categories', { template: '<app-document-category-list class="mat-typography"></app-document-category-list>', resolveRedirectTo: requireAnyRole([Role.CoreAdmin]) })
    // Documents
    .when('/documents', { template: '<app-document-list class="mat-typography"></app-document-list>', resolveRedirectTo: redirectLogin })
    .when('/documents/create', { template: '<app-document-edit class="mat-typography"></app-document-edit>', resolveRedirectTo: redirectLogin })
    .when('/documents/edit/:id', { template: '<app-document-edit class="mat-typography"></app-document-edit>', resolveRedirectTo: redirectLogin })

    // Task-Receipt-Edit
    .when('/task-receipt/edit', { template: '<app-task-receipt-edit class="mat-typography"></app-task-receipt-edit>' })

    // Notifications
    .when('/notifications', { template: '<app-notification-list class="mat-typography"></app-notification-list>' })
    .when('/notifications/settings', { template: '<app-notification-settings class="mat-typography"></app-notification-settings>' })

    // Todos
    .when('/todos', { template: '<app-todo-list class="mat-typography"></app-todo-list>', resolveRedirectTo: redirectLogin })

    //Timeline
    .when('/timeline', { template: '<app-timeline class="mat-typography"></app-timeline>', resolveRedirectTo: redirectLogin })
    .when('/timeline/list', { template: '<app-timeline-project-list class="mat-typography"></app-timeline-project-list>', resolveRedirectTo: redirectLogin })
    .when('/timeline/edit/:id', { template: '<app-timeline-project-edit class="mat-typography"></app-timeline-project-edit>', resolveRedirectTo: redirectLogin })

    .otherwise({ templateUrl: "/ClientApp/src/ajs/Views/NotFound.htm" });
}]);
