
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { combineLatest, firstValueFrom, forkJoin, map, Observable, of, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { PriorityDto } from 'src/ajs/Models/Tasks/PriorityDto';
import { ContactDto } from 'src/app/contacts/dto/contact-dto';
import { LegacyUserDto } from 'src/app/core/dto/legacy-user-dto';
import { UserService } from 'src/app/core/services/user.service';
import { ErpCachedAddressDto } from 'src/app/erp/dto/erp-cached-address-dto-legacy';
import { Phone3CxCallDto } from 'src/app/phone/dto/phone-3cx-call-dto';
import { PhoneService } from 'src/app/phone/services/phone.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ArrayUtils } from 'src/app/shared/utils/array-utils';
import { TaskEntryDto } from '../../dto/task-entry-dto';
import { TaskEntrySaveRequestDto } from '../../dto/task-entry-save-request-dto';
import { TaskNoteDto } from '../../dto/task-note-dto';
import { TaskPriorityDto } from '../../dto/task-priority-dto';
import { TaskQueueDto } from '../../dto/task-queue-dto';
import { TaskStateDto } from '../../dto/task-state-dto';
import { TaskTimeEntryDto } from '../../dto/task-time-entry-dto';
import { TaskService } from '../../services/task.service';
import { TaskNoteVm } from './task-note-vm';
import { TaskQueueVm } from './task-queue-vm';
import { MandatorService } from 'src/app/core/services/mandator.service';

@Component({
  selector: 'app-task-edit',
  templateUrl: './task-edit.component.html',
  styleUrls: ['./task-edit.component.scss']
})
export class TaskEditComponent implements OnInit, OnDestroy {

  private _taskId = 0;
  get taskId() {
    return this._taskId;
  }
  @Input() set taskId(value: number) {
    if (this._taskId !== value) {
      this.changeTaskConfirmation().then(() => {
        this._taskId = value;
        this.refresh$.next();
      });
    }
  }

  private _callIds = [];
  get callIds() {
    return this._callIds;
  }

  @Input() set callIds(array: number[]) {
    if (this._callIds !== array) {
      this._callIds = array;
    }
  }

  @Input() isDialog: boolean;

  get isNew() {
    return !this.taskId;
  }

  @Output() readonly closeRequest = new EventEmitter<void>();
  @Output() readonly refreshRequest = new EventEmitter<void>();
  private readonly refresh$ = new Subject<void>();
  private readonly destroy$ = new Subject<void>();
  isLoadingTask = false;
  isLoadingCalls = false;
  isEditing = true;
  task: TaskEntryDto;
  taskQueues: TaskQueueDto[] = [];
  taskQueueVms: TaskQueueVm[] = [];
  taskPriorieties: TaskPriorityDto[] = [];
  taskStates: TaskStateDto[] = [];
  contacts: ContactDto[] = [];
  calls: Phone3CxCallDto[] = [];
  formGroup: UntypedFormGroup;

  noteVms: TaskNoteVm[] = [];
  notesAmountChanged: boolean = false;

  filteredAddresses$: Observable<ErpCachedAddressDto[]>;
  filteredQueueVms$: Observable<TaskQueueVm[]>;
  filteredPriorities$: Observable<PriorityDto[]>;
  filteredStates$: Observable<TaskStateDto[]>;
  filteredUsers$: Observable<LegacyUserDto[]>;

  constructor(
    private readonly taskService: TaskService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly userService: UserService,
    private readonly dialogService: DialogService,
    private readonly phoneService: PhoneService,
    private readonly mandatorService: MandatorService
  ) {

    this.formGroup = this.formBuilder.group({
      title: ["", [Validators.required]],
      addressId: [null, [Validators.required]],
      description: [""],
      queueId: [null, [Validators.required]],
      priorityId: [null, [Validators.required]],
      stateId: [null, [Validators.required]],
      assignedUserId: [null],
      dueDate: [null],

      newActivity: [''],
      newActivityTime: [0],

      addressFilter: [''],
      queueFilter: [''],
      priorityFilter: [''],
      stateFilter: [''],
      userFilter: [''],
    });

    this.refresh$.pipe(
      takeUntil(this.destroy$),
      tap(() => this.isLoadingTask = true),
      switchMap(() => this.taskId ? this.taskService.getTaskEntry(this.taskId) : of({} as TaskEntryDto))
    ).subscribe((task) => {
      this.task = task;
      this.loadFromDto();
      this.updateNoteVms();
      this.isEditing = false;
      this.formGroup.markAsPristine();
      this.notesAmountChanged = false;
      this.isLoadingTask = false;
    });

    this.refresh$.pipe(
      takeUntil(this.destroy$),
      tap(() => this.isLoadingCalls = true),
      switchMap(() => this.taskId ? this.taskService.getTaskCalls(this.taskId) : of([] as Phone3CxCallDto[]))
    ).subscribe((calls) => {
      this.calls = calls;
      this.isLoadingCalls = false;
      this.contacts = [];

      for (const call of calls) {
        if (call.OtherPartyMainContact && !this.contacts.some(x => x.Id === call.OtherPartyMainContactId)) {
          this.contacts.push(call.OtherPartyMainContact);
        }
      }
    });

  }

  async ngOnInit() {
    const baseData = await firstValueFrom(forkJoin([
      this.taskService.getTaskQueues(this.mandatorService.selectedMandatorId),
      this.taskService.getTaskPriorities(),
      this.taskService.getTaskStates(),
      this.phoneService.getCallsById(this.callIds),
    ]));

    this.taskQueues = baseData[0];
    this.taskPriorieties = baseData[1];
    this.taskStates = baseData[2];
    this.calls = baseData[3];

    if (this.isNew) {
      this.isEditing = true;

      this.formGroup.patchValue({
        queueId: this.taskQueues[0]?.Id,
        priorityId: this.taskPriorieties.find(x => x.IsDefault)?.Id,
        stateId: this.taskStates.find(x => x.IsDefaultSate)?.Id,
        assignedUserId: this.userService.currentUser.userId,
      });

      if (this.calls.length > 0) {

        for (const call of this.calls) {
          if (call.OtherPartyMainContact && !this.contacts.some(x => x.Id === call.OtherPartyMainContactId)) {
            this.contacts.push(call.OtherPartyMainContact);
          }
        }

        if (this.calls.length === 1) {
          const addressId = this.calls[0]?.OtherPartyMainContact?.ErpCachedAddressId;
          if (addressId) {
            this.formGroup.patchValue({ addressId: addressId });
          }

        }

        let title = "Rückruf: ";
        let description = "Anruf eingegangen von: ";
        const contact = this.calls[0].OtherPartyMainContact;

        if (contact) {
          title += contact.GivenName + " " + contact.LastName;
          description += contact.GivenName + " " + contact.LastName;
        }
        else {
          title += this.calls[0].OtherPartyNumber;
          description += this.calls[0].OtherPartyNumber;
        }
        this.formGroup.patchValue({
          title: title,
          description: description
        });
      }
    }

    this.updateQueueVms();

    this.filteredAddresses$ = combineLatest([
      this.taskService.getAddressesWithMandator(this.mandatorService.selectedMandatorId),
      this.formGroup.get('addressFilter').valueChanges.pipe(
        startWith(''),
        map(search => ((search as string) ?? '').toLowerCase().trim())
      )
    ]).pipe(
      takeUntil(this.destroy$),
      map(([addresses, search]) => {
        return addresses.filter(x => x.CachedDisplayName.toLowerCase().includes(search));
      }));


    this.filteredQueueVms$ = this.formGroup.get('queueFilter').valueChanges.pipe(
      takeUntil(this.destroy$),
      startWith(''),
      map(search => ((search as string) ?? '').toLowerCase().trim()),
      map((search) => {
        return this.taskQueueVms.filter(x => x.displayText.toLowerCase().includes(search));
      }));

    this.filteredPriorities$ = this.formGroup.get('priorityFilter').valueChanges.pipe(
      takeUntil(this.destroy$),
      startWith(''),
      map(search => ((search as string) ?? '').toLowerCase().trim()),
      map((search) => {
        return this.taskPriorieties.filter(x => x.Description.toLowerCase().includes(search));
      }));

    this.filteredStates$ = this.formGroup.get('stateFilter').valueChanges.pipe(
      takeUntil(this.destroy$),
      startWith(''),
      map(search => ((search as string) ?? '').toLowerCase().trim()),
      map((search) => {
        return this.taskStates.filter(x => x.Description.toLowerCase().includes(search));
      }));

    this.filteredUsers$ = combineLatest([
      this.taskService.getUsers(),
      this.formGroup.get('userFilter').valueChanges.pipe(
        takeUntil(this.destroy$),
        startWith(''),
        map(search => ((search as string) ?? '').toLowerCase().trim())
      )
    ]).pipe(
      map(([users, search]) => {
        return users.filter(x => x.FirstName?.toLowerCase().includes(search) || x.LastName?.toLowerCase().includes(search));
      }));

  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.refresh$.complete();
  }

  loadFromDto() {
    this.formGroup.patchValue({
      title: this.task.Title,
      addressId: this.task.AddressId,
      description: this.task.Description,
      queueId: this.task.TaskQueueId,
      priorityId: this.task.PriorityId,
      stateId: this.task.StateId,
      assignedUserId: this.task.AssignedUserId,
      dueDate: this.task.DueDate,

      newActivity: "",
      newActivityTime: 0,
    });
  }

  private updateQueueVms() {
    this.taskQueueVms = [];
    for (const queue of this.taskQueues) {
      const newVm: TaskQueueVm = {
        taskQueue: queue,
        displayText: queue.Description
      };

      if (queue.ParentTaskQueueId != null) {
        const parentQueue = this.taskQueues.find(x => x.Id === queue.ParentTaskQueueId);
        newVm.displayText = parentQueue.Description + "➝" + queue.Description;
      }
      this.taskQueueVms.push(newVm);
    }

    this.taskQueueVms.sort((a, b) => a.displayText.localeCompare(b.displayText));
  }

  calculateActivityDurationSum() {
    let sum = 0;
    let result = "";

    if (this.noteVms) {
      for (const vm of this.noteVms) {
        if (vm.note.TimeEntry?.Minutes) {
          sum += vm.note.TimeEntry.Minutes;
        }
      }
    }

    if (sum >= 60) {
      const minutes = sum % 60;
      const hours = (sum - minutes) / 60;
      result = hours + "h" + " " + minutes + "min";
    }
    else {
      result = sum + "min";
    }
    return result;
  }

  private updateNoteVms() {
    this.noteVms = [];

    if (this.task.Notes) {
      for (const note of this.task.Notes) {
        const vm = new TaskNoteVm(this.formBuilder, note);
        this.noteVms.push(vm);
      }
    }
  }

  async addNewActivity() {

    const user = await firstValueFrom(this.taskService.getUser(this.userService.userId));

    const note: TaskNoteDto = {
      Id: 0,
      TaskId: this.taskId,
      CreatedUserId: this.userService.userId,
      ModifiedUserId: this.userService.userId,
      CreatedDate: new Date(),
      ModifiedDate: new Date(),
      Text: this.formGroup.value.newActivity,
      CreatedUser: user,
      TimeEntry: null,
    };

    if (this.formGroup.value.newActivityTime) {
      const timeEntry: TaskTimeEntryDto = {
        TaskNoteId: 0,
        TaskId: this.taskId,
        CreatedUserId: this.userService.userId,
        TasksReceiptId: null,
        TasksReceiptRowId: null,
        CreatedDate: new Date(),
        Tag: null,
        Minutes: this.formGroup.value.newActivityTime,
      };

      note.TimeEntry = timeEntry;
    }

    const vm = new TaskNoteVm(this.formBuilder, note);

    this.noteVms.push(vm);
    this.formGroup.patchValue({
      newActivity: "",
      newActivityTime: 0,
    });
    this.notesAmountChanged = true;
  }

  removeActivity(vm: TaskNoteVm) {
    ArrayUtils.remove(this.noteVms, vm);
    this.notesAmountChanged = true;
  }

  addToActivityTime(amount: number) {
    const sum = this.formGroup.value.newActivityTime += amount;
    this.formGroup.patchValue({
      newActivityTime: sum
    });
  }

  getQueueDisplayText(queueId: number) {
    const result = this.taskQueueVms.find(x => x.taskQueue.Id === queueId)?.displayText;
    return result;
  }

  getPriorityDisplayText(priorityId: number) {
    const result = this.taskPriorieties.find(x => x.Id === priorityId)?.Description;
    return result;
  }

  getStateDisplayText(stateId: number) {
    const result = this.taskStates.find(x => x.Id === stateId)?.Description;
    return result;
  }

  async closeConfirmation() {

    if (this.taskId) {
      if ((this.formGroup.dirty || this.noteVms.some(x => x.formGroup.dirty) || this.notesAmountChanged) && this.formGroup.valid) {
        if (!await this.dialogService.showConfirmation('Ungespeicherte Änderungen', 'Es gibt ungespeicherte Änderungen.', 'Speichen', 'Verwerfen')) {
          this.refresh$.next();
          this.closeRequest.emit();
          return;
        }
        await this.save();
        this.closeRequest.emit();
      }
      else {
        this.isEditing = false;
        this.closeRequest.emit();
      }
    }

  }

  async changeTaskConfirmation() {
    if ((this.formGroup.dirty || this.noteVms.some(x => x.formGroup.dirty) || this.notesAmountChanged) && this.formGroup.valid) {
      if (!await this.dialogService.showConfirmation('Ungespeicherte Änderungen', 'Es gibt ungespeicherte Änderungen.', 'Speichen', 'Verwerfen')) {
        return;
      }
      await this.save();
      this.closeRequest.emit();
    }
  }

  getCallsDuration() {
    const sumMs = this.calls.reduce((sum, call) => sum + call.EndDateTime.valueOf() - call.StartDateTime.valueOf(), 0);
    return this.formatDuration(sumMs);
  }

  async save() {

    const dto: TaskEntrySaveRequestDto = <TaskEntrySaveRequestDto>{
      TaskEntry: <TaskEntryDto>{},
      Calls: []
    };

    if (this.taskId !== 0) {
      Object.assign(dto.TaskEntry, this.task);
    }

    dto.TaskEntry.Title = this.formGroup.value.title;
    dto.TaskEntry.AddressId = this.formGroup.value.addressId;
    dto.TaskEntry.Description = this.formGroup.value.description;
    dto.TaskEntry.TaskQueueId = this.formGroup.value.queueId;
    dto.TaskEntry.PriorityId = this.formGroup.value.priorityId;
    dto.TaskEntry.StateId = this.formGroup.value.stateId;
    dto.TaskEntry.AssignedUserId = this.formGroup.value.assignedUserId;
    dto.TaskEntry.DueDate = this.formGroup.value.dueDate;
    dto.TaskEntry.Notes = [];
    dto.TaskEntry.EnvironmentId = this.mandatorService.selectedMandatorId;

    for (const vm of this.noteVms) {
      vm.note.Text = vm.formGroup.value.text;
      dto.TaskEntry.Notes.push(vm.note);
    }

    if (this.calls) {
      dto.Calls = this.calls;
    }

    await firstValueFrom(this.taskService.saveTask(dto));

    this.refresh$.next();
    this.refreshRequest.emit();
    this.formGroup.markAsPristine();
    this.notesAmountChanged = false;
    this.formGroup.patchValue({
      newActivity: "",
      newActivityTime: 0,
    });

    for (const vm of this.noteVms) {
      vm.formGroup.markAsPristine();
    }

  }

  openContactDialog(contactId: number) {
    this.phoneService.showPhoneContactDialog(contactId);
  }

  private formatDuration(ms: number) {
    let seconds = Math.round(ms / 1000);
    const hours = Math.trunc(seconds / 3600);
    seconds = seconds % 3600;
    const minutes = Math.trunc(seconds / 60);
    seconds = Math.round(seconds % 60);

    let result = "";
    if (hours > 0) {
      result += hours.toString() + "h ";
    }

    if (seconds % 60 > 0) {
      result += (minutes + 1).toString() + "min";
    }
    else {
      result += minutes.toString() + "min";
    }

    return result;
  }



}
