import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, firstValueFrom, tap } from 'rxjs';
import { Api2Service } from 'src/app/core/services/api2.service';
import { XmlImportDialogComponent } from '../components/xml-import-dialog/xml-import-dialog.component';
import { ReadXmlResultDto, fixReadXmlResultDto } from '../dto/read-xml-result-dto';
import { XmlAssignContractTypeRequestDto } from '../dto/xml-assign-contract-type-request-dto';
import { XmlUpdateContractValidUntilDateRequestDto } from '../dto/xml-update-contract-valid-until-date-request-dto';
import { XmlUpdateContractAccountableRequestDto } from '../dto/xml-update-contract-accountable-request-dto';

const controller = "Xml";
@Injectable({
  providedIn: 'root'
})
export class XmlService {

  constructor(
    private readonly api: Api2Service,
    private readonly dialog: MatDialog
  ) { }


  readXmlFile(file: File): Observable<ReadXmlResultDto> {
    return this.api.postFile<ReadXmlResultDto>(controller, "ReadXmlFile", file).pipe(
      tap(fixReadXmlResultDto)
    );
  }

  assignContractType(description: string, contractTypeId: number) {
    const request: XmlAssignContractTypeRequestDto = {
      description: description,
      contractTypeId: contractTypeId
    };

    return this.api.post(controller, "AssignContractType", null, request);
  }

  updateContractValidUtilDate(contractId: number, validUntilDate: Date) {
    const request: XmlUpdateContractValidUntilDateRequestDto = {
      contractId: contractId,
      validUntilDate: validUntilDate
    };

    return this.api.post(controller, "UpdateContractValidUtilDate", null, request);
  }

  updateContractAccountableDates(contractId: number, accountable: boolean) {
    const request: XmlUpdateContractAccountableRequestDto = {
      contractId: contractId,
      accountable: accountable
    };

    return this.api.post(controller, "UpdateContractAccountableDates", null, request);
  }


  async showXmlImportDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.hasBackdrop = false;
    dialogConfig.data = {
    };
    dialogConfig.panelClass = "full-dialog";

    const dialog = this.dialog.open<XmlImportDialogComponent>(XmlImportDialogComponent, dialogConfig);

    const ok = await firstValueFrom(dialog.afterClosed());
    return ok;
  }

}
