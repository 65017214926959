import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSort } from '@angular/material/sort';
import angular from 'angular';
import { catchError, debounceTime, firstValueFrom, forkJoin, merge, Observable, of, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { CallDirection } from 'src/ajs/Models/Phone3cx/CallDirection';
import { CallEntryDto } from 'src/ajs/Models/Phone3cx/CallEntryDto';
import { IPanelServiceEx } from 'src/ajs/Services/IPanelServiceEx';
import { SearchBarService } from 'src/ajs/Services/SearchBarService';
import { mdPanelAjs } from 'src/app/ajs-upgraded-providers';
import { Api1Service } from 'src/app/core/services/api1.service';
import { SignalRService } from 'src/app/core/services/signalr/signalr.service';
import { RemoteStorageService } from 'src/app/core/services/web-storage/remote-storage.service';
import { COLEDITOR_DEF, COLEDITOR_DEF_FIXED_START, COLEDITOR_STORAGE_KEY, ColumnEditorService } from 'src/app/shared/services/column-editor/column-editor.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SortService, SORT_DEFAULT_ACTIVE, SORT_DEFAULT_DIRECTION, SORT_STORAGE_KEY } from 'src/app/shared/services/sort/sort.service';
import { ArrayUtils } from 'src/app/shared/utils/array-utils';
import { GetPhoneListResultDto } from '../../dto/get-phone-list-result-dto';
import { PhoneListCallDto } from '../../dto/phone-list-call-dto';
import { PhoneService } from '../../services/phone.service';
import { CallStatusFilterVm } from './call-status-filter-vm';
import { PhoneListVm } from './phone-list-vm';


@Component({
  selector: 'app-phone-list',
  templateUrl: './phone-list.component.html',
  styleUrls: ['./phone-list.component.scss'],
  providers: [
    ColumnEditorService,
    { provide: COLEDITOR_STORAGE_KEY, useValue: 'phone-list.columns' },
    {
      provide: COLEDITOR_DEF, useValue: [
        { name: 'callId', header: 'CallId', isVisibleDefault: false },
        { name: 'startDate', header: 'Start' },
        { name: 'duration', header: 'Dauer' },
        { name: 'mainExtensionDescription', header: 'Nebenstelle' },
        { name: 'otherPartyNumber', header: 'Teilnehmer' },
        { name: 'contactName', header: 'Kontakt' },
        { name: 'address', header: 'Adresse' },
        { name: 'note', header: 'Notiz' },
      ]
    },
    {
      provide: COLEDITOR_DEF_FIXED_START, useValue: [
        { name: 'checkbox', header: '' },
        { name: 'task', header: '' }
      ]
    },
    SortService,
    { provide: SORT_STORAGE_KEY, useValue: 'phone-list.sort' },
    { provide: SORT_DEFAULT_ACTIVE, useValue: 'startDate' },
    { provide: SORT_DEFAULT_DIRECTION, useValue: 'desc' },
  ]
})
export class PhoneListComponent implements AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatDrawer) drawer: MatDrawer;
  totalCount: number = 0;
  data: PhoneListVm[] = [];
  private readonly destroy$ = new Subject<void>();
  public readonly refresh$ = new Subject<void>();
  private readonly $mdPanel: IPanelServiceEx;
  isLoading = false;
  dontShowLoadingOnNextRefresh = false;
  anyChecked: boolean;
  anyCheckedHasTask: boolean;
  statusFilterVms: CallStatusFilterVm[] = [];
  selectedCallId: number;

  callStatusFilterAngenommenActive: boolean = true;
  callStatusFilterVerpasstActive: boolean = true;
  callStatusFilterEingehendActive: boolean = true;
  callStatusFilterAusgehendActive: boolean = true;
  callStatusFilterInternActive: boolean = true;

  dateFilter: UntypedFormControl = new UntypedFormControl("today");

  @Output() readonly callClicked = new EventEmitter<CallEntryDto>();

  private _addressId: number;

  @Input() set addressId(value: number) {
    if (this._addressId !== value) {
      this._addressId = value;
      this.refresh$.next();
    }
  }

  get addressId() {
    return this._addressId;
  }

  @Input() refreshRequested: Observable<void>;

  constructor(
    public readonly sortService: SortService,
    public readonly columnEditor: ColumnEditorService,
    private readonly phoneService: PhoneService,
    $mdPanel: mdPanelAjs,
    private readonly api: Api1Service,
    private readonly SearchBarService: SearchBarService,
    private readonly signalRService: SignalRService,
    private readonly dialogService: DialogService,
    private readonly remoteStorage: RemoteStorageService,
  ) {
    this.$mdPanel = $mdPanel as IPanelServiceEx;
  }

  async ngAfterViewInit() {
    const query = await firstValueFrom(forkJoin([
      this.columnEditor.loaded$,
      this.sortService.loaded$,
      this.remoteStorage.getItem('phone-list.dateFilter'),
    ]));

    if (query[2]) {
      this.dateFilter.setValue(query[2]);
    }

    this.dateFilter.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => this.remoteStorage.setItem('phone-list.dateFilter', value));


    if (this.refreshRequested) {
      this.refreshRequested.pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        this.refresh$.next();
      });
    }

    merge(
      this.sort.sortChange,
      this.paginator.page,
      this.SearchBarService.searchTextChange.pipe(debounceTime(300)),
      this.refresh$,
      this.dateFilter.valueChanges
    ).pipe(
      takeUntil(this.destroy$),
      startWith({}),
      tap(() => {
        if (this.dontShowLoadingOnNextRefresh) {
          this.dontShowLoadingOnNextRefresh = false;
        }
        else {
          this.isLoading = true;
        }
      }),
      switchMap(() => this.phoneService.getPhoneList(
        this.sort.active,
        this.sort.direction === "desc",
        this.paginator.pageSize,
        this.paginator.pageIndex,
        this.SearchBarService.SearchText,
        this.callStatusFilterAngenommenActive,
        this.callStatusFilterVerpasstActive,
        this.callStatusFilterEingehendActive,
        this.callStatusFilterAusgehendActive,
        this.callStatusFilterInternActive,
        this.dateFilter.value,
        this.addressId,

      ).pipe(
        catchError(err => {
          console.log(err);
          return of({ TotalCount: 0, PhoneList: [] } as GetPhoneListResultDto);
        })
      ))
    ).subscribe(result => {

      if (!result) {
        result = { TotalCount: 0, PhoneList: [] } as GetPhoneListResultDto;
      }

      this.isLoading = false;
      this.totalCount = result.TotalCount;
      this.data = result.PhoneList?.map(dto => <PhoneListVm>{
        phoneCall: dto,
        isChecked: false
      });
      this.anyChecked = false;
    });

    this.signalRService.callOperation.pipe(
      takeUntil(this.destroy$),
    ).subscribe(ev => {
      // Nur aktualisieren wenn ein neuer Anruf reinkommt und man auf der ersten Seite ist,
      // oder ein angezeigter Anruf sich ändert
      if ((ev.operation === "add" && this.paginator?.pageIndex === 0) || this.data?.some(vm => vm.phoneCall.CallId === ev.callId)) {
        this.dontShowLoadingOnNextRefresh = true;
        this.refresh$.next();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.refresh$.complete();
  }

  getCallDirectionIcon(call: PhoneListCallDto) {
    if (call.Direction === CallDirection.Incomming && call.IsAnswered) {
      return "call_received";
    }
    else if (call.Direction === CallDirection.Incomming && !call.IsAnswered) {
      return "call_missed";
    }
    else if (call.Direction === CallDirection.Outgoing && call.IsAnswered) {
      return "call_made";
    }
    else if (call.Direction === CallDirection.Outgoing && !call.IsAnswered) {
      return "call_missed_outgoing";
    }
    else if (call.Direction === CallDirection.Internal) {
      return "call_merge";
    }
    return "";
  }

  toggleChecked(vm: PhoneListVm) {
    vm.isChecked = !vm.isChecked;
    this.checkAnyChecked();
  }

  toggleAllChecked(event: MatCheckboxChange) {
    if (event.checked) {
      for (const x of this.data) {
        x.isChecked = true;
      }
    }
    else {
      for (const x of this.data) {
        x.isChecked = false;
      }
    }
    this.checkAnyChecked();
  }

  checkAnyChecked() {
    if (this.data.some(x => x.isChecked)) {
      this.anyChecked = true;
    }
    else {
      this.anyChecked = false;
    }

    if (this.data.some(x => x.isChecked && x.phoneCall.Task)) {
      this.anyCheckedHasTask = true;
    }
    else {
      this.anyCheckedHasTask = false;
    }
  }

  async removeCallsFromLog() {
    const callIds: number[] = [];

    if (!await this.dialogService.showConfirmation('Aus Protokoll entfernen?', 'Ausgewählte Anrufe wirklich aus dem Protokoll entfernen?', 'Entfernen', 'Abbrechen', 'warn')) {
      return;
    }

    for (const call of this.data) {
      if (call.isChecked) {
        callIds.push(call.phoneCall.CallId);
      }
    }

    // Calls sofort entfernen; verhindert, dass refresh$ mehrmals über SignalR aufgerufen wird
    ArrayUtils.removeWhere(this.data, vm => callIds.includes(vm.phoneCall.CallId));

    await firstValueFrom(this.api.post<any>("Phone3cx", "RemoveCallsFromLog", null, { callIds: callIds }));
    this.closeDrawerIfEffected(callIds);
    this.refresh$.next();
  }


  private openAddToTaskPanelMultiple(calls: CallEntryDto[], $event?: any) {
    this.$mdPanel.newPanelGroup("AddToTask", {
      maxOpen: 1
    });

    const config = {
      attachTo: angular.element(document.body),
      templateUrl: '/ClientApp/src/ajs/Views/Tasks/TasksSuggestionWidget.htm',
      controller: "TasksSuggestionWidgetController",
      panelClass: 'window-panel-container',
      bindToController: true,
      locals: {
        SelectedCalls: calls,
        SelectedMails: null,
        tasksChanged: this.refresh$
      },
      openFrom: $event,
      focusOnOpen: true,
      propagateContainerEvents: true,
      groupName: ["AddToTask"],
      onCloseSuccess: () => {
        const callIds = calls.map(x => x.CallId);
        this.closeDrawerIfEffected(callIds);
      }
    };
    this.$mdPanel.open(config);
  }


  async addCallsToTask() {
    const callIds: number[] = [];
    const callsWithTask: PhoneListCallDto[] = [];

    for (const call of this.data) {
      if (call.isChecked) {
        callIds.push(call.phoneCall.CallId);
        if (call.phoneCall.Task) {
          callsWithTask.push(call.phoneCall);
        }
      }
    }

    if (callsWithTask.length) {
      let message = "";
      if (callsWithTask.length === 1) {
        message = callsWithTask.length + " Anruf ist bereits einer Aufgabe zugewiesen. Soll wirklich eine neue Aufgabe zugewiesen werden?";
      }
      else {
        message = callsWithTask.length + " Anrufe sind bereits einer Aufgabe zugewiesen. Soll wirklich eine neue Aufgabe zugewiesen werden?";
      }

      if (await this.dialogService.showConfirmation("Aufgabe zuweisen", message, "Zuweisen", "Abbrechen")) {
        const calls = await firstValueFrom(this.phoneService.getCallsByIdLegacy(callIds));

        //Old CallEntryDto fix
        for (const call of calls) {
          if (call.Contacts[0]?.Contact?.MainAddress) {
            call.Contacts[0].MainAddress = call.Contacts[0].Contact.MainAddress;
          }
        }
        //

        this.openAddToTaskPanelMultiple(calls);
      }
    }
    else {
      const calls = await firstValueFrom(this.phoneService.getCallsByIdLegacy(callIds));

      //Old CallEntryDto fix
      for (const call of calls) {
        if (call.Contacts[0]?.Contact?.MainAddress) {
          call.Contacts[0].MainAddress = call.Contacts[0].Contact.MainAddress;
        }
      }
      //

      this.openAddToTaskPanelMultiple(calls);
    }
  }

  async callClick(call: PhoneListCallDto) {
    this.selectedCallId = call.CallId;

    if (!this.drawer.opened) {
      this.drawer.open();
    }
  }

  resetStatusFilter() {
    this.callStatusFilterAngenommenActive = true;
    this.callStatusFilterVerpasstActive = true;
    this.callStatusFilterEingehendActive = true;
    this.callStatusFilterAusgehendActive = true;
    this.callStatusFilterInternActive = true;
    this.refresh$.next();
  }

  statusFilterActive() {

    if (!this.callStatusFilterAngenommenActive || !this.callStatusFilterVerpasstActive || !this.callStatusFilterEingehendActive || !this.callStatusFilterAusgehendActive || !this.callStatusFilterInternActive) {
      return true;
    }
    else {
      return false;
    }
  }

  toggleStatusFilterAngenommen() {
    this.callStatusFilterAngenommenActive = !this.callStatusFilterAngenommenActive;
    this.refresh$.next();
  }

  toggleStatusFilterVerpasst() {
    this.callStatusFilterVerpasstActive = !this.callStatusFilterVerpasstActive;
    this.refresh$.next();
  }

  toggleStatusFilterEingehend() {
    this.callStatusFilterEingehendActive = !this.callStatusFilterEingehendActive;
    this.refresh$.next();
  }

  toggleStatusFilterAusgehend() {
    this.callStatusFilterAusgehendActive = !this.callStatusFilterAusgehendActive;
    this.refresh$.next();
  }

  toggleStatusFilterIntern() {
    this.callStatusFilterInternActive = !this.callStatusFilterInternActive;
    this.refresh$.next();
  }

  async removeTaskFromCall() {
    const callIds: number[] = [];
    const message = "Sollen bei den ausgewählten Anrufen wirklich die Zuweisung zu den jeweiligen Aufgaben entfernt werden?";

    if (await this.dialogService.showConfirmation("Aufgabe entfernen", message, "Entfernen", "Abbrechen", 'warn')) {

      for (const call of this.data) {
        if (call.isChecked && call.phoneCall.Task) {
          callIds.push(call.phoneCall.CallId);
        }
      }
      await firstValueFrom(this.phoneService.removeTaskFromCalls(callIds));
      this.refresh$.next();
      this.closeDrawerIfEffected(callIds);
    }
  }

  private closeDrawerIfEffected(effectedCallIds: number[]) {
    if (this.selectedCallId && effectedCallIds.includes(this.selectedCallId)) {
      this.drawer.close();
      this.selectedCallId = null;
    }
  }
}
