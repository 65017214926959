import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, tap } from 'rxjs';
import { AppointmentDto } from 'src/ajs/Models/PublicContacts/AppointmentDto';
import { AppointmentType } from 'src/ajs/Models/PublicContacts/AppointmentType';
import { GetContactsResultDto } from 'src/ajs/Models/PublicContacts/GetContactsResultDto';
import { fixUserDto, UserDto } from 'src/app/core/dto/user-dto';
import { Api1Service } from 'src/app/core/services/api1.service';
import { Api2Service } from 'src/app/core/services/api2.service';
import { CalendarShareDto, fixCalendarShareDto } from '../dto/calendar-share-dto';

const controller = "PublicCalendar";

@Injectable({
  providedIn: 'root'
})
export class PublicCalendarService {

  constructor(
    private readonly api1: Api1Service,
    private readonly api2: Api2Service
  ) { }

  getAppointmentTypeClass(appointment: AppointmentDto) {
    switch (appointment.Type) {
      case AppointmentType.CalendarPrivate:
        return "calendar-private";
      case AppointmentType.CalendarOof:
        return "calendar-oof";
      case AppointmentType.CalendarElswhere:
        return "calendar-elswhere";
      case AppointmentType.CalendarTentative:
        return "calendar-tentative";
      case AppointmentType.HolidayNotApproved:
        return "holiday-not-approved";
      case AppointmentType.HolidayApproved:
        return "holiday-approved";
      case AppointmentType.HolidayUnknown:
        return "holiday-unknown";
      case AppointmentType.HolidayTradeSchool:
        return "holiday-trade-school";
      case AppointmentType.HolidayCompanyEvent:
        return "holiday-company-event";
    }
    return null;
  }

  /** Gibt alle Benutzer zurück, deren Kalenderfreigabe vom angemeldeten Benutzer beantragt werden kann. */
  getCalendarsSharableWithMe(): Observable<UserDto[]> {
    return this.api2.get<UserDto[]>(controller, 'GetCalendarsSharableWithMe').pipe(
      tap(array => array.forEach(fixUserDto))
    );
  }

  /** Gibt alle Benutzer zurück, deren Kalender für den aktuellen Benutzer freigegeben ist. */
  getCalendarsSharedWithMe(): Observable<UserDto[]> {
    return this.api2.get<UserDto[]>(controller, 'GetCalendarsSharedWithMe').pipe(
      tap(array => array.forEach(fixUserDto))
    );
  }

  /** Gibt alle Benutzer zurück, für die der Kalender des angemeldeten Benutzers freigegeben ist. */
  getMyCalendarSharedWith(): Observable<UserDto[]> {
    return this.api2.get<UserDto[]>(controller, 'GetMyCalendarSharedWith').pipe(
      tap(array => array.forEach(fixUserDto))
    );
  }

  requestSharing(username: string): Observable<void> {
    return this.api2.post<void>(controller, 'RequestSharing', { username: username });
  }

  getCalendarShare(token: string): Observable<CalendarShareDto> {
    return this.api2.get<CalendarShareDto>(controller, 'GetCalendarShare', { token: token }).pipe(
      tap(fixCalendarShareDto)
    );
  }

  saveCalendarShare(dto: CalendarShareDto): Observable<void> {
    return this.api2.post<void>(controller, 'SaveCalendarShare', null, dto);
  }

  getCalendar(mandatorId: string): Observable<GetContactsResultDto> {
    return this.api1.post<GetContactsResultDto>('PublicContacts', 'GetCalendar', { mandatorId: mandatorId }).pipe(
      tap(data => {
        for (const group of data.Groups) {
          for (const contact of group.Contacts) {
            for (const app of contact.Appointments) {
              app.MomentStart = moment(app.Start);
              app.MomentEnd = moment(app.End);
            }
          }
        }
      })
    );
  }
}

