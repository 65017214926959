import * as angular from "angular";

export class PdfDirective implements angular.IDirective {

  static staticPdfId = 0;
  restrict = 'E';
  scope = { src: '=' };

  link = (angularScope: angular.IScope, angularElement: angular.IAugmentedJQuery, attrs: angular.IAttributes) => {
    const scope: any = angularScope;
    const $compile = this.$compile;
    const element: any = angularElement;

    function update() {
      if (scope.src) {
        if (!scope.IsInit) {
          scope.Id = PdfDirective.staticPdfId++;
          scope.IsInit = true;
          element.html('<iframe id="PdfView' + scope.Id + '" style="width: 100%; height: 100%;" type="application/pdf" src="' + scope.src + '"></iframe>');
          //element.append('<div id="PdfLoader' + scope.Id + '" layout="row" style="display:none; width: 100%; height: 100%;" layout-align="center center"><div layout="column" layout-fill flex class="has-loading-backdrop loading-backdrop"></div><div layout="column" flex></div></div>');
          $compile(element.contents())(scope);
        } else {
          var frame = $("#PdfView" + scope.Id);
          frame.fadeOut(100, function () {
            //$("#PdfLoader" + scope.Id).show();
            frame.attr('src', scope.src);
            frame[0].onload = function () {
              setTimeout(function () {
                //$("#PdfLoader" + scope.Id).hide();
                $("#PdfView" + scope.Id).fadeIn(100);
              }, 50);
            };
          });
        }

      }
    }

    scope.$watch('src', update);
    if (scope.src) {
      update();
    }
  }

  constructor(
    private readonly $compile: angular.ICompileService
  ) { }

  static factory(): angular.IDirectiveFactory {
    const directive = ($compile: angular.ICompileService) => new PdfDirective($compile);
    directive.$inject = ['$compile'];
    return directive;
  }
}
