import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractDialogComponent } from './contract-dialog/contract-dialog.component';
import { SharedModule } from '../shared/shared.module';
import { ContractListComponent } from './contract-list/contract-list.component';
import { ContractFileNoteDialogComponent } from './contract-file-note-dialog/contract-file-note-dialog.component';
import { ContractTabEditDialogComponent } from './contract-tab-edit-dialog/contract-tab-edit-dialog.component';
import { ArticleSearchDialogComponent } from './article-search-dialog/article-search-dialog.component';
import { ArticleVariationDialogComponent } from './article-variation-dialog/article-variation-dialog.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    ContractDialogComponent,
    ContractListComponent,
    ContractFileNoteDialogComponent,
    ContractTabEditDialogComponent,
    ArticleSearchDialogComponent,
    ArticleVariationDialogComponent,
  ]
})
export class ContractsModule { }
