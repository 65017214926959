import angular, { IScope, ITimeoutService } from 'angular';
import { addDefaultScopeFunctions } from 'src/ajs/Functions/add-default-scope-functions';
import { ColumnDto } from 'src/ajs/Models/PriceImport/ColumnDto';
import { ColumnTransformationDto } from 'src/ajs/Models/PriceImport/ColumnTransformationDto';
import { DownloadDto } from 'src/ajs/Models/PriceImport/DownloadDto';
import { DownloadEventDto } from 'src/ajs/Models/PriceImport/DownloadEventDto';
import { FileDto } from 'src/ajs/Models/PriceImport/FileDto';
import { FileEventDto } from 'src/ajs/Models/PriceImport/FileEventDto';
import { GetVendorsResult } from 'src/ajs/Models/PriceImport/GetVendorsResultDto';
import { VendorDto } from 'src/ajs/Models/PriceImport/VendorDto';
import { VendorEventDto } from 'src/ajs/Models/PriceImport/VendorEventDto';
import { ThemeDto } from 'src/ajs/Models/ThemeDto';
import { ApiHandlerService } from 'src/ajs/Services/ApiHandlerService';
import { PriceImportService } from 'src/ajs/Services/PriceImportService';
import { StorageService, StorageServiceInstance } from 'src/ajs/Services/StorageService';
import { addNavigation } from 'src/ajs/Utils/HestiaCommon';
import { INavigationScope } from 'src/ajs/Utils/INavigationScope';



createPriceListController.$inject = ['$scope', '$mdDialog', '$timeout', 'ApiHandlerService', 'StorageService', 'PriceImportService', 'theme'];

export function createPriceListController(
  $scope: IPriceListControllerScope,
  $mdDialog: angular.material.IDialogService,
  $timeout: ITimeoutService,
  ApiHandlerService: ApiHandlerService,
  StorageService: StorageService,
  PriceImportService: PriceImportService,
  theme: ThemeDto
) {
  $scope.Storage = StorageService.CreateInstance("PriceListController");
  addDefaultScopeFunctions($scope, theme);
  addNavigation($scope, "PriceList");
  $scope.IsLoading = false;
  $scope.VendorChanged = function (dlEvent: VendorEventDto) {
    $timeout(function () {
      for (const vendor of $scope.Model.Vendors) {
        if (vendor.Id === dlEvent.VendorId) {
          vendor.LastEvent = dlEvent;
        }
      }
    }, 1);
  };
  $scope.DownloadChanged = function (dlEvent: DownloadEventDto) {
    $timeout(function () {
      for (const vendor of $scope.Model.Vendors) {
        for (const download of vendor.Downloads) {
          if (download.Id === dlEvent.DownloadId) {
            download.LastEvent = dlEvent;
          }
        }
      }
    }, 1);
  };
  $scope.FileChanged = function (dlEvent: FileEventDto) {
    $timeout(function () {
      for (const vendor of $scope.Model.Vendors) {
        for (const download of vendor.Downloads) {
          for (const file of download.Files) {
            if (file.Id === dlEvent.FileId) {
              file.LastEvent = dlEvent;
            }
          }
        }
      }
    }, 1);
  };
  PriceImportService.VendorChangedCallbacks.push($scope.VendorChanged);
  PriceImportService.DownloadChangedCallbacks.push($scope.DownloadChanged);
  PriceImportService.FileChangedCallbacks.push($scope.FileChanged);
  $scope.LoadData = function () {
    // DODO
    ApiHandlerService.SendRequest("PriceImport", "GetVendors").then(function (data) {
      $scope.Model = data;
    });
  };

  $scope.ExecuteAll = function () {
    for (const vendor of $scope.Model.Vendors) {
      $scope.ExecuteVendor(vendor);
    }
  };

  $scope.ExecuteVendor = function (vendor: VendorDto) {
    ApiHandlerService.SendRequest("PriceImport", "ExecuteVendor", { model: { VendorId: vendor.Id } }).then(function (data) {
    });
  };

  $scope.StartFileDownload = function (id: number) {
    ApiHandlerService.SendRequest("PriceImport", "StartDownload", { downloadId: id }).then(function (data) {
    });
  };

  $scope.EditVendor = function (vendor: VendorDto) {
    if (!vendor) {
      vendor = {
        Id: null,
        Name: null,
        CreditorIdentifier: null,
        DefaultShippingDays: 0,
        ShippingDaysOffset: 0,
        IsActive: false
      } as VendorDto;
    }
    $mdDialog.show({
      targetEvent: <MouseEvent>event,
      controller: 'EditVendorDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/PriceList/EditVendorDialog.htm',
      locals: {
        Model: vendor,
        OnSubmit: function (result) {
          ApiHandlerService.SendRequest("PriceImport", "SaveVendor", { model: result }).then(data => {
            $scope.LoadData();
          });
        }
      },
      parent: angular.element(document.body)
    });
  };

  $scope.EditDownload = function (vendor: VendorDto, download: DownloadDto) {
    if (!download) {
      download = {
        Id: null,
        VendorId: vendor.Id,
        Type: 'ftp',
        Server: null,
        User: null,
        Password: null,
        From: null,
        IsActive: false
      } as DownloadDto;
    }
    $mdDialog.show({
      targetEvent: <MouseEvent>event,
      controller: 'EditDownloadDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/PriceList/EditDownloadDialog.htm',
      locals: {
        Model: download,
        OnSubmit: function (result) {
          ApiHandlerService.SendRequest("PriceImport", "SaveDownload", { model: result }).then(data => {
            $scope.LoadData();
          });
        }
      },
      parent: angular.element(document.body)
    });
  };

  $scope.EditFile = function (download: DownloadDto, file: FileDto) {
    if (!file) {
      file = {
        Id: null,
        DownloadId: download.Id,
        FileName: null,
        FileType: 'txt',
        Charset: 'UTF-8',
        ImportOrder: 0,
        Delmiter: 'tab',
        TextQualifier: '"',
        HasHeader: true
      } as FileDto;
    }
    $mdDialog.show({
      targetEvent: <MouseEvent>event,
      controller: 'EditFileDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/PriceList/EditFileDialog.htm',
      locals: {
        Model: file,
        Packages: download.Files.filter(f => f.FileType === "zip" && f.Id !== file.Id),
        OnSubmit: function (result) {
          ApiHandlerService.SendRequest("PriceImport", "SaveFile", { model: result }).then(data => {
            $scope.LoadData();
          });
        }
      },
      parent: angular.element(document.body)
    });
  };

  $scope.EditColumn = function (file: FileDto, column: ColumnDto) {
    if (!column) {
      column = {
        Id: null,
        FileId: file.Id,
        SourceColumn: null,
        SourceHeader: null,
        Target: null,
        FormatString: null
      } as ColumnDto;
    }
    $mdDialog.show({
      targetEvent: <MouseEvent>event,
      controller: 'EditColumnDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/PriceList/EditColumnDialog.htm',
      locals: {
        Model: column,
        OnSubmit: function (result) {
          ApiHandlerService.SendRequest("PriceImport", "SaveColumn", { model: result }).then(data => {
            $scope.LoadData();
          });
        }
      },
      parent: angular.element(document.body)
    });
  };

  $scope.EditTransformation = function (column: ColumnDto, transformation: ColumnTransformationDto) {
    if (!transformation) {
      transformation = {
        Id: null,
        ColumnId: column.Id,
        Type: "replace",
        Parameter1: null,
        Parameter2: null,
        FallbackValue: null,
        Order: 0
      };
    }
    $mdDialog.show({
      targetEvent: <MouseEvent>event,
      controller: 'EditTransformationDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/PriceList/EditTransformationDialog.htm',
      locals: {
        Model: transformation,
        OnSubmit: function (result) {
          ApiHandlerService.SendRequest("PriceImport", "SaveTransformation", { model: result }).then(data => {
            $scope.LoadData();
          });
        }
      },
      parent: angular.element(document.body)
    });
  };

  $scope.ImportFile = function (id: number) {
    ApiHandlerService.SendRequest("PriceImport", "ImportFile", { fileId: id }).then(function (data) {
      alert(data.Success);
    });
  };

  $scope.LoadData();
  $scope.Navigate($scope.Storage.GetOrCreate("ActiveTab", "PriceList"), true);
}


interface IPriceListControllerScope extends IScope, INavigationScope {

  Storage: StorageServiceInstance;
  IsLoading: boolean;
  Model: GetVendorsResult;

  VendorChanged: (dlEvent: VendorEventDto) => void;
  DownloadChanged: (dlEvent: DownloadEventDto) => void;
  FileChanged: (dlEvent: FileEventDto) => void
  LoadData: () => void;
  ExecuteAll: () => void;
  ExecuteVendor: (vendor: VendorDto) => void;
  StartFileDownload: (id: number) => void;
  EditVendor: (vendor: VendorDto) => void;
  EditDownload: (vendor: VendorDto, download: DownloadDto) => void;
  EditFile: (download: DownloadDto, file: FileDto) => void;
  EditColumn: (file: FileDto, column: ColumnDto) => void;
  EditTransformation: (column: ColumnDto, transformation: ColumnTransformationDto) => void;
  ImportFile: (id: number) => void;

}
