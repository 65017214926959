import { fixNotificationDto, NotificationDto } from "./notification-dto";

export interface GetNotificationsDto {
  notifications: NotificationDto[];
  totalCount: number;
}

export function fixGetNotificationsDto(dto: GetNotificationsDto) {
  for (let notification of dto.notifications) {
    fixNotificationDto(notification);
  }
}
