<h2 mat-dialog-title *ngIf="isNew">Tag erstellen</h2>
<h2 mat-dialog-title *ngIf="!isNew">Tag bearbeiten</h2>

<mat-dialog-content class="mat-typography" *ngIf="formGroup">
  <form [formGroup]="formGroup">
    <div fxLayout="column">

      <div fxLayout="row">
        <mat-form-field appearance="outline" fxFlex="50">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name">
          <mat-error>{{ formGroup.controls.name.errors | validationError }}</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row">
        <mat-form-field appearance="outline" fxFlex="50">
          <mat-label>Farbe</mat-label>
          <input matInput formControlName="color" [value]="formGroup.controls.color.value">
          <mat-error>{{ formGroup.controls.color.errors | validationError }}</mat-error>
        </mat-form-field>

        <button type="button" mat-icon-button (click)="setRandomColor()" class="random-color-button">
          <mat-icon>sync</mat-icon>
        </button>

        <div class="preview" fxFlex>
          <div class="mat-chip-list">
            <div class="mat-chip"
              [style.background-color]="lastValidColor"
              [style.color]="colorService.getOptimalTextColor(lastValidColor)">
              {{ formGroup.value.name?.length ? formGroup.value.name : 'Vorschau' }}
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" class="palette-container">
        <app-color-palette formControlName="color"></app-color-palette>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="formGroup">
  <button type="button" mat-stroked-button (click)="cancel()">Abbrechen</button>
  <button type="button" mat-raised-button color="primary" [disabled]="formGroup.invalid || isSaving" (click)="save()">Speichern</button>
</mat-dialog-actions>
