import { Injectable } from "@angular/core";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { Api1Service } from "src/app/core/services/api1.service";

const controller = "Label";

@Injectable({
  providedIn: 'root'
})

export class LabelService {

  constructor(
    private readonly api: Api1Service,
    private readonly apiHandlerService: ApiHandlerService,
  ) { }


  //Alte Api
  getLabelCustomLabels() {
    return this.apiHandlerService.SendRequest(controller, "LoadCustomLabels");
  }


  //

}