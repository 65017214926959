<button mat-icon-button id="cp-detailbar-back" (click)="backClicked()">
  <mat-icon>chevron_left</mat-icon>
</button>

<div id="cp-detailbar-title-container">
  <ng-content select="cp-detailbar-title"></ng-content>
  <ng-content select="cp-detailbar-subtitle"></ng-content>
</div>

<div id="cp-detailbar-content-container">
  <ng-content select="cp-detailbar-content"></ng-content>
</div>


<ng-content select="cp-detailbar-actions"></ng-content>
