import * as angular from "angular";

function setIndicator() {
  var best = null;
  $('.loading-backdrop').each(function () {
    var z = parseInt($(this).css('z-index'), 10);
    var maxz = -1;
    if (!best || maxz < z) {
      best = this;
      maxz = z;
    }
  });
  //    if (best) {
  //        $("#mainProgress").show(0);
  //    } else {
  //        $("#mainProgress").hide(1000);
  //    }
}

export class LoaderDirective implements angular.IDirective {
  transclude = false;

  link = function (scope: angular.IScope, element: angular.IAugmentedJQuery, attr: angular.IAttributes) {
    element.addClass("has-loading-backdrop");
  };

  controller = ['$scope', '$attrs', '$element', function ($scope: angular.IScope, $attrs: angular.IAttributes, $element: angular.IAugmentedJQuery) {
    $scope.$watch($attrs.loader, function (value) {
      if (value !== undefined) {
        if (value) {
          $element.addClass("loading-backdrop");
          if ($attrs.spinner !== undefined) {
            $element.addClass("spinner");
          }
        } else {
          $element.removeClass("loading-backdrop");
          if ($attrs.spinner !== undefined) {
            $element.removeClass("spinner");
          }
        }
        setIndicator();
      };
    });
  }];

  static factory(): angular.IDirectiveFactory {
    const directive = () => new LoaderDirective();
    directive.$inject = [];
    return directive;
  }


}