import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Api2Service } from 'src/app/core/services/api2.service';
import { SageArticleDto } from '../dto/sage-article-dto';
import { SageArticleVariantDto } from '../dto/sage-article-variant-dto';
import { SageArticleVariantExpressionDto } from '../dto/sage-article-variant-expression-dto';
import { SageArticlePriceDto } from '../dto/sage-article-price-dto';

const controller = "SageArticle";

@Injectable({
  providedIn: 'root'
})
export class SageArticleService {

  constructor(
    private readonly api2: Api2Service,
  ) { }

  getArticlesBySearchText(search: string, mandatorId: string): Observable<SageArticleDto[]> {
    return this.api2.get(controller, "GetArticlesBySearchText", { search: search, mandatorId: mandatorId });
  }

  getArticleByArticleNumber(articleNumber: string, mandatorId: string): Observable<SageArticleDto> {
    return this.api2.get(controller, "GetArticleByArticleNumber", { articleNumber: articleNumber, mandatorId: mandatorId });
  }

  getArticleVariations(articleNumber: string, mandatorId: string): Observable<SageArticleVariantDto> {
    return this.api2.get(controller, "GetArticleVariations", { articleNumber: articleNumber, mandatorId: mandatorId });
  }

  getAllArticleVariantExpressions(variant: string, mandatorId: string): Observable<SageArticleVariantExpressionDto[]> {
    return this.api2.get(controller, "getAllArticleVariantExpressions", { variant: variant, mandatorId: mandatorId });
  }

  getArticlePrice(articleNumber: string, expressionId: number, amount: number, customer: string, mandatorId: string): Observable<SageArticlePriceDto> {
    return this.api2.get<SageArticlePriceDto>(controller, "GetArticlePrice", { articleNumber: articleNumber, expressionId: expressionId, amount: amount, customer: customer, mandatorId: mandatorId });
  }

}
