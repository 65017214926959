import { Moment } from "moment";
import { BaseReceiptPaymentDto } from "./BaseReceiptPaymentDto";
import { BaseReceiptPositionDto } from "./BaseReceiptPositionDto";

export interface ReceiptDto {
  Identifier: any;
  Year: number;
  ReceiptNumber: number;
  ReceiptIdentifier: string;
  Type: string;
  Date: string | Moment;
  DueDate: string | Moment;
  Creator: string;
  Sum: number;
  SumWithTax: number;
  Tax: number;
  PaymentState: PaymentState;
  DaysTilDue: number;
  ReminderLevel: number;
  Matchcode: string;
  BaseMatchcode: string;
  Reference: string;
  Recipient: string;
  InternalPath: string;
  HasFile: boolean;
  IsPrinted: boolean;
  StorageActivity: number;
  FullRecipient: string;
  AccumulatedPayment: BaseReceiptPaymentDto;
  Positions: BaseReceiptPositionDto[];
  Payments: BaseReceiptPaymentDto[];
  EMail: string;
  BillingEMail: string;

  //erpController
  DateFormatted?: string;
  IsLoading?: boolean;

  //ReceiptEnviroment
  DownloadReceipt?: (model: any, event: any) => void;
  SendReceiptAsMail?: (model: any, event: any) => void;
  DueDateFormatted?: string;
  SumFormatted?: string;
  SumWithTaxFormatted?: string;
  TaxFormatted?: string;
}

export enum PaymentState {
  None,
  FuturePayment,
  Finished,
  Open
}
