import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { catchError, firstValueFrom, Observable, of, shareReplay, tap } from 'rxjs';
import { TaskDto } from 'src/ajs/Models/Tasks/TaskDto';
import { TaskReceiptDto } from 'src/ajs/Models/Tasks/TaskReceiptDto';
import { ApiHandlerService } from 'src/ajs/Services/ApiHandlerService';
import { LegacyUserDto } from 'src/app/core/dto/legacy-user-dto';
import { Api1Service } from 'src/app/core/services/api1.service';
import { ErpCachedAddressDto } from 'src/app/erp/dto/erp-cached-address-dto-legacy';
import { fixPhone3CxCallDto, Phone3CxCallDto } from 'src/app/phone/dto/phone-3cx-call-dto';
import { TaskEditDialogComponent } from '../components/task-edit-dialog/task-edit-dialog.component';
import { DoNotAccountRequestDto } from '../dto/do-not-account-dto';
import { GetTaskListRequestDto } from '../dto/get-task-list-request-dto';
import { fixGetTaskListResultDto, GetTaskListResultDto } from '../dto/get-task-list-result-dto';
import { GetTaskReceiptListRequestDto } from '../dto/get-task-receipt-list-request-dto';
import { fixGetTaskReceiptListResultDto, GetTaskReceiptListResultDto } from '../dto/get-task-receipt-list-result-dto';
import { GetTaskTimeListRequest } from '../dto/get-task-time-list-request';
import { fixTaskEntryDto, TaskEntryDto } from '../dto/task-entry-dto';
import { TaskEntrySaveRequestDto } from '../dto/task-entry-save-request-dto';
import { TaskPriorityDto } from '../dto/task-priority-dto';
import { TaskQueueDto } from '../dto/task-queue-dto';
import { fixTaskReceiptEditDto, TaskReceiptEditDto } from '../dto/task-receipt-edit-dto';
import { TaskReceiptEditRequestDto } from '../dto/task-receipt-edit-request-dto';
import { TaskStateDto } from '../dto/task-state-dto';
import { TaskTimeListResultDto } from '../dto/task-time-list-result-dto';
import { GetTasksByIdRequestDto } from '../dto/get-tasks-by-id-request-dto';

const controller = "Tasks";

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  // Client-side cache
  private readonly _1DayMs = 24 * 60 * 60 * 1000;
  private readonly _15MinMs = 15 * 60 * 1000;
  private getTaskStates$: Observable<TaskStateDto[]>;
  private getTaskPriorities$: Observable<TaskPriorityDto[]>;
  private getUsers$: Observable<LegacyUserDto[]>;
  private getAddresses$: Observable<ErpCachedAddressDto[]>;

  constructor(
    private readonly api: Api1Service,
    private readonly apiHandler: ApiHandlerService,
    private readonly dialog: MatDialog
  ) { }

  async showTaskEditDialog(taskId?: number, callIds?: number[]) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = '350px';
    dialogConfig.data = {
      taskId: taskId,
      callIds: callIds,
    };

    const dialogRef = this.dialog.open<TaskEditDialogComponent, any, boolean>(TaskEditDialogComponent, dialogConfig);

    const result = await firstValueFrom(dialogRef.afterClosed());
    return result;
  }

  getTaskList(orderBy: string, descending: boolean, pageSize: number, pageIndex: number, search: string, taskStateId: number, taskUserFilterId: number, addressId: number, selectedRootQueueId: TaskQueueDto, selectedChildQueueId: TaskQueueDto, mandatorId: string): Observable<GetTaskListResultDto> {

    const request: GetTaskListRequestDto = {
      OrderBy: orderBy,
      Descending: descending,
      PageSize: pageSize,
      PageIndex: pageIndex,
      Search: search,
      TaskStateId: taskStateId,
      TaskUserFilterId: taskUserFilterId,
      AddressId: addressId,
      MandatorId: mandatorId,
      SelectedRootQueue: selectedRootQueueId,
      SelectedChildQueue: selectedChildQueueId
    };
    return this.api.post<GetTaskListResultDto>(controller, "GetTaskList", null, request).pipe(tap(x => fixGetTaskListResultDto(x)));
  }

  getTaskStates() {
    if (!this.getTaskStates$) {
      this.getTaskStates$ = this.api.get<TaskStateDto[]>(controller, "GetTaskStates").pipe(
        shareReplay(1, this._1DayMs),
        catchError(() => of(undefined))
      );
    }
    return this.getTaskStates$;
  }

  getTaskQueues(mandatorId: string) {
    return this.api.get<TaskQueueDto[]>(controller, "GetTaskQueues", { mandatorId: mandatorId });
  }

  getTasksById(taskIds: number[]) {

    const request: GetTasksByIdRequestDto = {
      TaskIds: taskIds
    };
    return this.api.post<TaskDto[]>(controller, "GetTasksById", null, request);
  }

  getTaskTimeList(filterDate: Date, searchText: string, mandatorId: string) {

    const request: GetTaskTimeListRequest = {
      FilterDate: filterDate,
      SearchText: searchText,
      MandatorId: mandatorId
    };

    return this.api.post<TaskTimeListResultDto>(controller, "GetTaskTimeList", null, request);
  }

  doNotAccount(request: DoNotAccountRequestDto) {
    return this.api.post<DoNotAccountRequestDto>(controller, "DoNotAccount", null, request);
  }

  getTaskReceiptEditData(addressId: number, receiptId: number, settlementDate: Date, mandatorId: string) {
    const request: TaskReceiptEditRequestDto = {
      AddressId: addressId,
      ReceiptId: receiptId,
      SettlementDate: settlementDate,
      MandatorId: mandatorId
    };

    return this.api.post<TaskReceiptEditDto>(controller, "GetTaskReceiptEdit", null, request).pipe(
      tap(x => fixTaskReceiptEditDto(x))
    );
  }

  createReceipt(dto: TaskReceiptDto) {
    return this.apiHandler.SendRequest(controller, "CreateReceipt", dto);
  }

  deleteReceipt(receiptId: number, mandatorId: string) {
    return this.apiHandler.SendRequest(controller, "DeleteReceipt", { receiptId: receiptId, mandatorId: mandatorId });
  }

  getTaskEntry(taskId: number) {
    return this.api.get<TaskEntryDto>(controller, "GetTaskEntry", { taskId: taskId }).pipe(
      tap(x => fixTaskEntryDto(x))
    );
  }

  getTaskPriorities() {
    if (!this.getTaskPriorities$) {
      this.getTaskPriorities$ = this.api.get<TaskPriorityDto[]>(controller, "GetTaskPriorities").pipe(
        shareReplay(1, this._1DayMs),
        catchError(() => of(undefined))
      );
    }
    return this.getTaskPriorities$;
  }

  getUsers() {
    if (!this.getUsers$) {
      this.getUsers$ = this.api.get<LegacyUserDto[]>(controller, "GetUsers").pipe(
        shareReplay(1, this._15MinMs),
        catchError(() => of(undefined))
      );
    }
    return this.getUsers$;
  }

  getUser(userId: string) {
    return this.api.get<LegacyUserDto>(controller, "GetUser", { userId: userId });
  }

  getTaskCalls(taskId: number) {
    return this.api.get<Phone3CxCallDto[]>(controller, "GetTaskCallsNew", { taskId: taskId }).pipe(
      tap(x => x.forEach(y => fixPhone3CxCallDto(y)))
    );
  }

  getAddresses() {
    if (!this.getAddresses$) {
      this.getAddresses$ = this.api.get<ErpCachedAddressDto[]>(controller, "GetAddresses").pipe(
        shareReplay(1, this._15MinMs),
        catchError(() => of(undefined))
      );
    }
    return this.getAddresses$;
  }

  getAddressesWithMandator(mandatorId: string) {
    return this.api.get<ErpCachedAddressDto[]>(controller, "GetAddressesWithMandator", { mandatorId: mandatorId });
  }

  saveTask(dto: TaskEntrySaveRequestDto) {
    return this.api.post<void>(controller, "SaveTask", null, dto);
  }

  getTaskReceiptList(orderBy: string, descending: boolean, pageSize: number, pageIndex: number, search: string, showToggle: number, addressId: number, mandatorId: string) {

    const request: GetTaskReceiptListRequestDto = {
      OrderBy: orderBy,
      Descending: descending,
      PageSize: pageSize,
      PageIndex: pageIndex,
      Search: search,
      ShowToggle: showToggle,
      AddressId: addressId,
      MandatorId: mandatorId
    };

    return this.api.post<GetTaskReceiptListResultDto>(controller, "GetTaskReceiptList", null, request).pipe(tap(x => fixGetTaskReceiptListResultDto(x)));
  }

  createTaskEnviroment(mandatorId: string) {
    return this.api.get<void>(controller, "CreateTaskEnviroment", { mandatorId: mandatorId });
  }

}
