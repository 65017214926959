import angular from "angular";
import moment from "moment";
import { ContactDto } from '../../Models/Contacts/ContactDto';

export class ConsolidationModel {

  $scope: any;
  SortOrder: number;
  LeftEntry: ContactDto;
  RightEntry: ContactDto;

  PhoneNumbers: any;
  MailAddresses: any;
  PostalAddresses: any;
  BaseData: any;
  AllNumberLabels: any;
  AllMailLabels: any;
  AllPostalLabels: any;
  AllMail: any;

  constructor(
    private readonly scope,
    private readonly $timeout,
    private readonly ApiHandlerService,
    private readonly ContactsService
  ) {
    this.$scope = scope;
    this.SortOrder = 0;
    this.LeftEntry = null;
    this.RightEntry = null;
  }

  CheckNull(string) {
    if (string) {
      return string;
    }
    return "< verwerfen >";
  }

  ValidPhoneLabelSelected(entity: any) {
    let count = 0;
    for (const key in this.PhoneNumbers) {
      if (this.PhoneNumbers[key].Result === entity.Result && entity.Result !== null) {
        count++;
      }
    }
    if (count > 1)
      return true;
    return false;
  }

  ValidMailLabelSelected(entity: any) {
    let count = 0;
    for (const key in this.MailAddresses) {
      if (this.MailAddresses[key].Result === entity.Result && entity.Result !== null) {
        count++;
      }
    }
    if (count > 1)
      return true;
    return false;
  }

  ValidPostalLabelSelected(entity: any) {
    let count = 0;
    for (const key in this.PostalAddresses) {
      if (this.PostalAddresses[key].Result === entity.Result && entity.Result !== null) {
        count++;
      }
    }
    if (count > 1)
      return true;
    return false;
  }


  CreateLabels() {
    this.BaseData = {
      "DisplayName": { Label: "Anzeigename", IsVisible: false },
      "GivenName": { Label: "Vorname", IsVisible: false },
      "MiddleName": { Label: "Zweiter Name", IsVisible: false },
      "LastName": { Label: "Nachname", IsVisible: false },
      "Suffix": { Label: "Titel", IsVisible: false },
      "Initials": { Label: "Initialen", IsVisible: false },
      "Nickname": { Label: "Spitzenname", IsVisible: false },
      "Company": { Label: "Firma", IsVisible: false },
      "Department": { Label: "Abteilung", IsVisible: false },
      "Position": { Label: "Position", IsVisible: false },
      "HomePage": { Label: "Homepage", IsVisible: false },
      "Birthday": { Label: "Geburtstag", IsVisible: false },
      "AssistantName": { Label: "Assistent", IsVisible: false },
      "OfficeLocation": { Label: "Büro", IsVisible: false },
      "Profession": { Label: "Beruf", IsVisible: false },
      "SpouseName": { Label: "Ehepartner", IsVisible: false }
    };

    this.PhoneNumbers = {};
    this.MailAddresses = {};
    this.PostalAddresses = {};
    this.AllNumberLabels = [null];
    this.AllMailLabels = [null];
    this.AllPostalLabels = [null];
    this.AllMail = [null];
    const model = this;

    angular.forEach(this.ContactsService.PhoneNumberLabels, (value, key) => {
      model.AllNumberLabels.push(value);
    });
    angular.forEach(this.ContactsService.EmailLabels, (value, key) => {
      model.AllMailLabels.push(value);
    });
    angular.forEach(this.ContactsService.PostalLabels, (value, key) => {
      model.AllPostalLabels.push(value);
    });
  }

  SetResult(collection) {
    angular.forEach(collection, (value, key) => {
      if (value.Right) {
        value.Result = value.Right;
        value.IsVisible = true;
      }
      if (value.Left) {
        value.Result = value.Left;
        value.IsVisible = true;
      }
      if (value.Left === value.Right && value.Left) {
        value.IsResolved = true;
      } else {
        value.IsResolved = false;
      }
    });
  }

  SelectedContactsChanged() {
    this.CreateLabels();
    if (this.LeftEntry) {
      this.BaseData["DisplayName"].Left = this.LeftEntry.DisplayName;
      this.BaseData["GivenName"].Left = this.LeftEntry.GivenName;
      this.BaseData["MiddleName"].Left = this.LeftEntry.MiddleName;
      this.BaseData["LastName"].Left = this.LeftEntry.LastName;
      this.BaseData["Suffix"].Left = this.LeftEntry.Suffix;
      this.BaseData["Initials"].Left = this.LeftEntry.Initials;
      this.BaseData["Nickname"].Left = this.LeftEntry.Nickname;
      this.BaseData["Company"].Left = this.LeftEntry.Company;
      this.BaseData["Department"].Left = this.LeftEntry.Department;
      this.BaseData["Position"].Left = this.LeftEntry.Position;
      this.BaseData["HomePage"].Left = this.LeftEntry.HomePage;
      if (this.LeftEntry.Birthday) {
        this.BaseData["Birthday"].Left = moment(this.LeftEntry.Birthday).format("DD.MM.YYYY");
      }
      this.BaseData["AssistantName"].Left = this.LeftEntry.AssistantName;
      this.BaseData["OfficeLocation"].Left = this.LeftEntry.OfficeLocation;
      this.BaseData["Profession"].Left = this.LeftEntry.Profession;
      this.BaseData["SpouseName"].Left = this.LeftEntry.SpouseName;
      for (let i = 0; i < this.LeftEntry.PhoneNumbers.length; i++) {
        const number = this.LeftEntry.PhoneNumbers[i];
        if (!this.PhoneNumbers[number.Number]) {
          this.PhoneNumbers[number.Number] = {
            Label: number.Number,
            Key: number.Number,
            IsVisible: true,
            SortOrder: this.SortOrder++
          };
        }
        this.PhoneNumbers[number.Number].Left = number.Label;
      }
      for (let i = 0; i < this.LeftEntry.MailAddresses.length; i++) {
        const mail = this.LeftEntry.MailAddresses[i];
        if (!this.MailAddresses[mail.Address]) {
          this.MailAddresses[mail.Address] = {
            Label: mail.Address,
            Key: mail.Address,
            IsVisible: true,
            SortOrder: this.SortOrder++
          };
        }
        this.MailAddresses[mail.Address].Left = mail.Label;
      }
      for (let i = 0; i < this.LeftEntry.PostalAddresses.length; i++) {
        const postal = this.LeftEntry.PostalAddresses[i];
        postal.FullAddress = [postal.Street, postal.PostalCode + " " + postal.City, postal.CountryOrRegion, postal.State].join("\n");
        postal.FullAddress = postal.FullAddress.replace(/\n/g, ";");
        if (!this.PostalAddresses[postal.FullAddress]) {
          this.PostalAddresses[postal.FullAddress] = {
            Label: postal.FullAddress,
            Key: postal.FullAddress,
            IsVisible: true,
            SortOrder: this.SortOrder++,
            Postal: postal
          };
        }
        this.PostalAddresses[postal.FullAddress].Left = postal.Label;
      }
    }
    if (this.RightEntry) {
      this.BaseData["DisplayName"].Right = this.RightEntry.DisplayName;
      this.BaseData["GivenName"].Right = this.RightEntry.GivenName;
      this.BaseData["MiddleName"].Right = this.RightEntry.MiddleName;
      this.BaseData["LastName"].Right = this.RightEntry.LastName;
      this.BaseData["Suffix"].Right = this.RightEntry.Suffix;
      this.BaseData["Initials"].Right = this.RightEntry.Initials;
      this.BaseData["Nickname"].Right = this.RightEntry.Nickname;
      this.BaseData["Company"].Right = this.RightEntry.Company;
      this.BaseData["Department"].Right = this.RightEntry.Department;
      this.BaseData["Position"].Right = this.RightEntry.Position;
      this.BaseData["HomePage"].Right = this.RightEntry.HomePage;
      if (this.RightEntry.Birthday) {
        this.BaseData["Birthday"].Right = moment(this.RightEntry.Birthday).format("DD.MM.YYYY");
      }
      this.BaseData["AssistantName"].Right = this.RightEntry.AssistantName;
      this.BaseData["OfficeLocation"].Right = this.RightEntry.OfficeLocation;
      this.BaseData["Profession"].Right = this.RightEntry.Profession;
      this.BaseData["SpouseName"].Right = this.RightEntry.SpouseName;
      for (let i = 0; i < this.RightEntry.PhoneNumbers.length; i++) {
        const number = this.RightEntry.PhoneNumbers[i];
        if (!this.PhoneNumbers[number.Number]) {
          this.PhoneNumbers[number.Number] = {
            Label: number.Number,
            Key: number.Number,
            IsVisible: true,
            SortOrder: this.SortOrder++
          };
        }
        this.PhoneNumbers[number.Number].Right = number.Label;
      }
      for (let i = 0; i < this.RightEntry.MailAddresses.length; i++) {
        const mail = this.RightEntry.MailAddresses[i];
        if (!this.MailAddresses[mail.Address]) {
          this.MailAddresses[mail.Address] = {
            Label: mail.Address,
            Key: mail.Address,
            IsVisible: true,
            SortOrder: this.SortOrder++
          };
        }
        this.MailAddresses[mail.Address].Right = mail.Label;
      }
      for (let i = 0; i < this.RightEntry.PostalAddresses.length; i++) {
        const postal = this.RightEntry.PostalAddresses[i];
        postal.FullAddress = [postal.Street, postal.PostalCode + " " + postal.City, postal.CountryOrRegion, postal.State].join("\n");
        postal.FullAddress = postal.FullAddress.replace(/\n/g, ";");
        if (!this.PostalAddresses[postal.FullAddress]) {
          this.PostalAddresses[postal.FullAddress] = {
            Label: postal.FullAddress,
            Key: postal.FullAddress,
            Postal: postal,
            IsVisible: true,
            SortOrder: this.SortOrder++
          };
        }
        this.PostalAddresses[postal.FullAddress].Right = postal.Label;
      }
    }
    this.SetResult(this.BaseData);
    this.SetResult(this.PhoneNumbers);
    this.SetResult(this.MailAddresses);
    this.SetResult(this.PostalAddresses);

    const model = this;

    this.$timeout(() => {
      if (model?.$scope?.ConsolidationForm) {
        model.$scope.ConsolidationForm.$setSubmitted();
        angular.forEach(model.$scope.ConsolidationForm.$$controls, (field) => {
          field.$setDirty();
        });
      }
    }, 50);
  }

  ConsolidateData() {
    let result;

    const consolidationResult = {
      DisplayName: this.BaseData["DisplayName"].Result,
      GivenName: this.BaseData["GivenName"].Result,
      MiddleName: this.BaseData["MiddleName"].Result,
      LastName: this.BaseData["LastName"].Result,
      Suffix: this.BaseData["Suffix"].Result,
      Initials: this.BaseData["Initials"].Result,
      Nickname: this.BaseData["Nickname"].Result,
      Company: this.BaseData["Company"].Result,
      Department: this.BaseData["Department"].Result,
      Position: this.BaseData["Position"].Result,
      HomePage: this.BaseData["HomePage"].Result,
      AssistantName: this.BaseData["AssistantName"].Result,
      OfficeLocation: this.BaseData["OfficeLocation"].Result,
      Profession: this.BaseData["Profession"].Result,
      SpouseName: this.BaseData["SpouseName"].Result,
      Birthday: undefined,
      PhoneNumbers: undefined,
      MailAddresses: undefined,
      PostalAddresses: undefined

    };

    if (this.BaseData["Birthday"] && this.BaseData["Birthday"].Result) {
      consolidationResult.Birthday = moment(this.BaseData["Birthday"].Result);
    }
    consolidationResult.PhoneNumbers = [];
    for (const key in this.PhoneNumbers) {
      const currentObject = this.PhoneNumbers[key];
      if (currentObject.Result) {
        let originalLabel = null;
        angular.forEach(this.ContactsService.PhoneNumberLabels, (value, key) => {
          if (value === currentObject.Result) {
            originalLabel = key;
          }
        });
        consolidationResult.PhoneNumbers.push({
          Label: currentObject.Result,
          Number: key,
          OriginalLabel: originalLabel
        });
      }
    }
    consolidationResult.MailAddresses = [];
    for (const key in this.MailAddresses) {
      const currentObject = this.MailAddresses[key];
      if (currentObject.Result) {
        let originalLabel = null;
        angular.forEach(this.ContactsService.EmailLabels, (value, key) => {
          if (value === currentObject.Result) {
            originalLabel = key;
          }
        });
        consolidationResult.MailAddresses.push({
          Label: currentObject.Result,
          Address: key,
          OriginalLabel: originalLabel
        });
      }
    }
    consolidationResult.PostalAddresses = [];
    for (const key in this.PostalAddresses) {
      const currentObject = this.PostalAddresses[key];
      if (currentObject.Result) {
        let originalLabel = null;
        angular.forEach(this.ContactsService.PostalLabels, (value, key) => {
          if (value === currentObject.Result) {
            originalLabel = key;
          }
        });
        consolidationResult.PostalAddresses.push({
          Label: currentObject.Result,
          City: currentObject.Postal.City,
          CountryOrRegion: currentObject.Postal.CountryOrRegion,
          PostalCode: currentObject.Postal.PostalCode,
          State: currentObject.Postal.State,
          Street: currentObject.Postal.Street,
          OriginalLabel: originalLabel
        });
      }
    }

    this.ApiHandlerService.SendRequest("Contacts", "ConsolidateContacts", { left: this.LeftEntry, right: this.RightEntry, result: consolidationResult }).then((data) => {
      result.LeftEntry = null;
      result.RightEntry = null;
      result.CreateLabels();
    });
    return result;
  }

}