<div class="dialog-container">

  <div mat-dialog-title class="dialog-title"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle>

    <div>
      <h2>Script Generator</h2>
    </div>

    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>

  </div>

  <mat-dialog-content class="mat-typography">

    <div class="content-container">

      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" [(selectedIndex)]="tabIndex">
        <mat-tab label="Variablen">
          <div class="row-container">
            <div>Vordefinierte Variablen </div>

            <div>
              <button mat-icon-button color="primary" (click)="save()" *ngIf="isEditing">
                <mat-icon>
                  save
                </mat-icon>
              </button>

              <button mat-icon-button color="primary" (click)="toggleEdit()">
                <mat-icon>edit</mat-icon>
              </button>
            </div>

          </div>

          <mat-divider></mat-divider>

          <div dkDropListGroup>
            <div
              cdkDropList
              [cdkDropListData]="variableVms"
              class="example-list"
              (cdkDropListDropped)="drop($event)">

              <div class="row-container" *ngFor="let vm of variableVms">

                <div class="form-container">
                  <div>
                    <mat-form-field>
                      <mat-label>Name</mat-label>
                      <input matInput [formControl]="vm.nameControl" [readonly]="!isEditing">
                    </mat-form-field>
                  </div>

                  <div>
                    <mat-form-field>
                      <mat-label>Wert</mat-label>
                      <input matInput [formControl]="vm.valueControl">
                    </mat-form-field>
                  </div>

                  <div>
                    <mat-form-field>
                      <mat-label>Muster</mat-label>
                      <input matInput [formControl]="vm.patternControl" [readonly]="!isEditing">
                    </mat-form-field>
                  </div>

                  <button mat-icon-button color="warn" (click)="removeVariable(vm)" *ngIf="isEditing">
                    <mat-icon>delete</mat-icon>
                  </button>

                </div>

                <mat-icon cdkDrag color="primary" *ngIf="isEditing">
                  dehaze
                </mat-icon>
              </div>

            </div>
          </div>


          <div class="row-container" *ngIf="isEditing">
            <div class="form-container">
              <div>
                <mat-form-field>
                  <mat-label>Name</mat-label>
                  <input matInput [formControl]="newVariableNameControl">
                </mat-form-field>
              </div>

              <div>
                <mat-form-field>
                  <mat-label>Wert</mat-label>
                  <input matInput [formControl]="newVariableValueControl">
                </mat-form-field>
              </div>

              <div>
                <mat-form-field>
                  <mat-label>Muster</mat-label>
                  <input matInput [formControl]="newVariablePatternControl">
                </mat-form-field>
              </div>

              <button mat-icon-button color="primary" (click)="addVariable()" [disabled]="!newVariableNameControl.value">
                <mat-icon>add</mat-icon>
              </button>

            </div>
          </div>

          <div class="row-container">

          </div>

          <mat-divider></mat-divider>


        </mat-tab>

        <mat-tab label="Script">

          <div class="row-container">
            <textarea readonly>{{scriptText}}</textarea>
          </div>

        </mat-tab>
      </mat-tab-group>

    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="row-container-end" *ngIf="tabIndex === 0">
      <button mat-raised-button color="primary" (click)="generateScript()">Script generieren</button>
    </div>


  </mat-dialog-actions>

</div>