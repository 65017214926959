export enum AppointmentType {
  CalendarPrivate = 1,
  CalendarOof = 2,
  CalendarElswhere = 3,
  CalendarTentative = 4,
  HolidayNotApproved = 10,
  HolidayApproved = 11,
  HolidayUnknown = 12,
  HolidayTradeSchool = 13,
  HolidayCompanyEvent = 14
}
