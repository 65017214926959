import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { SageExportContractQueueDialogComponent } from './components/sage-export-contract-queue-dialog/sage-export-contract-queue-dialog.component';
import { SageExportContractErrorDialogComponent } from './components/sage-export-contract-error-dialog/sage-export-contract-error-dialog.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    SageExportContractQueueDialogComponent,
    SageExportContractErrorDialogComponent
  ]
})
export class SageExportModule { }
