import { SageExportContractGroupDto, fixSageExportContractGroupDto } from "./sage-export-contract-group-dto";

export interface SageExportHistoryResultDto {
  completedGroups: SageExportContractGroupDto[];
  openGroups: SageExportContractGroupDto[];
  archivedGroups: SageExportContractGroupDto[];
}

export function SageExportHistoryResultDto(dto: SageExportHistoryResultDto) {
  if (dto) {
    for (const group of dto.completedGroups) {
      fixSageExportContractGroupDto(group);
    }
    for (const group of dto.openGroups) {
      fixSageExportContractGroupDto(group);
    }
    for (const group of dto.archivedGroups) {
      fixSageExportContractGroupDto(group);
    }
  }
}