/* eslint-disable @typescript-eslint/no-this-alias */
import angular, { IPromise, IQService, IScope, ITimeoutService } from 'angular';
import moment, { Moment } from 'moment';
import { ColumnDefinition, HestiaDataTable } from 'src/ajs/Directives/HestiaDataTableDirective';
import { addDefaultScopeFunctions } from 'src/ajs/Functions/add-default-scope-functions';
import { ExchangeDto } from 'src/ajs/Models/Administration/ExchangeDto';
import { GetPluginsResultDto } from 'src/ajs/Models/Administration/GetPluginsResultDto';
import { GetPrivilegeResult } from 'src/ajs/Models/Administration/GetPrivilegeResultDto';
import { GetStatistcsMetaDto } from 'src/ajs/Models/Administration/GetStatistcsMetaDto';
import { GetSystemResultDto } from 'src/ajs/Models/Administration/GetSystemResultDto';
import { GetUsersResultDto } from 'src/ajs/Models/Administration/GetUsersResultDto';
import { GroupDto } from 'src/ajs/Models/Administration/GroupDto';
import { LdapDto } from 'src/ajs/Models/Administration/LdapDto';
import { LoginDto } from 'src/ajs/Models/Administration/LoginDto';
import { PluginDto } from 'src/ajs/Models/Administration/PluginDto';
import { RequestLogActionDto } from 'src/ajs/Models/Administration/RequestLogActionDto';
import { RequestLogControllerDto } from 'src/ajs/Models/Administration/RequestLogControllerDto';
import { RoleRights } from 'src/ajs/Models/Administration/RoleRights';
import { ServiceActionDto } from 'src/ajs/Models/Administration/ServiceActionDto';
import { ServiceControllerDto } from 'src/ajs/Models/Administration/ServiceControllerDto';
import { SmppDto } from 'src/ajs/Models/Administration/SmppDto';
import { SmtpDto } from 'src/ajs/Models/Administration/SmtpDto';
import { TogglePluginActivityResultDto } from 'src/ajs/Models/Administration/TogglePluginActivityResultDto';
import { LegacyMandatorDto } from 'src/ajs/Models/LegacyMandatorDto';
import { SimpleStringResultDto } from 'src/ajs/Models/SimpleStringResult';
import { ThemeDto } from 'src/ajs/Models/ThemeDto';
import { LegacyUserDto } from 'src/ajs/Models/User/LegacyUserDto';
import { ApiHandlerService } from 'src/ajs/Services/ApiHandlerService';
import { GlobalValueService } from 'src/ajs/Services/GlobalValueService';
import { StorageService, StorageServiceInstance } from 'src/ajs/Services/StorageService';
import { addNavigation, addNavigationCallback, arrayRemove, convertDate, copyValues, formatDate } from 'src/ajs/Utils/HestiaCommon';
import { INavigationScope } from 'src/ajs/Utils/INavigationScope';


const RightsEnum = RoleRights;

createAdministrationController.$inject = ['$scope', '$mdToast', '$mdDialog', '$q', '$timeout', 'ApiHandlerService', 'StorageService', 'GlobalValueService', 'theme'];

function pad(num, size) {
  let s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

export function createAdministrationController(
  $scope: IAdministrationControllerScope,
  $mdToast: angular.material.IToastService,
  $mdDialog: angular.material.IDialogService,
  $q: IQService,
  $timeout: ITimeoutService,
  ApiHandlerService: ApiHandlerService,
  StorageService: StorageService,
  GlobalValueService: GlobalValueService,
  theme: ThemeDto
) {
  /* ++++++++++++++++++++++++++++++++ Controller constiablen ++++++++++++++++++++++++++++++++ */
  addDefaultScopeFunctions($scope, theme);
  // Init Storage and Navigation
  $scope.Storage = StorageService.CreateInstance("AdministrationController");
  addNavigation($scope, "System");
  addNavigationCallback($scope, "System", function () {
    $scope.SystemModel.Initialize();
  });
  addNavigationCallback($scope, "Users", function () {
    $scope.UsersModel.Initialize();
  });
  addNavigationCallback($scope, "Plugins", function () {
    $scope.PluginsModel.Initialize();
  });
  addNavigationCallback($scope, "Statistics", function () {
    $scope.StatisticsModel.Initialize();
  });

  $scope.$on('bindMandator', function (event: any, mandator: LegacyMandatorDto) {
    $scope.SelectedMandator = mandator;
    $scope.Privileges.Initialize();
    $scope.UsersModel.Initialize();
    $scope.PluginsModel.Initialize();
    $scope.SystemModel.Initialize();
  });

  $scope.OnSignatureDrop = function (file: any) {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = function () {
      $timeout(function () {
        $scope.SystemModel.Exchange.MailSignatureFileName = file.name;
        $scope.SystemModel.Exchange.MailSignatureContent = reader.result;
      });
    };
  };
  $scope.Privileges = {
    System: RoleRights.Deny,
    Users: RightsEnum.Deny,
    Plugins: RightsEnum.Deny,
    Navigation: RightsEnum.Deny,
    Mandator: RightsEnum.Deny,
    Initialize: function () {
      const deferred = $q.defer();
      const object = this;
      ApiHandlerService.SendRequest("Administration", "GetUserPrivileges").then(function (data: GetPrivilegeResult) {
        object.System = data.System;
        object.Mandator = data.Mandator; // TODO: Eigene Privilegien?
        object.Users = data.Users;
        object.Navigation = data.Navigation;
        object.Plugins = data.Plugins;
        $timeout(function () {
          deferred.resolve();
        }, 0, false);
      });
      return deferred.promise;
    }
  };
  $scope.StatisticsModel = {
    StartDate: moment().add(-7, 'days'),
    EndDate: moment().add(1, 'days'),
    FormatMilliseconds: function (value: number | string) {
      if (typeof value === 'string') {
        value = parseInt(value);
      }

      if (value > 1000) {
        let seconds = value / 1000;
        const ms = value % 1000;
        if (seconds > 60) {
          let minutes = seconds / 60;
          seconds = seconds % 60;
          return minutes + ":" + seconds + "." + pad(ms, 3);
        } else {
          return seconds + "." + pad(ms, 3);
        }

      }
      else {
        return "0." + pad(value, 3);
      }
    },
    FormatByte: function (value: number) {
      const mb = value / 1024 / 1024;
      return mb.toFixed(2) + " MB";
    },
    FormatType: function (type: number) {
      switch (type) {
        case 0:
          return "GET";
        case 1:
          return "POST";
        case 2:
          return "HEAD";
        case 3:
          return "NONE";
      }
      return "GET";
    },
    FormatRouteSource: function (id: any) {
      return $scope.StatisticsModel.Data.Routing.Sources.filter(f => f.Id === id)[0].Ip;
    },
    FormatServiceSource: function (id: any) {
      if (id) {
        return $scope.StatisticsModel.Data.Services.Sources.filter(f => f.Id === id)[0].Ip;
      } else {
        return "localhost";
      }

    },
    ControllerSelected: function (controller: any) {
      $scope.StatisticsModel.SelectedAction = null;
      if (controller) {
        $scope.StatisticsModel.IsLoading = true;
        ApiHandlerService.SendRequest("Administration", "GetRouteControllerActions", { controllerId: controller.Id, startDate: $scope.StatisticsModel.StartDate, endDate: $scope.StatisticsModel.EndDate }).then(function (data: RequestLogControllerDto) {
          copyValues(data, controller);
          $scope.StatisticsModel.SelectedController = controller;
          $scope.StatisticsModel.ActionsTable.UpdateSource(controller.Actions.slice(), $timeout);
          $scope.StatisticsModel.IsLoading = false;
        });
      } else {
        $scope.StatisticsModel.SelectedController = null;
      }
    },
    ServiceControllerSelected: function (controller: any) {
      if (controller) {
        $scope.StatisticsModel.IsLoading = true;
        ApiHandlerService.SendRequest("Administration", "GetServiceControllerActions", { controllerId: controller.Id, startDate: $scope.StatisticsModel.StartDate, endDate: $scope.StatisticsModel.EndDate }).then(function (data: ServiceControllerDto) {
          copyValues(data, controller);
          $scope.StatisticsModel.SelectedServiceController = controller;
          $scope.StatisticsModel.ServiceActionsTable.UpdateSource(controller.Actions.slice(), $timeout);
          $scope.StatisticsModel.IsLoading = false;
        });
      } else {
        $scope.StatisticsModel.SelectedServiceController = null;
      }
    },
    ActionSelected: function (action: any) {
      if (action) {
        $scope.StatisticsModel.IsLoading = true;
        ApiHandlerService.SendRequest("Administration", "GetRouteActionsRequests", { actionId: action.Id, startDate: $scope.StatisticsModel.StartDate, endDate: $scope.StatisticsModel.EndDate }).then(function (data: RequestLogActionDto) {
          copyValues(data, action);
          $scope.StatisticsModel.SelectedAction = action;
          $scope.StatisticsModel.RequestsTable.UpdateSource(action.Requests.slice(), $timeout);
          $scope.StatisticsModel.IsLoading = false;
        });
      } else {
        $scope.StatisticsModel.SelectedAction = null;
      }
    },
    ServiceActionSelected: function (action: any) {
      if (action) {
        $scope.StatisticsModel.IsLoading = true;
        ApiHandlerService.SendRequest("Administration", "GetServiceActionsRequests", { actionId: action.Id, startDate: $scope.StatisticsModel.StartDate, endDate: $scope.StatisticsModel.EndDate }).then(function (data: ServiceActionDto) {
          copyValues(data, action);
          $scope.StatisticsModel.SelectedServiceAction = action;
          $scope.StatisticsModel.ServiceRequestsTable.UpdateSource(action.Requests.slice(), $timeout);
          $scope.StatisticsModel.IsLoading = false;
        });
      } else {
        $scope.StatisticsModel.SelectedServiceAction = null;
      }
    },
    Initialize: function () {
      $scope.StatisticsModel.IsLoading = true;
      $scope.StatisticsModel.SelectedController = null;
      if (!$scope.StatisticsModel.ControllersTable) {
        $scope.StatisticsModel.ControllersTable = new HestiaDataTable(StorageService.CreateInstance("AdministrationStatisticsTable"), $timeout, $scope);
        $scope.StatisticsModel.ControllersTable.Options.Select = false;
        $scope.StatisticsModel.ControllersTable.ItemClicked = $scope.StatisticsModel.ControllerSelected;
        $scope.StatisticsModel.ControllersTable.UpdateColumns([
          new ColumnDefinition({ PropertyPath: 'Id', Heading: 'Id', HideHeading: true, IsVisible: false, ColumnWidth: 25, Resizeable: false, Sortable: true }),
          new ColumnDefinition({ PropertyPath: 'Name', Heading: 'Controller', Resizeable: true, Sortable: true }),
          new ColumnDefinition({ PropertyPath: 'TotalUpstreamSize', Align: 'right', Heading: 'Upload', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatByte }),
          new ColumnDefinition({ PropertyPath: 'TotalDownstreamSize', Align: 'right', Heading: 'Download', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatByte }),
          new ColumnDefinition({ PropertyPath: 'RequestCount', Align: 'right', Heading: 'Anfragen', Resizeable: true, Sortable: true }),
          new ColumnDefinition({ PropertyPath: 'ErrorCount', Align: 'right', Heading: 'Fehler', Resizeable: true, Sortable: true }),
          new ColumnDefinition({ PropertyPath: 'TotalDuration', Align: 'right', Heading: 'Gesamtzeit', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatMilliseconds }),
          new ColumnDefinition({ PropertyPath: 'AverageDuration', Align: 'right', Heading: 'Durchschnitt', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatMilliseconds }),
          new ColumnDefinition({ PropertyPath: 'MinDuratin', Align: 'right', Heading: 'Minimal', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatMilliseconds }),
          new ColumnDefinition({ PropertyPath: 'MaxDuration', Align: 'right', Heading: 'Maximal', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatMilliseconds })
        ]);
      }
      if (!$scope.StatisticsModel.ServicesTable) {
        $scope.StatisticsModel.ServicesTable = new HestiaDataTable(StorageService.CreateInstance("AdministrationServicesTable"), $timeout, $scope);
        $scope.StatisticsModel.ServicesTable.Options.Select = false;
        $scope.StatisticsModel.ServicesTable.ItemClicked = $scope.StatisticsModel.ServiceControllerSelected;
        $scope.StatisticsModel.ServicesTable.UpdateColumns([
          new ColumnDefinition({ PropertyPath: 'Id', Heading: 'Id', HideHeading: true, IsVisible: false, ColumnWidth: 25, Resizeable: false, Sortable: true }),
          new ColumnDefinition({ PropertyPath: 'Name', Heading: 'Controller', Resizeable: true, Sortable: true }),
          new ColumnDefinition({ PropertyPath: 'TotalUpstreamSize', Align: 'right', Heading: 'Upload', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatByte }),
          new ColumnDefinition({ PropertyPath: 'TotalDownstreamSize', Align: 'right', Heading: 'Download', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatByte }),
          new ColumnDefinition({ PropertyPath: 'RequestCount', Align: 'right', Heading: 'Anfragen', Resizeable: true, Sortable: true }),
          new ColumnDefinition({ PropertyPath: 'ErrorCount', Align: 'right', Heading: 'Fehler', Resizeable: true, Sortable: true }),
          new ColumnDefinition({ PropertyPath: 'TotalDuration', Align: 'right', Heading: 'Gesamtzeit', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatMilliseconds }),
          new ColumnDefinition({ PropertyPath: 'AverageDuration', Align: 'right', Heading: 'Durchschnitt', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatMilliseconds }),
          new ColumnDefinition({ PropertyPath: 'MinDuratin', Align: 'right', Heading: 'Minimal', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatMilliseconds }),
          new ColumnDefinition({ PropertyPath: 'MaxDuration', Align: 'right', Heading: 'Maximal', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatMilliseconds })
        ]);
      }
      if (!$scope.StatisticsModel.ActionsTable) {
        $scope.StatisticsModel.ActionsTable = new HestiaDataTable(StorageService.CreateInstance("AdministrationStatisticsControllerTable"), $timeout, $scope);
        $scope.StatisticsModel.ActionsTable.Options.Select = false;
        $scope.StatisticsModel.ActionsTable.ItemClicked = $scope.StatisticsModel.ActionSelected;
        $scope.StatisticsModel.ActionsTable.UpdateColumns([
          new ColumnDefinition({ PropertyPath: 'Id', Heading: 'Id', HideHeading: true, IsVisible: false, ColumnWidth: 25, Resizeable: false, Sortable: true }),
          new ColumnDefinition({ PropertyPath: 'Action', Heading: 'Route', Resizeable: true, Sortable: true }),
          new ColumnDefinition({ PropertyPath: 'TotalUpstreamSize', Align: 'right', Heading: 'Upload', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatByte }),
          new ColumnDefinition({ PropertyPath: 'TotalDownstreamSize', Align: 'right', Heading: 'Download', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatByte }),
          new ColumnDefinition({ PropertyPath: 'RequestCount', Align: 'right', Heading: 'Anfragen', Resizeable: true, Sortable: true }),
          new ColumnDefinition({ PropertyPath: 'ErrorCount', Align: 'right', Heading: 'Fehler', Resizeable: true, Sortable: true }),
          new ColumnDefinition({ PropertyPath: 'TotalDuration', Align: 'right', Heading: 'Gesamtzeit', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatMilliseconds }),
          new ColumnDefinition({ PropertyPath: 'AverageDuration', Align: 'right', Heading: 'Durchschnitt', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatMilliseconds }),
          new ColumnDefinition({ PropertyPath: 'MinDuratin', Align: 'right', Heading: 'Minimal', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatMilliseconds }),
          new ColumnDefinition({ PropertyPath: 'MaxDuration', Align: 'right', Heading: 'Maximal', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatMilliseconds })
        ]);
      }
      if (!$scope.StatisticsModel.ServiceActionsTable) {
        $scope.StatisticsModel.ServiceActionsTable = new HestiaDataTable(StorageService.CreateInstance("AdministrationServiceActionsTable"), $timeout, $scope);
        $scope.StatisticsModel.ServiceActionsTable.Options.Select = false;
        $scope.StatisticsModel.ServiceActionsTable.ItemClicked = $scope.StatisticsModel.ServiceActionSelected;
        $scope.StatisticsModel.ServiceActionsTable.UpdateColumns([
          new ColumnDefinition({ PropertyPath: 'Id', Heading: 'Id', HideHeading: true, IsVisible: false, ColumnWidth: 25, Resizeable: false, Sortable: true }),
          new ColumnDefinition({ PropertyPath: 'Action', Heading: 'Route', Resizeable: true, Sortable: true }),
          new ColumnDefinition({ PropertyPath: 'TotalUpstreamSize', Align: 'right', Heading: 'Upload', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatByte }),
          new ColumnDefinition({ PropertyPath: 'TotalDownstreamSize', Align: 'right', Heading: 'Download', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatByte }),
          new ColumnDefinition({ PropertyPath: 'RequestCount', Align: 'right', Heading: 'Anfragen', Resizeable: true, Sortable: true }),
          new ColumnDefinition({ PropertyPath: 'ErrorCount', Align: 'right', Heading: 'Fehler', Resizeable: true, Sortable: true }),
          new ColumnDefinition({ PropertyPath: 'TotalDuration', Align: 'right', Heading: 'Gesamtzeit', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatMilliseconds }),
          new ColumnDefinition({ PropertyPath: 'AverageDuration', Align: 'right', Heading: 'Durchschnitt', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatMilliseconds }),
          new ColumnDefinition({ PropertyPath: 'MinDuratin', Align: 'right', Heading: 'Minimal', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatMilliseconds }),
          new ColumnDefinition({ PropertyPath: 'MaxDuration', Align: 'right', Heading: 'Maximal', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatMilliseconds })
        ]);
      }
      if (!$scope.StatisticsModel.RequestsTable) {
        $scope.StatisticsModel.RequestsTable = new HestiaDataTable(StorageService.CreateInstance("AdministrationStatisticsActionTable"), $timeout, $scope);
        $scope.StatisticsModel.RequestsTable.Options.Select = false;
        $scope.StatisticsModel.RequestsTable.UpdateColumns([
          new ColumnDefinition({ PropertyPath: 'Id', Heading: 'Id', HideHeading: true, IsVisible: false, ColumnWidth: 25, Resizeable: false, Sortable: true }),
          new ColumnDefinition({ PropertyPath: 'SourceId', Heading: 'Quelle', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatRouteSource }),
          new ColumnDefinition({ PropertyPath: 'Type', Heading: 'Art', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatType }),
          new ColumnDefinition({ PropertyPath: 'ResponseCode', Heading: 'Code', Resizeable: true, Sortable: true }),
          new ColumnDefinition({ PropertyPath: 'Date', Heading: 'Zugriff', Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDate }),
          new ColumnDefinition({ PropertyPath: 'Duration', Heading: 'Zeit', Align: 'right', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatMilliseconds }),
          new ColumnDefinition({ PropertyPath: 'RequestSize', Align: 'right', Heading: 'Upload', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatByte }),
          new ColumnDefinition({ PropertyPath: 'ResponseSize', Align: 'right', Heading: 'Download', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatByte })
        ]);
      }
      if (!$scope.StatisticsModel.ServiceRequestsTable) {
        $scope.StatisticsModel.ServiceRequestsTable = new HestiaDataTable(StorageService.CreateInstance("AdministrationServiceRequestsTable"), $timeout, $scope);
        $scope.StatisticsModel.ServiceRequestsTable.Options.Select = false;
        $scope.StatisticsModel.ServiceRequestsTable.UpdateColumns([
          new ColumnDefinition({ PropertyPath: 'Id', Heading: 'Id', HideHeading: true, IsVisible: false, ColumnWidth: 25, Resizeable: false, Sortable: true }),
          new ColumnDefinition({ PropertyPath: 'SourceId', Heading: 'Quelle', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatServiceSource }),
          new ColumnDefinition({ PropertyPath: 'Success', Heading: 'Erfolg', Resizeable: true, Sortable: true, Template: "/ClientApp/src/ajs/Views/Common/BoolToCheckboxDisabledTemplate.htm" }),
          new ColumnDefinition({ PropertyPath: 'Date', Heading: 'Zugriff', Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDate }),
          new ColumnDefinition({ PropertyPath: 'Duration', Heading: 'Zeit', Align: 'right', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatMilliseconds }),
          new ColumnDefinition({ PropertyPath: 'RequestSize', Align: 'right', Heading: 'Upload', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatByte }),
          new ColumnDefinition({ PropertyPath: 'ResponseSize', Align: 'right', Heading: 'Download', Resizeable: true, Sortable: true, Formatter: $scope.StatisticsModel.FormatByte })
        ]);
      }
      ApiHandlerService.SendRequest("Administration", "GetStatisticsModel", { startDate: $scope.StatisticsModel.StartDate, endDate: $scope.StatisticsModel.EndDate }).then((data: GetStatistcsMetaDto) => {
        $scope.StatisticsModel.Data = data;
        $scope.StatisticsModel.ControllersTable.UpdateSource($scope.StatisticsModel.Data.Routing.Controllers, $timeout);
        $scope.StatisticsModel.ServicesTable.UpdateSource($scope.StatisticsModel.Data.Services.Controllers, $timeout);
        $scope.StatisticsModel.IsLoading = false;
      });
    }
  } as StatisticsModel;
  $scope.SystemModel = {
    Id: null,
    SendTestMessage: function (ev: any) {
      const confirm = $mdDialog.prompt()
        .title('E-Mailverbindung testen')
        .textContent('Bitte geben Sie eine gültige Empfängeradresse ein.')
        .placeholder('test@example.com')
        .ariaLabel('Receiver')
        .targetEvent(ev)
        .required(true)
        .ok('Testen!')
        .cancel('Abbrechen');

      $mdDialog.show(confirm).then(function (result: string) {
        ApiHandlerService.SendRequest("Administration", "SendTestEmail", { model: $scope.SystemModel.Smtp, receiver: result }).then(function (data: SimpleStringResultDto) {
          const toast = $mdToast.simple()
            .textContent(data.Result)
            .position('bottom right')
            .capsule(true);
          $mdToast.show(toast);
        });
      });
    },
    SendTestSms: function (ev: any) {
      const confirm = $mdDialog.prompt()
        .title('SMPP Testen')
        .textContent('Bitte geben Sie eine gültige Empfängernummer ein.')
        .placeholder('+436601234567')
        .ariaLabel('Receiver')
        .targetEvent(ev)
        .required(true)
        .ok('Testen!')
        .cancel('Abbrechen');

      $mdDialog.show(confirm).then(function (result) {
        ApiHandlerService.SendRequest("Administration", "SendTestSms", { model: $scope.SystemModel.Smpp, receiver: result }).then(function (data) {
          const toast = $mdToast.simple()
            .textContent(data.Result)
            .position('bottom right')
            .capsule(true);
          $mdToast.show(toast);
        });
      });
    },
    SubmitChanges: function () {
      this.IsLoading = true;
      const object = this;
      ApiHandlerService.SendRequest("Administration", "SetSystemModel", object).then(function (data: GetSystemResultDto) {
        object.Id = data.Id;
        object.Smtp = data.Smtp;
        object.Ldap = data.Ldap;
        object.Smpp = data.Smpp;
        object.Login = data.Login;
        object.Login.RemoveWhitelist = function (address) {
          if (object.Login.Whitelist) {
            arrayRemove(object.Login.Whitelist, address);
          }
        };
        object.Login.AddAddress = function (address) {
          if (!object.Login.Whitelist) {
            arrayRemove(object.Login.Whitelist, address);
            object.Login.Whitelist = [];
          }
          object.Login.Whitelist.push(address);
        };
        object.Exchange = data.Exchange;
        object.IsLoading = false;
      });
    },
    Initialize: function () {
      const deferred = $q.defer();
      const object = this;
      object.IsLoading = true;
      ApiHandlerService.SendRequest("Administration", "GetSystemModel", { id: $scope.SelectedMandator.Id }).then(function (data: GetSystemResultDto) {
        object.Id = data.Id;
        object.Smtp = data.Smtp;
        object.Ldap = data.Ldap;
        object.Smpp = data.Smpp;
        object.Exchange = data.Exchange;
        object.Login = data.Login;
        object.Login.RemoveWhitelist = function (address: string) {
          if (object.Login.Whitelist) {
            arrayRemove(object.Login.Whitelist, address);
          }
        };
        object.Login.AddAddress = function (address) {
          if (!object.Login.Whitelist) {
            arrayRemove(object.Login.Whitelist, address);
            object.Login.Whitelist = [];
          }
          object.Login.Whitelist.push(address);
        };
        object.IsLoading = false;
        $timeout(function () {
          deferred.resolve();
        }, 0, false);
      });
      return deferred.promise;
    }
  } as SystemModel;
  $scope.UsersModel = {
    UpdateLdapUsers: function (ev: any) {
      const object = this;
      this.IsLoading = true;
      ApiHandlerService.SendRequest("Administration", "UpdateLdapGroups", { mandatorId: $scope.SelectedMandator.Id }).then(function (data) {
        object.Initialize();
      });
    },
    DeleteGroup: function (group: GroupDto, ev: any) {
      const object = this;
      const confirm = $mdDialog.confirm()
        .title('Sind Sie sicher, dass Sie die Gruppe "' + group.Title + '" löschen wollen?')
        .textContent('Die Gruppe wird unwiderruflich entfernt und kann nicht wiederhergestellt werden.')
        .ariaLabel('Gruppe löschen.')
        .targetEvent(ev)
        .ok('Löschen!')
        .cancel('Abbrechen');

      $mdDialog.show(confirm).then(function () {
        ApiHandlerService.SendRequest("Administration", "RemoveGroup", { groupId: group.Id }).then(function (data) {
          if (data.Success) {
            object.Groups = object.Groups.filter(item => item !== group);
          }
          else {
            const toast = $mdToast.simple()
              .textContent(data.Message)
              .position('bottom right')
              .capsule(true);
            $mdToast.show(toast);
          }
        });
      }, function () { });
    },
    OpenEditGroupDialog: function (group: GroupDto, ev: any) {
      $mdDialog.show({
        targetEvent: ev,
        controller: 'EditMandatorGroupController',
        templateUrl: '/ClientApp/src/ajs/Views/Administration/EditMandatorGroupDialog.htm',
        locals: {
          ParentGroup: group,
          rights: $scope.Privileges.Users
        },
        parent: angular.element(document.body)
      });
    },
    OpenEditUserDialog: function (user: any, ev: any) {
      $mdDialog.show({
        targetEvent: ev,
        controller: 'EditUserController',
        templateUrl: '/ClientApp/src/ajs/Views/Administration/EditUserDialog.htm',
        locals: {
          ParentUser: user,
          rights: $scope.Privileges.Users,
          mandatorId: $scope.SelectedMandator.Id,
          parentScope: $scope
        },
        parent: angular.element(document.body)
      });
    },
    AddGroupDialog: function (ev: any) {
      const group = {
        IsNew: true,
        MandatorId: $scope.SelectedMandator.Id
      } as GroupDto;
      $scope.UsersModel.Groups.push(group);
      $mdDialog.show({
        targetEvent: ev,
        controller: 'EditMandatorGroupController',
        templateUrl: '/ClientApp/src/ajs/Views/Administration/EditMandatorGroupDialog.htm',
        locals: {
          ParentGroup: group,
          rights: $scope.Privileges.Users
        },
        parent: angular.element(document.body)
      });
    },
    Initialize: function () {
      const deferred = $q.defer();
      const object = this;
      object.IsLoading = true;
      ApiHandlerService.SendRequest("Administration", "GetUsersModel", { mandatorId: $scope.SelectedMandator.Id }).then(function (data: GetUsersResultDto) {
        object.Users = data.Users;
        object.Groups = data.Groups;
        object.InheritedGroups = data.InheritedGroups;
        object.HasLdapConnection = data.HasLdapConnection;
        object.IsLoading = false;

        $timeout(function () {
          deferred.resolve();
        }, 0, false);
      });
      return deferred.promise;
    }
  } as UserModel;

  $scope.PluginsModel = {
    Initialize: function () {
      const deferred = $q.defer();
      const object = this;
      object.IsLoading = true;
      ApiHandlerService.SendRequest("Administration", "GetPluginsModel", { mandatorId: $scope.SelectedMandator.Id }).then(function (data: GetPluginsResultDto) {
        object.Plugins = data.Plugins;
        object.IsLoading = false;
        $timeout(function () {
          deferred.resolve();
        }, 0, false);
      });
      return deferred.promise;
    },
    EditMandatorPluginsDialog: function (ev: any) {
      $mdDialog.show({
        targetEvent: ev,
        controller: 'EditMandatorPluginsController',
        templateUrl: '/ClientApp/src/ajs/Views/Administration/EditMandatorPluginsDialog.htm',
        locals: {
          mandatorId: $scope.SelectedMandator.Id,
          parentScope: $scope
        },
        parent: angular.element(document.body)
      });
    },
    OpenPluginAdmin: function (ev: any, plugin: PluginDto) {
      $mdDialog.show({
        targetEvent: ev,
        controller: plugin.AdminJavaScriptController,
        templateUrl: plugin.AdminHtmlTemplate,
        locals: {
          mandatorId: $scope.SelectedMandator.Id,
          parentScope: $scope
        },
        parent: angular.element(document.body)
      });
    },
    ToggleActive: function (event: any, plugin: PluginDto) {

      if (!plugin.IsActive) {
        plugin.IsActive = true;
        const confirm = $mdDialog.confirm()
          .title(plugin.Name + " deaktivieren?")
          .textContent('Beim deaktivieren werden vorhandene Daten gelöscht, sind Sie sicher, dass sie "' + plugin.Name + '" für den Mandanten "' + $scope.SelectedMandator.Description + '" deaktivieren wollen?')
          .targetEvent(event)
          .ok('Deaktivieren!')
          .cancel('Abbrechen');

        $mdDialog.show(confirm).then(function (result) {
          ApiHandlerService.SendRequest("Administration", "TogglePluginActivity", { mandatorId: $scope.SelectedMandator.Id, pluginId: plugin.PluginId, isActive: !plugin.IsActive }).then(function (data: TogglePluginActivityResultDto) {
            plugin.IsActive = data.IsActive;
          });
        });
      }
      else {
        ApiHandlerService.SendRequest("Administration", "TogglePluginActivity", { mandatorId: $scope.SelectedMandator.Id, pluginId: plugin.PluginId, isActive: plugin.IsActive }).then(function (data: TogglePluginActivityResultDto) {
          plugin.IsActive = data.IsActive;
        });
      }
    }
  } as PluginsModel;


  $scope.InitializeModule = function () {
    $scope.Privileges.Initialize().then(function () {
      $scope.IsLoading = false;
      $timeout(() => $scope.Navigate($scope.Storage.GetOrCreate("ActiveTab", "System"), true), 20);
    });
  };

  $scope.AddMandator = function (ev: any) {
    const confirm = $mdDialog.prompt()
      .title('Mandanten anlegen')
      .textContent('Bitte geben Sie den Namen des neuen Mandanten ein')
      .placeholder('<Mandant>')
      .ariaLabel('Mandant')
      .targetEvent(ev)
      .required(true)
      .ok('Anlegen')
      .cancel('Abbrechen');

    $mdDialog.show(confirm).then(function (result: IPromise<unknown>) {
      ApiHandlerService.SendRequest("Administration", "CreateMandator", { parentId: $scope.SelectedMandator.Id, newName: result }).then(function (data: LegacyMandatorDto) {
        const toast = $mdToast.simple()
          .textContent(data.Result)
          .position('bottom right')
          .capsule(true);
        // TODO: Force reload of user bar mandator controller!
        $mdToast.show(toast);
      });
    });
  };


  $timeout(function () {
    $scope.SelectedMandator = GlobalValueService.get('currentMandator');
    /* Initialisiere View */
    $scope.InitializeModule();
  }, 1500);

}

interface IAdministrationControllerScope extends IScope, INavigationScope {
  Storage: StorageServiceInstance;
  SystemModel: SystemModel;
  UsersModel: UserModel;
  PluginsModel: PluginsModel;
  StatisticsModel: StatisticsModel;
  SelectedMandator: LegacyMandatorDto;
  Privileges: GetPrivilegeResult;
  IsLoading: boolean;

  OnSignatureDrop: (file: any) => void;
  InitializeModule: () => void;
  AddMandator: (ev: any) => void;
}

interface SystemModel {
  Id: string;
  Smtp: SmtpDto;
  Ldap: LdapDto;
  Smpp: SmppDto;
  Login: LoginDto;
  IsLoading: boolean;
  Exchange: ExchangeDto;

  SendTestMessage: (ev: any) => void;
  SendTestSms: (ev: any) => void;
  SubmitChanges: () => void;
  Initialize: () => IPromise<unknown>;

}

interface UserModel {
  IsLoading: boolean
  Groups: GroupDto[];
  Users: LegacyUserDto[];
  InheritedGroups: GroupDto[];
  HasLdapConnection: boolean;

  UpdateLdapUsers: (ev: any) => void;
  Initialize: () => IPromise<unknown>;
  DeleteGroup: (group: GroupDto, ev: any) => void;
  OpenEditGroupDialog: (group: GroupDto, ev: any) => void;
  OpenEditUserDialog: (user: any, ev: any) => void;
  AddGroupDialog: (ev: any) => void;
}

interface PluginsModel {
  IsLoading: boolean;
  Plugins: PluginDto[];

  Initialize: () => IPromise<unknown>;
  EditMandatorPluginsDialog: (ev: any) => void;
  OpenPluginAdmin: (ev: any, plugin: PluginDto) => void;
  ToggleActive: (event: any, plugin: PluginDto) => void;
}

interface StatisticsModel {
  Data: GetStatistcsMetaDto;
  SelectedAction: any;
  IsLoading: boolean;
  SelectedController: any;
  ActionsTable: HestiaDataTable;
  SelectedServiceController: any;
  ServiceActionsTable: HestiaDataTable;
  RequestsTable: HestiaDataTable;
  SelectedServiceAction: any;
  ServiceRequestsTable: HestiaDataTable;
  ControllersTable: HestiaDataTable;
  ServicesTable: HestiaDataTable;
  StartDate: Moment;
  EndDate: Moment;

  Initialize: () => void;
  ControllerSelected: (controller: any) => void;
  ServiceControllerSelected: (controller: any) => void;
  ActionSelected: (action: any) => void;
  ServiceActionSelected: (action: any) => void;
  FormatByte: (value: number) => string;
  FormatMilliseconds: (value: number | string) => string;
  FormatRouteSource: (id: any) => string;
  FormatServiceSource: (id: any) => string;
  FormatType: (type: number) => string;
}




