import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { RouteParamsAjs } from 'src/app/ajs-upgraded-providers';
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import { CalendarShareDto } from '../../dto/calendar-share-dto';
import { PublicCalendarService } from '../../services/public-calendar.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-approve-sharing',
  templateUrl: './approve-sharing.component.html',
  styleUrls: ['./approve-sharing.component.scss']
})
export class ApproveSharingComponent implements OnInit, OnDestroy {

  isLoading = true;
  isRevoked = false;
  token: string;
  private readonly $routeParams: angular.route.IRouteParamsService;
  dto: CalendarShareDto;
  readonly formGroup: UntypedFormGroup;
  private readonly destroy$ = new Subject<void>();

  constructor(
    $routeParams: RouteParamsAjs,
    private readonly publicCalendarService: PublicCalendarService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly location: Location
  ) {
    this.$routeParams = $routeParams as angular.route.IRouteParamsService;

    this.formGroup = this.formBuilder.group({
      isIndefinitely: [null],
      validUntilUtc: [null, [
        Validators.required,
        CustomValidators.minToday,
      ]]
    });

    this.formGroup.get('isIndefinitely').valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe((isIndefinitely: boolean) => {

      // Wenn der RadioButton geändert, Datumsfeld enablen/disablen
      if (isIndefinitely) {
        this.formGroup.get('validUntilUtc').disable();
      }
      else {
        this.formGroup.get('validUntilUtc').enable();
      }
    });

    this.formGroup.patchValue({
      isIndefinitely: true,
      validUntilUtc: DateUtils.addMonths(new Date(), 1)
    });
  }

  async ngOnInit() {
    this.token = this.$routeParams['token'] as string;
    this.dto = await firstValueFrom(this.publicCalendarService.getCalendarShare(this.token));
    this.isLoading = false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  approve() {
    this.dto.isApproved = true;
    if (this.formGroup.value.isIndefinitely) {
      this.dto.validUntilUtc = new Date(3000, 0, 1);
    }
    else {
      this.dto.validUntilUtc = new Date(this.formGroup.value.validUntilUtc);
    }
    firstValueFrom(this.publicCalendarService.saveCalendarShare(this.dto));
  }

  isApprovedIndefinitely() {
    return this.dto.validUntilUtc.getFullYear() >= 2998;
  }

  revoke() {
    this.dto.isApproved = false;
    this.isRevoked = true;
    firstValueFrom(this.publicCalendarService.saveCalendarShare(this.dto));
  }

  goHome() {
    this.location.go('/PublicCalendar');
  }
}

