import { CallDirection } from "src/ajs/Models/Phone3cx/CallDirection";
import { TaskDto } from "src/ajs/Models/Tasks/TaskDto";
import { fixDate } from "src/ajs/Utils/DateUtils";

export interface PhoneListCallDto {
  CallId: number;
  StartDate: Date;
  Duration: number;
  MainExtensionDescription: string;
  OtherPartyNumber: string;
  ContactName: string;
  Address: string;
  Note: string;
  Direction: CallDirection;
  IsAnswered: boolean;
  Task: TaskDto;
}

export function fixGetPhoneListDto(dto: PhoneListCallDto) {
  dto.StartDate = fixDate(dto.StartDate);
}
