import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'replace' })
export class ReplacePipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    if (value && typeof value === "string" && args?.length === 2) {
      return value.replace(args[0], args[1]);
    }
    return value;
  }
}
