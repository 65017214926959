import angular, { ITimeoutService } from "angular";
import moment from 'moment';
import { ColumnDefinition, HestiaDataTable } from 'src/ajs/Directives/HestiaDataTableDirective';
import { addDefaultScopeFunctions } from 'src/ajs/Functions/add-default-scope-functions';
import { QueryArticleResultDto } from 'src/ajs/Models/Erp/QueryArticleResultDto';
import { LabelPrintDataDto } from 'src/ajs/Models/Label/LabelPrintDataDto';
import { SimpleStringResultDto } from 'src/ajs/Models/SimpleStringResult';
import { CheckExistingSerialResult } from 'src/ajs/Models/Systembuilder/CheckExistingSerialResultDto';
import { CheckSerialForArticleResult } from 'src/ajs/Models/Systembuilder/CheckSerialForArticleResultDto';
import { CheckSerialNumberResult } from 'src/ajs/Models/Systembuilder/CheckSerialNumberResult';
import { FindArticleBySearchtextResult } from 'src/ajs/Models/Systembuilder/FindArticleBySearchtextResult';
import { SystemDto } from 'src/ajs/Models/Systembuilder/SystemDto';
import { SystemPartDto } from 'src/ajs/Models/Systembuilder/SystemPartDto';
import { SystemPartUnistallReasonDto } from 'src/ajs/Models/Systembuilder/SystemPartUnistallReasonDto';
import { SystemQueryResultDto } from 'src/ajs/Models/Systembuilder/SystemQueryResultDto';
import { SystemStateDto } from 'src/ajs/Models/Systembuilder/SystemStateDto';
import { SystemTypeDto } from 'src/ajs/Models/Systembuilder/SystemTypeDto';
import { ThemeDto } from 'src/ajs/Models/ThemeDto';
import { SimpleUserDto } from 'src/ajs/Models/User/SimpleUserDto';
import { ApiHandlerService } from 'src/ajs/Services/ApiHandlerService';
import { IPanelServiceEx } from 'src/ajs/Services/IPanelServiceEx';
import { StorageService, StorageServiceInstance } from 'src/ajs/Services/StorageService';
import { UserService } from 'src/ajs/Services/UserService';
import { cloneObject, convertDate, formatDateShort } from 'src/ajs/Utils/HestiaCommon';
import { MandatorService } from "src/app/core/services/mandator.service";

createSystembuilderController.$inject = ['$scope', '$mdToast', '$mdDialog', '$timeout', '$mdPanel', 'UserService', 'ApiHandlerService', 'StorageService', 'theme', 'MandatorServiceNgx'];


export function createSystembuilderController(
  $scope: ISystembuilderControllerScope,
  $mdToast: angular.material.IToastService,
  $mdDialog: angular.material.IDialogService,
  $timeout: ITimeoutService,
  $mdPanel: IPanelServiceEx,
  UserService: UserService,
  ApiHandlerService: ApiHandlerService,
  StorageService: StorageService,
  theme: ThemeDto,
  mandatorServiceNgx: MandatorService
) {
  $scope.Filter = {};
  $scope.Filter.Addresses = {};
  $scope.EditElement = null;
  $scope.IsInEdit = false;
  $scope.ActiveTagText = "Übersicht";
  $scope.reportCount = 0;

  mandatorServiceNgx.selectedMandatorIdChanged.subscribe(() => {
    $scope.ReloadSystems();
    $scope.IsInEdit = false;
    $scope.EditElement = null;
    $scope.ActiveTagText = "Übersicht";
  });


  $scope.CheckKeyPress = (e) => {
    if (e.keyCode === 114) {
      $scope.OpenIdentcode(e);
      e.preventDefault();
    }
    if (e.keyCode === 115) {
      $scope.OpenPartSearch(e);
      e.preventDefault();
    }
  };

  $(window).on('keydown', $scope.CheckKeyPress);

  $scope.$on("$destroy", () => {
    $(window).off('keydown', $scope.CheckKeyPress);
  });

  $scope.Storage = StorageService.CreateInstance("SystembuilderController");
  addDefaultScopeFunctions($scope, theme);

  $scope.NavigateBack = () => {
    $scope.ReloadSystems();
    $scope.IsInEdit = false;
    $scope.EditElement = null;
    $scope.ActiveTagText = "Übersicht";
  };

  $scope.$on("navigateSystems", (event, args) => {
    $scope.NavigateBack();
  });
  $scope.$on("bindAddress", (event, args) => {
    $scope.HasFixedAddress = true;
    $scope.Filter.SelectedAddress = args;
    $scope.Filter.SelectedAddressId = args.Id;
    $scope.ApplyFilter();
    $timeout(() => {
      $scope.SystemsTable.Update();
    }, 50);
  });

  $scope.OpenSystemSheet = () => {
    window.open('/api/SystemBuilder/GenerateSystemReceipt/' + '?id=' + $scope.EditElement.Identcode, '_blank');
  };

  $scope.OpenSystemSheetTable = (identCode: string) => {
    window.open('/api/SystemBuilder/GenerateSystemReceipt/' + '?id=' + identCode, '_blank');
  };

  $scope.SendSystemSheet = (event) => {
    $mdDialog.show({
      targetEvent: event,
      controller: 'MailSendDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/MailSend/MailSendDialog.htm',
      locals: {
        //SelectedAddress.BillingEMail
        Parameters: {
          Subject: "Systemschein: " + $scope.EditElement.Identcode + ", " + $scope.EditElement.Name,
          CachedAddressId: $scope.EditElement.CachedAddressId
        },
        OnSubmit: (result) => {
          ApiHandlerService.SendRequest("SystemBuilder", "SendSystemReceipt", { identcode: $scope.EditElement.Identcode, mailMeta: result, mandatorId: mandatorServiceNgx.selectedMandatorId }).then((data) => {
            if (data.Success) {
              const toast = $mdToast.simple()
                .textContent("Systemschein wurde verschickt.")
                .position('bottom right')
                .capsule(true);
              $mdToast.show(toast);
            }
          });
        }
      },
      parent: angular.element(document.body)
    });
  };

  $scope.SendSystemSheetTable = (event, system) => {
    $mdDialog.show({
      targetEvent: event,
      controller: 'MailSendDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/MailSend/MailSendDialog.htm',
      locals: {
        //SelectedAddress.BillingEMail
        Parameters: {
          Subject: "Systemschein: " + system.Identcode + ", " + system.Name,
          CachedAddressId: system.CachedAddressId
        },
        OnSubmit: (result) => {
          ApiHandlerService.SendRequest("SystemBuilder", "SendSystemReceipt", { identcode: system.Identcode, mailMeta: result, mandatorId: mandatorServiceNgx.selectedMandatorId }).then((data) => {
            if (data.Success) {
              const toast = $mdToast.simple()
                .textContent("Systemschein wurde verschickt.")
                .position('bottom right')
                .capsule(true);
              $mdToast.show(toast);
            }
          });
        }
      },
      parent: angular.element(document.body)
    });
  };

  $scope.$on("applyFilters", (event, args) => {
    $timeout(() => {
      $scope.SystemsTable.Update();
    }, 50);
  });

  $scope.CreateSystem = (event) => {
    $scope.CreateNewSystemTemplate().then((model: SystemDto) => {
      $timeout(() => {
        $scope.SelectSystem(model);
      });
    });
  };

  $scope.SelectSystem = (system: SystemDto) => {
    $scope.SelectedTabIndex = 0;
    $scope.IsInEdit = true;
    system.IsLoadingParts = true;
    $scope.Filter.ArticleNumberInput = null;
    system.ActiveParts = 0;
    system.InactiveParts = 0;
    $scope.EditElement = cloneObject(system);
    $scope.EditElement.AddressSearchText = null;
    $scope.EditElement.CachedAddress = null;
    $scope.IsInEdit = true;
    $scope.ActiveTagText = "Bearbeite '" + system.Identcode + "'";
    $timeout(() => {
      $scope.EditElement.CachedAddress = system.CachedAddress;
    });
    ApiHandlerService.SendRequest("Systembuilder", "GetSystemParts", { identCode: system.Identcode, mandatorId: mandatorServiceNgx.selectedMandatorId }).then((data) => {
      $scope.EditElement.Parts = data.Parts;
      $scope.EditElement.Parts.forEach(f => {
        f.InstallDate = moment(f.InstallDate);
        f.InstallDateFormatted = f.InstallDate.format("DD.MM.YYYY");
        f.NewArticleNumber = f.ArticleNumber;
        if (f.UninstallDate) {
          f.UninstallDate = moment(f.UninstallDate);
          $scope.EditElement.InactiveParts++;
        } else {
          $scope.EditElement.ActiveParts++;
        }
      });
      $scope.EditElement.IsLoadingParts = false;
    });
  };

  $scope.OpenIdentcode = (event) => {
    $mdDialog.show({
      controller: 'OpenIdentcodeSearchDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/Systembuilder/Dialogs/IdentcodeSearchDialog.htm',
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        ParentScope: $scope
      },
      clickOutsideToClose: false,
      fullscreen: true
    });
  };

  $scope.OpenPartSearch = (event) => {
    $mdDialog.show({
      controller: 'OpenPartSearchDialogController',
      templateUrl: '/ClientApp/src/ajs/Views/Systembuilder/Dialogs/PartSearchDialog.htm',
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        OnSuccess: (identcode) => {
          const system = $scope.Model.Systems.filter(f => f.Identcode === identcode)[0];
          $scope.SelectSystem(system);
          $scope.RecalculateCountsAndLabels(system);
        }
      },
      clickOutsideToClose: false,
      fullscreen: true
    });
  };


  $scope.PrintLabels = (element) => {
    ApiHandlerService.SendRequest("Label", "PrintPluginTemplate", {
      plugin: $scope.Printing.Template.PluginName,
      pluginTemplateId: $scope.Printing.Template.PluginLabelId,
      printerId: $scope.Printing.SelectedPrinterId,
      paramDict: [
        {
          Key: "Identcode",
          Value: element.Identcode
        }, {
          Key: "IdentcodeHost",
          Value: element.Identcode + " | " + element.Name
        }, {
          Key: "IpText",
          Value: element.Ip
        }, {
          Key: "Quantity",
          Value: $scope.Printing.PrintQuantity
        }
      ]
    },
      true
    ).then(

      (data) => {
        $timeout(() => {
          if (!data.Success) {
            alert("Fehler beim Druck: " + data.Result);
          }
        });
      },

      (reason) => {
        $timeout(() => {
          const toast = $mdToast.simple()
            .textContent("Fehler beim Drucken")
            .position('bottom right');

          $mdToast.show(toast);
        });
      },

    );
  };

  $scope.CreateNewSystemTemplate = () => {
    return new Promise((resolve, reject) => {
      const model: SystemDto = {
        Identcode: null,
        IsAssembledSystem: true,
        SystemDate: moment(),
        Name: null,
        Ip: null,
        CustomerUser: null,
        Note: null,
        ActiveParts: 0,
        InactiveParts: 0,
        SystemUserId: UserService.Data.User.Id,
        SystemTypeId: $scope.Model.Types[0]?.Id,
        Parts: [],
        CachedAddressId: null,
        CachedAddress: null,
      } as SystemDto;
      if ($scope.HasFixedAddress) {
        model.CachedAddressId = $scope.Filter.SelectedAddressId;
        model.CachedAddress = $scope.Filter.SelectedAddress;
      }
      ApiHandlerService.SendRequest("Systembuilder", "GetValidIdentcode").then((data) => {
        $timeout(() => {
          model.Identcode = data.Result;
          resolve(model);
        });
      });
    });
  };


  $scope.FindArticleBySearchtext = (search) => {
    const result: FindArticleBySearchtextResult = {
      Success: false,
      Message: 'Ein unbekannter Fehler ist aufgetreten',
      Data: null
    };
    return new Promise((resolve, reject) => {
      if (search) {
        if (search.length === 5) {
          const number = parseInt(search);
          if (!isNaN(number) && number >= 0 && number <= 99999) {
            ApiHandlerService.SendRequest("Erp", "QueryArticleByIdentifier", { query: search, mandatorId: mandatorServiceNgx.selectedMandatorId }).then((data: QueryArticleResultDto) => {
              $timeout(() => {
                if (data.Result && data.Result.length) {
                  if (data.Result.length > 1) {
                    alert("Es wurden mehrere Artikel gefunden. Vorgang abgebrochen.");
                    resolve(result);
                    return;
                  }
                  data.Result[0].InstallDate = moment();
                  data.Result[0].InstallDateFormatted = data.Result[0].InstallDate.format("DD.MM.YYYY");
                  data.Result[0].NewArticleNumber = data.Result[0].ArticleNumber;
                  result.Success = true;
                  result.Message = null;
                  result.Data = data.Result[0];
                  resolve(result);
                } else {
                  result.Message = "Artikelnummer '" + search + "' konnte nicht gefunden werden";
                  resolve(result);
                }
              });
            });
            return;
          }
        } else {
          ApiHandlerService.SendRequest("Systembuilder", "QueryArticleBySerial", { query: search, mandatorId: mandatorServiceNgx.selectedMandatorId }).then((data: QueryArticleResultDto) => {
            $timeout(() => {
              if (data.Result && data.Result.length) {
                data.Result[0].InstallDate = moment();
                data.Result[0].InstallDateFormatted = data.Result[0].InstallDate.format("DD.MM.YYYY");
                data.Result[0].NewArticleNumber = data.Result[0].ArticleNumber;
                result.Success = true;
                result.Message = null;
                result.Data = data.Result[0];
                resolve(result);
              } else {
                result.Message = "Seriennummer '" + search + "' konnte nicht gefunden werden";
                resolve(result);
              }
            });
          });
        }
      }
    });
  };

  $scope.TryChangeArticle = ($event, part) => {
    if (part.ArticleNumber !== part.NewArticleNumber) {
      if (!part.IsChanging) {
        part.IsChanging = true;
        $scope.FindArticleBySearchtext(part.NewArticleNumber).then((data) => {
          $timeout(() => {
            if (data.Success) {
              if (confirm("Soll der Artikel überschrieben werden?")) {
                part.ArticleNumber = data.Data.ArticleNumber;
                part.NewArticleNumber = data.Data.NewArticleNumber;
                part.Description1 = data.Data.Description1;
                part.Description2 = data.Data.Description2;
                part.SerialNumber = data.Data.SerialNumber;
                $scope.SavePart(part, $scope.EditElement);
              } else {
                part.NewArticleNumber = part.ArticleNumber;
              }
            } else {
              part.NewArticleNumber = part.ArticleNumber;
              alert(data.Message);
            }
            part.IsChanging = false;
          });
        });
      }
    }
  };

  $scope.ResolveSerialNumber = (part: SystemPartDto) => {
    return new Promise((resolve, reject) => {
      if (part.SerialNumber) {
        resolve(part.SerialNumber);
      } else {
        const confirm = $mdDialog.prompt()
          .title("Seriennummernpflichtig")
          .textContent(part.ArticleNumber + " ist seriennummernpflichtig. Bitte geben Sie eine Seriennummer ein")
          .placeholder('Seriennummer')
          .ariaLabel('Seriennummer')
          .required(true)
          .ok('Okay!')
          .cancel('Abbrechen');
        $mdDialog.show(confirm).then((result) => {
          resolve(result);
        }, () => {
          resolve(null);
        });
      }
    });
  };

  $scope.CheckSerialNumber = (part, system, event) => {

    const serialResult: CheckSerialNumberResult = {
      Success: null,
      ProductKey: null,
    };

    return new Promise((resolve, reject) => {
      if (part.RequiresSerial) {
        $scope.ResolveSerialNumber(part).then((sn: string) => {
          if (sn) {
            part.SerialNumber = sn;
            ApiHandlerService.SendRequest("Systembuilder", "CheckSerialForArticle", { serialNumber: sn, articleNumber: part.ArticleNumber }).then((snData: CheckSerialForArticleResult) => {
              if (!snData.IsValid) {
                const confirm = $mdDialog.confirm()
                  .title("'" + sn + "' wurde nicht für Artikel '" + part.ArticleNumber + "' gefunden.")
                  .textContent("Die eingegebene Seriennummer konnte dem Arikel nicht zugeordnet werden. Soll Sie trotzdem gebucht werden?")
                  .ariaLabel('Fehler')
                  .ok('Trotzdem buchen')
                  .cancel('Lieber nicht');
                $mdDialog.show(confirm).then((result) => {
                  ApiHandlerService.SendRequest("Systembuilder", "CheckExistingSerial", { serialNumber: sn }).then((data: CheckExistingSerialResult) => {
                    serialResult.ProductKey = data.ProductKey;

                    if (data.IsFree) {
                      serialResult.Success = true;
                      resolve(serialResult);
                    } else {
                      $mdDialog.show({
                        controller: 'MoveSerialDialogController',
                        templateUrl: '/ClientApp/src/ajs/Views/Systembuilder/Dialogs/MoveSerialDialog.htm',
                        parent: angular.element(document.body),
                        targetEvent: event,
                        locals: {
                          Serial: sn,
                          FormerCodes: data.SystemsString,
                          CurrentIdentcode: $scope.EditElement.Identcode,
                          Reasons: $scope.Model.UninstallReasons,
                          Part: part,
                          OnClose: () => {
                            serialResult.Success = false;
                            resolve(serialResult);
                          },
                          OnSuccess: (reasonId, reason, sourceParts) => {
                            ApiHandlerService.SendRequest("Systembuilder", "UninstallPartsBySerial", { serialNumber: sn, targetSystem: system.Identcode, reasonId: reasonId, reason: reason, sourceParts: sourceParts }).then((data) => {
                              if (sourceParts.filter(f => f.Identcode === $scope.EditElement.Identcode)) {
                                serialResult.Success = 2;
                                resolve(serialResult);
                              }
                            });
                          }
                        },
                        clickOutsideToClose: false,
                        fullscreen: true
                      });
                    }
                  });
                }, () => {
                  serialResult.Success = false;

                  resolve(serialResult);
                });
              } else {
                ApiHandlerService.SendRequest("Systembuilder", "CheckExistingSerial", { serialNumber: sn }).then((data: CheckExistingSerialResult) => {
                  serialResult.ProductKey = data.ProductKey;

                  if (data.IsFree) {
                    serialResult.Success = true;
                    resolve(serialResult);
                  }
                  else {
                    $mdDialog.show({
                      controller: 'MoveSerialDialogController',
                      templateUrl: '/ClientApp/src/ajs/Views/Systembuilder/Dialogs/MoveSerialDialog.htm',
                      parent: angular.element(document.body),
                      targetEvent: event,
                      locals: {
                        Serial: sn,
                        FormerCodes: data.SystemsString,
                        CurrentIdentcode: $scope.EditElement.Identcode,
                        Reasons: $scope.Model.UninstallReasons,
                        Part: part,
                        OnClose: () => {
                          serialResult.Success = false;
                          resolve(serialResult);
                        },
                        OnSuccess: (reasonId: number | null, reason: string, sourceParts: SystemPartDto[]) => {
                          ApiHandlerService.SendRequest("Systembuilder", "UninstallPartsBySerial", { serialNumber: sn, targetSystem: system.Identcode, reasonId: reasonId, reason: reason, sourceParts: sourceParts }).then((data: SimpleStringResultDto) => {
                            if (sourceParts.filter(f => f.Identcode === $scope.EditElement.Identcode)) {
                              serialResult.Success = 2;
                              resolve(serialResult);
                            }
                          });
                        }
                      },
                      clickOutsideToClose: false,
                      fullscreen: true
                    });
                  }
                });
              }
            });
          } else {
            serialResult.Success = false;
            resolve(serialResult);
          }
        });
      } else {
        if (part.SerialNumber) {
          ApiHandlerService.SendRequest("Systembuilder", "CheckExistingSerial", { serialNumber: part.SerialNumber }).then((data: CheckExistingSerialResult) => {
            serialResult.ProductKey = data.ProductKey;

            if (data.IsFree) {
              serialResult.Success = true;
              resolve(serialResult);
            } else {
              $mdDialog.show({
                controller: 'MoveSerialDialogController',
                templateUrl: '/ClientApp/src/ajs/Views/Systembuilder/Dialogs/MoveSerialDialog.htm',
                parent: angular.element(document.body),
                targetEvent: event,
                locals: {
                  Serial: part.SerialNumber,
                  FormerCodes: data.SystemsString,
                  CurrentIdentcode: $scope.EditElement.Identcode,
                  Reasons: $scope.Model.UninstallReasons,
                  Part: part,
                  OnClose: () => {
                    serialResult.Success = false;
                    resolve(serialResult);
                  },
                  OnSuccess: (reasonId: number | null, reason: string, sourceParts: SystemPartDto[]) => {
                    ApiHandlerService.SendRequest("Systembuilder", "UninstallPartsBySerial", { serialNumber: part.SerialNumber, targetSystem: system.Identcode, reasonId: reasonId, reason: reason, sourceParts: sourceParts }).then((data: SimpleStringResultDto) => {
                      if (sourceParts.filter(f => f.Identcode === $scope.EditElement.Identcode)) {
                        serialResult.Success = 2;
                        resolve(serialResult);
                      }
                    });

                  }
                },
                clickOutsideToClose: false,
                fullscreen: true
              });
            }
          });
        } else {
          serialResult.Success = true;
          resolve(serialResult);
        }

      }
    });

  };

  $scope.ShowHistory = () => {
    alert($scope.EditElement.ChangeHistory);
  };

  $scope.AddArticleOrSerial = ($event: any, search: string) => {
    //$event.preventDefault();
    $scope.FindArticleBySearchtext(search).then((data) => {
      $timeout(() => {
        if (data.Success) {
          // Workaround: Cast nicht möglich, weil Typen nicht kompatibel.
          // Aber der bishere .js Code hat genau das gemacht.
          const part: SystemPartDto = {
            PartId: undefined,
            Identcode: undefined,
            ArticleNumber: data.Data.ArticleNumber,
            Description1: data.Data.Description1,
            Description2: data.Data.Description2,
            SerialNumber: data.Data.SerialNumber,
            ProductKey: undefined,
            InstallDate: data.Data.InstallDate,
            UninstallDate: undefined,
            UninstallReasonId: undefined,
            UninstallNote: undefined,
            IsSelected: undefined,
            NewArticleNumber: data.Data.NewArticleNumber,
            RequiresSerial: data.Data.RequiresSerial,
            InstallDateFormatted: data.Data.InstallDateFormatted,
            ComputerName: ''
          };


          $scope.CheckSerialNumber(part, $scope.EditElement).then((success) => {
            $timeout(() => {
              if (success.Success) {
                $scope.EditElement.Parts.push(part);
                part.ProductKey = success.ProductKey;
                $scope.SavePart(part, $scope.EditElement).then(() => {
                  if (success.Success === 2) {
                    $timeout(() => {
                      $scope.SelectSystem($scope.EditElement);
                    });
                  }
                });
                $scope.Filter.ArticleNumberInput = null;
                $scope.RecalculateCountsAndLabels($scope.EditElement);
                $("#articleNumberInput").focus();

              } else {
                //$scope.Filter.ArticleNumberInput = null;
              }
            });
          });
        } else {
          //$scope.Filter.ArticleNumberInput = null;
          alert(data.Message);
        }
      });
    });
  };

  $scope.DeletePart = (part, system, $event) => {
    $mdDialog.show({
      controller: 'DeletePartController',
      templateUrl: '/ClientApp/src/ajs/Views/Systembuilder/Dialogs/DeletePartDialog.htm',
      parent: angular.element(document.body),
      targetEvent: $event,
      locals: {
        Reasons: $scope.Model.UninstallReasons,
        Part: part,
        OnSuccess: () => {
          $scope.SavePart(part, $scope.EditElement);
        }
      },
      clickOutsideToClose: false,
      fullscreen: true
    }).then((answer) => {
      $scope.RecalculateCountsAndLabels(system);
    }, () => { });
  };

  $scope.OpenArticleSearch = ($event: any, currentElement: SystemPartDto) => {
    $mdPanel.newPanelGroup("FindArticle", {
      maxOpen: 1
    });
    const target = $event.srcElement || $event.target;
    const config = {
      attachTo: angular.element(document.body),
      templateUrl: '/ClientApp/src/ajs/Views/Erp/ArticleSearchTemplate.htm',
      controller: "ArticleSearchController",
      panelClass: 'window-panel-container',
      bindToController: true,
      locals: {
        SuccessCallback: (item) => {
          if (!currentElement) {
            const part: SystemPartDto = {
              Identcode: $scope.EditElement.Identcode,
              NewArticleNumber: item.ArticleNumber,
              ArticleNumber: item.ArticleNumber,
              Description1: item.Description1,
              Description2: item.Description2,
              InstallDate: moment(),
              SerialNumber: null
            } as SystemPartDto;
            $scope.EditElement.Parts.push(part);
            $scope.SavePart(part, $scope.EditElement);
          } else {
            currentElement.NewArticleNumber = item.ArticleNumber;
            currentElement.ArticleNumber = item.ArticleNumber;
            currentElement.Description1 = item.Description1;
            currentElement.Description2 = item.Description2;
            $scope.SavePart(currentElement, $scope.EditElement);
          }
          $scope.RecalculateCountsAndLabels($scope.EditElement);
        }
      },
      openFrom: $event,
      focusOnOpen: true,
      propagateContainerEvents: true,
      groupName: ["FindArticle"]
    };
    $mdPanel.open(config);
  };

  $scope.GetState = (id) => {
    const state = $scope.Model.States.filter(t => t.Id === id);
    if (state && state.length) {
      return state[0].State;
    }
    return "Unbekannt";
  };

  $scope.GetType = (id) => {
    const type = $scope.Model.Types.filter(t => t.Id === id);
    if (type && type.length) {
      return type[0].Name;
    }
    return "Unbekannt";
  };

  $scope.GetUser = (id) => {
    const user = $scope.Model.Users.filter(t => t.Id === id);
    if (user && user.length) {
      return user[0].DisplayName;
    }
    return "Unbekannt";
  };

  $scope.GetCustomer = (id, model) => {
    if (model.CachedAddress) {
      return model.CachedAddress.DisplayText;
    }
  };

  $scope.SystemsTable = new HestiaDataTable(StorageService.CreateInstance("SystemsTable"), $timeout, $scope);
  //$scope.ContractsTable.ItemDoubleClicked = $scope.OpenContractDialog;
  $scope.SystemsTable.Options.Select = false;
  $scope.SystemsTable.UpdateColumns([
    new ColumnDefinition({ PropertyPath: 'Identcode', Heading: ' ', ColumnWidth: 40, Resizeable: false, Sortable: false, Template: "/ClientApp/src/ajs/Views/Systembuilder/ViewSystemTemplate.htm" }),
    new ColumnDefinition({ PropertyPath: 'Identcode', Heading: ' ', ColumnWidth: 80, Resizeable: false, Sortable: false, Template: "/ClientApp/src/ajs/Views/Systembuilder/SystembuilderTableActions.html" }),
    new ColumnDefinition({ PropertyPath: 'Identcode', Heading: 'Identcode', Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'SystemDate', Heading: 'Datum', Resizeable: true, Sortable: true, RenderFunction: convertDate, Formatter: formatDateShort }),
    new ColumnDefinition({ PropertyPath: 'SystemStateId', Heading: 'Status', Resizeable: true, Sortable: true, RenderFunction: $scope.GetState }),
    new ColumnDefinition({ PropertyPath: 'CachedAddressId', Heading: 'Kunde', RenderFunction: $scope.GetCustomer, Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'SystemTypeId', Heading: 'Typ', Resizeable: true, Sortable: true, RenderFunction: $scope.GetType }),
    new ColumnDefinition({ PropertyPath: 'SystemUserId', Heading: 'Mitarbeiter', Resizeable: true, Sortable: true, RenderFunction: $scope.GetUser }),
    new ColumnDefinition({ PropertyPath: 'Name', Heading: 'Computername', Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'Ip', Heading: 'IP-Adresse', Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'CustomerUser', Heading: 'Benutzer', Resizeable: true, Sortable: true }),
    new ColumnDefinition({ PropertyPath: 'Note', Heading: 'Notiz', Resizeable: true, Sortable: true })
  ]);

  $scope.QueryAddresses = (query) => {
    return new Promise((resolve, reject) => {
      const result = [];
      ApiHandlerService.SendRequest("Erp", "QueryAddress", { query: query, mandatorId: mandatorServiceNgx.selectedMandatorId }).then((data) => {
        const result = [];
        data.Result.forEach((value) => {
          if (value.CachedAddress) {
            result.push(value.CachedAddress);
          }
        });
        resolve(result);
      });
    });
  };

  $scope.PrintPart = (part) => {
    const dict = [
      {
        Key: "Description",
        Value: part.Description1
      },
      {
        Key: "System",
        Value: $scope.EditElement.Identcode + " | " + $scope.EditElement.Name
      }
    ];

    if (part.ProductKey) {
      dict.push({
        Key: "ProductKey",
        Value: "Key: " + part.ProductKey
      });
    } else {
      dict.push({
        Key: "ProductKey",
        Value: ""
      });
    }
    if (part.SerialNumber) {
      dict.push({
        Key: "SerialNumber",
        Value: "Ser: " + part.SerialNumber
      });
    } else {
      dict.push({
        Key: "SerialNumber",
        Value: ""
      });
    }
    if (part.Identcode) {
      dict.push({
        Key: "Identcode",
        Value: part.Identcode
      });
    } else {
      dict.push({
        Key: "Identcode",
        Value: ""
      });
    }

    ApiHandlerService.SendRequest("Label", "PrintPluginTemplate", {
      plugin: $scope.PositionPrinting.Template.PluginName,
      pluginTemplateId: $scope.PositionPrinting.Template.PluginLabelId,
      printerId: $scope.PositionPrinting.SelectedPrinterId,
      paramDict: dict
    }).then(

      (data) => {
        $timeout(() => {
          if (!data.Success) {
            alert("Fehler beim Druck: " + data.Result);
          }
        });
      },

      (reason) => {
        $timeout(() => {
          const toast = $mdToast.simple()
            .textContent("Fehler beim Drucken")
            .position('bottom right');

          $mdToast.show(toast);
        });
      },

    );
  };

  $scope.RecalculateCountsAndLabels = (system) => {
    system.ActiveParts = 0;
    system.InactiveParts = 0;
    if (system.Parts) {
      system.Parts.forEach(f => {
        f.InstallDate = moment(f.InstallDate);
        f.InstallDateFormatted = f.InstallDate.format("DD.MM.YYYY");
        if (f.UninstallDate) {
          f.UninstallDate = moment(f.UninstallDate);
          system.InactiveParts++;
        } else {
          system.ActiveParts++;
        }
      });
    }
  };

  $scope.SelectedPrinterChanged = () => {
    $scope.Storage.Set("SelectedPrinterId", $scope.Printing.SelectedPrinterId);
    $scope.Storage.Set("SelectedPositionPrinterId", $scope.PositionPrinting.SelectedPrinterId);
  };

  $scope.ReloadSystems = () => {
    $scope.IsLoading = true;
    ApiHandlerService.SendRequest("Systembuilder", "GetAllSystems", { mandatorId: mandatorServiceNgx.selectedMandatorId }).then((data: SystemQueryResultDto) => {
      $scope.Printing = data.Printing;
      $scope.PositionPrinting = data.PositionPrinting;
      let currentId = null;
      let currentPositionId = null;
      if (data.Printing.Printers.length) {
        currentId = data.Printing.Printers[0].Id;
      }
      if (data.PositionPrinting.Printers.length) {
        currentPositionId = data.PositionPrinting.Printers[0].Id;
      }
      $scope.PositionPrinting.SelectedPrinterId = $scope.Storage.GetOrCreate("SelectedPositionPrinterId", currentPositionId);
      $scope.Printing.SelectedPrinterId = $scope.Storage.GetOrCreate("SelectedPrinterId", currentId);
      $scope.Printing.PrintQuantity = 1;
      $scope.Model = {
        Systems: data.Systems,
        Users: data.Users,
        Types: data.Types,
        States: data.States,
        UninstallReasons: data.UninstallReasons
      };
      $scope.Model.Systems.forEach(system => {
        system.SystemDate = moment(system.SystemDate);
        system.ActiveParts = 0;
        system.InactiveParts = 0;
      });
      $scope.ApplyFilter();
      $scope.IsLoading = false;
    });
  };

  $scope.ClearFilter = () => {
    let addresses = [];
    if ($scope.Filter && $scope.Filter.Addresses) {
      addresses = $scope.Filter.Addresses;
    }
    let oldAddressId = null;
    let oldAddress = null;
    if ($scope.Filter) {
      oldAddressId = $scope.Filter.SelectedAddressId;
      oldAddress = $scope.Filter.SelectedAddress;
    }
    $scope.Filter = {
      SelectedTypeId: null,
      SelectedUserId: null,
      AddressSearchText: null,
      SearchText: null,
      SelectedAddress: null,
      SelectedAddressId: null,
      Addresses: addresses
    };
    if ($scope.HasFixedAddress) {
      $scope.Filter.SelectedAddressId = oldAddressId;
      $scope.Filter.SelectedAddress = oldAddress;
    }
    $scope.FilterChanged();
  };

  $scope.FilterChanged = () => {
    $scope.ApplyFilter();
  };

  $scope.FilterChangedDelayed = () => {
    if ($scope.FilterChangedTimeout) {
      $timeout.cancel($scope.FilterChangedTimeout);
    }

    $scope.FilterChangedTimeout = $timeout(() => {
      $scope.FilterChanged();
      $scope.FilterChangedTimeout = null;
    }, 500);
  };

  $scope.AddressChanged = (system) => {
    if (system) {
      if (system.CachedAddress) {
        system.CachedAddressId = system.CachedAddress.Id;
        $scope.SaveBaseData(system);
      } else {
        system.CachedAddressId = null;
      }
    }
  };

  $scope.SetForm = (form) => {
    $scope.EditSystemForm = form;
  };

  $scope.SaveBaseData = (system) => {
    const form = $scope.EditSystemForm;
    if (form.$valid) {
      $scope.IsSavingSystem = true;
      ApiHandlerService.SendRequest("Systembuilder", "SaveSystem", { system: system, mandatorId: mandatorServiceNgx.selectedMandatorId }).then((data: SystemDto) => {
        $timeout(() => {
          $scope.IsSavingSystem = false;
          system.ChangeHistory = data.ChangeHistory;
        });
      });
    }
  };

  $scope.SaveDelayed = () => {
    if ($scope.SaveIpTimeout) {
      $timeout.cancel($scope.SaveIpTimeout);
    }
    $scope.SaveIpTimeout = $timeout(() => {
      $scope.SaveBaseData($scope.EditElement);
    }, 2000);
  };

  $scope.SavePart = (part: SystemPartDto, system: SystemDto, form) => {
    return new Promise((resolve, reject) => {
      const form = $scope.EditSystemForm;
      if (form.$valid) {
        $scope.IsSavingSystem = true;
        ApiHandlerService.SendRequest("Systembuilder", "SavePart", { part: part, identcode: system.Identcode, mandatorId: mandatorServiceNgx.selectedMandatorId }).then((data: SystemPartDto) => {
          $timeout(() => {
            part.PartId = data.PartId;
            $scope.IsSavingSystem = false;
            resolve(true);
          });
        });
      }
    });

  };

  $scope.GetErpSystemsLength = () => {
    if ($scope.Model && $scope.Model.Systems && $scope.Filter && $scope.Filter.SelectedAddress) {
      return $scope.Model.Systems.filter(f => f.CachedAddressId === $scope.Filter.SelectedAddress.Id).length;
    }
    return 0;
  };

  $scope.ApplyFilter = () => {
    if ($scope.Model) {
      $scope.FilteredSystems = $scope.Model.Systems.slice();
      if ($scope.Filter.SelectedTypeId) {
        $scope.FilteredSystems = $scope.FilteredSystems.filter(f => f.SystemTypeId === $scope.Filter.SelectedTypeId);
      }
      if ($scope.Filter.SelectedUserId) {
        $scope.FilteredSystems = $scope.FilteredSystems.filter(f => f.SystemUserId === $scope.Filter.SelectedUserId);
      }
      if ($scope.Filter.SelectedAddress) {
        $scope.FilteredSystems = $scope.FilteredSystems.filter(f => f.CachedAddressId === $scope.Filter.SelectedAddress.Id);
      }
      if ($scope.Filter.Identcode) {
        const transformedIdentcode = $scope.Filter.Identcode.trim().toUpperCase();
        if (transformedIdentcode.length) {
          $scope.FilteredSystems = $scope.FilteredSystems.filter(f => f.Identcode && f.Identcode.toUpperCase().startsWith(transformedIdentcode));
        }
      }
      if ($scope.Filter.SearchText) {
        const transformedSearch = $scope.Filter.SearchText.trim().toUpperCase();
        if (transformedSearch.length) {
          $scope.FilteredSystems = $scope.FilteredSystems.filter(f => f.Name && f.Name.toUpperCase().includes(transformedSearch) ||
            f.Ip && f.Ip.toUpperCase().includes(transformedSearch) ||
            f.Note && f.Note.toUpperCase().includes(transformedSearch) ||
            f.CustomerUser && f.CustomerUser.toUpperCase().includes(transformedSearch));
        }
      }
      $scope.SystemsTable.UpdateSource($scope.FilteredSystems, $timeout);
    }
  };

  $scope.GetFixedAddressLength = () => {
    if ($scope.Model) {
      return $scope.Model.Systems.filter(c => c.CachedAddressId === $scope.Filter.SelectedAddressId).length;
    }
    return 0;
  };

  $scope.setReportCount = (count: number) => {
    $scope.reportCount = count;
  };


  $scope.ClearFilter();
  $scope.ReloadSystems();
}


interface ISystembuilderControllerScope extends angular.IScope {

  Filter: any;
  EditElement: SystemDto;
  IsInEdit: boolean;
  ActiveTagText: string;
  Storage: StorageServiceInstance;
  SystemsTable: HestiaDataTable;
  SelectedTabIndex: number;
  Model: ISystemBuilderControllerModel;
  Printing: LabelPrintDataDto;
  PositionPrinting: LabelPrintDataDto;
  IsLoading: boolean;
  FilterChangedTimeout: angular.IPromise<void>;
  EditSystemForm: any;
  IsSavingSystem: boolean;
  SaveIpTimeout: angular.IPromise<void>;
  FilteredSystems: SystemDto[];
  HasFixedAddress: boolean;
  reportCount: number;

  CheckKeyPress: (e: any) => void;
  OpenIdentcode: (event: any) => void;
  OpenPartSearch: (event: any) => void;
  NavigateBack: () => void;
  ReloadSystems: () => void;
  ApplyFilter: () => void;
  OpenSystemSheet: () => void;
  OpenSystemSheetTable: (indentCode: string) => void;
  SendSystemSheet: (event: any) => void;
  SendSystemSheetTable: (event: any, system: SystemDto) => void;
  CreateSystem: (event: any) => void;
  CreateNewSystemTemplate: () => Promise<SystemDto>;
  SelectSystem: (system: SystemDto) => void;
  RecalculateCountsAndLabels: (system: any) => void;
  PrintLabels: (element: any) => void;
  FindArticleBySearchtext: (search: string) => Promise<FindArticleBySearchtextResult>;
  TryChangeArticle: ($event: any, part: any) => void;
  SavePart: (part: SystemPartDto, system: SystemDto, form?: any) => Promise<unknown>;
  ResolveSerialNumber: (part: SystemPartDto) => Promise<string>;
  CheckSerialNumber: (part: SystemPartDto, system: SystemDto, event?: any) => Promise<CheckSerialNumberResult>
  ShowHistory: () => void;
  AddArticleOrSerial: ($event: any, search: string) => void;
  DeletePart: (part: SystemPartDto, system: SystemDto, $event: any) => void;
  OpenArticleSearch: ($event: any, currentElement: SystemPartDto) => void;
  GetState: (id: number) => void;
  GetType: (id: number) => void;
  GetUser: (id: string) => void;
  GetCustomer: (id: any, model: any) => void;
  QueryAddresses: (query: string) => Promise<unknown>;
  PrintPart: (part: SystemPartDto) => void;
  SelectedPrinterChanged: () => void;
  ClearFilter: () => void;
  FilterChanged: () => void;
  FilterChangedDelayed: () => void;
  AddressChanged: (system: SystemDto) => void;
  SaveBaseData: (system: SystemDto) => void;
  SetForm: (form: any) => void;
  SaveDelayed: () => void;
  GetErpSystemsLength: () => number;
  GetFixedAddressLength: () => number;
  setReportCount: (count: number) => void;

}

interface ISystemBuilderControllerModel {
  Systems: SystemDto[];
  Users: SimpleUserDto[];
  Types: SystemTypeDto[];
  States: SystemStateDto[];
  UninstallReasons: SystemPartUnistallReasonDto[];
}
