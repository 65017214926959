import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelPrintDialogComponent } from './components/label-print-dialog/label-print-dialog.component';
import { SharedModule } from '../shared/shared.module';
import { PasswordGeneratorDialogComponent } from './components/password-generator-dialog/password-generator-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    LabelPrintDialogComponent,
    PasswordGeneratorDialogComponent
  ]
})
export class WidgetsModule { }
