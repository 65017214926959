<div class="content-padding" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="start center" class="table-bar">
    <h1 fxFlex>Anrufe</h1>

    <mat-button-toggle-group class="small" [formControl]="dateFilter">
      <mat-button-toggle value="today">Heute</mat-button-toggle>
      <mat-button-toggle value="48hours">48 Stunden</mat-button-toggle>
      <mat-button-toggle value="7days">7 Tage</mat-button-toggle>
      <mat-button-toggle value="all">Alle</mat-button-toggle>
    </mat-button-toggle-group>


    <button *ngIf="!statusFilterActive()" mat-stroked-button [mat-menu-trigger-for]="statusMenu">
      <mat-icon>label</mat-icon> Status
    </button>
    <button *ngIf="statusFilterActive()" mat-flat-button [mat-menu-trigger-for]="statusMenu" color="accent">
      <mat-icon>label</mat-icon> Status
    </button>

    <button mat-stroked-button (click)="columnEditor.showEditor()">
      <mat-icon>view_column</mat-icon> Spalten
    </button>

    <mat-menu #statusMenu="matMenu">
      <button mat-menu-item (click)="resetStatusFilter()">Filter zurücksetzen</button>

      <mat-divider></mat-divider>

      <button mat-menu-item (click)="toggleStatusFilterAngenommen(); $event.stopPropagation();">
        <mat-icon *ngIf="!callStatusFilterAngenommenActive"></mat-icon>
        <mat-icon *ngIf="callStatusFilterAngenommenActive">checked</mat-icon>
        Angenommen
      </button>

      <button mat-menu-item (click)="toggleStatusFilterVerpasst(); $event.stopPropagation();">
        <mat-icon *ngIf="!callStatusFilterVerpasstActive"></mat-icon>
        <mat-icon *ngIf="callStatusFilterVerpasstActive">checked</mat-icon>
        Verpasst
      </button>

      <button mat-menu-item (click)="toggleStatusFilterEingehend(); $event.stopPropagation();">
        <mat-icon *ngIf="!callStatusFilterEingehendActive"></mat-icon>
        <mat-icon *ngIf="callStatusFilterEingehendActive">checked</mat-icon>
        Eingehend
      </button>

      <button mat-menu-item (click)="toggleStatusFilterAusgehend(); $event.stopPropagation();">
        <mat-icon *ngIf="!callStatusFilterAusgehendActive"></mat-icon>
        <mat-icon *ngIf="callStatusFilterAusgehendActive">checked</mat-icon>
        Ausgehend
      </button>

      <button mat-menu-item (click)="toggleStatusFilterIntern(); $event.stopPropagation();">
        <mat-icon *ngIf="!callStatusFilterInternActive"></mat-icon>
        <mat-icon *ngIf="callStatusFilterInternActive">checked</mat-icon>
        Intern
      </button>
    </mat-menu>

    <mat-paginator [length]="totalCount" [pageSize]="50" hidePageSize [showFirstLastButtons]="true"></mat-paginator>
  </div>

  <mat-drawer-container fxFlex>
    <mat-drawer #drawer mode="side" position="end">
      <app-call-details *ngIf="selectedCallId" [callId]="selectedCallId" (closeRequest)="drawer.close()" (refreshRequest)="refresh$.next()"></app-call-details>
    </mat-drawer>

    <mat-drawer-content>
      <div fxFlex>
        <table
          mat-table
          class="axs-table dense call-table"
          [dataSource]="data"
          matSort
          [matSortActive]="sortService.active"
          [matSortDirection]="sortService.direction"
          (matSortChange)="sortService.change($event)"
          matSortDisableClear>

          <ng-container matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox [checked]="anyChecked" (change)="toggleAllChecked($event)"></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox [checked]="row.isChecked" (change)="toggleChecked(row)"></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="task">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <mat-icon
                *ngIf="row.phoneCall.Task && !row.phoneCall.Task.State.IsDeletedState"
                [class.task-completed]="row.phoneCall.Task.State.IsCompletedState"
                [matTooltip]="row.phoneCall.Task.Title">
                playlist_add_check
              </mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="callId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>CallId</th>
            <td mat-cell *matCellDef="let row">{{row.phoneCall.CallId}}</td>
          </ng-container>

          <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Start</th>
            <td mat-cell *matCellDef="let row" (click)="callClick(row.phoneCall)">
              {{row.phoneCall.StartDate | date: "dd.MM.yyyy HH:mm:ss"}}
            </td>
          </ng-container>

          <ng-container matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Dauer</th>
            <td mat-cell *matCellDef="let row" (click)="callClick(row.phoneCall)">
              <span *ngIf="row.phoneCall.IsAnswered">{{row.phoneCall.Duration | duration}}</span>
              <span *ngIf="!row.phoneCall.IsAnswered">{{0 | duration}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="mainExtensionDescription">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Nebenstelle</th>
            <td mat-cell *matCellDef="let row" (click)="callClick(row.phoneCall)">
              <span>{{row.phoneCall.MainExtensionDescription}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="otherPartyNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Teilnehmer</th>
            <td mat-cell *matCellDef="let row">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon [class.answered]="row.phoneCall.IsAnswered">{{getCallDirectionIcon(row.phoneCall)}}</mat-icon>
                <a href="{{row.phoneCall.OtherPartyNumber | tel}}">{{row.phoneCall.OtherPartyNumber}}</a>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="contactName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Kontakt</th>
            <td mat-cell *matCellDef="let row" (click)="callClick(row.phoneCall)">

              <div *ngIf="row.phoneCall.ContactName">
                {{row.phoneCall.ContactName}}
              </div>

              <div *ngIf="!row.phoneCall.ContactName && row.phoneCall.OtherPartyNumber?.startsWith('+43')">
                <a href="https://www.herold.at/gelbe-seiten/suche/?userTerm={{row.phoneCall.OtherPartyNumber | replace:'+43':'0'}}"
                  target="_blank"
                  fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon>public</mat-icon> Frag den Herold...
                </a>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Adresse</th>
            <td mat-cell *matCellDef="let row" (click)="callClick(row.phoneCall)">
              {{row.phoneCall.Address}}
            </td>
          </ng-container>

          <ng-container matColumnDef="note">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Notiz</th>
            <td mat-cell *matCellDef="let row" (click)="callClick(row.phoneCall)">
              {{row.phoneCall.Note}}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnEditor.displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: columnEditor.displayedColumns;"></tr>
        </table>

        <app-table-nodata-indicator [noData]="data?.length === 0"></app-table-nodata-indicator>
        <app-loading-overlay [isLoading]="isLoading" overlay="light"></app-loading-overlay>
      </div>
    </mat-drawer-content>

  </mat-drawer-container>

  <div class="actions">
    <button type="button" mat-raised-button color="primary" [matMenuTriggerFor]="actionMenu" [disabled]="!anyChecked">Aktionen</button>

    <mat-menu #actionMenu="matMenu">
      <button mat-menu-item (click)="addCallsToTask()">In Aufgabe überführen</button>
      <button mat-menu-item [disabled]="!anyCheckedHasTask" (click)="removeTaskFromCall()">Aufgabe entfernen</button>
      <button mat-menu-item (click)="removeCallsFromLog()">Aus Protokoll entfernen</button>
    </mat-menu>
  </div>
</div>
