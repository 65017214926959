
import { ErpCachedAddressDto } from "src/app/erp/dto/erp-cached-address-dto";
import { SageExportContractDto, fixSageExportContractDto } from "./sage-export-contract-dto";
import { FormControl } from "@angular/forms";
import { SageExportContractQueueDto } from "./sage-export-contract-queue-dto";


export interface SageExportContractGroupDto {
  id: number;
  erpCachedAddressId: number;
  sageExportContractQueueId: number;
  erpCachedAddress: ErpCachedAddressDto;
  belId: number | null;
  vorId: number | null;
  isCompleted: boolean;
  hasErrors: boolean;
  sageContractTypeId: number | null;
  sageExportContracts: SageExportContractDto[];
  isArchived: boolean;

  sageExportContractQueue?: SageExportContractQueueDto | null;



  // nur Frontend
  contractTypeControl: FormControl<number>;

  // nur zum anzeigen
  auftragStatus: number;
  lieferStatus: number;
  rechnungsStatus: number

}

export function fixSageExportContractGroupDto(dto: SageExportContractGroupDto) {
  if (dto) {
    for (const contract of dto.sageExportContracts) {
      fixSageExportContractDto(contract);
    }
  }

}