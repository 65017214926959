import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, tap } from 'rxjs';
import { ContractListResultDto } from '../dto/contract-list-result-dto';
import { ContractListRequestDto } from '../dto/contract-list-request-dto';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ContractDialogComponent } from '../contract-dialog/contract-dialog.component';
import { ContractFileNoteDialogComponent } from '../contract-file-note-dialog/contract-file-note-dialog.component';
import { ContractTabEditDialogComponent } from '../contract-tab-edit-dialog/contract-tab-edit-dialog.component';
import { ArticleSearchDialogComponent } from '../article-search-dialog/article-search-dialog.component';
import { ArticleVariationDialogComponent } from '../article-variation-dialog/article-variation-dialog.component';
import { Api2Service } from 'src/app/core/services/api2.service';
import { ErpCachedAddressDto } from 'src/app/erp/dto/erp-cached-address-dto';
import { ContractTypeDto } from '../dto/contract-type-dto';
import { ContractDto, fixContractDto } from '../dto/contract-dto';
import { SageArticleDto } from 'src/app/sage-articles/dto/sage-article-dto';
import { SageArticleVariantDto } from 'src/app/sage-articles/dto/sage-article-variant-dto';
import { ContractProductDto } from '../dto/contract-product-dto';
import { ContractDataDto } from '../dto/contract-data.dto';

const controller = "Contract";
@Injectable({
  providedIn: 'root'
})
export class ContractService {

  constructor(
    private readonly api2: Api2Service,
    private readonly dialog: MatDialog,
  ) { }

  getContract(contractId: number): Observable<ContractDto> {
    return this.api2.get(controller, "GetContract", { contractId: contractId });
  }

  getErpCachedAddresses(mandatorId: string): Observable<ErpCachedAddressDto[]> {
    return this.api2.get(controller, "GetErpCachedAddresses", { mandatorId: mandatorId });
  }

  getContractTypes(): Observable<ContractTypeDto[]> {
    return this.api2.get(controller, "GetContractTypes");
  }

  getContractList(
    orderBy: string,
    descending: boolean,
    pageSize: number,
    pageIndex: number,
    contractTypeId: number,
    addressId: number,
    search: string,
    contractFilter: number,
    isCanceled: boolean,
    accountedFilter: boolean,
    warningFilter: boolean,
    outsideAddressId: number,
    mandatorId: string,
    noPaginator?: boolean,

  ): Observable<ContractListResultDto> {

    const request: ContractListRequestDto =
    {
      OrderBy: orderBy,
      Descending: descending,
      PageSize: pageSize,
      PageIndex: pageIndex,
      ContractTypeId: contractTypeId,
      AddressId: addressId,
      Search: search,
      ContractFilter: contractFilter,
      IsCanceled: isCanceled,
      AccountedFilter: accountedFilter,
      WarningFilter: warningFilter,
      OutsideAddressId: outsideAddressId,
      MandatorId: mandatorId,
      NoPaginator: noPaginator
    };

    return this.api2.post<ContractListResultDto>(controller, "GetContractList", null, request).pipe(
      tap(value => {
        value.contracts?.forEach(fixContractDto);
      })
    );
  }

  saveContract(dto: ContractDto) {
    return this.api2.post(controller, "SaveContract", null, dto);
  }

  getTitles(): Observable<string[]> {
    return this.api2.get<string[]>(controller, "GetTitles");
  }

  getContractProducts(contractId: number): Observable<ContractProductDto[]> {
    return this.api2.get<ContractProductDto[]>(controller, "GetContractProducts", { contractId: contractId });
  }

  deleteContract(contractId: number) {
    return this.api2.get(controller, "DeleteContract", { contractId: contractId });
  }

  getContractSuggestedTabTitles(): Observable<string[]> {
    return this.api2.get(controller, "GetContractSuggestedTabTitles");
  }

  //Dialoge
  async showContractDialog(contractId?: number, outsideAddressId?: number) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.hasBackdrop = false;
    dialogConfig.data = {
      contractId: contractId,
      addressId: outsideAddressId
    };

    const dialog = this.dialog.open<ContractDialogComponent>(ContractDialogComponent, dialogConfig);

    const ok = await firstValueFrom(dialog.afterClosed());
    return ok;
  }

  async showContractFileNoteDialog(note: string) {
    const dialog = this.dialog.open<ContractFileNoteDialogComponent>(ContractFileNoteDialogComponent, {
      data: {
        note: note
      }
    });

    const ok = await firstValueFrom(dialog.afterClosed());
    return ok;
  }

  async showContractTabEditDialog(tab: ContractDataDto) {
    const dialog = this.dialog.open<ContractTabEditDialogComponent>(ContractTabEditDialogComponent, {
      data: {
        tab: tab
      }
    });

    const ok = await firstValueFrom(dialog.afterClosed());
    return ok;
  }


  async showArticleSearchDialog() {
    const dialog = this.dialog.open<ArticleSearchDialogComponent>(ArticleSearchDialogComponent, {
      data: {
      }
    });

    const ok: SageArticleDto = await firstValueFrom(dialog.afterClosed());
    return ok;
  }

  async showArticleVariationDialog(article: SageArticleVariantDto) {
    const dialog = this.dialog.open<ArticleVariationDialogComponent>(ArticleVariationDialogComponent, {
      data: {
        article: article
      }
    });

    const ok = await firstValueFrom(dialog.afterClosed());
    return ok;
  }






}
