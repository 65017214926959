import * as angular from "angular";
import { addDefaultScopeFunctions } from "../Functions/add-default-scope-functions";
import { UserConfigurationDto } from "../Models/Phone3cx/UserConfigurationDto";
import { ThemeDto } from "../Models/ThemeDto";
import { ApiHandlerService } from "../Services/ApiHandlerService";

export class Phone3CXUserController {

  static $inject = ['$scope', 'ApiHandlerService', 'theme'];

  constructor(
    private readonly $scope: IPhone3CXUserControllerScope,
    private readonly ApiHandlerService: ApiHandlerService,
    private readonly theme: ThemeDto
  ) {
    addDefaultScopeFunctions($scope, theme);
    $scope.Model = {
      Extension: ""
    };
    $scope.CommitData = this.CommitData.bind(this);
    this.LoadData();
  }

  CommitData() {
    this.ApiHandlerService.SendRequest("Phone3cx", "SetUserConfiguration", { model: this.$scope.Model }).then(data => {
      this.$scope.Model = data;
    });
  };

  LoadData() {
    this.ApiHandlerService.SendRequest("Phone3cx", "GetUserConfiguration").then(data => {
      this.$scope.Model = data;
    });
  };

}

interface IPhone3CXUserControllerScope extends angular.IScope {
  Model: UserConfigurationDto;
  CommitData: () => void;
}
