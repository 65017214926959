import * as angular from 'angular';
import { addDefaultScopeFunctions } from '../Functions/add-default-scope-functions';
import { ThemeDto } from '../Models/ThemeDto';
import { LocalizationService, PluginDict } from '../Services/Localization/LocalizationService';
import { StorageServiceInstance } from '../Services/StorageService';

var HESTIA_TABLE_RULES = null;
var STYLE_DICT = {};

interface IPagination {
  CurrentPage: number;
  PageSize: number;
  PageSizes: number[];
}

interface ISelection {
  SelectAll: boolean;
}

interface IColumnDefinitionStorage {
  Heading: string;
  PropertyPath: string;
  ColumnWidth: number;
  IsVisible: boolean;
  IsSorted: boolean;
  IsAscending: boolean;
}

interface IOptions {
  Select: boolean;
  Multiple: boolean
}

interface IAggregateFunction {
  id: number;
  func: any;
}

export class HestiaDataTable {

  timeout: angular.ITimeoutService;
  ExternalLocalization: PluginDict;
  LoadingCounter: number = 0;
  Storage: StorageServiceInstance;
  Data: any[] = null;
  ColumnDefinitions: ColumnDefinition[] = [];
  ActionGroups: ActionGroup[] = [];
  Actions: ActionDefinition[] = [];
  AggregateFunctions: IAggregateFunction[] = [];
  ResizingElement: any = null;
  ContentChanged: number = 0;
  OnSourceChanged: any = null;
  OnUpdate: any = null;
  ItemClicked: any = null;
  ItemDoubleClicked: any = null;
  ParentScope: any;
  SelectedItems: any[] = [];
  Selection: ISelection = { SelectAll: false };
  Filter = { OrderBy: null, Reverse: false, CompareFunction: undefined };
  Pagination: IPagination;
  Options: IOptions = { Select: true, Multiple: true };
  IsOpen: boolean = true;
  IsPrint: boolean;
  TableElement: any;
  Element: any;
  AggregateIndex: number[];

  constructor(
    private readonly storage: StorageServiceInstance,
    private readonly $timeout: angular.ITimeoutService,
    private readonly parentScope: any,
    private readonly localization?: PluginDict) {

    if (!HESTIA_TABLE_RULES) {
      HESTIA_TABLE_RULES = (function () {
        const style = document.createElement("style");
        style.appendChild(document.createTextNode(""));
        document.head.appendChild(style);
        return style.sheet;
      })();
    }

    this.timeout = $timeout;
    this.ExternalLocalization = localization;
    this.Storage = storage;
    this.ParentScope = parentScope;
    this.Pagination = this.Storage.GetOrCreate("Pagination", {
      CurrentPage: 0,
      PageSize: 50,
      PageSizes: [10, 25, 50, 100]
    } as IPagination);

    this.RequestNextUpdate(this);
  }

  NumberOfPages() {
    if (this.Data) {
      return Math.ceil(this.Data.length / this.Pagination.PageSize);
    }
    return 0;
  }

  SetPage(pageNumber: number) {
    if (pageNumber < 0)
      pageNumber = 0;
    if (pageNumber >= this.NumberOfPages()) {
      pageNumber = this.NumberOfPages() - 1;
    }
    this.Pagination.CurrentPage = pageNumber;
    this.LoadingCounter++;
  }

  PageSizeChanged() {
    this.Storage.Set("Pagination", this.Pagination);
    this.SetPage(0);
  }

  UpdateSource(dataSource: any[], $timeout: angular.ITimeoutService) {

    if (this.Data !== dataSource) {
      /*if (object.BodyElement && object.BodyElement.children) {
          while (object.BodyElement.children.length > 0) {
              $(object.BodyElement).find(':first-child').remove();
          }
      }*/
      this.AggregateIndex = [];
      for (let index = 0; index < this.AggregateFunctions.length; index++) {
        this.AggregateIndex.push(index);
      }
      if (this.Data) {
        this.Data.length = 0;
      }

      this.Selection.SelectAll = false;
      this.ChangeSelection();
      if (dataSource) {
        this.Data = dataSource;
      } else {
        this.Data = [];
      }
      if (this.OnSourceChanged) {
        this.OnSourceChanged();
      }
    } else {
      this.FilterChanged();
    }
    this.SetColumnValues(false);
    this.LoadingCounter++;
  }

  SetColumnValues(needsTimeout: boolean) {
    if (this.Data) {
      let hasChanges = false;
      for (let currentIndex = 0; currentIndex < this.Data.length; currentIndex++) {
        const currentData = this.Data[currentIndex];
        currentData._hestiaDataId = currentIndex;

        for (let cd of this.ColumnDefinitions) {
          const value = cd.GetFormattedValue(currentData);
          if (value !== cd.FormattedValues[currentData._hestiaDataId]) {
            cd.FormattedValues[currentData._hestiaDataId] = value;
            hasChanges = true;
          }
        }
      }

      if (hasChanges && needsTimeout) {
        this.ContentChanged++;
        this.parentScope.$apply();
      }
    }
  }

  ColumnWidthChanged(column: ColumnDefinition, newWidth: number) {
    const current: IColumnDefinitionStorage[] = this.Storage.Get("ColumnDefinitions");
    for (let i = 0; i < current.length; i++) {
      if (current[i].Heading === column.Heading && current[i].PropertyPath === column.PropertyPath) {
        current[i].ColumnWidth = newWidth;
      }
    }
    this.Storage.Set("ColumnDefinitions", this.MapColumnsToStorage(current));
  }

  MapColumnsToStorage(columns: IColumnDefinitionStorage[]): IColumnDefinitionStorage[] {
    return columns.map((v) => {
      return {
        Heading: v.Heading,
        PropertyPath: v.PropertyPath,
        ColumnWidth: v.ColumnWidth,
        IsVisible: v.IsVisible,
        IsSorted: v.IsSorted,
        IsAscending: v.IsAscending
      } as IColumnDefinitionStorage;
    });
  }

  UpdateColumns(columnDefinitions: ColumnDefinition[]) {
    this.ColumnDefinitions = columnDefinitions;

    for (let cd of columnDefinitions) {
      cd.ColumnCallback = this.ColumnWidthChanged.bind(this);
      if (!cd.Heading.match(/\S/)) {
        cd.HideInMenu = true;
      } else {
        cd.HideInMenu = false;
      }
    }

    if (this.Storage) {
      let current: IColumnDefinitionStorage[] = this.Storage.GetOrCreate("ColumnDefinitions", this.MapColumnsToStorage(this.ColumnDefinitions));
      if (current) {
        for (let i = 0; i < this.ColumnDefinitions.length; i++) {
          let c: IColumnDefinitionStorage[] | IColumnDefinitionStorage = current.filter(s => s.Heading === this.ColumnDefinitions[i].Heading && s.PropertyPath === this.ColumnDefinitions[i].PropertyPath);
          if (c.length) {
            c = c[0];
            this.ColumnDefinitions[i].IsVisible = c.IsVisible;
            if (!this.ColumnDefinitions.filter(f => f.IsSorted).length) {
              this.ColumnDefinitions[i].IsSorted = c.IsSorted;
              this.ColumnDefinitions[i].IsAscending = c.IsAscending;
            }
            if (this.ColumnDefinitions[i].IsSorted) {
              this.ColumnClicked(this.ColumnDefinitions[i], null, true);
            }
            if (this.ColumnDefinitions[i].Resizeable) {
              this.ColumnDefinitions[i].ColumnWidth = c.ColumnWidth;
              this.ColumnDefinitions[i].UpdateWidth();
            } else {
              this.ColumnDefinitions[i].UpdateWidth();
            }
            if (c.IsSorted) {
              this.Filter.OrderBy = this.ColumnDefinitions[i].PropertyPath;
              this.Filter.Reverse = this.ColumnDefinitions[i].IsAscending;
            }
          }
          else {
            this.ColumnDefinitions[i].ColumnCallback = this.ColumnWidthChanged.bind(this);
            current.push(this.ColumnDefinitions[i]);
          }
          this.ColumnDefinitions[i].CheckVisibility();
        }
      }
      else {
        current = this.ColumnDefinitions;
      }
      this.Storage.Set("ColumnDefinitions", this.MapColumnsToStorage(this.ColumnDefinitions));
    }
  }

  AddActionGroup(actionGroup: ActionGroup) {
    actionGroup.SetParent(this);
    this.ActionGroups.push(actionGroup);
  }

  ClearActionGroups() {
    this.ActionGroups = [];
  }

  AddAction(actionDefinition: ActionDefinition) {
    actionDefinition.Table = this;
    this.Actions.push(actionDefinition);
  }

  ClearActions() {
    this.Actions = [];
  }

  Update() {
    this.ColumnDefinitions.forEach(cd => {
      cd.CheckVisibility();
    });
    this.Storage.Set("ColumnDefinitions", this.MapColumnsToStorage(this.ColumnDefinitions));
    if (this.OnUpdate) {
      this.OnUpdate();
    }
  }

  HasActions() {
    return this.Actions.length || this.ActionGroups.length;
  }

  SupressRowClick($event) {
    $event.stopPropagation();
  }

  ColumnClicked(columnDefinition, event, doNotReverse, onlyIfInput?) {
    if (onlyIfInput === true && !columnDefinition.AllowFilter)
      return;
    if (onlyIfInput === false && columnDefinition.AllowFilter)
      return;

    if (event && event.currentTarget === this.ResizingElement) {
      this.ResizingElement = null;
      return;
    }
    if (columnDefinition.Sortable) {
      if (columnDefinition.IsSorted) {
        if (!doNotReverse) {
          columnDefinition.IsAscending = !columnDefinition.IsAscending;
        }
        this.Filter.Reverse = columnDefinition.IsAscending;
        if (columnDefinition.SortValueSelector) {
          this.Filter.CompareFunction = columnDefinition.SortValueSelector;
        } else {
          this.Filter.CompareFunction = null;
        }
      } else {
        for (var i = 0; i < this.ColumnDefinitions.length; i++) {
          this.ColumnDefinitions[i].IsSorted = false;
          this.ColumnDefinitions[i].IsAscending = false;
        }
        columnDefinition.IsSorted = true;
        columnDefinition.IsAscending = true;
        this.Filter.OrderBy = columnDefinition.PropertyPath;
        if (columnDefinition.SortValueSelector) {
          this.Filter.CompareFunction = columnDefinition.SortValueSelector;
        } else {
          this.Filter.CompareFunction = null;
        }
        this.Filter.Reverse = columnDefinition.IsAscending;
      }
    }
    this.LoadingCounter++;
    this.Storage.Set("ColumnDefinitions", this.MapColumnsToStorage(this.ColumnDefinitions));
  }

  OpenTableMenu($mdMenu, ev) {
    $mdMenu.open(ev);
  }

  Print(ev) {
    var object = this;
    object.IsPrint = true;
    this.$timeout(function () {
      var frame = $(object.TableElement).find(".print-container");
      frame.addClass("print-focus");
      window.print();
      frame.removeClass("print-focus");
      object.IsPrint = false;
    });
  }

  SelectAllChanged() {
    for (var i = 0; i < this.Data.length; i++) {
      this.Data[i].HestiaSelected = this.Selection.SelectAll;
    }
    this.ChangeSelection();
  }

  SelectChanged(item) {
    for (var i = 0; i < this.Data.length; i++) {
      if (!this.Data[i].HestiaSelected) {
        this.Selection.SelectAll = false;
        this.ChangeSelection();
        return;
      }
      this.Selection.SelectAll = true;
    }
    this.ChangeSelection();
  }

  ChangeSelection() {
    if (this.Data && this.Data.filter) {
      this.SelectedItems = this.Data.filter(f => f.HestiaSelected);
    }
  }

  FilterChanged() {
    if (this.OnSourceChanged) {
      this.OnSourceChanged();
    }
  }

  UpdateInternal() {
    if (this.IsOpen && this.Element) {
      this.SetColumnValues(true);
      this.RequestNextUpdate(this);
    }
  }

  RequestNextUpdate(object) {
    if (window.requestIdleCallback) {
      setTimeout(function () {
        window.requestIdleCallback(object.UpdateInternal.bind(object));
      }, 1000);
    } else {
      setTimeout(object.UpdateInternal.bind(object), 1000);
    }
  }
}

export class ActionDefinition {
  Caption: string;
  Icon: string;
  Table: HestiaDataTable;

  constructor(
    caption: string,
    icon: string,
    private readonly callback: (item: any) => void,
    private readonly collectedCallback?: (items: any[], event: any) => void) {
    this.Caption = caption;
    this.Icon = icon;
  }

  Clicked(event: any) {
    if (this.collectedCallback) {
      if (this.Table) {
        this.collectedCallback(this.Table.SelectedItems, event);
      }
    } else {
      if (this.Table) {
        for (var i = 0; i < this.Table.SelectedItems.length; i++) {
          this.callback(this.Table.SelectedItems[i]);
        }
      }
    }
  };
}


export class ActionGroup {
  Caption: string;
  Actions: any[];
  Parent: any;

  constructor(caption: string, actionDefinitons) {
    this.Caption = caption;
    this.Actions = actionDefinitons;
  }

  SetParent(parent) {
    this.Parent = parent;
    if (this.Actions) {
      for (let i = 0; i < this.Actions.length; i++) {
        this.Actions[i].Table = parent;
      }
    }
  }

  OpenMenu($mdMenu: angular.material.IMenuService, ev: MouseEvent) {
    $mdMenu.open(ev);
  }
}

export class ColumnDefinition implements IColumnDefinitionStorage {

  ColumnWidth: number;
  PropertyPath: string;
  Heading: string;
  Aggregate: any;
  Resizeable: boolean;
  Sortable: boolean;
  RenderFunction: (value: any, model: any) => any;
  TrustedHtml: any;
  Template: any;
  Formatter: any;
  CellClass: any;
  AllowFilter: any;
  SortValueSelector: any;
  HideHeading: boolean;
  IsSorted: boolean;
  IsAscending: boolean;
  NoPrint: any;
  Rtl: any;
  Class: string;
  FormattedValues: any;
  Align: string;
  IsVisible: boolean;
  MainClass: string[];
  ClassList: string[];
  CellClassList: string[]
  CurrentX: number;
  ColumnCallback: (column: ColumnDefinition, columnWidth: number) => void;
  HideInMenu: boolean;
  FilterText?: string; // für HestiaTableFilter

  constructor(params) {
    this.ColumnWidth = params.ColumnWidth ? params.ColumnWidth : 150;
    this.PropertyPath = params.PropertyPath ? params.PropertyPath : null;
    this.Heading = params.Heading ? params.Heading : null;
    this.Aggregate = params.Aggregate;
    this.Resizeable = params.Resizeable;
    this.Sortable = params.Sortable;
    this.RenderFunction = params.RenderFunction;
    this.TrustedHtml = params.TrustedHtml;
    this.Template = params.Template;
    this.Formatter = params.Formatter;
    this.CellClass = params.CellClass;
    this.AllowFilter = params.AllowFilter;
    this.SortValueSelector = params.SortValueSelector;
    this.HideHeading = params.HideHeading;
    this.IsSorted = params.IsSorted;
    this.IsAscending = params.IsAscending;
    this.NoPrint = params.NoPrint;
    this.Rtl = params.Rtl;
    this.Class = "column_" + this.makeId(8);
    this.FormattedValues = {};

    HESTIA_TABLE_RULES.insertRule("." + this.Class + " { width: " + this.ColumnWidth + "px;}");
    STYLE_DICT[this.Class] = this.GetStyle();

    if (typeof params.Align !== 'undefined') {
      this.Align = params.Align;
    } else {
      this.Align = 'left';
    }

    if (typeof params.IsVisible !== 'undefined') {
      this.IsVisible = params.IsVisible;
    } else {
      this.IsVisible = true;
    }

    this.MainClass = this.GetMainClass();
    this.ClassList = this.GetClassList();
    this.CellClassList = this.GetCellClassList();
  }

  private makeId(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  GetStyle() {
    const classes: any[] = HESTIA_TABLE_RULES.rules || HESTIA_TABLE_RULES.cssRules;
    for (let x = 0; x < classes.length; x++) {
      if (classes[x].selectorText === "." + this.Class) {
        return classes[x];
      }
    }
  }

  CheckVisibility() {
    if (this.IsVisible) {
      STYLE_DICT[this.Class].style.display = "block";
    } else {
      STYLE_DICT[this.Class].style.display = "none";
    }
  }

  GetMainClass() {
    const result: string[] = [];
    result.push(this.Class);
    return result;
  }

  Mouseup(event) {
    $(document).off("mousemove");
  }

  Mousemove(event) {
    if (this.CurrentX) {
      const delta = this.CurrentX - event.pageX;
      if (Math.abs(delta) > 2) {
        const newWidth = this.ColumnWidth - delta;
        if (newWidth < 50) {
          return;
        } else {
          this.ColumnWidth = newWidth;
        }
      } else {
        return;
      }
    }
    this.CurrentX = event.pageX;
    this.UpdateWidth();
  }

  ResizeToFit() {
    let maxWidth = 50;
    const elements = $("." + this.Class);
    for (let i = 1; i < elements.length; i++) {
      const currentElement = elements[i];
      const width1 = (<any>currentElement.children[0]).offsetWidth + 16;
      let width2 = width1;
      if (currentElement.children.length > 1 && currentElement.children[1].firstElementChild) {
        const element = currentElement.children[1].firstElementChild;
        width2 = (<any>element).offsetWidth + 16;
      }

      maxWidth = Math.max(maxWidth, width1, width2);
    }
    this.ColumnWidth = maxWidth;
    this.UpdateWidth();
  }

  UpdateWidth() {
    STYLE_DICT[this.Class].style.width = this.ColumnWidth + "px";
    if (this.ColumnCallback) {
      this.ColumnCallback(this, this.ColumnWidth);
    }
  }

  StartResize($event) {
    this.CurrentX = $event.originalEvent.pageX;
    $(document).bind("mousemove", this.Mousemove.bind(this));
    $(document).one("mouseup", this.Mouseup.bind(this));
  }

  GetClassList() {
    const result: string[] = [];
    result.push("hestia-head-base");
    if (this.Sortable) {
      result.push("hestia-head-sortable");
    }
    if (this.Align === "left") {
      result.push("hestia-cell-left");
    }
    if (this.Align === "center") {
      result.push("hestia-cell-center");
    }
    if (this.Align === "right") {
      result.push("hestia-cell-right");
    }
    return result;
  }

  GetCellClassList() {
    const result: string[] = [];
    result.push(this.Class);
    if (this.Align === "stretch") {
      result.push("hestia-cell-stretch");
    }
    if (this.Align === "left") {
      result.push("hestia-cell-left");
    }
    if (this.Rtl) {
      result.push("table-column-rtl");
    }
    if (this.Align === "center") {
      result.push("hestia-cell-center");
    }
    if (this.Align === "right") {
      result.push("hestia-cell-right");
    }
    if (this.CellClass) {
      result.push(this.CellClass);
    }
    return result;
  }

  GetValue(model: any) {
    let result = model[this.PropertyPath];
    if (this.isFunction(result)) {
      result = model[this.PropertyPath](model);
    }
    if (this.RenderFunction) {
      return this.RenderFunction(result, model);
    } else {
      return result;
    }
  }

  private isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }

  GetAggregate(index: number, model: any) {
    if (model) {
      if (model.AggregateFunctions) {
        if (index < model.AggregateFunctions.length) {
          const aggregateFunction = model.AggregateFunctions[index].func;
          if (this.Aggregate && index < this.Aggregate.length && this.Aggregate[index] === true) {
            const values = [];
            if (model.Data) {
              model.Data.forEach(cModel => {
                const value = this.GetValue(cModel);
                if (value) {
                  values.push(value);
                }
              });
              if (values.length && model.Data.length) {
                return aggregateFunction(values, model.Data.length);
              }
            }
          }
        }
      }
    }
    return null;
  }

  GetFormattedValue(model) {
    if (this.Formatter) {
      return this.Formatter(this.GetValue(model), model);
    } else {
      return this.GetValue(model);
    }
  }
}

interface HestiaDataTableScope extends angular.IScope {
  Localization: PluginDict;
  ParentScope: angular.IScope;
  IsMobile: () => boolean;
  ngModel: any;
  TransforTableTimeouted: () => void;
  TransformationTimeout: any;
  trySetPagination: any;
  transformTable: any;

}

export class HestiaDataTableDirective implements angular.IDirective {
  restrict = 'E';
  scope = { ngModel: "=" };
  templateUrl = '/ClientApp/src/ajs/Views/Common/HestiaDataTable.htm';
  private $scope: HestiaDataTableScope;

  link = (scope: angular.IScope, $elem: angular.IAugmentedJQuery, $attrs: angular.IAttributes, $ctrl: any) => {
    const $scope = scope as HestiaDataTableScope;
    this.$scope = $scope;

    // Initialze Base-Table events
    addDefaultScopeFunctions($scope, this.theme);
    $scope.Localization = this.localizationService.GetPluginDict("Core");
    $scope.ParentScope = $scope.$parent;

    $scope.IsMobile = () => {
      return this.ScreenWidthService.IsMobile();
    };

    if ($scope.ngModel) {
      $scope.ngModel.IsOpen = true;
      $scope.ngModel.Element = $elem;
      if ($scope.ngModel.ExternalLocalization) {
        $scope.Localization.Merge($scope.ngModel.ExternalLocalization);
      }
      $scope.ngModel.Directive = $scope;
      $scope.ngModel.OnSourceChanged = () => {
        $scope.TransforTableTimeouted();
      };
      $scope.ngModel.OnUpdate = () => {
        $scope.TransforTableTimeouted();
      };
      $scope.ngModel.RequestNextUpdate($scope.ngModel);
    }

    $elem.on('$destroy', function () {
      if ($scope && $scope.ngModel) {
        $scope.ngModel.IsOpen = false;
      }
    });
    $scope.$on("$destroy", function () {
      if ($scope && $scope.ngModel) {
        $scope.ngModel.IsOpen = false;
      }
    });

    $scope.TransforTableTimeouted = () => {
      if ($scope.TransformationTimeout !== null) {
        this.$timeout.cancel($scope.TransformationTimeout);
      }
      $scope.TransformationTimeout = this.$timeout($scope.transformTable);
    };

    $scope.trySetPagination = ($scope2, attempt) => {
      if ($scope2 && $scope2.ngModel) {
        if ($scope2.ngModel.Pagination.CurrentPage === -1 && attempt < 100) {
          $scope2.ngModel.SetPage(0);
        }
      } else {
        this.$timeout(function () {
          $scope.trySetPagination($scope2, attempt++);
        }, 100);
      }
    }
    $scope.transformTable = () => {
      if ($scope.ngModel.Pagination) {
        if ($scope.ngModel.NumberOfPages() < $scope.ngModel.Pagination.CurrentPage + 1 || $scope.ngModel.Pagination.CurrentPage < 0) {
          $scope.ngModel.SetPage(0);
          $scope.trySetPagination($scope, 0);
        }
      }
    }
  }

  constructor(
    private readonly $timeout: angular.ITimeoutService,
    public readonly localizationService: LocalizationService,
    private readonly ScreenWidthService: any,
    private readonly theme: ThemeDto
  ) { }

  static factory(): angular.IDirectiveFactory {
    const directive = ($timeout: angular.ITimeoutService, LocalizationService: LocalizationService, ScreenWidthService: any, theme: ThemeDto) =>
      new HestiaDataTableDirective($timeout, LocalizationService, ScreenWidthService, theme);
    directive.$inject = ['$timeout', 'LocalizationService', 'ScreenWidthService', 'theme'];
    return directive;
  }
}
