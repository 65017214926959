export class UrlUtils {
  static normalize(url: string) {
    if (url) {
      if (!this.startsWithProtocol(url)) {
        url = "https://" + url;
      }
    }
    return url;
  }

  static startsWithProtocol(url: string): boolean {
    if (!url) {
      return false;
    }

    if (new RegExp('^(?:[a-z+]+:)?//', 'i').test(url)) {
      return true;
    }

    if (url.toLowerCase().startsWith("mailto:")) {
      return true;
    }

    return false;
  }
}
