import { Component, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment';
import { firstValueFrom, forkJoin, Subject, takeUntil } from 'rxjs';
import { AppointmentDto } from 'src/ajs/Models/PublicContacts/AppointmentDto';
import { GetContactsResultDto } from 'src/ajs/Models/PublicContacts/GetContactsResultDto';
import { UserDto } from 'src/app/core/dto/user-dto';
import { MandatorService } from 'src/app/core/services/mandator.service';
import { UserService } from 'src/app/core/services/user.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { PublicCalendarPrivilegesService } from '../../services/public-calendar-privileges.service';
import { PublicCalendarService } from '../../services/public-calendar.service';
import { ContactVM } from './contact-vm';
import { DayVM } from './day.vm';

@Component({
  selector: 'app-mobile-month-view',
  templateUrl: './mobile-month-view.component.html',
  styleUrls: ['./mobile-month-view.component.scss']
})
export class MobileMonthViewComponent implements OnInit, OnDestroy {

  months: Date[];
  selectedMonth: Date;
  contacts: ContactVM[];
  selectedContact: ContactVM;
  data: GetContactsResultDto = { Groups: [], MayEdit: true, Extensions: [], MinDate: null, MaxDate: null };
  calendarsSharableWithMe: UserDto[] = [];
  calendarsSharedWithMe: UserDto[] = [];
  myCalendarSharedWith: UserDto[] = [];
  days: DayVM[];
  isLoading = false;
  private readonly destroy$ = new Subject<void>();

  constructor(
    public readonly publicCalendarService: PublicCalendarService,
    private readonly mandatorService: MandatorService,
    private readonly userService: UserService,
    private readonly privileges: PublicCalendarPrivilegesService,
    private readonly dialogService: DialogService
  ) {
    const now = new Date();

    this.months = [];
    this.months.push(new Date(now.getFullYear(), now.getMonth(), 1)); // dieses Monat
    this.months.push(new Date(now.getFullYear(), now.getMonth() + 1, 1)); // nächstes Monat

    this.selectMonth(this.months[0]);
  }

  async ngOnInit() {
    this.mandatorService.selectedMandatorChanged.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.loadData();
    });
    await this.loadData();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private async loadData() {
    this.isLoading = true;

    const queryResult = await firstValueFrom(forkJoin([
      this.publicCalendarService.getCalendar(this.mandatorService.selectedMandatorId),
      this.publicCalendarService.getCalendarsSharableWithMe(),
      this.publicCalendarService.getCalendarsSharedWithMe(),
      this.publicCalendarService.getMyCalendarSharedWith()
    ]));

    this.data = queryResult[0];
    this.calendarsSharableWithMe = queryResult[1];
    this.calendarsSharedWithMe = queryResult[2];
    this.myCalendarSharedWith = queryResult[3];

    this.contacts = [];
    for (const group of this.data.Groups) {
      for (const contact of group.Contacts) {
        const exists = this.contacts.findIndex(x => x.contact.AccountName === contact.AccountName) >= 0;
        if (!exists) {
          const element: ContactVM = {
            contact: contact,
            isMyself: false,
            isCalendarSharableWithMe: false,
            isCalendarSharedWithMe: false,
            isMyCalendarSharedWith: false,
            isRequested: false
          };

          if (contact.AccountName === this.userService.currentUser.userName) {
            element.isMyself = true;
          }
          else {
            if (this.myCalendarSharedWith.findIndex(x => x.userName === contact.AccountName) >= 0) {
              element.isMyCalendarSharedWith = true;
            }

            if (this.calendarsSharableWithMe.findIndex(x => x.userName === contact.AccountName) >= 0) {
              element.isCalendarSharableWithMe = this.privileges.canRequestAccess(this.mandatorService.selectedMandatorId);
            }

            if (this.calendarsSharedWithMe.findIndex(x => x.userName === contact.AccountName) >= 0) {
              element.isCalendarSharedWithMe = true;
            }
          }

          this.contacts.push(element);
        }
      }
    }

    this.sortContacts(this.contacts);

    // Nach einem Mandantenwechsel existiert der zuvor gewählte Mitarbeiter u.U. nicht mehr
    if (this.selectedContact) {
      let exists = false;

      for (const group of this.data.Groups) {
        if (group.Contacts.findIndex(x => x.AccountName === this.selectedContact.contact.AccountName) >= 0) {
          exists = true;
          break;
        }
      }

      if (!exists) {
        this.selectedContact = this.getDefaultContact();
      }
    }
    else {
      this.selectedContact = this.getDefaultContact();
    }

    this.fillEvents();
    this.isLoading = false;
  }


  getEventTimeAndLocation(event: AppointmentDto) {
    let result = "";

    if (event.MomentStart) {
      result += `${event.MomentStart.format("HH:mm")} - ${event.MomentEnd.format("HH:mm")}`;
    }

    if (event.MomentStart && event.Location) {
      result += ", ";
    }

    if (event.Location) {
      result += event.Location;
    }
    return result;
  }

  fillEvents() {
    for (const day of this.days) {
      day.events = [];
    }

    if (this.data) {
      for (const group of this.data.Groups) {
        for (const contact of group.Contacts) {
          if (this.selectedContact && contact.AccountName === this.selectedContact.contact.AccountName) {
            for (const appointment of contact.Appointments) {
              for (const day of this.days) {
                if (moment(appointment.Start).format('YYYY-MM-DD') === moment(day.date).format("YYYY-MM-DD")) {

                  const exists = day.events.findIndex(x => x.Id === appointment.Id) >= 0;
                  if (!exists) {
                    day.events.push(appointment);
                  }
                  break;
                }
              }
            }
            break;
          }
        }
      }
    }
  }

  selectMonth(month: Date) {
    this.selectedMonth = month;
    this.createDayVMs(month);
    this.fillEvents();
  }

  selectContact(contact: ContactVM) {
    this.selectedContact = contact;
    this.fillEvents();
  }

  createDayVMs(date: Date) {
    this.days = [];

    const numberOfDays = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    for (let d = 1; d <= numberOfDays; d++) {
      this.days.push({
        date: new Date(date.getFullYear(), date.getMonth(), d),
        events: []
      });
    }
  }

  private sortContacts(array: ContactVM[]) {
    array.sort((a, b) => {
      if (a.isMyself && !b.isMyself) {
        return -1;
      }
      else if (!a.isMyself && b.isMyself) {
        return 1;
      }

      if (a.isCalendarSharedWithMe && !b.isCalendarSharedWithMe) {
        return -1;
      }
      else if (!a.isCalendarSharedWithMe && b.isCalendarSharedWithMe) {
        return 1;
      }

      if (a.isCalendarSharableWithMe && !b.isCalendarSharableWithMe) {
        return -1;
      }
      else if (!a.isCalendarSharableWithMe && b.isCalendarSharableWithMe) {
        return 1;
      }

      if (a.contact.LastName > b.contact.LastName) {
        return 1;
      }
      else if (a.contact.LastName < b.contact.LastName) {
        return -1;
      }

      if (a.contact.FirstName > b.contact.FirstName) {
        return 1;
      }
      else if (a.contact.FirstName < b.contact.FirstName) {
        return -1;
      }
      return 0;
    });
  }

  /** Gibt den Mitarbeiter zurück, der standardmäßig ausgewählt sein soll. */
  private getDefaultContact(): ContactVM {
    for (const contact of this.contacts) {
      if (contact.isMyself) {
        return contact;
      }
    }

    for (const contact of this.contacts) {
      return contact;
    }

    return null;
  }

  async requestSharing(contact: ContactVM) {
    if (contact.isRequested) {
      await this.dialogService.showMessage("Zugriff angefordert", "Zugriff auf den Kalender ist bereits angefordert.");
      return;
    }

    if (contact.isCalendarSharedWithMe) {
      await this.dialogService.showMessage("Zugriff freigegeben", "Zugriff auf den Kalender ist bereits freigegeben.");
      return;
    }

    if (!await this.dialogService.showConfirmation("Zugriff anfordern",
      `Zugriff auf den Kalender von ${contact.contact.FirstName} ${contact.contact.LastName} anfordern?`, "Anfordern", "Abbrechen")) {
      return;
    }

    try {
      await firstValueFrom(this.publicCalendarService.requestSharing(contact.contact.AccountName));
      contact.isRequested = true;
      this.dialogService.showMessage("Zugriff angefordert",
        `Es wurde Zugriff auf den Kalender von ${contact.contact.FirstName} ${contact.contact.LastName} angefordert.`);
    }
    catch (err) {
      this.dialogService.showMessage("Fehler", `Ein Fehler ist aufgetreten.`);
    }
  }
}



