import * as moment from 'moment';
import { TimelineMode } from './timeline-mode.enum';
import { TimelineDatePickerService } from './TimelineDatePickerService';

export class TimelineOptions {

  Hours: number;
  Ticks: number;
  TickMinutes: number;
  IsMinimum: boolean;
  IsMaximum: boolean;
  StartTime: Date;
  MinDate: Date;
  MaxDate: Date;

  constructor(
    public mode: TimelineMode,
    private readonly timelineService: TimelineDatePickerService) {

    switch (this.mode) {
      case TimelineMode.Day:
        this.Hours = 16;
        this.Ticks = 16;
        this.TickMinutes = 60;
        break;
      case TimelineMode.Week:
        this.Hours = 168;
        this.Ticks = 7;
        this.TickMinutes = 1440;
        break;
      case TimelineMode.Month:
        this.Hours = 672;
        this.Ticks = 28;
        this.TickMinutes = 1440;
        break;
    }

    this.StartTime = null;
  }

  CalculateCurrentStartTime() {
    const resultDate = new Date();
    resultDate.setMinutes(resultDate.getMinutes() - 120);
    resultDate.setSeconds(0);
    resultDate.setMilliseconds(0);

    const currentMinutes = resultDate.getMinutes();
    const newMinutes = currentMinutes - currentMinutes % this.TickMinutes;
    resultDate.setMinutes(newMinutes);
    return resultDate;
  }

  CaclulatedEndTime() {
    return moment(this.StartTime).add(this.TickMinutes * this.Ticks, 'm');
  }

  SetStartTime(date) {
    if (this.timelineService.datePickerPanel) {
      this.timelineService.datePickerPanel.$$state.value.close();
      this.timelineService.datePickerPanel = null;
    }

    date = new Date(date);
    this.IsMinimum = this.IsMaximum = false;
    this.StartTime = date;
    if (this.MinDate && date <= this.MinDate) {
      this.StartTime = new Date(this.MinDate);
      this.IsMinimum = true;
    }
    if (this.MaxDate && new Date(date.getTime() + this.TickMinutes * this.Ticks * 60000) >= this.MaxDate) {
      this.StartTime = new Date(this.MaxDate.getTime() - this.TickMinutes * this.Ticks * 60000);
      this.IsMaximum = true;
    }
  };
}