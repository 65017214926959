import angular, { IQService, IScope } from "angular";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { GetGroupResultDto } from "src/ajs/Models/Administration/GetGroupResultDto";
import { GroupRoleAccessDto } from "src/ajs/Models/Administration/GroupRoleAccessDto";
import { QueryRolesResultDto } from "src/ajs/Models/Administration/QueryRolesResultDto";
import { RoleRights } from "src/ajs/Models/Administration/RoleRights";
import { SetLdapGroupResultDto } from "src/ajs/Models/Administration/SetLdapGroupResultDto";
import { SetUserGroupResultDto } from "src/ajs/Models/Administration/SetUserGroupResultDto";
import { QueryLdapGroupsResultDto } from "src/ajs/Models/PublicContacts/QueryLdapGroupsResultDto";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { LegacyUserDto } from "src/ajs/Models/User/LegacyUserDto";
import { QueryUserResultDto } from "src/ajs/Models/User/QueryUserResultDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";


createEditMandatorGroupController.$inject = ['$scope', '$mdToast', '$mdDialog', '$q', 'ApiHandlerService', 'ParentGroup', 'rights', 'theme'];

export function createEditMandatorGroupController(
  $scope: IEditMandatorGroupControllerScope,
  $mdToast: angular.material.IToastService,
  $mdDialog: angular.material.IDialogService,
  $q: IQService,
  ApiHandlerService: ApiHandlerService,
  ParentGroup: any,
  rights: RoleRights,
  theme: ThemeDto
) {
  addDefaultScopeFunctions($scope, theme);
  $scope.ParentGroup = ParentGroup;
  $scope.IsLoading = true;
  $scope.SelectedRightsLevel = 0;
  $scope.SelectedAccess = null;
  $scope.SelectedUser = null;
  $scope.SelectedLdapGroup = null;
  $scope.AccessSearchString = null;
  $scope.UserSearchString = null;
  $scope.LdapSearchString = null;
  $scope.Rights = rights;
  $scope.LdapAvailable = false;

  if ($scope.ParentGroup.IsNew) {
    $scope.Model = {
      Title: null,
      Description: null,
      IsNew: true,
      MandatorId: $scope.ParentGroup.MandatorId,
      Groups: [],
      Users: [],
      LdapAvailable: false
    } as GetGroupResultDto;
    $scope.IsLoading = false;
  }
  else {
    $scope.Id = ParentGroup.Id;
    ApiHandlerService.SendRequest("Administration", "GetGroup", { groupId: $scope.Id }).then(function (data: GetGroupResultDto) {
      $scope.Model = data;
      $scope.IsLoading = false;
    });
  }

  $scope.QueryLdapGroups = function (searchString: string) {
    let result = null;
    const deferred = $q.defer();
    const request = ApiHandlerService.SendRequest("Administration", "QueryLdapGroups", { groupId: $scope.Id, query: searchString }).then(function (data: QueryLdapGroupsResultDto) {
      result = data.LdapGroups;
      deferred.resolve(result);
    });
    return deferred.promise;
  };


  $scope.QueryUser = function (searchString: string) {
    let result = null;
    const request = ApiHandlerService.SendRequest("Administration", "QueryAllUser", { groupId: $scope.Id, query: searchString }).then(function (data: QueryUserResultDto) {
      result = data.Users;
      return result;
    });
    const deferred = $q.defer();
    deferred.resolve(request);
    return deferred.promise;
  };

  $scope.QueryAccess = function (searchString: string) {
    let result = null;
    const request = ApiHandlerService.SendRequest("Administration", "QueryRoles", { groupId: $scope.Id, query: searchString }).then(function (data: QueryRolesResultDto) {
      result = data.Roles;
      return result;
    });
    const deferred = $q.defer();
    deferred.resolve(request);
    return deferred.promise;
  };

  $scope.AddNewLdapGroup = function () {

    if ($scope.SelectedLdapGroup !== null) {
      ApiHandlerService.SendRequest("Administration", "SetLdapGroup", { ldapId: $scope.SelectedLdapGroup.LdapId, groupId: $scope.Id, isIn: true }).then(function (data: SetLdapGroupResultDto) {
        if (data.Success) {
          $scope.Model.LdapGroups.push(data.Model);
        }
      });
      $scope.SelectedLdapGroup = null;
      $scope.LdapSearchString = null;
    }
  };

  $scope.RemoveLdapGroup = function (ldap) {
    $scope.SelectedLdapGroup = null;
    $scope.LdapSearchString = null;
    ApiHandlerService.SendRequest("Administration", "SetLdapGroup", { ldapId: ldap.LdapId, groupId: $scope.Id, isIn: false }).then(function (data: SetLdapGroupResultDto) {
      if (data.Success) {
        $scope.Model.LdapGroups = $scope.Model.LdapGroups.filter(item => item !== ldap);
      }
    });
  };

  $scope.AddNewUser = function () {
    if ($scope.SelectedUser !== null) {
      ApiHandlerService.SendRequest("Administration", "SetUserGroup", { userId: $scope.SelectedUser.Id, groupId: $scope.Id, isIn: true }).then(function (data: SetUserGroupResultDto) {
        if (data.Success) {
          $scope.Model.Users.push(data.User);
          $scope.SelectedUser = null;
          $scope.UserSearchString = null;
        }
      });
    }
  };

  $scope.RemoveUser = function (user: LegacyUserDto) {
    ApiHandlerService.SendRequest("Administration", "SetUserGroup", { userId: user.Id, groupId: $scope.Id, isIn: false }).then(function (data: SetUserGroupResultDto) {
      if (data.Success) {
        $scope.Model.Users = $scope.Model.Users.filter(item => item !== user);
      }
      else {
        const toast = $mdToast.simple()
          .textContent(data.Message)
          .position('bottom right')
          .capsule(true);
        $mdToast.show(toast);
      }
    });
  };

  $scope.AddNewAccess = function () {
    if ($scope.SelectedAccess !== null) {
      const request = ApiHandlerService.SendRequest("Administration", "SetGroupRole", { groupId: $scope.Id, roleId: $scope.SelectedAccess.RoleId, rights: $scope.SelectedRightsLevel }).then(function (data: GroupRoleAccessDto) {
        $scope.Model.Access.push(data);
        $scope.SelectedAccess = null;
        $scope.AccessSearchString = "";
      });
    }
  };

  $scope.DeleteAccess = function (access) {
    if (access) {
      const request = ApiHandlerService.SendRequest("Administration", "UnsetGroupRole", { groupId: $scope.Id, roleId: access.RoleId }).then(function (data) {
        $scope.Model.Access = $scope.Model.Access.filter(item => item !== access);
      });
    }
  };

  $scope.AccessRightChanged = function (access) {
    if (access) {
      const request = ApiHandlerService.SendRequest("Administration", "AlterGroupRole", { groupId: $scope.Id, roleId: access.RoleId, rights: access.Rights }).then(function (data) { });
    }
  };

  $scope.SaveBaseData = function () {
    const request = ApiHandlerService.SendRequest("Administration", "SetGroupBaseData", { model: $scope.Model }).then(function (data: GetGroupResultDto) {
      $scope.Model = data;
      $scope.Id = $scope.ParentGroup.Id = $scope.Model.Id;
      $scope.ParentGroup.IsNew = false;
      $scope.ParentGroup.Title = $scope.Model.Title;
      $scope.ParentGroup.Description = $scope.Model.Description;
      const toast = $mdToast.simple()
        .textContent("Gruppe wurde gespeichert")
        .position('bottom right')
        .capsule(true);
      $mdToast.show(toast);
    });
  };


  $scope.SelectedAccessChanged = function (access) {

  };

  $scope.SelectedUserChanged = function (user) {
    $scope.AddNewUser();
  };

  $scope.SelectedLdapGroupChanged = function (ldapGroup) {
    $scope.SelectedLdapGroup = ldapGroup;
    $scope.AddNewLdapGroup();
  };

  $scope.Cancel = function () {
    $mdDialog.hide();
  };
}

interface IEditMandatorGroupControllerScope extends IScope {
  ParentGroup: any;
  IsLoading: boolean;
  SelectedRightsLevel: number;
  SelectedAccess: any;
  SelectedUser: any;
  SelectedLdapGroup: any;
  AccessSearchString: string;
  UserSearchString: string;
  LdapSearchString: string;
  Rights: RoleRights;
  LdapAvailable: boolean;
  Model: GetGroupResultDto;
  Id: string;

  QueryLdapGroups: (searchString: string) => angular.IPromise<unknown>;
  QueryUser: (searchString: string) => angular.IPromise<unknown>;
  QueryAccess: (searchString: string) => angular.IPromise<unknown>;
  AddNewLdapGroup: () => void;
  AddNewAccess: () => void;
  RemoveLdapGroup: (ldap: any) => void;
  AddNewUser: () => void;
  RemoveUser: (user: LegacyUserDto) => void;
  DeleteAccess: (access: any) => void;
  AccessRightChanged: (access: any) => void;
  SaveBaseData: () => void;
  SelectedAccessChanged: (access: any) => void;
  SelectedUserChanged: (user: any) => void
  SelectedLdapGroupChanged: (ldapGroup: any) => void;
  Cancel: () => void;
}
