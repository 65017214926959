import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function minToday(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const date = new Date(control.value);
    const now = new Date();

    if (date.valueOf() && date.valueOf() < now.valueOf()) {
      return { 'minToday': { 'value': control.value } };
    }
    return null;
  };
}
