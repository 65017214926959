import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { COLEDITOR_DEF, COLEDITOR_DEF_FIXED_END, COLEDITOR_DEF_FIXED_START, COLEDITOR_STORAGE_KEY, ColumnEditorService } from 'src/app/shared/services/column-editor/column-editor.service';
import { SORT_DEFAULT_ACTIVE, SORT_DEFAULT_DIRECTION, SORT_STORAGE_KEY, SortService } from 'src/app/shared/services/sort/sort.service';
import { ContractService } from '../services/contract.service';
import { Observable, Subject, firstValueFrom, forkJoin, map, startWith, takeUntil, merge, tap, switchMap, catchError, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ContractListResultDto } from '../dto/contract-list-result-dto';
import { MatTableDataSourceEx } from 'src/app/shared/utils/mat-table-data-source-ex';
import { ContractListVm } from './contract-list-vm';
import { SageExportService } from 'src/app/sage-export/services/sage-export.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SageExportContractQueueDto } from 'src/app/sage-export/dto/sage-export-contract-queue-dto';
import { ErpCachedAddressDto } from 'src/app/erp/dto/erp-cached-address-dto';
import { ContractTypeDto } from '../dto/contract-type-dto';
import { ContractDto } from '../dto/contract-dto';
import { EXCEL_EXPORT_COLDEF, ExcelExportService } from 'src/app/shared/services/excel-export/excel-export.service';
import { XmlService } from 'src/app/xml/services/xml.service';
import { MandatorService } from 'src/app/core/services/mandator.service';
import { NewSignalrService } from 'src/app/core/services/signalr/new-signalr.service';

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.scss'],
  providers: [
    ColumnEditorService,
    { provide: COLEDITOR_STORAGE_KEY, useValue: 'contract-list.columns' },
    {
      provide: COLEDITOR_DEF, useValue: [
        { name: 'isInSageExportContractQueue', header: 'In Export Warteschlange' },
        { name: 'isAccounted', header: 'Verrechnet' },
        { name: 'warning', header: 'Warnung' },
        { name: 'id', header: 'Id' },
        { name: 'dueDate', header: 'Gültig bis' },
        { name: 'billingDate', header: 'Verrechnet bis' },
        { name: 'intervall', header: 'Intervall' },
        { name: 'type', header: 'Typ' },
        { name: 'address', header: 'Addresse' },
        { name: 'title', header: 'Titel' },
        { name: 'externalContractNumber', header: 'Externe Vertragsnummer' },
        { name: 'description', header: 'Beschreibung' },
      ]
    },
    {
      provide: COLEDITOR_DEF_FIXED_START, useValue: [
        { name: 'checkbox', header: '' },
        { name: 'edit', header: '' },
      ]
    },
    {
      provide: COLEDITOR_DEF_FIXED_END, useValue: [
        { name: 'whitespace', header: '' },
      ]
    },
    SortService,
    { provide: SORT_STORAGE_KEY, useValue: 'contract-list.sort' },
    { provide: SORT_DEFAULT_ACTIVE, useValue: 'startDate' },
    { provide: SORT_DEFAULT_DIRECTION, useValue: 'desc' },
    ExcelExportService,
    {
      provide: EXCEL_EXPORT_COLDEF, useValue: [
        { name: 'isInSageExportContractQueue', noExport: true },
        { name: 'isAccounted', noExport: true },
        { name: 'warning', noExport: true },
        { name: 'id', propertyPath: 'contract.contractId' },
        { name: 'dueDate', propertyPath: 'contract.dueDate' },
        { name: 'billingDate', propertyPath: 'contract.billingDate' },
        { name: 'intervall', propertyPath: 'contract.intervallMonts' },
        { name: 'type', propertyPath: 'contract.type.description' },
        { name: 'address', propertyPath: 'contract.cachedAddress.cachedDisplayName' },
        { name: 'title', propertyPath: 'contract.title' },
        { name: 'externalContractNumber', propertyPath: 'contract.externalContractNumber' },
        { name: 'description', propertyPath: 'contract.description' },
      ]
    }
  ]
})

export class ContractListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  destroy$: Subject<void> = new Subject<void>();
  refresh$: Subject<void> = new Subject<void>();

  private _outsideAddressId: number;

  @Input() set outsideAddressId(value: number) {
    if (this._outsideAddressId !== value) {
      this._outsideAddressId = value;
      this.refresh$.next();
    }
  }

  get outsideAddressId() {
    return this._outsideAddressId;
  }

  vms: ContractListVm[] = [];
  dataSource = new MatTableDataSourceEx<ContractListVm>();
  totalCount;
  selectedAmount = 0;

  contractTypes: ContractTypeDto[];
  addresses: ErpCachedAddressDto[];

  contractTypeId: FormControl<number> = new FormControl<number>(0);
  addressIdFilter: FormControl<number> = new FormControl<number>(null);
  search: FormControl<string> = new FormControl<string>('');
  contractFilter: FormControl<number> = new FormControl<number>(0);
  isCanceled: FormControl<boolean> = new FormControl<boolean>(false);

  contractTypeSearch: FormControl<string> = new FormControl<string>("");
  filteredContractTypes$: Observable<ContractTypeDto[]>;

  addressSearch: FormControl<string> = new FormControl<string>("");
  filteredAddresse$: Observable<ErpCachedAddressDto[]>;

  isLoading = false;

  accountedFilter: boolean = false;
  warningFilter: boolean = false;

  sageExportContractQueue: SageExportContractQueueDto;

  constructor(
    public readonly sortService: SortService,
    public readonly columnEditor: ColumnEditorService,
    private readonly contractService: ContractService,
    private readonly sageExportService: SageExportService,
    private readonly snackBar: MatSnackBar,
    private readonly excelExportService: ExcelExportService,
    private readonly xmlService: XmlService,
    private readonly mandatorService: MandatorService,
    private readonly signalRService: NewSignalrService

  ) {


  }

  async ngOnInit() {
    await this.signalRService.startConnection();

    this.signalRService.hubConnection.on("contractUpdated", async () => {
      this.refresh$.next();
    });

    setInterval(() => this.signalRService.checkSignalRConnection(), 10000);

    await this.loadData();
  }

  async ngAfterViewInit() {

    this.isLoading = true;

    const queryResult = await firstValueFrom(forkJoin([
      this.contractService.getContractTypes(),
    ]));

    this.contractTypes = queryResult[0];

    merge(
      this.sort.sortChange,
      this.paginator.page,
      this.contractTypeId.valueChanges,
      this.addressIdFilter.valueChanges,
      this.search.valueChanges,
      this.contractFilter.valueChanges,
      this.isCanceled.valueChanges,
      this.refresh$,
    ).pipe(
      takeUntil(this.destroy$),
      startWith({}),
      tap(() => {
        this.isLoading = true;
      }),
      switchMap(() => this.contractService.getContractList(
        this.sort.active,
        this.sort.direction === "desc",
        this.paginator.pageSize,
        this.paginator.pageIndex,
        this.contractTypeId.value,
        this.addressIdFilter.value,
        this.search.value,
        this.contractFilter.value,
        this.isCanceled.value,
        this.accountedFilter,
        this.warningFilter,
        this._outsideAddressId,
        this.mandatorService.selectedMandator.id
      )
        .pipe(
          catchError(err => {
            console.log(err);
            return of({ totalCount: 0, contracts: [] } as ContractListResultDto);
          })
        ))
    ).subscribe(result => {

      if (!result) {
        result = { totalCount: 0, contracts: [] } as ContractListResultDto;
      }

      this.isLoading = false;
      this.totalCount = result.totalCount;

      this.vms = [];

      for (const contract of result.contracts) {
        const vm: ContractListVm = {
          isSelected: new FormControl<boolean>(false),
          contract: contract
        };

        this.vms.push(vm);
      }

      this.dataSource.data = this.vms;
    });

    this.isCanceled.valueChanges.subscribe(() => {
      this.paginator.firstPage();
    });

    this.mandatorService.selectedMandatorIdChanged.subscribe(async () => {
      await this.loadData();
    });


  }


  ngOnDestroy() {
    this.refresh$.complete();
    this.destroy$.next();
    this.destroy$.complete();

  }

  async loadData() {
    this.refresh$.next();
    this.addresses = await firstValueFrom(this.contractService.getErpCachedAddresses(this.mandatorService.selectedMandatorId));
    this.sageExportContractQueue = await firstValueFrom(this.sageExportService.getSageExportContractQueue(this.mandatorService.selectedMandatorId));

    this.filteredContractTypes$ = this.contractTypeSearch.valueChanges.pipe(
      takeUntil(this.destroy$),
      startWith({}),
      map(() => {
        const search = (this.contractTypeSearch.value ?? '').toLowerCase().trim();
        return this.contractTypes.filter(x => x.description.toLowerCase().trim().includes(search));
      })
    );

    this.filteredAddresse$ = this.addressSearch.valueChanges.pipe(
      takeUntil(this.destroy$),
      startWith({}),
      map(() => {
        const search = (this.addressSearch.value ?? '').toLowerCase().trim();
        return this.addresses.filter(x => x.cachedDisplayName.toLowerCase().trim().includes(search));
      })
    );
  }

  async openDialog(contractId: number) {
    await this.contractService.showContractDialog(contractId, this.outsideAddressId);
    this.refresh$.next();

  }

  toggleAccountedFilter() {
    this.accountedFilter = !this.accountedFilter;
    this.refresh$.next();
  }

  toggleWarningFilter() {
    this.warningFilter = !this.warningFilter;
    this.refresh$.next();
  }

  resetFilter() {
    this.contractTypeId.patchValue(0);
    this.addressIdFilter.patchValue(null);
    this.search.patchValue("");
    this.contractFilter.patchValue(0);
    this.isCanceled.patchValue(false);
  }

  allSelected() {
    if (this.dataSource.data.some(x => !x.isSelected.value)) {
      return false;
    }
    return true;
  }

  someSelected() {
    if (!this.allSelected()) {
      if (this.dataSource.data.some(x => x.isSelected.value)) {
        return true;
      }
    }
    return false;
  }

  selectAll(selected: boolean) {
    for (const vm of this.dataSource.data) {
      vm.isSelected.patchValue(selected);
    }
  }

  async addSelectedToSageExportQueue() {

    const contractIds: number[] = [];

    for (const vm of this.dataSource.data) {
      if (vm.isSelected.value) {
        contractIds.push(vm.contract.contractId);
      }
    }

    await firstValueFrom(this.sageExportService.addToSageExportQueue(contractIds, this.mandatorService.selectedMandatorId));

    this.loadData();

    this.snackBar.open("In Export Warteschlange eingefügt", null, { duration: 2000, horizontalPosition: "end", verticalPosition: "bottom" });
  }

  async addToSageExportQueue(contractId: number) {

    const contractIds: number[] = [];
    contractIds.push(contractId);

    await firstValueFrom(this.sageExportService.addToSageExportQueue(contractIds, this.mandatorService.selectedMandatorId));

    this.loadData();

    this.snackBar.open("In Export Warteschlange eingefügt", null, { duration: 2000, horizontalPosition: "end", verticalPosition: "bottom" });
  }

  async openSageExportQueueDialog() {
    const result = await this.sageExportService.showContractSageExportQueueDialog();

    if (result) {
      this.loadData();
    }
  }

  isContractInSageExportContractQueue(dto: ContractDto) {

    if (dto) {
      if (this.sageExportContractQueue?.sageExportContractGroups.some(x =>
        x.sageExportContracts.some(y =>
          y.contractId === dto.contractId))
      ) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  exportExcel() {
    let filename = "Verträge";

    if (this._outsideAddressId) {
      const address = this.addresses.find(x => x.erpCachedAddressId === this._outsideAddressId);

      if (address) {
        filename = filename + "-" + address.cachedDisplayName + ".xlsx";
      }
    }

    this.excelExportService.exportArrayAsExcel(this.dataSource.data, filename);
  }

  async exportExcelAll() {
    let filename = "Verträge";

    if (this._outsideAddressId) {
      const address = this.addresses.find(x => x.erpCachedAddressId === this._outsideAddressId);

      if (address) {
        filename = filename + "-" + address.cachedDisplayName + ".xlsx";
      }
    }

    const allFilteredContracts = await firstValueFrom(
      this.contractService.getContractList(
        this.sort.active,
        this.sort.direction === "desc",
        this.paginator.pageSize,
        this.paginator.pageIndex,
        this.contractTypeId.value,
        this.addressIdFilter.value,
        this.search.value,
        this.contractFilter.value,
        this.isCanceled.value,
        this.accountedFilter,
        this.warningFilter,
        this._outsideAddressId,
        this.mandatorService.selectedMandator.id,
        true
      )
    );

    const excelData: ContractListVm[] = [];
    for (const contract of allFilteredContracts.contracts) {
      const newVm: ContractListVm = {
        contract: contract,
        isSelected: new FormControl<boolean>(false)
      };
      excelData.push(newVm);
    }

    this.excelExportService.exportArrayAsExcel(excelData, filename);
  }


  exportSelectedToExcel() {

    const dataToExport: ContractListVm[] = [];

    let filename = "Verträge";

    if (this._outsideAddressId) {
      const address = this.addresses.find(x => x.erpCachedAddressId === this._outsideAddressId);

      if (address) {
        filename = filename + "-" + address.cachedDisplayName + ".xlsx";
      }
    }

    for (const entry of this.dataSource.data) {
      if (entry.isSelected.value) {
        dataToExport.push(entry);
      }
    }

    if (dataToExport.length) {
      this.excelExportService.exportArrayAsExcel(dataToExport, filename);
    }
  }

  calculateSelectedAmount() {
    this.selectedAmount = this.vms.filter(x => x.isSelected.value).length;
  }

  openXmlImportDialog() {
    this.xmlService.showXmlImportDialog();
  }

}
