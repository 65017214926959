import * as  angular from "angular";

export class InvalidIfDirective implements angular.IDirective {
  require = 'ngModel';
  link = (scope: angular.IScope, elm: angular.IAugmentedJQuery, attrs: angular.IAttributes, ctrl: any) => {

    let argsObject = scope.$eval(attrs.invalidIf);

    if (!angular.isObject(argsObject)) {
      argsObject = { invalidIf: attrs.invalidIf };
    }

    for (const validationKey in argsObject) {
      scope.$watch(argsObject[validationKey], (newVal) => {
        ctrl.$setValidity(validationKey, !newVal);
      });
    }
  }

  static factory(): angular.IDirectiveFactory {
    const directive = () => new InvalidIfDirective();
    directive.$inject = [];
    return directive;
  }
}

