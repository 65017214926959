import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { GetItemDto } from '../../dto/get-item-dto';
import { SetItemDto } from '../../dto/set-item-dto';
import { Api2Service } from '../api2.service';
import { UserService } from '../user.service';
import { AsyncStorage } from './async-storage';

@Injectable({
  providedIn: 'root'
})
export class RemoteStorageService implements AsyncStorage {

  constructor(
    private readonly api: Api2Service,
    private readonly userService: UserService
  ) { }

  async setItem(key: string, value: string): Promise<void> {
    // Wenn kein Benutzer angemeldet ist, können wir nicht speichern
    if (!this.userService.isLoggedIn) {
      console.warn('No user logged in, remote storage unavailable.');
      return;
    }

    const dto: SetItemDto = {
      key: key,
      value: value
    };

    await firstValueFrom(this.api.post('RemoteStorage', 'SetItem', null, dto));
  }

  async getItem(key: string): Promise<string | null> {
    if (!this.userService.isLoggedIn) {
      console.warn('No user logged in, remote storage unavailable.');
      return null;
    }

    const result = await firstValueFrom(this.api.get<GetItemDto>('RemoteStorage', 'GetItem', { key: key }));
    return result?.value;
  }

  async setBoolean(key: string, value: boolean): Promise<void> {
    await this.setItem(key, value ? 'true' : 'false');
  }

  async getBoolean(key: string): Promise<boolean> {
    return (await this.getItem(key)) === 'true';
  }
}
