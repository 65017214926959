import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallAnalyticsComponent } from './components/call-analytics/call-analytics.component';
import { SharedModule } from '../shared/shared.module';
import { PhoneContactDialogComponent } from './components/phone-contact-dialog/phone-contact-dialog.component';
import { PhoneListComponent } from './components/phone-list/phone-list.component';
import { CallDetailsComponent } from './components/call-edit/call-details.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    CallAnalyticsComponent,
    PhoneContactDialogComponent,
    PhoneListComponent,
    CallDetailsComponent
  ]
})
export class PhoneModule { }
