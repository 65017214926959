import { IScope, material } from "angular";
import { CategoryColumnDto } from "src/ajs/Models/Document/CategoryColumnDto";
import { CategoryDto } from "src/ajs/Models/Document/CategoryDto";
import { CustomColumnDto } from "src/ajs/Models/Document/CustomColumnDto";
import { arrayRemove } from "src/ajs/Utils/HestiaCommon";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { MandatorService } from "src/app/core/services/mandator.service";


export class EditDocumentsCategoryDialogController {
  static $inject = ['$scope', '$mdDialog', 'StorageService', 'ApiHandlerService', 'Element', 'OnSuccess', 'ColumnDefinitions', 'MandatorServiceNgx'];
  constructor(
    private readonly $scope: IEditDocumentsCategoryDialogControllerScope,
    private readonly $mdDialog: material.IDialogService,
    private readonly ApiHandlerService: ApiHandlerService,
    Element: CategoryDto,
    OnSuccess: (result: CategoryDto) => void,
    ColumnDefinitions: CustomColumnDto[],
    private readonly mandatorService: MandatorService
  ) {
    $scope.Title = "Kategorie bearbeiten";
    $scope.Model = Element;
    $scope.ColumnDefinition = ColumnDefinitions;
    $scope.OnSuccess = OnSuccess;

    $scope.SearchColumn = this.SearchColumn.bind(this);
    $scope.AddColumn = this.AddColumn.bind(this);
    $scope.DeleteColumn = this.DeleteColumn.bind(this);
    $scope.CleanArray = this.CleanArray.bind(this);
    $scope.MoveColumn = this.MoveColumn.bind(this);
    $scope.ToggleRequired = this.ToggleRequired.bind(this);
    $scope.SortUp = this.SortUp.bind(this);
    $scope.SortDown = this.SortDown.bind(this);
    $scope.Close = this.Close.bind(this);
    $scope.Submit = this.Submit.bind(this);

    if (!$scope.Model) {
      $scope.Title = "Kategorie anlegen";
      $scope.Model = {
        Description: null,
        SortOrder: 0,
        FlexWidth: 100,
        Columns: []
      } as CategoryDto;
    }
    else {
      $scope.Model.Columns.forEach(c => {
        c.Column = $scope.ColumnDefinition.filter(f => f.Id === c.ColumnId)[0];
      });
    }
  }

  SearchColumn(query: string) {
    if (!query) {
      query = "";
    }
    var columns = this.$scope.ColumnDefinition.filter(f => f.Description.toUpperCase().includes(query.toUpperCase()));
    var unique = columns.filter(f => this.$scope.Model.Columns.filter(x => f.Id === x.ColumnId).length === 0);
    if (unique.length > 10)
      unique = unique.slice(0, 10);
    return unique;
  }

  AddColumn(column: CustomColumnDto) {
    if (column) {
      this.$scope.Model.NewColumn = null;
      this.$scope.Model.Columns.push({
        Column: column,
        ColumnId: column.Id,
        IsRequired: false,
      } as CategoryColumnDto);
    }
    this.$scope.CleanArray();
  }

  DeleteColumn(column: CategoryColumnDto) {
    arrayRemove(this.$scope.Model.Columns, column);
    this.$scope.CleanArray();
  }

  CleanArray() {
    for (var i = 0; i < this.$scope.Model.Columns.length; i++) {
      var element = this.$scope.Model.Columns[i];
      element.CanSortUp = true;
      element.CanSortDown = true;
      if (i === 0) {
        element.CanSortUp = false;
      }
      if (i === this.$scope.Model.Columns.length - 1) {
        element.CanSortDown = false;
      }
      element.SortOrder = i;
    }
  }

  MoveColumn(oldIndex: number, newIndex: number) {
    if (newIndex >= this.$scope.Model.Columns.length) {
      var k = newIndex - this.$scope.Model.Columns.length + 1;
      while (k--) {
        this.$scope.Model.Columns.push(undefined);
      }
    }
    this.$scope.Model.Columns.splice(newIndex, 0, this.$scope.Model.Columns.splice(oldIndex, 1)[0]);
  }

  ToggleRequired(column: CategoryColumnDto) {
    column.IsRequired = !column.IsRequired;
  }

  SortUp(column: CategoryColumnDto) {
    var oldIndex = this.$scope.Model.Columns.indexOf(column);
    this.$scope.MoveColumn(oldIndex, oldIndex - 1);
    this.$scope.CleanArray();
  }

  SortDown(column: CategoryColumnDto) {
    var oldIndex = this.$scope.Model.Columns.indexOf(column);
    this.$scope.MoveColumn(oldIndex, oldIndex + 1);
    this.$scope.CleanArray();
  }

  Close() {
    this.$mdDialog.hide();
  }

  Submit() {
    if (!this.$scope.IsSubmiting) {
      this.$scope.IsSubmiting = true;
      this.ApiHandlerService.SendRequest("Document", "SaveCategory", { model: this.$scope.Model, mandatorId: this.mandatorService.selectedMandatorId }).then(result => {
        this.$scope.OnSuccess(result);
        this.$mdDialog.cancel();
      });
    }
  }
}

interface IEditDocumentsCategoryDialogControllerScope extends IScope {
  Title: string;
  Model: CategoryDto;
  ColumnDefinition: CustomColumnDto[];
  OnSuccess: (result: CategoryDto) => void;
  IsSubmiting: boolean;

  AddColumn: (column: CustomColumnDto) => void;
  SearchColumn: (query: string) => CustomColumnDto[];
  MoveColumn: (oldIndex: number, newIndex: number) => void;
  Close: () => void;
  Submit: () => void;
  SortDown: (column: CategoryColumnDto) => void;
  CleanArray: () => void;
  DeleteColumn: (column: CategoryColumnDto) => void;
  ToggleRequired: (column: CategoryColumnDto) => void;
  SortUp: (column: CategoryColumnDto) => void;
}
