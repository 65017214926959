<mat-form-field appearance="outline" id="cp-searchbar-search">
  <mat-label>Suche</mat-label>
  <input matInput type="text" [formControl]="searchFormControl">
  <mat-icon matPrefix>search</mat-icon>
  <button matSuffix mat-icon-button *ngIf="searchFormControl?.value" (click)="searchFormControl?.setValue('')">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>

<ng-content select="cp-searchbar-buttons">

</ng-content>
