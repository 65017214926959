import { IScope } from 'angular';
import { DragAndDropPanel } from '../Directives/DragAndDropPanel';
import { addDefaultScopeFunctions } from '../Functions/add-default-scope-functions';
import { ThemeDto } from '../Models/ThemeDto';
import { StorageService, StorageServiceInstance } from '../Services/StorageService';

createSubnetCalculatorController.$inject = ['$scope', 'mdPanelRef', 'StorageService', 'theme'];

export function createSubnetCalculatorController(
  $scope: ISubnetCalculatorControllerScope,
  mdPanelRef: angular.material.IPanelRef,
  StorageService: StorageService,
  theme: ThemeDto

) {
  addDefaultScopeFunctions($scope, theme);
  $scope.Storage = StorageService.CreateInstance("SubnetCalculatorController");
  $scope.DragAndDropPanel = new DragAndDropPanel("Subnet Kalkulator", mdPanelRef);
  $scope.CleanValues = {
    IsValid: false,
    Ip: "",
    IpSlashMask: 0,
    Subnet: ""
  };

  $scope.OnInputChanged = function () {
    const adr = new Uint32Array(1);
    if (CheckInput($scope.IPinput)) {
      $scope.CleanValues.Ip = $scope.IPinput;
      if ($scope.IPinput.indexOf('/') >= 8) {
        $scope.CleanValues.IpSlashMask = parseInt($scope.IPinput.substr($scope.IPinput.indexOf('/') + 1));
        adr[0] = ShiftanSet(0, parseInt($scope.CleanValues.IpSlashMask.toString()));
        $scope.CleanValues.Subnet = FormatToString(adr[0]);
        $scope.CleanValues.IsValid = true;
        $scope.SNinput = $scope.CleanValues.Subnet;
      }
      else {
        $scope.CleanValues.Ip = $scope.IPinput;
        $scope.CleanValues.Subnet = $scope.SNinput;
        if (CheckInput($scope.SNinput) && $scope.SNinput !== "0.0.0.0") {
          const invertedSubnet = ~SplitString($scope.CleanValues.Subnet) + 1;
          const subnet = 32 - Math.log2(invertedSubnet);
          $scope.IPinput = $scope.CleanValues.Ip + "/" + subnet.toString();
        }
        $scope.CleanValues.IsValid = true;
      }
    }
    if ($scope.CleanValues.IsValid === true) {
      CalcAddresses();
    }

  };

  $scope.ASinput = 1;
  $scope.SubnetAdress = null;
  $scope.Subnet = null;
  $scope.Ranges = [];
  $scope.TrennLinie = "";
  $scope.OffSet = "";
  $scope.GesamtAddressen = "";
  $scope.ValueSubnet = false;
  $scope.SubnetShow = false;

  function ShiftanSet(number, repeat) {
    let result = number;
    for (let i = 0; i < repeat; i++) {
      result = (result >> 1) | 0x80000000;
    }
    return result;
  }

  function SplitString(input) {
    const splittstring = input.split('.');
    const uintBuffer = new Uint32Array(5);
    uintBuffer[0] = parseInt(splittstring[0]);
    uintBuffer[1] = parseInt(splittstring[1]);
    uintBuffer[2] = parseInt(splittstring[2]);
    uintBuffer[3] = parseInt(splittstring[3]);
    uintBuffer[4] = (uintBuffer[0] << 24) | (uintBuffer[1] << 16) | (uintBuffer[2] << 8) | (uintBuffer[3]);
    return uintBuffer[4];
  }

  function FormatToString(input) {
    const subnettostring = input.toString(2).padStart(32, '0');
    return (parseInt(subnettostring.slice(0, 8), 2) + "." + parseInt(subnettostring.slice(8, 16), 2) + "." + parseInt(subnettostring.slice(16, 24), 2) + "." + parseInt(subnettostring.slice(24, 32), 2));

  }

  function CheckInput(ipaddress) {

    if ($scope.IPinput.indexOf('/') >= 0) {
      return true;
    }
    else {
      if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
        return true;
      }
      else {
        return false;
      }
    }


  }

  function CalcAddresses() {
    if (CheckInput($scope.CleanValues.Subnet)) {

      $scope.ValueSubnet = true;
      $scope.SubnetShow = true;

      const subnetInput = null;
      let subnetShifted = null;
      let subnet = null;
      const addresses = new Uint32Array(3);

      $scope.Ranges = [];
      $scope.ASinput = parseInt(<string>$scope.ASinput);


      addresses[1] = SplitString($scope.CleanValues.Ip) & SplitString($scope.CleanValues.Subnet);
      addresses[0] = ShiftanSet(SplitString($scope.CleanValues.Subnet), Math.log2($scope.ASinput));
      const invertedSubnet = ~SplitString($scope.CleanValues.Subnet) + 1;
      subnetShifted = 32 - Math.log2(invertedSubnet) + Math.log2($scope.ASinput);
      subnet = 32 - Math.log2(invertedSubnet);
      const offset = parseInt((invertedSubnet / $scope.ASinput).toString());

      for (let i = 0; i < $scope.ASinput; i++) {
        const result = new Uint32Array(2);
        result[0] = (addresses[1] & addresses[0]) | (offset * i);
        result[1] = (addresses[1] & addresses[0]) | (offset * (i + 1) - 1);
        $scope.Ranges.push({
          CurrentIpInRange: false,
          RawResult: result,
          Start: FormatToString(result[0]),
          End: FormatToString(result[1])
        });

      }

      for (let i = 0; i < $scope.Ranges.length; i++) {
        if (SplitString($scope.IPinput) < $scope.Ranges[i].RawResult[1]) {
          $scope.Ranges[i].CurrentIpInRange = true;
          break;
        }
      }
      $scope.SubnetAdress = FormatToString(addresses[1]) + "/" + subnetShifted.toString();
      $scope.OffSet = offset;
      $scope.GesamtAddressen = invertedSubnet;
      $scope.Subnet = FormatToString(addresses[0]);
    }

  }
}


interface ISubnetCalculatorControllerScope extends IScope {
  Storage: StorageServiceInstance;
  DragAndDropPanel: DragAndDropPanel;
  CleanValues: {
    IsValid: boolean,
    Ip: string,
    IpSlashMask: number | string,
    Subnet: string
  };
  IPinput: string;
  SNinput: string;
  ASinput: number | string;
  SubnetAdress: string;
  Subnet: string;
  Ranges: any[];
  TrennLinie: string;
  OffSet: string | number;
  GesamtAddressen: string | number;
  ValueSubnet: boolean;
  SubnetShow: boolean;

  OnInputChanged: () => void;

}
