import angular from "angular";
import moment from "moment";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { SystemPartDto } from "src/ajs/Models/Systembuilder/SystemPartDto";
import { SystemPartUnistallReasonDto } from "src/ajs/Models/Systembuilder/SystemPartUnistallReasonDto";
import { ThemeDto } from "src/ajs/Models/ThemeDto";

createDeletePartController.$inject = ['$scope', '$mdDialog', 'Reasons', 'Part', 'OnSuccess', 'theme'];

export function createDeletePartController(
  $scope: DeletePartControllerScope,
  $mdDialog: angular.material.IDialogService,
  Reasons: SystemPartUnistallReasonDto[],
  Part: SystemPartDto,
  OnSuccess: () => void,
  theme: ThemeDto) {

  addDefaultScopeFunctions($scope, theme);
  $scope.Reasons = Reasons;
  $scope.SelectedReasonId = null;
  $scope.Part = Part;
  $scope.OnSuccess = OnSuccess;

  $scope.HasRequiredReason = function () {
    if (!$scope.SelectedReasonId) {
      return true;
    }

    const reason = $scope.Reasons.filter(f => f.Id === $scope.SelectedReasonId)[0];
    if (reason.Reason === "Sonstiges") {
      return true;
    }
    return false;
  };

  $scope.Hide = function () {
    $mdDialog.hide();
  };

  $scope.Cancel = function () {
    $mdDialog.cancel();
  };

  $scope.Ok = function () {
    Part.UninstallDate = moment();
    Part.UninstallReasonId = $scope.SelectedReasonId;
    Part.UninstallNote = $scope.UninstallMemo;
    if ($scope.OnSuccess) {
      $scope.OnSuccess();
    }
    $mdDialog.hide();
  };
}

interface DeletePartControllerScope extends angular.IScope {
  Reasons: SystemPartUnistallReasonDto[],
  SelectedReasonId: number | null,
  Part: SystemPartDto,
  OnSuccess: () => void,
  HasRequiredReason: () => boolean;
  Hide: () => void;
  Cancel: () => void;
  Ok: () => void;
  UninstallMemo: string;
}
