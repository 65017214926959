import * as angular from 'angular';

// @types/ckeditor hat mit angular nicht funktioniert, daher wurden die Typen auf any umgestellt.

export class CkeditorService {

  static $inject = [];

  constructor() { }

  createSmallToolbarConfig()/*: CKEDITOR.config*/ {
    const config: any /*CKEDITOR.config*/ = {};
    config.toolbarGroups = [
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
      { name: 'forms', groups: ['forms'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
      { name: 'links', groups: ['links'] },
      { name: 'insert', groups: ['insert'] },
      { name: 'colors', groups: ['colors'] },
      { name: 'styles', groups: ['styles'] },
      { name: 'tools', groups: ['tools'] },
      { name: 'others', groups: ['others'] },
      { name: 'about', groups: ['about'] }
    ];
    config.tabSpaces = 4;
    config.floatSpaceDockedOffsetY = 30;
    config.extraPlugins = 'colorbutton,justify,font,find,openlink';
    config.removeButtons = 'Source,Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Subscript,Superscript,Blockquote,CreateDiv,Language,Outdent,Indent,BidiLtr,Link,Unlink,Anchor,Image,Flash,Smiley,HorizontalRule,Table,SpecialChar,PageBreak,Iframe,Styles,Format,Maximize,ShowBlocks,About,CopyFormatting,RemoveFormat,BidiRtl,Strike,JustifyBlock,JustifyRight,JustifyCenter,JustifyLeft';

    // Eigenschaft nicht in type vorhanden
    config['openlink_enableReadOnly'] = true;

    return config;
  }

  createFullToolbarConfig()/*: CKEDITOR.config*/ {
    const config: any /*CKEDITOR.config*/ = {};
    config.toolbarGroups = [
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
      { name: 'forms', groups: ['forms'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
      { name: 'links', groups: ['links'] },
      { name: 'insert', groups: ['insert'] },
      { name: 'styles', groups: ['styles'] },
      { name: 'colors', groups: ['colors'] },
      { name: 'tools', groups: ['tools'] },
      { name: 'others', groups: ['others'] }
    ];

    // Upload dropped or pasted images to the CKFinder connector (note that the response type is set to JSON).
    config.uploadUrl = '/api/FileDownload/UploadFile';
    config.tabSpaces = 4;
    config.extraPlugins = 'colorbutton,justify,font,find,openlink,image2,uploadimage,uploadfile,html5video,codesnippet';
    config.removePlugins = 'image';
    config.resize_enabled = false;
    config.skin = "moono-lisa";
    config.startupShowBorders = false;
    config.allowedContent = true;
    config.fullPage = true;
    config.height = '100%';
    config.removeButtons = 'Subscript,Superscript';

    // Eigenschaft nicht in type vorhanden
    config['openlink_enableReadOnly'] = true;

    return config;

  }
}
