import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewSignalrService {

  hubConnection: signalR.HubConnection;

  constructor() {

    let basePath = "";

    if (environment.baseUrlApi2) {
      basePath = environment.baseUrlApi2;
    }
    else {
      basePath = document.getElementsByTagName('base')[0].href + "api2/";
    }


    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(basePath + "contractHub", {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .build();
  }

  async startConnection() {
    this.hubConnection.start()
      .then(() => console.log("contractHub connectet"))
      .catch(err => console.log("Error while connecting with contractHub : " + err));
  }

  checkSignalRConnection(): void {
    if (this.hubConnection.state === signalR.HubConnectionState.Disconnected) {
      this.hubConnection.start()
        .then(() => console.log("contractHub connectet"))
        .catch(err => console.log("Error while connecting with contractHub : " + err));
    }
  }


}
