<div class="dialog-container">

  <div mat-dialog-title class="dialog-title"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle>

    <div>
      Import Fehler
    </div>

    <button mat-icon-button (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>

  </div>

  <mat-dialog-content class="mat-typography">

    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="sageExportContractGroupId">
        <th mat-header-cell *matHeaderCellDef>
          SageExportContractGroupId
        </th>
        <td mat-cell *matCellDef="let row">
          {{row.sageExportContractGroupId}}
        </td>
      </ng-container>

      <ng-container matColumnDef="belegID">
        <th mat-header-cell *matHeaderCellDef>
          EPCBelegID
        </th>
        <td mat-cell *matCellDef="let row">
          {{row.belegID}}
        </td>
      </ng-container>

      <ng-container matColumnDef="artikelnummer">
        <th mat-header-cell *matHeaderCellDef>
          Artikelnummer
        </th>
        <td mat-cell *matCellDef="let row">
          {{row.artikelnummer}}
        </td>
      </ng-container>

      <ng-container matColumnDef="fehlertext">
        <th mat-header-cell *matHeaderCellDef>
          Fehlertext
        </th>
        <td mat-cell *matCellDef="let row">
          {{row.fehlertext}}
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button color="warn" (click)="delete(row)"><mat-icon>delete</mat-icon></button>
        </td>
      </ng-container>




      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>


  </mat-dialog-content>


  <mat-divider></mat-divider>

  <mat-dialog-actions>

    <div class="footer-container">

      <div>
      </div>

      <div>
      </div>

    </div>

  </mat-dialog-actions>
</div>