import angular, { IScope, ITimeoutService } from "angular";
import { DragAndDropPanel } from "src/ajs/Directives/DragAndDropPanel";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { QueryArticleDto } from "src/ajs/Models/Erp/QueryArticleDto";
import { QueryArticleResultDto } from "src/ajs/Models/Erp/QueryArticleResultDto";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { MandatorService } from "src/app/core/services/mandator.service";

createArticleSearchController.$inject = ['$scope', 'mdPanelRef', '$timeout', 'ApiHandlerService', 'SuccessCallback', 'theme', 'MandatorServiceNgx'];

export function createArticleSearchController(
  $scope: IArticleSearchControllerScope,
  mdPanelRef: angular.material.IPanelRef,
  $timeout: ITimeoutService,
  ApiHandlerService: ApiHandlerService,
  SuccessCallback: DecodeSuccessCallback,
  theme: ThemeDto,
  mandatorServiceNgx: MandatorService

) {

  addDefaultScopeFunctions($scope, theme);
  $scope.SearchString = null;
  $scope.CurrentTimeout = null;
  $scope.DragAndDropPanel = new DragAndDropPanel("Artikelsuche", mdPanelRef);

  $scope.ItemClicked = function (item) {
    if (SuccessCallback) {
      SuccessCallback(item);
    }
    mdPanelRef.close();
  };

  $scope.SearchChanged = function () {
    $scope.Hits = [];
    if ($scope.SearchString && $scope.SearchString.length > 2) {
      if ($scope.CurrentTimeout) {
        $timeout.cancel($scope.CurrentTimeout);
      }
      $scope.CurrentTimeout = $timeout(function () {
        ApiHandlerService.SendRequest("Erp", "QueryArticleBySearchText", { query: $scope.SearchString, mandatorId: mandatorServiceNgx.selectedMandatorId }).then(function (data: QueryArticleResultDto) {
          $scope.CurrentTimeout = null;
          $timeout(function () {
            $scope.Hits = data.Result;
          });
        });
      });
    }
  };
}


interface IArticleSearchControllerScope extends IScope {
  SearchString: string;
  CurrentTimeout: any;
  Hits: QueryArticleDto[];
  DragAndDropPanel: DragAndDropPanel;

  SearchChanged: () => void;
  ItemClicked: (any) => void;

}
