import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { XmlImportDialogComponent } from './components/xml-import-dialog/xml-import-dialog.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    XmlImportDialogComponent
  ]
})
export class XmlModule { }
