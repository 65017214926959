import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { TaskListComponent } from './components/task-list/task-list.component';
import { TaskTimeListComponent } from './components/task-time-list/task-time-list.component';
import { TaskReceiptEditComponent } from './components/task-receipt-edit/task-receipt-edit.component';
import { TaskEditComponent } from './components/task-edit/task-edit.component';
import { TaskEditDialogComponent } from './components/task-edit-dialog/task-edit-dialog.component';
import { TaskReceiptListComponent } from './components/task-receipt-list/task-receipt-list.component';

@NgModule({
  providers: [
    DatePipe
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    TaskListComponent,
    TaskTimeListComponent,
    TaskReceiptEditComponent,
    TaskEditComponent,
    TaskEditDialogComponent,
    TaskReceiptListComponent,
  ],


})
export class TaskModule { }
