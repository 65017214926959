import { fixDate } from "src/ajs/Utils/DateUtils";
import { ContactDto } from "src/app/contacts/dto/contact-dto";
import { fixTaskEntryDto, TaskEntryDto } from "src/app/task/dto/task-entry-dto";
import { fixPhone3CxCallPartEntryDto, Phone3CxCallPartEntryDto } from "./phone-3cx-call-part-entry-dto";
import { fixPhone3CxExtensionDto, Phone3CxExtensionDto } from "./phone-3cx-extension-dto";
import { fixPhone3CxQueueDto, Phone3CxQueueDto } from "./phone-3cx-queue-dto";

export interface Phone3CxCallDto {
  Id: number;
  EnvironmentId: string;
  StartDateTime: Date;
  EndDateTime: Date;
  TaskId: number | null;
  Task: TaskEntryDto;
  TasksReceiptId: number | null;
  TasksReceiptRowId: number | null;
  IsDeleted: boolean;
  DeletedDate: Date | null;
  Note: string;
  ExtensionId: number | null;
  QueueId: number | null;
  OtherPartyNumber: string;
  OtherPartyExtensionId: number | null;
  OtherPartyQueueId: number | null;
  IsAnswered: boolean;
  ConnectionType: number;
  OtherPartyMainContactId: number | null;

  OtherPartyMainContact: ContactDto;
  Extension: Phone3CxExtensionDto;
  Queue: Phone3CxQueueDto;
  CleanPartList: Phone3CxCallPartEntryDto[];
}

export function fixPhone3CxCallDto(dto: Phone3CxCallDto) {
  dto.StartDateTime = fixDate(dto.StartDateTime);
  dto.EndDateTime = fixDate(dto.EndDateTime);

  if (dto.DeletedDate) {
    dto.DeletedDate = fixDate(dto.DeletedDate);
  }

  if (dto.Task) {
    fixTaskEntryDto(dto.Task);
  }

  if (dto.Extension) {
    fixPhone3CxExtensionDto(dto.Extension);
  }

  if (dto.Queue) {
    fixPhone3CxQueueDto(dto.Queue);
  }

  if (dto.CleanPartList) {
    for (const x of dto.CleanPartList) {
      fixPhone3CxCallPartEntryDto(x);
    }
  }
}
