import * as angular from "angular";
import { LegacyMandatorDto } from "../Models/LegacyMandatorDto";
import { GlobalValueService } from "../Services/GlobalValueService";
import { LocalizationService, PluginDict } from "../Services/Localization/LocalizationService";
import { StorageService, StorageServiceInstance } from "../Services/StorageService";
import { SimpleStringResultDto } from "../Models/SimpleStringResult";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";
import { MandatorService } from "src/app/core/services/mandator.service";

export interface MandatorSelectorModel {
  IsLoading: boolean;
  IsOpen: boolean;
  TreeData: LegacyMandatorDto[];
  SelectedMandator: LegacyMandatorDto;
  OnChange: () => void;
  Storage: StorageServiceInstance;
  Localization: PluginDict;
  ToggleNode: (node: LegacyMandatorDto) => void;
  Toggle: () => void;
  SelectItem: (node: LegacyMandatorDto, noToggle: boolean) => void;
  UnselectAll: (node: LegacyMandatorDto[] | LegacyMandatorDto) => void;
  SelecteMandatorById: (id: string) => void;
  FindNodeByIdRecursive: (node: LegacyMandatorDto, id: string) => LegacyMandatorDto;
  SelectParentMandator: () => void;
  Initialize: () => void;
}

export function createMandatorSelectorModel(
  $rootScope: angular.IRootScopeService,
  ApiHandlerService: ApiHandlerService,
  StorageService: StorageService,
  $q: angular.IQService,
  LocalizationService: LocalizationService,
  UserService: any,
  GlobalValueService: GlobalValueService,
  MandatorService: MandatorService
) {

  const mandatorSvc: MandatorSelectorModel = {
    IsLoading: true,
    IsOpen: false,
    TreeData: [],
    SelectedMandator: null,
    OnChange: null,
    Storage: StorageService.CreateInstance("MandatorTree"),
    Localization: LocalizationService.GetPluginDict("Core"),

    ToggleNode: function (node: LegacyMandatorDto) {
      node.Collapsed = !node.Collapsed;
    },

    Toggle: function () {
      this.IsOpen = !this.IsOpen;
      const tree = $("#mandatorTree");
      const input = $("#mandatorInput");
      const inputPosition = input.offset();
      tree.css(inputPosition.toString());
      tree.css({ width: 'auto' });
      tree.toggle("fast");
    },

    SelectItem: function (node: LegacyMandatorDto, noToggle: boolean) {
      if (UserService.Data && UserService.Data.IsLoggedIn) {
        if (node !== this.SelectedMandator) {
          if (node.Id !== "none") {
            this.Storage.Set("SelectedMandator", node.Id);
          }
          const oldValue = node.Selected;
          this.UnselectAll(this.TreeData);
          node.Selected = !oldValue;
          this.SelectedMandator = node;
          ApiHandlerService.SendRequest("User", "SetSelectedMandator", { mandator: this.SelectedMandator }, true).then(function (data: SimpleStringResultDto) {
            if (data.Success) {
              GlobalValueService.set('currentMandator', node);
              $rootScope.$broadcast('bindMandator', node);
            }
          });
          // TODO: Call callbacks!
          if (this.OnChange) {
            this.OnChange();
          }
        }
        if (!noToggle) {
          this.Toggle();
        }
      }
    },

    UnselectAll: function (node: LegacyMandatorDto[] | LegacyMandatorDto) {
      if (Array.isArray(node)) {
        if (node.length) {
          for (let i = 0; i < node.length; i++) {
            this.UnselectAll(node[i]);
          }
        }
      }
      else {
        node.Selected = false;
        if (node.Children && node.Children.length) {
          for (let x = 0; x < node.Children.length; x++) {
            this.UnselectAll(node.Children[x]);
          }
        }
      }
    },

    SelecteMandatorById: function (id: string) {
      for (let i = 0; i < this.TreeData.length; i++) {
        const found = this.FindNodeByIdRecursive(this.TreeData[i], id);
        if (found) {
          this.SelectItem(found, true);
        }
      }
    },

    FindNodeByIdRecursive: function (node: LegacyMandatorDto, id: string) {
      if (node.Id === id) {
        return node;
      }
      else {
        if (node.Children && node.Children.length) {
          for (let x = 0; x < node.Children.length; x++) {
            const foundNode = this.FindNodeByIdRecursive(node.Children[x], id);
            if (foundNode) {
              return foundNode;
            }
          }
        }
      }
      return null;
    },

    SelectParentMandator: function () {
      if (this.SelectedMandator && this.SelectedMandator.ParentId) {
        this.SelecteMandatorById(this.SelectedMandator.ParentId);
      }
    },

    Initialize: function () {
      const deferred = $q.defer();
      const object = this;
      const mandatorId = MandatorService.selectedMandatorId;

      object.SelectedMandator = null;

      ApiHandlerService.SendRequest("Administration", "GetMandatorTree", { mandatorId: mandatorId }).then(function (data: LegacyMandatorDto) {
        object.TreeData = [data];
        object.SelecteMandatorById(object.Storage.GetOrCreate("SelectedMandator", "0"));
        if (!object.SelectedMandator)
          object.SelectItem(data, true);
        object.IsLoading = false;
        deferred.resolve();
      });
      return deferred.promise;
    }
  };
  UserService.RegisterLoadedEvent(() => {
    mandatorSvc.Initialize();
  });
  return mandatorSvc;
}
