import angular from "angular";
import { addDefaultScopeFunctions } from "src/ajs/Functions/add-default-scope-functions";
import { ConfigurationDto } from "src/ajs/Models/Phone3cx/ConfigurationDto";
import { ThemeDto } from "src/ajs/Models/ThemeDto";
import { ApiHandlerService } from "src/ajs/Services/ApiHandlerService";

createErpAdminDialgController.$inject = ['$scope', '$mdDialog', 'ApiHandlerService', 'theme'];

export function createErpAdminDialgController(
  $scope: IErpAdminDialgControllerScope,
  $mdDialog: angular.material.IDialogService,
  ApiHandlerService: ApiHandlerService,
  theme: ThemeDto,
) {


  addDefaultScopeFunctions($scope, theme);
  $scope.IsLoading = true;
  $scope.Model = {} as ConfigurationDto;
  $scope.Model.Type = 0;

  ApiHandlerService.SendRequest("Erp", "GetConfiguration").then(function (data: ConfigurationDto) {
    $scope.Model = data;
  });


  $scope.SaveData = function () {
    ApiHandlerService.SendRequest("Erp", "SetConfiguration", $scope.Model).then(function (data: ConfigurationDto) {
      $scope.Model = data;
      $scope.Cancel();
    });
  };

  $scope.Cancel = function () {
    $mdDialog.hide();
  };
}

interface IErpAdminDialgControllerScope extends angular.IScope {
  IsLoading: boolean;
  Model: ConfigurationDto;

  SaveData: () => void;
  Cancel: () => void;

}
