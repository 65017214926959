import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ApproveSharingComponent } from './components/approve-sharing/approve-sharing.component';
import { MobileMonthViewComponent } from './components/mobile-month-view/mobile-month-view.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    ApproveSharingComponent,
    MobileMonthViewComponent
  ]
})
export class PublicCalendarModule { }
