import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Service für die neue .NET6 Api (Hestia.Api).
 */
@Injectable({
  providedIn: 'root'
})
export class Api2Service {

  private readonly defaultHttpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8' }),
    withCredentials: true // withCredentials ist nötig, damit das AuthCookie mitgesendet wird.
  };

  constructor(
    private readonly http: HttpClient,
    @Inject('BASE_URL_API2') private readonly BASE_URL_API2: string
  ) {
  }

  get<T>(controller: string, action: string, params: { [param: string]: string | number | boolean } = null): Observable<T> {
    const url = this.getUrl(controller, action, params);
    return this.http.get<T>(url, this.defaultHttpOptions);
  }

  post<T>(controller: string, action: string, params: { [param: string]: string | number | boolean } = null, body: any = null): Observable<T> {
    const url = this.getUrl(controller, action, params);
    return this.http.post<T>(url, body, this.defaultHttpOptions);
  }

  postFile<T>(controller: string, action: string, file: File): Observable<T> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    const options = { headers, withCredentials: true };
    const url = this.getUrl(controller, action);
    return this.http.post<T>(url, formData, options);
  }

  getUrl(controller: string, action: string, params?: { [param: string]: string | number | boolean }) {
    let result = this.BASE_URL_API2 + controller + "/" + action;

    if (params) {
      let httpParams = new HttpParams();

      for (const param in params) {
        const value = params[param];

        if (value !== null && value !== undefined) {
          httpParams = httpParams.set(param, value);
        }
      }
      result += '?' + httpParams.toString();
    }
    return result;
  }
}
