import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSort } from '@angular/material/sort';
import { catchError, debounceTime, firstValueFrom, forkJoin, merge, Observable, of, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { COLEDITOR_DEF, COLEDITOR_DEF_FIXED_START, COLEDITOR_STORAGE_KEY, ColumnEditorService } from 'src/app/shared/services/column-editor/column-editor.service';
import { SortService, SORT_DEFAULT_ACTIVE, SORT_STORAGE_KEY } from 'src/app/shared/services/sort/sort.service';
import { MatTableDataSourceEx } from 'src/app/shared/utils/mat-table-data-source-ex';
import { TaskTimeListResultDto } from '../../dto/task-time-list-result-dto';
import { TaskService } from '../../services/task.service';
import { TaskTimeListVm } from './task-time-list-vm';
import { TaskTimeAddressDto } from "../../dto/task-time-address-dto";
import { UntypedFormControl } from '@angular/forms';
import { DoNotAccountRequestDto } from '../../dto/do-not-account-dto';
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { SearchBarService } from 'src/ajs/Services/SearchBarService';
import { MandatorService } from 'src/app/core/services/mandator.service';

@Component({
  selector: 'app-task-time-list',
  templateUrl: './task-time-list.component.html',
  styleUrls: ['./task-time-list.component.scss'],
  providers: [
    ColumnEditorService,
    { provide: COLEDITOR_STORAGE_KEY, useValue: 'task-time.columns' },
    {
      provide: COLEDITOR_DEF, useValue: [
        { name: 'address', header: 'Adresse/Kunde' },
        { name: 'totalTime', header: 'Gesamtzeit' },
        { name: 'timeEntries', header: 'Zeiteinträge' },
        { name: 'taskCalls', header: 'Taskanrufe' },
        { name: 'otherCalls', header: 'Andere Anrufe' },
      ]
    },
    {
      provide: COLEDITOR_DEF_FIXED_START, useValue: [
        { name: 'checkbox', header: '' },
      ]
    },
    SortService,
    { provide: SORT_STORAGE_KEY, useValue: 'task-time.sort' },
    { provide: SORT_DEFAULT_ACTIVE, useValue: 'address' },
  ]
})
export class TaskTimeListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  isLoading: boolean = false;
  anyChecked: boolean = false;

  readonly dataSource: MatTableDataSourceEx<TaskTimeListVm> = new MatTableDataSourceEx<TaskTimeListVm>();

  filterDate = new UntypedFormControl(new Date());
  today = new Date();

  private readonly destroy$ = new Subject<void>();
  private readonly refresh$ = new Subject<void>();

  constructor(
    public readonly columnEditor: ColumnEditorService,
    public readonly sortService: SortService,
    public readonly taskService: TaskService,
    private readonly SearchBarService: SearchBarService,
    private readonly mandatorService: MandatorService
  ) {
    this.dataSource.addSortingDataAccessor('address', x => x.TaskTime.Address);
    this.dataSource.addSortingDataAccessor('timeEntries', x => x.TaskTime.TimeEntryDuration);
    this.dataSource.addSortingDataAccessor('taskCalls', x => x.TaskTime.TaskCallDuration);
    this.dataSource.addSortingDataAccessor('otherCalls', x => x.TaskTime.OtherCallDuration);
    this.dataSource.addSortingDataAccessor('totalTime', x => this.calculateTotalCallDuration(x.TaskTime));

  }

  async ngOnInit() {
    await firstValueFrom(forkJoin([
      this.columnEditor.loaded$,
      this.sortService.loaded$
    ]));

  }

  ngAfterViewInit() {

    merge(
      this.filterDate.valueChanges,
      this.SearchBarService.searchTextChange.pipe(debounceTime(300)),
      this.refresh$,
      this.mandatorService.selectedMandatorIdChanged
    ).pipe(
      takeUntil(this.destroy$),
      startWith({}),
      tap(() => this.isLoading = true),
      switchMap(() => this.taskService.getTaskTimeList(
        DateUtils.addDays(this.filterDate.value, 1),
        this.SearchBarService.SearchText,
        this.mandatorService.selectedMandatorId
      ).pipe(
        catchError(err => {
          console.log(err);
          return of({ TaskTimeList: [] } as TaskTimeListResultDto);
        })
      ))
    ).subscribe(result => {
      this.isLoading = false;

      if (result) {
        this.dataSource.data = [];
        if (result.TaskTimeList) {
          for (const x of result.TaskTimeList) {
            const vm: TaskTimeListVm = {
              TaskTime: x,
              IsChecked: false
            };
            this.dataSource.data.push(vm);
          }
        }
        this.anyChecked = false;

        this.dataSource.data = this.dataSource.data;
      }
    });

    this.dataSource.sort = this.sort;

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.refresh$.complete();
  }


  toggleAllChecked(event: MatCheckboxChange) {

    if (event.checked) {
      for (const x of this.dataSource.data) {
        x.IsChecked = true;
      }
      this.anyChecked = true;
    }
    else {
      for (const x of this.dataSource.data) {
        x.IsChecked = false;
      }
      this.anyChecked = false;
    }
  }

  toggleChecked(vm) {
    vm.IsChecked = !vm.IsChecked;

    this.checkAnyChecked();
  }

  checkAnyChecked() {
    if (this.dataSource.data.some(x => x.IsChecked)) {
      this.anyChecked = true;
    }
    else {
      this.anyChecked = false;
    }
  }

  calculateTotalCallDuration(taskTime: TaskTimeAddressDto) {
    const result = taskTime.OtherCallDuration + taskTime.TaskCallDuration + taskTime.TimeEntryDuration;
    return result;
  }

  async doNotAccount() {
    const doNotAccountRequestDto: DoNotAccountRequestDto = {
      AddressIds: [],
      FilterDate: DateUtils.addDays(this.filterDate.value, 1),
      AccountingPeriodEnd: this.filterDate.value,
      MandatorId: this.mandatorService.selectedMandatorId
    };

    for (const taskTime of this.dataSource.data) {
      if (taskTime.IsChecked) {
        doNotAccountRequestDto.AddressIds.push(taskTime.TaskTime.AddressId);
      }
    }

    await firstValueFrom(this.taskService.doNotAccount(doNotAccountRequestDto));

    this.refresh$.next();
  }
}
