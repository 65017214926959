<div class="scroll">

  <table mat-table [dataSource]="dataSource" class="axs-table dense">

    <ng-container matColumnDef="details">
      <th mat-header-cell *matHeaderCellDef>
        <div class="column-container">
          <div>Mitarbeiter</div>
          <div>Datum</div>
          <div>Arbeitszeit (h)</div>
        </div>
      </th>

      <td mat-cell *matCellDef="let row" class="column-container" [formGroup]="row">

        <div *ngIf="row.value.isEditing">
          <mat-form-field appearance="fill">
            <mat-label>Mitarbeiter</mat-label>
            <mat-select formControlName="employeeId">
              <mat-option *ngFor="let user of users" [value]="user.Id">
                {{user.DisplayName }}
              </mat-option>
            </mat-select>
            <mat-error>{{ row.get('employeeId').errors | validationError }}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Datum</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="workDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error>{{ row.get('workDate').errors | validationError }}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Arbeitszeit (h)</mat-label>
            <input matInput formControlName="workTime" type="number">
            <mat-error>{{ row.get('workTime').errors | validationError }}</mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="!row.value.isEditing">
          <mat-form-field appearance="fill">
            <mat-label>Mitarbeiter</mat-label>
            <input matInput type="text" [value]="getEmployeeName(row.value.employeeId)" [readonly]="true">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Datum</mat-label>
            <input matInput type="text" [value]="row.value.workDate | date" [readonly]="true">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Arbeitszeit (h)</mat-label>
            <input matInput type="text" [value]="row.value.workTime" [readonly]="true" type="number">
          </mat-form-field>
        </div>

      </td>


    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>
        <div>Fehlerbeschreibung</div>
      </th>

      <td mat-cell *matCellDef="let row" [formGroup]="row">

        <mat-form-field appearance="outline" *ngIf="row.value.isEditing">
          <mat-label>Fehlerbeschreibung</mat-label>
          <textarea matInput class="textarea" formControlName="description"></textarea>
          <mat-error>{{ row.get('description').errors | validationError }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="!row.value.isEditing">
          <mat-label>Fehlerbeschreibung</mat-label>
          <textarea matInput class="textarea" [value]="row.value.description" [readonly]="true"></textarea>
        </mat-form-field>

      </td>
    </ng-container>

    <ng-container matColumnDef="work">
      <th mat-header-cell *matHeaderCellDef>
        <div>Arbeiten</div>
      </th>

      <td mat-cell *matCellDef="let row" [formGroup]="row">

        <mat-form-field appearance="outline" *ngIf="row.value.isEditing">
          <mat-label>Arbeiten</mat-label>
          <textarea matInput class="textarea" formControlName="work"></textarea>
          <mat-error>{{ row.get('work').errors | validationError }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="!row.value.isEditing">
          <mat-label>Arbeiten</mat-label>
          <textarea matInput class="textarea" [value]="row.value.work" [readonly]="true"></textarea>
        </mat-form-field>

      </td>

    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>
        <button color="primary" mat-icon-button (click)="addNewEntry()" matTooltip="Neuen Bericht erstellen" matTooltipPosition="before">
          <mdi-icon icon="plus-circle"></mdi-icon>
        </button>
      </th>

      <td mat-cell *matCellDef="let row" [formGroup]="row">

        <div class="button-container">
          <button color="primary" mat-icon-button type="button" (click)="saveReport(row)" *ngIf="row.value.isEditing" matTooltip="Speichern" matTooltipPosition="before" [disabled]="row.invalid">
            <mdi-icon icon="content-save"></mdi-icon>
          </button>

          <button color="warn" mat-icon-button type="button" (click)="deleteReport(row)" *ngIf="row.value.isEditing && !row.value.isNew" matTooltip="Löschen" matTooltipPosition="before">
            <mdi-icon icon="delete"></mdi-icon>
          </button>

          <button color="primary" mat-icon-button type="button" (click)="resetRow(row);" *ngIf="row.value.isEditing" matTooltip="Abbrechen" matTooltipPosition="before">
            <mdi-icon icon="close"></mdi-icon>
          </button>

          <button color="primary" mat-icon-button type="button" (click)="toggleIsEditing(row)" *ngIf="!row.value.isEditing" matTooltip="Bearbeiten" matTooltipPosition="before">
            <mdi-icon icon="pencil"></mdi-icon>
          </button>

          <button color="primary" mat-icon-button type="button" (click)="downloadPdf(row)" *ngIf="!row.value.isEditing" matTooltip="Als PDF downloaden" matTooltipPosition="before">
            <mdi-icon icon="download"></mdi-icon>
          </button>
        </div>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
</div>