import angular from "angular";
import { addDefaultScopeFunctions } from "../Functions/add-default-scope-functions";
import { GetPluginsResultDto } from "../Models/Administration/GetPluginsResultDto";
import { PluginDto } from "../Models/Administration/PluginDto";
import { SetPluginInstallStateResultDto } from "../Models/Administration/SetPluginInstallStateResultDto";
import { ThemeDto } from "../Models/ThemeDto";
import { ApiHandlerService } from "../Services/ApiHandlerService";


creatEditMandatorPluginsController.$inject = ['$scope', '$mdToast', '$mdDialog', 'ApiHandlerService', 'mandatorId', 'parentScope', 'theme'];

export function creatEditMandatorPluginsController(
  $scope: IEditMandatorPluginsControllerScope,
  $mdToast: angular.material.IToastService,
  $mdDialog,
  ApiHandlerService: ApiHandlerService,
  mandatorId: string,
  parentScope: any,
  theme: ThemeDto) {

  addDefaultScopeFunctions($scope, theme);

  $scope.IsLoading = true;
  $scope.MandatorId = mandatorId;
  $scope.ParentScope = parentScope;
  $scope.Plugins = [];

  ApiHandlerService.SendRequest("Administration", "GetAvailableAndInstalledPlugins", { mandatorId: $scope.MandatorId }).then(function (data: GetPluginsResultDto) {
    $scope.Plugins = data.Plugins;
    $scope.IsLoading = false;
  });

  $scope.TogglePlugin = function (plugin: PluginDto, toggle: boolean) {
    if (toggle) {
      plugin.IsInstalled = !plugin.IsInstalled;
    }
    plugin.IsToggling = true;
    ApiHandlerService.SendRequest("Administration", "SetPluginInstallState", { mandatorId: $scope.MandatorId, pluginId: plugin.PluginId, install: plugin.IsInstalled }).then(function (data: SetPluginInstallStateResultDto) {
      if (data.Success) {
        $scope.ParentScope.PluginsModel.Initialize();
      } else {
        plugin.IsInstalled = !plugin.IsInstalled;
        const toast = $mdToast.simple()
          .textContent(data.Error)
          .position('bottom right')
          .capsule(true);
        $mdToast.show(toast);
      }
      plugin.IsToggling = false;
    });
  };

  $scope.Cancel = function () {
    $mdDialog.hide();
  };
}

interface IEditMandatorPluginsControllerScope extends angular.IScope {
  IsLoading: boolean;
  MandatorId: string;
  ParentScope: any;
  Plugins: PluginDto[];
  TogglePlugin: (plugin: PluginDto, toggle: boolean) => void;
  Cancel: () => void;
}
