import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { ContractService } from '../services/contract.service';
import { firstValueFrom } from 'rxjs';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SageArticleVariantDto } from 'src/app/sage-articles/dto/sage-article-variant-dto';
import { SageArticleService } from 'src/app/sage-articles/services/sage-article.service';
import { SageArticleVariantExpressionDto } from 'src/app/sage-articles/dto/sage-article-variant-expression-dto';
import { MandatorService } from 'src/app/core/services/mandator.service';

@Component({
  selector: 'app-article-variation-dialog',
  templateUrl: './article-variation-dialog.component.html',
  styleUrls: ['./article-variation-dialog.component.scss']
})
export class ArticleVariationDialogComponent implements OnInit {

  article: SageArticleVariantDto;
  expression1: FormControl<string> = new FormControl<string>("");
  expression2: FormControl<string> = new FormControl<string>("");

  allVariantExpressions: SageArticleVariantExpressionDto[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: {
      article?: SageArticleVariantDto,
    },
    private readonly dialogRef: MatDialogRef<ArticleVariationDialogComponent>,
    private readonly contractService: ContractService,
    private readonly dialogService: DialogService,
    private readonly sageArticleService: SageArticleService,
    private readonly mandatorService: MandatorService
  ) {

    dialogRef.disableClose = true;
  }

  async ngOnInit() {
    this.article = this.data.article;
    this.allVariantExpressions = await firstValueFrom(this.sageArticleService.getAllArticleVariantExpressions(this.article.variant, this.mandatorService.selectedMandatorId));

  }


  async ok() {

    let currentVariantExpression;

    if (this.article.expressions2.length) {
      currentVariantExpression = this.allVariantExpressions.find(x => x.expression1 === this.expression1.value && x.expression2 === this.expression2.value);
    }
    else {
      currentVariantExpression = this.allVariantExpressions.find(x => x.expression1 === this.expression1.value);
    }


    if (currentVariantExpression) {
      this.dialogRef.close(currentVariantExpression);

    }
    else {
      this.dialogService.showMessage("Nicht Möglich", "Diese Kombination ist nicht möglich");
    }


  }

  isValid() {

    if (this.article.expressions2.length) {
      return this.expression1.value !== '' && this.expression2.value !== '';
    }
    else {
      return this.expression1.value !== '';
    }

  }


}
