import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { ContactDto } from 'src/app/contacts/dto/contact-dto';
import { PhoneService } from '../../services/phone.service';

@Component({
  selector: 'app-phone-contact-dialog',
  templateUrl: './phone-contact-dialog.component.html',
  styleUrls: ['./phone-contact-dialog.component.scss']
})
export class PhoneContactDialogComponent implements OnInit {

  contact: ContactDto;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: {
      contactId?: number,
      contactDto?: ContactDto,
    },
    private readonly phoneService: PhoneService,
  ) { }


  async ngOnInit() {

    if (this.data.contactId) {
      this.contact = await firstValueFrom(this.phoneService.getPhoneContactDialogData(this.data.contactId));
    }
    else {
      this.contact = this.data.contactDto;
    }


  }


}
